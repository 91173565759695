import React, { useState, useEffect } from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  Close as CloseIcon,
  Save as SaveIcon,
  Home as HomeIcon,
  Search as SearchIcon,
} from "@material-ui/icons";

import {
  Autocomplete as GooglePlacesAutocomplete,
  LoadScript,
} from "@react-google-maps/api";

import Loader from "../../components/Loader";
import PromptModal from "../../components/PromptModal";
import {
  fetchUserWorkHistory,
  getUserAddress,
  saveUserAddress,
} from "../../api/UserApi";
import { isEqual } from "lodash"; // JSON object comparison function
import { getAddress } from "../../api/SitesApi";

export default function ContactCard(props) {
  const [mode, setMode] = useState("view"); // "view" or "edit"

  const [address, setAddress] = useState(null);

  const [userSiteList, setUserSiteList] = useState([]);

  // On load and mode change, get profile details from API
  useEffect(() => {
    function removeDuplicates(originalArray, prop) {
      let newArray = [];
      let lookupObject = {};
      for (let i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
      }
      for (let i in lookupObject) {
        newArray.push(lookupObject[i]);
      }
      return newArray;
    }

    try {
      Promise.all([
        getUserAddress(localStorage.userId),
        fetchUserWorkHistory({
          PageNr: 1,
          SearchList: [{ UserId: localStorage.userId }],
        }),
      ]).then(([addressRes, workHistorySiteList]) => {
        setAddress({
          id: addressRes?.id || 0,
          userId: addressRes?.userId || localStorage.userId,
          address1: addressRes?.address1 || "",
          address2: addressRes?.address2 || "",
          city: addressRes?.city || "",
          province: addressRes?.province || "",
          postalCode: addressRes?.postalCode || "",
          country: addressRes?.country || "",
          modifiedBy: addressRes?.modifiedBy || localStorage.userId,
        });
        const userSites = removeDuplicates(workHistorySiteList, "siteId");
        setUserSiteList(userSites);
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }, [mode]);

  return (
    <div className="card" style={{ width: "130%" }}>
      <div className="content-container">
        <h3>My Address</h3>
      </div>

      {mode === "view" ? (
        <ContactInfoView
          trigger={props.trigger}
          address={address}
          userSiteList={userSiteList}
          handleEditModeClick={() => setMode("edit")}
        />
      ) : (
        <ContactInfoForm address={address} onClose={() => setMode("view")} />
      )}
    </div>
  );
}

// View-mode body for ContactInfoCard
function ContactInfoView(props) {
  const [selectedSiteId, setSelectedSiteId] = useState(0);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "auto",
        overflow: "scroll",
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  useEffect(() => {
    if (localStorage.siteId) {
      setSelectedSiteId(localStorage.siteId);
    }
  }, []);
  const handleSiteChange = async (e) => {
    const newSiteId = e.target.value;
    localStorage.setItem("siteId", newSiteId);
    setSelectedSiteId(newSiteId);
    await props.trigger();
  };
  return (
    <>
      <div
        className="flex-container-horizontal"
        style={{ justifyContent: "space-between" }}>
        <div style={{ flex: 1 }}>
          {
            // Only show address section if there is something to display
            props.address &&
            (props.address.address1 !== "" ||
              props.address.city !== "" ||
              props.address.province !== "" ||
              props.address.postalCode !== "" ||
              props.address.country !== "") ? (
              <>
                <div className="flex-container-horizontal">
                  <div className="content-container">
                    <HomeIcon color="primary" />
                  </div>

                  <div style={{ marginLeft: "5px", marginTop: "5px" }}>
                    <div className="contact-info-item">
                      {
                        // Show address1 if not empty
                        props.address.address1 !== "" ? (
                          <p>{props.address.address1}</p>
                        ) : (
                          <></>
                        )
                      }
                      {
                        // Only show comma if both city and province are visible
                        props.address.city !== "" &&
                        props.address.province !== "" ? (
                          <p>
                            {props.address.city}, {props.address.province}
                          </p>
                        ) : (
                          <p>
                            {props.address.city}
                            {props.address.province}
                          </p>
                        )
                      }
                      {
                        // Show space between postal code and country if both are non-empty
                        props.address.postalCode !== "" &&
                        props.address.country !== "" ? (
                          <p>
                            {props.address.postalCode}, {props.address.country}
                          </p>
                        ) : (
                          <p>
                            {props.address.postalCode}
                            {props.address.country}
                          </p>
                        )
                      }
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )
          }
        </div>

        <div>
          <IconButton color="primary" onClick={props.handleEditModeClick}>
            <EditIcon />
          </IconButton>
        </div>
      </div>

      {/* <div className="content-container">
                <h3>Current Site</h3>
            </div> */}

      {/* <div className="flex-container-horizontal" style={{ justifyContent: "space-between" }}> */}
      <div className="input-container">
        <div
          className="flex-container-vertical"
          style={{ alignItems: "stretch" }}>
          <FormControl>
            <InputLabel id="mutiple-select-label" className="timesheet-label">
              {selectedSiteId ? "Current Site" : "(no site)"}
            </InputLabel>
            <Select
              labelId="mutiple-select-label"
              label="Company"
              variant="outlined"
              value={selectedSiteId}
              onChange={handleSiteChange}
              MenuProps={MenuProps}
              style={{ maxWidth: "100%" }}>
              {props.userSiteList.map((option, index) => {
                return (
                  <MenuItem
                    key={option.id}
                    value={option.siteId}
                    style={{
                      width: "450px",
                    }}>
                    <ListItemText
                      primary={option.siteName}
                      secondary={
                        option.address.length > 30
                          ? `${option.address.slice(0, 30)}...`
                          : option.address
                      }
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </div>

      {/* </div> */}
    </>
  );
}

function ContactInfoForm(props) {
  const [isSaving, setIsSaving] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [addressObj, setAddressObj] = useState(props.address);

  const [searchTerm, setSearchTerm] = useState("");
  const [scriptLibraries] = useState(["places"]);
  const [autocomplete, setAutocomplete] = useState(null);

  async function handleSearch() {
    let place = autocomplete.getPlace();
    const getAddressRes = await getAddress({
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    });
    setIsSaving(true);

    const newAdd = {
      city: getAddressRes.objAddress?.city,
      province: getAddressRes.objAddress?.province,
      country: getAddressRes.objAddress?.country,
      address1: "",
      address2: "",
      postalCode: "",
    };

    setAddressObj(newAdd);
    setIsSaving(false);
  }

  // function handleAddressChange(newValue, field) {
  //     let newAddress = addressObj;
  //     switch (field) {
  //         case "address1":
  //             newAddress.address1 = newValue;
  //             break;
  //         case "address2":
  //             newAddress.address2 = newValue;
  //             break;
  //         case "city":
  //             newAddress.city = newValue;
  //             break;
  //         case "province":
  //             newAddress.province = newValue;
  //             break;
  //         case "country":
  //             newAddress.country = newValue;
  //             break;
  //         case "postalCode":
  //             newAddress.postalCode = newValue;
  //             break;
  //         default:
  //             break;
  //     }

  //     setAddressObj(newAddress);
  // }

  function handleAddressChange(e) {
    setAddressObj({
      ...addressObj,
      [e.target.name]: e.target.value,
    });
  }

  // If form has changes, then show discard confirmation popup
  function handleCloseButtonClick() {
    if (!isEqual(addressObj, props.address)) setDiscardModalOpen(true);
    else props.onClose();
  }

  // API save values from form
  async function handleSaveButtonClick() {
    try {
      setIsSaving(true); // Show loading animation
      // Parallelized API Calls for saving emails/phones

      const addObj = {
        id: props.address.id || 0,
        userId: localStorage.userId,
        address1: addressObj.address1,
        address2: addressObj.address2,
        city: addressObj.city,
        province: addressObj.province,
        postalCode: addressObj.postalCode,
        country: addressObj.country,
        modifiedBy: localStorage.userId,
      };

      let apiCalls = [saveUserAddress(addObj)];
      await Promise.all(apiCalls);

      props.onClose(); // Saving/deleting was successful; close form
    } catch (error) {
      if (error.name === "InvalidEmailError") {
        alert(error.message);
        return;
      } else {
        console.log(error);
        // alert("Error saving emails and/or phones!");
      }
    } finally {
      setIsSaving(false); // Clear loading animation
    }
  }

  return (
    <div className="flex-container-horizontal">
      <PromptModal
        isOpen={discardModalOpen}
        onClose={() => setDiscardModalOpen(false)}
        title="Are you sure you want to leave?"
        desc="Any unsaved changes will be discarded."
        redButtonText="Discard"
        onRedButtonClick={props.onClose}
      />

      {
        // Form body
        isSaving ? (
          <Loader />
        ) : (
          <div style={{ flex: 1 }}>
            <div>
              <div className="input-container">
                
                  <GooglePlacesAutocomplete
                    onLoad={(newInstance) => setAutocomplete(newInstance)} // Sets instance of Autocomplete to be referenced
                    onPlaceChanged={handleSearch}>
                    <TextField
                      variant="outlined"
                      placeholder="Search address or project name"
                      fullWidth
                      value={searchTerm}
                      onChange={(event) => setSearchTerm(event.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {
                              // Only show "X" button when field not empty
                              searchTerm !== "" ? (
                                <IconButton onClick={() => setSearchTerm("")}>
                                  <CloseIcon />
                                </IconButton>
                              ) : (
                                <></>
                              )
                            }
                            <IconButton color="primary" onClick={handleSearch}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GooglePlacesAutocomplete>
              </div>

              <hr></hr>

              <p
                style={{
                  textAlign: "center",
                  fontSize: "small",
                  marginBottom: "2rem",
                }}>
                Or fill your address in below
              </p>

              <div className="input-container">
                <TextField
                  id="address1-textfield"
                  label="Address 1"
                  variant="outlined"
                  fullWidth
                  name="address1"
                  value={addressObj?.address1}
                  onChange={handleAddressChange}
                />
              </div>

              <div className="input-container">
                <TextField
                  id="address2-textfield"
                  label="Address 2"
                  variant="outlined"
                  fullWidth
                  name="address2"
                  value={addressObj?.address2}
                  onChange={handleAddressChange}
                />
              </div>

              <div className="flex-container-horizontal">
                <div className="input-container">
                  <TextField
                    id="city-textfield"
                    label="City"
                    name="city"
                    variant="outlined"
                    value={addressObj?.city}
                    onChange={handleAddressChange}
                  />
                </div>
                <div className="input-container">
                  <TextField
                    id="province-textfield"
                    label="State/Province"
                    variant="outlined"
                    name="province"
                    value={addressObj?.province}
                    onChange={handleAddressChange}
                  />
                </div>
              </div>

              <div className="flex-container-horizontal">
                <div className="input-container">
                  <TextField
                    id="city-textfield"
                    label="Postal Code"
                    variant="outlined"
                    name="postalCode"
                    value={addressObj?.postalCode}
                    onChange={handleAddressChange}
                  />
                </div>
                <div className="input-container">
                  <TextField
                    id="province-textfield"
                    label="Country"
                    variant="outlined"
                    name="country"
                    value={addressObj?.country}
                    onChange={handleAddressChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }

      {/* Form Buttons */}
      <div className="flex-container-vertical" style={{ marginLeft: "10px" }}>
        <IconButton color="primary" onClick={handleCloseButtonClick}>
          <CloseIcon />
        </IconButton>

        <IconButton color="primary" onClick={handleSaveButtonClick}>
          <SaveIcon />
        </IconButton>
      </div>
    </div>
  );
}
