import React from "react";
import { Modal, IconButton, Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import SummarizeIcon from '@mui/icons-material/Summarize';
import RoomIcon from '@mui/icons-material/Room';
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { useState, useRef } from "react";
import { useToasts } from "react-toast-notifications";

function ImageChooseFromModal(props) {

    const fileRef = useRef(null);
    const { addToast } = useToasts();

    function handleBlankPageAdd() {
        const picArray = [...props.pictureList];
        const obj = {
            id: (picArray.length + 1),
            fileUrl: 'https://biappstoragetest2.blob.core.windows.net/userprofiles/96/20230427231517132img.jpg',
            mimeType: 'image/jpeg',
            name: 'image' + (picArray.length + 1).toString(),
            imageFrom: 'Blank',
            imageDescription: ''
        }
        picArray.splice(props.selectedFromEmpty ? props.pictureList.length : props.addIconPosition + 1, 0, obj);
        props.setPictureList(picArray);
        props.onClose();
        // props.handlePreview();
    }

    function handleGalleryAdd() {
        if (props.imageCount >= 5) {
            alert('No picture added limit of 5 picture of gallery');
        }
        else {
            fileRef.current.click();
        }
    }

    const handleChange = (event) => {
        let allFilesValid = true;
        if (fileRef?.current?.files?.length > 0) {
            for (let i = 0; i < fileRef?.current?.files.length; i++) {
                if (!fileRef?.current?.files[i].type.startsWith('image/')) {
                    allFilesValid = false;
                    break;
                }
            }
        }
        if (!allFilesValid) return alert('Please select valid image files (JPEG, PNG, GIF, etc.).');

        const selectedFileLength = fileRef?.current?.files?.length ?? 0;
        if (selectedFileLength > 5) {
            return alert('No picture added limit of 5 picture of gallery');
        }

        if (props?.imageCount + fileRef.current.files.length > 5) {
            return alert(`Now you can add only ${(5 - props?.imageCount)} images form gallery`);
        }

        const tmpArr = [...fileRef.current.files];
        props.setImageCount(tmpArr.length + props?.imageCount)
        let a = props.pictureList;
        let maxIndexPict = 0;
        for (let i = 0; i < tmpArr.length; i++) {
            const obj = {
                id: props.pictureList.length != 0 ? props.pictureList.length + 1 : (maxIndexPict + parseInt(i) + 1),
                fileUrl: URL.createObjectURL(tmpArr[i]),
                galleryUploadUrl: tmpArr[i],
                type: tmpArr[i].type,
                name: 'image' + (maxIndexPict + parseInt(i)).toString(),
                imageFrom: 'Gallery',
                imageDescription: ''
            }
            a.splice(props.selectedFromEmpty ? props.pictureList.length : props.addIconPosition + 1, 0, obj)
        }
        props.setPictureList(a);
        props.onClose();
        // props.handlePreview();
    }

    const useStyles = makeStyles(() => ({
        blankPage: {
            backgroundColor: 'white',
            border: `3px solid grey`,
            color: 'black',
        },
        fromSite: {
            backgroundColor: 'white',
            border: `3px solid red`,
            color: 'black'
        },
        fromGallery: {
            backgroundColor: 'white',
            border: `3px solid yellow`,
            color: 'black'
        }
    }));

    const classes = useStyles();

    const oepnForsite = () => {
        console.log("pic", props.pictureList);

        props.setAddPictureModalOpen(true)
    }

    return (
        <>
            <input type="file" id="fileLen" style={{ display: 'none' }} multiple size="5" ref={fileRef} accept="image/*" onChange={handleChange} />
            <Modal
                open={props.isOpen}
                onClose={props.onClose}>
                <div className="modal-center">
                    <div className="modal-card" style={{ width: "500px" }}>
                        <div className="modal-card-header-buttons">
                            <IconButton
                                color="primary"
                                onClick={props.onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                            <div onClick={handleBlankPageAdd} style={{ marginBottom: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                                <Avatar className={classes.blankPage}>
                                    <SummarizeIcon />
                                </Avatar>
                                <Typography variant="subtitle1" gutterBottom>Add Blank Page</Typography>
                            </div>
                            <div onClick={() => oepnForsite()} style={{ marginBottom: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                                <Avatar className={classes.fromSite}>
                                    <RoomIcon />
                                </Avatar>
                                <Typography variant="subtitle1" gutterBottom>Photos From Projects</Typography>
                            </div>
                            <div onClick={handleGalleryAdd} style={{ marginBottom: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                                <Avatar className={classes.fromGallery}>
                                    <Typography style={{ margin: 'auto' }} variant="subtitle1">{`${props.imageCount}/5`}</Typography>
                                </Avatar>
                                <Typography variant="subtitle1" gutterBottom>Add From Computer</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ImageChooseFromModal;
