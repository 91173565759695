import React, { useState, useEffect, useCallback } from "react";
import {
    Stack,
    Card, CardContent,
    TextField,
    Dialog, DialogTitle, DialogContent, DialogActions,
    IconButton,
    List, ListItem, ListItemButton, ListItemText, ListSubheader,
    Avatar,
    Menu, MenuItem, Divider, Modal, Typography
} from "@mui/material";

import {
    MoreVert as MoreIcon,
    Error as ErrorOutlineOutlinedIcon
} from "@mui/icons-material";
import SearchField from "../SearchField";
import { useHistory } from "react-router-dom";
import { BIBlue, BIRed } from "../../assets/buildidColors";

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { fetchCompanies } from "../../api/CompanyApi";
import { fetchRoles } from "../../api/RolesApi";
import { fetchSiteCompanyRoles, saveProjectCompany, saveSiteCompany } from "../../api/SitesApi";
import BuildIcon from "@mui/icons-material/Build";
import Placeholder from "../Placeholder";
import Loader from "../Loader";
import ReportModal from "../ReportModal";
import moment from "moment";
import AddIteamModalCompanies from "./AddIteamModalCompanies";
import { Button } from "@material-ui/core";



const filter = createFilterOptions();
/*
List component
Props (*required):
    *getData            - function; API function that returns the items in the list (uncategoried)
    *itemIdKey          - string; key name in JSON item for ID, used for redirection
    *itemNameKey        - string; key name in JSON item for name, displayed as primary text
    *itemRecordType     - string; name of record type to redirect to

    To display more information for each list item, use the following props to define the keys:
    itemImgKey          - string; key name in JSON item for image URL, displayed as thumbnail
    itemSecondaryKey    - string; key name for JSON item for text to be displayed as secondary text

    Lists are uncategorized, unless category props are used (all must be used, or none):
    categoryIdKey       - string; key name in JSON item for category ID, used for redirection
    categoryNameKey     - string; key name in JSON item used to categorize items
    categoryRecordType  - string; name of record type to redirect to

    searchPlaceholder   - string; text displayed in search field when empty
    
    To render an "Add Item" button, use the following props (all except addItemCategoryLabel are required):
    addButtonLabel      - string; text displayed for button to add an item to the list
    addItemLabel        - string; placeholder text for Add Item form
    addItemCategoryLabel    -   string; placeholder text for Add Item form (optional, if not specified
                                field will not be rendered)
    onSaveItem          - function; API function that saves the item
    
Usage:
<ListSection
    getData={() => }
    itemIdKey=""
    itemNameKey=""
    searchPlaceholder=""
    />
*/
export default function CompanyListSection(props) {
    const history = useHistory();

    const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [showCompanyRoleModal, setShowCompanyRoleModal] = useState(false);
    const [companyData, setCompanyData] = useState("");

    const [itemRecordId, setItemRecordId] = useState(0);

    /*
        formattedData is an array with elements with the following format:
        {
            {props.categoryIdKey}:      int
            {props.categoryNameKey}:    string
            items: [
                { JSON object from API }
            ]
        }
    */
    const [formattedData, setFormattedData] = useState([]);
    /*
        filteredData is a copy of formattedData that contains only elements whose category[categoryNameKey] or
        item[itemNameKey] matches the search term
    */
    const [filteredData, setFilteredData] = useState([]);



    const updateList = useCallback(() => {
        props.getData()
            .then(res => {
                // Format response from list of elements into a list of category objects with a list
                // of items under that category
                let formatted = [];
                res?.data?.forEach(element => {
                    {
                        /* udpate Projects cahnges Yash 19-03 */
                    }

                    if (props.itemRecordType === "site") setItemRecordId(element?.siteId);

                    if (props.itemRecordType === "product")
                        setItemRecordId(element?.productId);

                    if (props.itemRecordType === "company")
                        setItemRecordId(element?.companyId);
                    {
                        /* udpate Projects cahnges Yash 19-03 */
                    }
                    let existingCategory = false;
                    formatted.forEach(obj => {
                        if (element[props.categoryIdKey] === obj[props.categoryIdKey]) {
                            existingCategory = true;
                            obj.items.push(element);
                        }
                    })

                    if (!existingCategory) {
                        let newCategory = {};
                        newCategory[props.categoryIdKey] = element[props.categoryIdKey];
                        newCategory[props.categoryNameKey] = (
                            // If element[props.categoryIdKey] is 0, API will have no name (correct)
                            // Display element[props.categoryNameKey] as "No Role"
                            (element[props.categoryIdKey] !== 0 && element[props.categoryIdKey] !== 7)
                                ? element[props.categoryNameKey]
                                : (element[props.categoryIdKey] == 7) ? element[props.categoryNameKey] : "No Role"
                        );
                        newCategory.items = [element];
                        formatted.push(newCategory);
                    }
                })

                // Sort formatted[] in alphabetical order of category name
                // formatted.sort(function (a, b) {
                //     if (a[props.categoryNameKey] < b[props.categoryNameKey]) return -1;
                //     if (a[props.categoryNameKey] > b[props.categoryNameKey]) return 1;
                //     return 0;
                // });
                // Sort each items subarray in alphabetical order of item names

                formatted.sort((a, b) => {
                    if (a[props.categoryNameKey] === "No Role") {
                        return 1; // Place "No Role" items at the end
                    } else if (b[props.categoryNameKey] === "No Role") {
                        return -1; // Place "No Role" items at the end
                    } else {
                        // Sort other items based on the title
                        return a[props.categoryNameKey].localeCompare(b[props.categoryNameKey]);
                    }
                });

                formatted.forEach(obj => {
                    obj.items.sort(function (a, b) {
                        if (a[props.itemNameKey] < b[props.itemNameKey]) return -1;
                        if (a[props.itemNameKey] > b[props.itemNameKey]) return 1;
                        return 0;
                    })
                })
                setFormattedData(formatted);
            });
    }, []);


    // On searchTerm change, update to only display data that is matching searchTerm
    useEffect(() => {
        let data = [];
        formattedData.forEach(category => {
            // Base case; if searchTerm matches categoryNameKey then display the entire section
            if (category[props.categoryNameKey]?.toLowerCase().includes(searchTerm.toLowerCase()))
                data.push(category);
            // If searchTerm matches itemNameKey or itemSecondaryKey of item, then display the item(s) as
            // well as the category subheading 
            else {
                let matchingItems = [];
                category.items.forEach(item => {
                    if (item[props.itemNameKey]?.toLowerCase().includes(searchTerm.toLowerCase())
                        || item[props.itemSecondaryKey]?.toLowerCase().includes(searchTerm.toLowerCase()))
                        matchingItems.push(item)
                })

                if (matchingItems.length > 0) {
                    let newCategory = {};
                    newCategory[props.categoryIdKey] = category[props.categoryIdKey];
                    newCategory[props.categoryNameKey] = category[props.categoryNameKey];
                    newCategory.items = matchingItems;
                    data.push(newCategory);
                }
            }
        })

        setFilteredData(data);
    }, [searchTerm, formattedData, props])


    // On load, retrieve data
    useEffect(() => {
        updateList();
    }, [])

    // get updated data after save/edit/delete
    useEffect(async () => {
        if (props?.getUpdatedData) {
            try {
                await updateList();
                props?.changeGetUpdateDataValue()
            } catch (err) {
                props?.changeGetUpdateDataValue()
            }
        }
    }, [props?.getUpdatedData])

    const onCompanyRoleAdd = async () => {
        updateList()
    }

    const onAddCompanyRoleClick = async (data) => {
        if (!data) return null;
        await setCompanyData(data)
        setShowCompanyRoleModal(true);

    }

    return (
        <>


            {
                props?.emptyData ?
                    (
                        <div>
                            {
                                props?.NoResultBtn && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        size="small"
                                        onClick={() => setAddCompanyModalOpen(true)}
                                    >
                                        {props?.NoResultBtn ?? ""}
                                    </Button>
                                </div>
                            }

                            <Placeholder
                                icon={props?.NOResultIcon ?? <BuildIcon />}
                                text={props?.NoResultText ?? ""}
                                text2={props?.NoResultText2 ?? ""}
                            />
                        </div>
                    )
                    :
                    <>
                        {
                            props.isLoading ? <Loader /> :
                                <Card>
                                    <CardContent>

                                        {
                                            props?.searchTopDescription &&
                                            (
                                                <>
                                                    <div
                                                        style={{ color: 'gray', padding: '10px 0px', fontSize: '14px' }}
                                                    >
                                                        {props?.searchTopDescription ?? ""}
                                                    </div>
                                                </>
                                            )


                                        }
                                        <SearchField
                                            value={searchTerm}
                                            onChange={setSearchTerm}
                                            placeholder={props.searchPlaceholder} />

                                        {   // Only render "Add Item" button if specified
                                            props.addButtonLabel
                                                ? (
                                                    <div className="flex-container-horizontal" style={{ justifyContent: "flex-end" }}>
                                                        <Button
                                                            variant="text"
                                                            color="primary"
                                                            size="small"
                                                            onClick={() => setAddCompanyModalOpen(true)}>
                                                            {props.addButtonLabel}
                                                        </Button>
                                                    </div>
                                                )
                                                : <></>
                                        }

                                    </CardContent>


                                    {
                                        filteredData.map((category, categoryIndex) => (
                                            <>
                                                <List key={categoryIndex} style={{ padding: "0" }}>

                                                    <ListSubheader style={{ lineHeight: '30px' }}>
                                                        {   // If props.categoryRecordType is not defined, it shouldn't redirect anywhere (ie. non-clickable)
                                                            props.categoryRecordType
                                                                ? (
                                                                    <span
                                                                        className="link"
                                                                        onClick={() => {
                                                                            if (props.categoryRecordType === "manufacturer")
                                                                                history.push(`/manufacturer/${category[props.categoryIdKey]}`);
                                                                        }}>
                                                                        {category[props.categoryNameKey]}
                                                                    </span>
                                                                ) : <> {
                                                                    category[props.categoryNameKey]
                                                                }
                                                                    {
                                                                        category[props?.categoryNameKey] === "No Role" ?
                                                                            (
                                                                                <>
                                                                                    <IconButton
                                                                                        onClick={() => {
                                                                                            setShowMessage(true)
                                                                                        }}
                                                                                        style={{ marginTop: '-5px' }}
                                                                                    >

                                                                                        <ErrorOutlineOutlinedIcon
                                                                                            sx={{ width: 15, height: 15 }}
                                                                                        />

                                                                                    </IconButton>
                                                                                </>
                                                                            )
                                                                            : null

                                                                    }
                                                                </>
                                                        }
                                                    </ListSubheader>
                                                    {
                                                        category.items.map((item, itemIndex) => (
                                                            <ListSectionItem
                                                                key={itemIndex}
                                                                data={item}
                                                                itemIdKey={props.itemIdKey}
                                                                itemNameKey={props.itemNameKey}
                                                                itemRecordType={props.itemRecordType}
                                                                itemImgKey={props.itemImgKey}
                                                                itemSecondaryKey={props.otherSiteCompanyRole}
                                                                noRole={category[props?.categoryNameKey] === "No Role" ? true : false}
                                                                showAddCompanyRoleModal={async (data) => {
                                                                    // setAddCompanyModalOpen(true)
                                                                    await setCompanyData(data)
                                                                    onAddCompanyRoleClick(data)
                                                                }}
                                                                recordId={itemRecordId}
                                                                setRecordId={setItemRecordId}
                                                            />
                                                        ))
                                                    }
                                                </List>
                                                <Divider />
                                            </>
                                        ))
                                    }

                                </Card>

                        }
                    </>

            }
            <AddIteamModalCompanies
                open={addCompanyModalOpen}
                onClose={() => setAddCompanyModalOpen(false)}
                saveItem={newItem => props.saveItem(newItem)}
                updateList={updateList}
                title={props.addButtonLabel}
                addItemLabel={props.addItemLabel}
                itemNameKey={props.itemNameKey}
                addItemCategoryLabel={props.addItemCategoryLabel}
                categoryNameKey={props.categoryNameKey}
                hideRole={props?.hideRole ?? false}
            />

            <Modal
                open={showMessage}
                onClose={() => {
                    setShowMessage(false);
                }}>
                <div className="modal-center">
                    <div className="modal-card" style={{ width: "400px" }}>
                        <div className="modal-card-body">
                            <div className="flex-container-verticle">
                                <p>Tap the ... button to add a role to a company.</p>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button color="primary"
                                        onClick={() => {
                                            setShowMessage(false);
                                        }}>Ok</Button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <AddCompanyRoleModal
                open={showCompanyRoleModal}
                onClose={() => {
                    setCompanyData("")
                    setShowCompanyRoleModal(false)
                }}
                companyData={companyData}
                onCompanyRoleAdd=
                {() => {
                    setCompanyData("")
                    setShowCompanyRoleModal(false)
                    onCompanyRoleAdd()
                }}
            />
        </>

    )
}






function ListSectionItem(props) {
    const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
    const [reportModalOpen, setReportModalOpen] = useState(false);
    const history = useHistory();


    const handleReport = (id) => {
        props.setRecordId(id);
        setMoreMenuAnchorEl(false);
        setReportModalOpen(true);
    }


    return (
        <ListItem
            secondaryAction={
                <>
                    <IconButton
                        onClick={event => setMoreMenuAnchorEl(event.currentTarget)}>
                        <MoreIcon />
                    </IconButton>
                    <Menu
                        anchorEl={moreMenuAnchorEl}
                        open={Boolean(moreMenuAnchorEl)}
                        onClose={() => setMoreMenuAnchorEl(null)}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}>
                        {
                            props?.noRole &&
                            <MenuItem
                                onClick={() => {
                                    setMoreMenuAnchorEl(false);
                                    props.showAddCompanyRoleModal(props?.data)
                                }}
                                style={{ color: BIBlue }}
                                dense>
                                Add company role on project
                            </MenuItem>
                        }
                        <MenuItem
                            onClick={() => handleReport(props.data[props.itemIdKey])}

                            style={{ color: BIRed }}
                            dense>
                            Report
                        </MenuItem>
                    </Menu>
                    <ReportModal
                        name={props?.itemRecordType?.charAt(0).toUpperCase() + props?.itemRecordType?.slice(1)?.toLowerCase()}
                        isOpen={reportModalOpen}
                        onClose={() => setReportModalOpen(false)}
                        recordId={props.recordId}

                    />


                </>
            }
        // disablePadding={true}
        >
            <ListItemButton
                onClick={() => {
                    if (props.itemRecordType === "site")
                        history.push(`/sites/site/${props.data[props.itemIdKey]}`);
                    if (props.itemRecordType === "product")
                        history.push(`/product/${props.data[props.itemIdKey]}`);
                    if (props.itemRecordType === "company")
                        history.push(`/company/${props.data[props.itemIdKey]}`);
                }}>
                {props.data[props.itemImgKey]
                    ? (
                        <Avatar
                            variant="rounded"
                            style={{ marginRight: "8px" }}
                            src={props.data[props.itemImgKey]}>
                            {   // Use first letter of item name as Avatar default
                                // If a default image is to be used, it must be in props.data[props.itemImgKey]
                                props.data[props.itemNameKey][0]
                            }
                        </Avatar>
                    )
                    : <></>
                }
                <ListItemText
                    sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                    primary={<span> {props.data[props.itemNameKey]} </span>}
                    secondary={(props.data[props.itemSecondaryKey] == "") ?
                        <div style={{ display: 'none' }}></div> :
                        <div style={{ marginBottom: '10px' }}>
                            {props.data[props.itemSecondaryKey]}
                        </div>}
                />
            </ListItemButton>
        </ListItem>
    )
}


function AddCompanyRoleModal(props) {


    const [rolesData, setRolesData] = useState([]);
    const [roleId, setRoleId] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [otherRoleName, setOtherRoleName] = useState('');
    const [otherValFlag, setOtherValFlag] = useState(false);


    // Reset fields on close
    function onCloseClick() {
        setOtherValFlag(false)
        setRoleId(0)
        setOtherRoleName('')
        setInputValue('')
        props.onClose();
    }

    async function onSaveClick() {
        const obj = [{
            Id: props?.companyData?.id ?? 0,
            ProjectId: Number(props?.companyData?.projectId ?? 0),
            CompanyId: props?.companyData?.companyId ?? 0,
            CreatedBy: Number(localStorage.userId),
            DateCreated: moment().format(),
            DateModified: moment().format(),
            ModifiedBy: Number(localStorage.userId),
            ProjectCompanyRoleId: roleId,
            OtherProjectCompanyRole: otherRoleName
        }]

        await saveProjectCompany(obj).then((res) => {
            setOtherValFlag(false)
            setRoleId(0)
            setOtherRoleName('')
            setInputValue('')
            props.onCompanyRoleAdd()
        }).catch((err) => {
            console.log("error while saving role :-", err)
        })
    }


    const getRoles = async () => {
        const response = await fetchSiteCompanyRoles({ SearchList: [{ userId: Number(localStorage.userId) }] });

        let a = [];
        const companyRoleData = response.map((value) => {
            a = value;
            a['label'] = value.name;
            return a;
        });
        setRolesData(companyRoleData);
    };

    useEffect(() => {
        getRoles();
    }, [])


    return (
        <Dialog
            open={props.open}
            onClose={onCloseClick}
            fullWidth
            maxWidth="sm">
            <DialogTitle>Add Company Role</DialogTitle>
            <DialogContent dividers>
                <div style={{ display: 'flex' }}>
                    <div style={{ fontWeight: 'bold' }}>
                        Project:- {" "} &nbsp;
                    </div>
                    <div>
                        {props?.companyData?.projectName ?? ""}
                    </div>
                </div>
            </DialogContent>
            <DialogContent dividers>
                <Stack spacing={2}>
                    Let us know what role company played in this site
                </Stack>
            </DialogContent>
            <DialogContent dividers>
                <div style={{ display: 'flex' }}>
                    <div style={{ fontWeight: 'bold' }}>
                        Company Name:- {" "} &nbsp;
                    </div>
                    <div>
                        {props?.companyData?.companyName ?? ""}
                    </div>
                </div>

            </DialogContent>
            <DialogContent dividers>
                <Stack spacing={2}>
                    <>
                        <Autocomplete
                            freeSolo
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            label="Roles"
                            id="Roles"
                            options={rolesData}
                            onChange={(event, newValue) => {
                                if (newValue && newValue.name == 'Other') {
                                    setOtherValFlag(true)
                                    setRoleId(newValue?.id);
                                }
                                else {
                                    setOtherValFlag(false)
                                    setRoleId(newValue?.id);
                                }
                            }}
                            getOptionLabel={(option) => `${option?.name}`}
                            style={{ flex: 1 }}
                            renderInput={(params) => {
                                return (
                                    <>
                                        <TextField {...params} label="Roles" variant="outlined" />
                                    </>)
                            }}
                        />
                        {otherValFlag ?
                            <TextField
                                label="New Role"
                                variant="outlined"
                                onChange={(event) => {
                                    setOtherRoleName(event.target.value);
                                }}
                            /> : <></>
                        }
                    </>

                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={() => onCloseClick()}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => onSaveClick()}
                    disabled={otherValFlag ? !otherRoleName.trim() : !roleId}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}