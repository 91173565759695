import React from "react";

import { Modal, IconButton, Button } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

import { saveContactEvent } from "../../api/ContactsApi";


function RequestsModal(props){
	return(
		<Modal
			open={props.isOpen}
			onClose={props.onClose}>
			<div className="modal-center">
				<div className="modal-card" style={{ width: "400px" }}>
					<div className="modal-card-header-buttons">
						<IconButton
							color="primary"
							onClick={props.onClose}>
							<CloseIcon/>
						</IconButton>
					</div>

					<div className="modal-card-body">
						<h2>Contact Requests</h2>

						{
							props.requests.map((notif, index) => (
								<Request
									key={index}
									contactUserId={notif.custom1}
									contactName={notif.custom2}
									deleteRequestNotification={() => 
										props.deleteRequestNotification(index)}
									updateContacts={props.updateContacts}/>
							))
						}

						{
							(props.requests.length === 0)
								? (
									<p className="greytext">
										No requests at this time.
									</p>
								)
								: <></>
						}
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default RequestsModal;


function Request(props){
	function handleButtonClick(config){
		try{
			// Use appropriate eventStatusId
			let eventStatusId = 0;
			if(config === "accept") eventStatusId = 2;	// 2 = accept
			else if(config === "reject") eventStatusId = 3;	// 3 = reject
			// Error if config is neither accept or reject
			else if(eventStatusId === 0) throw new Error();

			saveContactEvent({
				Id: 0,
				UserId: props.contactUserId,
				ContactEmail: localStorage.primaryEmail,
				EventStatusId: eventStatusId,
				ModifiedBy: localStorage.userId,
			})
				.then(res => {
					props.updateContacts();
					props.deleteRequestNotification();
				})
		}
		catch(error){
			console.log(error);
			if(config === "accept") alert("Error accepting contact request!");
			else if(config === "reject") alert("Error rejecting contact request!");
		}
	}

	return(
		<div className="flex-container-horizontal" style={{
				justifyContent: "space-between",
				alignItems: "center"
			}}>

			{props.contactName}

			<div className="flex-container-horizontal">
				<div className="input-container">
					<Button
						variant="contained"
						color="primary"
						onClick={() => handleButtonClick("accept")}>
						Accept
					</Button>
				</div>

				<div className="input-container">
					<Button
						variant="outlined"
						color="secondary"
						onClick={() => handleButtonClick("reject")}>
						Decline
					</Button>
				</div>
			</div>
		</div>
	)
}