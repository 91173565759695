import { restGet, restPost } from "./RestClient";
import { headers } from "./UserApi";

// let headers = {
// 	"Content-Type": "application/json",
// 	"Accept": "application/json",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }

// Companies options for Dropdowns
export async function fetchCompanies(data) {
	const res = await restPost("companies/FetchCompanies/", data, headers);
	return res;
}

export async function searchCompanies(data) {
	const res = await restPost("Companies/SearchCompanies/", data, headers);
	return res;
}

export async function searchManufacturers(data) {
	const res = await restPost("Companies/SearchManufacturers/", data, headers);
	return res;
}



export async function saveCompanies2(args) {
	const res = await restPost("companies/SaveCompanies2/", args, headers);
	return res.data;
}

export async function fetchCompaniesTrades(args) {
	const res = await restPost("companies/FetchCompanyTrades/", args, headers);
	return res.data;
}

export async function fetchCompaniesTradesForWeb(args) {
	const res = await restPost("companies/FetchCompanyTradesForWeb/", args);
	return res.data;
}

export async function saveCompanyTrades(args) {
	const res = await restPost("companies/SaveCompanyTrades", args, headers);
	return res.data;
}

export async function deleteCompanyTrades(args) {
	const res = await restPost("Companies/DeleteCompanyTrades2/", args, headers);
	return res.data;
}


export async function fetchCompanyAddress(args) {
	const res = await restPost("companies/FetchCompanyAddresses", args, headers);
	return res.data;
}

export async function fetchCompanyAddressForWeb(args) {
	const res = await restPost("companies/FetchCompanyAddressesForWeb", args);
	return res.data;
}

export async function saveCompanyAddress(args) {
	const res = await restPost("companies/SaveCompanyAddresses2", args, headers);
	return res.data;
}

export async function getProvinceByCountryName(args) {
	const res = await restPost("misc/FetchProvinces/", args, headers);
	return res.data;
}


export async function fetchCompaniesProducts(args) {
	const res = await restPost("companies/FetchCompanyProducts/", args, headers);
	return res;
}


export async function saveCompanyProduct(args) {
	const res = await restPost("companies/SaveCompanyProducts", args, headers);
	return res.data;
}

export async function getCompaniesDetails(companyId) {
	const res = await restGet("Companies/GetCompaniesDetailsForWeb/" + companyId);
	return res;
}

export async function getManufacturerDetails(manufacturerId) {
	const res = await restGet("Companies/GetManufacturerDetailsForWeb/" + Number(manufacturerId), {
		"Content-Type": "application/json",
		"Accept": "application/json"
	});
	return res;
}