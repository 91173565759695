import React from 'react'
import {
    Modal, Stack, Typography, Paper, IconButton,
    TextField, Button, Card, InputAdornment, CardContent, CardActions,
    ToggleButton, ToggleButtonGroup, Avatar
} from '@mui/material'
import { BIBlue, BIYellow } from '../../assets/buildidColors'
import NorthWestIcon from '@mui/icons-material/NorthWest';
import SouthIcon from '@mui/icons-material/South';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import AddIcon from '@mui/icons-material/Add';
import { MoreVert as MoreIcon } from "@mui/icons-material";
import { useActions } from '../ActionsContext';




const DashboardTutorial = (props) => {
    const { builderBucksBalance } = useActions();

    const btnlist = [
        {
            text: "All",
            active: true
        },
        {
            text: "Projects",
            active: false
        },
        {
            text: "Trades",
            active: false
        },
        {
            text: "Products",
            active: false
        }
    ]

    return (
        <>
            <Modal
                open={props?.tutorialIndex == 0 ? false : true}
                onClose={props.onClose}
                style={{
                    backgroundColor: 'rbga(0,0,0,0.1)'
                }}
            >
                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', height: '100%' }} onClick={props.onPress}>
                    {
                        props.tutorialIndex === 1 && <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <Stack sx={{ textAlign: 'center', color: 'white' }}>
                                <Typography variant="h6">
                                    Homepage
                                </Typography>
                                <Typography variant="subtitle1">
                                    This will display all posts from contacts, and groups you follow.
                                    <br />
                                    It will also display all your general details such as BuilderBucks and notifications.
                                </Typography>
                                <Typography variant="body1" >
                                    <span style={{ color: BIBlue }}> (Tap anywhere to continue)</span>
                                </Typography>
                            </Stack>
                        </div>
                    }


                    {
                        props.tutorialIndex === 2 &&
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '100%',
                            }}
                        >
                            <div className='home-tutorial-2'>
                                <Stack className='home-tutorial-2-stack'>
                                    {/* <ToggleButtonGroup
                                        color="primary"
                                        value={'All'}
                                        exclusive
                                        className="newsfeed-tabs-group"
                                        fullWidth={true}
                                        size='medium'
                                    >
                                        <ToggleButton disableRipple={true} value="All">ALL</ToggleButton>
                                        <ToggleButton disableRipple={true} value="Sites">SITES</ToggleButton>
                                        <ToggleButton disableRipple={true} value="Products">PRODUCTS</ToggleButton>
                                        <ToggleButton disableRipple={true} value="Trades">TRADES</ToggleButton>
                                    </ToggleButtonGroup> */}

                                    <Stack className="btn-listing">
                                        {btnlist.map((item, index) => (
                                            <Button className="btn-post" onClick={() => { }} style={{ background: item?.active === true ? "#083db8" : "#ffff", color: item?.active === true ? "#ffff" : "#000" }}
                                                key={index}
                                            >
                                                {item?.text}
                                            </Button>
                                        ))}
                                    </Stack>

                                </Stack>
                                <div style={{ marginLeft: '20%', color: 'white', marginTop: '20px' }}>
                                    <NorthWestIcon style={{ fontSize: '40px' }} />
                                    <div>
                                        Filter Posts by these keywords
                                    </div>
                                </div>
                            </div>
                        </div>
                    }



                    {
                        props.tutorialIndex === 3 &&
                        <div
                            className='home-tutorial-3-main-div'
                        >
                            <div className='home-tutorial-3-body'>
                                <Typography variant="subtitle1">
                                    Create a general post for all your contacts to see
                                </Typography>

                                <Stack >
                                    <Typography>
                                        <Typography>
                                            <SouthWestIcon style={{ fontSize: '40px' }} />
                                        </Typography>
                                        <Card>
							<div className="flex-container-horizontal" style={{ alignItems: "center", padding: "0px 16px" }}>
								<Avatar
									alt="Profile Picture"
									src={localStorage.pictureUrl} />

								{/* comment writing body description */}
								<div className="input-container" style={{ flex: 1 }}>
									<TextField
										variant="outlined"
										size="small"
										fullWidth
										placeholder="What do you want to talk about?" />
								</div>
							</div>
						</Card>
                                    </Typography>
                                </Stack>
                            </div>
                        </div>
                    }


                    {/* {
                        props.tutorialIndex === 4 &&
                        <div
                            style={{ height: '100%' }}
                        >
                            <div style={{ textAlign: 'center', backgroundColor: BIYellow, width: '95%', marginLeft: '5%', height: '48px', marginTop: '66px', lineHeight: '48px' }}>
                                <span style={{ color: BIBlue, fontSize: '1.5em', fontWeight: '700' }}>
                                    <b>{builderBucksBalance ?? '000'} Builder Bucks</b>
                                </span>
                            </div>
                            <div style={{ marginLeft: '50%', color: 'white', marginTop: '10px' }}>
                                <NorthWestIcon style={{ fontSize: '40px' }} />
                                <div>
                                    These are BuilderBucks! By adding information to BuildID, earn points to enter to win draws!
                                </div>
                            </div>
                        </div>
                    } */}

                </div>
            </Modal>
        </>
    )
}

export default DashboardTutorial