import React from "react";
import {
  Modal,
  Stack,
  Typography,
  IconButton,
  Card,
  CardContent,
  Box,
  Tooltip,
} from "@mui/material";
import { BIBlue } from "../../assets/buildidColors";
import { Delete as DeleteIcon } from "@material-ui/icons";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SouthEastRoundedIcon from "@mui/icons-material/SouthEastRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CheckBox } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import '../../assets/css/ResumeBuilder.css'

const ResumeBuilderTutorial = (props) => {
  return (
    <>
      <Modal
        open={props?.tutorialIndex == 0 ? false : true}
        onClose={props.onClose}
        style={{
          backgroundColor: "rbga(0,0,0,0.1)",
        }}>
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", height: "100%" }}
          onClick={props.onPress}>
          {props.tutorialIndex === 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}>
              <Stack sx={{ textAlign: "center", color: "white" }}>
                <Typography variant="h6">Resume Builder</Typography>
                <Typography variant="subtitle1">
                  Quickly generate and share your resume with others
                </Typography>
                <Typography variant="body1">
                  <span style={{ color: BIBlue }}>
                    {" "}
                    (Tap anywhere to continue)
                  </span>
                </Typography>
              </Stack>
            </div>
          )}

          {props.tutorialIndex === 2 && (
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                height: "100%",
              }}>
              <div className="resumebuilder-tutorial-3-body">
                <Box
                  className="resumebuilder-tutorial-3-box">
                  <Card>
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <Box>
                          <Typography component="div" variant="h5">
                            Test Resume 1
                          </Typography>
                          <Typography style={{ marginRight: "60%" }}>
                            Dec 13
                          </Typography>
                        </Box>
                        <Box>
                          <Tooltip title="Delete Resume">
                            <IconButton color="secondary">
                              <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </div>
                    </CardContent>
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <Box>
                          <Typography component="div" variant="h5">
                            Test Resume 2
                          </Typography>
                          <Typography style={{ marginRight: "60%" }}>
                            Dec 13
                          </Typography>
                        </Box>
                        <Box>
                          <Tooltip title="Delete Resume">
                            <IconButton color="secondary">
                              <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </div>
                    </CardContent>
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <Box>
                          <Typography component="div" variant="h5">
                            Test Resume 3
                          </Typography>
                          <Typography style={{ marginRight: "60%" }}>
                            Dec 13
                          </Typography>
                        </Box>
                        <Box>
                          <Tooltip title="Delete Resume">
                            <IconButton color="secondary">
                              <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </div>
                    </CardContent>
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <Box>
                          <Typography component="div" variant="h5">
                            Test Resume 4
                          </Typography>
                          <Typography style={{ marginRight: "60%" }}>
                            Dec 13
                          </Typography>
                        </Box>
                        <Box>
                          <Tooltip title="Delete Resume">
                            <IconButton color="secondary">
                              <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </div>
                    </CardContent>
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <Box>
                          <Typography component="div" variant="h5">
                            Test Resume 5
                          </Typography>
                          <Typography style={{ marginRight: "60%" }}>
                            Dec 13
                          </Typography>
                        </Box>
                        <Box>
                          <Tooltip title="Delete Resume">
                            <IconButton color="secondary">
                              <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </div>
                    </CardContent>
                  </Card>
                </Box>
                <div
                  style={{
                    marginLeft: "25%",
                    color: "white",
                    marginTop: "10px",
                  }}>
                  <ArrowUpwardRoundedIcon style={{ fontSize: "40px" }} />
                  <div> up to 5 Resumes at a time</div>
                </div>
              </div>
            </div>
          )}

          {props.tutorialIndex === 3 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}>
              <div
                style={{
                  marginTop: "35%",
                  color: "#fff",
                  marginLeft: "80%",
                }}>
                <div> click of Add icon</div>
                <SouthEastRoundedIcon style={{ marginLeft: "80%" }} />
              </div>

              <AddCircleIcon
                style={{
                  height: "50px",
                  width: "50px",
                  position: "fixed",
                  bottom: "30px",
                  right: "30px",
                  color: "blue",
                }}
              />
            </div>
          )}

          {props.tutorialIndex === 4 && (
            <div
              style={{ height: "100%", display: "flex", alignItems: "center" }}>
              <div
                className="resumebuilder-tutorial-4-body">
                <Card className="resumebuilder-tutorial-4-info-card">
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <h3>Your Info</h3>
                      <div>
                        <KeyboardArrowUpIcon sx={{ color: "blue" }} />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                      }}>
                      <div>
                        <HomeIcon style={{ color: "blue" }} />
                      </div>
                      <Typography
                        sx={{ mb: 1.5, marginLeft: "15px" }}
                        color="text.secondary">
                        Rushikesh Chopade
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <div sx={{ mb: 1.5 }}>
                        (123) 456-7890 <br />
                        Primary
                      </div>
                      <Typography>
                        <KeyboardArrowDownIcon />
                        <CheckBox sx={{ color: "blue" }} />
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <div sx={{ mb: 1.5 }}>
                        rushikeshchopade@gmail.com <br />
                        Primary
                      </div>
                      <Typography>
                        <KeyboardArrowDownIcon />
                        <CheckBox sx={{ color: "blue" }} />
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
                <ArrowUpwardRoundedIcon style={{ fontSize: "40px", marginLeft:'20px' }} />
                <div>
                  After you click the add button, select all the information you
                  need on your resume.
                  <br /> Note : All information is take form your profile
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ResumeBuilderTutorial;
