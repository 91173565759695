import React, { useState, useEffect, useCallback } from 'react'
import { fetchSafetyIncidents } from '../../api/SafetyIncidentsApi';
import Loader from '../../components/Loader';
import SafetyIncidentsListSection from './SafetyIncidentsListSection';
import { Search as SearchIcon } from "@mui/icons-material";
import Placeholder from '../../components/Placeholder';
import { Stack } from '@mui/material';
import { BIBlue } from '../../assets/buildidColors';
import { Button, Container } from '@material-ui/core';
import SafetyIncidentsModal from './SafetyIncidentsModal';

export default function SafetyIncidents() {

    const [isSafetyIncidentsEmpty, setIsSafetyIncidentsEmpty] = useState(false)
    const [pageNr, setPageNr] = useState(1);
    const [safetyIncidentsModalOpen, setSafetyIncidentsModalOpen] = useState(false);


    const [safetyIncidentsId, setSafetyIncidentsId] = useState(0);
    const [safetyIncidentsData, setSafetyIncidentsData] = useState([]);
    const [editSafetyIncidentsData, setEditSafetyIncidentsData] = useState([]);


    const [safetyIncidentsList, setSafetyIncidentsList] = useState([])
    const [nrOfRecords, setNrOfRecords] = useState([]);


    const getSafetyIncidents = async () =>{
        const obj = {
            PageNr: pageNr,
            NrOfRecPerPage: 100,
            FullSearch: "",
            UserId: localStorage.userId,
            IncludeRecordNr: "true",
            TypeOfObjectReturned: "",
            FetchAllowedRecordsOnly: false,
            SearchList: [
                { createdBy: localStorage.userId }
            ],
            SortList: [
				{
					FieldName: "Id",
					Direction: "DESC"
				}
			]
        }
         await fetchSafetyIncidents(obj).then(res => {
            setNrOfRecords(res.nrOfRecords);
            setSafetyIncidentsList(res.data);
        }).catch((err) => {
            console.log("getting error while getting safety incidents list", err)
        })
    }

    useEffect(()=>{
       getSafetyIncidents()
    },[])


    const getUpdatedSafetyIncidentList = ()=>{
        console.log("updated data ")
        getSafetyIncidents()
    }


    const getEditSafetyIncidentsData = async (data) => {
        setSafetyIncidentsId(data?.id);
        setEditSafetyIncidentsData(data)
        setSafetyIncidentsModalOpen(true);
    }

    const getSafetyIncidentsRecords = useCallback(async () => {
        const obj = {
            PageNr: pageNr,
            NrOfRecPerPage: 100,
            FullSearch: "",
            UserId: localStorage.userId,
            IncludeRecordNr: "true",
            TypeOfObjectReturned: "",
            FetchAllowedRecordsOnly: false,
            SearchList: [
                { createdBy: localStorage.userId }
            ],
            SortList: [
				{
					FieldName: "Id",
					Direction: "DESC"
				}
			]
        }
        const res = await fetchSafetyIncidents(obj);
        if (res.nrOfRecords === 0)
            setIsSafetyIncidentsEmpty(true);
        else
            setIsSafetyIncidentsEmpty(false);
        return res;
    }, [pageNr]);

    useEffect(() => {
        setPageNr(1);
    }, [])

    return (
        <>

            <SafetyIncidentsModal
                isOpen={safetyIncidentsModalOpen}
                onClose={() => {
                    setPageNr(1); 
                    setSafetyIncidentsModalOpen(false)
                }}
                safetyIncidentsId={safetyIncidentsId}
                editSafetyIncidentsData={editSafetyIncidentsData}
                // setSafetyIncidentsModalOpen={() => {  setSafetyIncidentsModalOpen(true) }}
                // setSafetyIncidentsData={() => setEditSafetyIncidentsData([])}
                setPageNr={setPageNr}
                getUpdatedSafetyIncidentList={getUpdatedSafetyIncidentList}
            />

            <div className="page rewards-page">
                <div className="header">
                    <div className="page-content">
                        <h1>Safety Incidents</h1>
                    </div>
                </div> </div>
            <div className="page" style={{ alignItems: "center" }}>
                <Container>
                    <div className="resp-container-horizontal" style={{ margin: "15px 0px 0px 0px", display: 'flex', justifyContent: 'flex-end' }}>
                        <div className="flex-container-right" >
                            <div className="flex-container-horizontal" >
                                <div className="input-container">
                                    <Button
                                        variant="contained"
                                        style={{ background: BIBlue, color: 'white' }}
                                        onClick={() => { setSafetyIncidentsModalOpen(true) }}>
                                        Add Safety Incident
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Stack spacing={2} style={{ marginBottom: "15px" }}>
                        <Stack direction="row" spacing={2}>
                            <div style={{ flex: 2 }} >
                                {
                                    isSafetyIncidentsEmpty
                                        ?
                                        <Placeholder icon={<SearchIcon />} text="No results found." />
                                        :
                                        <SafetyIncidentsListSection
                                            setPageNr={() => setPageNr(1)}
                                            getData={getSafetyIncidentsRecords}
                                            getEditSafetyIncidentsData={getEditSafetyIncidentsData}
                                            safetyIncidentsList={safetyIncidentsList}
                                            nrOfRecords={nrOfRecords}
                                            getUpdatedSafetyIncidentList={getUpdatedSafetyIncidentList}
                                        />
                                }
                            </div>
                        </Stack>
                    </Stack>
                </Container>
            </div>
        </>
    )
}
