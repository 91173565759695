import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import { Card, CardContent, Menu, MenuItem } from "@material-ui/core";
import { MoreVert as MoreIcon } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import { BIBlue, BIRed } from "../../../assets/buildidColors";
import AlertModal from "../../../components/AlertModal";
import Chooselocation from "../../newsite/Chooselocation";
import PromptModal from "../../../components/PromptModal";
import { deleteSites } from "../../../api/SitesApi";
import ReportModal from "../../../components/ReportModal";

const NoLocationSitesModel = (props) => {
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(false);
  const [noLocationAlert, setNoLocationAlert] = useState(false);
  const [showChooselocation, setShowchooselocation] = useState(false);
  {
    /* Mahindra Code 28-mar-2024 */
  }
  const [chooseLocation, setChooselocation] = useState({ lat: 0, lng: 0 });
  const [deleteSiteConfirmModalOpen, setDeleteSiteConfirmModalOpen] =
    useState(false);
  const [reportModalOpenSearch, setReportModalOpenSearch] = useState(false);

  const [addNewSiteLocation, setAddNewSiteLocation] = useState(false);

  function handleSiteNameClick(site) {
    props?.setSelectedSiteDetails(site);
    props?.setShowSiteCardModal(true);
    props?.handleNoLocationModalClose();
  }

  function handleAddLocationClick() {
    setMoreMenuAnchorEl(false);
    setShowchooselocation(true);
  }

  function getSiteDetails(site) {
    props?.setSiteInfo(site);
  }

  async function handleDeleteSite() {
    try {
      const payloadObj = {
        UserId: localStorage.userId,
        ReturnRecordId: false,
        ReturnRecordError: true,
        DeleteList: [
          {
            Id: props?.siteInfo?.id,
            ModifiedBy: localStorage.userId,
          },
        ],
      };
      const deleteSiteRes = await deleteSites(payloadObj);
      if (deleteSiteRes.message === "OK") {
        setDeleteSiteConfirmModalOpen(false);
        await props.handleNoLocationModalClose(false);
        await props?.getNoLocationSiteList();
      }
    } catch (err) {
      console.log("Error occur while delete site", err);
    }
  }

  async function handleDeletebtnClick() {
    await setDeleteSiteConfirmModalOpen(true);
    await setMoreMenuAnchorEl(false);
  }

  {
    /* Mahindra Code 28-mar-2024 */
  }
  function handleNoLocationAlert() {
    setNoLocationAlert(true);
  }

  const addProject = () => {
    setMoreMenuAnchorEl(false);
    props?.addProjectToSite(props?.siteInfo);
  };
  {
    /* Mahindra Code 28-mar-2024 */
  }
  const handleReport = () => {
    setMoreMenuAnchorEl(false);
    props?.handleReportModal(props?.siteInfo);
  };

  return (
    <>
      {/* <Modal
        open={props.handleNoLocationModalOpen}
        onClose={() => props.handleNoLocationModalClose(false)}
        aria-labelledby="add-description-modal"
        aria-describedby="add-description-form"> */}
      {/* <Box className="modal-center" sx={{ width: 600 }}> */}
      {/* Mahindra Code 28-mar-2024 */}
      <div style={{ padding: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" className="no-location-message">
            No Location Sites
          </Typography>
          <IconButton
            style={{ color: "white", backgroundColor: "lightgrey" }}
            onClick={props.handleNoLocationModalClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Typography
          sx={{ paddingTop: "8px" }}
          variant="body2"
          className="no-location-message"
        >
          The following Sites are missing a location. if you know where they
          are, add it here.
        </Typography>
      </div>
      {props?.noLocationSiteList && (
        <>
          {/* Mahindra Code 28-mar-2024 */}
          <div className="noSite-list">
            <List sx={{ padding: 0 }}>
              {props?.noLocationSiteList?.map((item, index) => {
                return (
                  <ListItem
                    sx={{ padding: moreMenuAnchorEl ? "0px" : "0px" }}
                    key={index}
                    secondaryAction={
                      <>
                        {/* <IconButton onClick={() => setNoLocationAlert(true)}> */}
                        <IconButton onClick={() => handleNoLocationAlert()}>
                          <ErrorIcon
                            fontSize="small"
                            style={{ color: BIRed }}
                          />
                        </IconButton>
                        {props?.from === "experience" ||
                        props?.from === "companyProfile" ||
                        props?.from == "quiz" ? (
                          <></>
                        ) : (
                          <>
                            {!props?.showChooseOption ? (
                              <IconButton
                                onClick={(event) => {
                                  getSiteDetails(item);
                                  setMoreMenuAnchorEl(event.currentTarget);
                                }}
                              >
                                <MoreIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                          </>
                        )}
                      </>
                    }
                    disablePadding
                  >
                    <ListItemButton onClick={() => handleSiteNameClick(item)}>
                      <ListItemText
                        primary={item?.siteName || item?.name || "Unnamed Site"}
                        secondary={
                          item?.address ? item?.address : "No Location"
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </div>
          <Menu
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            style={{ left: "50px" }}
            anchorEl={moreMenuAnchorEl}
            open={Boolean(moreMenuAnchorEl)}
            onClose={(event) => {
              setMoreMenuAnchorEl(null);
            }}
          >
            <MenuItem
              onClick={() => handleAddLocationClick()}
              style={{ color: BIBlue }}
              dense
            >
              Add location
            </MenuItem>
            <MenuItem
              onClick={() => addProject()}
              style={{ color: BIBlue }}
              dense
            >
              Add Project
            </MenuItem>
            <MenuItem
              onClick={() => handleDeletebtnClick()}
              style={{ color: BIRed }}
              dense
            >
              Delete Site
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleReport();
              }}
              style={{ color: BIRed }}
              dense
            >
              Report
            </MenuItem>
          </Menu>
        </>
      )}

      {/* </Box> */}
      {/* </Modal> */}

      <AlertModal
        isOpen={noLocationAlert}
        onClose={() => setNoLocationAlert(false)}
        title={
          "This project will not be visible to others until a location is added."
        }
      />

      <Chooselocation
        chooseLocation={chooseLocation}
        siteInfo={props?.siteInfo}
        showChooselocation={showChooselocation}
        setShowchooselocation={setShowchooselocation}
        setChooselocation={setChooselocation}
        getNoLocationSiteList={props?.getNoLocationSiteList}
        handleNoLocationModalClose={props?.handleNoLocationModalClose}
        noLocationSiteModal={true}
        currentLocation={props?.currentLocation}
        addNewSiteLocation={addNewSiteLocation}
        setAddNewSiteLocation={setAddNewSiteLocation}
        getSiteInfo={props?.getSiteInfo}
      />

      <PromptModal
        isOpen={deleteSiteConfirmModalOpen}
        onClose={() => {
          setMoreMenuAnchorEl(false);
          setDeleteSiteConfirmModalOpen(false);
        }}
        title="Are you sure you want to delete this site?"
        desc="This action cannot be undone."
        redButtonText="Delete"
        onRedButtonClick={() => {
          handleDeleteSite();
        }}
      />
      {/* Mahindra Code 28-mar-2024 */}

      {reportModalOpenSearch && (
        <ReportModal
          name={props?.siteInfo?.name}
          isOpen={reportModalOpenSearch}
          onClose={() => {
            setReportModalOpenSearch(false);
          }}
          recordId={props?.siteInfo?.id}
        />
      )}
    </>
  );
};

export default NoLocationSitesModel;
