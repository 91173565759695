import React from 'react'
import {
    Modal, Stack, Typography, Paper, IconButton,
    TextField, Button, Card, InputAdornment, CardContent, CardActions,
    ToggleButton, ToggleButtonGroup, Tabs, Checkbox,
    Tab
} from '@mui/material'
import { BIBlue, BILightRed, BIRed, BIYellow } from '../../assets/buildidColors'
import NorthWestIcon from '@mui/icons-material/NorthWest';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthIcon from '@mui/icons-material/South';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import AddIcon from '@mui/icons-material/Add';
import { MoreVert as MoreIcon } from "@mui/icons-material";
import { Edit as EditIcon } from "@material-ui/icons";
import ErrorIcon from '@mui/icons-material/Error';
import GroupIcon from '@mui/icons-material/Group';
import '../../assets/css/Tickets.css'


const TicketTutorial = (props) => {

    const tabsArray = ["my tickets", "shared with me"];

    return (
        <>
            <Modal
                open={props?.tutorialIndex == 0 ? false : true}
                onClose={props.onClose}
                style={{
                    backgroundColor: 'rbga(0,0,0,0.1)'
                }}
            >
                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', height: '100%' }} onClick={props.onPress}>
                    {
                        props.tutorialIndex === 1 && <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <Stack sx={{ textAlign: 'center', color: 'white' }}>
                                <Typography variant="h6">
                                    Tickets
                                </Typography>
                                <Typography variant="subtitle1">
                                    Where you can save and share licenses that you have with others
                                </Typography>
                                <Typography variant="body1" >
                                    <span style={{ color: BIBlue }}> (Tap anywhere to continue)</span>
                                </Typography>
                            </Stack>
                        </div>
                    }


                    {
                        props.tutorialIndex === 2 &&
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                height: '100%',
                            }}
                        >
                            <div className='ticket-tutorial-2-body'>
                                <Stack className='ticket-tutorial-2-stack'>
                                    <Tabs
                                        textColor="primary"
                                        indicatorColor="primary"
                                        value={"shared with me"}
                                        className='ticket-tutorial-2-tabs'
                                        sx={{ marginLeft: '190px' }}
                                        onChange={() => { }}>
                                        {
                                            tabsArray.map((tab, index) => (
                                                <Tab key={index} value={tab} label={tab} />
                                            ))
                                        }
                                    </Tabs>
                                </Stack>
                                <div style={{ marginLeft: '25%', color: 'white', marginTop: '10px' }}>
                                    <NorthWestIcon style={{ fontSize: '40px' }} />
                                    <div>
                                        Any tickets that are shared with you are seen in the SHARED WITH ME tab
                                    </div>
                                </div>
                            </div>
                        </div>
                    }



                    {
                        props.tutorialIndex === 3 &&
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                            }}
                            
                        >
                            <div className='ticket-tutorial-3-body'>
                                <Stack direction="row" spacing={10}>
                                    <div className="ticket-tutorial-3-ticket-card"
                                        style={{ backgroundColor: BILightRed, borderRadius: '10px' }}
                                    >
                                        <div className="ticket-card-image">
                                            <img style={{ display: "none" }} alt="" />
                                        </div>

                                        <div className="flex-container-horizontal">
                                            <div className="input-container">
                                                <Checkbox
                                                    style={{ color: BIBlue }}
                                                    onChange={() => { }}
                                                    checked={false}
                                                    disable
                                                />
                                            </div>

                                            <div className="flex-container-left" >
                                                <div className="content-container" style={{ padding: "10px", }}>
                                                    <p><b></b></p>
                                                    <p><b></b></p>
                                                    <p style={{ color: BIRed }}></p>
                                                </div>
                                            </div>

                                            <div className="flex-container-right">
                                                <div className="input-container">

                                                    <IconButton
                                                        style={{ color: 'red' }}
                                                        size="small"
                                                    >
                                                        <ErrorIcon fontSize="small" />
                                                    </IconButton>

                                                    <IconButton
                                                        color="primary"
                                                        size="small"
                                                    >
                                                        <GroupIcon fontSize="small" />
                                                    </IconButton>

                                                    <IconButton
                                                        color="primary"
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ticket-tutorial-3-ticket-card"
                                        style={{ backgroundColor: 'white', borderRadius: '10px',marginLeft:'28px' }}
                                    >
                                        <div className="ticket-card-image">
                                            <img style={{ display: "none" }} alt="" />
                                        </div>

                                        <div className="flex-container-horizontal">
                                            <div className="input-container">
                                                <Checkbox
                                                    style={{ color: BIBlue }}
                                                    onChange={() => { }}
                                                    checked={false}
                                                    disable
                                                />
                                            </div>

                                            <div className="flex-container-left" >
                                                <div className="content-container" style={{ padding: "10px" }}>
                                                    <p><b></b></p>
                                                    <p><b></b></p>
                                                    <p style={{ color: BIRed }}></p>
                                                </div>
                                            </div>

                                            <div className="flex-container-right">
                                                <div className="input-container">
                                                    <IconButton
                                                        color="primary"
                                                        size="small"
                                                    >
                                                        <GroupIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton
                                                        color="primary"
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Stack>
                                <Stack direction='row' justifyContent={'center'} sx={{ marginTop: '10px' }}>
                                    <NorthWestIcon style={{ fontSize: '40px' }} sx={{ marginRight: '20%' }} />
                                    <NorthEastIcon style={{ fontSize: '40px' }} />
                                </Stack>
                                <div>
                                    <Typography variant="subtitle1">
                                        Watch for these indicators to signify when your ticket expires or shared with other users
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    }


                    {
                        props.tutorialIndex === 4 &&
                        <div
                            style={{ height: '100%', display: 'flex', alignItems: 'center' }}
                        >
                            <div className='ticket-tutorial-4-body'>
                                <NorthWestIcon style={{ fontSize: '40px' }} />
                                <div>
                                    Once the tickets are created, click and hold to select and share multiple tickets at a time
                                    <br /> This icon indicates that the ticket has beem shared
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </Modal>
        </>
    )
}

export default TicketTutorial