import React, { useState } from "react";
import { Autocomplete as GooglePlacesAutocomplete } from "@react-google-maps/api";
import { BIBlue, BIRed, BIWhite } from "../../assets/buildidColors";
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons";
import {
  Button,
  TextField,
  Card,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import "../../assets/css/Sites.css";
import SiteIssuesList from "./SiteIssuesList";
import ViewListIcon from "@mui/icons-material/ViewList";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import MapIcon from "@mui/icons-material/Map";
import NoLocationSitesModel from "../sites/dilogs/NoLocationSitesModel";

const TabOptions = (props) => {
  const { setSearchTerm, searchTerm } = props;

  const [autocomplete, setAutocomplete] = useState(null);
  // const [showSiteIssuesList, setShowSiteIssuesList] = useState(false);
  const [noLoactionSiteModalFlag, setNoLoactionSiteModalFlag] = useState(false);

  const onLoadAuto = (newInstance) => {
    // Access the suggestion list container and add a custom class
    setAutocomplete(newInstance);
  };

  const handleSearch = async () => {
    let place = autocomplete.getPlace();
    // If place is a Autocomplete Place, then it will have a geometry property
    if (place?.geometry) {
      setSearchTerm(place.formatted_address);
      props.handleSearch(place);
    }
  };

  const onFilterClick = (value) => {
    props.onFilterClick(value !== props.activeTab ? value : "");
  };

  const handleShowSiteList = () => {
    if (props?.showSiteIssuesList) props?.setShowSiteIssuesList(false);
    else {
      props?.setShowSiteIssuesList(true);
      props?.getNoLocationSiteList();
    }
  };

  return (
    <>
      {/* Mahindra Code 28-mar-2024 */}
      <Card
        raised
        className={
          props?.from !== "changeSiteOnMap" &&
          props?.fromSignUpPage !== "signUpPage"
            ? "site-overlay-container"
            : props?.fromSignUpPage == "signUpPage"
            ? "site-overlay-container3"
            : "site-overlay-container2"
        }
        style={{ margin: "8px" }}
      >
        {noLoactionSiteModalFlag ? (
          <NoLocationSitesModel
            handleNoLocationModalClose={() => setNoLoactionSiteModalFlag(false)}
            noLocationSiteList={props?.noLocationSiteList}
            setSelectedSiteDetails={props?.setSelectedSiteDetails}
            setShowSiteCardModal={props?.setShowSiteCardModal}
            siteInfo={props?.siteInfo}
            setSiteInfo={props?.setSiteInfo}
            getNoLocationSiteList={props?.getNoLocationSiteList}
            addProjectToSite={props?.addProjectToSite}
            handleReportModal={props?.handleReportModal}
            showChooseOption={props?.showChooseOption ?? false}
            currentLocation={props?.currentLocation}
            getSiteInfo={props?.getSiteInfo}
            from={props?.from}
            fromQuestion={props?.fromQuestion}
          />
        ) : (
          <>
            <div className="flex-container-vertical" style={{ flex: 1 }}>
              {/* Mahindra Code 28-mar-2024 */}
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "8px",
                  margin: props?.from !== "changeSiteOnMap" ? "8px" : "3px",
                  marginBottom:
                    props?.from !== "changeSiteOnMap" ? "0px" : "3px",
                }}
              >
                <GooglePlacesAutocomplete
                  onLoad={(newInstance) => onLoadAuto(newInstance)} // Sets instance of Autocomplete to be referenced
                  onPlaceChanged={handleSearch}
                  options={{
                    componentRestrictions: { country: ["US", "CA", "GB"] }, // Restrict to USA, Canada, and UK
                  }}
                >
                  <TextField
                    variant="outlined"
                    placeholder="Search map"
                    className="search-location"
                    fullWidth
                    size="small"
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {
                            // Only show "X" button when field not empty
                            searchTerm !== "" ? (
                              <IconButton onClick={() => setSearchTerm("")}>
                                <CloseIcon />
                              </IconButton>
                            ) : (
                              <></>
                            )
                          }
                          <IconButton color="primary" onClick={handleSearch}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </GooglePlacesAutocomplete>
              </div>
              {/* Mahindra Code 28-mar-2024 */}
              {props?.from !== "changeSiteOnMap" ? (
                <div
                  className="flex-container-horizontal"
                  style={{
                    margin: "0px 8px",
                    justifyContent:
                      props?.fromSignUpPage == "signUpPage" ? "flex-end" : "",
                  }}
                >
                  {props?.from !== "changeSiteOnMap" &&
                  props?.fromSignUpPage !== "signUpPage" ? (
                    <>
                      <div
                        className="input-container"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      >
                        <Button
                          style={{ borderRadius: "20px", fontSize: "13px" }}
                          color={
                            props.activeTab === "WorkedSites"
                              ? "primary"
                              : "default"
                          }
                          variant={"contained"}
                          onClick={() => onFilterClick("WorkedSites")}
                        >
                          My Sites
                        </Button>
                      </div>
                      {/* Mahindra Code 28-mar-2024 */}
                      <div
                        className="input-container"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      >
                        <Button
                          style={{ borderRadius: "20px", fontSize: "13px" }}
                          color={
                            props.activeTab === "FollowSites"
                              ? "primary"
                              : "default"
                          }
                          variant={"contained"}
                          onClick={() => onFilterClick("FollowSites")}
                        >
                          Following
                        </Button>
                      </div>
                      <div
                        className="input-container"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      >
                        <Button
                          style={{ borderRadius: "20px", fontSize: "13px" }}
                          color={
                            props.activeTab === "UnnamedSites"
                              ? "primary"
                              : "default"
                          }
                          variant={"contained"}
                          onClick={() => onFilterClick("UnnamedSites")}
                        >
                          Unnamed
                        </Button>
                      </div>
                    </>
                  ) : null}
                  <div
                    className="input-container"
                    style={{ marginLeft: "7px" }}
                  >
                    <Button
                      style={{ minWidth: "auto" }}
                      onClick={handleShowSiteList}
                      color="primary"
                      size="medium"
                    >
                      {/* {showSiteIssuesList ? <MapIcon style={{ color: BIBlue }} /> : <FormatListBulletedIcon style={{ color: BIBlue }} />} */}
                      {props?.showSiteIssuesList ? "Hide List" : "View List"}

                      {props?.noLocationSiteList?.length > 0 && (
                        <div
                          style={{
                            width: "8px",
                            height: "8px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            position: "absolute",
                            top: "4px",
                            right: "4px",
                          }}
                        />
                      )}
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>

            {/* Mahindra Code 28-mar-2024 */}
            {props?.showSiteIssuesList && (
              <SiteIssuesList
                siteList={props?.siteList}
                setSelectedSiteDetails={props?.setSelectedSiteDetails}
                setShowSiteCardModal={props?.setShowSiteCardModal}
                noLocationSiteList={props?.noLocationSiteList}
                siteInfo={props?.siteInfo}
                setSiteInfo={props?.setSiteInfo}
                getNoLocationSiteList={props?.getNoLocationSiteList}
                addProjectToSite={(obj) => {
                  // setShowSiteIssuesList(false);
                  props?.addProjectToSite(obj);
                }}
                handleReportModal={(obj) => {
                  // setShowSiteIssuesList(false);
                  props?.handleReportModal(obj);
                }}
                handleAddSiteName={(obj) => {
                  // setShowSiteIssuesList(false);
                  onFilterClick("");
                  props?.handleAddSiteName(obj);
                }}
                // handleReportModal={props?.handleReportModal}
                showChooseOption={props?.showChooseOption ?? false}
                currentLocation={props?.currentLocation}
                getSiteInfo={props?.getSiteInfo}
                setNoLoactionSiteModalFlag={setNoLoactionSiteModalFlag}
                from={props?.from}
                fromQuestion={props?.fromQuestion}
                fromSignUpPage={props?.fromSignUpPage}
              />
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default TabOptions;
