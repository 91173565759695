import { Box, Button, DialogContent, DialogTitle, Modal } from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import placeholder from "../../../assets/images/project-profile-placeholder-thumbnail.png";
import React, { useState } from "react";
import Viewer from "react-viewer";
import PictureViewer from "../../../components/PictureViewer";

const ImageDialog = (props) => {
  const [viewerVisible, setViewerVisible] = useState(false);
  const [viewerIndex, setViewerIndex] = useState(0);

  const openViewer = (index) => {
    setViewerIndex(index);
    setViewerVisible(true);
    props.setImageModalOpen(false)
  };

  return (
    <>
      <Modal
        open={props.imageModalOpen}
        onClose={() => props.setImageModalOpen(false)}
        maxWidth="lg">
        <Box className="modal-center" sx={{ width: 575 }}>
          <div className="modal-card" >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <DialogTitle>{props.siteName}</DialogTitle>
              <Button onClick={() => props.setImageModalOpen(false)}>
                {" "}
                <CloseIcon />
              </Button>
            </Box>

            <DialogContent sx={{ paddingTop: '0px' }}>
              <div style={{ display: "flex", flexWrap: "wrap", maxHeight: "450px", overflow: "auto", gap: '10px', }}>
                {props.projectImageArray.map((item, index) => (
                  <div key={index} onClick={() => openViewer(index)}>
                    <img
                      src={item.pictureUrl || placeholder}
                      alt={`Image ${index}`}
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                ))}
              </div>
            </DialogContent>
          </div>
        </Box>
      </Modal>

      {props?.projectImageArray ?
        <PictureViewer
          isOpen={viewerVisible}
          onClose={() => {
            setViewerVisible(false)
            props.setImageModalOpen(true)
          }}
          pictures={props.projectImageArray.map(pic => (
            {
              src: pic.pictureUrl,
              alt: ""
            }
          ))}
          viewerIndex={viewerIndex}
        /> : ""}
    </>
  );
};

export default ImageDialog;
