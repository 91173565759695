import { restGet, restPost } from "./RestClient";
import { headers } from "./UserApi";

export async function fetchSafetyObservations(data) {
    const res = await restPost("Sites/FetchSafetyObservations", data, headers);
    return res;
}

export async function saveSafetyObservations(data) {
    const res = await restPost("Sites/SaveSafetyObservations", data, headers);
    return res;
}

export async function deleteSafetyObservations(data) {
    const res = await restPost("Sites/DeleteSafetyObservations", data, headers);
    return res;
}

export async function deleteSafetyObservationsPictures(data) {
    const res = await restPost("Sites/DeleteSafetyObservationsPictures", data, headers);
    return res;
}


export async function saveSafetyObservationsPictures(data, form_headers) {
    const res = await restPost("Sites/SaveSafetyObservationsPictures", data, form_headers);
    return res;
}

export async function searchUsersMembers() {
    const res = await restGet(`Users/SearchUsersMembers/${localStorage.userId}/${0}/`, headers);
    return res;
}

export async function fetchSites(data) {
    const res = await restPost("Sites/FetchSites", data, headers);
    return res;
}


