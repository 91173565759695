import React, { useState, useEffect, useCallback, useRef } from "react";
import {
    Checkbox,
    Divider,
    IconButton,
    ListItemText,
    Typography,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Box, Button, Stack, Modal, ListItemButton, CircularProgress } from "@mui/material";
import ProductSearchField from "./ProductSearchField";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchTrades } from "../api/TradesApi";


const SelectTradeModal = (props) => {

    const [pageNo, setPageNo] = useState(1);
    const [list, setList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [noData, setNoData] = useState();

    // 20-03-2024 chenges Yash

    const [allDataLoaded, setAllDataLoaded] = useState(false);
    // 20-03-2024 chenges Yash

    useEffect(() => {
        getData(1);
    }, []);

    async function getData(pageNo, searchTerm) {
        try {
            const res = await fetchTrades({
                PageNr: pageNo,
                IncludeRecordNr: true,
                NrOfRecPerPage: 20,
                SearchList: [{ name: searchTerm }],
                SortList: [{ FieldName: "Name", Direction: "ASC" }],
            });
            let finalArray = res?.data ?? [];

            const noOfRecords = res?.nrOfRecords;
            setNoData(noOfRecords);
            if (pageNo > 1) {
                setTimeout(() => {
                    setList((prevProducts) => [...prevProducts, ...finalArray]);
                }, 500);
            } else {
                setList(finalArray);
            }
            // 20-03-2024 chenges Yash
            if (res?.data?.length === 0) {
                setAllDataLoaded(true);
            }
            // 20-03-2024 chenges Yash
            setPageNo(pageNo + 1);
        } catch (e) {
            console.log("Error while getting data :: ", e);
        }
    }

    const handleCloseModal = () => {
        props?.onClose();
    };


    const onItemClick = (item) => {
        props?.onItemClick(item);
    };

    return (
        <>
            <Modal
                aria-labelledby="add-description-modal"
                aria-describedby="add-description-form"
                open={props?.open}
                onClose={handleCloseModal}>
                <Box className="modal-center" sx={{ width: 600 }}>
                    <div className="modal-card" style={{ padding: "15px" }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mb: 2,
                            }}>
                            <Typography variant="h6">Select Trade</Typography>
                            <IconButton onClick={handleCloseModal} style={{ color: "blue" }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Stack spacing={2} padding={"20px"}>
                            <ProductSearchField
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e);
                                    getData(1, e);
                                }}
                                isFocused={() => { }}
                                placeholder={"Search Trade"}
                            />
                        </Stack>
                        <p>{noData} results</p>
                        <Divider style={{ margin: "10px" }} />
                        <div id="scrollableDiv_trade" style={{ height: 300, overflow: "auto" }}>
                            <InfiniteScroll
                                dataLength={
                                    list?.length
                                }
                                next={() => getData(pageNo, searchTerm)}
                                // 20-03-2024 chenges Yash
                                hasMore={!allDataLoaded}
                                loader={
                                    allDataLoaded ? null : (
                                        <h4 style={{ display: "flex", justifyContent: "center" }}>
                                            <CircularProgress />
                                        </h4>
                                    )
                                }
                                // 20-03-2024 chenges Yash
                                scrollableTarget="scrollableDiv_trade">
                                {list.map((option) => (
                                    <ListItemButton
                                        key={option.id}
                                        onClick={() =>
                                            onItemClick(option)
                                        }>
                                        <ListItemText>
                                            <b>{option?.name}</b>
                                        </ListItemText>
                                    </ListItemButton>
                                ))}
                            </InfiniteScroll>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default SelectTradeModal