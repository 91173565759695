import React, { useEffect, useState } from "react";
import { Button, TextField, IconButton } from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AlertModal from "../../components/AlertModal";
import DialogContentText from '@mui/material/DialogContentText';
import ChangePasswordModal from "./ChangePasswordModal";
import { BIBlack, BIRed } from "../../assets/buildidColors";
import { userLogin } from "../../api/UserApi";
import Bowser from "bowser";
import packageInfo from '../../../package.json'
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js"
import { sha256 } from "crypto-hash";
import "../../assets/css/Settings.css";
let userEmail = '';
let bowserRes = Bowser.getParser(window.navigator.userAgent)

function ConfirmChangePasswordModal(props) {
	const [isShowCurrentPassword, setIsShowCurrentPassword] = React.useState(false);
	const [currentPassword, setCurrentPassword] = React.useState('');
	const [differentPasswordAlert, setDifferentPasswordAlert] = useState(false);
	const [isIncorrectPassword, setIsIncorrectPassword] = useState(false);
	const [changePasswordModal, setChangePasswordModal] = useState(false);

	const hash = (str) => {
		return sha256(str);
	};

	async function getDeviceName() {
		const visitorId = await getCurrentBrowserFingerPrint();
		return visitorId;
	}

	useEffect(() => {
		userEmail = localStorage.getItem('primaryEmail');
	}, [])

	// Reset form states on modal open/close
	useEffect(() => {
		setCurrentPassword('');
		setIsShowCurrentPassword(false);
		setIsIncorrectPassword(false);
	}, [props.isOpen]);

	async function handleSubmitClick(event) {
		event.preventDefault();

		try {
			if (userEmail === "" || currentPassword === "")
				throw new Error("Password field is required!")

			const pwd = await hash(currentPassword);
			const deviceName = await getDeviceName();
			const loginObj = {
				Email: userEmail,
				Password: pwd,
				NotifyToken: "",
				AppName: "BuilID-Web",
				AppVersion: packageInfo?.version,
				Browser: bowserRes.parsedResult.browser?.name,
				BrowserVersion: bowserRes.parsedResult.browser?.version,
				Platform: bowserRes.parsedResult.os.name,
				PlatformVersion: "NA",
				DeviceName: deviceName
			}
			let loginResult = await userLogin(loginObj);
			await localStorage.setItem("token", loginResult?.data?.token ?? null);

			if (loginResult?.status === 200) {
				props.onClose();
				setChangePasswordModal(true);
			} else setIsIncorrectPassword(true);

		}
		catch (error) {
			(error.message === "Request failed with status code 400")
				? alert("Wrong email or password!")
				: setIsIncorrectPassword(true);
		}
	}

	return (
		<div>
			<ChangePasswordModal
				isOpen={changePasswordModal}
				onClose={() => setChangePasswordModal(false)} />

			<AlertModal
				isOpen={differentPasswordAlert}
				onClose={() => setDifferentPasswordAlert(false)}
				title={"Password Error"}
				body={"New password must be different from current password."} />

			<Dialog
				open={props.isOpen}
				onClose={() => {
					props.onClose();
					setCurrentPassword('');
					setIsIncorrectPassword(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">

				<DialogTitle style={{ color: BIBlack }} id="alert-dialog-title">
					Change Password
				</DialogTitle>

				<DialogContent>
					<div style={{ width: "100%" }}>
						<div className="input-container">
							<DialogContentText id="alert-dialog-description">
								Please enter your password to continue.
							</DialogContentText>
						</div>
						<div className="input-container">
							<TextField
								variant="outlined"
								fullWidth
								placeholder="Password"
								error={isIncorrectPassword}
								value={currentPassword}
								type={isShowCurrentPassword ? "text" : "password"}
								onChange={(e) => {
									setCurrentPassword(e.target.value)
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												tabIndex="-1"	// Unfocusable button
												onClick={() => setIsShowCurrentPassword(!isShowCurrentPassword)}>
												{isShowCurrentPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</div>

						<div className="input-container">
							{isIncorrectPassword ?
								<DialogContentText id="alert-dialog-description" style={{ color: BIRed }}>
									Incorrect Password.
								</DialogContentText>
								: <></>
							}
						</div>
					</div>
				</DialogContent>

				<DialogActions>
					<Button
						onClick={() => {
							props.onClose();
							setCurrentPassword('');
							setIsIncorrectPassword(false);
						}}
						color="primary"> Cancel </Button>

					<Button
						variant="contained"
						color="primary"
						onClick={handleSubmitClick}
					>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default ConfirmChangePasswordModal;