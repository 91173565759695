import { restGet, restPost} from "./RestClient";
import { headers, form_headers } from "./UserApi";

// let headers = {
// 	"Content-Type": "application/json",
// 	"Accept": "application/json",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }

// let form_headers = {
// 	"Content-Type": "multipart/form-data",
// 	"Accept": "application/json",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }


// Get Posts for a specific group
// appId = 7 for Trades
export async function getPosts(appId, appRecordId, pageNr, nrOfRecPerPage){
	const res = await restGet("posts/getPosts/" + localStorage.userId + "/" + appId + "/" + appRecordId + "/" + pageNr + "/" + nrOfRecPerPage, headers);
	return res;
}

// Special getPosts that retrieves all Posts from every group the user is a part of
// tab is a string argument for filtering for specific group types, eg. "All", "Project"
export async function getPostsNewsFeed(tab, pageNr, nrOfRecPerPage){	
	const res = await restGet("posts/GetPostsNewsFeed/" + localStorage.userId + "/" + tab + "/" + pageNr + "/" + nrOfRecPerPage, headers);
	return res;
}

export async function getPostComments(postId, pageNr, nrOfRecPerPage,appId){	
	const res = await restGet("posts/GetPostComments/" + localStorage.userId + "/" + postId + "/" + pageNr + "/" + nrOfRecPerPage + "/" + appId, headers);
	return res;
}

export async function savePostComment(data){	
	const res = await restPost("posts/SavePostComment", data, form_headers);
	return res;
}

export async function savePost(data){	
	const res = await restPost("posts/SavePosts", data, headers);
	return res.data;
}

export async function savePostsUsers(data){	
	const res = await restPost("posts/SavePostsUsers", data, headers);
	return res.data;
}

export async function savePostPicture(data){	
	const res = await restPost("posts/savePostPicture", data, form_headers);
	return res;
}

export async function postSettings(data){	
	const res = await restPost("posts/postsUserSettings", data, headers);
	return res;
}