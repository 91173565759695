import React, { useState, useEffect } from "react";
import { isoToMonthDateYear } from "../../assets/helpers";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  AccordionSummary,
} from "@material-ui/core";
import {
  getUserRewardActivities,
  getUserRewardActivities2,
} from "../../api/RewardsApi";
import BuilderBucksBalance from "../../components/BuilderBucksBalance";
import Store from "./Rewards";
import DateSelector from "../../components/DateSelector";
import { Close as CloseIcon } from "@material-ui/icons";
import UserUsages from "../../components/userUsages/UserUsages";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { BIGrey, BILightGrey } from "../../assets/buildidColors";
import InfiniteScroll from "react-infinite-scroll-component";

function AccountActivity(props) {
  const [activities, setActivities] = useState([]); // Unmodified activities
  const [accountActivity, setAccountActivity] = useState([]); // Displayed activites
  const [activeTab, setActiveTab] = useState("ALL");
  const [searchString, setSearchString] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedId, setExpandedId] = useState(null);
  const [pageNo, setPageNo] = useState(1);

  // On page load, retrieve user's account activity from API
  useEffect(() => {
    UserUsages("AccountActivity");
    getData("ALL");
  }, []);

  // On searchString change, filter account activity table
  useEffect(() => {
    setAccountActivity(
      activities.filter((activity) =>
        activity.activityDescription
          .toLowerCase()
          .includes(searchString.toLowerCase())
      )
    );
  }, [searchString]);

  const removeDuplicates = (originalArray, prop) => {
    const newArray = [];
    const lookupObject = {};
    for (const i in originalArray) {
      lookupObject[moment(originalArray[i][prop]).format("MMM DD, YYYY")] =
        originalArray[i];
    }
    for (const i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  };

  const getData = async (string) => {
    try {
      const result = await getUserRewardActivities2(
        localStorage.userId,
        string,
        1,
        20
      );
      const newResult = JSON.parse(JSON.stringify(result));
      const allUnique = removeDuplicates(newResult, "activityDate");
      let a = [];
      const allActivities = allUnique.map((unique) => {
        a = unique;
        a["data"] = [];
        a["titleDate"] = unique.activityDate;
        result.map((data) => {
          if (
            moment(data.activityDate).format("MMM DD, YYYY") ===
            moment(unique.activityDate).format("MMM DD, YYYY")
          ) {
            const obj = { ...data, isExpanded: false };
            a["data"].push(obj);
          }
        });
        return a;
      });
      setAccountActivity(allActivities);
      setActivities(allActivities);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Error while getting the account activity list :: ", err);
    }
  };

  async function handleFromDateChange(date) {
    if (date) {
      await setFromDate(date.format().substring(0, 10));

      if (toDate) {
        const newDisplay = accountActivity.filter(
          (activity) =>
            Date.parse(activity.activityDate) >= Date.parse(date) - 86400000 &&
            Date.parse(activity.activityDate) <= Date.parse(toDate)
        );
        setAccountActivity(newDisplay);
      } else {
        const newDisplay = accountActivity.filter(
          (activity) =>
            Date.parse(activity.activityDate) >= Date.parse(date) - 86400000
        );
        setAccountActivity(newDisplay);
      }
    } else {
      setAccountActivity(activities);
      setFromDate(null);
    }
  }

  async function handleToDateChange(date) {
    if (date) {
      await setToDate(date.format().substring(0, 10));
      if (fromDate) {
        const newDisplay = accountActivity.filter(
          (activity) =>
            Date.parse(activity.activityDate) >=
              Date.parse(fromDate) - 86400000 &&
            Date.parse(activity.activityDate) <= Date.parse(date)
        );
        setAccountActivity(newDisplay);
      } else {
        const newDisplay = accountActivity.filter(
          (activity) => Date.parse(activity.activityDate) <= Date.parse(date)
        );
        setAccountActivity(newDisplay);
      }
    } else {
      setAccountActivity(activities);
      setToDate(null);
    }
  }

  const onTabChange = async (type) => {
    setActiveTab(type);
    setLoading(true);
    await getData(type);
  };

  const getNewDate = (date) => {
    if (!moment.isMoment(date)) date = moment(date);
    if (date.isSame(moment(), "day")) {
      return "Today";
    } else if (date.isSame(moment().subtract(1, "d"), "day")) {
      return "Yesterday";
    } else {
      return moment.parseZone(date).local().format("MMMM DD, YYYY");
    }
  };

  const renderActivities = (activities) => {
    return (
      <>
        <div style={{ background: "white", padding: "10px" }}>
          <Typography style={{ color: BIGrey, marginBottom: "10px" }}>
            {getNewDate(moment(activities.titleDate))}
          </Typography>
          {activities?.data?.map((item, index) => (
            <Accordion
              expanded={expandedId === item?.id}
              sx={{ border: "none", boxShadow: "none" }}
              onClick={() => {
                setExpandedId((preValue) =>
                  preValue !== item?.id ? item?.id : null
                );
              }}
              key={index}>
              <AccordionSummary
                expandIcon={
                  expandedId === item?.id ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                <Typography
                  sx={{
                    width: "90%",
                    flexShrink: 0,
                    fontWeight: expandedId == item.id ? "bold" : "normal",
                  }}>
                  {item.appName == "Questions"
                    ? "Answered a question"
                    : item.appName == "Purchase"
                    ? "Entered to Win"
                    : item.title}
                </Typography>
                <Typography
                  sx={{
                    width: "100%",
                    textAlign: "end",
                    marginRight: "10px",
                    color: item.bucks > 0 ? "#008000" : "#FF0000",
                  }}>
                  {item.bucks > 0 ? `+${item.bucks}` : item.bucks}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: BILightGrey }}>
                <Typography>
                  {item?.activityDescription
                    ?.replaceAll("<", "")
                    ?.replaceAll(">", "")}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        <Divider />
      </>
    );
  };

  const updatePageNumber = (callback) => {
    setPageNo((prevPageNr) => prevPageNr + 1);
    callback(pageNo + 1);
  };

  const getNextAccountActivityData = async (pageNr) => {
    try {
      const result = await getUserRewardActivities2(
        localStorage.userId,
        activeTab,
        pageNr,
        20
      );
      const newResult = JSON.parse(JSON.stringify(result));
      const allUnique = removeDuplicates(newResult, "activityDate");
      let a = [];
      const allActivities = allUnique.map((unique) => {
        a = unique;
        a["data"] = [];
        a["titleDate"] = unique.activityDate;
        result.map((data) => {
          if (
            moment(data.activityDate).format("MMM DD, YYYY") ===
            moment(unique.activityDate).format("MMM DD, YYYY")
          ) {
            const obj = { ...data, isExpanded: false };
            a["data"].push(obj);
          }
        });
        return a;
      });
      setAccountActivity((preValue) => [...preValue, ...allActivities]);
      setActivities((preValue) => [...preValue, ...allActivities]);
    } catch (err) {
      console.log("Error while getting the account activity list :: ", err);
    }
  };
  return (
    <>
      <Store activeTab={props?.activeTab ?? ""} />
      <div className="page">
        <div className="page-content">
          <BuilderBucksBalance />
          <div className="activity-cards-container">
            <div className="flex-container-horizontal activity-flex">
              <div
                className="flex-container-left"
                style={{ display: "flex", flexDirection: "row" }}>
                <div className="rewards-input-container">
                  <Button
                    variant={activeTab === "ALL" ? "contained" : "outlined"}
                    color="primary"
                    onClick={() => onTabChange("ALL")}>
                    All
                  </Button>
                </div>
                <div className="rewards-input-container">
                  <Button
                    variant={
                      activeTab === "Earnings" ? "contained" : "outlined"
                    }
                    color="primary"
                    onClick={() => onTabChange("Earnings")}>
                    Earnings
                  </Button>
                </div>
                <div className="rewards-input-container">
                  <Button
                    variant={
                      activeTab === "Spending" ? "contained" : "outlined"
                    }
                    color="primary"
                    onClick={() => onTabChange("Spending")}>
                    Spendings
                  </Button>
                </div>

                <div className="rewards-input-container">
                  <Button
                    variant={
                      activeTab === "Deductions" ? "contained" : "outlined"
                    }
                    color="primary"
                    onClick={() => onTabChange("Deductions")}>
                    Deductions
                  </Button>
                </div>
              </div>

              <div
                className="flex-container-right"
                style={{ display: "flex", flexDirection: "row" }}>
                {/* <div className="rewards-input-container">
									<TextField
										variant="outlined"
										placeholder="Search"
										size="small"
										fullWidth
										value={searchString}
										onChange={event => setSearchString(event.target.value)}
										InputProps={{
											endAdornment: <InputAdornment position="end">
												{	// Only show "X" button when field not empty
													(searchString !== "")
														? (
															<IconButton
																onClick={() => setSearchString("")}>
																<CloseIcon />
															</IconButton>
														)
														: <></>
												}
											</InputAdornment>
										}} />
								</div> */}
                <div
                  className="flex-container-horizontal"
                  style={{
                    padding: "10px 0px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  {/* <div >
										<DateSelector
											setValue={handleFromDateChange}
											value={fromDate}
											label="From" />
									</div>

									<div >
										<DateSelector
											setValue={handleToDateChange}
											value={toDate}
											
											label="To" />
									</div> */}
                  <div
                    style={{ backgroundColor: "White", borderRadius: "5px" }}>
                    <TextField
                      variant="outlined"
                      placeholder="Search"
                      size="small"
                      value={searchString}
                      onChange={(event) => setSearchString(event.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {
                              // Only show "X" button when field not empty
                              searchString !== "" ? (
                                <IconButton onClick={() => setSearchString("")}>
                                  <CloseIcon />
                                </IconButton>
                              ) : (
                                <></>
                              )
                            }
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {!loading ? (
              <div style={{ marginBottom: "20px" }}>
                <InfiniteScroll
                  dataLength={accountActivity.length}
                  next={async () => {
                    await updatePageNumber((pageNr) => {
                      getNextAccountActivityData(pageNr);
                    });
                  }}
                  hasMore={true}>
                  {accountActivity?.map((item, index) => (
                    <div key={index}>{renderActivities(item)}</div>
                  ))}
                </InfiniteScroll>
              </div>
            ) : (
              <div style={{ textAlign: "center", marginTop: "10%" }}>
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default AccountActivity;
