import { Button, IconButton, Input, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { GoogleMap, Marker } from "@react-google-maps/api";
import mapMarkerYellow from "../../assets/images/map-marker-yellow.png";
import NearMeIcon from '@mui/icons-material/NearMe';
import { useRef } from "react";
import { useEffect } from "react";

function Chooseoption(props) {

    const containerStyle = {
        width: '100%',
        height: '450px',
        borderRadius: "10px",
    };
    const mapRef = useRef(null);

    const [chooseLoc, setChooseloc] = useState(false);


    useEffect(() => {
        if (props?.fromAddsite === true) {
            setChooseloc(true);
        }
    }, [])


    const closeOption = () => {
        props.setChooseoption(false);
    }

    const selectLocation = () => {
        setChooseloc(true);
    }

    const closeLocation = () => {
        setChooseloc(false);
    }

    const handleMapDrag = () => {
        if (mapRef.current) {
            const center = mapRef.current.getCenter(); // Get the center of the map
            const lat = center.lat();
            const lng = center.lng();
            props.setChooselocation({
                lat: Number(lat),
                lng: Number(lng)
            })
        }
    };

    const selectLocationcenter = (e) => {
        props.setChooselocation({
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        })
    }

    const chooseLocation = () => {
        if (props?.fromAddsite === true) {
            props.setChangeLocation(false);
            props.getInitaldata();
        } else {
            props.setChooseoption(false);
            setChooseloc(false);
            props.setShowaddsite(true);
        }
    }

    const openFilepicker = () => {
        document.getElementById("selectsite-pic").click();
    }

    const getPictures = (e) => {
        props.setSelectsitepic(e.target.files[0]);
        props.setChooseoption(false);
        setChooseloc(false);
        props.setShowaddsite(true);
    }

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(currentLocation, showError);
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    const showError = (error) => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                getCurrentLocation();
                break;
            case error.POSITION_UNAVAILABLE:
                break;
            case error.TIMEOUT:
                break;
            case error.UNKNOWN_ERROR:
                break;
            default:
                break;
        }
    };

    // Get current location
    const currentLocation = async (position) => {
        var lat = position?.coords?.latitude;
        var lng = position?.coords?.longitude;
        props.setChooselocation({
            lat: Number(lat),
            lng: Number(lng)
        })
    };


    return (
        <>
            <Modal className="choose-opt" open={props.chooseOption}>
                <div className="modal-center">
                    <div className="modal-card chooseopt-card">
                        <div className="header-section">
                            <Typography className="chooseopt-title" variant="h6" component="h2">
                                Select Option
                            </Typography>
                            <IconButton color="primary" onClick={closeOption}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className="options">
                            <div className="select-loc">
                                <IconButton className="insert-btn" onClick={selectLocation}>
                                    <LocationOnIcon className="opt-icon" />
                                </IconButton>
                                <span className="select-text">Select Location</span>
                            </div>
                            <div className="select-loc">
                                <IconButton className="insert-btn" onClick={openFilepicker}>
                                    <InsertPhotoIcon className="opt-icon" />
                                </IconButton>
                                <Input type="file" id="selectsite-pic" style={{ display: "none" }} onChange={(e) => getPictures(e)}
                                    accept="image/*"
                                />
                                <span className="select-text">Select Image</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal className="choose-opt choose-location" open={chooseLoc}>
                <div className="modal-center">
                    <div className="modal-card chooseopt-card location-card">
                        <div className="header-section">
                            <Typography className="addsite-title" style={{ fontSize: "16px" }} variant="h6" component="h2">
                                Choose Location
                            </Typography>
                            <IconButton color="primary" onClick={closeLocation}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <GoogleMap
                            center={props.chooseLocation}
                            zoom={13}
                            mapContainerStyle={containerStyle}
                            onDragEnd={handleMapDrag}
                            onClick={(e) => selectLocationcenter(e)}
                            onLoad={map => (mapRef.current = map)}
                            ref={mapRef} // Assign the reference to the map
                        >
                            <Marker
                                position={props.chooseLocation}
                                icon={{
                                    url: mapMarkerYellow,
                                    scaledSize: (new window.google.maps.Size(30, 40))
                                }} />
                            <div className="chooseloc-footer">
                                <p className="currentloc" onClick={getCurrentLocation}><NearMeIcon style={{ color: "#083DB8" }} /><span style={{ fontSize: "14px" }}>Use Current location</span></p>
                                <Button className="chooseloc-btn" variant="contained" fullWidth onClick={chooseLocation}>
                                    Choose Location
                                </Button>
                            </div>
                        </GoogleMap>
                    </div>
                </div>
            </Modal>
        </>
    )

}

export default Chooseoption;