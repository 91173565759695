import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import ClientChangeTrackerAddModal from "./ClientChangeTrackerAddModal";
import { fetchClientTracker2 } from "../../../api/ClientApi";
import moment from "moment";
import ClientChangeTrackerViewModel from "./ClientChangeTrackerViewModel";
import Loader from "../../../components/Loader";
export default function ClientTracketCardList(props) {
  const [trackerData, setTrackerData] = useState([]);
  const [displayCount, setDisplayCount] = useState(3);
  const [viewData, setViewData] = useState({});
  const [loading, setLoading] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleViewModelClose = () => setViewOpen(false);
  const handleSeeMore = () => setDisplayCount(trackerData.length);
  const handelModel = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const reloadData = () => getChangeTracker(props?.data?.jobCode);

  const getChangeTracker = async (jobCode) => {
    setLoading(true);
    try {
      let obj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: Number(localStorage.getItem("userId")),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: true,
        SortList: [{ FieldName: "id", Direction: "ASC" }],
        SearchList: [
          {
            clientEmployeeJobCode: jobCode,
          },
        ],
      };
      let res = await fetchClientTracker2(obj);
      setLoading(false);
      setDisplayCount(3);
      setTrackerData(res?.data);
    } catch (error) {
      setLoading(false);
      console.log("error ::::::", error);
    }
  };

  return (
    <div>
      <Accordion
        expanded={props?.data?.expanded}
        onClick={() => {
          getChangeTracker(props?.data?.jobCode);
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              onClick={() => {
                props.handelaccordian(props.index);
                getChangeTracker(props?.data?.jobCode);
              }}
            />
          }
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <LocationOnIcon color="error" />
              <span>{props?.data?.jobName}</span>
            </div>
            <AddIcon
              onClick={(e) => {
                e.stopPropagation();
                handelModel();
              }}
              color="error"
              style={{
                marginRight: "10px",
              }}
            />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <Loader />
          ) : (
            <>
              {trackerData.length === 0 ? (
                <>
                  <span>No Request Available</span>
                </>
              ) : (
                <>
                  {trackerData.slice(0, displayCount).map((item, index) => (
                    <div
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        setViewOpen(true);
                        setViewData(item);
                      }}
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        margin: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      {moment(item.date).format("MMM DD, YYYY")}
                    </div>
                  ))}
                  {displayCount < trackerData.length && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        color: "#B63F3B",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSeeMore();
                        }}
                      >
                        {`See More ${trackerData.length - displayCount} `}
                      </span>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <ClientChangeTrackerAddModal
        open={open}
        close={handleClose}
        data={props?.data}
        updateData={reloadData}
      />
      <ClientChangeTrackerViewModel
        data={viewData}
        open={viewOpen}
        close={handleViewModelClose}
      />
    </div>
  );
}
