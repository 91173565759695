import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { BIBlue, BIRed } from "./assets/buildidColors";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Notifications } from "react-push-notification";
import Login from "./pages/login/Login";
import Notification from "./pages/notifications/Notification";
import Dashboard from "./pages/dashboard/Dashboard";
import Profile from "./pages/profile/Profile";
import Tickets from "./pages/tickets/Tickets";
import Settings from "./pages/settings/Settings";
import HelpAndSupport from "./pages/helpAndSupport/HelpAndSupport";
import MyStore from "./pages/rewards/StoreCard";
import AccountActivity from "./pages/rewards/AccountActivity";
import EarnBuilderBucks from "./pages/rewards/EarnBuilderBucksCard";
import Sites from "./pages/sites/Sites";
import Sitemap from "./pages/sites/Sitemap";
import SiteProfile from "./pages/sites/SiteProfile";
import Contacts from "./pages/contacts/Contacts";
import ChatLayout from "./pages/connect/ChatLayout";
import ChangeLog from "./pages/changelog/ChangeLog";
import TradeProfile from "./pages/trades/TradeProfile";
import CompanyProfile from "./pages/companies/CompanyProfile";
import ManufacturerProfile from "./pages/manufacturers/ManufacturerProfile";
import ProductProfile from "./pages/products/ProductProfile";
import Timesheets from "./pages/timesheets/Timesheets";
import TimeSheetListSection from "./pages/timesheets/TimeSheetListSection";
import Expenses from "./pages/expenses/Expenses";
import ExpensesListSection from "./pages/expenses/ExpensesListSection";
import Montages from "./pages/montages/Montages";
import { getUserDashboard, userDeviceLogout } from "./api/UserApi";
import "./App.css";
import SafetyObservation from "./pages/safetyObservation/SafetyObservation";
import UserProfile from "./pages/userProfile/UserProfile";
import SafetyIncidents from "./pages/safetyIncidents/SafetyIncidents";
import ReferralSignUp from "./pages/referralsignup/ReferralSignUp";
import CarPool from "./pages/carpool/CarPool";
import WorkBuddyVerify from "./pages/workBuddy/WorkBuddyVerify";
import MiniDrawer from "./components/MiniDrawer";
import ProxySite from "./pages/proxySite/ProxySite";
import JobList from "./pages/jobPostings/JobList";
import SaveResumes from "./pages/resumebuilder/SaveReumes";
import { useActions } from "./components/ActionsContext";
import Universalsearch from "./pages/search/Universalsearch";
import BuySellList from "./pages/buysell/BuySellList";
import { HelpModal } from "./pages/rewards/HelpModal";
import Add from "./pages/externaladd/Add";
import { ToastProvider } from "react-toast-notifications";
import Toastify from "./Toastify";
import MapScreen from "./pages/newsite/MapScreen";
import ClientHome from "./pages/client/ClientHome";
import NewProjectDetailsPage from "./pages/newProjects/NewProjectDetailsPage";
import Manufacturelist from "./pages/manufacturers/Manufacturelist";
import Tradelist from "./pages/trades/Tradelist";
import Companylist from "./pages/companies/Companylist";
import ProductList from "./pages/products/Productlist";
import CloseAccountModal from "./pages/settings/CloseAccountModal";
import { messaging } from "./firebase";
import ClientExpenses from "./pages/client/clientExpenses/ClientExpenses";
import ExpensesDetails from "./pages/client/clientExpenses/ExpensesDetails";
import ClientTimesheets from "./pages/client/clientTimesheets/ClientTimesheets";
import ReviewIndividualTimesheet from "./pages/client/clientTimesheets/ReviewIndividualTimesheet";
import { ClientSafetyObservations } from "./pages/client/clientSafetyObservations/ClientSafetyObservations";
import PictureBoard from "./pages/client/PictureBoard";
import { ClientCoaching } from "./pages/client/clientCoaching/ClientCoaching";
import ClientSafetyIncidents from "./pages/client/clientSafetyIncidents/ClientSafetyIncidents";
import ClientChangeTracker from "./pages/client/clientChangeTracker/ClientChangeTracker";
import { ClientCommunicator } from "./pages/client/clientCommunicator/ClientCommunicator";
import ClientSiteEstimation from "./pages/client/clientSiteEstimation/ClientSiteEstimation";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: BIBlue,
      },
      secondary: {
        main: BIRed,
      },
    },
  });

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
        .then(function (registration) {
          console.log(
            "Service Worker registered successfully:",
            registration.scope
          );
        })
        .catch(function (error) {
          console.error("Service Worker registration failed:", error);
        });
    }
  }, []);

  return (
    <Router>
      <ToastProvider>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Notifications position="top-right" />
            <Routes />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
        {/* new code sanket 21-03-2024  */}
      </ToastProvider>
    </Router>
  );
}

export default App;

function Routes() {
  // Restores logged in session from localStorage if it exists
  const [userId, setUserId] = useState(
    localStorage.userId ? localStorage.userId : -1
  );
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [tradeName, setTradeName] = useState("");
  const { getBuilderBucksCount } = useActions();
  const location = useLocation();

  const [showToast, setShowtoast] = useState(false);

  // Propagate to changes Sidebar on ProfileHeader form submit
  function handleChangeSidebarInfo(pictureUrl, firstName, lastName, tradeName) {
    setProfilePictureUrl(pictureUrl);
    console.log("Firstname", firstName);
    setFirstName(firstName);
    setLastName(lastName);
    setTradeName(tradeName);
    // Update localStorage with changes
    localStorage.setItem("firstName", firstName);
    localStorage.setItem("lastName", lastName);
    localStorage.setItem("pictureUrl", pictureUrl);
    localStorage.setItem("tradeName", tradeName);
  }

  function handleLogIn(res, email, urlPath) {
    const urlParts = urlPath.split("/");
    if (urlParts[2] || urlParts[3]) {
      localStorage.setItem("screenName", urlParts[2]);
      localStorage.setItem("itemId", urlParts[3]);
    }
    // Populate localStorage
    localStorage.setItem("userId", res?.userInformation?.UserId);
    localStorage.setItem("primaryEmail", email);
    localStorage.setItem("firstName", res?.userInformation?.FirstName);
    localStorage.setItem("lastName", res?.userInformation?.LastName);
    localStorage.setItem("pictureUrl", res.pictureUrl);

    // sessionStorage.setItem('reloaded', 'yes');

    // Populate local state
    setUserId(res?.userInformation?.UserId);
    setProfilePictureUrl(res.pictureUrl);
    setFirstName(res?.userInformation?.FirstName);
    setLastName(res?.userInformation?.LastName);
  }

  async function handleLogOut() {
    let deviceId = localStorage.getItem("deviceId");
    if (deviceId !== null) {
      await userDeviceLogout(deviceId);
    }
    // Reset localStorage
    localStorage.clear();
    // Reset local state
    setUserId(-1);
    setProfilePictureUrl("");
    setFirstName("");
    setLastName("");
    setTradeName("");
  }

  const getUserDashboardData = async () => {
    try {
      const userData = await getUserDashboard(localStorage?.userId);
      setTradeName(userData?.tradeName);
      setFirstName(userData?.firstName);
      setLastName(userData?.lastName);
      setProfilePictureUrl(userData?.pictureUrl);
    } catch (error) {
      console.log("Error---", error);
    }
  };

  // On load, if localStorage is not populated then force logout
  // useEffect(() => {
  // 	if (localStorage.userId === undefined)
  // 		handleLogOut();
  // }, []);

  useEffect(async () => {
    if (localStorage?.userId) {
      await getBuilderBucksCount(1);
      await getUserDashboardData();
    }
  }, [localStorage?.userId]);

  // useEffect(()=>{
  //    console.log("userId",userId)
  //    alert(location.pathname)
  // 	//   console.log("window.location.pathname.includes(workBuddyVerify)",window.location.pathname.includes("workBuddyVerify"))
  // 	  if(location.pathname.includes("/refer")){
  // 		if(localStorage?.userId){
  // 		setUserId(-1);
  // 		localStorage.clear();
  // 		}
  // 	  }
  // },[])

  const theme = createTheme({
    palette: {
        primary: {
            main: BIBlue
        },
        secondary: {
            main: BIRed
        }
    }
})

  return userId !== -1 ? (
    <>
      {window.location.pathname.includes("workBuddyVerify") ? (
        <></>
      ) : (
        <>
        <MuiThemeProvider theme={theme}>
          <MiniDrawer
            // onLogOut={() => {
            // 	handleLogOut();
            // 	window.location.replace("/");
            // 	// window.history.replaceState(null, '', '/');
            // }}
            setUserId={setUserId}
            profilePictureUrl={profilePictureUrl}
            firstName={firstName}
            lastName={lastName}
            tradeName={tradeName}>
            <Switch>
              <Route
                path="/profile"
                render={(props) => (
                  <Profile onChangeSidebarInfo={handleChangeSidebarInfo} />
                )}
              />
              <Route
                path="/trade/:tradeId"
                component={({ match }) => (
                  <TradeProfile tradeId={match.params.tradeId} />
                )}
              />

              <Route
                path="/company/:companyId"
                component={({ match }) => (
                  <CompanyProfile companyId={match.params.companyId} />
                )}
              />

              <Route
                path="/manufacturer/:manufacturerId"
                component={({ match }) => (
                  <ManufacturerProfile
                    manufacturerId={match.params.manufacturerId}
                  />
                )}
              />

              <Route
                path="/product/:productId"
                component={({ match }) => (
                  <ProductProfile productId={match.params.productId} />
                )}
              />

              {window.location.pathname.includes("proxysite") &&
              window.location.pathname.includes("TradeDetail") ? (
                <Route
                  path={`/proxysite/TradeDetail/:tradeId`}
                  component={({ match }) => (
                    <TradeProfile tradeId={match.params.tradeId} />
                  )}
                />
              ) : window.location.pathname.includes("CompanyInfo") ? (
                <Route
                  path="/proxysite/CompanyInfo/:companyId"
                  component={({ match }) => (
                    <CompanyProfile companyId={match.params.companyId} />
                  )}
                />
              ) : window.location.pathname.includes("ManufacturerInfo") ? (
                <Route
                  path="/proxysite/ManufacturerInfo/:manufacturerId"
                  component={({ match }) => (
                    <ManufacturerProfile
                      manufacturerId={match.params.manufacturerId}
                    />
                  )}
                />
              ) : window.location.pathname.includes("ProductInfo") ? (
                <Route
                  path="/proxysite/ProductInfo/:productId"
                  component={({ match }) => (
                    <ProductProfile productId={match.params.productId} />
                  )}
                />
              ) : window.location.pathname.includes("SiteDetail") ? (
                <Route
                  path="proxysite/SiteDetail/:siteId"
                  component={({ match }) => (
                    <SiteProfile siteId={match.params.siteId} />
                  )}
                />
              ) : null}

              <Route path="/notifications" component={Notification} />

              <Route path="/dashboard" component={Dashboard} />

              {/* Build ID Web Client */}

              <Route path="/client" component={ClientHome} />
              <Route path="/client-picture-board" component={PictureBoard} />
              <Route path="/client-expenses" component={ClientExpenses} />
              <Route
                path={"/client-expenses-details/:id"}
                component={ExpensesDetails}
              />
              <Route path="/client-timesheets" component={ClientTimesheets} />
              <Route
                path="/client-individual-timesheet/:id"
                component={({ match }) => (
                  <ReviewIndividualTimesheet id={match.params.id} />
                )}
              />
              <Route
                path="/client-safety-observations"
                component={ClientSafetyObservations}
              />
              <Route path="/client-coaching" component={ClientCoaching} />
              <Route
                path="/client-safety-incidents"
                component={ClientSafetyIncidents}
              />
              <Route
                path="/client-change-Tracker"
                component={ClientChangeTracker}
              />
              <Route
                path="/client-communicator"
                component={ClientCommunicator}
              />
              <Route
                path="/client-site-estimation"
                component={ClientSiteEstimation}
              />

              <Route path="/tickets" component={Tickets} />

              <Route path="/carpool" component={CarPool} />

              <Route exact path="/settings" component={Settings} />
              <Route exact path="/closeaccount" component={CloseAccountModal} />

              <Route path="/login" component={Login} />

              <Route path="/signup" component={Login} />

              <Route path="/help-and-support" component={HelpAndSupport} />

              <Route
                path="/sites/company/:companyId"
                component={({ match }) => (
                  <Sites companyId={match.params.companyId} />
                )}
              />

              <Route
                path="/sites/site/:siteId"
                component={({ match }) => (
                  <SiteProfile siteId={match.params.siteId} />
                )}
              />

              <Route
                path="/projects/project/:projectId"
                component={({ match }) => (
                  <NewProjectDetailsPage
                    projectId={Number(match.params.projectId)}
                  />
                )}
              />

              <Route
                path="/user-dashboard-profile/:contactId/"
                component={({ match }) => (
                  <UserProfile contactId={match.params.contactId} />
                )}
              />

              <Route
                path="/workBuddyVerify/:dbId/:userId/:workBuddyId"
                component={({ match }) => (
                  <WorkBuddyVerify
                    dbId={match.params.dbId}
                    userId={match.params.userId}
                    workBuddyId={match.params.workBuddyId}
                  />
                )}
              />

              <Route path="/sites" component={Sitemap} />
              <Route path="/new-map" component={MapScreen} />

              <Route
                path="/rewards/store/"
                component={() => <MyStore activeTab={"store"} />}
              />
              <Route
                path="/rewards/earn-rewards"
                component={() => (
                  <EarnBuilderBucks activeTab={"earn-rewards"} />
                )}
              />
              <Route
                path="/rewards/earn-builderbucks"
                component={() => <HelpModal activeTab={"earn-builderbucks"} />}
              />
              <Route
                path="/rewards/account-activity"
                component={() => (
                  <AccountActivity activeTab={"account-activity"} />
                )}
              />

              <Route path="/timesheets/" component={Timesheets} />

              <Route
                path="/timesheetlistsection/"
                component={TimeSheetListSection}
              />

              <Route path="/expenses/" component={Expenses} />
              <Route path="/job-postings/" component={JobList} />
              <Route path="/buy-sell/" component={BuySellList} />

              <Route
                path="/expenseslistsection/"
                component={ExpensesListSection}
              />

              <Route path="/montages" component={Montages} />

              <Route path="/resume-builder" component={SaveResumes} />

              <Route path="/safety-observation" component={SafetyObservation} />

              <Route path="/safety-incidents" component={SafetyIncidents} />

              <Route path="/chat" component={ChatLayout} />

              <Route path="/manufactures" component={Manufacturelist} />

              <Route path="/trades" component={Tradelist} />

              <Route path="/companies" component={Companylist} />

              <Route path="/products" component={ProductList} />

              <Route path="/content-search" component={Universalsearch} />

              <Route path="/contacts" component={Contacts} />

              <Route path="/changelog" component={ChangeLog} />

              <Route path="/add" component={Add} />

              {/* Edge case - going to base URL when logged in; redirect to dashboard */}
              {/* <Route path="/"><Redirect to="/dashboard" /></Route> */}
              <Redirect from="/" to="/dashboard" />

              {/* <Route path="/dashboard" component={Dashboard} /> */}
            </Switch>
          </MiniDrawer>
          </MuiThemeProvider>
        </>
      )}
    </>
  ) : (
    <>
      {window.location.pathname.includes("refer") ? (
        <Route
          path="/refer/:referId/"
          component={({ match }) => (
            <ReferralSignUp referId={match.params.referId} />
          )}
        />
      ) : window.location.pathname.includes("workBuddyVerify") ? (
        <Route
          path="/workBuddyVerify/:dbId/:userId/:workBuddyId"
          component={({ match }) => (
            <WorkBuddyVerify
              dbId={match.params.dbId}
              userId={match.params.userId}
              workBuddyId={match.params.workBuddyId}
            />
          )}
        />
      ) : window.location.pathname.includes("proxySite") ||
        window.location.pathname.includes("proxysite") ? (
        window.location.pathname.includes("ProjectPage") ? (
          <Route
            path="/proxySite/:screenName/:itemId/"
            component={({ match }) => (
              <ProxySite
                screenName={match.params.screenName}
                itemId={match.params.itemId}
              />
            )}
          />
        ) : window.location.pathname.includes("TradeDetail") ? (
          <Route
            path="/proxySite/:screenName/:itemId/"
            component={({ match }) => (
              <ProxySite
                screenName={match.params.screenName}
                itemId={match.params.itemId}
              />
            )}
          />
        ) : window.location.pathname.includes("ProductInfo") ? (
          <Route
            path="/proxySite/:screenName/:itemId/"
            component={({ match }) => (
              <ProxySite
                screenName={match.params.screenName}
                itemId={match.params.itemId}
              />
            )}
          />
        ) : (
          <Route
            path="/proxySite/:screenName/:itemId/"
            component={({ match }) => (
              <ProxySite
                screenName={match.params.screenName}
                itemId={match.params.itemId}
              />
            )}
          />
        )
      ) : window.location.pathname.includes("redirect") ? (
        <Route
          path="/redirect/:screenName/:itemId/"
          component={({ match }) => (
            <Login
              onLogIn={handleLogIn}
              screenName={match.params.screenName}
              itemId={match.params.itemId}
            />
          )}
        />
      ) : window.location.pathname.includes("create-account") ? (
        <Route path="/create-account" component={ReferralSignUp} />
      ) : (
        <Route path="/" render={(props) => <Login onLogIn={handleLogIn} />} />
      )}
    </>
  );
}
