import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import {
  Stack,
  Button,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from "@mui/material";
import ProductSearchField from "../../../components/ProductSearchField";
import {
  deleteUserTrades,
  fetchTrades,
  saveUserTrades,
} from "../../../api/TradesApi";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { Close as CloseIcon } from "@material-ui/icons";
import { fetchUserWorkHistory } from "../../../api/UserApi";
import Loader from "../../../components/Loader";
import CircularProgress from "@mui/material/CircularProgress";
import { BIGrey } from "../../../assets/buildidColors";

const TradeCardModal = (props) => {
  const [isModified, setIsModified] = useState(false);
  const [tradeData, setTradeData] = useState([]);
  const [tradeList, setTradeList] = useState([]);
  const [userTradesDataMain, setUserTradesDataMain] = useState([]);
  const [tradeInputValue, setTradeInputValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [filteredTradeList, setFilteredTradeList] = useState([]);
  const [workHistories, setWorkHistories] = useState([]);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [laodingData, setLodaingData] = useState(true);
  const [submit, setSubmit] = useState(false);

  const getTrade = async () => {
    try {
      const workHistoryObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        SearchList: [{ UserId: localStorage.userId }],
      };
      const workHistory = await fetchUserWorkHistory(workHistoryObj);
      const userTradeIdArray = workHistory.map((work) => work.tradeId);

      setWorkHistories(userTradeIdArray);

      const userTradeData = props.userTradeList;

      let a = [];
      const newUsertradeData = userTradeData.map((trade) => {
        a = trade;
        a["isSelected"] = false;
        a["isDeleted"] = false;
        a["isChecked"] = false;
        a["isVisible"] = false;
        if (trade.tradeType.toLowerCase() == "main") {
          a["isSelected"] = true;
        }
        return a;
      });

      const obj = {
        PageNr: 1,
        FullSearch: "",
        SearchList: [],
        IncludeRecordNr: false,
        TypeOfObjectReturned: "NameOnly",
      };
      const trades = await fetchTrades(obj);

      let b = [];
      const newTrades = trades?.data.map((trade) => {
        b = trade;
        b["isVisible"] = true;
        b["isSelected"] = false;
        b["isDeleted"] = false;
        b["isChecked"] = false;

        if (tradeData.some((t) => t.tradeId == trade.id)) {
          b["isVisible"] = false;
          b["isChecked"] = true;
        }
        b["tradeName"] = b["name"];
        delete b["name"];
        b["tradeId"] = b["id"];
        delete b["id"];
        return b;
      });

      const newTradeData = newUsertradeData.sort((a, b) =>
        a.tradeName
          ?.trim()
          ?.toLowerCase()
          .localeCompare(b.tradeName?.trim()?.toLowerCase())
      );
      const newVisibleTrade = newTrades.filter(
        (trade) => trade.isVisible && trade.recordStatusId != 3
      );
      const newTradeList = newVisibleTrade.sort((a, b) =>
        a.tradeName
          ?.trim()
          ?.toLowerCase()
          .localeCompare(b.tradeName?.trim()?.toLowerCase())
      );
      const newArray = newTradeList.filter(
        (name) => !newTradeData.some((e) => e.tradeId === name.tradeId)
      );
      setTradeData(newTradeData);
      setTradeList(newArray);
      setFilteredTradeList(newArray);
      setUserTradesDataMain(userTradeData);
      setLodaingData(false);
    } catch (err) {
      setLodaingData(false);
      console.log("FetchTradesError:", err);
    }
  };

  const onRadioButtonPressed = (item) => {
    setIsModified(true);
    const newTradeData = tradeData.map((trade) => {
      if (trade.tradeId == item.tradeId) trade.isSelected = true;
      else trade.isSelected = false;
      return trade;
    });
    setTradeData(newTradeData);
  };

  function onChnageTrades(props) {
    setTradeInputValue(props);
    if (props != "") setIsSearching(true);
    else setIsSearching(false);
    onChangeSearchText(props);
  }

  const onChangeSearchText = async (searchText) => {
    setFilteredTradeList(
      tradeList?.filter((trade) =>
        trade?.tradeName
          .trim()
          .toLowerCase()
          .includes(searchText?.trim()?.toLowerCase())
      )
    );
    // if (searchText != '' && searchText.length > 1) {
    //     // const filteredTradeList = tradeList.filter(data => data.tradeName.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
    //     // const updatedItems = tradeList.filter((e) => e.tradeName == searchText);
    //     // // Matching Exact name and updating   --->
    //     // if (updatedItems.length != 0) {
    //     //     setFilteredTradeList(updatedItems);
    //     // }
    //     // else {
    //     //     setFilteredTradeList(filteredTradeList.filter((trade) => trade.recordStatusId != 3));
    //     // }
    // } else setFilteredTradeList([]);
  };

  const tradeListData =
    isSearching && tradeInputValue.length > 1
      ? filteredTradeList.filter((trade) => trade.isVisible)
      : tradeList.filter(
          (trade) => trade.isVisible && trade.recordStatusId != 3
        );

  const onTradeListItemPressed = async (item, index) => {
    setIsModified(true);
    let temp = [];
    let tradeDataList = tradeList
      .filter((t) => t.isVisible)
      .sort((a, b) =>
        a.tradeName
          ?.trim()
          ?.toLowerCase()
          .localeCompare(b.tradeName?.trim()?.toLowerCase())
      );
    let tempFilteredTradeListData = filteredTradeList
      .filter((t) => t.isVisible)
      .sort((a, b) =>
        a.tradeName
          ?.trim()
          ?.toLowerCase()
          .localeCompare(b.tradeName?.trim()?.toLowerCase())
      );

    if (isSearching) {
      let a = [];
      tempFilteredTradeListData.map((tempFilter) => {
        if (item.tradeId == tempFilter.tradeId) {
          tempFilter.isChecked = true;
          tempFilter.isVisible = false;
          tempFilter.isDeleted = false;
          tempFilter.isSelected = tradeData.length == 0 ? true : false;
          a.push(tempFilter);
          return;
        }
      });

      if (!tradeData.some((trade) => trade.tradeId == item.tradeId)) {
        tradeData.push(a[0]);
        setTradeData(tradeData);
      } else {
        let newTradeData = tradeData.map((trade) => {
          if (trade.tradeId == item.tradeId) {
            trade.isChecked = true;
            trade.isVisible = false;
            trade.isDeleted = false;
            trade.isSelected = tradeData.length == 0 ? true : false;
          }
          return trade;
        });

        setTradeData(newTradeData);
      }
      setTradeList(tradeDataList);
      setFilteredTradeList([]);
      setTradeInputValue("");
      setIsSearching(false);
    } else {
      let userTrades = tradeData.filter((trade) => !trade.isVisible);
      let a = [];
      tradeDataList.map((t) => {
        if (item.tradeId == t.tradeId) {
          t.isChecked = true;
          t.isVisible = false;
          t.isDeleted = false;
          t.isSelected = userTrades.length == 0 ? true : false;
          a.push(t);
          return;
        }
      });
      if (!tradeData.some((trade) => trade.tradeId == item.tradeId)) {
        tradeData.push(a[0]);
        temp.push(item);
        setTradeData(tradeData);
      } else {
        let newTradeData = tradeData.map((trade) => {
          if (trade.tradeId == item.tradeId) {
            trade.isChecked = true;
            trade.isVisible = false;
            trade.isDeleted = false;
            trade.isSelected = userTrades.length == 0 ? true : false;
          }

          return trade;
        });
        setTradeData(newTradeData);
      }
      setTradeList(tradeDataList);
    }
  };

  const onDeleteTrade = (item) => {
    if (workHistories?.includes(item.tradeId)) {
      setDiscardModalOpen(true);
    } else {
      setIsModified(true);
      onUserTradesPressed(item);
    }
  };

  const onUserTradesPressed = (item, index) => {
    let a = [];
    const user_Trades = tradeData.filter((t) => !t.isVisible);

    if (user_Trades.findIndex((trade) => trade.tradeId == item.tradeId) != -1) {
      a.push(item);
      user_Trades.map((i) => {
        if (i.tradeId == item.tradeId) {
          i.isChecked = false;
          i.isVisible = true;
          i.isDeleted = true;

          if (i.isSelected) {
            i.isSelected = false;
            const userTradeIndex = user_Trades.findIndex(
              (trade) => !trade.isVisible
            );
            if (userTradeIndex != -1) {
              user_Trades[userTradeIndex].isSelected = true;
            }
          }

          if (!tradeList.some((trade) => trade.tradeId == item.tradeId)) {
            tradeList.push(i);
            setTradeList(tradeList);
            setTradeData(user_Trades);
          } else {
            tradeList.map((trade) => {
              if (trade.tradeId == item.tradeId) {
                trade.isChecked = false;
                trade.isVisible = true;
                trade.isDeleted = true;
              }
              return trade;
            });
          }
        }
      });
      const filterUserTradesTemp = user_Trades.filter(
        (trade) => !trade.isVisible
      );
      setTradeData(filterUserTradesTemp);
      const sortTradeList = tradeList.sort(function (a, b) {
        if (a.tradeName < b.tradeName) {
          return -1;
        }
        if (a.tradeName > b.tradeName) {
          return 1;
        }
        return 0;
      });
      setTradeList(sortTradeList);
    }
  };

  const onSubmit = async () => {
    try {
      setSubmit(true);
      const deletedTradeData = userTradesDataMain.filter((userTrade) =>
        [...tradeData, ...tradeList].some(
          (trade) => trade.tradeId == userTrade.tradeId && trade.isDeleted
        )
      );
      const deletedTradesArray = deletedTradeData.map((trade) => {
        return { Id: trade.id, ModifiedBy: Number(localStorage.userId) };
      });

      if (deletedTradesArray.length != 0) {
        const obj = {
          UserId: Number(localStorage.userId),
          ReturnRecordError: true,
          DeleteList: deletedTradesArray,
        };
        await deleteUserTrades(obj);
        props.onDataSave();
      }

      const newTradeData = tradeData
        .filter((trade) => !trade.isDeleted)
        .map((tr) => {
          let a = tr;
          userTradesDataMain.map((userTrade) => {
            if (userTrade.tradeId == tr.tradeId) {
              a["id"] = userTrade.id;
            }
          });
          return a;
        });

      // new code sanket 15-03-2024
      const tradesArray = newTradeData.map((trade) => {
        return {
          Id: trade.id ?? 0,
          UserId: Number(localStorage.userId),
          TradeId: trade.tradeId,
          TradeType: trade.isSelected ? "Main" : "Other",
          ModifiedBy: Number(localStorage.userId),
          tradeName: trade?.tradeName,
        };
      });
      if (tradesArray.length > 0) {
        const userTradeObj = {
          UserId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: tradesArray,
        };
        await saveUserTrades(userTradeObj);
        props.onDataSave();
        // new code sanket 15-03-2024
        localStorage.setItem("tradeName", tradesArray[0]?.tradeName.toString());
      } else {
        localStorage.setItem("tradeName", "");
      }
      props.onClose();
      setIsModified(false);
      setSubmit(false);
    } catch (error) {
      setSubmit(false);
      console.log("Error::", error);
    }
  };

  useEffect(() => {
    getTrade();
  }, [props]);

  return (
    <div>
      <Modal open={discardModalOpen} onClose={() => setDiscardModalOpen(false)}>
        <div className="modal-center">
          <div className="modal-card" style={{ width: "500px" }}>
            <div className="modal-card-body">
              <div>
                <p style={{ lineHeight: 2 }}>
                  This trade cannot be deleted because it is used with one or
                  more of the sites in your timeline.
                </p>
              </div>
              <div
                className="flex-container-horizontal"
                style={{ justifyContent: "flex-end" }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setDiscardModalOpen(false)}
                >
                  <b>OK</b>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Dialog
        open={props.isOpen}
        onClose={() => {
          props.onClose();
          setIsModified(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <div
            className="modal-card-header-buttons"
            style={{ alignItems: "center" }}
          >
            <h2>Edit Trades</h2>
            <IconButton
              color="primary"
              onClick={async () => {
                props.onClose();
                setIsModified(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <Stack spacing={2} padding={"0px 40px"}>
          <ProductSearchField
            value={tradeInputValue}
            onChange={(e) => onChnageTrades(e)}
            isFocused={() => {
              setIsSearching(true);
            }}
            placeholder={"Trade Name"}
          />
        </Stack>
        <DialogContent style={{ paddingTop: 0 }}>
          {!laodingData ? (
            <Stack spacing={0}>
              {tradeData?.length > 0 && (
                <div
                  style={{
                    marginTop: "5px",
                    textAlign: "right",
                    color: BIGrey,
                    marginRight: "12px",
                  }}
                >
                  <Typography>Main</Typography>
                </div>
              )}
              <div>
                {tradeData.map((item, index) => {
                  return (
                    <div key={index.toString()}>
                      <ListItem
                        key={index.toString()}
                        secondaryAction={
                          <div style={{ display: "flex" }}>
                            {item?.isSelected ? (
                              <IconButton>
                                <StarRateRoundedIcon
                                  sx={
                                    item?.isSelected
                                      ? { color: "gold" }
                                      : { color: "white" }
                                  }
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => onRadioButtonPressed(item)}
                              >
                                <StarBorderRoundedIcon />
                              </IconButton>
                            )}
                          </div>
                        }
                        disablePadding
                      >
                        <ListItemButton
                          sx={{ display: "flex", flexDirection: "row" }}
                          onClick={() => onDeleteTrade(item, index)}
                        >
                          {item.recordStatusId !== 3 ? (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={
                                    (item.isChecked == true ? true : false) ||
                                    (item.isDeleted == false ? true : false)
                                  }
                                  onChange={() => onDeleteTrade(item, index)}
                                />
                              }
                            />
                          ) : (
                            <></>
                          )}
                          <ListItemText>{item.tradeName}</ListItemText>
                        </ListItemButton>
                      </ListItem>
                    </div>
                  );
                })}
              </div>
            </Stack>
          ) : (
            <Loader />
          )}
          {tradeData?.length > 0 && <Divider />}
          <Stack spacing={0}>
            <div>
              {tradeListData.map((item, index) => {
                return (
                  <div key={index.toString()}>
                    <ListItem key={index.toString()} disablePadding>
                      <ListItemButton
                        sx={{ display: "flex", flexDirection: "row" }}
                        onClick={() => onTradeListItemPressed(item, index)}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={item.isChecked == true ? true : false}
                              onChange={() =>
                                onTradeListItemPressed(item, index)
                              }
                            />
                          }
                        />
                        <ListItemText>{item.tradeName}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </div>
                );
              })}
            </div>
          </Stack>
        </DialogContent>
        <Stack spacing={0}>
          <div
            className="input-container"
            style={{
              padding: "10px 20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p style={{ color: "GrayText" }}>
              Your Trades are visible to others
            </p>
            {!submit ? (
              <Button
                disabled={isModified ? false : true}
                variant="contained"
                color="primary"
                fullWidth
                size="medium"
                component="label"
                onClick={() => onSubmit()}
              >
                Save
              </Button>
            ) : (
              <Button
                disabled={true}
                variant="contained"
                color="primary"
                fullWidth
                size="medium"
                component="label"
              >
                <CircularProgress size={24} />
              </Button>
            )}
          </div>
        </Stack>
      </Dialog>
    </div>
  );
};
export default TradeCardModal;
