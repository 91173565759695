// ActionsContext.js
import { createContext, useContext, useEffect, useState } from 'react';
import { fetchUserCompanies, fetchUserWorkHistory, getUserDashboard, getUserRewardAmount } from '../api/UserApi';
import moment from "moment";


const ActionsContext = createContext();

export const ActionsProvider = ({ children }) => {

    const [builderBucksBalance, setBuilderBucksBalance] = useState(0);
    const [nrOfSites, setNrOfSites] = useState(0);
    const [workYears, setWorkYears] = useState(0);
    const [numUniqueCompanies, setNumUniqueCompanies] = useState(0);


    function removeDuplicates(originalArray, prop) {
        let newArray = [];
        let lookupObject = {};
        for (let i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }
        for (let i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }


    const resetValue = async () => {
        await setBuilderBucksBalance(0);
        await setWorkYears(0);
        await setNrOfSites(0);
        await setNumUniqueCompanies(0);
    }

    const getBuilderBucksCount = async (i) => {
        try {
            if (i) {
                await resetValue();
            }
            const result = await getUserRewardAmount(localStorage.userId);
            const userData = await getUserDashboard(localStorage.userId);
            const workHistoryObj = {
                PageNr: 1,
                FullSearch: "",
                IncludeRecordNr: false,
                SearchList: [{ Id: 0, UserId: Number(localStorage.userId) }]
            }
            const userCompanyObj = {
                PageNr: 1,
                FullSearch: "",
                IncludeRecordNr: false,
                SearchList: [{ UserId: Number(localStorage.userId) }]
            }
            // await this.getNewsFeed();
            const temp = await fetchUserWorkHistory(workHistoryObj);
            const userCompanies = await fetchUserCompanies(userCompanyObj);
            const tempArray = [...temp, ...userCompanies];
            const uniqueArray = await removeDuplicates(tempArray, "companyId");
            const summeryCompanies = uniqueArray.filter((unique) => unique.companyName !== '');
            const num = uniqueArray.length != 0 && uniqueArray.filter((data) => data.startDate).map((t) => {
                const a = t.endDate != null ? moment(t.endDate) : moment(new Date()), b = moment(t.startDate);
                return a.diff(b, 'months');
            }).reduce((acc, currentValue) => { return acc + currentValue }, 0);

            const convertYears = num / 12;
            const WorkingYears = Math.round(convertYears);
            setNrOfSites(userData?.nrOfSites ?? 0);
            setWorkYears(WorkingYears);
            setBuilderBucksBalance(result.bucks);
            setNumUniqueCompanies(summeryCompanies.length)
        } catch (error) {
            console.log("error while getting user wrok counts", error)
        }
    };


    return (
        <ActionsContext.Provider value={{ builderBucksBalance, getBuilderBucksCount, nrOfSites, workYears, numUniqueCompanies }}>
            {children}
        </ActionsContext.Provider>
    );
};

export const useActions = () => {
    return useContext(ActionsContext);
};
