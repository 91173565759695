import React, { useEffect, useState } from "react";
import HomeModel from "./HomeModel";
import "../../assets/css/ClientHome.css";
import {
  Avatar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Box,
  Stack,
  Modal,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import MessageIcon from "@mui/icons-material/Message";
import ImageIcon from "@mui/icons-material/Image";
import AddBoxIcon from "@mui/icons-material/AddBox";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import EngineeringIcon from "@mui/icons-material/Engineering";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import ChatIcon from "@mui/icons-material/Chat";
import PlaceIcon from "@mui/icons-material/Place";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { BIGrey, BIRed } from "../../assets/buildidColors";
import Addexpenses from "../client/clientExpenses/Addexpenses";
import { ClientHeader } from "./ClientHeader";
import {
  fetchClientEmployeeLicenses,
  getClientApprovedPictureBoard,
  updateEmployeeLicensesIsPinFlag,
} from "../../api/ClientApi";
import { Chip } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import PushPinIcon from "@mui/icons-material/PushPin";
import { Close, Place } from "@material-ui/icons";
import moment from "moment";
import PictureViewer from "../../components/PictureViewer";

export default function ClientHome() {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [clientLicenseData, setClientLicenseData] = useState([]);
  const [clientApprovedPictureBoardData, setClientApprovedPictureBoardData] =
    useState([]);
  const [openEditLicenseListModal, setOpenEditLicenseListModal] =
    useState(false);
  const [sortBy, setSortBy] = useState("alphabetical");
  const [viewPictureViewerOpen, setViewPictureViewerOpen] = useState(false);
  const history = useHistory(); // Initialize useHistory for navigation
  const clientAccentColor = localStorage.getItem("clientAccentColor");

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  const navigateTocurrentpage = async (item) => {
    switch (item?.appName) {
      case "Expenses":
        history.push("/client-expenses");
        break;
      case "Safety Observation":
        history.push("/client-safety-observations");
        break;
      case "Safety Incident":
        history.push("/client-safety-incidents");
        break;
      case "Timesheets":
        history.push("/client-timesheets");
        break;
      case "Coaching":
        history.push("/client-coaching");
        break;
      case "Picture Board":
        history.push("/client-picture-board");
        break;
      case "Change Tracker":
        history.push("/client-change-tracker");
        break;
      case "Communicator":
        history.push("/client-communicator");
        break;
      case "Site Estimation":
        history.push("/client-site-estimation");
        break;
      // case "Support":
      // history.push("/client-support");
      // break;
      // case "Site Activity Reports":
      // history.push("/client-site-activity-reports");
      // break;
      default:
        return null;
    }
    const obj = {
      Id: item?.id,
      IsPin: item?.isPin,
    };
    await updateEmployeeLicensesIsPinFlag(obj);
  };

  const getAppIcon = (appName) => {
    switch (appName) {
      case "Expenses":
        return <StickyNote2Icon style={{ color: clientAccentColor }} />;
      case "Timesheets":
        return <CalendarMonthIcon style={{ color: clientAccentColor }} />;
      case "Safety Observation":
        return <EngineeringIcon style={{ color: clientAccentColor }} />;
      case "Safety Incident":
        return <AddBoxIcon style={{ color: clientAccentColor }} />;
      case "Coaching":
        return <ChatIcon style={{ color: clientAccentColor }} />;
      case "Picture Board":
        return <ImageIcon style={{ color: clientAccentColor }} />;
      case "Change Tracker":
        return <StickyNote2Icon style={{ color: clientAccentColor }} />;
      case "Communicator":
        return <ChatIcon style={{ color: clientAccentColor }} />;
      case "Site Estimation":
        return <ChatIcon style={{ color: clientAccentColor }} />;
      case "Support":
        return <HelpCenterIcon style={{ color: clientAccentColor }} />;
      case "Site Activity Reports":
        return <PlaceIcon style={{ color: clientAccentColor }} />;
      default:
        return null;
    }
  };

  const handleSortByValue = () => {
    if (sortBy === "alphabetical") {
      setSortBy("recently used");
      getList();
    } else {
      setSortBy("alphabetical");
      const pinnedItems = clientLicenseData?.filter((item) => item.isPin);
      const nonPinnedItems = clientLicenseData?.filter((item) => !item.isPin);
      const sortedByRecentUsedDate = [...nonPinnedItems].sort((a, b) => {
        if (!a.recentUsedAppDate && !b.recentUsedAppDate) return 0;
        if (!a.recentUsedAppDate) return 1;
        if (!b.recentUsedAppDate) return -1;
        return new Date(b.recentUsedAppDate) - new Date(a.recentUsedAppDate);
      });
      setClientLicenseData([...pinnedItems, ...sortedByRecentUsedDate]);
    }
  };

  const getList = async () => {
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: localStorage.userId,
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SearchList: [
        {
          ClientId: Number(localStorage.clientId),
          EmployeeId: Number(localStorage.clientEmployeeId),
        },
      ],
    };

    const clientApprovedPictureBoardRes = await getClientApprovedPictureBoard(
      Number(localStorage.clientId)
    );
    setClientApprovedPictureBoardData(clientApprovedPictureBoardRes);

    const res = await fetchClientEmployeeLicenses(obj);
    const finalList = await Promise.all(
      res?.data.map(async (item) => {
        return {
          ...item,
          selected: item.isPin,
          loading: false,
          icon: getAppIcon(item?.appName),
        };
      })
    );
    // Separate pinned and non-pinned items
    const pinnedItems = finalList?.filter((item) => item.isPin);
    const nonPinnedItems = finalList?.filter((item) => !item.isPin);

    // Sort both lists alphabetically by a key (assuming 'appName' or another key for sorting)
    // pinnedItems?.sort((a, b) => a.appName?.localeCompare(b.appName));
    nonPinnedItems?.sort((a, b) => a.appName?.localeCompare(b.appName));

    // Concatenate the sorted lists, with pinned items first
    let sortedFinalList = [...pinnedItems, ...nonPinnedItems];

    // Remove 'pictureboad' item if clientApprovedPictureBoardRes exists and has items
    if (clientApprovedPictureBoardRes?.length > 0) {
      sortedFinalList = sortedFinalList?.filter(
        (item) => item.appName !== "Picture Board"
      );
    }

    setClientLicenseData(sortedFinalList);
  };

  useEffect(() => getList(), []);

  return (
    <>
      <ClientHeader screenName={localStorage.getItem("clientName")} />
      <section
        style={{
          height: clientLicenseData?.length > 0 ? "auto" : "100vh",
        }}
        className="client-main client-home-height">
        {clientApprovedPictureBoardData?.length > 0 && (
          <div className="pictureboard-card">
            <Card className="board-card">
              <h4
                onClick={() =>
                  navigateTocurrentpage({
                    appName: "Picture Board",
                    id: 2,
                    isPin: false,
                  })
                }
                className="card-title">
                {"Picture Board"}
              </h4>
              <CardMedia
                component="img"
                height="194"
                image={clientApprovedPictureBoardData[0]?.fileUrl}
                alt={clientApprovedPictureBoardData[0]?.pictureDescription}
                onClick={() => setViewPictureViewerOpen(true)}
              />
              <CardContent className="board-content">
                <div className="board-footer">
                  <div className="profile-detail">
                    <Avatar
                      src={
                        clientApprovedPictureBoardData[0]?.userProfilePicture
                      }
                      aria-label="recipe"
                    />
                    <h4 className="profile-name">
                      {clientApprovedPictureBoardData[0]?.userName}
                    </h4>
                  </div>
                  <span>
                    {moment(
                      clientApprovedPictureBoardData[0]?.dateApproved
                    ).format("MMMM D, YYYY")}
                  </span>
                </div>
              </CardContent>
            </Card>
          </div>
        )}
        <Grid container spacing={2} className="client-content">
          {/* <Grid item xs={12} md={4} style={{ paddingLeft: "0px" }}>
            <Box
              sx={{
                p: 2,
                borderRadius: "8px",
              }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <Typography variant="h6" className="link-title">
                  Quick Links
                </Typography>
              </Box>
              <List>
                <Card sx={{ marginBottom: "5px" }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <AttachMoneyIcon style={{ color: BIRed }} />
                    </ListItemIcon>
                    <ListItemText primary={<h4>Add a new Expense</h4>} />
                  </ListItemButton>
                </Card>
              </List>
              <List>
                <Card sx={{ marginBottom: "5px" }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <InsertPhotoIcon style={{ color: BIRed }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={<h4>Submit a picture to the Picture Board</h4>}
                    />
                  </ListItemButton>
                </Card>
              </List>
            </Box>
          </Grid> */}
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                padding: "16px",
                paddingRight: "0px",
                paddingLeft: "0px",
                borderRadius: "8px",
              }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <Typography variant="h6" className="my-application">
                  My Application
                </Typography>
                <Typography
                  variant="outlined"
                  className="recent-text"
                  size="small"
                  color={BIGrey}
                  style={{
                    userSelect: "none",
                    WebkitUserSelect: "none",
                    MozUserSelect: "none",
                    msUserSelect: "none",
                  }}>
                  Sort by
                  <span
                    onClick={handleSortByValue}
                    style={{
                      cursor: "pointer",
                      color: localStorage.getItem("clientAccentColor"),
                      userSelect: "none",
                      WebkitUserSelect: "none",
                      MozUserSelect: "none",
                      msUserSelect: "none",
                    }}>
                    {` ${sortBy}`}
                  </span>
                </Typography>
              </Box>
              {clientLicenseData?.map((item) => {
                return (
                  <List onClick={() => navigateTocurrentpage(item)}>
                    <Card
                      sx={{
                        marginBottom: "5px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      <ListItemButton>
                        <ListItemIcon>{item?.icon}</ListItemIcon>
                        <ListItemText primary={<h4>{item?.appName}</h4>} />
                      </ListItemButton>
                      {item.isPin && (
                        <ListItemIcon sx={{ marginLeft: "auto" }}>
                          {" "}
                          {/* Add ListItemIcon for the pushpin */}
                          <PushPinIcon
                            style={{
                              color: localStorage.getItem("clientAccentColor"),
                            }}
                          />
                        </ListItemIcon>
                      )}
                    </Card>
                  </List>
                );
              })}
              <Stack direction="row" justifyContent={"center"}>
                <Chip
                  icon={<EditIcon fontSize="small" />}
                  style={{ cursor: "pointer" }}
                  label="Edit"
                  onClick={() => setOpenEditLicenseListModal(true)}
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </section>
      {viewPictureViewerOpen && (
        <PictureViewer
          isOpen={viewPictureViewerOpen}
          onClose={() => {
            setViewPictureViewerOpen(false);
          }}
          pictures={clientApprovedPictureBoardData?.map((pic) => ({
            src: pic?.fileUrl || "",
            alt: pic?.pictureDescription || "",
          }))}
          viewerIndex={0}
        />
      )}
      {open && (
        <HomeModel
          open={open}
          handleClose={handleClose}
          selectedItem={selectedItem}
        />
      )}
      {openEditLicenseListModal && (
        <EditClientLicenseListModal
          open={openEditLicenseListModal}
          handleClose={() => {
            setOpenEditLicenseListModal(false);
            getList();
          }}
          clientLicenseData={clientLicenseData}
          setClientLicenseData={setClientLicenseData}
          getList={getList}
        />
      )}
      <Addexpenses />
    </>
  );
}

function EditClientLicenseListModal(props) {
  const handleIconClick = async (item, index) => {
    try {
      let updatedData = props.clientLicenseData.map((data, i) => {
        if (i === index) {
          return { ...data, loading: true };
        }
        return data;
      });
      props?.setClientLicenseData(updatedData);

      const obj = {
        Id: item?.id,
        IsPin: !item?.selected,
      };
      await updateEmployeeLicensesIsPinFlag(obj);

      updatedData = props.clientLicenseData.map((data, i) => {
        if (i === index) {
          return { ...data, loading: false, selected: !data.selected };
        }
        return data;
      });
      props?.setClientLicenseData(updatedData);
    } catch (err) {
      console.log("UpdateEmployeeLicensesIsPinFlag ERROR : ", err);
      const updatedData = props.clientLicenseData.map((data, i) => {
        if (i === index) {
          return { ...data, loading: false };
        }
        return data;
      });
      props?.setClientLicenseData(updatedData);
    }
  };

  useEffect(() => {
    props?.getList();
  }, []);

  return (
    <Modal open={props?.open} onClose={props?.handleClose}>
      <div className="modal-center" style={{ outline: "none" }}>
        <div
          className="modal-card"
          style={{
            height: "auto",
            width: "600px",
            outline: "none",
            overflowY: "auto",
            maxHeight: "500px",
          }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              gap: "10px",
              marginLeft: "10px",
            }}>
            <IconButton
              color="primary"
              onClick={props?.handleClose}
              style={{ padding: "0px" }}>
              <Close />
            </IconButton>
          </div>

          {props?.clientLicenseData.map((item, index) => {
            return (
              <List
                disabled={item?.loading}
                onClick={() => handleIconClick(item, index)}>
                <Card
                  sx={{
                    marginBottom: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}>
                  <ListItemButton>
                    <ListItemIcon>
                      {item?.icon}
                      {/* <MessageIcon style={{ color: localStorage.getItem("clientAccentColor") }} /> */}
                    </ListItemIcon>
                    <ListItemText primary={<h4>{item?.appName}</h4>} />
                  </ListItemButton>
                  <ListItemIcon
                    sx={{ marginLeft: "auto", cursor: "pointer" }}
                    onClick={() => handleIconClick(item, index)}
                    disabled={item?.loading}>
                    {item?.loading ? (
                      <CircularProgress
                        style={{
                          color: localStorage.getItem("clientAccentColor"),
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    ) : (
                      <PushPinIcon
                        style={{
                          color: item.selected
                            ? localStorage.getItem("clientAccentColor")
                            : BIGrey,
                        }}
                      />
                    )}
                  </ListItemIcon>
                </Card>
              </List>
            );
          })}
        </div>
      </div>
    </Modal>
  );
}
