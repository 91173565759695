import { restGet, restPost } from "./RestClient";
import { form_headers, headers } from "./UserApi";


export async function getChatGroupDashboard(userId) {
    const res = await restGet(`chatGroups/GetChatGroupDashboard/${userId}`, headers);
    return res;
}

export async function getChatGroupMessages(userId, chatGroupId, pageNr, nrRecordsPerPage) {
    try {
        const res = await restGet(`chatGroups/GetChatGroupMessages/${userId}/${chatGroupId}/${pageNr}/${nrRecordsPerPage}`, headers);
        return res;
    }
    catch (error) {
        throw error;
    }
}

export async function sendChatGroupNotifications(chatGroupId, groupName, userId) {
    try {
        const res = await restGet(`chatGroups/sendChatGroupNotifications/${chatGroupId}/${groupName}/${userId}`, headers);
        return res;
    }
    catch (error) {
        console.log(error);
        throw error;
    }
}

export async function getMyChatGroupsUsers(userId, groupChatId) {
    try {
        const res = await restGet(`chatGroups/GetMyChatGroupsUsers/${userId}/${groupChatId}`, headers);
        return res
    }
    catch (error) {
        throw error;
    }
}


export async function saveMessagePictures(params) {
    try {
        const newFormHeader = form_headers.Authorization === "Bearer null" ? { ...form_headers, Authorization: `Bearer ${localStorage.getItem('token')}` } : form_headers
        const res = await restPost(`chatGroups/SaveMessagePictures`, params, newFormHeader);
        return res.data;
    }
    catch (error) {
        console.log(error);
        throw error;
    }
}

export async function getUnreadMessagecount(userId, groupChatId) {
    try {
        const res = await restGet(`ChatGroups/GetChatGroupUnreadMessages/${userId}/${groupChatId}`, headers);
        return res
    }
    catch (error) {
        throw error;
    }
}

export async function searchUsersMembers(currentUserId, searchUserId, searchStr) {
    try {
        const res = await restGet(`users/searchUsersMembers/${currentUserId}/${searchUserId}/${searchStr}`, headers);
        console.log("response", res)
        return res;
    }
    catch (error) {
        throw error;
    }
}

export async function saveChatGroupUsers(params) {
    try {
        const res = await restPost(`chatGroups/SaveChatGroupUsers`, params, headers);
        return res;
    }
    catch (error) {
        throw error;
    }
}

export async function leaveChatGroup(params) {
    try {
        const res = await restPost(`chatGroups/LeaveChatGroup`, params, headers);
        return res;
    }
    catch (error) {
        throw error;
    }
}

export async function saveChatGroupUserMessages(params) {
    try {
        const res = await restPost(`chatGroups/SaveChatGroupUserMessages`, params, headers);
        return res
    }
    catch (error) {
        throw error;
    }
}

export async function sendChatGroupFirebaseNotify(params) {
    try {
        const res = await restPost(`chatGroups/SendChatGroupFirebaseNotify`, params, headers);
        return res
    }
    catch (error) {
        throw error;
    }
}
