import React, { useState } from "react";

import { IconButton, FormControlLabel, Checkbox } from "@material-ui/core";
import {
    ExpandMore as ExpandIcon,
    ExpandLess as CollapseIcon
} from "@material-ui/icons";
import { stringToLocale } from "../../../assets/helpers";

function EducationCard(props) {

    const [isExpanded, setIsExpanded] = useState(true);


    return (
        <>
            <div className="card" >
                <div className="flex-container-horizontal">
                    <div className="flex-container-horizontal" style={{ flex: 1 }}>

                        <span style={{
                            display: 'flex', justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <h3>
                                Education
                            </h3></span>

                    </div>
                    <div>

                        {	// Show appropriate expand/collapse icon
                            isExpanded
                                ? (
                                    <IconButton
                                        color="primary"
                                        onClick={() => setIsExpanded(false)}>
                                        <CollapseIcon />
                                    </IconButton>
                                )
                                : (
                                    <IconButton
                                        color="primary"
                                        onClick={() => setIsExpanded(true)}>
                                        <ExpandIcon />
                                    </IconButton>
                                )
                        }
                    </div>
                </div>

                <div>
                {isExpanded ? (
                  <>
                    {props.certifications.length > 0 ? (
                      props.certifications.map((cert) => (
                        <Certification
                          key={cert.id}
                          cert={cert}
                          handleToggle={() => props.handleToggle(cert.id, "EducationCard")}
                        />
                      ))
                    ) : (
                      <p>You haven't added any certifications, degrees, or training experiences to your profile.</p>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
        </>
    )
}

export default EducationCard;

export function Certification(props) {
    // Display/TextField
    // fieldOfStudy
    const school = props.cert.school;
    const fieldOfStudy = props.cert.fieldOfStudy;
    const tradeReceived = props.cert.tradeReceived;
    const startDate = props.cert.startDate;
    const endDate = props.cert.endDate;
    const displayEndDate = endDate ? stringToLocale(endDate) : 'Present';

    return (
        <div className="flex-container-horizontal">
            <div className="flex-container-left">
                <div className="content-container">
                    <p><b>{school}</b></p>
                    <p>{fieldOfStudy}</p>
                    <p><b>{tradeReceived}</b></p>
                    <p style={{ fontSize: "14px" }}>{stringToLocale(startDate)} - {displayEndDate}</p>
                </div>
            </div>

            <div className="flex-container-right">
                <div className="input-container">
                    <FormControlLabel
                        control={
                            <Checkbox checked={props.cert.isSelected} color="primary" onChange={() => { props.handleToggle() }} />
                        }
                    />
                </div>
            </div>
        </div>
    )
}