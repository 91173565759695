import React, { useState } from "react";
import ProfileHeader from "./ProfileHeader";
import ContactInfoCard from "./sections/ContactInfoCard";
import ExperienceCard from "./sections/ExperienceCard";
import CertificationsCard from "./sections/CertificationsCard";
import "../../assets/css/Profile.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ProductCard from "./sections/ProductCard";
import Loader from "../../components/Loader";

function Profile(props) {
	const location = useLocation();
	// new code sanket 04-04-2024
	const [loading, setLoading] = useState(true);
	return (
		<div className="page">
			{loading && (
				<div style={{ margin: "auto" }}>
					<Loader />
				</div>
			)}
			<ProfileHeader onChangeSidebarInfo={props.onChangeSidebarInfo} loading={loading} />
			<div className="page-content" style={{ paddingTop: "0px" }}>
				{/* Responsive two-column layout */}
				<div className="resp-container-horizontal">
					<div className="flex-container-vertical" style={{ flex: 1 }}>
						<ContactInfoCard loading={loading} />
					</div>
					<div className="flex-container-vertical" style={{ flex: 2 }}>

						<ExperienceCard
							siteInfo={location?.state}
							siteProjectFromSite={location?.state?.siteProjectFromSite ? true : false}
							contactId={Number(localStorage.userId)}
							setLoading={setLoading}
							loading={loading}
						/>
						<CertificationsCard loading={loading} />
						<ProductCard loading={loading} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Profile;

