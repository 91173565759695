import React from "react";

import { Modal, Button } from "@material-ui/core";


/*
Warning message popup component with a "Go Back" button. Two optional buttons, a red and blue
button, can be added if the text and onClick function are defined.

PromptModal code in parent must be under any other modals in order to appear on top

Props (* required):
	*isOpen				- boolean; modal visibility variable
	*onClose			- function; to modify visibility state variable
	*title				- string; prompt title
	*desc				- string; prompt description
	redButtonText		- string; red button text
	onRedButtonClick	- function; executed on red button click
	blueButtonText		- string; blue button text
	onBlueButtonClick	- function; executed on blue button click

Usage:
	<PromptModal
		isOpen={}
		onClose={() => }
		title=""
		desc=""
		redButtonText=""
		onRedButtonClick={() => }/>
*/
function PromptModal(props) {
	return (
		<Modal
			disableEnforceFocus={true}
			open={props.isOpen}
			onClose={props.onClose}>
			<div className="modal-center">
				<div className="modal-card">
					<div className="modal-card-body">
						<div style={{ paddingBottom: "20px" }}>
							<h3>{props.title}</h3>
							<p>{props.desc}</p>
						</div>
						<div className="flex-container-horizontal" style={{ display: 'flex', justifyContent: "space-between" }}>
							{
								(!props.blueButtonText && !props.onBlueButtonClick) &&
								<Button
									variant="outlined"
									color="primary"
									onClick={props.onClose}>
									<b>Go Back</b>
								</Button>
							}

							<div className="flex-container-horizontal">
								{	// If blueButtonText and onBlueButtonClick is defined, show the blue button
									(props.blueButtonText2 && props.onBlueButtonClick2)
										? (
											<Button
												variant="contained"
												color="primary"
												onClick={props.onBlueButtonClick2}
												style={{ marginRight: "10px" }}>
												<b>{props.blueButtonText2}</b>
											</Button>
										)
										: <></>
								}
								{	// If blueButtonText and onBlueButtonClick is defined, show the blue button
									(props.blueButtonText && props.onBlueButtonClick)
										? (
											<Button
												variant="contained"
												color="primary"
												onClick={props.onBlueButtonClick}
												style={{ marginRight: "10px" }}>
												<b>{props.blueButtonText}</b>
											</Button>
										)
										: <></>
								}
								{	// If redButtonText and onRedButtonClick is defined, show the red button
									(props.redButtonText && props.onRedButtonClick)
										? (
											<Button
												variant="contained"
												color="secondary"
												onClick={props.onRedButtonClick}>
												<b>{props.redButtonText}</b>
											</Button>
										)
										: <></>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default PromptModal;