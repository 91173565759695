import { Card, Divider, IconButton, Modal, Typography } from "@mui/material"
import React, { useEffect, useState } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import { fetchClientjobs } from "../../../api/ClientApi";

export default function Clientjobs(props) {

    const [joblist, setJoblist] = useState([]);

    useEffect(() => {
        fetchClientjob();
    }, [])

    const fetchClientjob = async () => {
        let obj = {
            PageNr: 1,
            NrOfRecPerPage: 20,
            FullSearch: "",
            UserId: Number(localStorage?.userId),
            IncludeRecordNr: true,
            FetchAllowedRecordsOnly: false,
            SearchList: [
                {
                    Id: 1
                }
            ]
        }
        let getResponse = await fetchClientjobs(obj);
        console.log("getResponse", getResponse)
        setJoblist(getResponse?.data);

    }

    const closeClientjobs = () => {
        props.setShowjobs(false);
    }

    const selectClientJob = (item) => {
       props.setAddexpense({
            ...props.addExpense,
            clientJobCodeId: item
        });
       props.setShowjobs(false);
    }

    return (
        <Modal
            open={true}
            onClose={async () => closeClientjobs()}>
            <div className="modal-center add-expenses" style={{ maxHeight: "760px" }}>
                <div className="addexpenses-form">
                    <div className="form-header">
                        <Typography variant="h6" component="label" className="heading">
                            Jobs
                        </Typography>
                        <IconButton
                            color="primary"
                            onClick={async () => closeClientjobs()}
                            className="close-btn">
                            <CloseIcon style={{ color: "#fffff" }} />
                        </IconButton>
                    </div>
                    <Divider style={{ borderWidth: "1px", borderColor: "rgb(22 21 21 / 12%)" }} />
                    <div className="form-content" style={{ marginTop: "1rem" }}>
                        {joblist?.map((item) => (
                            <Card className="clientjob-card" onClick={() => selectClientJob(item)}>
                                <Typography style={{ cursor: "pointer" }} variant="h6" component="label" className="form-label" htmlFor="my-input">
                                    {/* Job Name : {props?.data?props?.data.id.name: item?.name} */}
                                    Job Name : {item?.name}
                                </Typography>
                                <Typography style={{ cursor: "pointer" }} variant="h6" component="label" className="form-label" htmlFor="my-input">
                                    Job Code : {item?.jobCode}
                                </Typography>
                            </Card>
                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    )

}
