import { restGet, restPost } from "./RestClient";
import { headers, form_headers } from "./UserApi";

// let headers = {
// 	"Content-Type": "application/json",
// 	"Accept": "application/json",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }

// let form_headers = {
// 	"Content-Type": "multipart/form-data",
// 	"Accept": "application/json",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }


// Get product options for Dropdown
export async function fetchProducts(args) {
	const res = await restPost("misc/fetchProducts/", args, headers);
	return res;
}

export async function searchProducts(args) {
	const res = await restPost("misc/SearchProducts/", args, headers);
	return res;
}


// Save new Product to the database
export async function saveProducts(args) {
	const res = await restPost("misc/saveProducts/", args, headers);
	return res;
}


export async function fetchProductPictures(args) {
	const res = await restPost("misc/fetchProductPictures/", args, headers);
	return res;
}
export async function fetchProductsPostsPictures(args) {
	const res = await restPost("misc/FetchProductsPostsPictures", args, headers);
	return res;
}

export async function fetchProductsFavoriteImages(args) {
	const res = await restPost("misc/FetchProductsFavouriteImages", args, headers);
	return res;
}
export async function saveProductsFavouriteImages(args) {
	const res = await restPost("misc/SaveProductsFavouriteImages", args, headers);
	return res;
}

export async function deleteProductsFavouriteImages(args) {
	const res = await restPost("misc/DeleteProductsFavouriteImages", args, headers);
	return res;
}


export async function fetchProductsPostsPicturesForWeb(args) {
	const res = await restPost("misc/FetchProductsPostsPicturesForWeb", args);
	return res;
}

export async function saveProductPictures(args) {
	const res = await restPost("misc/saveProductPictures/", args, form_headers);
	return res;
}


export async function deleteProductPictures(args) {
	const res = await restPost("misc/deleteProductPictures/", args, headers);
	return res;
}


// Mismatched name
export async function fetchProductReviews(args) {
	const res = await restPost("misc/fetchProductRatings/", args, headers);
	return res;
}


// Mismatched name
export async function saveProductReview(args) {
	const res = await restPost("misc/saveProductRatings/", args, headers);
	return res;
}


export async function fetchProductBarcodes(args) {
	const res = await restPost("misc/fetchProductBarcodes/", args, headers);
	return res;
}


export async function saveProductBarcodes(args) {
	const res = await restPost("misc/saveProductBarcodes/", args, headers);
	return res;
}

export async function getProductMembers(productId) {
	const res = await restGet("users/getProductMembers/" + localStorage.userId + "/" + productId, headers);
	return res;
}

export async function getProjectsForProduct(productId) {
	const res = await restGet("projects/getProjectsForProduct/" + localStorage.userId + "/" + productId, headers);
	return res;
}

export async function fetchUserProducts(args) {
	const res = await restPost("users/fetchUserProducts/", args, headers);
	return res;
}


export async function saveUserProducts(args) {
	const res = await restPost("users/saveUserProducts/", args, headers);
	return res;
}

export async function deleteUserProducts(args) {
	const res = await restPost("users/DeleteUserProducts/", args, headers);
	return res;
}

export async function fetchTradeProducts(args) {
	const res = await restPost("misc/fetchTradeProducts/", args, headers);
	return res;
}

export async function fetchTradeProductsForWeb(args) {
	const res = await restPost("misc/fetchTradeProductsForWeb/", args);
	return res;
}

export async function saveProductTrades(args) {
	const res = await restPost("misc/saveTradeProducts", args, headers);
	return res.data;
}

export async function deleteProductTrade(args) {
	const res = await restPost("Misc/DeleteTradeProducts2/", args, headers);
	return res;
}

export async function getProductDetails(productId) {
	const res = await restGet("Misc/GetProductDetailsForWeb/" + productId);
	return res;
}


export async function getUserExperiencesProducts(args) {
	const res = await restPost("Sites/FetchUsersExperiencesProducts", args, headers);
	return res;
}

export async function fetchProjectsForCompany(args) {
	const res = await restPost("Projects/FetchProjectCompanies2", args, headers);
	return res;
}

export async function fetchProjectsManufacturers(args) {
	const res = await restPost("Projects/FetchProjectsManufacturers", args, headers);
	return res;
}