import { Button, Card, CardContent } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { BIBlue, BIYellow } from "../../assets/buildidColors";
import BuildIDLogo from "../../assets/images/map-marker-yellow.png";

const SignInCard = (props) => {
    const history = useHistory();
    const location = useLocation();

    function handleSignInClick() {
        window.location.href = ("/redirect/" + props.detailsObj?.screenName + "/" + props.detailsObj?.itemId);
    }


    function handleSignUpClick() {
        // history.push({
        //     pathname: '/login',
        //     state: {  // location state
        //         clickFrom: "proxySite",
        //     },
        // })
        // history.push("/login")
        // window.history.pushState({ clickFrom: 'proxysite' }, "", "/");
        window.location.href = ("/signup");
        // window.history.replaceState("", "", "/login");
    }
    return (
        <>
            <Card>
                <CardContent>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center", padding: "25px 0px" }}>
                        <div>
                            <img
                                src={BuildIDLogo}
                                alt=""
                                style={{
                                    width: "50px", height: "auto"
                                }} />
                        </div>
                        <div>
                            <p>You must be a BuildID member to view this content.</p>
                        </div>
                        <div onClick={handleSignInClick}>
                            <Button variant="contained" style={{ width: '16rem', fontWeight: 'bold', background: BIYellow, color: BIBlue }} >
                                SIGN IN
                            </Button>
                        </div>
                        <div style={{ paddingTop: '20px' }}>
                            <p><b>Don't have an account? <span style={{ color: BIBlue, textDecoration: 'underline', cursor: 'pointer' }} onClick={handleSignUpClick}>Sign up for free</span></b></p>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </>)
}

export default SignInCard;