import React, { useCallback, useEffect, useState } from "react";
import { searchManufacturers } from "../../api/CompanyApi";
import SearchField from "../../components/SearchField";
import SearchListSection from "../search/SearchListSection";

export default function Manufacturelist() {

    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);
    const [getUpdatedData, setGetUpdatedData] = useState(false);

    const getManufacture = useCallback(async (pageNumber, searchText) => {
        const obj = {
            UserId: localStorage?.userId,
            IncludeRecordNr: true,
            SearchString: searchText ?? "",
            PageNumber: pageNumber === "" ? 1 : pageNumber,
            NrRowsPerPage: 20
        }
        const res = await searchManufacturers(obj);
        console.log("res", res);
        return res?.data;
    }, [page])


    const increaseOffset = (callback) => {
        setPage((prevPageNr) => prevPageNr + 1);
        callback(page + 1);
    }

    const clearSearch = (value) => {
        setSearchText(value);
        if (value === "") {
            setGetUpdatedData(true)
        }
    }


    return (
        <div className="page">
            <div className="page-content">
                <div className="search-container" style={{ position: "fixed", zIndex: 999 }}>
                    <div style={{ backgroundColor: 'white', borderRadius: '5px' }}>
                        <SearchField
                            value={searchText}
                            onChange={(value) => clearSearch(value)}
                            onSubmit={() => { setPage(1); setGetUpdatedData(true) }}
                            placeholder={"Search Manufacturers"}
                        />
                    </div>
                </div>
                <div className="list-container" id={"scrollList"}>
                    <SearchListSection
                        getData={getManufacture}
                        increaseOffset={increaseOffset}
                        page={page}
                        universalsearch={false}
                        searchText={searchText}
                        getUpdatedData={getUpdatedData}
                        setGetUpdatedData={(val) => setGetUpdatedData(val)}
                        from="manufacturer"
                    />
                </div>
            </div>
        </div>
    )
}