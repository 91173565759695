import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import ShareIcon from "@mui/icons-material/Share";
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import ExploreIcon from '@mui/icons-material/Explore';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import GroupIcon from '@mui/icons-material/Group';
const Data = () => {
  // You can fetch data from an API or define it here statically
  const ticketData = [
    {
      heading: "Tickets",
    },
    {
      icon: <FolderIcon />,
      text: "Manage all of your safety tickets in one convenient place"
    },
    {
      icon: <ShareIcon />,
      text: "Easily share your safety tickets with your team"
    }
  ];

  const sitesData = [
    {
      heading: "Sites",
    },
    {
      icon: <PersonIcon/>,
      text: "Add sites you were involved in to your timeline"
    },
    { icon: <EditIcon/>, text: "Create a rsume to showcase your hard work" },
    { icon: <ExploreIcon/>, text: "Explore sites around you and get detailed information" }
  ];
  const rewardsData = [
    {
      heading: "Rewards",
    },
    {
      icon: <PriceChangeIcon/>,
      text: "Earn BuilderBucks by being a part of our community"
    },
    { icon: <ShoppingCartIcon/>, text: "Redeem BuilderBucks in the Rewards Store for free stuff!" },
  ];
  const chatData = [
    {
      heading: "Chat",
    },
    {
      icon: <GroupIcon/>,
      text: "Communicate with anyone on your job site and others in our community"
    },
  ];

  return { ticketData, sitesData ,rewardsData,chatData};
};

export default Data;
