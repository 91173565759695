import React, { useState } from "react";
import PictureViewer from "../PictureViewer";

//card
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";
import { isISODate, isoToMonthDateYear } from "../../assets/helpers";
import { BIRed } from "../../assets/buildidColors";
import ReportModal from "../ReportModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const PostComments = (props) => {
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);

  const [pictureViewerOpen, setPictureViewerOpen] = useState(false);
  const [postCommentId, setPostCommentId] = useState(false);
  const [reportPostCommentModalOpen, setReportPostCommentModalOpen] =
    useState(false);
  const history = useHistory();
  const handleReport = (id) => {
    setPostCommentId(id);
    setMoreMenuAnchorEl(false);
    setReportPostCommentModalOpen(true);
  };

  function getPictureUrl(item) {
    if (item == null) {
      return null;
    } else if (item == "") {
      return null;
    } else {
      return item;
    }
  }
  return (
    <>
      <ReportModal
        isOpen={reportPostCommentModalOpen}
        onClose={() => {
          setReportPostCommentModalOpen(false);
          props?.closeModal();
        }}
        name={"Post Comment"}
        recordId={postCommentId}
      />

      <Card className="newsfeed-comment-card">
        <CardHeader
          className="newsfeed-comment-header"
          avatar={
            <Avatar
              alt="Profile Picture"
              src={
                props?.commentSource
                  ? props.comment.postedByPictureUrl
                  : props.comment.authorPictureUrl
              }
            />
          }
          title={props.comment.appName}
          subheader={
            <div>
              <div>
                <span
                  className={
                    Number(props.comment.authorUserId) !==
                    Number(localStorage.userId)
                      ? "link"
                      : ""
                  }
                  onClick={() => {
                    Number(props.comment.authorUserId) !==
                      Number(localStorage.userId) &&
                      history.push(
                        `/user-dashboard-profile/${props.comment.authorUserId}`
                      );
                  }}
                  style={{ color: "#4f4f4f" }}
                >
                  <b>
                    {props?.commentSource
                      ? `${props.comment.postedByFirstName} ${props.comment.postedByLastName}`
                      : `${props.comment.authorFirstName} ${props.comment.authorLastName}`}
                  </b>{" "}
                  {props?.comment?.tradeName
                    ? `(${props?.comment?.tradeName})`
                    : ""}
                </span>
              </div>
              <div>
                {props?.commentSource
                  ? props?.comment?.message
                  : // Prettyprinting ISO dates; if not ISO date, then print it as it is from API
                  // eg. "2 days"
                  isISODate(props.comment.age)
                  ? isoToMonthDateYear(props.comment.age)
                  : props.comment.age}
              </div>
            </div>
          }
          action={
            props?.commentSource ? (
              <></>
            ) : (
              <div>
                <IconButton
                  aria-label="settings"
                  size="small"
                  onClick={(event) => setMoreMenuAnchorEl(event.currentTarget)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={moreMenuAnchorEl}
                  open={Boolean(moreMenuAnchorEl)}
                  onClose={() => setMoreMenuAnchorEl(null)}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                >
                  <MenuItem
                    onClick={() => handleReport(props.comment.id)}
                    style={{ color: BIRed }}
                    dense
                  >
                    Report
                  </MenuItem>
                </Menu>
              </div>
            )
          }
        />
        {/* card body description */}
        <CardContent style={{ paddingTop: "0px", paddingBottom: "8px" }}>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ wordBreak: "break-all" }}
          >
            {props.comment.comment}
          </Typography>
          {getPictureUrl(props?.comment?.pictureUrl) == null ? null : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                className="picture-container"
                style={{ margin: "0px 16px", height: "350px", display: "flex" }}
              >
                <img
                  src={getPictureUrl(props?.comment?.pictureUrl)}
                  width="100%"
                  onClick={() => setPictureViewerOpen(true)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          )}
        </CardContent>
      </Card>
      <PictureViewer
        isOpen={pictureViewerOpen}
        onClose={() => setPictureViewerOpen(false)}
        src={props?.comment?.pictureUrl}
      />
    </>
  );
};

export default PostComments;
