import React, { useState } from "react";

import { IconButton, FormControlLabel } from "@material-ui/core";
import {
    ExpandMore as ExpandIcon,
    ExpandLess as CollapseIcon
} from "@material-ui/icons";

import Checkbox from '@mui/material/Checkbox';
import { BIBlue } from "../../../assets/buildidColors";


function TradesCard(props) {

    const [isExpanded, setIsExpanded] = useState(true);


    return (
        <>

            <div className="card" >
                <div className="flex-container-horizontal">
                    <div className="flex-container-horizontal" style={{ flex: 1 }}>
                        <span style={{
                            display: 'flex', justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <h3>
                                Trades
                            </h3></span>

                    </div>
                    <div>

                        {	// Show appropriate expand/collapse icon
                            isExpanded
                                ? (
                                    <IconButton
                                        color="primary"
                                        onClick={() => setIsExpanded(false)}>
                                        <CollapseIcon />
                                    </IconButton>
                                )
                                : (
                                    <IconButton
                                        color="primary"
                                        onClick={() => setIsExpanded(true)}>
                                        <ExpandIcon />
                                    </IconButton>
                                )
                        }
                    </div>
                </div>

                <div>

                    {
                        isExpanded
                        ? (
                            <>
                                {props.userTrades.length > 0 ? (
                                    <div>
                                        {props.userTrades.map((itemTrade, id) => (
                                            <TradeList key={id} id={id} itemTrade={itemTrade} handleToggle={props.handleToggle} />
                                        ))}
                                    </div>
                                ) : (
                                    <p>No data available for trades</p>
                                )}
                            </>
                        )
                            : <></>
                    }
                </div>

                {
                    isExpanded
                        ? (
                            <div className="content-container">

                            </div>
                        )
                        : <></>
                }
            </div>
        </>
    )
}

export default TradesCard;

export function TradeList(props) {
    return (<div key={props.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div> <p> {props.itemTrade.tradeName}   </p> </div>
        <div><FormControlLabel
            control={
                <Checkbox checked={props.itemTrade.isSelected} style={{color:BIBlue}} onChange={() => props.handleToggle(props.itemTrade.id, "TradesCard")} />
            }
        /> </div></div>)
}