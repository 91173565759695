import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Card, Avatar, TextField, Button } from "@mui/material";
import Rating from '@mui/material/Rating';
import { saveProductReview } from "../../api/ProductsApi";
import PromptModal from "../../components/PromptModal";
import Loader from "../Loader";

export default function ReviewInput(props) {
	const [open, setOpen] = useState(false);
	const [comment, setComment] = useState("");
	const [discardModalOpen, setDiscardModalOpen] = useState(false);
	const [rating, setRating] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	function handleCancelButtonClick() {
		// If there are changes to the form, prompt user before discarding
		if (rating !== 0 || comment !== "") {
			// setOpen(false);
			setDiscardModalOpen(true);
		}
		else setOpen(false);
	}

	function handleDiscardClick() {
		setRating(0);
		setComment("");
		setDiscardModalOpen(false);
		setOpen(false);
	}


	//saves rating to api
	async function handlePostReview() {
		try {
			setIsLoading(true);
			const newProductRating = {
				ReturnRecordId: false,
				ReturnRecordError: true,
				UserId: localStorage.userId,
				SaveList: [
					{
						Id: 0,
						ProductId: props.productId,
						Rating: rating,
						Comments: comment,
						ModifiedBy: localStorage.userId,
					},
				],
			}
			await saveProductReview(newProductRating);
			handleDiscardClick();
			await props.updateRatings();
			setIsLoading(false);
		} catch (error) {
			console.log('saveProductReview error', error)
		}
	}

	return (
		<div>

			<PromptModal
				isOpen={discardModalOpen}
				onClose={() => {
					setDiscardModalOpen(false);
					setOpen(true);
				}}
				title="Are you sure you want to leave?"
				desc="Your review will be discarded."
				redButtonText="Discard"
				onRedButtonClick={handleDiscardClick} />

			{isLoading
				? <Loader /> :
				(
					<Card>
						<div className="flex-container-horizontal" style={{ alignItems: "center", padding: "0px 16px" }}>
							<Avatar
								alt="Profile Picture"
								src={localStorage?.pictureUrl} />

							{/* comment writing body description */}
							<div className="input-container" style={{ flex: 1 }}>
								<TextField
									variant="outlined"
									size="small"
									fullWidth
									onClick={handleClickOpen}
									placeholder="Write a Review" />
							</div>
						</div>
					</Card>
				)

			}


			<Dialog open={open} onClose={handleCancelButtonClick} >
				<DialogTitle>Write a Review</DialogTitle>
				<DialogContent style={{ width: '500px' }}>
					<div className="flex-container-horizontal" style={{ marginBottom: '15px' }}>
						<div className="flex-container-vertical">
							<Avatar
								alt="Profile Picture"
								src={localStorage.pictureUrl} />
						</div>

						<div className="flex-container-vertical" style={{ flex: 1, marginLeft: "10px" }}>
							<div>{localStorage.firstName + " " + localStorage.lastName}</div>
							<div>{localStorage?.tradeName}</div>
						</div>
					</div>

					<div className="input-container">
						<Rating
							name="simple-controlled"
							value={rating}
							onChange={(event, newValue) => setRating(newValue)} />
					</div>

					<div className="flex-container-horizontal">
						<TextField
							variant="outlined"
							size="small"
							sx={{ backgroundColor: "white" }}
							fullWidth
							value={comment}
							onChange={event => setComment(event.target.value)}
							placeholder="What do you think about this product? (optional)" />
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancelButtonClick}>Cancel</Button>
					<Button
						onClick={handlePostReview}
						disabled={(comment === "" && rating === 0)}>
						Post
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}