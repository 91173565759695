import React, { useState } from "react";
import { Checkbox, IconButton } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";

import { TicketModal } from "./Modals";

import { stringToLocale } from "../../assets/helpers";

import { BIBlue, BILightRed, BIRed } from "../../assets/buildidColors";
import ErrorIcon from '@mui/icons-material/Error';
import GroupIcon from '@mui/icons-material/Group';
import AlertModal from "../../components/AlertModal";
import { useEffect } from "react";
import moment from "moment";

function TicketCard(props) {
	const [modalOpen, setModalOpen] = useState(false);
	const [modalEditMode, setModalEditMode] = useState(false);
	const [sharedTicketAlertModalOpen, setSharedTicketAlertModalOpen] = useState(false);
	const [expirTicketAlertModalOpen, setExpireTicketAlertModalOpen] = useState(false);
	const [shareTicketData, setShareTicketData] = useState({});

	const handleToggle = (index) => {
		props.displayTickets[index].isSelected = props.displayTickets[index].isSelected ? false : true;
		props.setDisplayTickets([...props.displayTickets]);
		props.displayTickets.some((item) => item.isSelected) ? props.setShareTicketButtonEnable(false) : props.setShareTicketButtonEnable(true)
		// props.setSendButtonVisible(response.filter((res) => res.isTimesheetChecked));
	}

	function handleSharedWithMeToggle(index, id) {
		props.displayTickets.map((item) => {
			item.tickets.map((ticket) => {
				if (id === ticket.id) {
					ticket.isSelected = ticket.isSelected ? false : true;
				}
			})

		})

		props.setDisplayTickets([...props.displayTickets]);
		props.handleDeleteDisable();
	}




	// Opens ticket modal
	function handleModalOpen(data) {
		if (props.modalEditMode) {
			const filteredData = props.displayTickets.filter((item) => item.id === data.id);
		}
		setShareTicketData(data);
		setModalOpen(true);
	}

	// Opens ticket modal with editMode active
	function handleModalOpenEdit() {
		setModalEditMode(true);
		handleModalOpen();
	}

	function handleModalClose() {
		setModalOpen(false);
		setModalEditMode(false);	// Reset edit mode to default = false
	}

	return (
		<>
			<TicketModal
				config={props.config}
				modalOpen={modalOpen}
				modalEditMode={modalEditMode}
				shareTicketData={shareTicketData}
				onModalClose={handleModalClose}
				onEditModeChange={setModalEditMode}
				onDelete={props.onDelete}
				onSave={props.onSave}
				ticket={props.ticket}
				getUpdatedShareList={props?.getUpdatedShareList}
			/>

			<AlertModal
				isOpen={expirTicketAlertModalOpen}
				onClose={() => setExpireTicketAlertModalOpen(false)}
				body={"This Ticket has expired."}
			/>

			<AlertModal
				isOpen={sharedTicketAlertModalOpen}
				onClose={() => setSharedTicketAlertModalOpen(false)}
				body={"This ticket has been shared. People with access can view your Ticket and any changes you make to it."}
			/>

			<div className="ticket-card"
				style={Date.parse(props.ticket?.expiry) < new Date()	// Set background as red if ticket is expired
					? { backgroundColor: BILightRed }
					: {}}>
				<div className="ticket-card-image" onClick={() => handleModalOpen(props?.ticket)}>
					{
						(props.ticket?.frontPictureUrl !== "")
							? (<img src={props.ticket?.frontPictureUrl} alt="Ticket front" />)
							: (<img style={{ display: "none" }} alt="" />)
					}
				</div>

				<div className="flex-container-horizontal">
					{props.config === "view-edit" || props.config === "view-only" ? <div className="input-container">
						<Checkbox
							style={{ color: BIBlue }}
							onChange={() => props?.config === "view-edit" ? handleToggle(props.index) : handleSharedWithMeToggle(props?.index, props?.id)}
							checked={props.ticket?.isSelected}
						/>
					</div> : <></>}

					<div className="flex-container-left" onClick={() => handleModalOpen(props?.ticket)}>
						<div className="content-container" style={{ padding: "10px" }}>
							<p><b>{props.ticket?.ticketType}</b></p>
							<p><b>{props.ticket?.issuedBy}</b></p>
							<p style={{ color: BIRed }}>{
								props.ticket?.expiry
									? moment(props.ticket?.expiry).format("MMM D, YYYY")
									: ""
							}</p>
						</div>
					</div>

					{
						(props.config !== "view-only")
							? (
								<>
									{/* { Date.parse(props.ticket.expiry) < new Date() &&
								<div className="flex-container-right">
									<div className="input-container">
										<IconButton
											style={{color:'red'}}
											onClick={handleModalOpenEdit}>
											<ErrorIcon />
										</IconButton>
									</div>
								</div>
								} */}
									<div className="flex-container-right">
										<div className="input-container">

											{Date.parse(props.ticket?.expiry) < new Date() ? <IconButton
												style={{ color: 'red' }}
												size="small"
												onClick={() => setExpireTicketAlertModalOpen(true)}>
												<ErrorIcon fontSize="small" />
											</IconButton> : <></>}

											{props.ticket?.noOfUsersSharedWith !== 0 ? <IconButton
												color="primary"
												size="small"
												onClick={() => setSharedTicketAlertModalOpen(true)}>
												<GroupIcon fontSize="small" />
											</IconButton> : <></>}

											<IconButton
												color="primary"
												onClick={handleModalOpenEdit}>
												<EditIcon fontSize="small" />
											</IconButton>

										</div>
									</div>
								</>
							)
							: (<></>)
					}

				</div>
			</div>
		</>
	)
}

export default TicketCard;
