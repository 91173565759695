import React, { useState, useEffect } from "react";
import { Close as CloseIcon, RemoveCircleOutline as RemoveIcon, } from "@material-ui/icons";
import Loader from "../../components/Loader";
import PromptModal from "../../components/PromptModal";
import AlertModal from "../../components/AlertModal";
import { getRecentlyInteractUsers, saveRecentlyInteractUsers, } from "../../api/UserApi";
import { searchUsersMembers, sendTickets, unshareTicketsWithUser, } from "../../api/TicketsApi";
import { BIBlue } from "../../assets/buildidColors";
import TicketCard from "./TicketCard";
import { FormControlLabel, Autocomplete, Avatar, Button, Checkbox, Chip, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Modal, Stack, TextField, Typography, Box, ListItemIcon } from "@mui/material";
import { ToastProvider, useToasts } from "react-toast-notifications";

function ShareModal(props) {
  const userId = Number(localStorage.userId);
  const [isLoading, setIsLoading] = useState(false);
  const [ticketSentAlert, setTicketSentAlert] = useState(false);
  const [isSendPdfCopy, setIsSendPdfCopy] = useState(false); // Unshare PromptModal
  const [unshareModalOpen, setUnshareModalOpen] = useState(false);
  const [toUnshareWith, setToUnshareWith] = useState({}); // User to unshare ticket with
  const [toSendWith, setToSendWith] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [shareWith, setShareWith] = useState([]); // Users that current user is sharing with
  const [recentInteractUsers, setRecentInteractUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [allUserList, setAllUserList] = useState([]);

  // async function fetchRecentInteractUsers() {
  const fetchRecentInteractUsers = async () => {
    const res = await getRecentlyInteractUsers(Number(localStorage.userId));
    setRecentInteractUsers(res);
  }
  useEffect(() => {
    fetchRecentInteractUsers();
    getUserList([]);
  }, [props?.modalOpen]);

  const getUserList = async (item) => {
    const getValue = await searchUsersMembers(Number(localStorage.getItem('userId')), 0, "");
    setAllUserList(getValue);
    setUserList(getValue);
  };

  function resetCheckBoxSelect() {
    const tmpArr = props.displayTickets.map((item) => {
      if (item.isSelected) {
        item.isSelected = false;
        return item;
      } else {
        return item;
      }
    });
    props.setDisplayTickets(tmpArr);
  }

  const handleRecentInteractUserClick = (user) => {
    if (toSendWith.find((item) => item.userEmail === user.interactUserEmail)) {
      return;
    }
    if (user && user.interactUserEmail) {
      setToSendWith((toSendWith) => [
        ...toSendWith,
        {
          userEmail: user?.interactUserEmail,
          userId: user?.interactUserId,
        },
      ]);
      const filteredUserList = userList.filter((item) => item.userEmail !== user.interactUserEmail);
      setUserList(filteredUserList);
    }
  };
  function handleSendPdfCopy() {
    isSendPdfCopy ? setIsSendPdfCopy(false) : setIsSendPdfCopy(true);
  }

  async function handleSendClick() {
    try {
      setIsLoading(true);

      const selectedUsers = toSendWith.map((user) => {
        if (user.userEmail) {
          return user?.userEmail;
        } else {
          return user;
        }
      });

      const shareData = {
        Emails: selectedUsers,
        Tickets:
          props.config === "view-edit"
            ? [props.shareTicketData.id]
            : props.displayTickets
              .filter((ticket) => ticket.isSelected)
              .map(({ id }) => id),
        UserId: userId,
        IsSendPDF: isSendPdfCopy,
      };

      const uniqueEmails = [...new Set(shareData.Emails)];
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // Arrays to hold valid and invalid emails
      const validEmails = [];
      const invalidEmails = [];

      // Check each email against the regex pattern
      uniqueEmails.forEach(email => {
        if (emailPattern.test(email)) {
          validEmails.push(email);
        } else {
          alert(`The "${email}" is Invalid email address!`);
          // addToast(`The "${email}" is Invalid email address!`, {  appearance: 'error', autoDismiss: true });
          invalidEmails.push(email);
          return;
        }
      });

      if (invalidEmails.length === 0) {
        shareData.Emails = validEmails;
        const sendTicketRes = await sendTickets(shareData);

        if (sendTicketRes === "") {
          props.config === "view-edit"
            ? props.onModalClose()
            : resetCheckBoxSelect();
          props.setShareTicketButtonEnable(true);
          setTicketSentAlert(true);
          props.onModalClose();

          toSendWith.map(async (user) => {
            const saveRecentChatUsers = {
              UserId: Number(userId),
              ReturnRecordId: true,
              ReturnRecordError: true,
              SaveList: [
                {
                  Id: 0,
                  UserId: Number(userId),
                  InteractUserId: user?.userId,
                  ModifiedBy: Number(userId),
                  fromScreen: "Tickets",
                },
              ],
            };
            await saveRecentlyInteractUsers(saveRecentChatUsers);
          });
          fetchRecentInteractUsers(userId);
          setToSendWith([]);
        }
      }
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRemoveShareWithUser() {
    try {
      await unshareTicketsWithUser(toUnshareWith.sharedWithEmail);

      await setToUnshareWith({});
    } catch (error) {
      alert(error);
    }
  }

  const handleInputChange = (e) => {
    setNewEmail(e.target.value.trim());
  };

  function handleChange(event, newValue) {
    handleEmail(newValue);
  }
  const handleEmail = (email) => {
    if (email?.length != 0) {
      let tmpArr = email.map((item) => {
        if (item.contactEmail) {
          return {
            contactEmail: item.contactEmail,
            contactId: item.contactId,
          };
        }
        if (toSendWith.find((user) => item.userId === user.userId)) {
          const filteredUserList = allUserList.filter((user) => item.userEmail !== user.userEmail);
          setUserList(filteredUserList)
          return item;
        }
        else {
          return item;
        }
      });
      setNewEmail("");
      setToSendWith(tmpArr);
    } else {
      setUserList(allUserList)
      setNewEmail("");
      setToSendWith([]);
    }
  };

  return (
    <>
      <PromptModal
        isOpen={unshareModalOpen}
        onClose={() => setUnshareModalOpen(false)}
        title={
          "Are you sure you want to unshare all your valid tickets with " +
          toUnshareWith.sharedWithEmail +
          "?"
        }
        redButtonText="Unshare"
        onRedButtonClick={() => {
          handleRemoveShareWithUser();
          setUnshareModalOpen(false);
        }}
      />

      <AlertModal
        isOpen={ticketSentAlert}
        onClose={() => setTicketSentAlert(false)}
        title={"Tickets Sent Successfully..."}
      />

      <Modal open={props.modalOpen} onClose={props.onModalClose}>
        <div className="modal-center">
          <ToastProvider>
            <div className="modal-card" style={{ width: "850px", maxHeight: '650px', overflow: 'auto' }}>
              <div className="modal-card-header-buttons">
                <IconButton color="primary" onClick={props.onModalClose}>
                  <CloseIcon />
                </IconButton>
              </div>

              <div className="modal-card-body" style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                <div className="flex-container-vertical">
                  <h2>Add Tickets To Share</h2>
                  <div style={{ flex: 1 }}>
                    <div
                      className="flex-container-horizontal"
                      style={{ alignItems: "stretch" }}>
                      <Autocomplete
                        multiple
                        freeSolo
                        fullWidth
                        size="small"
                        className="newuser-list"
                        disableCloseOnSelect
                        open={newEmail?.length > 2}
                        options={userList}
                        onChange={handleChange}
                        value={toSendWith}
                        filterSelectedOptions={true}
                        getOptionLabel={(option) => `${option?.firstName} ${option?.lastName} ${option?.userEmail}`}
                        renderOption={(props, option, index) => (
                          <List {...props} key={option?.userId}>
                            <ListItem style={{ padding: '0px' }} disablePadding>
                              <IconButton color="primary" disabled>
                                <Avatar src={option?.pictureUrl} />
                              </IconButton>
                              <ListItemText style={{ paddingLeft: '10px' }}
                                primary={<span>{option?.firstName + " " + option?.lastName}</span>}
                                secondary={option?.userEmail}
                              />
                            </ListItem>
                          </List>
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              label={option?.userEmail ? `${option?.userEmail}` : option}
                              {...getTagProps({ index })}
                              key={index}

                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search"
                            onChange={(e) => handleInputChange(e)}
                          />
                        )}
                      />
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <Button
                          variant="contained"
                          disabled={toSendWith?.length == 0}
                          color="primary"
                          onClick={handleSendClick}>
                          Send
                        </Button>
                      )}
                    </div>
                  </div>
                  {recentInteractUsers.length != 0 ?
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            <b>Recent</b>
                          </Typography>
                        </Grid>
                        {recentInteractUsers.map((user, index) => (
                          <Grid item xs={12} sm={6} md={4} lg={3.3} key={index} style={{ padding: '0px' }} >
                            <List key={index} disablePadding>
                              <ListItem style={{ padding: '0px' }} disablePadding>
                                <ListItemButton onClick={() => handleRecentInteractUserClick(user)}>
                                  <ListItemIcon color="primary" disabled>
                                    <Avatar src={user?.interactUserImageUrl} />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={user.interactUserName.length > 16
                                      ? user.interactUserName.substring(0, 16) + "..."
                                      : user.interactUserName}
                                    secondary={user.interactUserTradeName.length > 16
                                      ? user.interactUserTradeName.substring(0, 16) + "..."
                                      : user.interactUserTradeName}
                                  />
                                </ListItemButton>
                              </ListItem>
                            </List>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    : <></>}
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: BIBlue }}
                        checked={isSendPdfCopy}
                        onChange={handleSendPdfCopy}
                      />
                    }
                    label="Send PDF Copy"
                  />

                  <Typography variant="h6">Tickets to Share</Typography>
                  {props.displayTickets && (
                    <div className="ticket-cards-container2">
                      {props.displayTickets.map((ticket, index) =>
                        ticket.isSelected ? (
                          <TicketCard key={index} ticket={ticket} index={index} />
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  )}

                  {props.tempConfig === "previewTicket" && (
                    <div className="ticket-cards-container2">
                      <TicketCard ticket={props.shareTicketData} />
                    </div>
                  )}

                  <div className="share-list-container">
                    {shareWith.map((user, index) => (
                      <div
                        key={index}
                        className="flex-container-horizontal"
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "5px 20px",
                        }}>
                        <p>
                          {user.sharedWithUser}
                          <span style={{ color: "grey" }}>
                            {" "}
                            ({user.sharedWithEmail})
                          </span>
                        </p>

                        <IconButton
                          color="primary"
                          onClick={async () => {
                            await setToUnshareWith(user);
                            await setUnshareModalOpen(true);
                          }}>
                          <RemoveIcon />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </ToastProvider>
        </div>
      </Modal>
    </>
  );
}

export default ShareModal;
