import React from 'react'
import MontageCard from './MontageCard';

function MontageList(props) {

    return (
        <>
            <div className="store-cards-container">
                {props.montageData.map((item, index, arr) => {
                    return (
                        <div key={index}>
                            <MontageCard
                                fetchMontage={props.fetchMontage}
                                setActivePage={props.setActivePage}
                                setNewMontage={() => props.setNewMontage()}
                                editMontage={props.editMontage}
                                id={item.id}
                                data={item}
                                musicUrl={item.musicUrl}
                                name={item?.name}
                                imageFile={item.fileList[0]?.fileUrl}
                                setAddMontageBtnVisible={props.setAddMontageBtnVisible}
                                arr={arr}
                            /> </div>)
                })}
            </div>
        </>
    )
}

export default MontageList;