import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "../../assets/css/Rewards.css";
function Store(props) {
  let history = useHistory();
  const activeTab = props?.activeTab ?? "store";

  return (
    <div className="page rewards-page">
      <div className="header">
        <div className="page-content">
          <h1>Rewards</h1>
        </div>
      </div>
      <div className="page-content">
        <div className="activity-cards-container">
          <div className="flex-container-horizontal">
            <div
              className="flex-container-left"
              style={{ display: "flex", flexDirection: "row" }}>
              <div className="rewards-input-container">
                <div style={{ backgroundColor: "white", borderRadius: "5px" }}>
                  <Button
                    variant={activeTab === "store" ? "contained" : "outlined"}
                    color="primary"
                    onClick={() => {
                      history.push("/rewards/store/");
                    }}>
                    Store
                  </Button>
                </div>
              </div>
              <div className="rewards-input-container">
                <div style={{ backgroundColor: "white", borderRadius: "5px" }}>
                  <Button
                    variant={
                      activeTab === "earn-builderbucks"
                        ? "contained"
                        : "outlined"
                    }
                    color="primary"
                    // onClick={() => { history.push("/rewards/earn-builderbucks/"); }}
                    onClick={() => {
                      history.push("/rewards/earn-builderbucks/");
                    }}>
                    Earn Builder Bucks
                  </Button>
                </div>
              </div>
            </div>
            <div
              className="flex-container-right"
              style={{ display: "flex", flexDirection: "row" }}>
              <div className="rewards-input-container">
                <div style={{ backgroundColor: "white", borderRadius: "5px" }}>
                  <Button
                    variant={
                      activeTab === "account-activity"
                        ? "contained"
                        : "outlined"
                    }
                    color="primary"
                    onClick={() => {
                      history.push("/rewards/account-activity/");
                    }}>
                    Rewards Activity Log
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Store;
