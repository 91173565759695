import firebase from "firebase/compat/app";
import "firebase/compat/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyDbSSyyPW8MEb2R8jT5dSb5ntliwi3SbZU",
  authDomain: "mybuilderid-e7f6c.firebaseapp.com",
  databaseURL: "https://mybuilderid-e7f6c.firebaseio.com",
  projectId: "mybuilderid-e7f6c",
  storageBucket: "mybuilderid-e7f6c.appspot.com",
  messagingSenderId: "552465314432",
  appId: "1:552465314432:web:d442f646a8a6e09f8cac65",
  measurementId: "G-TQQMXF62HF"
};

export const firebase_app = firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

console.log("messaging 1 -----------",messaging)

export const getToken = async () => {
  // debugger;
  console.log("getToken----------")
  let currentToken = "";
  try {
    currentToken = await messaging.getToken({
      // vapidKey: "BCqP195jNssapgeeygO8drnDTD9ScxKI-hwvN-zb67fNjRhmyWoJlQ8AuFoReTanbTw0I21vkLqVB3Ae63ioCto",
      // vapidKey: "BMJ661C-UrC8E2odc99PC-QrTkLTZPzkTomBrToYxElmIsmozUYP34lUJHVTuBLMvE1MnwyMVt0n-H232UDleZg",
      vapidKey: "BD9mg7R5aFWb4WOG9UFPc1vWfpkkmD6_7Fs-06Y8DqIFexQTN398M0a95fwyNsoxiRfMGRdfTJQyFmN2BWr-wjA",
    });
    console.log("current token", currentToken)
  } catch (error) {
    console.error("An error occurred while retrieving token.", error);
  }
  return currentToken;
};

export { messaging };

