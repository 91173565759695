import { restPost } from "./RestClient";
import { headers } from "./UserApi";

export async function saveUserQuestion(data) {
  const res = await restPost("users/saveUserQuestion/", data, headers);
  return res.data;
}

export async function saveUserAnswers(data) {
  const res = await restPost("users/saveUserAnswers/", data, headers);
  return res;
}
export async function SaveUserQuizAnswers(data) {
  const res = await restPost("users/saveUserQuizAnswers", data, headers);
  return res;
}
