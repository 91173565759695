import React, { useState } from 'react'
import { Modal, IconButton, Button } from "@material-ui/core";
import {
    Close as CloseIcon,
} from "@material-ui/icons";
import { BIYellow } from '../../assets/buildidColors';
import { List, ListItem } from '@mui/material';

export default function AddPicturesModal(props) {

    const [selectCount, setSelectCount] = useState(0);
    const [selectedPictures, setSelectedPictures] = useState([]);

    {/* new code sanket 01-04-2024 */ }

    const onImageSelect = async (item, i, projectId) => {
        let imageCount = selectCount;
        let pics = [], checkArray = [], selectedCount = 0;
        await props.pictures.map((pic) => {
            if (pic.projectId === projectId) pics = pic.file;
            return pic;
        })
        pics.map(async (data, index) => {
            if (data.fileUrl === item.fileUrl) checkArray = data;
            selectedCount = checkArray.selectCount;
        });
        if (checkArray.isSelected) {
            checkArray.isSelected = !checkArray.isSelected;
            props.pictures.map((img) => {
                img.file.map((image) => {
                    if (image.isSelected && image.selectCount > selectedCount) image.selectCount--;
                    return image;
                });
                return img;
            })
            imageCount--;
            checkArray.selectCount = 0;
        } else {
            checkArray.isSelected = true
            imageCount++;
            checkArray.selectCount = imageCount;
        }
        await props.pictures.map((pictureData) => {
            if (pictureData.projectId === projectId) {
                pictureData = pics
            }
            return pictureData;
        });
        await setSelectCount(imageCount);
        selectedPictures.push(checkArray)
        checkArray.isSelected ? setSelectedPictures(selectedPictures) : setSelectedPictures([]);
    }

    const handleAddPictures = () => {
        props.setSelectedFromEmpty(true);
        const picturesArray = selectedPictures.map((item) => item);
        let a = props.pictureList;
        let b = [];
        const pic = picturesArray.map((item) => {
            b = item;
            b['imageDescription'] = '';
            a.splice(props.selectedFromEmpty ? props.pictureList.length : props.addIconPosition + 1, 0, item);
            return b;
        })
        props.setPictureList(a)
        setSelectedPictures([]);
        // props.handlePreview();
        props.onClose();
        props.setImageChooseFromModalOpen(false);
    }

    return (
        <>
            <Modal
                open={props.isOpen}
                onClose={props.onClose}>
                <div className="modal-center">
                    <div className="modal-card" style={{ height: "525px", width: "500px" }}>
                        <div style={{ display: "flex", alignItems: "center", padding: "10px", gap: "10px" }}>
                            <IconButton
                                color="primary"
                                onClick={props.onClose} style={{ padding: "0px" }}>
                                <CloseIcon />
                            </IconButton>
                            <h2 style={{ fontSize: "17px", fontWeight: "600", margin: "0px" }}>Add Photos From Project</h2>
                        </div>
                        { props.pictures.length == 0 ? <></> :
                        <div className="modal-card-body" style={{ padding: "0px" }}>
                            <List style={{ height: "420px", overflow: "scroll", padding: "0px 10px", gap: "5px", marginBottom: "10px" }}>
                                {props.pictures.map((item) => (
                                    <ListItem key={item?.id} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                        <h4 style={{ fontSize: "15px", margin: "0px" }}>
                                            {item?.projectName}
                                        </h4>
                                        {item.file.map((op, index) => (
                                            <img src={op?.fileUrl ? op?.fileUrl : URL.createObjectURL(op)} onClick={() => onImageSelect(op, index, op.projectId)} alt="Montage" style={{ height: '110px', width: "85px", objectFit: 'contain', border: `5px solid ${op.isSelected ? BIYellow : 'white'}` }} />
                                        ))}
                                    </ListItem>
                                ))}
                            </List>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                {(
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddPictures}>
                                        Add
                                    </Button>
                                )}
                            </div>
                        </div>}
                    </div>
                </div>
            </Modal>

        </>
    )
}
