import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ActionsProvider } from './components/ActionsContext';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
    .then(function(registration) {
      console.log('Service Worker registered successfully:', registration.scope);
    })
    .catch(function(error) {
      console.error('Service Worker registration failed:', error);
    });
}

// Handle messages from the service worker
navigator.serviceWorker.addEventListener('message', (event) => {

  console.log("message    event------",event)
  const message = event.data;
  if (message && message.type === 'navigate' && message.url) {
    // Navigate to the specified URL
    window.location.href = message.url;
  }
});

ReactDOM.render(
  <ActionsProvider>
    <App />
  </ActionsProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
