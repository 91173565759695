import React, { useEffect, useState } from "react";
import { Card, Stack, Typography, Box } from "@mui/material";
import moment from "moment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../components/Loader";
import ClientAddSiteEstimation from "./ClientAddSiteEstimation";
import { fetchClientSiteEstimations } from "../../../api/ClientApi";
import { ClientHeader } from "../ClientHeader";
import "../../../assets/css/ClientHome.css";
import ClientSiteEstimateViewModal from "./ClientSiteEstimateViewModal";

function ClientSiteEstimation() {
  const [siteEstimationList, setSiteEstimationList] = useState([]);
  const [addmodel, setaddmodel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const [viewModel, setViewModel] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState(null);

  const handleModelclose = () => {
    setViewModel(false);
    setSelectedIncident(null);
  };
  const closeModel = () => {
    setaddmodel(false);
  };

  const getSiteEstimation = async () => {
    setIsLoading(true);
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 30,
      FullSearch: "",
      UserId: localStorage?.userId,
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SearchList: [
        {
          //   clientEmployeeId: localStorage.getItem("clientEmployeeId"),
        },
      ],
    };
    await fetchClientSiteEstimations(obj)
      .then((res) => {
        setSiteEstimationList(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("getting error while getting safety incidents list", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSiteEstimation();
  }, []);

  return (
    <>
      <ClientHeader
        screenName={"Site Estimation"}
        clientSearchTerm={clientSearchTerm}
        setClientSearchTerm={setClientSearchTerm}
      />
      <div className="page">
        <div className="page-content" style={{ marginTop: "20px" }}>
          <div className="flex-container-vertical" style={{ flex: 2 }}>
            {isLoading ? (
              <Loader />
            ) : (
              <Stack spacing={1} style={{ cursor: "pointer" }}>
                {siteEstimationList?.map((data, index) => {
                  return (
                    <Card
                      key={index}
                      variant="outlined"
                      onClick={() => {
                        setSelectedIncident(data);
                        setViewModel(true);
                      }}>
                      <Box p={2}>
                        <Box display="flex" gap={1}>
                          <LocationOnIcon
                            style={{ color: localStorage?.clientAccentColor }}
                          />
                          <div>
                            {data.clientJobCode} - {data.clientJobName}
                          </div>
                        </Box>
                        <Typography
                          variant="span"
                          color={"rgb(164, 164, 164)"}
                          style={{ marginLeft: "30px" }}>
                          {data.estimationDate
                            ? moment(data.estimationDate).format("MMMM D, YYYY")
                            : ""}
                        </Typography>
                      </Box>
                    </Card>
                  );
                })}
              </Stack>
            )}
          </div>
          <ClientAddSiteEstimation close={closeModel} open={addmodel} />
          {selectedIncident && (
            <ClientSiteEstimateViewModal
              open={viewModel}
              close={handleModelclose}
              data={selectedIncident}
            />
          )}
        </div>
      </div>
      <div className="fixed-button-container">
        <button
          className="fixed-button"
          style={{ background: localStorage?.clientAccentColor }}
          onClick={() => setaddmodel(true)}>
          <AddIcon />
        </button>
      </div>
    </>
  );
}

export default ClientSiteEstimation;
