import React, { useState, useCallback, useEffect } from 'react'
import "../../assets/css/Profile.css";
import UserCertificationsCard from './sections/UserCertificationCard';
import ProfileHeaderCard from './ProfileHeaderCard';
import UserExperienceCard from './sections/UserExperienceCard';
import UserContactInfoCard from './sections/UserContactInfoCard';
import UserProductCard from './sections/UserProductCard';


const UserProfile = (props) => {
    const [showLoader, setShowLoader] = useState(true);
    const [isContactListEmpty, setIsContactListEmpty] = useState(false);
    const [isContactList, setIsContactList] = useState(false);

    useEffect(() => {
        console.log("isContactList-----", isContactList)
        console.log("isContactList props-----     ", props)
    }, [props])

    return (
        <>
            <div className="page">
                <ProfileHeaderCard contactId={Number(props.contactId)} showLoader={showLoader} setShowLoader={setShowLoader} setIsContactList={(value) => setIsContactList(value)} />
                {isContactList ?
                    <div className="page-content" style={{ paddingTop: "0px" }}>
                        <div className="resp-container-horizontal">
                            <div className={!isContactListEmpty ? "flex-container-vertical" : ""} style={!isContactListEmpty ? { flex: 1 } : {}}>
                                <UserContactInfoCard contactId={Number(props.contactId)} showLoader={showLoader} setIsContactListEmpty={setIsContactListEmpty} />
                            </div>

                            <div className="flex-container-vertical" style={{ flex: 2 }}>
                                <UserExperienceCard contactId={Number(props.contactId)} showLoader={showLoader} />
                                <UserCertificationsCard contactId={props.contactId} showLoader={showLoader} />
                                <UserProductCard contactId={props.contactId} showLoader={showLoader} />
                            </div>
                        </div>
                    </div> : <></>
                }
            </div>
        </>
    )
}

export default UserProfile;
