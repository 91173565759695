import React, { useState } from 'react'
import { Divider, Stack, Tab, Tabs } from '@mui/material';
import { Chip, Typography } from '@material-ui/core';
import { BIGreen, BIGrey, BILightGreen, BILightRed, BILightYellow, BIRed, BIWhite, BIYellow } from "../../../assets/buildidColors"
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import "../../../assets/css/ClientHome.css"
import AddIcon from '@mui/icons-material/Add';
import { ClientHeader } from '../ClientHeader';


const CustomTab = ({ label, counter }) => {
    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            {label}
            {counter > 0 && (
                <div style={{
                    position: 'absolute',
                    top: '-14px',
                    right: '-13px',
                    backgroundColor: localStorage?.clientAccentColor,
                    color: 'white',
                    borderRadius: '50%',
                    padding: '0px 4px',
                    fontSize: '10px',
                }}>
                    {counter}
                </div>
            )}
        </div>
    );
};


const ClientTimesheets = () => {

    const history = useHistory();
    const tabsArray = ["My Timesheets", "To Approve"];
    const [activeTab, setActiveTab] = useState("My Timesheets");

    let data = [
        {
            id: 1,
            title: "Enterprise Rental",
            dateSubmitted: "Oct 27, 2016",
            total: "$53125.55",
            status: "In Review",
            subTitle: "62520-Auto"
        },
        {
            id: 2,
            title: "Extra drywall",
            dateSubmitted: "Jan 07, 2010",
            total: "$525.05",
            status: "Rejected",
            subTitle: "5100 - Material"
        },
        {
            id: 3,
            title: "Eye exam",
            total: "$125.10",
            status: "In Review",
            subTitle: "22300 - Employee Benfits"
        },
        {
            id: 4,
            title: "drywall",
            dateSubmitted: "Nov 17, 2024",
            total: "$999.99",
            status: "Approved",
            subTitle: "62520 Auto"
        }
    ]

    const handleTabChange = (event, newTab) => {
        setActiveTab(newTab);
    };

    return (
        <>
        <ClientHeader screenName={"Timesheets"}/>
        <div className="page rewards-page">
            <div className="header client-content-header">
                <div className="content-heading" style={{ position: "relative" }}>
                    <div
                        className="flex-container-horizontal"
                        style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <Tabs
                            sx={{
                                ".Mui-selected": {
                                    color: localStorage?.clientAccentColor,
                                }
                            }}
                            TabIndicatorProps={{ sx: { backgroundColor: localStorage?.clientAccentColor } }}
                            value={activeTab}
                            onChange={handleTabChange}>
                            {tabsArray.map((tab, index) => (
                                <Tab
                                    key={index}
                                    value={tab}
                                    label={tab === "To Approve" ? <CustomTab label={tab} counter={5} /> : tab}
                                    style={{
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        marginRight: "20px",
                                    }}
                                />
                            ))}
                        </Tabs>
                    </div>
                    {/* <div className="add-content" style={{ position: "absolute", right: "12px", top: "3px" }}>
                        <AddIcon className="add-icon" />
                    </div> */}
                </div>
                {data?.length > 0 ?
                    (
                        <>
                            <Stack style={{margin:"15px 15px 0px 0px"}}>
                                {
                                    data?.map((item, index) => (
                                        <div key={index} style={{ backgroundColor: BIWhite, margin: '10px 0px', padding: '20px', borderRadius: '20px', cursor: 'pointer' }}
                                            onClick={() => {
                                                history.push(`/client-individual-timesheet/${item.id}`)
                                            }}
                                        >
                                            <Stack display={'flex'} justifyContent={"space-between"} direction={'row'}>
                                                <Stack>
                                                    <Typography style={{ marginTop: '4px' }}>{item?.title}</Typography>
                                                    <Typography style={{ color: BIGrey }}>{item?.subTitle}</Typography>
                                                    <div style={{ width: 'fit-content', marginTop: '2px', display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Stack display={"flex"} gap={2} direction={"row"}>
                                                            <Stack>
                                                                <Typography >Employee</Typography>
                                                                <Typography >3</Typography>
                                                            </Stack>
                                                            <Divider orientation="vertical" variant="middle" flexItem />
                                                            <Stack>
                                                                <Typography >Hours</Typography>
                                                                <Typography >7.00</Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </div>
                                                </Stack>
                                                <Stack display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    <Chip
                                                        label={item?.status}
                                                        style={item?.status === "In Review" ? { backgroundColor: BILightYellow, color: BIGrey } :
                                                            item?.status === "Rejected" ? { backgroundColor: BILightRed, color: BIRed } :
                                                                { backgroundColor: BILightGreen, color: BIGreen }
                                                        }
                                                    />
                                                </Stack>
                                            </Stack>
                                        </div>
                                    ))
                                }
                            </Stack>
                        </>
                    )
                    :
                    (
                        <>
                            <Stack display={'flex'} justifyContent={'center'} alignItems={'center'}
                                style={{ marginTop: "20%" }}
                            >
                                <Typography style={{ color: BIGrey, fontSize: '18px' }}>
                                    No Timesheets.
                                </Typography>
                            </Stack>
                        </>
                    )
                }
            </div>
            <div className="fixed-button-container">
                <button className="fixed-button" style={{background:localStorage?.clientAccentColor}} onClick={()=>{}}>
                    <AddIcon />
                </button>
            </div>
        </div>
        </>
    )
}

export default ClientTimesheets