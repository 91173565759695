import React, { useState, useEffect } from "react";
import { fetchStoreItems } from "../../api/RewardsApi";
import BuilderBucksBalance from "../../components/BuilderBucksBalance";
import { Button, TextField, Modal, Box, Typography, IconButton } from "@material-ui/core";
import { saveUserPurchase } from "../../api/RewardsApi";
import AlertModal from "../../components/AlertModal";
import { getUserAddress, getUserRewardAmount } from "../../api/UserApi";
import Store from "./Rewards";
import UserUsages from "../../components/userUsages/UserUsages";
import { CircularProgress, Stack } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import Loader from "../../components/Loader";
import { Close as CloseIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles({
	centeredInput: {
		textAlign: 'center',
	},
});

function MyStore(props) {
	const [store, setStore] = useState([]);
	const [locationVerifyModal, setLocationVerifyModal] = useState(false);
	const [showAlertModal, setShowAlertModal] = useState(false);
	const [alertMessage, setAlertMessage] = useState(null);
	const [loading, setLoading] = useState(true);
	const [items, setItems] = useState([])
	const [bucks, setBucks] = useState(1110)
	const classes = useStyles();

	useEffect(() => {
		getLocation()
	}, [])

	const getLocation = async () => {
		let userId = localStorage.userId;
		try {
			const userAddress = await getUserAddress(userId);
			if (userAddress == null) {
				setLocationVerifyModal(true);
				return setLoading(false);
			} else {
				if (userAddress.country === '') {
					setLocationVerifyModal(true);
					return setLoading(false);
				} else {
					setLocationVerifyModal(false);
					getData(userAddress?.country);
				}
			}
		} catch (error) {
			setLoading(false);
			console.log('ERROR: [_getLocation_]:', error);
		}
	}

	const getData = async (userCountry) => {
		try {
			const rewards = await getUserRewardAmount(localStorage.userId);
			const obj = {
				PageNr: 1,
				FullSearch: "",
				IncludeRecordNr: "false",
				TypeOfObjectReturned: "QtyGreaterThanZero",
				FetchAllowedRecordsOnly: false,
				SearchList: [{ RecordStatusId: 2 }],
				SortList: [{ FieldName: "ItemName", Direction: "ASC" }]
			}
			const storeItems = await fetchStoreItems(obj);
			let a = [];
			const storeData = storeItems.map((value) => {
				a = value;
				a['quantity'] = 1;
				return a;
			})
			const storeData2 = storeData.filter((item) => item.recordStatus != "Not Visible" && item.recordStatus != "New" && item.country === userCountry);
			setItems(storeData2);
			setBucks(rewards?.bucks ?? 0);
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.log("Error while getting data :: ", err)
		}
	}



	const onActionTypeClick = async (item, index) => {
		if (item.quantity == '' || item.quantity == 0) {
			setAlertMessage("Please enter valid number of entries!");
			return;
		}
		if (item.quantity * item.price > bucks) {
			setAlertMessage("Sorry! You don't have enough BuilderBucks yet.")
			return;
		}

		try {
			const obj = {
				Id: 0,
				UserId: Number(localStorage.userId),
				StoreItemId: item.id,
				StatusId: 4,
				Qty: item.quantity
			}
			await saveUserPurchase(obj);
			if (item.quantity * item.price <= bucks) {
				setAlertMessage("You have successfully registered for the draw!")
			}
			const rewards = await getUserRewardAmount(localStorage.userId);
			const duplicateItems = [...items];
			duplicateItems[index].quantity = 1;
			setItems(duplicateItems);
			setBucks(rewards?.bucks);
		} catch (err) {
			console.log("Error :: ", err)
		}
	}

	const onPlusClicked = (i) => {
		let duplicateItems = [...items];
		duplicateItems[i].quantity++;
		setItems(duplicateItems)
	}

	const onMinusClicked = (i) => {
		let duplicateItems = [...items];
		if (duplicateItems[i].quantity > 1) {
			duplicateItems[i].quantity--;
		}
		setItems(duplicateItems)
	}

	const renderRewardCard = (item, index) => {
		return (
			<>
				<div className="store-card">
					<div className="store-card-image">
						{
							(item.fileUrl !== "")
								? (<img src={item.fileUrl} alt="Store Item" />)
								: (<img style={{ display: "none" }} alt="" />)
						}
					</div>


					<div className="store-card-content">
						<div className="flex-container-verticle profile-flex" style={{ justifyContent: "space-between" }}>
							<div >
								<span className="store-card-title-new">{item.itemName}</span>
								<div>
									{item?.description != '' &&
										(
											<span className="greytext">{item.description}</span>
										)
									}
									<p className="greytext">
										{
											item.actionType === 'Redeem'
												? `${item.price} BuilderBucks`
												: `${item.price} BuilderBucks/entry`
										}
									</p>
								</div>
							</div>
							<div>
								<div>
									<p style={{ textAlign: 'center' }}>Number of Entries</p>
								</div>
								<Stack style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} gap={1}>
									<Button className="btn-width" variant="contained" onClick={() => { onMinusClicked(index) }} color="primary" disabled={Number(item.quantity) <= 1}>
										-
									</Button>
									<TextField
										size="small"
										type="number"
										variant="outlined"
										InputProps={{ classes: { input: classes.centeredInput } }}
										value={item.quantity.toString()}
										onChange={(e) => {
											const duplicateItems = [...items];
											let val = e.target.value;
											const numericValue = val.replace(/[^0-9]/g, '');
											duplicateItems[index].quantity = numericValue;
											setItems(duplicateItems);
										}}
									/>
									<Button className="btn-width" variant="contained" onClick={() => { onPlusClicked(index) }} color="primary">
										+
									</Button>
								</Stack>
								<div style={{ marginTop: '10px', textAlign: 'center' }}>
									<Button variant="contained" className="store-card-btn" onClick={() => { onActionTypeClick(item, index) }} color="primary">{item.actionType}</Button>
								</div>

							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

	return (
		<>
			<Store activeTab={props?.activeTab ?? ""} />
			<div className="page">
				<div className="page-content">
					<BuilderBucksBalance />

					{
						!loading ? (
							items.length > 0 ?
								(
									<div className="store-cards-container">
										{
											items.map((item, index) => (
												<div key={item?.id}>
													{renderRewardCard(item, index)}
												</div>
											))
										}
									</div>
								)
								:
								(
									<div style={{ textAlign: "center", marginTop: '20px', color: 'red' }}>
										Currently there are no rewards in your location
									</div>
								)
						)
							: (
								<div style={{ textAlign: "center", marginTop: '10%' }}>
									<CircularProgress />
								</div>
							)
					}
				</div>
			</div>
			{
				locationVerifyModal && <LocationMessageModal
					open={locationVerifyModal}
				/>
			}
			{
				alertMessage &&
				<AlertModal
					isOpen={true}
					body={alertMessage}
					onClose={() => { setAlertMessage(null) }}
				/>
			}
		</>
	)
}

export default MyStore;


export const LocationMessageModal = (props) => {

	let history = useHistory();

	const goToDashboard = () => {
		history.push('/dashboard')
	}

	const goToProfile = () => {
		history.push('/profile')
	}

	return (
		<>
			<Modal
				open={props.open}
				onClose={goToDashboard}>
				<div className="modal-center">
					<div className="modal-card" style={{ width: "450px" }}>
						<div style={{ display: 'flex', justifyContent: 'end', padding: '0px 10px' }}>
							<IconButton
								color="primary"
								onClick={goToDashboard}>
								<CloseIcon />
							</IconButton>
						</div>

						<div className="modal-card-body">
							<div style={{ paddingBottom: "20px", textAlign: 'center' }}>
								<Typography style={{ color: 'red' }}>
									Rewards Requires User location
								</Typography>
								<Typography>
									To add your location, go to About tab in Profile.
									(Address needs to set before coming to rewards).
								</Typography>
							</div>

							<div className="flex-container-horizontal" style={{ justifyContent: "space-between" }}>
								<Button
									variant="outlined"
									color="primary"
									onClick={goToDashboard}>
									<b>Back to Dashboard</b>
								</Button>
								<Button
									variant="outlined"
									color="primary"
									onClick={goToProfile}>
									<b>Go to Profile</b>
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}
