import React from "react";
import { Box, Typography, Modal, IconButton } from "@mui/material";
import moment from "moment";
import { Close } from "@material-ui/icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 600,
  bgcolor: "background.paper",
  padding: "25px",
  borderRadius: "10px",
};

const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
};

const headerCellStyle = {
  borderBottom: "1px solid #ccc",
  padding: "8px",
  textAlign: "left",
  fontWeight: "bold",
};

const cellStyle = {
  borderBottom: "1px solid #ccc",
  padding: "8px",
  textAlign: "left",
};

const subtotalStyle = {
  borderBottom: "1px solid #ccc",
  padding: "8px",
  textAlign: "left",
  color: "red",
};

const ClientSiteEstimateViewModal = ({ data, close, open }) => {
  if (!data || !data.siteEstimationList) return null;

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            marginBottom: "5px",
          }}>
          <IconButton
            color="primary"
            onClick={close}
            style={{ padding: "0px" }}>
            <Close />
          </IconButton>
        </div>

        <Typography id="modal-modal-title" variant="h6" component="h2">
          Job
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }}>
          {data.clientJobCode} {data.clientJobName}
        </Typography>

        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mt: 3 }}>
          Area/Item
        </Typography>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={headerCellStyle}>Description</th>
              <th style={headerCellStyle}>Days</th>
              <th style={headerCellStyle}>Employees</th>
              <th style={headerCellStyle}>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {data.siteEstimationList.map((item, index) => (
              <tr key={index}>
                <td style={cellStyle}>{item.description}</td>
                <td style={cellStyle}>{item.days}</td>
                <td style={cellStyle}>{item.employees}</td>
                <td style={subtotalStyle}>{item.subTotal}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Modal>
  );
};

export default ClientSiteEstimateViewModal;
