import React from "react";
import { Modal } from "@material-ui/core";
import { Avatar, ListItem, ListItemButton, ListItemText, Stack } from "@mui/material";
import { BIBlue } from "../../assets/buildidColors";
import placeholder from "../../assets/images/project-profile-placeholder.jpg";



function Chooseasite(props) {

    return (
        <Modal
            open={props.isOpen}
            onClose={props.onClose}>

            <div className="modal-center" style={{ width: "62%", marginLeft: "50px", overflow: 'scroll', maxHeight: '80%' }}>
                <div className="modal-card">
                    <div className="modal-card-body">
                        <div style={{ paddingBottom: "20px", display: 'flex', justifyContent: 'space-between', color: BIBlue }}>
                            <div>
                                Choose A Site
                            </div>
                            <div style={{ cursor: "pointer" }} onClick={props.onClose}>
                                Cancel
                            </div>
                        </div>

                        <Stack spacing={0}>

                            <>
                                {
                                    props?.siteList?.length != 0 ? (
                                        <>
                                            {
                                                props?.siteList?.map((item, index) => {
                                                    return (
                                                        <div key={index.toString()}>
                                                            <ListItem key={index.toString()}
                                                                disablePadding
                                                            >
                                                                <ListItemButton sx={{ display: 'flex', flexDirection: 'row' }}
                                                                    onClick={() => props?.onSiteClick(item)}
                                                                >

                                                                    <div style={{ display: 'flex', flexDirection: 'row', width: "8%" }}>
                                                                        <Stack
                                                                            sx={{ marginRight: '10px' }}
                                                                            direction="row"
                                                                            spacing={1}
                                                                            alignItems="center"
                                                                        >
                                                                            {
                                                                                item?.mainPictureUrl ?
                                                                                    <Avatar src={item?.mainPictureUrl}
                                                                                    />
                                                                                    :
                                                                                    <Avatar src={placeholder}
                                                                                    />
                                                                            }

                                                                        </Stack>
                                                                    </div>
                                                                    <ListItemText
                                                                        primary={item?.name ?? ''}
                                                                        secondary={item?.address ?? ''}
                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        </div>
                                                    )
                                                })}
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )
                                }
                            </>

                        </Stack>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Chooseasite;