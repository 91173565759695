import { Button, Card, CardActions, CardContent, IconButton, Menu, MenuItem, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../assets/css/Sites.css";
import placeholder from "../../assets/images/project-profile-placeholder.jpg";
import { Close as CloseIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddSiteModal from "../profile/sections/AddSiteModal";
import ProjectForm from "../profile/sections/ProjectForm";
import { fetchUserTrades } from "../../api/UserApi";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import ReportModal from "../../components/ReportModal";
import { BIRed } from "../../assets/buildidColors";
import { MoreVert as MoreIcon } from "@mui/icons-material";
import { fetchFollowEntities, saveFollowEntities } from "../../api/SitesApi";
import PromptModal from "../../components/PromptModal";

function Siteview(props) {

    const [isFollow, setIsFollow] = useState(false);
    const [followId, setFollowId] = useState(0);
    const [siteSectionAlert, setSiteSectionAlert] = useState(false);

    const [addSiteModalOpen, setAddSiteModalOpen] = useState(false);
    const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(false);
    const [reportModalOpenSearch, setReportModalOpenSearch] = useState(false);
    const [userTrade, setUserTrade] = useState([]);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        getUsertrades();
    }, []);


    useEffect(() => {
        if (props?.siteInfo?.id) {
            fetchFollowsEntity();
        }
    }, [props?.siteInfo?.id])

    const getUsertrades = async () => {
        const userTradeObj = {
            PageNr: 1,
            FullSearch: "",
            IncludeRecordNr: false,
            TypeOfObjectReturned: "",
            FetchAllowedRecordsOnly: false,
            SearchList: [{ UserId: localStorage.userId }],
        }
        const userTradeRes = await fetchUserTrades(userTradeObj);
        const mainTrade = userTradeRes.filter((item) => item.tradeType.toLowerCase() == 'main')
        setUserTrade(mainTrade[0]);
    }

    const addSiteshow = () => {
        props.setShowaddsite(true);
    }

    function handleFollowPressed() {
        handleSaveFollowClick(true)
    }

    function handleFollowingPressed() {
        setSiteSectionAlert(true)
    }

    async function fetchFollowsEntity() {
        try {
            const obj = {
                PageNr: 1,
                NrOfRecPerPage: 20,
                FullSearch: "",
                UserId: Number(localStorage.userId),
                IncludeRecordNr: true,
                TypeOfObjectReturned: "",
                SearchList: [{ EntityId: Number(props?.siteInfo?.id), userId: Number(localStorage.userId) }],
            }
            const followingData = await fetchFollowEntities(obj);
            if (followingData.length != 0) {
                setFollowId(followingData.data[0]?.id ?? 0)
                setIsFollow(followingData.data[0]?.isFollowing)
            } else {
                setFollowId(0)
                setIsFollow(false)
            }

        } catch (Error) {
            console.log("GET_Follow Entity [ERROR]::", Error)

        }
    }

    const closeSiteview = () => {
        props.setSelectedSite(false);
    }

    const handleReport = (id) => {
        setMoreMenuAnchorEl(false)
        setReportModalOpenSearch(true);
    }

    async function handleSaveFollowClick(isFollow) {
        try {
            const saveEntitiesObject = {
                UserId: Number(localStorage.userId),
                ReturnRecordId: true,
                ReturnRecordError: true,
                SaveList: [{
                    Id: followId,
                    EntityTypeId: 2,
                    EntityId: Number(props?.siteInfo?.id),
                    UserId: Number(localStorage.userId),
                    IsFollowing: isFollow,
                    ModifiedBy: Number(localStorage.userId)
                }]
            }
            const followRes = await saveFollowEntities(saveEntitiesObject);
            fetchFollowsEntity();
        } catch (e) {
            console.log("SAVE_FOLLOW__[ERROR]:", e);

        }

    }

    return (
        <>

            {props.isSkeleton ? <> <Card className="site-view">
                <Skeleton variant="rectangular" width={450} height={250} />
                <IconButton
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    onClick={closeSiteview}
                >
                    <CloseIcon />
                </IconButton>
                <CardContent className="site-info ">
                    <Skeleton width="50%" />
                    <Skeleton width="50%" />
                </CardContent>
                <CardActions>
                    <Skeleton width="60%" height={60} />
                    <Skeleton width="60%" height={60} />
                </CardActions>
                <CardContent className="site-info add-site">
                    <h5 className="add-siteheading"><Skeleton width="30%" /></h5>
                    <span className="site-address"><Skeleton width="60%" /></span>
                    <Skeleton width="100%" height={60} />
                </CardContent>
            </Card></> : (
                <Card className="site-view">
                    <img style={{ height: "250px", width: "450px" }} loading="lazy" src={props?.sitePic} onError={(e) => { e.target.src = placeholder }} />
                    <PromptModal
                        isOpen={siteSectionAlert}
                        onClose={() => { setSiteSectionAlert(false) }}
                        title="Are you sure you want to unfollow this Site "
                        redButtonText="YES"
                        blueButtonTextFirst="NO"
                        onRedButtonClick={() => {
                            handleSaveFollowClick(false);
                            setSiteSectionAlert(false)
                        }}
                    />
                    <IconButton
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        onClick={closeSiteview}
                    >
                        <CloseIcon />
                    </IconButton>
                    <CardContent className="site-info">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography className="site-name" gutterBottom variant="h5" component="div" onClick={() => {
                                props?.siteInfo?.id && history.push(`/sites/site/${props?.siteInfo?.id}`)
                            }}>
                                {props?.siteInfo?.name}
                            </Typography>
                            <IconButton
                                onClick={event => {
                                    setMoreMenuAnchorEl(event.currentTarget)
                                }}>
                                <MoreIcon />
                            </IconButton>
                        </div>
                        <Typography className="site-address" variant="body2" color="text.secondary">
                            {props?.siteInfo?.address}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        {isFollow ? <Button
                            color="primary"
                            style={{ marginRight: '5px' }}
                            fullWidth
                            variant="outlined"
                            onClick={handleFollowingPressed}>
                            Following
                        </Button>
                            : <Button
                                color="primary"
                                style={{ marginRight: '5px' }}
                                fullWidth
                                variant="contained"
                                onClick={handleFollowPressed}>
                                Follow
                            </Button>}
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={() => setAddSiteModalOpen(true)}>
                            Add To Profile
                        </Button>
                    </CardActions>
                    <CardContent className="site-info add-site">
                        <h5 className="add-siteheading">Add a different Site to this location</h5>
                        <span className="site-address">{props?.siteInfo?.address}</span>
                        <Button
                            color="primary"
                            style={{ marginRight: '5px', marginTop: "10px" }}
                            variant="contained"
                            fullWidth
                            onClick={addSiteshow}>
                            Add Site
                        </Button>
                    </CardContent>
                </Card>
            )}
            <AddSiteModal
                setAddSiteModalOpen={setAddSiteModalOpen}
                addSiteModalOpen={addSiteModalOpen}
                resetSelected={() => { setAddSiteModalOpen(false) }}
                projectForm={<ProjectForm
                    siteName={props?.siteInfo?.name}
                    siteAddress={props?.siteInfo?.address}
                    siteId={props?.siteInfo?.id}
                    isSiteExistForm={location?.state?.projectSelectCompany ? true : false}
                    company={location?.state?.projectSelectCompany ? location?.state?.projectSelectCompany : {}}
                    tradeId={userTrade?.tradeId ?? 0}
                    tradeName={userTrade?.tradeName ?? ""}
                    isSiteNewForm={true}
                    onReturnClick={() => {
                        setAddSiteModalOpen(false);
                    }}
                    goToSite={!location?.state?.projectSelectCompany ? true : false}
                    goToProfile={location?.state?.projectSelectCompany ? true : false}
                />}
            />
            <Menu
                anchorEl={moreMenuAnchorEl}
                open={Boolean(moreMenuAnchorEl)}
                onClose={(event) => {
                    setMoreMenuAnchorEl(false)
                }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}>
                <MenuItem
                    onClick={() => handleReport(props?.siteInfo?.id)}
                    style={{ color: BIRed }}
                    dense>
                    Report
                </MenuItem>
            </Menu>
            <ReportModal
                name={props?.siteInfo?.name}
                isOpen={reportModalOpenSearch}
                onClose={() => { setReportModalOpenSearch(false) }}
            />
        </>
    )
}
export default Siteview;