import {
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
  Chip,
  Modal,
  CircularProgress,
  List,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../assets/css/Sites.css";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  deleteSites,
  fetchSites,
  fetchSitesActive,
  fetchSitesDescriptions,
  fetchSitesPictures,
  fetchSitesProjects,
  getAddress,
  saveFollowingProjects,
  saveProjectCompany,
  siteActiveClaims,
} from "../../api/SitesApi";
import ProjectList from "../sites/ProjectList";
import {
  BIBlue,
  BILightRed,
  BILightYellow,
  BIRed,
  BIYellow,
} from "../../assets/buildidColors";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useHistory, useLocation } from "react-router-dom";
import AddProjectModal from "../../components/AddProjectModal";
import AddDescriptionDialog from "../sites/dilogs/AddDescriptionDialog";
import WarningIcon from "@mui/icons-material/Warning";
import AddSiteNameModal from "./AddSiteNameModal";
import InfiniteScroll from "react-infinite-scroll-component";
import DescriptionDialog from "./DiscriptionDialog";
import ReportModal from "../../components/ReportModal";
import Chooselocation from "./Chooselocation";
import ErrorIcon from "@mui/icons-material/Error";
import ExperienceProjectForm from "../../components/ExperienceProjectForm";
import { Button } from "@material-ui/core";
import ImageDialog from "../sites/dilogs/ImageDialog";
import PictureViewer from "../../components/PictureViewer";
import PromptModal from "../../components/PromptModal";
import { saveProjectProducts } from "../../api/ProjectsApi";

const SiteCard = (props) => {
  const history = useHistory();
  const location = useLocation();
  let siteId = props?.selectedSiteDetails?.id ?? 0;
  const [loading, setLoading] = useState(true);
  const [siteDescriptions, setSiteDescriptions] = useState([]);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(false);
  const [siteProjects, setSiteProjects] = useState([]);
  const [noOfDescription, setNoOfDescription] = useState(null);
  const [noOfProject, setNoOfProject] = useState(null);
  const [projectImageArray, setProjectImageArray] = useState([]);
  const [addDescriptionDialogOpen, setAddDescriptionDialogOpen] =
    useState(false);
  const [reportModalOpenSearch, setReportModalOpenSearch] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [pictureViewerOpen, setPictureViewerOpen] = useState(false);
  const [pictureViewIndex, setPictureViewIndex] = useState(0);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [showAddNameModal, setShowAddNameModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [otherDescriptionDialogOpen, setOtherDescriptionDialogOpen] =
    useState(false);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [allDataLoadedForDesciption, setAllDataLoadedForDesciption] =
    useState(false);
  const [descPageNumber, setDescPageNumber] = useState(1);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState({});
  const [showAddExperienceModal, setShowAddExperienceModal] = useState(false);
  const [showExperienceDiscardModal, setShowExperienceDiscardModal] =
    useState(false);
  const [showChooselocation, setShowchooselocation] = useState(false);
  const [chooseLocation, setChooselocation] = useState({ lat: 0, lng: 0 });
  const [deleteSiteConfirmModalOpen, setDeleteSiteConfirmModalOpen] =
    useState(false);
  const [allFollowing, setAllFollowing] = useState(false);
  const [removeProjectSectionAlert, setRemoveProjectSectionAlert] =
    useState(false);
  const [addNewSiteLocation, setAddNewSiteLocation] = useState(false);
  {
    /* Mahindra Code 28-mar-2024 */
  }
  const [initialViewMore, setInitialViewMore] = useState(true);

  useEffect(async () => {
    if (!props?.showChooseOption) {
      getSiteData();
    } else {
      getSiteDataForChoose();
    }
  }, []);

  const getSiteData = async () => {
    try {
      await props?.getSiteInfo();
      await getSiteProjects(1);
      await getSiteDescriptions(1);
      setLoading(false);
    } catch (error) {
      console.log("Error while getting site data :: ", error);
    }
  };

  const getSiteDataForChoose = async () => {
    await props?.getSiteInfo();
    await setLoading(false);
  };

  const getSiteProjects = async (pageNumber) => {
    try {
      let obj = {
        PageNr: pageNumber,
        NrOfRecPerPage: 10,
        FullSearch: "",
        UserId: 1,
        IncludeRecordNr: "true",
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            siteId: siteId,
          },
        ],
        SortList: [
          {
            FieldName: "id",
            Direction: "DESC",
          },
        ],
      };
      let res = await fetchSitesProjects(obj);
      if (pageNumber > 1) {
        setTimeout(() => {
          setSiteProjects((prevProducts) => [...prevProducts, ...res?.data]);
          const allFollowingRecords = res?.data.every(
            (record) => record?.isFollowing === true
          );
          setAllFollowing(allFollowingRecords);
        }, 500);
      } else {
        setSiteProjects(res?.data);
        const allFollowingRecords = res?.data.every(
          (record) => record?.isFollowing === true
        );
        setAllFollowing(allFollowingRecords);
      }

      setPageNumber(pageNumber + 1);
      setNoOfProject(res?.nrOfRecords);
      if (res?.data?.length === 0 || res?.data?.length < 9) {
        setAllDataLoaded(true);
      }

      const thumbUrls = res?.data?.reduce((acc, item) => {
        if (item?.pictureList && item?.pictureList?.length > 0) {
          const urls = item?.pictureList?.map((pic) => ({
            pictureUrl: pic?.pictureUrl,
            thumbUrl: pic?.thumbUrl,
            postId: pic?.id,
            pictureDateTime: pic?.PictureDateTime,
          }));
          acc = acc?.concat(urls);
        }
        return acc;
      }, []);
      setProjectImageArray((preValue) => [...preValue, ...thumbUrls]);
    } catch (error) {
      console.log("Error while getting site projects :: ", error);
    }
  };

  {
    /* Mahindra Code 28-mar-2024 */
  }
  const getSiteDescriptions = async (descPageNumber) => {
    try {
      const obj = {
        PageNr: descPageNumber,
        NrOfRecPerPage: 10,
        FullSearch: "",
        UserId: 1,
        IncludeRecordNr: "true",
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            siteId: siteId,
          },
        ],
        SortList: [
          {
            FieldName: "Id",
            Direction: "DESC",
          },
        ],
      };
      {
        /* Mahindra Code 28-mar-2024 */
      }
      let siteDescriptionsRes = await fetchSitesDescriptions(obj);
      if (descPageNumber > 1) {
        setTimeout(() => {
          setSiteDescriptions((prevProducts) => [
            ...prevProducts,
            ...siteDescriptionsRes?.data,
          ]);
        }, 500);
      } else {
        setSiteDescriptions(siteDescriptionsRes?.data);
      }
      setDescPageNumber(descPageNumber + 1);
      setNoOfDescription(siteDescriptionsRes?.nrOfRecords);
      if (
        siteDescriptionsRes?.data?.length === 0 ||
        siteDescriptionsRes?.data?.length < 9
      ) {
        setAllDataLoadedForDesciption(true);
      }
    } catch (error) {
      console.log("Error while getting site descriptions :: ", error);
    }
  };

  const onClose = () => {
    props.onClose();
  };

  const handleReport = (id) => {
    setMoreMenuAnchorEl(false);
    setReportModalOpenSearch(true);
  };

  const openAddDescriptionDialog = () => {
    setMoreMenuAnchorEl(false);
    setAddDescriptionDialogOpen(true);
  };

  const openImageModal = (index) => {
    setPictureViewerOpen(true);
    setPictureViewIndex(index);
  };

  const openImageSeeMoreModal = () => {
    setImageModalOpen(true);
  };

  const onProjectSave = async (projectId) => {
    if (projectId) {
      setShowAddProjectModal(false);
      history.push(`/projects/project/${projectId}`);
      return;
    } else {
      await getSiteProjects(1);
      await props?.showSiteInfo();
      await props?.getSiteInfo();
    }
  };

  const onChooseSiteForAddProject = () => {
    if (props?.fromSignUpPage == "signUpPage") {
      if (props?.from == "mapScreenForAddOption" && props?.showChooseOption) {
        props.onAddNewSite();
      } else {
        props?.setSignUpSiteInfo(props?.siteInfo);
        props?.setAddSiteModalForSignUp(false);
      }
      return;
    } else if (location?.state?.from === "quiz_site") {
      if (props?.from == "mapScreenForAddOption" && props?.showChooseOption) {
        props.onAddNewSite();
      } else {
        let obj = {
          from: "map",
          question: location?.state?.questionData,
          siteInfo: props?.siteInfo,
          for: "site",
        };
        if (location?.state?.fromQuestion == "dashboard-profile") {
          history.push({
            pathname: "/dashboard",
            state: { obj },
          });
        } else {
          history.push({
            pathname: "/rewards/earn-rewards",
            state: { obj },
          });
        }
      }
    } else if (
      props?.from == "mapScreenForAddOption" &&
      props?.showChooseOption
    ) {
      props.onAddProjectClick();
      props?.setShowChooseOption(false);
    } else {
      props?.setShowChooseOption(false);
      setShowAddProjectModal(true);
    }
    props?.setChangeSiteFlag(false);
  };

  const increaseOffset = (callback) => {
    setPageNumber((prevPageNr) => prevPageNr + 1);
    callback(pageNumber + 1);
  };

  const setSiteActiveStatus = async () => {
    try {
      let payload = {
        UserId: localStorage?.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: props?.siteInfo?.id,
          },
        ],
      };
      await siteActiveClaims(payload);
      await props?.getSiteInfo();
      setMoreMenuAnchorEl(false);
    } catch (error) {
      setMoreMenuAnchorEl(false);
      console.log("Error while getting changing active status :: ", error);
    }
  };

  function handleFollowPressed() {
    handleSaveFollowClick(true);
  }

  function handleFollowingPressed() {
    setRemoveProjectSectionAlert(true);
  }

  async function handleSaveFollowClick(isFollow) {
    try {
      const obj = {
        UserId: Number(localStorage?.userId),
        SiteId: siteId,
        IsFollowing: isFollow,
      };
      await saveFollowingProjects(obj);
      await onProjectSave();
    } catch (error) {
      console.log("ERROR_SAVE_FOLLOW__:", error);
    }
  }

  const saveProjectForCompany = async (item, companyId) => {
    const saveObj = [
      {
        Id: 0,
        ProjectId: item.id,
        CompanyId: companyId,
        ModifiedBy: Number(localStorage?.userId),
      },
    ];
    try {
      await saveProjectCompany(saveObj);
      if (props?.from == "companyProfile") {
        let companyId = props?.companyData?.id;
        history.push(`/company/${companyId}`);
      } else if (props?.from == "manufactureProfile") {
        let manufacturerId = props?.manufacturerData?.id;
        history.push(`/manufacturer/${manufacturerId}`);
      }
    } catch (error) {
      console.log("saveProjectCompany_ERROR: ", error);
    }
  };

  const saveProjectForProduct = async (item, productId) => {
    const saveObj = {
      UserId: Number(localStorage?.userId),
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: 0,
          ProjectId: item.id,
          ProductId: productId,
          ModifiedBy: Number(localStorage?.userId),
        },
      ],
    };
    try {
      await saveProjectProducts(saveObj);
      let productId = props?.productData?.id;
      history.push(`/product/${productId}`);
    } catch (error) {
      console.log("saveProjectproduct_ERROR: ", error);
    }
  };

  //changes-07-03-24-rahul
  const onAddToProfile = async (item) => {
    if (props?.from == "companyProfile") {
      let companyId = props?.companyData?.id;
      saveProjectForCompany(item, companyId);
    } else if (props?.from == "manufactureProfile") {
      let manufactureId = props?.manufacturerData?.id;
      saveProjectForCompany(item, manufactureId);
    } else if (props?.from == "productProfile") {
      let productId = props?.productData?.id;
      saveProjectForProduct(item, productId);
    } else {
      setSelectedProjectDetails(item);
      setShowAddExperienceModal(true);
    }
  };

  const onExperienceSave = (projectId) => {
    setShowAddExperienceModal(false);
    if (projectId) {
      history?.push(`/projects/project/${projectId}`);
    }
    return;
  };

  const onExperienceModalClose = () => {
    setShowExperienceDiscardModal(false);
    setShowAddExperienceModal(false);
    props?.onClose();
    if (props?.from === "experience") {
      history?.push(`/profile`);
    }
  };

  function handleAddLocationClick() {
    setMoreMenuAnchorEl(false);
    setShowchooselocation(true);
  }

  async function handleDeletebtnClick() {
    await setDeleteSiteConfirmModalOpen(true);
    await setMoreMenuAnchorEl(false);
  }

  async function handleDeleteSite() {
    try {
      const payloadObj = {
        UserId: Number(localStorage?.userId),
        ReturnRecordId: false,
        ReturnRecordError: true,
        DeleteList: [
          {
            Id: Number(props?.siteInfo?.id),
            ModifiedBy: Number(localStorage?.userId),
          },
        ],
      };
      const deleteSiteRes = await deleteSites(payloadObj);
      if (deleteSiteRes.message === "OK") {
        setDeleteSiteConfirmModalOpen(false);
        await props?.getNoLocationSiteList();
        await props?.getSiteInfo();
        onClose();
      }
    } catch (err) {
      console.log("Error occur while delete site", err);
    }
  }

  {
    /* Mahindra Code 28-mar-2024 */
  }
  function truncateText(text, maxLength) {
    if (text?.length <= maxLength) {
      return text;
    }
    return text?.substring(0, maxLength) + " ...";
  }
  const handleShowMoreDescriptions = () => {
    setInitialViewMore(false);
  };

  const handleNoLocationModalClose = () => {
    setShowchooselocation(false);
  };
  return (
    <>
      {props?.from == "mapScreenForAddOption" && !props?.showChooseOption ? (
        <>
          <Card
            className="site-view"
            sx={{
              width: props?.fromSignUpPage == "signUpPage" ? "380px" : "445px",
              height: props?.fromSignUpPage == "signUpPage" ? "75vh" : "91vh",
              minWidth:
                props?.fromSignUpPage == "signUpPage" ? "380px" : "445px",
              padding: "10px",
            }}
          >
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Stack display={"flex"} justifyContent={"flex-start"}>
                <Typography variant="h5">Droped Pinned</Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{ paddingBottom: "5px" }}
                >
                  {props?.siteAddressData?.address !== "" ? (
                    props?.siteAddressData?.address
                  ) : (
                    <>
                      {props?.chooseLocationSite?.lat === 0 &&
                      props?.chooseLocationSite?.lng === 0 ? null : (
                        <>{`(${props?.chooseLocationSite?.lat} , ${props?.chooseLocationSite?.lng})`}</>
                      )}
                    </>
                  )}
                  {/* <>{`(${(props?.chooseLocationSite?.lat)} , ${(props?.chooseLocationSite?.lng)})`}</> */}
                </Typography>
              </Stack>

              <Stack display={"flex"} flexDirection={"row"} gap={1}>
                {/* Mahindra Code 28-mar-2024 */}
                <Stack>
                  <IconButton
                    size="small"
                    style={{ color: "white", backgroundColor: "lightgrey" }}
                    onClick={onClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
            <div style={{ paddingBottom: "5px" }}>
              <Stack padding={"10px 5px"}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ border: "2px solid #083db8", fontWeight: "bold" }}
                  onClick={() => props?.onAddNewSite()}
                >
                  Add New Site
                </Button>
              </Stack>
              <Stack padding={"10px 5px"}>
                No Projects have been added to this location
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ border: "2px solid #083db8", fontWeight: "bold" }}
                  onClick={() => props?.onAddProjectClick()}
                >
                  Add Project
                </Button>
              </Stack>
            </div>
            <Divider />
          </Card>
        </>
      ) : loading ? (
        <Card
          className="site-view"
          style={{
            width: props?.fromSignUpPage == "signUpPage" ? "400px" : "445px",
            minWidth: props?.fromSignUpPage == "signUpPage" ? "400px" : "445px",
            height: props?.fromSignUpPage == "signUpPage" ? "78vh" : "91vh",
          }}
        >
          <Skeleton variant="rectangular" width={450} height={250} />
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme?.palette?.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <CardContent className="site-info ">
            <Skeleton width="50%" />
            <Skeleton width="50%" />
          </CardContent>
          <CardActions>
            <Skeleton width="60%" height={60} />
            <Skeleton width="60%" height={60} />
          </CardActions>
          <CardContent className="site-info add-site">
            <h5 className="add-siteheading">
              <Skeleton width="30%" />
            </h5>
            <span className="site-address">
              <Skeleton width="60%" />
            </span>
            <Skeleton width="100%" height={60} />
          </CardContent>
        </Card>
      ) : (
        <>
          {/* Mahindra Code 28-mar-2024 */}
          <>
            <Menu
              transformOrigin={{ horizontal: "center", vertical: "top" }}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              style={{ top: "0px", left: "10px" }}
              anchorEl={moreMenuAnchorEl}
              open={Boolean(moreMenuAnchorEl)}
              onClose={(event) => {
                setMoreMenuAnchorEl(false);
              }}
            >
              {props?.siteInfo?.active ? null : (
                <MenuItem
                  onClick={() => {
                    setSiteActiveStatus();
                  }}
                  style={{ color: BIBlue }}
                  dense
                >
                  Flag as Active
                </MenuItem>
              )}
              {props?.siteInfo?.latitude === 0 &&
              props?.siteInfo?.longitude === 0 ? (
                <MenuItem
                  onClick={() => handleAddLocationClick()}
                  style={{ color: BIBlue }}
                  dense
                >
                  Add location
                </MenuItem>
              ) : null}

              <MenuItem
                onClick={() => openAddDescriptionDialog()}
                style={{ color: BIBlue }}
                dense
              >
                Add Description
              </MenuItem>
              {props?.siteInfo?.latitude === 0 &&
              props?.siteInfo?.longitude === 0 ? (
                <MenuItem
                  onClick={() => handleDeletebtnClick()}
                  style={{ color: BIRed }}
                  dense
                >
                  Delete Site
                </MenuItem>
              ) : null}

              <MenuItem
                onClick={() => handleReport(siteId)}
                style={{ color: BIRed }}
                dense
              >
                Report
              </MenuItem>
            </Menu>
            {/* Mahindra Code 28-mar-2024 */}
            {!props?.showChooseOption ? (
              <Card
                className="site-view"
                sx={{
                  padding: "10px",
                  width:
                    props?.fromSignUpPage == "signUpPage" ? "380px" : "445px",
                  minWidth:
                    props?.fromSignUpPage == "signUpPage" ? "380px" : "445px",
                  height:
                    props?.fromSignUpPage == "signUpPage" ? "75vh" : "91vh",
                }}
              >
                <Stack
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                >
                  <Stack display={"flex"} justifyContent={"flex-start"}>
                    <Typography
                      variant="h5"
                      onClick={() => {
                        siteId && history.push(`/sites/site/${siteId}`);
                      }}
                      style={{
                        color:
                          props?.siteInfo?.name?.trim() !== ""
                            ? "black"
                            : "GrayText",
                      }}
                    >
                      {props?.siteInfo?.name?.trim() !== ""
                        ? props?.siteInfo?.name
                        : "Unnamed Site"}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{ paddingBottom: "5px" }}
                    >
                      {props?.siteInfo?.address !== "" ? (
                        props?.siteInfo?.address
                      ) : (
                        <>
                          {props?.siteInfo?.latitude === 0 &&
                          props?.siteInfo?.longitude === 0 ? null : (
                            <>{`(${props?.siteInfo?.latitude} , ${props?.siteInfo?.longitude})`}</>
                          )}
                        </>
                      )}
                    </Typography>
                  </Stack>

                  <Stack display={"flex"} flexDirection={"row"} gap={1}>
                    {props?.from === "experience" ||
                    props?.from == "companyProfile" ||
                    props?.from == "manufactureProfile" ||
                    props?.from == "productProfile" ||
                    props?.from == "quiz" ? (
                      <></>
                    ) : (
                      <Stack>
                        <IconButton
                          size="small"
                          onClick={(event) => {
                            setMoreMenuAnchorEl(event.currentTarget);
                          }}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </Stack>
                    )}
                    {/* Mahindra Code 28-mar-2024 */}
                    <Stack>
                      <IconButton
                        size="small"
                        style={{ color: "white", backgroundColor: "lightgrey" }}
                        onClick={onClose}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Stack>

                {/* new code pradip 05032024   */}
                <Stack className="scrollableMinDiv">
                  {props?.siteInfo?.latitude === 0 &&
                  props?.siteInfo?.longitude === 0 ? (
                    <>
                      <Typography marginBottom={"5px"} color={"GrayText"}>
                        {" "}
                        No Location
                      </Typography>
                      <Stack marginBottom={"10px"}>
                        <Stack
                          display={"flex"}
                          padding={"20px 10px"}
                          backgroundColor={BILightRed}
                          borderRadius={"5px"}
                        >
                          <Stack
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            paddingBottom={"10px"}
                          >
                            <ErrorIcon
                              fontSize="small"
                              style={{ color: BIRed, paddingRight: "3px" }}
                            />{" "}
                            <Typography
                              fontSize={13}
                              color={"red"}
                              fontWeight={"bold"}
                            >
                              {" "}
                              This Site will not be visible to others until a
                              location is added
                            </Typography>
                          </Stack>
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              setShowchooselocation(true);
                            }}
                          >
                            Add Site Location
                          </Button>
                        </Stack>
                      </Stack>
                    </>
                  ) : null}

                  {props?.siteInfo?.active && (
                    <Stack padding={"5px 0px"}>
                      <Chip style={{ width: "100px" }} label={"Active"} />
                    </Stack>
                  )}

                  {props?.siteInfo?.name?.trim() === "" && (
                    <Stack margin={"10px 0px"}>
                      <Stack
                        display={"flex"}
                        padding={"20px 10px"}
                        backgroundColor={BILightYellow}
                        borderRadius={"5px"}
                      >
                        <Stack
                          display={"flex"}
                          flexDirection={"row"}
                          alignItems={"center"}
                          paddingBottom={"10px"}
                        >
                          <WarningIcon
                            fontSize="small"
                            style={{ color: BIYellow, margin: "10px" }}
                          />
                          <Typography
                            fontSize={13}
                            color={BIYellow}
                            fontWeight={"bold"}
                          >
                            {" "}
                            Do you know the name of this site? Add it now to
                            help others find it.
                          </Typography>
                        </Stack>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            setShowAddNameModal(true);
                          }}
                        >
                          Add site Name
                        </Button>
                      </Stack>
                    </Stack>
                  )}

                  {projectImageArray?.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "7px",
                        paddingTop: "5px",
                      }}
                    >
                      {projectImageArray?.slice(0, 3)?.map((item, index) => (
                        <div key={index} onClick={() => openImageModal(index)}>
                          <img
                            src={item?.pictureUrl}
                            alt={`Image ${index}`}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      ))}

                      {projectImageArray?.length > 3 && (
                        <Button
                          color="primary"
                          size="small"
                          variant="outlined"
                          style={{ width: "100px", height: "100px" }}
                          onClick={() => openImageSeeMoreModal()}
                        >
                          <div>
                            <ArrowForwardIcon />
                            <br />
                            See More
                          </div>
                        </Button>
                      )}
                    </div>
                  )}
                  <div style={{ paddingBottom: "5px" }}>
                    {siteDescriptions?.length > 0 && (
                      <Stack paddingTop={"5px"}>
                        {/* Mahindra Code 28-mar-2024 */}
                        {siteDescriptions[0].description.length > 195 ? (
                          <>
                            {initialViewMore ? (
                              <>
                                <Typography variant="body2">
                                  {" "}
                                  {truncateText(
                                    siteDescriptions[0]?.description,
                                    195
                                  )}
                                </Typography>
                                <Stack display={"flex"} alignItems={"flex-end"}>
                                  <Button
                                    variant="text"
                                    size="small"
                                    color="primary"
                                    onClick={handleShowMoreDescriptions}
                                  >
                                    See More
                                  </Button>
                                </Stack>
                              </>
                            ) : (
                              <Typography variant="body2">
                                {" "}
                                {siteDescriptions[0]?.description}
                              </Typography>
                            )}
                          </>
                        ) : (
                          <Typography variant="body2">
                            {" "}
                            {siteDescriptions[0]?.description}
                          </Typography>
                        )}
                      </Stack>
                    )}
                    {siteDescriptions?.length === 0 && (
                      <Stack padding={"10px 5px"}>
                        <Button
                          fullWidth
                          variant="outlined"
                          color="primary"
                          size="small"
                          style={{
                            border: "2px solid #083db8",
                            fontWeight: "bold",
                          }}
                          onClick={() => openAddDescriptionDialog()}
                        >
                          Add Description
                        </Button>
                      </Stack>
                    )}

                    {siteProjects?.length === 0 ? (
                      <></>
                    ) : (
                      <>
                        {allFollowing ? (
                          <Stack padding={"10px 5px"}>
                            <Button
                              fullWidth
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{
                                border: "2px solid #083db8",
                                fontWeight: "bold",
                              }}
                              onClick={() => handleFollowingPressed()}
                            >
                              Following All
                            </Button>
                          </Stack>
                        ) : (
                          <Stack padding={"10px 5px"}>
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              size="small"
                              style={{
                                border: "2px solid #083db8",
                                fontWeight: "bold",
                              }}
                              onClick={() => handleFollowPressed()}
                            >
                              Follow All
                            </Button>
                          </Stack>
                        )}
                      </>
                    )}

                    {/* {siteDescriptions.length !== 0 && props?.siteInfo?.name?.trim() !== "" && ( */}
                    {siteDescriptions?.length !== 0 && (
                      <Stack display={"flex"} alignItems={"flex-end"}>
                        <Typography
                          variant="body2"
                          style={{
                            color: "gray",
                            cursor: "pointer",
                            padding: "5px",
                          }}
                          onClick={() => setOtherDescriptionDialogOpen(true)}
                        >
                          Other Description({noOfDescription})
                        </Typography>
                      </Stack>
                    )}

                    {/* Mahindra Code 28-mar-2024 */}
                    {otherDescriptionDialogOpen && (
                      <DescriptionDialog
                        otherDescriptionDialogOpen={otherDescriptionDialogOpen}
                        setOtherDescriptionDialogOpen={
                          setOtherDescriptionDialogOpen
                        }
                        SiteName={props?.siteInfo?.name}
                        siteCardDescription={siteDescriptions}
                        allDataLoaded={allDataLoadedForDesciption}
                        pageNumber={descPageNumber}
                        getSiteDescriptions={() => {
                          getSiteDescriptions(descPageNumber);
                        }}
                      />
                    )}
                  </div>

                  <Divider />

                  {siteProjects?.length > 0 && (
                    <Stack
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      padding={"5px 0px"}
                    >
                      <Typography variant="body2" color={"GrayText"}>
                        {noOfProject} Project(s)
                      </Typography>

                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => setShowAddProjectModal(true)}
                      >
                        Add Project
                      </Button>
                    </Stack>
                  )}

                  {siteProjects?.length > 0 ? (
                    <div>
                      <div
                        className={
                          projectImageArray?.length > 0
                            ? "scrollableMinDiv"
                            : "scrollableMaxDiv"
                        }
                        id="scrollableDiv"
                        style={{ overflow: "auto", marginRight: "5px" }}
                      >
                        {/* Mahindra Code 28-mar-2024 */}
                        <InfiniteScroll
                          dataLength={siteProjects?.length}
                          next={() => getSiteProjects(pageNumber)}
                          hasMore={!allDataLoaded}
                          loader={
                            allDataLoaded ? null : (
                              <h4
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <CircularProgress />
                              </h4>
                            )
                          }
                          scrollableTarget="scrollableDiv"
                        >
                          <List sx={{ padding: 0 }}>
                            {siteProjects?.map((project, index) => (
                              <div key={index}>
                                <ProjectList
                                  from={props?.from}
                                  project={project}
                                  onAddToProfile={onAddToProfile}
                                  fromQuestion={props?.fromQuestion}
                                />
                              </div>
                            ))}
                          </List>
                        </InfiniteScroll>
                      </div>
                    </div>
                  ) : (
                    <Stack
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      padding={"10px 0px"}
                    >
                      <Typography variant="body2" color={"GrayText"}>
                        No Projects have been added to this location
                      </Typography>

                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => setShowAddProjectModal(true)}
                      >
                        Add Project
                      </Button>
                    </Stack>
                  )}
                </Stack>
              </Card>
            ) : (
              <Card
                className="site-view"
                sx={{
                  padding: "10px",
                  width:
                    props?.fromSignUpPage == "signUpPage" ? "380px" : "445px",
                  minWidth:
                    props?.fromSignUpPage == "signUpPage" ? "380px" : "445px",
                  height:
                    props?.fromSignUpPage == "signUpPage" ? "75vh" : "91vh",
                }}
              >
                <Stack
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                >
                  {props?.from == "mapScreenForAddOption" &&
                  props?.showChooseOption ? (
                    <>
                      <Stack display={"flex"} justifyContent={"flex-start"}>
                        <Typography variant="h5">Droped Pinned</Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          style={{ paddingBottom: "5px" }}
                        >
                          {props?.siteAddressData?.address !== "" ? (
                            props?.siteAddressData?.address
                          ) : (
                            <>
                              {props?.chooseLocationSite?.lat === 0 &&
                              props?.chooseLocationSite?.lng === 0 ? null : (
                                <>{`(${props?.chooseLocationSite?.lat} , ${props?.chooseLocationSite?.lng})`}</>
                              )}
                            </>
                          )}
                          {/* <>{`(${(props?.chooseLocationSite?.lat)} , ${(props?.chooseLocationSite?.lng)})`}</> */}
                        </Typography>
                      </Stack>
                    </>
                  ) : (
                    <Stack display={"flex"} justifyContent={"flex-start"}>
                      <Typography
                        variant="h5"
                        onClick={() => {
                          siteId && history.push(`/sites/site/${siteId}`);
                        }}
                        style={{
                          color:
                            props?.siteInfo?.name?.trim() !== ""
                              ? "black"
                              : "GrayText",
                        }}
                      >
                        {props?.siteInfo?.name?.trim() !== ""
                          ? props?.siteInfo?.name
                          : "Unnamed Site"}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {props?.siteInfo?.address !== "" ? (
                          props?.siteInfo?.address
                        ) : (
                          <>
                            {props?.siteInfo?.latitude === 0 &&
                            props?.siteInfo?.longitude === 0 ? null : (
                              <>{`(${props?.siteInfo?.latitude} , ${props?.siteInfo?.longitude})`}</>
                            )}
                          </>
                        )}
                      </Typography>
                    </Stack>
                  )}
                  <Stack>
                    <IconButton
                      onClick={onClose}
                      size="small"
                      style={{ color: "white", backgroundColor: "lightgrey" }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                </Stack>
                <Stack>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ margin: "20px 0px", width: "100%" }}
                    onClick={onChooseSiteForAddProject}
                  >
                    Choose
                  </Button>
                  {/* <Typography style={{ textAlign: 'center', cursor: 'pointer', color: 'red', fontSize: '14px', paddingBottom: '10px' }} onClick={() => {
                  if (location?.state?.from === "quiz_site") {
                    history.push({
                      pathname: "/rewards/earn-rewards",
                      state: {}
                    })
                  } else {
                    props?.setShowChooseOption(false);
                  }
                }}>
                  Don't want to choose right now?
                </Typography> */}
                </Stack>
                <Divider />
              </Card>
            )}
          </>
        </>
      )}
      {showAddProjectModal && (
        <AddProjectModal
          show={showAddProjectModal}
          onClose={() => {
            setShowAddProjectModal(false);
            props?.onClose();
            props.closeProfileProject();
            if (props?.showChooseOption) {
              props?.showSiteInfo();
            }
          }}
          companyData={props?.companyData ?? {}}
          manufacturerData={props?.manufacturerData ?? {}}
          productData={props?.productData ?? {}}
          // companyDetails={props?.companyDetails ?? {}}
          siteDetails={props?.siteInfo ?? {}}
          onDataSave={onProjectSave}
          onChangeSite={(obj) => {
            setShowAddProjectModal(false);
            props.onChangeSite(obj);
          }}
          addProjectObj={props?.addProjectObj}
          showChooseOption={props?.showChooseOption}
          from={props?.from}
        />
      )}

      {addDescriptionDialogOpen && (
        <AddDescriptionDialog
          addDescriptionDialogOpen={addDescriptionDialogOpen}
          siteDetails={props?.siteInfo ?? {}}
          onDataSave={async () => {
            setDescPageNumber(1);
            await getSiteDescriptions(1);
          }}
          firstDescription={siteDescriptions?.length === 0}
          onClose={() => {
            setAddDescriptionDialogOpen(false);
          }}
        />
      )}

      {showAddNameModal && (
        <AddSiteNameModal
          open={showAddNameModal}
          onClose={() => {
            setShowAddNameModal(false);
          }}
          siteDetails={props?.siteInfo}
          onDataSave={() => {
            props?.getSiteInfo();
          }}
        />
      )}

      {reportModalOpenSearch && (
        <ReportModal
          name={props?.siteInfo?.name}
          isOpen={reportModalOpenSearch}
          onClose={() => {
            setReportModalOpenSearch(false);
          }}
          recordId={siteId}
        />
      )}

      {/* new code Pradip 08032024 */}
      <Chooselocation
        chooseLocation={chooseLocation}
        siteInfo={props?.siteInfo}
        fromSiteCard
        getSiteInfo={props?.getSiteInfo}
        setLoading={setLoading}
        showChooselocation={showChooselocation}
        setShowchooselocation={setShowchooselocation}
        setChooselocation={setChooselocation}
        getNoLocationSiteList={props?.getNoLocationSiteList}
        currentLocation={props?.currentLocation}
        noLocationSiteModal={true}
        onClose={onClose}
        addNewSiteLocation={addNewSiteLocation}
        setAddNewSiteLocation={setAddNewSiteLocation}
        from={props?.from}
        handleNoLocationModalClose={handleNoLocationModalClose}
      />

      {/* changes-07-03-24-rahul */}
      {showAddExperienceModal && (
        <>
          <Modal
            open={showAddExperienceModal}
            onClose={() => {
              setShowAddExperienceModal(false);
            }}
          >
            <div className="modal-center">
              <div className="modal-card" style={{ width: "800px" }}>
                <div className="modal-card-body">
                  <ExperienceProjectForm
                    siteDetails={props?.siteInfo}
                    projectId={selectedProjectDetails?.id}
                    projectName={selectedProjectDetails?.projectName}
                    onClose={() => onExperienceModalClose()}
                    showExperienceDiscardModal={showExperienceDiscardModal}
                    setShowExperienceDiscardModal={
                      setShowExperienceDiscardModal
                    }
                    onDataSave={onExperienceSave}
                    isFollowing={true}
                    companyData={props?.companyData}
                    from={props?.from}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}

      <ImageDialog
        imageModalOpen={imageModalOpen}
        setImageModalOpen={setImageModalOpen}
        projectImageArray={projectImageArray}
        siteName={props?.siteInfo?.name}
      />

      <PictureViewer
        isOpen={pictureViewerOpen}
        onClose={() => setPictureViewerOpen(false)}
        pictures={projectImageArray.map((pic) => ({
          src: pic.pictureUrl,
          alt: "",
        }))}
        viewerIndex={pictureViewIndex}
      />

      <PromptModal
        isOpen={deleteSiteConfirmModalOpen}
        onClose={() => {
          setMoreMenuAnchorEl(false);
          setDeleteSiteConfirmModalOpen(false);
        }}
        title="Are you sure you want to delete this site?"
        desc="This action cannot be undone."
        redButtonText="Delete"
        onRedButtonClick={() => {
          handleDeleteSite();
        }}
      />

      <PromptModal
        isOpen={removeProjectSectionAlert}
        onClose={() => {
          setRemoveProjectSectionAlert(false);
        }}
        title="Are you sure you want to unfollow this Site Page"
        redButtonText="Yes"
        blueButtonTextFirst="No"
        onRedButtonClick={() => {
          handleSaveFollowClick(false);
          setRemoveProjectSectionAlert(false);
        }}
      />
    </>
  );
};

export default SiteCard;
