import React from "react";
import {
  Box,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import { useState } from "react";
import { saveSitesDescriptions } from "../../../api/SitesApi";
import {
  TextField, IconButton, Button,
  CircularProgress
} from "@material-ui/core";

const AddDescriptionDialog = (props) => {


  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);



  const onClose = () => {
    props.onClose();
  }

  const onSave = async () => {
    try {
      setIsLoading(true);
      const obj = {
        UserId: localStorage?.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            SiteId: props?.siteDetails?.id,
            Description: description,
            ModifiedBy: localStorage?.userId,
          },
        ],
      };
      await saveSitesDescriptions(obj);
      props.onDataSave();
      setIsLoading(true);
      onClose();
    } catch (error) {
      console.log("Error while saving description :: ", error);
    }
  }


  return (
    <Modal
      open={props.addDescriptionDialogOpen}
      onClose={onClose}
      aria-labelledby="add-description-modal"
      aria-describedby="add-description-form">
      <Box className="modal-center" sx={{ width: 600 }}>
        <div className="modal-card" style={{ padding: "15px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}>
            <Typography variant="h6" > {props?.firstDescription ? "Add New Description " : "Add  Description "}</Typography>

            <IconButton
              onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {/* new code sanket 18-03-2024 */}
          <Stack style={{ marginBottom: '15px' }}>
            <TextField
              multiline
              rows={4}
              fullWidth
              label="Enter Description"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              sx={{ mb: 2, mt: 2 }}
            />
          </Stack>
          {
            !isLoading ?
              <Button
                disabled={description?.trim() === ""}
                variant="contained"
                color="primary"
                size="medium"
                component="label"
                onClick={onSave}
              >
                Save
              </Button>
              :
              <Button
                disabled={true}
                variant="contained"
                color="primary"
                size="medium"
                component="label"
              >
                <CircularProgress size={24} />
              </Button>
          }
        </div>{" "}
      </Box>
    </Modal>
  );
};

export default AddDescriptionDialog;
