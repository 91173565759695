import { Button, Checkbox, FormControlLabel, FormControl, FormGroup, Autocomplete, Grid, IconButton, ImageList, ImageListItem, Input, MenuItem, Modal, Select, TextField, Typography, InputLabel, ListItemText, Box, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import mapMarkerYellow from "../../assets/images/map-marker-yellow.png";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Close as CloseIcon } from "@material-ui/icons";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { fetchSitesCountry, getAddress, saveSitesDetails } from "../../api/SitesApi";
import "../../assets/css/Sites.css";
import { savePost, savePostPicture } from "../../api/PostsApi";
import { getProjectTypes } from "../../api/ProjectsApi";
import { Autocomplete as GooglePlacesAutocomplete } from "@react-google-maps/api";
import { fetchUserTrades, saveUserWorkHistory } from "../../api/UserApi";
import AddSiteModal from "../profile/sections/AddSiteModal";
import ProjectForm from "../profile/sections/ProjectForm";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Chooseoption from "./Chooseoption";
import { useHistory } from 'react-router-dom';
import { useActions } from "../../components/ActionsContext";

function Addsite(props) {

    const history = useHistory();
    const [addSite, setAddsite] = useState({
        siteId: 0,
        buildingTypeId: 0,
        siteName: "",
        type: "",
        website: "",
        country: "",
        address: "",
        city: "",
        province: "",
        postalCode: "",
        latitude: 0,
        longitude: 0,
        workedSite: false
    })
    const [sitePictures, setSitepictures] = useState([]);
    const [projectTypes, setProjecttypes] = useState([]);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [autocomplete, setAutocomplete] = useState(null);
    const [userTrade, setUserTrade] = useState([]);
    const [addSiteModalOpen, setAddSiteModalOpen] = useState(false);
    const location = useLocation();
    const [changeLocation, setChangeLocation] = useState(false);
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0
    });
    const [projectForm, setProjectForm] = useState(false);

    const { getBuilderBucksCount } = useActions()

    const containerStyle = {
        width: '100%',
        height: '150px',
        border: "1px solid #000000d6",
        marginBottom: "14px"
    };

    useEffect(() => {
        getInitaldata();
    }, [])

    const getInitaldata = async () => {
        let siteData = {};
        if (props.chooseLocation?.lat !== 0 && props.chooseLocation?.lng !== 0) {
            let getLatlng = {
                latitude: props.chooseLocation?.lat,
                longitude: props.chooseLocation?.lng
            }
            let addressData = await getAddress(getLatlng);
            let addressObj = addressData.objAddress;
            siteData = {
                ...props?.siteInfo,
                address: addressObj?.fullAddress,
                city: addressObj?.city,
                country: addressObj?.country,
                latitude: addressObj?.latitude,
                longitude: addressObj?.longitude,
                province: addressObj?.province,
                postalCode: addressObj?.postalCode,
            }
        } else {
            siteData = {
                ...props?.siteInfo,
            }
        }
        getSiteData(siteData);
        await countryObj();
    }

    const countryObj = async () => {
        let request = {
            PageNr: 1,
            FullSearch: "",
            IncludeRecordNr: false,
            NrOfRecPerPage: 0,
            SearchList: []
        }
        let countriesResponse = await fetchSitesCountry(request);
        setCountries(countriesResponse);
        await getUsertrades();
    }

    const getUsertrades = async () => {
        const userTradeObj = {
            PageNr: 1,
            FullSearch: "",
            IncludeRecordNr: false,
            TypeOfObjectReturned: "",
            FetchAllowedRecordsOnly: false,
            SearchList: [{ UserId: localStorage.userId }],
        }
        const userTradeRes = await fetchUserTrades(userTradeObj);
        const mainTrade = userTradeRes.filter((item) => item.tradeType.toLowerCase() == 'main')
        setUserTrade(mainTrade[0]);
    }

    const getSiteData = (siteData) => {
        setSelectedCountry(siteData?.country?.toLowerCase() === "india" ? "India" : siteData?.country?.toLowerCase() === "united states" ? "USA" : siteData?.country?.toLowerCase() === "canada" ? "Canada" : siteData?.country?.toLowerCase() === "united kingdom" || siteData?.country?.toLowerCase() === "united kingdom" ? "United Kingdom" : "");
        setAddsite({
            ...addSite,
            siteId: typeof siteData?.id === "undefined" ? 0 : siteData?.id,
            buildingTypeId: typeof siteData?.buildingTypeId === "undefined" ? 0 : siteData?.buildingTypeId,
            address: siteData?.address,
            city: siteData?.city,
            country: siteData?.country,
            latitude: siteData?.latitude,
            longitude: siteData?.longitude,
            province: siteData?.province,
            postalCode: siteData?.postalCode,
        });
        setCenter({
            lat: siteData?.latitude,
            lng: siteData?.longitude
        });
        if (sitePictures && sitePictures.length > 0) {
            sitePictures.filter((img) => img.alreadySelected);
            if (sitePictures.length === 0) {
                if (props.selectSitepic !== null) {
                    let getSitepic = [...sitePictures];
                    getSitepic.push(props.selectSitepic);
                    return setSitepictures(getSitepic);
                }
            }
        } else {
            if (props.selectSitepic !== null) {
                let getSitepic = [...sitePictures];
                getSitepic.push(props.selectSitepic);
                return setSitepictures(getSitepic);
            }
        }
    }

    const closeSite = () => {
        setSitepictures([]);
        props.setShowaddsite(false);
        props.setChooselocation({
            lat: 0,
            lng: 0
        })
    }

    const openFilepicker = (id) => {
        document.getElementById(id).click();
    }

    const getPictures = (e) => {
        setSitepictures([...sitePictures, e.target.files[0]])
    }

    const addSitechange = (e) => {
        setAddsite({
            ...addSite,
            [e.target.name]: e.target.value
        })
    }

    const getProjecttypes = async (e) => {
        let getResponse = await getProjectTypes(e.target.value);
        getResponse = getResponse.map((item) => {
            return {
                label: item?.name,
                id: item?.id
            }
        })
        setProjecttypes(getResponse);
    }

    const selectProjecttype = (event, newValue) => {
        setAddsite({
            ...addSite,
            type: newValue,
            buildingTypeId: newValue?.id
        })
    }

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
        setAddsite({
            ...addSite,
            country: event.target.value
        })
    };

    const closeCurrentimage = (e, getIndex) => {
        let getUpdatedlist = sitePictures.filter((item, index) => index !== getIndex);
        setSitepictures(getUpdatedlist);
    }

    const setSearchTerm = (getAddress) => {
        setAddsite({
            ...addSite,
            address: getAddress
        })
    }

    const handleSearch = () => {
        let place = autocomplete.getPlace();
        if (place?.address_components) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            setAddsite({
                ...addSite,
                country: place?.address_components.find(address => address.types.includes('country'))?.long_name ?? '',
                address: place?.formatted_address,
                city: place?.address_components.find(address => address.types.includes('locality'))?.long_name ?? '',
                province: place?.address_components.find(address => address.types.includes('administrative_area_level_1'))?.short_name ?? '',
                postalCode: place?.address_components.find(address => address.types.includes('postal_code'))?.long_name ?? '',
                latitude: lat,
                longitude: lng
            })
        }
    };

    const workSiteChange = (e) => {
        setAddsite({
            ...addSite,
            workedSite: e.target.checked
        })
    }

    const onChangelocation = () => {
        props.setChooselocation({
            lat: addSite?.latitude,
            lng: addSite?.longitude
        })
        setChangeLocation(true);
    }

    const saveSiterequest = async () => {
        try {
            if (!addSite?.siteName) {
                alert('Please enter site name')
                return;
            }
            if (addSite?.postalCode != '' && addSite?.postalCode.length > 10) {
                alert(`Please enter correct ${addSite?.country.toLowerCase() == 'usa' ? 'ZIP code' : addSite?.country.toLowerCase() == 'united kingdom' ? 'postcode' : 'postal code'}`)
                return;
            }
            const obj = {
                UserId: Number(localStorage.userId),
                ReturnRecordId: true,
                ReturnRecordError: true,
                SaveList: [{
                    Id: 0,
                    Name: addSite?.siteName,
                    Address: addSite?.address,
                    City: addSite?.city,
                    Province: addSite?.province,
                    PostalCode: addSite?.postalCode,
                    Country: addSite?.country,
                    Latitude: addSite?.latitude,
                    Longitude: addSite?.longitude,
                    StartDate: '',
                    EndDate: '',
                    Active: true,
                    BuildingTypeId: addSite?.buildingTypeId,
                    RecordStatusId: 1,
                    ContractorId: 0,
                    JobNo: '',
                    ConstructionStatusId: 0,
                    WebSite: addSite.website,
                    Description: '',
                    ModifiedBy: Number(localStorage.userId)
                }]
            }
            const result = await saveSitesDetails(obj);
            // Set new site id
            setAddsite({
                ...addSite,
                siteId: result[0]?.objData?.id
            })
            const savePostObj = {
                UserId: Number(localStorage.userId),
                ReturnRecordId: true,
                ReturnRecordError: false,
                SaveList: [{
                    Id: 0, FormatTypeId: 1,
                    PostedByUserId: Number(localStorage.userId),
                    AppId: 2,
                    AppRecordId: result[0]?.objData?.id,
                    Message: '<<<picture>>>',
                    Reported: false,
                    ModifiedBy: Number(localStorage.userId)
                }],
            }
            const postResult = await savePost(savePostObj);
            getBuilderBucksCount()
            for (let i = 0; i < sitePictures.length; i++) {
                const formData = new FormData();
                formData.append('Id', 0);
                formData.append('PostId', postResult[0]?.objData?.id);
                formData.append('ModifiedBy', Number(localStorage.userId));
                formData.append('Pictures', sitePictures[i]);
                await savePostPicture(formData);
            }
            if (addSite.workedSite === true) {
                setProjectForm(true);
                setAddSiteModalOpen(true);
            } else {
                history.push(`/sites/site/${result[0]?.objData?.id ?? 0}`)
            }
        } catch (err) {
            console.log("Error while saving the site ::", err)
        }
    }

    return (
        <>
            {
                projectForm === false ? (
                    <>
                        <Modal
                            open={props?.showAddsite}
                            className="add-site-modal"
                            onClose={() => {
                                props.setShowaddsite(false);
                            }}
                        >
                            <div className="modal-center">
                                <div className="modal-card addsite-card">
                                    <div className="header-section">
                                        <Typography className="addsite-title" variant="h6" component="h2">
                                            Add Site
                                        </Typography>
                                        <IconButton color="primary" onClick={closeSite}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                    <div className="main">
                                        {sitePictures.length > 0 ? (
                                            <ImageList sx={{ width: "auto", height: "auto" }} cols={5} rowHeight={164} className="sitepic-list">
                                                {
                                                    sitePictures.map((item, index) => (
                                                        <ImageListItem className="sitepic-item">
                                                            <img
                                                                srcSet={URL.createObjectURL(item)}
                                                                src={URL.createObjectURL(item)}
                                                                loading="lazy"
                                                                className="site-img"
                                                            />
                                                            <div className="close-icon" onClick={(e) => closeCurrentimage(e, index)}>
                                                                <CloseIcon className="icon-cl" />
                                                            </div>
                                                        </ImageListItem>
                                                    ))
                                                }
                                                <ImageListItem className="sitepic-item">
                                                    <div className="add-picture addpic-inimagelist" onClick={() => openFilepicker("multiplesite-pic")}>
                                                        <Input type="file" id="multiplesite-pic" style={{ display: "none" }} onChange={(e) => getPictures(e)} />
                                                        <InsertPhotoIcon style={{ height: "18px" }} /><span className="addpicture-text">Add pictures</span>
                                                    </div>
                                                </ImageListItem>
                                            </ImageList>
                                        ) : (
                                            <div className="add-picture" onClick={() => openFilepicker("addsite-pic")}>
                                                <Input type="file" id="addsite-pic" style={{ display: "none" }} onChange={(e) => getPictures(e)} />
                                                <InsertPhotoIcon /><span className="addpicture-text">Add pictures</span>
                                            </div>
                                        )}
                                        <div className="site-info">
                                            <h5 className="title">Site Info</h5>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} lg={6}>
                                                    <TextField
                                                        size="small"
                                                        type="text"
                                                        variant="outlined"
                                                        label="Site Name"
                                                        fullWidth
                                                        style={{ marginBottom: "14px" }}
                                                        value={addSite?.siteName}
                                                        name="siteName"
                                                        onChange={addSitechange}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} lg={6}>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={projectTypes}
                                                        sx={{ width: 300 }}
                                                        size="small"
                                                        value={addSite?.type}
                                                        onChange={selectProjecttype}
                                                        renderInput={(params) => <TextField onChange={(e) => getProjecttypes(e)} label={"Type (Optional)"} {...params} />}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <TextField
                                                size="small"
                                                type="text"
                                                variant="outlined"
                                                label="Website (optional)"
                                                fullWidth
                                                style={{ marginBottom: "14px" }}
                                                value={addSite?.website}
                                                name="website"
                                                onChange={addSitechange}
                                            />
                                        </div>
                                        <div className="site-info">
                                            <GoogleMap
                                                center={center}
                                                zoom={13}
                                                mapContainerStyle={containerStyle}
                                            >
                                                <Marker
                                                    position={center}
                                                    icon={{
                                                        url: mapMarkerYellow,
                                                        scaledSize: (
                                                            new window.google.maps.Size(30, 40)
                                                        )
                                                    }} />
                                            </GoogleMap>
                                            <Button
                                                className="changeloc-btn"
                                                variant="contained"
                                                fullWidth onClick={onChangelocation}>
                                                Change location
                                            </Button>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} lg={6}>
                                                    <Box size="small">
                                                        <FormControl size="small" fullWidth>
                                                            <InputLabel size="small" >Country</InputLabel>
                                                            <Select
                                                                style={{ height: "41px" }}
                                                                size="small"
                                                                label="Country"
                                                                value={selectedCountry}
                                                                onChange={handleCountryChange}
                                                            >
                                                                {countries.map((country) => {
                                                                    return (
                                                                        <MenuItem key={country?.id} value={country?.name}>
                                                                            <ListItemText primary={country?.name} />
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6} lg={6}>
                                                    <style>
                                                        {
                                                            (
                                                                `.pac-container {
                                                                         width: 299px !important;
                                                                   }`
                                                            )
                                                        }
                                                    </style>
                                                    <GooglePlacesAutocomplete
                                                        onPlaceChanged={handleSearch}
                                                        onLoad={newInstance => {
                                                            setAutocomplete(newInstance)
                                                        }}
                                                        options={{
                                                            componentRestrictions: { country: ['US', 'CA', 'GB'] }, // Restrict to USA, Canada, and UK
                                                        }}
                                                    >
                                                        <TextField
                                                            variant="outlined"
                                                            placeholder="Search address or site name"
                                                            fullWidth
                                                            size="small"
                                                            style={{ marginBottom: "14px" }}
                                                            value={addSite?.address}
                                                            onChange={(event) => setSearchTerm(event.target.value)}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">
                                                                    {addSite?.address !== "" && (
                                                                        <IconButton
                                                                            onClick={() => setSearchTerm("")}>
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </InputAdornment>,
                                                            }} />
                                                    </GooglePlacesAutocomplete>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} lg={6}>
                                                    <TextField
                                                        size="small"
                                                        type="text"
                                                        variant="outlined"
                                                        label="City"
                                                        name="city"
                                                        value={addSite?.city}
                                                        fullWidth
                                                        style={{ marginBottom: "14px" }}
                                                        onChange={addSitechange}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} lg={6}>
                                                    <TextField
                                                        size="small"
                                                        type="text"
                                                        variant="outlined"
                                                        label="Province"
                                                        value={addSite?.province}
                                                        name="province"
                                                        fullWidth
                                                        style={{ marginBottom: "14px" }}
                                                        onChange={addSitechange}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <TextField
                                                size="small"
                                                type="text"
                                                variant="outlined"
                                                label="Postal Code"
                                                value={addSite?.postalCode}
                                                name="postalCode"
                                                fullWidth
                                                style={{ marginBottom: "14px" }}
                                                onChange={addSitechange}
                                            />
                                            <FormGroup style={{ marginBottom: "14px" }}>
                                                <FormControlLabel control={<Checkbox value={addSite?.workedSite} onChange={workSiteChange} />} label="I worked at this Site" />
                                            </FormGroup>
                                            <Button
                                                style={{ marginBottom: "14px" }}
                                                color="primary"
                                                variant="contained"
                                                fullWidth
                                                disabled={addSite?.siteName?.trim() === "" ? true : false}
                                                onClick={saveSiterequest}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        {changeLocation && (<Chooseoption setChooselocation={props.setChooselocation} chooseLocation={props.chooseLocation} getInitaldata={getInitaldata} setChangeLocation={setChangeLocation} fromAddsite={true} />)}
                    </>
                ) : (
                    <AddSiteModal
                        setAddSiteModalOpen={setAddSiteModalOpen}
                        addSiteModalOpen={addSiteModalOpen}
                        resetSelected={() => {
                            props.setShowaddsite(false);
                            setAddSiteModalOpen(false)
                        }}
                        projectForm={<ProjectForm
                            siteName={addSite?.siteName}
                            siteAddress={addSite?.address}
                            siteId={addSite?.siteId}
                            isSiteExistForm={location?.state?.projectSelectCompany ? true : false}
                            company={location?.state?.projectSelectCompany ? location?.state?.projectSelectCompany : {}}
                            tradeId={userTrade?.tradeId ?? 0}
                            tradeName={userTrade?.tradeName ?? ""}
                            isSiteNewForm={true}
                            goToSite={true}
                            onReturnClick={() => {
                                props.setShowaddsite(false);
                            }}
                        />}
                    />
                )
            }
        </>
    )

}
export default Addsite;