import React, { useState } from "react";

import { IconButton, FormControlLabel, Checkbox } from "@material-ui/core";
import {
  ExpandMore as ExpandIcon,
  ExpandLess as CollapseIcon,
} from "@material-ui/icons";

function ProductsCard(props) {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <>
      <div className="card" style={{ maxHeight: "100%" }}>
        <div className="flex-container-horizontal">
          <div className="flex-container-horizontal" style={{ flex: 1 }}>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <h3>Products</h3>
            </span>
          </div>
          <div>
            {isExpanded ? (
              <IconButton color="primary" onClick={() => setIsExpanded(false)}>
                <CollapseIcon />
              </IconButton>
            ) : (
              <IconButton color="primary" onClick={() => setIsExpanded(true)}>
                <ExpandIcon />
              </IconButton>
            )}
          </div>
        </div>

        <div>
          {isExpanded ? (
            <>
              {props.product.length > 0 ? (
                props.product.map((prod) => (
                  <Certification
                    key={prod.id}
                    product={prod}
                    handleToggle={() =>
                      props.handleToggle(prod.id, "ProductsCard")
                    }
                  />
                ))
              ) : (
                <p>
                  You haven't added any product, degrees, or training
                  experiences to your profile.
                </p>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default ProductsCard;

export function Certification(props) {
  const productName = props.product.productName;
  const manufacturerName = props.product.manufacturerName;

  return (
    <div className="flex-container-horizontal">
      <div className="flex-container-left">
        <div className="content-container">
          <p>
            <b>{productName}</b>
          </p>
          {manufacturerName ? <p>{manufacturerName}</p> : <p>Unknown</p>}
        </div>
      </div>

      <div className="flex-container-right">
        <div className="input-container">
          <FormControlLabel
            control={
              <Checkbox
                checked={props.product.isSelected}
                color="primary"
                onChange={() => {
                  props.handleToggle();
                }}
              />
            }
          />
        </div>
      </div>
    </div>
  );
}
