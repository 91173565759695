import React, { useEffect, useState } from "react";
import { MoreVert as MoreIcon } from "@mui/icons-material";
import { BIRed } from "../../assets/buildidColors";
import "../../assets/css/Search.css";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Card, IconButton, ListItem, ListItemButton, ListItemText, ListSubheader, Menu, MenuItem } from "@mui/material";
import { useHistory } from "react-router-dom";
import PromptModal from "../../components/PromptModal";
import ReportModal from "../../components/ReportModal";

export default function SearchListSection(props) {

	const [list, setList] = useState([]);
	const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(false);
	const [mobileAppRedirect, setMobileAppRedirect] = useState(false);
	const [reportModalOpenSearch, setReportModalOpenSearch] = useState(false);
	const [itemRecordId, setItemRecordId] = useState(0);
	const [itemName, setItemname] = useState("");

	const history = useHistory();

	var isAndroid = /Android/i.test(navigator.userAgent);
	var isIphone = /iPhone/i.test(navigator.userAgent);


	useEffect(() => {
		updateList();
	}, [])

	useEffect(() => {
		if (props?.getUpdatedData) {
			updateList();
		}
	}, [props?.getUpdatedData])

	const updateList = async () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
		if (props.universalsearch === true) {
			setList(props.getData)
		} else {
			await props?.getData(props?.page, props?.searchText)
				.then(async (res) => {
					setList(res);
				})
			props?.setGetUpdatedData(false)
		}
	}

	const openMenu = (event, item) => {
		event.stopPropagation();
		event.preventDefault();
		setMoreMenuAnchorEl(event.currentTarget);
		setItemRecordId(item?.id);
		setItemname(item?.name);
	}

	const handleReport = () => {
		setMoreMenuAnchorEl(false)
		setReportModalOpenSearch(true);
	}

	const showSeemore = () => {
		if (props.from === "product")
			history.push(`/products`);
		if (props.from === "company")
			history.push(`/companies`);
		if (props.from === "manufacturer")
			history.push(`/manufactures`);
		if (props.from === "trade")
			history.push(`/trades`);
	}

	const handleMobileAppClick = () => {
		if (isAndroid) {
			const url = `intent://?id=${props.data[props.itemIdKey]}&type=${props.itemRecordType}#Intent;scheme=mybuilderid;package=com.mybuilderid;end`;
			window.location.replace(url);
		}
		else if (isIphone) {
			window.location.replace(`mybuilderid://?id=${props.data[props.itemIdKey]}&type=${props.itemRecordType}`)
		}
	}


	const handleWebAppClick = (e, item) => {
		e.stopPropagation();
		e.preventDefault();
		if (props.from === "product")
			if (isAndroid || isIphone)
				setMobileAppRedirect(true);
			else {
				history.push(`/product/${item?.id}`);
			}
		if (props.from === "company")
			if (isAndroid || isIphone)
				setMobileAppRedirect(true);
			else
				history.push(`/company/${item?.id}`);
		if (props.from === "manufacturer")
			if (isAndroid || isIphone)
				setMobileAppRedirect(true);
			else
				history.push(`/manufacturer/${item?.id}`);
		if (props.from === "trade")
			if (isAndroid || isIphone)
				setMobileAppRedirect(true);
			else
				history.push(`/trade/${item?.id}`);

	}

	return (
		<>

			<PromptModal
				isOpen={mobileAppRedirect}
				onClose={() => setMobileAppRedirect(false)}
				title="Open link in App"
				desc="Please choose your option."
				redButtonText="Web"
				blueButtonText="Open App"
				blueButtonTextFirst="Go Back"
				onBlueButtonClick={() => { handleMobileAppClick() }}
				onRedButtonClick={() => handleWebAppClick()} />

			<ReportModal
				isOpen={reportModalOpenSearch}
				onClose={() => { setReportModalOpenSearch(false) }}
				name={itemName}
				recordId={itemRecordId} />

			<InfiniteScroll
				dataLength={list?.length}
				next={props?.universalsearch === false ?
					async () => {
						await props.increaseOffset((pageNr) => {
							props.getData(pageNr, props.searchText)
								.then(res => {
									setList(list.concat(res));
								});
						});
					}
					: () => { }}
				hasMore={true}
				scrollableTarget="scrollList">
				<Menu
					anchorEl={moreMenuAnchorEl}
					open={Boolean(moreMenuAnchorEl)}
					onClose={(event) => {
						setMoreMenuAnchorEl(false)
					}}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					transformOrigin={{ horizontal: "right", vertical: "top" }}>
					<MenuItem
						onClick={() => handleReport()}
						style={{ color: BIRed }}
						dense>
						Report
					</MenuItem>
				</Menu>
				{list.map((item, index) => (
					<div key={index}>
						<Card style={{ marginTop: props?.universalsearch === true ? "" : "7px", borderRadius: props?.universalsearch === true ? "0px" : "4px" }}>
							<ListItem
								key={index}
								secondaryAction={
									<IconButton onClick={(event) => openMenu(event, item)}>
										<MoreIcon />
									</IconButton>
								}
								disablePadding
								onClick={(e) => handleWebAppClick(e, item)}>
								<ListItemButton>
									<ListItemText style={{ fontSize: "15px" }} primary={props?.universalsearch === true ? item?.description1 : item?.name}
										secondary={item?.address} />
								</ListItemButton>
							</ListItem>
						</Card>
					</div>
				))}
			</InfiniteScroll>
			{props?.universalsearch === true && (
				<ListSubheader>
					<Button
						color="primary"
						style={{ marginBottom: 15, marginTop: 15 }}
						variant="outlined"
						fullWidth
						onClick={() => showSeemore()}>
						Show More
					</Button>
				</ListSubheader>
			)}
		</>
	)
}

