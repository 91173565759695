import React from "react";
import { default as loaderImage } from "../assets/images/loader.svg";


/*
Reusable animated loading image
Props (* required):
	icon	- boolean; sets size of Loader to be 50px by 50px (ie. MaterialUI IconButton sized)

Usage:
	<Loader/>
	<Loader icon/>
*/
function Loader(props) {
	return (
		<img
			src={loaderImage}
			alt="loading animation"
			style={{
				display: "block",
				margin: "0 auto",
				height: (props.icon) ? "50px" : "",
				width: (props.icon) ? "50px" : ""
			}} />
	)
}

export default Loader;