import { restPost } from "./RestClient";
import { headers } from "./UserApi";

// let headers = {
// 	"Content-Type": "application/json",
// 	"Accept": "application/json",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }

// Gets role options for Experience Dropdown
export async function fetchRoles(args){	
	const res = await restPost("misc/FetchRoles/", args, headers);
	return res;
}


export async function saveRole(args){	
	const res = await restPost("misc/SaveRole/", args, headers);
	return res.data;
}