import React, { useState, useEffect } from "react";
import ResumeBuilder from "./ResumeBuilder";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  DeleteUsersResumes,
  FetchUsersResumes,
} from "../../api/ResumeBuilderApi";

import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  Button,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import Loader from "../../components/Loader";
import Placeholder from "../../components/Placeholder";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import ResumeDetailsModal from "./ResumeDetailsModal";
import UserUsages from "../../components/userUsages/UserUsages";
import { List, ListItem, ListItemButton, ListItemText, } from "@mui/material";
import ResumeBuilderTutorial from "../../components/Tutorial/ResumeBuilderTutorial";
import { showTutorialForNewUser, updateTutorialForNewUser } from "../../assets/helpers";

function SaveResumes() {
  const [showResumeBuilder, setShowResumeBuilder] = useState(false);
  const [userResumes, setUserResumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openResumeBuilderModal, setOpenResumeBuilderModal] = useState(false);
  const [selectedResumeData, setSelectedResumeData] = useState(null);
  const [tutorialIndex, setTutorialIndex] = useState(1);


  useEffect(() => {

    const fetchResumes = async () => {
      try {
        const userId = Number(localStorage.userId);
        const requestData = {
          PageNr: 1,
          NrOfRecPerPage: 100,
          FullSearch: "",
          UserId: userId,
          IncludeRecordNr: "true",
          TypeofObjectReturned: "",
          SearchList: [{ UserId: userId }],
        };

        const response = await FetchUsersResumes(requestData);
        await UserUsages('ResumeBuilder');
        setUserResumes(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching resumes:", error);
        setLoading(false);
      }
    };

    fetchResumes();

  }, []);

  const handleAddClick = () => {
    setShowResumeBuilder(true);
  };

  const handleDelete = async (resumeId) => {
    try {
      const userId = Number(localStorage.userId);
      const deleteData = {
        userId,
        ReturnRecordId: false,
        ReturnRecordError: true,
        DeleteList: [
          {
            Id: resumeId,
            ModifiedBy: userId,
          },
        ],
      };

      await DeleteUsersResumes(deleteData);

      const updatedResumes = userResumes.filter(
        (resume) => resume.id !== resumeId
      );
      setUserResumes(updatedResumes);
    } catch (error) {
      console.error("Error deleting resume:", error);
    }
  };

  const handleCardClick = (resumeId) => {
    const selected = userResumes.find((resume) => resume.id === resumeId);
    setSelectedResumeData(selected);
    setOpenResumeBuilderModal(true);
  };

  return (
    <div className="page">
      {!showResumeBuilder &&
        (
          <>
            <div className="header">
              <div className="page-content">
                <h1>Resume Builder</h1>
              </div>
            </div>

            {loading ? (
              <Loader />
            ) : (

              <div className="page-content" >
                <div className="flex-container-horizontal" style={{ margin: "10px 0px", justifyContent: 'flex-end' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddClick}
                  >
                    Add Resume
                  </Button>
                </div>
                {userResumes.length > 0 ?
                  <List sx={{ padding: 0 }}>
                    {userResumes.map((resume) => {
                      return (
                        resume !== undefined && (
                          <div style={{ marginBottom: "10px" }}>
                            <Card >
                              <ListItem
                                secondaryAction={
                                  <>
                                    <Tooltip title="Send Message" >
                                      <IconButton
                                        style={{ cursor: 'pointer' }}
                                        color="secondary"
                                        onClick={() => handleDelete(resume.id)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                }
                                disablePadding>
                                <ListItemButton
                                  onClick={() => handleCardClick(resume.id)}
                                >
                                  <ListItemText
                                    primary={resume.title}
                                    secondary=
                                    {new Date(resume.resumeDate).toLocaleDateString(
                                      undefined,
                                      {
                                        month: "short",
                                        day: "numeric",
                                      }
                                    )}
                                  />
                                </ListItemButton>
                              </ListItem>
                            </Card>
                          </div>
                        ));
                    })
                    }
                  </List>
                  : (
                    <Placeholder
                      icon={<ModeCommentIcon />}
                      text="There aren't any active Resumes right now, Please add new resume"
                    />
                  )}
              </div>
            )}
          </>
        )
      }
      {showResumeBuilder && <ResumeBuilder />}
      <ResumeDetailsModal
        Open={openResumeBuilderModal}
        Close={() => setOpenResumeBuilderModal(false)}
        resumeData={selectedResumeData}
      />
      {showTutorialForNewUser('resumeBuilder') && (
        <ResumeBuilderTutorial
          onPress={() => {
            setTutorialIndex((p) => {
              if (p >= 4) {
                if (showTutorialForNewUser('resumeBuilder')) {
                  updateTutorialForNewUser('resumeBuilder');
                }
                return 0;
              } else {
                return p + 1
              }
            });

          }}
          tutorialIndex={tutorialIndex}
        />
      )}
    </div>
  );
}

export default SaveResumes;
