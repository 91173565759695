import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AlertModal from "../../components/AlertModal";
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import ConfirmChangePasswordModal from "./ConfirmChangePasswordModal";
import CloseAccountModal from "./CloseAccountModal";
import FacebookLogo from "../../assets/images/facebook.png";
import InstagramLogo from "../../assets/images/instagram.png";
import LinkedInLogo from "../../assets/images/linkedin.png";
import TwitterLogo from "../../assets/images/twitter.png";
import BuildIdLogo from "../../assets/images/Build-ID-Transparent.png";
import SendFeedbackModal from "./SendFeedbackModal";
import UserUsages from "../../components/userUsages/UserUsages";
import { useHistory } from "react-router-dom";

function Settings() {
	const [alertModalOpen, setAlertModalOpen] = useState(false);
	const [confirmChangePasswordModal, setConfirmChangePasswordModal] = useState(false);
	const [closeAccountModalOpen, setCloseAccountModalOpen] = useState(false);
	const [sendFeedbackModalOpen, setCloseSendFeedbackModalOpen] = useState(false);
	const history = useHistory();

	useEffect(() => {
		UserUsages('MainSettings');
		// eslint-disable-next-line
	}, [])

	return (
		<>
			<AlertModal
				isOpen={alertModalOpen}
				onClose={() => setAlertModalOpen(false)}
				title="Subscription Page"
				body="Coming soon!" />

			<ConfirmChangePasswordModal
				isOpen={confirmChangePasswordModal}
				onClose={() => setConfirmChangePasswordModal(false)} />

			{/* <CloseAccountModal
				isOpen={closeAccountModalOpen}
				onClose={() => setCloseAccountModalOpen(false)} /> */}

			<SendFeedbackModal
				isOpen={sendFeedbackModalOpen}
				onClose={() => setCloseSendFeedbackModalOpen(false)}
			/>


			<div className="page">
				<div className="header">
					<div className="page-content">
						<h1>Settings</h1>
					</div>
				</div>

				<div className="page-content">

					<div className="flex-container-vertical" style={{ padding: "25px 0px", justifyContent: "space-between", alignItems: "center" }}>
						<div className="flex-container-vertical" component={Paper} style={{ width: '100%' }}>
							<List
								sx={{ width: '100%', maxWidth: 1960, bgcolor: 'background.paper' }}
								component="nav"
								aria-labelledby="nested-list-subheader"
								subheader={
									<ListSubheader component="div" id="nested-list-subheader">
										GENERAL
									</ListSubheader>
								}
							>
								<ListItemButton onClick={() => setAlertModalOpen(true)}>
									<ListItemText primary="Subscription" />
									<ListItemIcon>
										<ChevronRightIcon />
									</ListItemIcon>
								</ListItemButton>

								<ListItemButton href='https://www.iubenda.com/privacy-policy/90161377' target='_blank'>
									<ListItemText primary="Privacy Policy" />
									<ListItemIcon>
										<ChevronRightIcon />
									</ListItemIcon>
								</ListItemButton>

								<ListItemButton href='https://www.iubenda.com/terms-and-conditions/90161377' target='_blank'>
									<ListItemText primary="Terms & Conditions" />
									<ListItemIcon>
										<ChevronRightIcon />
									</ListItemIcon>
								</ListItemButton>
							</List>


							<List
								sx={{ width: '100%', maxWidth: 1960, bgcolor: 'background.paper' }}
								component="nav"
								aria-labelledby="nested-list-subheader"
								subheader={
									<ListSubheader component="div" id="nested-list-subheader">
										ACCOUNT INFO
									</ListSubheader>
								}
							>
								<ListItemButton onClick={() => setConfirmChangePasswordModal(true)}>
									<ListItemText primary="Change Password" />
									<ListItemIcon>
										<ChevronRightIcon />
									</ListItemIcon>
								</ListItemButton>

								<ListItemButton onClick={() => history.push('/closeaccount')}>
									<ListItemText primary="Close Account" />
									<ListItemIcon>
										<ChevronRightIcon />
									</ListItemIcon>
								</ListItemButton>



								<ListItemText style={{ textAlign: "center", color: "var(--buildid-blue)", cursor: "pointer" }} onClick={() => setCloseSendFeedbackModalOpen(true)}>
									SEND FEEDBACK
								</ListItemText>
							</List>

							<List sx={{ width: '100%', maxWidth: 1960, bgcolor: 'background.paper', textAlign: "center", }}>

								<div className='settingsWrapper'>
									<div className='settingsCard'>

										<a className='settings__img' rel="noreferrer" href="https://www.facebook.com/profile.php?id=100083197813565" target="_blank">
											<img src={FacebookLogo} alt="" />
										</a>

										<a className='settings__img' rel="noreferrer" href="https://www.instagram.com/mybuilderid/" target="_blank">
											<img src={InstagramLogo} alt="" />
										</a>

										<a className='settings__img' rel="noreferrer" href="https://www.linkedin.com/company/my-builder-id" target="_blank">
											<img src={LinkedInLogo} alt="" />
										</a>

										<a className='settings__img' rel="noreferrer" href="https://twitter.com/mybuilderid" target="_blank">
											<img src={TwitterLogo} alt="" />
										</a>
									</div>
								</div>
								<div className='flex-container-horizontal' style={{ justifyContent: 'center' }}>
									<div style={{ display: 'flex', flexDirection: 'column', width: '10%', objectFit: 'cover', paddingBottom: '10px' }}>
										<img src={BuildIdLogo} />
									</div>
								</div>
							</List>
						</div>
					</div>

				</div>




			</div>
		</>
	)
}

export default Settings;
