import {
  Avatar,
  Grid,
  Modal,
  Stack,
} from "@mui/material";
import {
  Save as SaveIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Divider, IconButton } from "@material-ui/core";
import defaultProjectImage from "../../../assets/images/project-profile-placeholder.jpg";
import { BIBlue } from "../../../assets/buildidColors";
import moment from "moment";
import ExperienceProjectForm from "../../../components/ExperienceProjectForm";
import { getUserExperiencesProducts } from "../../../api/ProductsApi";
import PromptModal from "../../../components/PromptModal";
import {
  deleteUserSitesProjects,
  deleteUserWorkHistories2,
  deleteUsersWorkHistory,
} from "../../../api/SitesApi";
import { useHistory } from "react-router-dom";
import { Card, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
const DetailForm = (props) => {
  const [productData, setProductData] = useState([]);
  const [showEditExperienceModal, setShowEditExperienceModal] = useState(false);
  const [showExperienceDiscardModal, setShowExperienceDiscardModal] =
    useState(false);
  const [showDeleteAlertModal, setShowDeleteAlertModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    handleFetchProducts();
  }, []);

  const handleOpenEditModal = () => {
    setShowEditExperienceModal(true);
  };

  const handleCloseEditModal = () => {
    setShowExperienceDiscardModal(true);
  };

  async function handleFetchProducts() {
    try {
      const projectDataObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: "true",
        TypeOfObjectReturned: "",
        SearchList: [
          {
            userExperienceId: props?.project?.id,
          },
        ],
      };
      const productListRes = await getUserExperiencesProducts(projectDataObj);
      setProductData(productListRes?.data ?? []);
    } catch (error) {
      console.log("error while getting product list :: ", error);
    }
  }

  const onExperienceModalClose = () => {
    setShowExperienceDiscardModal(false);
    setShowEditExperienceModal(false);
  };

  const onExperienceSave = () => {
    onExperienceModalClose();
    props?.getUpdatedData();
    props?.Close();
  };

  const handleDeleteButtonClick = async () => {
    try {
      setShowDeleteAlertModal(false);
      // let array = [
      //   {
      //     Id: props?.project?.id ?? 0,
      //     ModifiedBy: localStorage?.userId,
      //   },
      // ];
      // let deleteObj = {
      //   UserId: localStorage?.userId,
      //   ReturnRecordId: false,
      //   ReturnRecordError: true,
      //   DeleteList: [
      //     {
      //       Id: props?.project?.id ?? 0,
      //       ModifiedBy: localStorage?.userId,
      //     },
      //   ],
      // };
      // await deleteUserWorkHistories2(array);
      // await deleteUserSitesProjects(deleteObj);
      let deleteObj = {
        UserId: Number(localStorage?.userId),
        ReturnRecordId: false,
        ReturnRecordError: true,
        DeleteList: [{
          Id: props?.project?.id ?? 0,
          ModifiedBy: Number(localStorage?.userId),
        }]
      }
      await deleteUsersWorkHistory(deleteObj);
      props?.onExperienceDelete();
    } catch (error) {
      props?.onExperienceDelete();
      console.log("Error while deleting experience :: ", error);
    }
  };

  const navigateToProjectDetailProfile = (project) => {
    history.push(
      props?.siteData
        ? "/projects/project/" + project?.id
        : "/projects/project/" + project?.projectId,
      { projectData: project, from: "vieweExperience" }
    );
  };

  const navigateToCompanyDetailProfile = (project) => {
    return project?.companyId
      ? history.push("/company/" + project?.companyId ?? 0)
      : null;
  };

  // 20-03-2024 chenges Yash
  const renderDescription = (description) => {
    if (!description) return null;

    const maxCharactersPerLine = 50;
    const lines = [];
    let currentLine = "";

    for (let i = 0; i < description.length; i++) {
      currentLine += description[i];
      if (
        currentLine.length === maxCharactersPerLine ||
        i === description.length - 1
      ) {
        lines.push(currentLine);
        currentLine = "";
      }
    }

    return lines.map((line, index) => (
      <h4
        key={index}
        style={{
          fontSize: "1rem",
          fontWeight: "400",
          marginBottom: "0px",
        }}>
        {line}
      </h4>
    ));
  };
  // 20-03-2024 chenges Yash

  return (
    <>
      {!showEditExperienceModal ? (
        <>
          <Modal disableEnforceFocus={true} open={props?.show} onClose={() => props?.Close()}>
            <div className="modal-center">
              <div className="modal-card" style={{ width: "800px" }}>
                <div className="modal-card-body">
                  <div className="flex-container-vertical">
                    <div
                      className="flex-container-horizontal"
                      style={{ justifyContent: "space-between", maxHeight: "500px" }}>
                      <div>
                        <IconButton color="primary" onClick={props?.Close}>
                          <CloseIcon />
                        </IconButton>
                      </div>

                      {props?.from !== "contactProfile" && (
                        <div>
                          <IconButton
                            color="primary"
                            onClick={handleOpenEditModal}>
                            <EditIcon />
                          </IconButton>

                          <IconButton
                            color="secondary"
                            onClick={() => {
                              setShowDeleteAlertModal(true);
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      )}
                    </div>
                    <h2>View Experience</h2>
                    <div style={{ height: "500px", overflowY: "auto" }} className="experience-form">
                      <div className="flex-container-vertical" style={{ flex: 1, padding: "0px 10px" }} >
                        <Grid container>
                          <Grid item md={12} xs={12}>
                            <span
                              style={{
                                fontSize: "0.900rem",
                                color: "#8D8D8D",
                              }}>
                              Site/Project
                            </span>
                          </Grid>
                          <Grid item md={12} xs={12} sx={{ display: "flex" }}>
                            <ListItem sx={{ padding: 0 }} disablePadding>
                              <ListItemButton
                                onClick={() => {
                                  navigateToProjectDetailProfile(props.project);
                                }}>
                                <Avatar
                                  // onClick={() => setPictureViewerOpen(true)}
                                  variant="square"
                                  style={{
                                    marginRight: "15px",
                                    cursor: "pointer",
                                    width: "80px",
                                    height: "55px",
                                    borderRadius: "5px",
                                  }}
                                  src={
                                    props.project.mainPictureUrl
                                      ? props.project.mainPictureUrl
                                      : defaultProjectImage
                                  }></Avatar>
                                <ListItemText
                                  primary={props?.project?.projectName}
                                  secondary={
                                    <>
                                      {props?.project?.siteName ? (
                                        <span>
                                          {" "}
                                          {props?.project?.siteName} <br />{" "}
                                        </span>
                                      ) : null}
                                      {props?.project?.address ? (
                                        <span> {props?.project?.address} </span>
                                      ) : null}
                                    </>
                                  }
                                />
                              </ListItemButton>
                            </ListItem>
                          </Grid>
                        </Grid>
                        <Divider
                          style={{
                            margin: "1rem 0px",
                            height: "0.9px",
                            backgroundColor: "#cccccc73",
                          }}></Divider>
                        <Grid container>
                          <Grid item md={12} xs={12}>
                            <span
                              style={{ fontSize: "0.900rem", color: "#8D8D8D" }}>
                              Company
                            </span>
                            <h5
                              style={{
                                fontSize: "1rem",
                                fontWeight: "400",
                                marginBottom: "10px",
                                color: `${BIBlue}`,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                navigateToCompanyDetailProfile(props?.project);
                              }}>
                              <b>{props?.project?.companyName != "" ? props?.project?.companyName : ""}</b>
                            </h5>
                          </Grid>
                          {props?.project?.roleName != "" || props?.project?.tradeName != "" ?
                            <Grid item md={12} xs={12} style={{ display: 'flex', flexDirection: 'row' }}>

                              {props?.project?.roleName != "" ?
                                <Grid item sm={6} md={6}>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "0.900rem",
                                        color: "#8D8D8D",
                                      }}>
                                      Title/Role
                                    </span>
                                    <h4
                                      style={{
                                        fontSize: "1rem",
                                        fontWeight: "400",
                                        marginBottom: "0px",
                                      }}>
                                      {props?.project?.roleName}
                                    </h4>
                                  </div>
                                </Grid> : null}
                              {props?.project?.tradeName != "" ?
                                <Grid item sm={6} md={6}>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "0.900rem",
                                        color: "#8D8D8D",
                                      }}>
                                      Trade
                                    </span>
                                    <h4
                                      style={{
                                        fontSize: "1rem",
                                        fontWeight: "400",
                                        marginBottom: "0px",
                                      }}>
                                      {props?.project?.tradeName}
                                    </h4>
                                  </div>
                                </Grid> : null}
                            </Grid> : null}

                          {props?.project?.startDate === null || props?.project?.startDate == "Invalid date" || props?.project?.endDate === null || props?.project?.endDate == "Invalid date" ? null :
                            <Grid item md={12} xs={12} style={{ display: 'flex', flexDirection: 'row' }}>

                              {props?.project?.startDate === null || props?.project?.startDate == "Invalid date" ? null :
                                <Grid item sm={6} md={6} xs={6} style={{ paddingTop: '5px' }}>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "0.900rem",
                                        color: "#8D8D8D",
                                      }}>
                                      Start Date
                                    </span>
                                    <h4
                                      style={{
                                        fontSize: "1rem",
                                        fontWeight: "400",
                                        marginBottom: "0px",
                                      }}>
                                      {moment(props?.project?.startDate).format(
                                        "MMM YYYY"
                                      )}
                                    </h4>
                                  </div>
                                </Grid>
                              }
                              {props?.project?.endDate === null || props?.project?.endDate == "Invalid date" ? null :
                                <Grid item sm={6} md={6} xs={6} style={{ paddingTop: '5px' }}>
                                  <div>
                                    <span
                                      style={{
                                        fontSize: "0.900rem",
                                        color: "#8D8D8D",
                                      }}>
                                      End Date
                                    </span>
                                    <h4
                                      style={{
                                        fontSize: "1rem",
                                        fontWeight: "400",
                                        marginBottom: "0px",
                                      }}>
                                      {props?.project?.present ? "Present" : moment(props?.project?.endDate).format("MMM YYYY")}
                                    </h4>
                                  </div>
                                </Grid>}
                            </Grid>}
                          {props?.project?.description != "" ?
                            <Grid item sm={12} md={12} style={{ paddingTop: '5px' }}>
                              <div>
                                <span
                                  style={{
                                    fontSize: "0.900rem",
                                    color: "#8D8D8D",
                                  }}>
                                  Description
                                </span>
                                <h4
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    marginBottom: "0px",
                                  }}>
                                  {renderDescription(props?.project?.description)}
                                </h4>
                              </div>
                            </Grid> : null}
                        </Grid>
                        <Divider
                          style={{
                            margin: "1rem 0px",
                            height: "0.9px",
                            backgroundColor: "#cccccc73",
                          }}></Divider>

                        <Grid container>
                          <Grid item sm={6} md={12}>
                            <div>
                              <span
                                style={{
                                  fontSize: "0.900rem",
                                  color: "#8D8D8D",
                                }}>
                                Products
                              </span>
                              {productData.length === 0 ? (
                                <div style={{ textAlign: "center" }}>
                                  <span
                                    style={{
                                      fontSize: "0.900rem",
                                      color: "#8D8D8D",
                                    }}>
                                    No Products
                                  </span>
                                </div>
                              ) : (
                                <>
                                  <List sx={{ padding: 0 }}>
                                    {productData?.slice(0, 20)?.map((item, index) => (
                                      <div key={index}>
                                        <ListItem
                                          key={index}
                                          disablePadding>
                                          <ListItemButton
                                            onClick={() => history.push(`/product/${item?.productId}`)}
                                          >
                                            <ListItemText
                                              primary={item.productName}
                                              secondary={item.manufacturerName}
                                            />
                                          </ListItemButton>
                                        </ListItem>
                                      </div>
                                    ))}
                                  </List>
                                </>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <>
          <Modal open={showEditExperienceModal} onClose={handleCloseEditModal}>
            <div className="modal-center">
              <div className="modal-card" style={{ width: "800px" }}>
                <div className="modal-card-body">
                  <ExperienceProjectForm
                    details={props?.project ?? {}}
                    from={"experience"}
                    onClose={onExperienceModalClose}
                    showExperienceDiscardModal={showExperienceDiscardModal}
                    setShowExperienceDiscardModal={(val) => {
                      setShowExperienceDiscardModal(val);
                    }}
                    onDataSave={onExperienceSave}
                    isFollowing={false}
                    onExperienceDelete={() => {
                      props?.onExperienceDelete();
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </>
      )
      }

      {
        showDeleteAlertModal && (
          <PromptModal
            isOpen={showDeleteAlertModal}
            onClose={() => setShowDeleteAlertModal(false)}
            title="Delete Experience"
            desc="Are you sure you want to delete this Experience from your Profile?"
            redButtonText="Yes"
            blueButtonTextFirst="No"
            onRedButtonClick={handleDeleteButtonClick}
          />
        )
      }
    </>
  );
};

export default DetailForm;
