import React, { useCallback, useState } from "react";
import Dialog from "@mui/material/Dialog";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from "@mui/icons-material/Close";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import {
  CardActions,
  IconButton,
  Card,
  Avatar,
  TextField,
  InputAdornment,
  Input,
} from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import { getPostComments, savePostComment } from "../../api/PostsApi";
import AlertModal from "../AlertModal";
import { useEffect } from "react";
import PostComments from "../post/PostComments";

const CustomImageViewer = (props) => {
  const image = props?.images[props?.selectedImageIndex] || {};
  const [noOfComments, setNoOfComments] = useState(image?.nrComments ?? 0);
  const [toggleComment, setToggleComment] = useState(false);

  const [userComment, setUserComment] = useState("");
  const [picUrl, setPicUrl] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inappropriateImageAlert, setInappropriateImageAlert] = useState(false);
  const [comments, setComments] = useState([]);

  const updateComments = useCallback(() => {
    let pageNr = 1;
    let nrOfRecPerPage = 10;

    getPostComments(image?.id, pageNr, nrOfRecPerPage, image?.appId)
      .then(res => {
        if (res) {
          // Reverse comments so that they are displayed in oldest to newest
          let reverse = Array.from(res).reverse();
          setComments(reverse || 0)
        }
      })
  }, [props])
  useEffect(() => {
    if (image) {
      setNoOfComments(image?.nrComments ?? 0);
    }
  }, [image]);

  const handleNextImage = () => {
    if (props.selectedImageIndex < props.images.length - 1) {
      props.setSelectedImageIndex(props.selectedImageIndex + 1);
    }
  };

  const handlePreviousImage = () => {
    if (props.selectedImageIndex > 0) {
      props.setSelectedImageIndex(props.selectedImageIndex - 1);
    }
  };

  async function handleCommentPost() {
    try {
      if (picUrl?.length > 0) {
        if (!picUrl?.[0]?.type.startsWith("image/")) {
          return alert("Please select image only.");
        }
      }
      setLoading(true);
      let data = new FormData();
      data.append("Id", "0");
      data.append("PostId", image?.id);
      data.append("UserId", localStorage.userId);
      data.append("Comment", userComment);
      data.append("ModifiedBy", localStorage.userId);
      data.append("AppId", image?.appId);
      picUrl?.length > 0 && data.append("Pictures", picUrl?.[0]);

      await savePostComment(data)
        .then(async (res) => {
          setLoading(false);
          setNoOfComments((preNo) => preNo + 1);
          props?.getUpdatedPicList()
        })
        .catch((err) => {
          if (err === "Content Inappropriate") {
            setInappropriateImageAlert(true);
          }
          setLoading(false);
          console.log("Error while saving the comment:-", err);
        });
      // Reset input states
      setToggleComment(false);
      setPicUrl([]);
      setUserComment("");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Error while saving the comment:-", err);
    }
  }
  let handleCommentToggler = () => {
    setToggleComment(!toggleComment)
    updateComments()
  }
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={false}
      maxWidth="md"
    >
      <div
        style={{
          display: "flex",
          backgroundColor: "rgba(0,0,0,0.1)",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "480px",
          width: "800px",
          position: "relative",
        }}
      >
        <img
          src={image.pictureUrl}
          alt={`Image ${props.selectedImageIndex}`}
          style={{ maxHeight: "90%", maxWidth: "90%", objectFit: "contain" }}
        />

        <div className="picture-photo-buttons">
          {image.isFavorite ? (
            <StarIcon
              style={{
                color: "gold",
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: 1,
                cursor: 'pointer'
              }}
              onClick={() => props.handleDeleteImage(image)}
            />
          ) : (
            <StarIcon
              className="picture-photo-star"
              style={{
                color: "#000",
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: 1,
                cursor: 'pointer'
              }}
              onClick={() => props.handleSaveImage(image)}
            />
          )}

          <div className="picture-photo-container">
            <IconButton
              size="small"
              onClick={props.onClose}
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                zIndex: 1,
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ padding: "15px" }}>
          <p>
            {`${image.postedByFirstName} ${image.postedByLastName} (${image.tradeName})`}
          </p>
          <p>{image.message != "<<<picture>>>" ? image.message : ""}</p>
          <p>
            Photo taken:{" "}
            {image.pictureDateTime != null ? image.pictureDateTime : "N/A"}
          </p>
        </div>
        <div>
          <CardActions disableSpacing style={{ justifyContent: "flex-end" }}>
            <p
              className="greytext link"
              onClick={() => {
                setUserComment("");
                setPicUrl([]);
                handleCommentToggler()
              }}
            >
              {noOfComments == 0
                ? "comment"
                : noOfComments == 1
                  ? `${noOfComments} comment`
                  : noOfComments > 1
                    ? `${noOfComments} comments`
                    : null}
            </p>
          </CardActions>
        </div>
      </div>
      {
        props?.selectedImageIndex !== 0 &&
        <SkipPreviousIcon
          onClick={handlePreviousImage}
          disabled={props.selectedImageIndex === 0}
          className="picture-photo-SkipPreviousIcon"
        />
      }

      {
        props?.selectedImageIndex !== props?.images?.length - 1 &&
        <SkipNextIcon
          onClick={handleNextImage}
          disabled={props.selectedImageIndex === props.images.length - 1}
          className="picture-photo-SkipNextIcon"
        />
      }


      {toggleComment && (
        <div>
          {comments.map((comment, index) => (
            <React.Fragment key={index}>
              <PostComments comment={comment} commentUserPic={comment.userPictureUrl} />
            </React.Fragment>
          ))}

          <Card className="newsfeed-comment-card" style={{ marginBottom: "0px" }}>
            <div
              className="flex-container-horizontal"
              style={{ alignItems: "center", padding: "0px 16px" }}
            >
              <Avatar alt="Profile Picture" src={localStorage.pictureUrl} />

              {/* comment writing body description */}
              <div className="input-container" style={{ flex: 1 }}>
                <TextField
                  variant="outlined"
                  disabled={loading}
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  fullWidth
                  value={userComment}
                  onChange={(event) => setUserComment(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment value={userComment} position="end">
                        <label htmlFor={"demo"}>
                          <Input
                            accept="image/*"
                            id={"demo"}
                            type="file"
                            onChange={(event) => {
                              setPicUrl([event.target.files[0]]);
                              event.target.value = null;
                            }}
                            style={{
                              display:
                                "none" /* Hide underlying HTML component */,
                            }}
                            disabled={loading}
                          />
                          <IconButton
                            color={picUrl.length !== 0 ? "secondary" : "primary"}
                            aria-label="upload picture"
                            component="span"
                          >
                            <InsertPhotoIcon />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Comment"
                />
              </div>

              {!loading ? (
                <IconButton
                  color="primary"
                  disabled={
                    userComment?.trim() != "" || picUrl?.length != 0
                      ? false
                      : true
                  }
                  onClick={() => {
                    handleCommentPost();
                  }}
                >
                  <SendIcon />
                </IconButton>
              ) : (
                <CircularProgress size={24} />
              )}
            </div>

            {/* Uploaded image preview */}
            <div style={{ paddingBottom: "10px" }}>
              {Array.isArray(picUrl) &&
                picUrl.map((pic) => (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      className="picture-container"
                      style={{
                        margin: "0px 16px",
                        height: "350px",
                        display: "flex",
                      }}
                    >
                      <div className="picture-container-button">
                        <IconButton size="small" onClick={() => setPicUrl([])}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                      <img
                        src={URL.createObjectURL(pic)}
                        alt={pic.name}
                        width="100%"
                      />
                    </div>
                  </div>
                ))}
            </div>
          </Card>
        </div>
      )}
      <AlertModal
        isOpen={inappropriateImageAlert}
        onClose={() => setInappropriateImageAlert(false)}
        title={"Content Rejected"}
        body={
          "The text picture you entered has been automatically identified as inappropriate."
        }
        inappropriateImage
        text1={"Please remove the content and try again."}
      />
    </Dialog>
  );
};

export default CustomImageViewer;
