import React, { useEffect, useRef, useState } from "react";
import mapMarkerYellow from "../../assets/images/map-marker-yellow.png";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import { GoogleMap, Marker } from "@react-google-maps/api";
import NearMeIcon from "@mui/icons-material/NearMe";
import { SaveSites, getAddress } from "../../api/SitesApi";
import TabOptions from "./TabOptions";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import { BIBlue } from "../../assets/buildidColors";
import Loader from "../../components/Loader";

export default function Chooselocation(props) {
  /* Mahindra Code 28-mar-2024 */
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(false);
  const [mapTypeId, setMapTypeId] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const containerStyle = {
    width: "100%",
    height: "500px",
  };
  const mapRef = useRef(null);
  const [location, setLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const [showCurrentDropMarker, setShowCurrentDropMarker] = useState(false);
  const [disableLocation, setDisableLocation] = useState(true);
  const [map, setMap] = useState();
  useEffect(() => {
    console.log("props?.from--", props?.from);
    if (props?.changeLocation && !props?.addNewSiteLocation) {
      setLocation({
        lat: props?.chooseLocation?.lat,
        lng: props?.chooseLocation?.lng,
      });
    } else if (props?.changeLocation && props?.addNewSiteLocation) {
      setLocation({
        lat: props?.currentLocation?.lat,
        lng: props?.currentLocation?.lng,
      });
    } else if (props?.noLocationSiteModal) {
      setLocation({
        lat: props?.currentLocation?.lat,
        lng: props?.currentLocation?.lng,
      });
    } else {
      getLocation();
    }
  }, []);

  const createCircleIcon = (color, radius) => ({
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor: color,
    fillOpacity: 0.4,
    strokeColor: "Blue",
    strokeOpacity: 20,
    strokeWeight: 40,
    scale: radius,
  });

  const closeLocation = () => {
    props.setShowchooselocation(false);
  };

  const chooseLocation = async () => {
    props.setChooselocation({
      lat: Number(location?.lat),
      lng: Number(location?.lng),
    });
    props?.setAddNewSiteLocation(false);

    if (props?.siteInfo) {
      try {
        props.fromSiteCard && props?.setLoading(true);
        setLoading(true);
        const getAddRes = await getAddress({
          latitude: Number(location?.lat),
          longitude: Number(location?.lng),
        });
        if (getAddRes?.message === "") {
          const obj = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                Id: props?.siteInfo?.id,
                Name: props?.siteInfo?.name,
                Address:
                  getAddRes?.objAddress?.address === ""
                    ? getAddRes?.objAddress?.fullAddress
                    : getAddRes?.objAddress?.address,
                City: getAddRes?.objAddress?.city,
                Province: getAddRes?.objAddress?.province,
                PostalCode: getAddRes?.objAddress?.postalCode,
                Country: getAddRes?.objAddress?.country,
                Latitude: getAddRes?.objAddress?.latitude,
                Longitude: getAddRes?.objAddress?.longitude,
                StartDate: props?.siteInfo?.startDate,
                EndDate: props?.siteInfo?.endDate,
                Active: true,
                RecordStatusId: 1,
                ContractorId: 0,
                JobNo: "",
                ConstructionStatusId: props?.siteInfo?.constructionStatusId,
                ModifiedBy: Number(localStorage.userId),
              },
            ],
          };
          const saveSiteRes = await SaveSites(obj);

          console.log("props?.from----", props?.from);
          if (saveSiteRes.message === "OK") {
            if (
              (props.fromSiteCard && props?.from == "experience") ||
              (props.fromSiteCard && props?.from == "quiz")
            ) {
              console.log("saveSiteRes--If ");
              await props?.setLoading(false);
              await props?.getNoLocationSiteList();
              await props?.getSiteInfo();
              setLoading(false);
            } else {
              console.log("saveSiteRes--else ");
              await props?.setLoading(false);
              await props?.handleNoLocationModalClose();
              await props?.getNoLocationSiteList();
              await props?.getSiteInfo();
              setLoading(false);
              await props?.onClose();
            }
          }
        }
      } catch (err) {
        console.log("Save Site Error : ", err);
        props.fromSiteCard && props?.setLoading(false);
        setLoading(false);
      }
    }
    closeLocation();
  };

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve(currentLocation(position)),
          showError
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  const currentLocation = (position) => {
    var lat = position?.coords?.latitude;
    var lng = position?.coords?.longitude;
    return {
      lat: lat,
      lng: lng,
    };
  };

  const getLocation = async () => {
    setLocation({
      lat: props?.currentLocation?.lat,
      lng: props?.currentLocation?.lng,
    });
    setShowCurrentDropMarker(true);
    setSearchTerm("");
  };

  const getNearLocation = async () => {
    setLocation({
      lat: props?.currentLocation?.lat,
      lng: props?.currentLocation?.lng,
    });
    setShowCurrentDropMarker(true);
    setSearchTerm("");
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("Please allow location from your browser");
        getCurrentLocation();
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
      default:
        break;
    }
  };

  /* Mahindra Code 28-mar-2024 */
  const handleSearch = (place) => {
    let lat = Number(place?.geometry?.location?.lat());
    let lng = Number(place?.geometry?.location?.lng());
    setLocation({ lat, lng });
    map.fitBounds(place.geometry.viewport);
    setShowCurrentDropMarker(false);
  };
  function handleDefaultSite() {
    setMoreMenuAnchorEl(false);
    setMapTypeId(0);
  }
  function handleHybridSite() {
    setMoreMenuAnchorEl(false);
    setMapTypeId(1);
  }
  function handleSatelliteSite() {
    setMoreMenuAnchorEl(false);
    setMapTypeId(2);
  }
  function handleTerrainSite() {
    setMoreMenuAnchorEl(false);
    setMapTypeId(3);
  }

  const handleMapDrag = () => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter(); // Get the center of the map
      const lat = center.lat();
      const lng = center.lng();
      setLocation({
        lat: Number(lat),
        lng: Number(lng),
      });
    }
    setShowCurrentDropMarker(false);
  };

  return (
    <Modal
      className="choose-opt choose-location"
      open={props.showChooselocation}
    >
      <div className="modal-center" style={{ outline: "none" }}>
        {loading ? (
          <Loader />
        ) : (
          <div className="modal-card chooseopt-card location-card">
            <div className="header-section">
              <Typography
                className="addsite-title"
                style={{ fontSize: "16px" }}
                variant="h6"
                component="h2"
              >
                Choose Location
              </Typography>
              <IconButton sx={{ color: "#083DB8" }} onClick={closeLocation}>
                <CloseIcon />
              </IconButton>
            </div>
            <GoogleMap
              mapContainerClassName="site-map-container"
              mapContainerStyle={containerStyle}
              center={location}
              onDragEnd={() => {
                handleMapDrag();
                setDisableLocation(false);
              }}
              onZoomChanged={handleMapDrag}
              zoom={showCurrentDropMarker ? 0 : 13}
              onLoad={(map) => {
                mapRef.current = map;
                setMap(map);
              }}
              options={{
                fullscreenControl: false,
                zoomControl: false,
                mapTypeControl: false,
              }}
              ref={mapRef} // Assign the reference to the map
              mapTypeId={
                mapTypeId == 0
                  ? "roadmap"
                  : mapTypeId == 1
                  ? "hybrid"
                  : mapTypeId == 2
                  ? "satellite"
                  : mapTypeId == 3
                  ? "terrain"
                  : ""
              }
            >
              <img
                className="mapIcon"
                src={mapMarkerYellow}
                alt="Contact profile pic"
                style={{ height: "35px" }}
              />
              {/* Mahindra Code 28-mar-2024 */}

              <Marker
                position={{
                  lat: props?.currentLocation?.lat
                    ? props?.currentLocation?.lat
                    : location?.lat,
                  lng: props?.currentLocation?.lng
                    ? props?.currentLocation?.lng
                    : location?.lng,
                }}
                icon={createCircleIcon("#4285F4", 5)}
              />

              <TabOptions
                handleSearch={handleSearch}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                from={"changeSiteOnMap"}
              />
              <IconButton
                className="add-iconbtn2"
                color="primary"
                onClick={(event) => {
                  setMoreMenuAnchorEl(event.currentTarget);
                }}
              >
                <FilterNoneIcon fontSize="small" />
              </IconButton>
              <Menu
                anchorEl={moreMenuAnchorEl}
                open={Boolean(moreMenuAnchorEl)}
                onClose={(event) => {
                  setMoreMenuAnchorEl(false);
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => {
                    handleDefaultSite();
                  }}
                  style={{ color: BIBlue, width: "110px" }}
                >
                  Default
                </MenuItem>

                <MenuItem
                  onClick={() => handleHybridSite()}
                  style={{ color: BIBlue }}
                >
                  Hybrid
                </MenuItem>

                <MenuItem
                  onClick={() => handleSatelliteSite()}
                  style={{ color: BIBlue }}
                >
                  Satellite
                </MenuItem>
                <MenuItem
                  onClick={() => handleTerrainSite()}
                  style={{ color: BIBlue }}
                >
                  Terrain
                </MenuItem>
              </Menu>
            </GoogleMap>

            <div>
              <Button
                style={{ color: disableLocation ? "gray" : "#083DB8" }}
                disabled={disableLocation}
                variant="text"
                fullWidth
                onClick={() => {
                  getNearLocation();
                  setDisableLocation(true);
                }}
              >
                <NearMeIcon
                  fontSize="small"
                  style={{ color: disableLocation ? "gray" : "#083DB8" }}
                />{" "}
                Use Current location
              </Button>
              <Button
                style={{ backgroundColor: "#083DB8" }}
                variant="contained"
                fullWidth
                onClick={chooseLocation}
              >
                Choose Location
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
