import React, { useState } from "react";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import {
	Menu, MenuItem,
	Typography
} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CardContent from '@mui/material/CardContent';
import { BIRed } from "../../assets/buildidColors";
import Rating from '@mui/material/Rating';
import ReportModal from "../ReportModal";

const Review = (props) => {

	const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
	const [reportModalOpen, setReportModalOpen] = useState(false);


	return (
		<Card>
			<CardHeader
				avatar={
					<Avatar
						alt="Profile Picture"
						src={props.review.createdByPictureUrl
							? props.review.createdByPictureUrl
							: ""} />
				}
				title={props.review.createdByUser}
				subheader={props.review?.tradeName}
				action={
					<div>
						<IconButton
							aria-label="settings"
							onClick={event => setMoreMenuAnchorEl(event.currentTarget)}>
							<MoreVertIcon />
						</IconButton>
						<Menu
							anchorEl={moreMenuAnchorEl}
							open={Boolean(moreMenuAnchorEl)}
							onClose={() => setMoreMenuAnchorEl(null)}
							anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
							transformOrigin={{ horizontal: "right", vertical: "top" }}>
							<MenuItem
								onClick={() => {
									setReportModalOpen(true)
									setMoreMenuAnchorEl(null)
								}}
								style={{ color: BIRed }}
								dense>
								Report
							</MenuItem>
						</Menu>
					</div>
				}
			/>

			{/* card body description */}
			<CardContent className="cardBody"
				style={{ paddingTop: "0px", paddingBottom: "16px" }}>
				<Rating name="read-only" value={props.review.rating} readOnly />
				{
					(props.review.comments)
						? (
							<div style={{ paddingTop: "8px" }}>
								<Typography>{props.review.comments}</Typography>
							</div>
						)
						: <></>
				}
			</CardContent>
			<ReportModal
				isOpen={reportModalOpen}
				onClose={() => setReportModalOpen(false)}
				name={"Review"}
				recordId={props?.review?.id}
			/>
		</Card>

	)
}

export default Review;