import React, { useEffect, useState } from 'react'
import { Stack } from '@mui/material';
import { Chip, Typography } from '@material-ui/core';
import { BIGreen, BIGrey, BILightGreen, BILightRed, BILightYellow, BIRed, BIWhite } from "../../../assets/buildidColors";
import { useHistory } from 'react-router-dom';
import { fetchClientexpenses } from '../../../api/ClientApi';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import Addexpenses from './Addexpenses';
import Loader from '../../../components/Loader';
import "../../../assets/css/ClientHome.css"
import { ClientHeader } from '../ClientHeader';

const ClientExpenses = () => {

    const history = useHistory();
    const [expenseslist, setExpenseslist] = useState([]);
    const [showAdd, setShowadd] = useState(false);
    const [loader, setLoader] = useState(false);
    const [clientSearchTerm, setClientSearchTerm] = useState("");

    useEffect(async () => {
        await fetchExpensesdata();
    }, []);


    const fetchExpensesdata = async () => {
        setLoader(true)
        let obj = {
            PageNr: 1,
            NrOfRecPerPage: 20,
            FullSearch: "",
            UserId: Number(localStorage?.userId),
            IncludeRecordNr: true,
            TypeOfObjectReturned: "",
            FetchAllowedRecordsOnly: false,
            SearchList: [{ CreatedBy: Number(localStorage?.userId) }],
            SortList: [
                {
                    FieldName: "Id",
                    Direction: "DESC"
                }
            ]
        }
        let getExpenses = await fetchClientexpenses(obj);
        setExpenseslist(getExpenses.data);
        setLoader(false)
    }


    const returnCreateddate = (getDate) => {
        return moment(getDate).format("MMM D YYYY");
    }


    const navigateTopage = () => {
        setShowadd(true);
    }
    if (loader) {
        return <div className="page">
            <ClientHeader
                screenName={"Expenses"}
                clientSearchTerm={clientSearchTerm}
                setClientSearchTerm={setClientSearchTerm}
            />
            <Loader />
        </div>
    }

    return (
        <>
            <ClientHeader
                screenName={"Expenses"}
                clientSearchTerm={clientSearchTerm}
                setClientSearchTerm={setClientSearchTerm}
            />
            {/* <div className="page rewards-page">
                <div className="header">
                    <div className="page-content content-heading">
                        <h1 className="expenses-heading">Expenses</h1>
                        <div className="add-content" onClick={navigateTopage}>
                            <AddIcon className="add-icon" />
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="page">
                <div className="page-content" style={{ marginTop: '20px' }}>
                    {expenseslist?.length > 0 ?
                        (
                            <>
                                <Stack>
                                    {expenseslist?.map((item, index) => (
                                        <div key={index} style={{ backgroundColor: BIWhite, margin: '10px 0px', padding: '20px', borderRadius: '20px', cursor: 'pointer' }}
                                            onClick={() => {
                                                history.push(`/client-expenses-details/${item?.id}`)
                                            }}
                                        >
                                            <Stack display={'flex'} justifyContent={"space-between"} direction={'row'}>
                                                <Stack>
                                                    <Typography style={{ color: BIGrey, fontSize: '14px' }}>{returnCreateddate(item?.dateSubmitted)}</Typography>
                                                    <Typography style={{ marginTop: '4px' }}>{item?.title}</Typography>
                                                    <Typography style={{ color: BIGrey }}>{item?.description}</Typography>
                                                    <div style={{ width: 'fit-content', marginTop: '2px' }}>
                                                        <Chip
                                                            label={item?.status}
                                                            style={item?.status === "InReview" ? { backgroundColor: BILightYellow, color: BIGrey } :
                                                                item?.status === "Rejected" ? { backgroundColor: BILightRed, color: BIRed } :
                                                                    { backgroundColor: BILightGreen, color: BIGreen }
                                                            }
                                                        />
                                                    </div>
                                                </Stack>
                                                <Stack display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    <Typography style={{ fontWeight: '600' }}>{item?.total}</Typography>
                                                </Stack>
                                            </Stack>
                                        </div>
                                    ))
                                    }
                                </Stack>
                            </>
                        )
                        :
                        (
                            <>
                                <Stack display={'flex'} justifyContent={'center'} alignItems={'center'}
                                    style={{ marginTop: "20%" }}
                                >
                                    <Typography style={{ color: BIGrey, fontSize: '18px' }}>
                                        You have not submitted any Expenses yet.
                                    </Typography>
                                </Stack>
                            </>
                        )
                    }
                </div>
            </div>

            <div className="fixed-button-container">
                <button className="fixed-button" style={{ background: localStorage?.clientAccentColor }} onClick={navigateTopage}>
                    <AddIcon />
                </button>
            </div>

            {
                showAdd && (
                    <Addexpenses showAdd={showAdd} setShowadd={setShowadd} />
                )
            }

        </>

    )
}


export default ClientExpenses