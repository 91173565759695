import React, { useState } from "react";
import ErrorIcon from '@mui/icons-material/Error';
import { IconButton, FormControlLabel, Checkbox } from "@material-ui/core";
import {
    ExpandMore as ExpandIcon,
    ExpandLess as CollapseIcon
} from "@material-ui/icons";
import AlertModal from "../../../components/AlertModal";
import moment from "moment";


function TicketCard(props) {
    const [isExpanded, setIsExpanded] = useState(true);

    return (
        <>
            <div className="card" style={{ maxHeight: "100%" }}>
                <div className="flex-container-horizontal">
                    <div className="flex-container-horizontal" style={{ flex: 1 }}>
                        <span style={{
                            display: 'flex', justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <h3>
                                Tickets
                            </h3></span>
                    </div>
                    <div>
                        {	// Show appropriate expand/collapse icon
                            isExpanded
                                ? (
                                    <IconButton
                                        color="primary"
                                        onClick={() => setIsExpanded(false)}>
                                        <CollapseIcon />
                                    </IconButton>
                                )
                                : (
                                    <IconButton
                                        color="primary"
                                        onClick={() => setIsExpanded(true)}>
                                        <ExpandIcon />
                                    </IconButton>
                                )
                        }
                    </div>
                </div>
                <div>
                    {isExpanded
                        ? (
                            <>
                                {props.tickets.length > 0 ? (
                                    props.tickets.map((value, id) => (
                                        <TicketList
                                            key={id}
                                            ticketType={value.ticketType}
                                            ticket={value}
                                            issuedBy={value.issuedBy}
                                            handleToggle={() => props.handleToggle(value.id, "TicketCard")}
                                        />
                                    ))
                                ) : (
                                    <p>No data available for tickets</p>
                                )}
                            </>
                        )
                        : <></>
                    }
                </div>
            </div>
        </>
    )
}

export default TicketCard;

export function TicketList(props) {
    const [expirTicketAlertModalOpen, setExpireTicketAlertModalOpen] = useState(false);
    // const isExpired = new Date(props.ticket.expiry) < new Date();
    const current = moment().format('YYYY-MM-DDThh:mm:ss');
    const isExpired = props?.ticket?.expiry <= current && props?.ticket?.expiry != null ? true : false;
    return (
        <>
            <div className="flex-container-horizontal">
                <div className="flex-container-left">
                    <div className="content-container">
                        <p><b>{props.ticketType}</b></p>
                        <div style={{ display: "flex", }}>
                            <p>{props.issuedBy} </p>

                        </div>
                    </div>
                </div>
                <div className="flex-container-right">
                    <div className="input-container" style={{ display: "flex", }}>
                        <p>
                            <IconButton
                                style={{ color: 'red' }}
                                onClick={() => {
                                    setExpireTicketAlertModalOpen(true);
                                }}>
                                {isExpired && <ErrorIcon style={{ color: 'red', width: '15px', height: '15px' }} />}
                            </IconButton>
                        </p>
                        <FormControlLabel
                            control={
                                <Checkbox checked={props.ticket.isSelected} color="primary" onChange={() => { props.handleToggle() }} />
                            }
                        />
                    </div>
                </div>
            </div>
            <AlertModal
                isOpen={expirTicketAlertModalOpen}
                onClose={() => setExpireTicketAlertModalOpen(false)}
                body={"This Ticket has expired."}
            />
        </>
    )
}