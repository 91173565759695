import React from "react";
import { makeStyles } from "@material-ui/styles";

import { BIGrey } from "../assets/buildidColors";


/*
Placeholder with an icon and text
Props (*required):
	*icon	- JSX object of the MUI icon
	*text	- string; text displayed underneath the icon

Usage:
	<Placeholder icon={} text=""/>
*/

const useStyles = makeStyles({
	placeholderStyle: {
		"& > svg": {
			width: 208,
			height: 208,
			color: BIGrey
		},
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	}
});

export default function Placeholder(props) {
	const styles = useStyles();

	return (
		<div className={styles.placeholderStyle}
			style={{ margin: "16px", textAlign: "center" }}>
			{props.icon}
			<div style={{ padding: "10px 0" }}>
				<p style={{ color: BIGrey }}>{props.text}</p>
				<p style={{ color: BIGrey }}>{props.text2}</p>
			</div>
		</div>
	)
}