import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  TextField,
  CardHeader,
  Card,
  Avatar,
  makeStyles,
  IconButton,
  Tabs,
  Tab,
} from "@material-ui/core";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CallToActionIcon from "@mui/icons-material/CallToAction";

import TicketCard from "./TicketCard";
import { TicketModal } from "./Modals";
import ShareModal from "./ShareModal";
import { Close as CloseIcon } from "@mui/icons-material";
import ShareIcon from "@mui/icons-material/Share";
import {
  fetchTickets,
  saveTicket,
  deleteTicket,
  getTicketsSharedWithUser,
  unshareTicketsWithUser,
  unshareTicketsWithUser1,
} from "../../api/TicketsApi";

import "../../assets/css/Tickets.css";
import { BIBlue, BILightGrey, BIRed } from "../../assets/buildidColors";
import PromptModal from "../../components/PromptModal";
import AlertModal from "../../components/AlertModal";
import Placeholder from "../../components/Placeholder";
import Loader from "../../components/Loader";
import UserUsages from "../../components/userUsages/UserUsages";
import { showTutorialForNewUser, updateTutorialForNewUser } from "../../assets/helpers";
import TicketTutorial from "../../components/Tutorial/TicketsTutotial";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const tabsArray = ["my tickets", "shared with me"];

function Tickets(props) {

  const location = useLocation();
  const [activePage, setActivePage] = useState("my tickets");
  const [tutorialIndex, setTutorialIndex] = useState(1);

  function goToMyTickets() {
    setActivePage("my tickets");
  }
  function goToSharedWithMe() {
    setActivePage("shared with me");
  }

  const handleTabChange = (event, newTab) => {
    setActivePage(newTab);
  };

  useEffect(() => {
    UserUsages("Tickets");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location?.state?.ticketType === "valid") {
      setActivePage("my tickets");
    } else if (location?.state?.ticketType === "expired") {
      setActivePage("my tickets");
    } else if (location?.state?.ticketType === "shared") {
      setActivePage("shared with me");
    }
    // eslint-disable-next-line
  }, [location?.state?.refresh]);

  return (
    <>
      <div className="page">
        <div className="header">
          <div className="page-content">
            <h1>Tickets</h1>
          </div>
        </div>

        {/* <div className="header-nav">
				<div className="page-content">
					<div className="flex-container-horizontal">
						<Button
							onClick={goToMyTickets}>
							<h3 style={{ margin: 0 }}>My Tickets</h3>
						</Button>
						<Button
							onClick={goToSharedWithMe}>
							<h3 style={{ margin: 0 }}>Shared with Me</h3>
						</Button>
					</div>
				</div>
			</div> */}

        {activePage === "my tickets" ? (
          <MyTickets
            activePage={activePage}
            handleTabChange={handleTabChange}
            tabsArray={tabsArray}
            ticketLocation={location?.state?.ticketType}
          />
        ) : (
          <SharedWithMe
            activePage={activePage}
            handleTabChange={handleTabChange}
            tabsArray={tabsArray}
          />
        )}
      </div>
      {showTutorialForNewUser('ticket') && (
        <TicketTutorial
          onPress={() => {
            setTutorialIndex((p) => {
              if (p >= 4) {
                if (showTutorialForNewUser('ticket')) {
                  updateTutorialForNewUser('ticket');
                }
                return 0;
              } else {
                return p + 1
              }
            });

          }}
          tutorialIndex={tutorialIndex}
        />
      )}
    </>

  );
}

export default Tickets;

function MyTickets(props) {
  const userId = localStorage.userId;

  const [tickets, setTickets] = useState([]);
  const [displayTickets, setDisplayTickets] = useState([]);

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  // const [checkBoxVisible, setCheckBoxVisible] = useState(false);
  // const [buttonName, setButtonName] = useState("Share Tickets");
  const [searchValue, setSearchValue] = useState("");
  const [shareTicketsButtonEnable, setShareTicketButtonEnable] = useState(true);
  const [inappropriateImageAlert, setInappropriateImageAlert] = useState(false);
  const [multipleDeleteModal, setMultipleDeleteModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [filter, setFilter] = useState("all");
  const [sortBy, setSortBy] = useState("recent");

  function handleAddModalOpen() {
    setAddModalOpen(true);
  }
  function handleAddModalClose() {
    setAddModalOpen(false);
  }
  function handleShareModalOpen() {
    setShareModalOpen(true);
  }
  function handleShareModalClose() {
    setShareModalOpen(false);
  }

  function setFilterAll() {
    setFilter("all");
  }
  function setFilterValid() {
    setFilter("valid");
  }
  function setFilterExpired() {
    setFilter("expired");
  }

  function handleSortByChange(event) {
    setSortBy(event.target.value);
  }

  function handleShareTicket() {
    setShareModalOpen(true);
  }

  function handleMultipleDeleteModal() {
    setMultipleDeleteModal(true);
  }

  async function handleMultipleTicketDelete() {
    const selectedTickets = displayTickets.filter(
      (ticket) => ticket.isSelected
    );
    selectedTickets.map(async (ticket) => {
      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        DeleteList: [
          {
            Id: ticket.id,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      await deleteTicket(obj);
    });
    await updateTickets();
    setShareTicketButtonEnable(true);
  }

  async function handleDeleteTicket(id) {
    const obj = {
      UserId: Number(localStorage.userId),
      ReturnRecordId: true,
      DeleteList: [
        {
          Id: id,
          ModifiedBy: Number(localStorage.userId),
        },
      ],
    };
    await deleteTicket(obj);
    await updateTickets();
    setShareTicketButtonEnable(true)
  }

  async function handleSaveTicket(ticketData) {
    try {
      ticketData.append("ModifiedBy", userId); // No need to propagate it down to Modal level, just add it here
      await saveTicket(ticketData);
      await updateTickets();
    } catch (error) {
      console.log(error);
      if (error) {
        setInappropriateImageAlert(true);
      }
    }
  }

  // Updates local ticket state array with API's version
  const updateTickets = useCallback(() => {
    // console.log("Getting tickets for userId: " + userId);
    fetchTickets({
      SearchList: [{ createdBy: userId }],
      SortList: [
        {
          FieldName: "Id",
          Direction: "DESC",
        },
      ],
    }).then((res) => {
      let ticketData = [];
      if (res) {
        res.forEach((ticket) => {
          ticketData.push({
            id: ticket.id || 0,
            ticketTypeId: ticket.ticketTypeId || 0,
            ticketType: ticket.ticketType || "",
            expiry: ticket.expiry || "",
            ticketId: ticket.ticketId || "",
            issuedById: ticket.issuedById || 0,
            issuedBy: ticket.issuedBy || "",
            issuedOn: ticket.issuedOn || "",
            sharedTicketsList: ticket.sharedTicketsList || [],
            frontPictureUrl: ticket.frontPictureUrl || "",
            backPictureUrl: ticket.backPictureUrl || "",
            noOfUsersSharedWith: ticket.noOfUsersSharedWith || 0,
            isSelected: false,
          });
        });
      }
      setTickets(ticketData);
      setShowLoader(false);
      setShareTicketButtonEnable(true)
    });
  }, [userId]);

  // On page load, retrieve tickets from API
  useEffect(() => {
    setShowLoader(true);
    updateTickets();
  }, [updateTickets]);

  const getUpdatedShareList = () => {
    updateTickets();
  };
  const [placeholderText, setPlaceholderText] = useState(
    "You don't have any tickets yet"
  );
  // Modify displayed tickets array when filter button is clicked/sort is selected
  // Also updates in special case for when tickets gets updated (ie. after API get)
  useEffect(() => {
    let newDisplay = [...tickets]; // Copy ticket array for modification

    switch (filter) {
      case "all":
        break;

      case "valid":
        newDisplay = newDisplay.filter(
          (ticket) => Date.parse(ticket.expiry) > new Date()
        );
        break;

      case "expired":
        newDisplay = newDisplay.filter(
          (ticket) => Date.parse(ticket.expiry) < new Date()
        );
        break;

      default:
        break;
    }

    switch (sortBy) {
      case "recent":
        newDisplay = newDisplay.sort(
          (a, b) => Date.parse(b.dateModified) - Date.parse(a.dateModified)
        );
        break;

      case "issued-by":
        newDisplay = newDisplay.sort((a, b) =>
          a.issuedBy.localeCompare(b.issuedBy)
        );
        break;

      case "issued-date":
        newDisplay = newDisplay.sort(
          (a, b) => Date.parse(b.issuedOn) - Date.parse(a.issuedOn)
        );
        break;

      case "expiry-date":
        newDisplay = newDisplay.sort(
          (a, b) => Date.parse(a.expiry) - Date.parse(b.expiry)
        );
        break;

      case "type":
        newDisplay = newDisplay.sort((a, b) =>
          a.ticketType.localeCompare(b.ticketType)
        );
        break;

      default:
        break;
    }

    const filterArr = newDisplay.filter((item) => {
      if (item.ticketType.toLowerCase().includes(searchValue.toLowerCase())) {
        return item;
      } else if (
        item.issuedBy.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        return item;
      }
    });

    setDisplayTickets(filterArr); // Display newly filtered/sorted array
    switch (filter) {
      case "all":
        if (filterArr.length === 0) {
          setPlaceholderText("You don't have any tickets yet");
        }
        break;

      case "valid":
        if (filterArr.length === 0) {
          setPlaceholderText("You don't have any valid tickets yet");
        }
        break;

      case "expired":
        if (filterArr.length === 0) {
          setPlaceholderText("You don't have any expired tickets yet");
        }
        break;

      default:
        break;
    }
  }, [filter, sortBy, searchValue, tickets]);
  // const [filter, setFilter] = useState("all");

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    console.log("ticketLocation--", props?.ticketLocation)
    if (props?.ticketLocation === "valid") {
      setFilter("valid");
    } else if (props?.ticketLocation === "expired") {
      setFilter("expired");
    }
  }, []);

  return (
    <div className="page-content">
      {shareModalOpen &&
        <ShareModal
          modalOpen={shareModalOpen}
          setShareTicketButtonEnable={setShareTicketButtonEnable}
          onModalClose={handleShareModalClose}
          setDisplayTickets={setDisplayTickets}
          displayTickets={displayTickets}
        />}

      <PromptModal
        isOpen={multipleDeleteModal}
        onClose={() => setMultipleDeleteModal(false)}
        title="Delete Tickets"
        desc="Are you sure you want to delete this tickets?"
        redButtonText="Yes"
        blueButtonTextFirst="No"
        onRedButtonClick={() => {
          setMultipleDeleteModal(false);
          handleMultipleTicketDelete();
        }}
      />

      <AlertModal
        isOpen={inappropriateImageAlert}
        onClose={() => setInappropriateImageAlert(false)}
        title={"Content Rejected"}
        body={
          "The text picture you entered has been automatically identified as inappropriate."
        }
        inappropriateImage
        text1={"Please remove the content and try again."}
      />

      <TicketModal
        config="edit-only"
        modalOpen={addModalOpen}
        modalEditMode={true} // Force edit mode; no need to change
        onModalClose={handleAddModalClose}
        onSave={handleSaveTicket}
        ticket={{
          id: 0,
          ticketTypeId: 0,
          ticketType: "",
          issuedById: 0,
          issuedBy: "",
          backPictureUrl: "",
          frontPictureUrl: "",
          issuedOn: null,
          expiry: null,
        }}
      />

      <div className="resp-container-horizontal" style={{ margin: "0px 20px" }}>
        <div className="flex-container-left" style={{ marginTop: "10px" }}>
          <div className="flex-container-horizontal profile-flex">
            <div className="input-container">
              <Tabs
                textColor="primary"
                indicatorColor="primary"
                value={props.activePage}
                onChange={props.handleTabChange}>
                {props.tabsArray.map((tab, index) => (
                  <Tab
                    key={index}
                    value={tab}
                    label={tab}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                      marginRight: "20px",
                    }}
                  />
                ))}
              </Tabs>
            </div>
            <div className="input-container">
              <TextField
                variant="outlined"
                label="Search"
                size="small"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                  endAdornment:
                    searchValue !== "" ? (
                      <IconButton
                        size="small"
                        onClick={() => setSearchValue("")}>
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <></>
                    ),
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex-container-right" style={{ marginTop: "10px" }}>
          <div className="flex-container-horizontal">
            <div className="input-container">
              <Select
                style={{ height: "35px" }}
                value={filter}
                onChange={handleFilterChange}
                variant="outlined">
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="valid">Valid</MenuItem>
                <MenuItem value="expired">Expired</MenuItem>
              </Select>
            </div>

            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                Sort By
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={sortBy}
                style={{ height: "35px" }}
                onChange={handleSortByChange}
                autoWidth
                label="Sort By">
                <MenuItem value="recent">Recent</MenuItem>
                <MenuItem value="issued-by">Issued By</MenuItem>
                <MenuItem value="issued-date">Issue Date</MenuItem>
                <MenuItem value="expiry-date">Expiry Date</MenuItem>
                <MenuItem value="type">Type</MenuItem>
              </Select>
            </FormControl>
            <div className="input-container">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddModalOpen}>
                Add New Ticket
              </Button>
            </div>
            {!shareTicketsButtonEnable && (
              <IconButton onClick={() => { handleShareTicket() }}>
                <ShareIcon />
              </IconButton>
            )}
            {!shareTicketsButtonEnable && (
              <IconButton
                variant="contained"
                style={{ color: BIRed }}
                fontSize={"large"}
                onClick={handleMultipleDeleteModal}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </div>
      </div>

      {!showLoader ? (
        displayTickets.length === 0 ? (
          <Placeholder icon={<CallToActionIcon />} text={placeholderText} />
        ) : (
          <div className="ticket-cards-container">
            {displayTickets.length > 0 ? (
              displayTickets.map((ticket, index) => (
                <TicketCard
                  key={ticket.id}
                  index={index}
                  id={ticket.id}
                  displayTickets={displayTickets}
                  setShareTicketButtonEnable={setShareTicketButtonEnable}
                  setDisplayTickets={setDisplayTickets}
                  ticket={ticket}
                  onDelete={handleDeleteTicket}
                  onSave={handleSaveTicket}
                  config="view-edit"
                  getUpdatedShareList={getUpdatedShareList}
                />
              ))
            ) : (
              <div className="centered-message">
                <CallToActionIcon />
                <p>You don't have any tickets yet.</p>
              </div>
            )}
          </div>
        )
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
}

function SharedWithMe(props) {
  const userId = localStorage.userId;

  const [ticketsSharedWithUser, setTicketsSharedWithUser] = useState([]);
  const [displayTickets, setDisplayTickets] = useState([]);
  const [sectionVisible, setSectionVisible] = useState([]);
  const [deleteButtonDisable, setDeleteButtonDisable] = useState(true);
  const [discardTicketModalOpen, setDiscardTicketModalOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("share-date");

  function handleSearchTextFieldChange(event) {
    setSearchTerm(event.target.value);
  }

  function handleSortByChange(event) {
    setSortBy(event.target.value);
  }

  function handleHideSection(event) {
    let newVisibility = [...sectionVisible];
    newVisibility[event.currentTarget.id] = false;

    setSectionVisible(newVisibility);
  }

  function handleShowSection(event) {
    let newVisibility = [...sectionVisible];
    newVisibility[event.currentTarget.id] = true;
    setSectionVisible(newVisibility);
  }

  function handleClick() {
    const selectedTickets = ticketsSharedWithUser.filter(
      (item) => item.isSelected
    );

    if (selectedTickets.length === 0) {
      alert("Please select ticket");
    } else {
      setDiscardTicketModalOpen(true);
    }
  }

  async function handleDelete() {
    try {
      const selectedTickets = ticketsSharedWithUser.filter(
        (item) => item.isSelected
      );
      let deletedTicketsList = [];

      if (selectedTickets.length !== 0) {
        for (let i = 0; i < selectedTickets.length; i++) {
          deletedTicketsList.push({
            TicketId: selectedTickets[i].id,
            UserId: selectedTickets[i].ticketOwnerId,
            SharedWithUserEmail: localStorage.primaryEmail,
          });
        }
      }

      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: false,
        ReturnRecordError: true,
        DeleteList: deletedTicketsList,
      };
      const unshareTicketsWithUserRes = await unshareTicketsWithUser1(obj);

      if (unshareTicketsWithUserRes.message === "OK") {
        setDeleteButtonDisable(true);
        setDiscardTicketModalOpen(false);
        fetchTicketsSharedWithUser();
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  }

  function handleDeleteDisable() {
    const selectedTickets = ticketsSharedWithUser.filter(
      (item) => item.isSelected
    );
    if (selectedTickets.length === 0) setDeleteButtonDisable(true);
    else setDeleteButtonDisable(false);
  }

  // On page load, get tickets shared with user
  useEffect(() => {
    fetchTicketsSharedWithUser();
  }, [userId]);

  function fetchTicketsSharedWithUser() {
    getTicketsSharedWithUser(userId).then((res) => {
      let ticketData = [];
      if (res) {
        res.forEach((ticket) => {
          ticketData.push({
            id: ticket.id || 0,
            ticketType: ticket.ticketType || "",
            expiry: ticket.expiry || "",
            issuedBy: ticket.issuedBy || "",
            issuedOn: ticket.issuedOn || "",
            frontPictureUrl: ticket.frontPictureUrl || "",
            backPictureUrl: ticket.backPictureUrl || "",
            ticketOwner: ticket.ticketOwner || "",
            ticketOwnerPicture: ticket.ticketOwnerPicture || "",
            ticketOwnerEmail: ticket.ticketOwnerEmail || "",
            ticketOwnerId: ticket.ticketOwnerId || 0,
            isSelected: false,
          });
        });
      }

      setTicketsSharedWithUser(ticketData);
    });
  }

  // function handleProfilePicClick(shareTicketList) {
  //   console.log(
  //     "Array Len : ",
  //     displayTickets.filter((item) =>
  //       item.tickets.map((ticket) => ticket.isSelected)
  //     ).length
  //   );
  //   // if(displayTickets.filter(item=>item.tickets.map((ticket)=>ticket.isSelected)).length){

  //   // }
  //   const newDisplayTickets = displayTickets.map((item) => {
  //     if (shareTicketList.key == item.key) {
  //       item.tickets.map((item) => {
  //         item.isSelected = true;
  //       });
  //     }
  //     return item;
  //   });
  //   console.log("newDisplayTickets", newDisplayTickets);
  //   setDisplayTickets(newDisplayTickets);
  // }

  // function handleProfilePicClick(shareTicketList) {
  //   const selectedTickets = displayTickets
  //     .find((item) => item.key === shareTicketList.key)
  //     .tickets.filter((item) => item.isSelected);

  //   const allTicketsSelected =
  //     selectedTickets.length === shareTicketList.tickets.length;

  //   const newDisplayTickets = displayTickets.map((item) => {
  //     if (shareTicketList.key === item.key) {
  //       item.tickets = item.tickets.map((ticket) => {
  //         ticket.isSelected = !allTicketsSelected;
  //         return ticket;
  //       });
  //     }
  //     return item;
  //   });

  //   setDisplayTickets(newDisplayTickets);
  // }

  function handleProfilePicClick(shareTicketList) {
    const selectedTickets = displayTickets
      .find((item) => item.key === shareTicketList.key)
      .tickets.filter((item) => item.isSelected);

    const allTicketsSelected =
      selectedTickets.length === shareTicketList.tickets.length;

    const newDisplayTickets = displayTickets.map((item) => {
      if (shareTicketList.key === item.key) {
        item.tickets = item.tickets.map((ticket) => {
          ticket.isSelected = !allTicketsSelected;
          return ticket;
        });
      }
      return item;
    });

    setDisplayTickets(newDisplayTickets);
  }

  // Format tickets array for display
  // Structure: [{name, [tickets]}, {name2, [tickets2]}, ...]

  useEffect(() => {
    let newDisplay = [];
    ticketsSharedWithUser.forEach((ticket) => {
      // Check if to-be-added ticket's owner has been saved already
      let existingUser = -1;
      newDisplay.forEach((sharer, index) => {
        if (sharer.name === ticket.ticketOwner) existingUser = index;
      });

      // Push ticket to existing array, or create a new element if does not exist
      if (existingUser !== -1) newDisplay[existingUser].tickets.push(ticket);
      else {
        newDisplay.push({
          key: ticket.id, // Use first-added ticket as unique key for mapping
          name: ticket.ticketOwner,
          ticketOwnerPicture: ticket.ticketOwnerPicture,
          ticketOwnerEmail: ticket.ticketOwnerEmail,
          tickets: [ticket],
        });
      }
    });

    // Sort displayed array based on selected sorting method
    switch (sortBy) {
      case "shareDate":
        newDisplay.forEach((sharer) => {
          sharer.tickets = sharer.tickets.sort(
            (a, b) => Date.parse(b.dateModified) - Date.parse(a.dateModified)
          );
        });
        break;

      case "name":
        newDisplay.forEach((sharer) => {
          sharer.tickets = sharer.tickets.sort((a, b) =>
            a.ticketType.localeCompare(b.ticketType)
          );
        });
        break;

      default:
        break;
    }

    // Filter through, only displaying tickets that match search term
    newDisplay.forEach((sharer) => {
      sharer.tickets = sharer.tickets.filter(
        (ticket) =>
          ticket.ticketType.toLowerCase().includes(searchTerm.toLowerCase()) ||
          ticket.issuedBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
          ticket.ticketOwner.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    newDisplay = newDisplay.filter((sharer) => sharer.tickets.length !== 0);

    newDisplay.forEach((sharer) => {
      sectionVisible.push(false);
    });

    setDisplayTickets(newDisplay);
  }, [ticketsSharedWithUser, sortBy, searchTerm, sectionVisible]);

  return (
    <>
      <PromptModal
        isOpen={discardTicketModalOpen}
        onClose={() => setDiscardTicketModalOpen(false)}
        title="Remove Tickets?"
        desc="Are you sure you want to remove tickets shared with you?"
        redButtonText="Yes"
        blueButtonTextFirst="No Thanks"
        onRedButtonClick={handleDelete}
      />

      <div className="page-content" style={{ marginBottom: "30px" }}>
        <div
          className="flex-container-horizontal"
          style={{ justifyContent: "space-between", marginTop: "10px" }}>
          <div className="input-container">
            <Tabs
              textColor="primary"
              indicatorColor="primary"
              value={props.activePage}
              onChange={props.handleTabChange}>
              {props.tabsArray.map((tab, index) => (
                <Tab
                  key={index}
                  value={tab}
                  label={tab}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    marginRight: "20px",
                  }}
                />
              ))}
            </Tabs>
          </div>
          <div className="flex-container-left" style={{ display: "flex" }}>
            <div className="flex-container-horizontal">
              <div className="input-container">
                <TextField
                  variant="outlined"
                  size="small"
                  label="Search"
                  value={searchTerm}
                  onChange={handleSearchTextFieldChange}
                />
              </div>
            </div>
            <div className="input-container">
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Sort By
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={sortBy}
                  style={{ height: "35px" }}
                  onChange={handleSortByChange}
                  autoWidth
                  label="Sort By">
                  <MenuItem value="share-date">Share Date</MenuItem>
                  <MenuItem value="name">Name</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex-container-right">
            <div className="flex-container-horizontal">
              <div className="input-container">
                <Button
                  variant={"contained"}
                  color="primary"
                  size="small"
                  disabled={deleteButtonDisable}
                  onClick={handleClick}>
                  Delete Ticket
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-container-vertical">
          {displayTickets.length > 0 ? (
            displayTickets.map((sharer, index) => {
              const allTicketsSelected = sharer.tickets.every(
                (ticket) => ticket.isSelected
              );
              return (
                <div key={sharer.key} className="ticket-card-container">
                  <div
                    className="flex-container-horizontal"
                    style={{
                      justifyContent: "space-between",
                      padding: "0px 10px",
                    }}>
                    {/* <h2>{sharer.name}</h2> */}
                    <Card className={"remove-shadow"} sx={{ maxWidth: 345 }}>
                      <CardHeader
                        avatar={
                          allTicketsSelected ? (
                            <Avatar
                              style={{ background: BIBlue }}
                              onClick={() => handleProfilePicClick(sharer)}>
                              {" "}
                              <CheckIcon />{" "}
                            </Avatar>
                          ) : (
                            <Avatar
                              src={sharer.ticketOwnerPicture}
                              onClick={() => handleProfilePicClick(sharer)}
                            />
                          )
                        }
                        title={`${sharer.name} (${sharer.tickets.length})`}
                        subheader={sharer.ticketOwnerEmail}
                      />
                    </Card>
                    {sectionVisible[index] ? (
                      <IconButton
                        className="expand-btn"
                        id={index}
                        variant="outlined"
                        onClick={handleHideSection}>
                        <ExpandLessIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        id={index}
                        variant="outlined"
                        onClick={handleShowSection}>
                        <ExpandMoreIcon />
                      </IconButton>
                    )}
                  </div>
                  {sectionVisible[index] && (
                    <div className="ticket-cards-container">
                      {sectionVisible[index] ? (
                        sharer.tickets.map((ticket, index) => (
                          <TicketCard
                            key={ticket.id}
                            index={index}
                            id={ticket.id}
                            ticket={ticket}
                            handleDeleteDisable={handleDeleteDisable}
                            displayTickets={displayTickets}
                            setDisplayTickets={setDisplayTickets}
                            onDelete={props.onDelete}
                            onSave={props.onSave}
                            config="view-only"
                            getUpdatedShareList={() => { }}
                          />
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <Placeholder
              icon={<CallToActionIcon />}
              text="No tickets have been shared with you yet!"
            />
          )}
        </div>
      </div>
    </>
  );
}
