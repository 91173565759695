import { Stack, Modal } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ProfileHeader from "../../components/profile/ProfileHeader";
import AboutSection from "../../components/profile/AboutSection";
import ListSection from "../../components/profile/ListSection";
import { LocationOn as MapMarkerIcon } from "@material-ui/icons";
import BuildIcon from "@mui/icons-material/Build";
import { useHistory } from "react-router-dom";
import { getPosts, savePost, savePostPicture } from "../../api/PostsApi";
import PromptModal from "../../components/PromptModal";
import {
  fetchFollowEntities,
  fetchJobPosts,
  saveFollowEntities,
  saveProjectCompany,
  saveSitePostPicture,
  saveUsersExperiencesProducts,
} from "../../api/SitesApi";
import {
  fetchProjectCompanies2,
  fetchProjectPictures,
  fetchProjectProducts,
  fetchUsersExperiencesProducts,
  getProjectMembers,
  saveProjectProducts,
} from "../../api/ProjectsApi";
import {
  fetchProjectsFavouriteImages,
  fetchProjectsPostsPictures,
  fetchUserSitesProjects,
  fetchUserWorkHistory,
} from "../../api/UserApi";
import moment from "moment";
import AddProjectModal from "../../components/AddProjectModal";
import MemberListModal from "../../components/profile/MemberListModal";
import { inviteFriendsByEmail } from "../../api/ContactsApi";
import { saveChatGroupUsers } from "../../api/ChatApi";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import AddAnotherNameModal from "../../components/AddAnotherNameModal";
import PostInput from "../../components/post/PostInput";
import Post from "../../components/post/Post";
import ExperienceProjectForm from "../../components/ExperienceProjectForm";
import AddSiteModal from "../profile/sections/AddSiteModal";
import ProfilePic from "../../components/profile/ProfilePicListSection";
import CompanyListSection from "../../components/profile/CompanyListSection";
import WorkIcon from "@mui/icons-material/Work";
import { saveCompanies2 } from "../../api/CompanyApi";
import Loader from "../../components/Loader";
import JobListSection from "../../components/profile/JobListSection";
import ChatIcon from "@mui/icons-material/Chat";
import Placeholder from "../../components/Placeholder";
const NewProjectDetailsPage = (props) => {
  // const appId = 2;
  const [projectInfo, setProjectInfo] = useState({});
  const [companyTrades, setCompanyTrades] = useState([]);
  const navigate = useHistory();
  const [isSiteEmpty, setIsSiteEmpty] = useState(false);
  const [companyAddress, setCompanyAddress] = useState([]);
  const [workingHistory, setWorkingHistory] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [getUpdatedData, setGetUpdatedData] = useState(false);

  const [members, setMembers] = useState([]);
  const [activeTab, setActiveTab] = useState("posts");
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [followId, setFollowId] = useState(0);
  const [isSiteOnTimeline, setIsSiteOnTimeline] = useState(false);
  const [projectSectionModalOpen, setProjectSectionModalOpen] = useState(false);
  const [projectSectionAlert, setProjectSectionAlert] = useState(false);
  const [removeProjectSectionAlert, setRemoveProjectSectionAlert] =
    useState(false);
  const [memberListModalOpen, setMemberListModalOpen] = useState(false);
  const [otherSiteNameList, setOtherSiteNameList] = useState([]);
  const fileInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState("");
  const [userTrade, setUserTrade] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [inappropriateImageAlert, setInappropriateImageAlert] = useState(false);
  const [recordId, setRecordId] = useState(0);
  const [imageLoading, setImageLodaing] = useState(false);
  const [isProductEmpty, setIsProductEmpty] = useState(false);
  const [isCompanyEmpty, setIsCompanyEmpty] = useState(false);
  const [getUpdatedImages, setGetUpdatedImages] = useState(false);
  const [showBtnLoader, setShowBtnLoader] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [pictureList, setPictureList] = useState([]);
  const [activeJobArray, setActiveJobArray] = useState([]);
  const [currentSite, setCurrentSite] = useState([]);
  const history = useHistory();
  const projectLocation = useLocation();

  const [isProductLoading, setIsProductLoading] = useState(false);
  const [isCompanyLoading, setIsCompanyLoading] = useState(false);

  const [showAddExperienceModal, setShowAddExperienceModal] = useState(false);
  const [showExperienceDiscardModal, setShowExperienceDiscardModal] =
    useState(false);

  useEffect(() => {
    getProjectData();
    // getProjectMembersList();
    // getSiteMembersList();
    // updatePosts();
    // getUsertrades();
    if (projectLocation?.state?.from === "DashboardNotifications") {
      setActiveTab("jobs");
    }
    return () => {
      setProjectInfo({});
      setMembers([]);
      setActiveTab("posts");
      setPosts([]);
      setIsLoading(false);
      setIsMember(false);
      setIsFollow(false);
      setFollowId(0);
      setIsSiteOnTimeline(false);
      setProjectSectionModalOpen(false);
      setProjectSectionAlert(false);
      setRemoveProjectSectionAlert(false);
      setMemberListModalOpen(false);
      setOtherSiteNameList([]);
      setImageUrl("");
      // setImageUrl(projectLocation.state?.projectData?.mainPictureUrl);
      console.log("projectLocation", projectLocation);
      // if (projectLocation?.state?.from === "DashboardNotifications") {
      //     setActiveTab("jobs");
      // }
    };
  }, []);

  const updatePosts = useCallback(() => {
    getPosts(24, Number(props.projectId), 1, 25).then((res) => {
      if (res) {
        setPosts(res);
        setIsLoading(false);
      }
    });
  }, [props]);

  const onMenuOptionClick = (data) => {
    if (data === "Add Site picture") {
      fileInputRef.current.click();
    } else {
    }
  };

  function handleFollowPressed() {
    handleSaveFollowClick(true);
  }

  function handleFollowingPressed() {
    setRemoveProjectSectionAlert(true);
  }

  function handleAddToProfileButton() {
    setShowAddExperienceModal(true);
  }

  function handleRightIconPressed() {
    setShowAddExperienceModal(true);
  }

  function handleMemberListPressed() {
    setMemberListModalOpen(true);
  }

  async function handleSaveFollowClick(isFollow) {
    setIsLoading(true);
    try {
      const saveEntitiesObject = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: followId,
            EntityTypeId: 24,
            EntityId: Number(props.projectId),
            UserId: Number(localStorage.userId),
            IsFollowing: isFollow,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      await saveFollowEntities(saveEntitiesObject);
      // await getProjectUsers();
      // await fetchFollowsEntity();
      await getProjectData();
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR_SAVE_FOLLOW__:", error);
      setIsLoading(false);
    }
  }

  const getProjectUsers = async () => {
    try {
      const projectUsers = await getProjectMembers(Number(props.projectId));
      const isFollowed = projectUsers.some(
        (user) => user.userId == localStorage.userId
      );
      setMembers(projectUsers);
      setIsFollow(isFollowed);
      // await this.setState({ projectUsers: projectUsers ?? [], isFollowing: isFollowed })
    } catch (e) {
      console.log("ERROR :: GET PROJECT USERS :", e);
    }
  };

  async function fetchFollowsEntity() {
    try {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        SearchList: [
          {
            EntityId: Number(props.projectId),
            userId: Number(localStorage.userId),
          },
        ],
      };
      const followingData = await fetchFollowEntities(obj);
      if (followingData.length != 0) {
        setFollowId(followingData.data[0]?.id ?? 0);
        setIsFollow(followingData.data[0]?.isFollowing);
      }
    } catch (Error) {
      console.log("GET_Follow Entity [ERROR]::", Error);
    }
  }

  const getProjectPosts = async () => {
    try {
      getProjectsPostsPictures();
      const projectPosts = await getPosts(24, Number(props.projectId), 1, 20);
      setPosts(projectPosts);
    } catch (error) {
      console.log("Get Project PostsError:", error.message ?? error);
    }
  };

  async function getUserWorkHistory() {
    try {
      const obj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: "true",
        SearchList: [{ Id: 0, UserId: Number(localStorage.userId) }],
      };
      const userWorkHistoryres = await fetchUserWorkHistory(obj);
      let isSiteOnTimeline = false;
      const currentSite = userWorkHistoryres?.filter(
        (p) => p.userSiteProjectId == Number(props.projectId)
      );
      if (currentSite?.length > 0) isSiteOnTimeline = true;
      setIsSiteOnTimeline(isSiteOnTimeline);
      // setCurrentSite()
      setIsLoading(false);
    } catch (Error) {
      console.log("GET_WORK_HISTORY[ERROR]::", Error);
      setIsLoading(false);
    }
  }

  const getProjectsJobs = async () => {
    try {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: true,
        SearchList: [{ projectId: Number(props.projectId) }],
      };
      const result = await fetchJobPosts(obj);

      const activeArray = [];

      result.forEach((item) => {
        const currentDate = moment(new Date());
        const itemDate = moment(item.dateCreated);
        const diffInDays = currentDate.diff(itemDate, "days");
        if (
          moment(new Date()).format("YYYY-MM-DD") <=
          moment(item.jobPostExpiryDate).format("YYYY-MM-DD")
        ) {
          activeArray.push(item);
        }
      });
      setActiveJobArray(activeArray);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getProjectData = async () => {
    setIsLoading(true);
    const obj = {
      PageNr: 1,
      IncludeRecordNr: true,
      FullSearch: "",
      SearchList: [{ Id: Number(props.projectId) }],
    };
    const projectDataRes = await fetchUserSitesProjects(obj);
    // setProjectData(projectDataRes);
    setProjectInfo(projectDataRes?.[0]);
    const picObj = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: Number(localStorage.userId),
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SearchList: [{ ProjectId: Number(props.projectId) }],
      SortList: [{ FieldName: "Id", Direction: "DESC" }],
    };
    const pictureListRes = await fetchProjectPictures(picObj);

    setPictureList(pictureListRes);
    // } else {
    //     setProjectData(props.projectData);
    // }
    await getProjectUsers();
    await getProjectPosts();
    await fetchFollowsEntity();
    await getProjectsJobs();
    await getProjectPicturesList();
    await getUserWorkHistory();
    setIsLoading(false);
  };

  function afterSaveSite() {
    if (!isFollow) {
      handleSaveFollowClick(true);
    }
    setShowAddExperienceModal(false);
    setIsUpdated(true);
  }

  const handleAddContact = async (data) => {
    try {
      setShowBtnLoader(true);
      let obj = {
        UserId: localStorage.userId,
        FriendsEmails: data?.userEmail,
        Notification: {
          ActionUrl: `${process.env.REACT_APP_CONTACTPROFILE}${Number(
            localStorage.userId
          )}`,
          title: "Connect Request",
          Body: "has requested to Connect you.",
          //NotifyUserList: `${contact?.userId}`
        },
      };
      await inviteFriendsByEmail(obj);
      await getProjectUsers();
      setShowBtnLoader(false);
    } catch (error) {
      setShowBtnLoader(false);
      console.log("handleAddContact error", error);
    }
  };

  const navigateTochat = async (data) => {
    try {
      setShowBtnLoader(true);
      const saveListArray = [
        {
          Id: 0,
          ChatGroupId: 0,
          UserId: data?.userId,
          ModifiedBy: Number(localStorage.getItem("userId")),
          Muted: false,
        },
      ];
      const chatGroupObj = {
        UserId: Number(localStorage.getItem("userId")),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: saveListArray,
      };
      let result = await saveChatGroupUsers(chatGroupObj);
      if (result?.data?.length > 0) {
        let chatGroupId = result.data[0]?.objData.chatGroupId;
        history.push({
          pathname: "/chat",
          state: {
            from: "New Chat",
            chatGroupId: chatGroupId,
            showInList: true,
          },
        });
      }
      setShowBtnLoader(false);
    } catch (error) {
      setShowBtnLoader(false);
      console.log("Error while sending to chat screen :: ", error);
    }
  };

  const onDataSave = async () => {
    const obj = {
      PageNr: 1,
      IncludeRecordNr: true,
      FullSearch: "",
      SearchList: [{ Id: Number(props.projectId) }],
    };
    const projectDataRes = await fetchUserSitesProjects(obj);
    setProjectInfo(projectDataRes?.[0]);
  };

  const getProjectPicturesList = async () => {
    const postPicObj = {
      PageNr: 1,
      NrOfRecPerPage: 12,
      FullSearch: "",
      UserId: Number(localStorage.userId),
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SearchList: [{ appRecordId: Number(props.projectId) }],
      SortList: [{ FieldName: "Id", Direction: "DESC" }],
    };
    await fetchProjectsPostsPictures(postPicObj)
      .then((res) => {
        setImageUrl(res?.[0]?.pictureUrl ?? "");
        setImageLodaing(false);
      })
      .catch((err) => {
        setImageLodaing(false);
        console.log("Error while getting site images:-", err);
      });
  };

  const handleFileSelect = async (e) => {
    let files = e.target.files;
    if (files && files.length > 0) {
      let allFilesValid = true;

      for (let i = 0; i < files.length; i++) {
        if (!files[i].type.startsWith("image/")) {
          allFilesValid = false;
          break;
        }
      }

      if (allFilesValid) {
        setImageLodaing(true);
        const obj = {
          UserId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: false,
          SaveList: [
            {
              Id: 0,
              FormatTypeId: 1,
              PostedByUserId: Number(localStorage.userId),
              AppId: 24,
              AppRecordId: Number(props?.projectId),
              Message: "<<<picture>>>",
              Reported: false,
              ModifiedBy: Number(localStorage.userId),
            },
          ],
        };

        try {
          const postRes = await savePost(obj);

          for (let i = 0; i < files.length; i++) {
            const formData = new FormData();
            formData.append("Id", 0);
            formData.append("PostId", postRes[0].objData.id);
            formData.append("ModifiedBy", localStorage.userId);
            formData.append("Pictures", files[i]);
            await savePostPicture(formData).catch((err) => {
              console.log(
                "Error while calling the save post pictures API:",
                err
              );
              if (err === "Content Inappropriate") {
                setImageLodaing(false);
                setInappropriateImageAlert(true);
              }
            });
          }
          await getProjectPicturesList();
          updatePosts();
          if (activeTab === "photos") {
            setGetUpdatedImages(true);
          }
        } catch (err) {
          setImageLodaing(false);
          console.log("Error while calling the save site API:", err);
        }
      } else {
        alert("Please select valid image files (JPEG, PNG, GIF, etc.).");
      }
    }
  };

  async function getProjectsPostsPictures() {
    try {
      const postObj = {
        PageNr: 1,
        NrOfRecPerPage: 12,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [{ appRecordId: Number(props.projectId) }],
        SortList: [{ FieldName: "Id", Direction: "DESC" }],
      };

      const postPictures = await fetchProjectsPostsPictures(postObj);

      const favObj = {
        PageNr: 1,
        NrOfRecPerPage: 12,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            projectId: props?.projectId,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
        SortList: [{ FieldName: "Id", Direction: "DESC" }],
      };

      const favoriteImagesResponse = await fetchProjectsFavouriteImages(favObj);
      let b = [];
      const siteWithFavImages = postPictures.map((fav) => {
        b = fav;
        b["isFavorite"] = false;
        favoriteImagesResponse.map((img) => {
          if (img.projectPictureId === fav.pictureId) {
            b["isFavorite"] = true;
          }
        });
        return b;
      });
      return siteWithFavImages;
    } catch (error) {
      setIsLoading(false);
    }
  }

  async function getProjectCompaniesData() {
    try {
      setIsCompanyLoading(true);
      const obj1 = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage?.userId),
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
        SearchList: [{ projectId: Number(props?.projectId) }],
        SortList: [{ FieldName: "CompanyName", Direction: "ASC" }],
      };
      const projectCompanies2 = await fetchProjectCompanies2(obj1);
      setIsCompanyEmpty(projectCompanies2?.data?.length === 0 ? true : false);
      setIsCompanyLoading(false);
      return projectCompanies2;
    } catch (Error) {
      console.log("GET SITE_COMPANIES-2 [ERROR]::", Error);
      setIsLoading(false);
      setIsCompanyLoading(false);
    }
  }

  function saveComp(params) {
    if (params.companyName === "") {
      getProjectCompany(params);
    } else {
      handleSaveCompanies(params);
    }
  }

  async function getProjectCompany(params) {
    try {
      setIsLoading(true);
      const obj = [
        {
          Id: 0,
          ProjectId: Number(props.projectId),
          CompanyId: params.companyName == "" ? params.id : params.id,
          ModifiedBy: Number(localStorage.userId),
          ProjectCompanyRoleId: params.roleId,
          OtherProjectCompanyRole: params.otherRoleName,
        },
      ];
      await saveProjectCompany(obj);
      setIsLoading(false);
    } catch (ERROR) {
      console.log("GET SITE_COMPANIES [ERROR]::", ERROR);
      setIsLoading(false);
    }
  }

  async function handleSaveCompanies(params) {
    try {
      const saveObject = {
        userId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            Name: params.companyName,
            ModifiedBy: Number(localStorage.userId),
            RecordStatusId: 1,
            Manufacturer: false,
          },
        ],
      };
      const projectCompaniesRes = await saveCompanies2(saveObject);
      let newItem = {
        id: projectCompaniesRes[0]?.objData?.id,
        roleId: params.roleId,
        otherRoleName: params.otherRoleName,
      };
      getProjectCompany(newItem);
    } catch (error) {
      console.log("SAVE SITE_COMPANIES [ERROR]::", error);
    }
  }

  async function addProductToProjectData() {
    try {
      setIsProductLoading(true);
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage?.userId),
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            projectId: Number(props?.projectId),
          },
        ],
        SortList: [{}],
      };
      const projectProducts = await fetchProjectProducts(obj);
      setIsProductEmpty(projectProducts?.data?.length === 0 ? true : false);
      setIsProductLoading(false);
      return projectProducts;
    } catch (Error) {
      console.log("GET Project COMPANIES-2 [ERROR]::", Error);
      setIsLoading(false);
      setIsProductLoading(false);
    }
  }

  const onProductSave = async (productId) => {
    if (!productId) return;
    try {
      setIsLoading(true);

      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0, //! here dynamic data have to add
            ProjectId: Number(props.projectId),
            ProductId: productId,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };

      await saveProjectProducts(obj);

      setIsLoading(false);
    } catch (ERROR) {
      console.log("GET SITE_Products [ERROR]::", ERROR);
      setIsLoading(false);
    }
  };

  // if (isLoading) {
  //     return (
  //         <div className="profile-loader">
  //             <Loader />
  //         </div>
  //     )
  // }

  const onExperienceSave = () => {
    setShowAddExperienceModal(false);
  };

  const onExperienceModalClose = () => {
    setShowExperienceDiscardModal(false);
    setShowAddExperienceModal(false);
  };

  return (
    <div className="page" style={{ justifyContent: isLoading ? "center" : "" }}>
      <PromptModal
        isOpen={removeProjectSectionAlert}
        onClose={() => {
          setRemoveProjectSectionAlert(false);
        }}
        title="Are you sure you want to unfollow this Site Page"
        redButtonText="Yes"
        blueButtonTextFirst="No"
        onRedButtonClick={() => {
          handleSaveFollowClick(false);
          setProjectSectionModalOpen(false);
          setRemoveProjectSectionAlert(false);
        }}
      />

      {showAddExperienceModal && (
        <>
          <Modal
            open={showAddExperienceModal}
            onClose={() => {
              setShowExperienceDiscardModal(true);
            }}
          >
            <div className="modal-center">
              <div className="modal-card" style={{ width: "800px" }}>
                <div className="modal-card-body">
                  <ExperienceProjectForm
                    from={"projectProfile"}
                    data={projectInfo ?? {}}
                    onClose={onExperienceModalClose}
                    showExperienceDiscardModal={showExperienceDiscardModal}
                    setShowExperienceDiscardModal={(val) => {
                      setShowExperienceDiscardModal(val);
                    }}
                    onDataSave={onExperienceSave}
                    isFollowing={false}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}

      <MemberListModal
        isOpen={memberListModalOpen}
        onClose={() => {
          setMemberListModalOpen(false);
        }}
        members={members}
        profileMemberList={false}
        handleConnectClick={handleAddContact}
        handleMessageClick={navigateTochat}
        showBtnLoader={showBtnLoader}
      />

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelect}
        accept="image/*"
        multiple
      />

      <div className="page-content">
        {isLoading ? (
          <Loader />
        ) : (
          <Stack spacing={2} style={{ margin: "16px 0px" }}>
            <ProfileHeader
              isFollow={isFollow}
              title={projectInfo?.projectName}
              imageUrl={imageUrl}
              images={[]}
              recordId={recordId}
              setRecordId={setRecordId}
              subtitle={"Project"}
              handleFollowClick={() => handleFollowPressed()}
              handleFollowingClick={() => handleFollowingPressed()}
              handleAddToProfileButton={() => handleAddToProfileButton()}
              handleRightIconPressed={() => handleRightIconPressed()}
              handleMemberListPressed={() => handleMemberListPressed()}
              isLoading={isLoading}
              isMember={isMember}
              // isSiteOnTimeline={isSiteOnTimeline}
              btnTitle1={"Follow"}
              btnTitle={"ADD TO PROFILE"}
              warningText={
                projectInfo?.address === "" &&
                projectInfo?.city === "" &&
                projectInfo?.province === "" &&
                projectInfo?.country === "" &&
                projectInfo?.postalCode === ""
                  ? "This Site will not be visible to others until an address is added."
                  : false
              }
              members={members}
              tabs={[
                "posts",
                "photos",
                "companies",
                "products",
                activeJobArray.length != 0 ? "jobs" : null,
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              menuOptions={["Add Site picture", "Send in Chat", "Share"]}
              onMenuOptionClick={onMenuOptionClick}
              shareDetails={{ type: "ProjectDetail", id: props?.projectId }}
              imageLoading={imageLoading}
              from="Project"
            />

            <Stack direction="row" spacing={2}>
              <div style={{ flex: 1 }}>
                <AboutSection
                  withCard
                  content={{
                    description: projectInfo?.description ?? "",
                    projectTypeName: projectInfo?.projectTypeName,
                    projectTimeFrame: {
                      startDate: projectInfo?.startDate ?? null,
                      endDate: projectInfo?.endDate ?? null,
                    },
                    website: projectInfo?.projectWebsite ?? "",
                  }}
                  aboutSectionType={"project"}
                  onDataSave={onDataSave}
                  projectDetails={projectInfo ?? {}}
                />
              </div>

              <div style={{ flex: 2 }}>
                {activeTab === "posts" ? (
                  <Stack spacing={0.5}>
                    <PostInput
                      post={props.post}
                      appId={24}
                      appRecordId={Number(props.projectId)}
                      updatePosts={updatePosts}
                      onSaveFunction={true}
                      onPostSave={getProjectPicturesList}
                    />

                    {posts?.length != 0 ? (
                      <>
                        {posts.map((post, index) => (
                          <Post
                            key={index}
                            post={post}
                            from={"projectDetailPage"}
                          />
                        ))}
                      </>
                    ) : (
                      <Placeholder
                        icon={<ChatIcon />}
                        text={"Share your thoughts with other BuildId Users"}
                      />
                    )}
                  </Stack>
                ) : (
                  <></>
                )}

                {activeTab === "photos" ? (
                  <ProfilePic
                    projectId={props.projectId}
                    getData={() => getProjectsPostsPictures()}
                    getUpdatedImages={getUpdatedImages}
                    updateValueOfGetUpdatedUmages={() => {
                      setGetUpdatedImages(false);
                    }}
                  />
                ) : (
                  <></>
                )}

                {activeTab === "companies" ? (
                  <CompanyListSection
                    getData={getProjectCompaniesData}
                    itemIdKey="companyId"
                    itemNameKey="companyName"
                    itemRecordType="company"
                    categoryIdKey="projectCompanyRoleId"
                    categoryNameKey="projectCompanyRole"
                    otherSiteCompanyRole="otherProjectCompanyRole"
                    searchPlaceholder="Search this Project's Companies"
                    addButtonLabel="Add Company"
                    saveItem={saveComp}
                    recordId={recordId}
                    setRecordId={setRecordId}
                    emptyData={isCompanyEmpty}
                    NoResultBtn="Add Company"
                    NOResultIcon={<WorkIcon />}
                    isLoading={isCompanyLoading}
                  />
                ) : (
                  <></>
                )}

                {activeTab === "products" ? (
                  <ListSection
                    getData={addProductToProjectData}
                    itemIdKey="productId"
                    itemNameKey="productName"
                    categoryIdKey="manufacturerId"
                    categoryNameKey="manufacturerName"
                    itemRecordType="product"
                    categoryRecordType="manufacturer"
                    searchPlaceholder="Search this Project's Products"
                    addButtonLabel="Add Product"
                    onProductSave={onProductSave}
                    recordId={recordId}
                    setRecordId={setRecordId}
                    emptyData={isProductEmpty}
                    NoResultBtn="Add Product"
                    NOResultIcon={<BuildIcon />}
                    NoResultText="No information for this site."
                    NoResultText2="Know any information? Add it here for builderbucks"
                    addItemLabel="Product Name"
                    showManufactureNameInNewAddProduct={true}
                    isLoading={isProductLoading}
                  />
                ) : (
                  <></>
                )}
                {activeTab === "jobs" ? (
                  <JobListSection activeJobArray={activeJobArray} />
                ) : (
                  <></>
                )}
              </div>
            </Stack>
          </Stack>
        )}
      </div>
    </div>
  );
};

export default NewProjectDetailsPage;
