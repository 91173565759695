import React, { useState } from "react";
import { Modal, IconButton, TextField } from "@material-ui/core";
import { Close, Close as CloseIcon, Search } from "@material-ui/icons";
import { inviteFriendsByEmail } from "../../api/ContactsApi";
import {
  Avatar,
  Button,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { saveChatGroupUsers, searchUsersMembers } from "../../api/ChatApi";
import PromptModal from "../../components/PromptModal";
import { useHistory } from "react-router-dom";
import { saveContactEvent } from "../../api/UserApi";

function AddContactModal(props) {
  const [newEmail, setNewEmail] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [cancelItem, setCancelitem] = useState({});
  const [alertModalOpen, setAlertModalOpen] = useState(false);

  const history = useHistory();

  const handleInputChange = async (e) => {
    setNewEmail(e.target.value.trim());
    let getData = await searchUsersMembers(
      Number(localStorage.userId),
      0,
      e.target.value.trim()
    );
    if (getData?.length > 0) {
      setSearchData(getData);
    }
  };

  const onAddContactPressed = async (contact) => {
    try {
      let obj = {
        UserId: localStorage.userId,
        FriendsEmails: contact.userEmail,
        Notification: {
          ActionUrl: `${process.env.REACT_APP_CONTACTPROFILE}${Number(
            localStorage.userId
          )}`,
          title: "Connect Request",
          Body: "has requested to Connect you.",
          //NotifyUserList: `${contact?.userId}`
        },
      };
      let getOutput = await inviteFriendsByEmail(obj);
      if (getOutput === "Connect Request Send") {
        let getUpdatedlist = searchData.map((item) => {
          if (item?.userId === contact?.userId) {
            return {
              ...item,
              contactStatus: "Request Sent",
            };
          } else {
            return item;
          }
        });
        setSearchData(getUpdatedlist);
      }
    } catch (error) {
      console.log(error);
      alert("Error adding contact(s)");
    }
  };

  const onRequestSentBtnPressed = (item) => {
    setAlertModalOpen(true);
    setCancelitem(item);
  };

  const cancelRequest = async () => {
    try {
      const obj = {
        Id: 0,
        UserId: Number(localStorage.getItem("userId")),
        ContactEmail: cancelItem?.userEmail,
        EventStatusId: 3,
        ModifiedBy: Number(localStorage.getItem("userId")),
      };
      const res = await saveContactEvent(obj);
      let getUpdatedlist = searchData.map((item) => {
        if (item?.userId === cancelItem?.userId) {
          return {
            ...item,
            contactStatus: "No",
          };
        } else {
          return item;
        }
      });
      setSearchData(getUpdatedlist);
      setCancelitem({});
      setAlertModalOpen(false);
    } catch (err) {}
  };

  const onMessagePressed = async (item) => {
    const saveListArray = [
      {
        Id: 0,
        ChatGroupId: 0,
        UserId: item?.userId,
        ModifiedBy: Number(localStorage.getItem("userId")),
        Muted: false,
      },
    ];
    const chatGroupObj = {
      UserId: Number(localStorage.getItem("userId")),
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: saveListArray,
    };
    let result = await saveChatGroupUsers(chatGroupObj);
    if (result?.data?.length > 0) {
      let chatGroupId = result.data[0]?.objData.chatGroupId;
      history.push({
        pathname: "/chat",
        state: {
          from: "New Chat",
          chatGroupId: chatGroupId,
          showInList: true,
        },
      });
    }
  };

  const onAcceptPressed = async () => {
    try {
      const obj = {
        Id: 0,
        UserId: Number(localStorage.getItem("userId")),
        ContactEmail: cancelItem?.userEmail,
        EventStatusId: 2,
        ModifiedBy: Number(localStorage.getItem("userId")),
      };
      const res = await saveContactEvent(obj);
      let getUpdatedlist = searchData.map((item) => {
        if (item?.userId === cancelItem?.userId) {
          return {
            ...item,
            contactStatus: "Yes",
          };
        } else {
          return item;
        }
      });
      setSearchData(getUpdatedlist);
    } catch (err) {}
  };

  const clearValue = () => {
    setNewEmail("");
    setSearchData([]);
  };

  return (
    <>
      <Modal
        open={props.isOpen}
        onClose={() => {
          props.onClose();
          clearValue();
        }}
      >
        <div className="modal-center" style={{ width: "35%" }}>
          <div className="modal-card">
            <div className="modal-card-header-buttons">
              <IconButton
                color="primary"
                onClick={() => {
                  props.onClose();
                  clearValue();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="modal-card-body">
              <h2 style={{ fontSize: "19px" }}>Add Contact</h2>
              <TextField
                fullWidth
                size="medium"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      onClick={clearValue}
                      position="end"
                      style={{ cursor: "pointer" }}
                    >
                      <Close />
                    </InputAdornment>
                  ),
                }}
                value={newEmail}
                variant="outlined"
                onChange={handleInputChange}
                placeholder="Search people"
              />
              <List sx={{ width: "100%" }} className="contact-list">
                {searchData.map((item) => (
                  <ListItem
                    secondaryAction={
                      item?.contactStatus === "No" ? (
                        <Button
                          style={{ background: "#083db8" }}
                          color="primary"
                          variant="contained"
                          onClick={() => onAddContactPressed(item)}
                        >
                          connect
                        </Button>
                      ) : item?.contactStatus === "Request Sent" ? (
                        <Button
                          style={{ background: "#083db8" }}
                          color="primary"
                          variant="contained"
                          onClick={() => onRequestSentBtnPressed(item)}
                          className="request-sent"
                        >
                          Request Sent
                        </Button>
                      ) : item?.contactStatus === "Awaiting response" ? (
                        <Button
                          style={{ background: "#083db8" }}
                          color="primary"
                          variant="contained"
                          onClick={() => onAcceptPressed(item)}
                        >
                          Accept
                        </Button>
                      ) : (
                        <Button
                          style={{ background: "#083db8" }}
                          color="primary"
                          variant="contained"
                          onClick={() => onMessagePressed(item)}
                        >
                          Messages
                        </Button>
                      )
                    }
                  >
                    <ListItemAvatar>
                      <Avatar src={item?.pictureUrl} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item?.firstName + " " + item?.lastName}
                      secondary={item?.tradeName}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </div>
      </Modal>

      <PromptModal
        isOpen={alertModalOpen}
        onClose={() => {
          setAlertModalOpen(false);
          setCancelitem({});
        }}
        title="Connect Request"
        desc="Are you sure want to cancel request ?"
        redButtonText="Cancel"
        blueButtonText="Ok"
        onRedButtonClick={() => {
          setAlertModalOpen(false);
          setCancelitem({});
        }}
        onBlueButtonClick={cancelRequest}
      />
    </>
  );
}

export default AddContactModal;
