import React, { useEffect, useState } from "react";
import { KeyboardDatePicker, TimePicker } from "@material-ui/pickers";
import {
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import { Button } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import { fetchClientJobs, saveClientSafetyIncidents } from "../../../api/ClientApi"
import { Close } from "@material-ui/icons";

function Addmodel({ close, open, getSafetyIncidents }) {

  const [data, setData] = useState({
    Id: "",
    ClientEmployeeId: "",
    IncidentDate: new Date(),
    IncidentTime: new Date(),
    ClientJobCodeId: "", //jobCode
    Description: "",
    ModifiedBy: "",
  });

  const [jobOptions, setJobOptions] = useState([]);


  const getJobList = async () => {
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: localStorage.getItem("userId"),
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SearchList: [
        {
          clientEmployeeId: localStorage.getItem("clientEmployeeId"),
        },
      ],
    };

    await fetchClientJobs(obj)
      .then((res) => {
        setJobOptions(res.data);
      })

      .catch((err) => {
        console.log("getting error while getting safety incidents list", err);
      });
  };

  const handleSaveSafetyIncidents = async () => {

    const payload = {
      ReturnRecordId: 1,
      ReturnRecordError: 1,
      SaveList: [
        {
          Id: data.Id ? data.Id : 0,
          ClientEmployeeId: localStorage.getItem("clientEmployeeId"),
          IncidentDate: data.IncidentDate
            ? moment(data?.IncidentDate).format("YYYY-MM-DD")
            : "",
          IncidentTime: data.time ? data?.time : moment.utc().format(),
          ClientJobCodeId: data.ClientJobCodeId, //jobCode
          Description: data.Description ? data?.Description : "",
          ModifiedBy: Number(localStorage.getItem("userId")),
        },
      ],
    };

    try {
      const res = await saveClientSafetyIncidents(payload);
      if (res.message === "OK") {
        setData({
          Id: "",
          ClientEmployeeId: "",
          IncidentDate: new Date(),
          IncidentTime: new Date(),
          ClientJobCodeId: "", //jobCode
          Description: "",
          ModifiedBy: "",
        })
        getSafetyIncidents();
      }
    } catch (error) {
      console.log("error while saving safety incidents", error);
    } finally {
      close();
    }
  };

  useEffect(() => {
    getJobList();
  }, []);

  return (
    <div>
      <Modal open={open} onClose={close} disableAutoFocus={true}>
        <div className="modal-center timesheet-modal">
          <div className="modal-card">
            <div className="modal-card-body" style={{ padding: "0px" }}>
              <div className="input-container">
                <div className="input-container">
                  <div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: 'right', marginBottom: '10px' }}>
                      <IconButton
                        color="primary"
                        onClick={() =>{ close();
                          setData({
                            Id: "",
                            ClientEmployeeId: "",
                            IncidentDate: new Date(),
                            IncidentTime: new Date(),
                            ClientJobCodeId: "", //jobCode
                            Description: "",
                            ModifiedBy: "",
                          })
                        }}>
                        <Close />
                      </IconButton>
                    </div>
                    <span>
                      <KeyboardDatePicker
                        style={{ width: "30vw", maxWidth: "310px" }}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Date"
                        format="YYYY-MM-DD"
                        value={
                          data?.IncidentDate ? data?.IncidentDate : new Date()
                        }
                        maxDate={new Date()}
                        onChange={(date) =>
                          setData({ ...data, IncidentDate: date })
                        }
                      />
                    </span>
                    <span style={{ width: "auto" }}>
                      <TimePicker
                        style={{ width: "50%", marginLeft: "10px" }}
                        label="Time"
                        variant="inline"
                        autoOk
                        inputVariant="outlined"
                        value={
                          data?.IncidentTime ? data?.IncidentTime : new Date()
                        }
                        onChange={(timeValue) => {
                          setData({
                            ...data,
                            IncidentTime: moment.utc(timeValue).format(),
                          });
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className="input-container">
                <div className="input-container">
                  <div className="flex-container-vertical">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Job
                      </InputLabel>
                      <Select
                        style={{ alignItems: "stretch" }}
                        label="Select Job"
                        // labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.ClientJobCodeId}
                        onChange={(e) => {
                          setData({
                            ...data,
                            ClientJobCodeId: e.target.value,
                          });
                        }}
                      >
                        {jobOptions?.map((job) => (
                          <MenuItem key={job.id} value={job.id}>
                            {job.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="input-container">
                  <div className="flex-container-vertical">
                    <TextField
                      id="outlined-multiline-static"
                      label="Description"
                      multiline
                      rows={3}
                      onChange={(e) => {
                        setData({ ...data, Description: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div
                  className="input-container"
                  style={{ padding: "20px 0px", display: "flex" }}
                >
                  <Button
                    onClick={() => handleSaveSafetyIncidents()}
                    fullWidth
                    disabled={!data.Description || !data.IncidentTime || !data.IncidentDate || !data.ClientJobCodeId}
                    style={{ background: !data.Description || !data.IncidentTime || !data.IncidentDate || !data.ClientJobCodeId ? "grey" : localStorage?.clientAccentColor, color: 'white', cursor: 'pointer' }}>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Addmodel;
