import React, { useState } from "react";
import SearchField from "../../components/SearchField";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import SearchListSection from "../search/SearchListSection";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { searchProducts } from "../../api/ProductsApi";

const myProductsArray = ["Worked With", "Following"];
const productsTypeArray = ["All", "Tools", "Materials"];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="span">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function TabPanel2(props) {
    const { children2, value2, index2, ...other2 } = props;

    return (
        <div
            role="tabpanel"
            hidden={value2 !== index2}
            id={`vertical-tabpanel-${index2}`}
            aria-labelledby={`vertical-tab-${index2}`}
            {...other2}
        >
            {value2 === index2 && (
                <Box sx={{ p: 3 }}>
                    <Typography component="span">{children2}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel2.propTypes = {
    children2: PropTypes.node,
    index2: PropTypes.number.isRequired,
    value2: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

export default function ProductList(props) {

    const [searchTerm, setSearchTerm] = useState("");
    const [activeMyProducts, setActiveMyProducts] = useState("Worked With");
    const [searchText, setSearchText] = useState("");
    const [productType, setProductType] = useState("All");
    const [productTabValue, setProductTabValue] = useState(0);
    const [page, setPage] = useState(1);
    const [getUpdatedData, setGetUpdatedData] = useState(false);

    const getProducts = useCallback(async (pageNumber, searchText) => {
        const obj = {
            UserId: Number(localStorage.userId),
            SearchString: searchText,
            ProductType: "All",
            MyProductCategory: "",
            PageNumber: pageNumber ?? 1,
            NrRowsPerPage: 20,
        };
        const result = await searchProducts(obj);
        const products = result?.data
            ?.map((res) => {
                // if (
                //     res.manufacturerName == "" &&
                //     res.createdBy !== Number(localStorage.userId)
                // ) {
                //     return null;
                // } else return res;
                return res;
            })
            .filter((value) => value != null);
        return products;
    }, [page]);

    const handleChangeProductTab = (event, newValue) => {
        setProductTabValue(newValue);
        setPage(1);
    };

    const increaseOffset = (callback) => {
        setPage((prevPageNr) => prevPageNr + 1);
        callback(page + 1);
    };

    const clearSearch = (value) => {
        setSearchText(value);
        if (value === "") {
            setGetUpdatedData(true)
        }
    }

    const getToolsProducts = useCallback(async (pageNumber, searchText) => {
        const obj = {
            UserId: Number(localStorage.userId),
            SearchString: searchText,
            ProductType: 'Tools',
            MyProductCategory: '',
            PageNumber: pageNumber ?? 1,
            NrRowsPerPage: 20,
        }
        const result = await searchProducts(obj);
        const products = result?.data?.map((res) => {
            if (res.manufacturerName == '' && res.createdBy !== Number(localStorage.userId)) {
                return null;
            } else return res;
        }).filter((value) => value != null);
        return products;
    }, [page]);

    const getMaterialsProducts = useCallback(async (pageNumber, searchText) => {
        const obj = {
            UserId: Number(localStorage.userId),
            SearchString: searchText,
            ProductType: 'Materials',
            MyProductCategory: '',
            PageNumber: pageNumber ?? 1,
            NrRowsPerPage: 20,
        }
        const result = await searchProducts(obj);
        const products = result?.data?.map((res) => {
            if (res.manufacturerName == '' && res.createdBy !== Number(localStorage.userId)) {
                return null;
            } else return res;
        }).filter((value) => value != null);
        return products;
    }, [page]);

    const getWorkedData = useCallback(async (pageNumber, searchText) => {
        const obj = {
            UserId: Number(localStorage.userId),
            SearchString: searchText,
            ProductType: '',
            MyProductCategory: 'worked with',
            PageNumber: pageNumber ?? 1,
            NrRowsPerPage: 20
        }
        const res = await searchProducts(obj);
        return res.data;
    }, [page]);

    const getFollowingProductData = useCallback(async (pageNumber, searchText) => {
        const obj = {
            UserId: Number(localStorage.userId),
            SearchString: searchText,
            ProductType: '',
            MyProductCategory: 'following',
            PageNumber: pageNumber ?? 1,
            NrRowsPerPage: 20
        }
        const res = await searchProducts(obj)
        return res.data;
    }, [page]);


    return (
        <div className="page">
            <div className="page-content">
                <div className="search-container" style={{ position: "fixed", zIndex: 999 }}>
                    <div style={{ backgroundColor: "white", borderRadius: "5px" }}>
                        <SearchField
                            value={searchText}
                            onChange={(value) => clearSearch(value)}
                            onSubmit={() => { setPage(1); setGetUpdatedData(true) }}
                            placeholder={"Search Products"}
                        />
                    </div>
                </div>
                <div className="product-list">
                    <Tabs
                        orientation="vertical"
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="primary tabs example"
                        style={{ marginTop: "17px", width: "250px" }}
                        value={productTabValue}
                        onChange={handleChangeProductTab}
                    >
                        <Tab
                            textColor="primary"
                            className={"tab-btn-radius"}
                            label="All Products"
                            {...a11yProps(0)}
                        />
                        <Tab
                            textColor="primary"
                            className={"tab-btn-radius"}
                            style={{ marginTop: "17px" }}
                            label="My Products"
                            {...a11yProps(1)}
                        />
                    </Tabs>

                    <TabPanel
                        value={productTabValue}
                        index={0}
                        style={{ width: "100%" }}
                    >
                        <div>
                            <Tabs
                                textColor="primary"
                                indicatorColor="primary"
                                value={productType}
                                onChange={async (event, newTab) => {
                                    setPage(1);
                                    await setProductType(newTab);
                                }}
                            >
                                {productsTypeArray.map((tab, index) => (
                                    <Tab
                                        key={index}
                                        value={tab}
                                        label={tab}
                                        style={{
                                            backgroundColor: "white",
                                            borderRadius: "5px",
                                            marginTop: "17px",
                                            marginRight: "20px",
                                        }}
                                    />
                                ))}
                            </Tabs>
                            {productType === "All" && (
                                <div className="list-container" style={{ marginTop: "0px" }} id={"scrollList"}>
                                    <SearchListSection
                                        getData={getProducts}
                                        increaseOffset={increaseOffset}
                                        page={page}
                                        universalsearch={false}
                                        searchText={searchText}
                                        getUpdatedData={getUpdatedData}
                                        setGetUpdatedData={(val) => setGetUpdatedData(val)}
                                        from="product"
                                    />
                                </div>
                            )}

                            {productType === "Tools" && (
                                <div className="list-container" style={{ marginTop: "0px" }} id={"scrollList"}>
                                    <SearchListSection
                                        getData={getToolsProducts}
                                        increaseOffset={increaseOffset}
                                        page={page}
                                        universalsearch={false}
                                        searchText={searchText}
                                        getUpdatedData={getUpdatedData}
                                        setGetUpdatedData={(val) => setGetUpdatedData(val)}
                                        from="product"
                                    />
                                </div>
                            )}

                            {productType === "Materials" && (
                                <div className="list-container" style={{ marginTop: "0px" }} id={"scrollList"}>
                                    <SearchListSection
                                        getData={getMaterialsProducts}
                                        increaseOffset={increaseOffset}
                                        page={page}
                                        universalsearch={false}
                                        searchText={searchText}
                                        getUpdatedData={getUpdatedData}
                                        setGetUpdatedData={(val) => setGetUpdatedData(val)}
                                        from="product"
                                    />
                                </div>
                            )}

                        </div>
                    </TabPanel>
                    <TabPanel
                        value={productTabValue}
                        index={1}
                        style={{ width: "100%" }}
                    >
                        <div>
                            <Tabs
                                textColor="primary"
                                indicatorColor="primary"
                                value={activeMyProducts}
                                onChange={(event, newTab) => {
                                    setPage(1);
                                    setActiveMyProducts(newTab);
                                }}
                            >
                                {myProductsArray.map((tab, index) => (
                                    <Tab
                                        key={index}
                                        value={tab}
                                        label={tab}
                                        style={{
                                            backgroundColor: "white",
                                            borderRadius: "5px",
                                            marginTop: "17px",
                                            marginRight: "20px",
                                        }}
                                    />
                                ))}
                            </Tabs>
                            {activeMyProducts === "Worked With" &&
                                (
                                    <div className="list-container" style={{ marginTop: "0px" }} id={"scrollList"}>
                                        <SearchListSection
                                            getData={getWorkedData}
                                            increaseOffset={increaseOffset}
                                            page={page}
                                            universalsearch={false}
                                            searchText={searchText}
                                            getUpdatedData={getUpdatedData}
                                            setGetUpdatedData={(val) => setGetUpdatedData(val)}
                                            from="product"
                                        />
                                    </div>
                                )}

                            {activeMyProducts === "Following" &&
                                (
                                    <div className="list-container" style={{ marginTop: "0px" }} id={"scrollList"}>
                                        <SearchListSection
                                            getData={getFollowingProductData}
                                            increaseOffset={increaseOffset}
                                            page={page}
                                            universalsearch={false}
                                            searchText={searchText}
                                            getUpdatedData={getUpdatedData}
                                            setGetUpdatedData={(val) => setGetUpdatedData(val)}
                                            from="product"
                                        />
                                    </div>

                                )}
                        </div>
                    </TabPanel>
                </div>

            </div>
        </div>
    );
}
