import React, { useState } from "react";
import {
	TextField, InputAdornment, IconButton, Button,
	Card, CardContent, Typography
} from "@material-ui/core";
import {
	Search as SearchIcon,
	Close as CloseIcon
} from "@material-ui/icons";
import { Autocomplete as GooglePlacesAutocomplete } from "@react-google-maps/api";
import { fetchFollowingSites, getAddress } from "../../api/SitesApi";
import placeholder from "../../assets/images/project-profile-placeholder.jpg";
import { BIBlue, BIRed, BIWhite } from "../../assets/buildidColors";
import "../../assets/css/Sites.css";
import ReportModal from "../../components/ReportModal";
import { MoreVert as MoreIcon } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { useHistory } from "react-router-dom";
import AddSiteModal from "../profile/sections/AddSiteModal";
import ProjectForm from "../profile/sections/ProjectForm";
import { fetchUserWorkHistory } from "../../api/UserApi";

export default function SitesOverlay(props) {

	const [searchTerm, setSearchTerm] = useState("");
	const [autocomplete, setAutocomplete] = useState(null);	// Google Places Autocomplete API instance
	const [overlayTab, setOverlayTab] = useState("explore");
	const [profileList, setProfilelist] = useState([]);
	const [otherList, setOtherlist] = useState([]);
	const [showEditMenuOption, setShowEditMenuOption] = useState(false);

	const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(false);
	const [reportModalOpenSearch, setReportModalOpenSearch] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const history = useHistory();
	const [editProjectDetails, setEditProjectDetails] = useState({})
	const [projectSectionModalOpen, setProjectSectionModalOpen] = useState(false);
	const [mapRecordId, setMapRecordId] = useState(0);


	const handleSearch = async () => {
		let place = autocomplete.getPlace();
		//const getAddressRes = await getAddress({ latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() });
		// If place is a Autocomplete Place, then it will have a geometry property
		if (place?.geometry) {
			setSearchTerm(place.formatted_address);
			props.setChooselocation({
				lat: Number(place.geometry.location.lat()),
				lng: Number(place.geometry.location.lng())
			});
			props.setDropmarker(true);
			props.getSitesofCurrentlocation(Number(place.geometry.location.lat()), Number(place.geometry.location.lng()));
			props.addjustmapView(place.geometry.viewport);
		}
	}

	const onLoadAuto = (newInstance) => {
		// Access the suggestion list container and add a custom class
		setAutocomplete(newInstance);
	}

	const showMysites = async (val) => {
		try {
			let userSites = [];
			if (val && val === "delete") {
				let getUserworkhistory = await fetchUserWorkHistory({ SearchList: [{ UserId: Number(localStorage.userId) }] });
				userSites = getUserworkhistory ?? []
			} else {
				userSites = props.userSites
			}
			let getUpdatedusersites = await removeDuplicates(userSites, "siteId");
			setProfilelist(getUpdatedusersites);
			const obj = {
				PageNr: 1,
				NrOfRecPerPage: 0,
				FullSearch: "",
				UserId: Number(localStorage.userId),
				IncludeRecordNr: true,
				TypeOfObjectReturned: "",
				SearchList: [{ UserId: Number(localStorage.userId) }],
			}
			const followingSites = await fetchFollowingSites(obj);
			const newFollowingSites = followingSites?.data?.filter((site) => !getUpdatedusersites.some((s) => s?.siteId == site?.id));
			setOtherlist(newFollowingSites);
			setOverlayTab("my-sites");
		} catch (err) {
			console.log("Error while showing site list :: ", err)
		}
	}

	const showOverlaytab = () => {
		setProfilelist([]);
		setOtherlist([]);
		setOverlayTab("explore")
	}

	const removeDuplicates = (originalArray, prop) => {
		let newArray = [];
		let lookupObject = {};
		for (let i in originalArray) {
			lookupObject[originalArray[i][prop]] = originalArray[i];
		}
		for (let i in lookupObject) {
			newArray.push(lookupObject[i]);
		}
		return newArray;
	}

	const handleReport = (id) => {
		setMoreMenuAnchorEl(false)
		setMapRecordId(id);
		// props.setItemRecordId(id);
		setReportModalOpenSearch(true);
	}

	const hanldeEditOnProfile = async (siteDetails) => {
		setEditProjectDetails(siteDetails);
		setMoreMenuAnchorEl(false)
		return setProjectSectionModalOpen(true)
	}


	const onProjectSave = async () => {
		setEditProjectDetails({})
		setProjectSectionModalOpen(false)
		await showMysites("delete")
	}

	return (
		<Card raised className="site-overlay-container"
			style={{ margin: "10px" }}>
			<div className="flex-container-vertical" style={{ flex: 1 }}>
				<div style={{ backgroundColor: BIWhite, borderRadius: "8px", margin: "8px", marginBottom: "0px" }}>
					<GooglePlacesAutocomplete
						onLoad={newInstance => onLoadAuto(newInstance)}	// Sets instance of Autocomplete to be referenced
						onPlaceChanged={handleSearch}
						options={{
							componentRestrictions: { country: ['US', 'CA', 'GB'] }, // Restrict to USA, Canada, and UK
						}}
					>
						<TextField
							variant="outlined"
							placeholder="Search map"
							className="search-location"
							fullWidth
							value={searchTerm}
							onChange={(event) => setSearchTerm(event.target.value)}
							InputProps={{
								endAdornment: <InputAdornment position="end">
									{	// Only show "X" button when field not empty
										(searchTerm !== "")
											? (
												<IconButton
													onClick={() => setSearchTerm("")}>
													<CloseIcon />
												</IconButton>
											)
											: <></>
									}
									<IconButton
										color="primary"
										onClick={handleSearch}>
										<SearchIcon />
									</IconButton>
								</InputAdornment>,
							}} />
					</GooglePlacesAutocomplete>
				</div>
				<div className="flex-container-horizontal" style={{ margin: "0px 8px" }}>
					<div className="input-container"
						style={{ backgroundColor: BIWhite, borderRadius: "8px" }}>
						<Button
							style={{ borderRadius: "20px", fontSize: "13px" }}
							color={overlayTab === "my-sites" ? BIWhite : "primary"}
							variant={"contained"}
							onClick={() => showOverlaytab()}>
							Explore
						</Button>
					</div>
					<div className="input-container"
						style={{ backgroundColor: BIWhite, borderRadius: "8px" }}>
						<Button
							style={{ borderRadius: "20px", fontSize: "13px" }}
							color={overlayTab === "my-sites" ? "primary" : BIWhite}
							variant={"contained"}
							onClick={() => showMysites()}>
							My Sites
						</Button>
					</div>
				</div>
				{
					overlayTab === "my-sites" && (
						<div className="mysite-list">

							{
								profileList && profileList?.length > 0 ?
									(
										<>
											<span className="myprofile-txt">On My Profile</span>
											{
												profileList?.map((item) => (
													<Card sx={{ maxWidth: 345 }} className="mysites-card">
														<div className="site-content" onClick={() => { item?.siteId && history.push(`sites/site/${item?.siteId}`) }}>
															<img
																className="site-img"
																onError={(e) => { e.target.src = placeholder }}
																src={item?.mainPictureUrl}
															/>
															<CardContent className="list-content">
																<Typography gutterBottom variant="h5" className="site-name" component="div">
																	{item?.siteName}
																</Typography>
																<Typography variant="body2" className="site-address">
																	{item?.address}
																</Typography>
															</CardContent>
														</div>
														<IconButton
															onClick={(event) => {
																setShowEditMenuOption(true)
																setSelectedItem(item);
																setMoreMenuAnchorEl(event.currentTarget);
															}}>
															<MoreIcon />
														</IconButton>
													</Card>
												))
											}
										</>
									)
									:
									<></>
							}

							{
								otherList && otherList?.length > 0 ?
									(
										<>
											<span className="myprofile-txt">Others</span>
											{
												otherList?.map((item) => (
													<Card sx={{ maxWidth: 345 }} className="mysites-card">
														<div className="site-content" onClick={() => { item?.siteId && history.push(`sites/site/${item?.siteId}`) }}>
															<img
																className="site-img"
																onError={(e) => { e.target.src = placeholder }}
																src={item?.mainPictureUrl}
															/>
															<CardContent className="list-content">
																<Typography gutterBottom variant="h5" className="site-name" component="div">
																	{item?.name}
																</Typography>
																<Typography variant="body2" className="site-address">
																	{item?.address}
																</Typography>
															</CardContent>
														</div>
														<IconButton
															onClick={(event) => {
																setShowEditMenuOption(false)
																setSelectedItem(item);
																setMoreMenuAnchorEl(event.currentTarget);
															}}>
															<MoreIcon />
														</IconButton>
													</Card>
												))
											}
										</>
									)
									: (
										<></>
									)

							}

						</div>
					)
				}
				<Menu
					anchorEl={moreMenuAnchorEl}
					open={Boolean(moreMenuAnchorEl)}
					onClose={() => {
						setShowEditMenuOption(false)
						setMoreMenuAnchorEl(null);
					}}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
					transformOrigin={{ horizontal: "right", vertical: "top" }}
				>
					{
						showEditMenuOption &&
						<MenuItem onClick={() => { hanldeEditOnProfile(selectedItem) }} style={{ color: BIBlue }} dense>
							Edit on Profile
						</MenuItem>
					}
					<MenuItem onClick={() => handleReport(selectedItem.id)} style={{ color: BIRed }} dense>
						Report
					</MenuItem>
				</Menu>
				<ReportModal
					name={selectedItem?.siteName ?? selectedItem?.name ?? ""}
					isOpen={reportModalOpenSearch}
					onClose={() => {
						setReportModalOpenSearch(false);
						setSelectedItem(null);
					}}
					recordId={mapRecordId}
				/>
			</div>

			<AddSiteModal
				setAddSiteModalOpen={() => {
					setEditProjectDetails({})
					setProjectSectionModalOpen(false)
				}}
				addSiteModalOpen={projectSectionModalOpen}
				resetSelected={() => {
					setEditProjectDetails({})
					setProjectSectionModalOpen(false)
				}}
				projectForm={
					<ProjectForm
						isExperienceCard={true}
						project={editProjectDetails}
						allCompanies={[]}
						onReturnClick={() => {
							setEditProjectDetails({})
							setProjectSectionModalOpen(false)
						}}
						goToSite={true}
						onSaveFunction={true}
						onProjectSave={onProjectSave}
					/>
				}
			/>
		</Card>
	)
}
