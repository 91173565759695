import React from 'react'
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import moment from 'moment';

export const ClientCoachingCard = ({ coachingListItem }) => {
    const { createdByUser, dateCreated, clientEmployeeName, description, userProfilePicture, submitAnonymously } = coachingListItem;
    return (
        <>
            <Grid style={{ cursor: 'pointer' }} marginBottom={1} container marginLeft={2} alignContent={"center"} md={12} lg={12} >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Card>
                        <Grid container alignItems="center" padding={2} justifyContent="space-between">
                            <Grid item gap={1} container xs={6} alignItems="center">
                                {submitAnonymously ?
                                    <Grid item>
                                        <Typography variant="body1">{"Submitted anonymously"}</Typography>
                                    </Grid> :
                                    <>
                                        <Grid item>
                                            <Avatar alt="" src={userProfilePicture} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">{createdByUser}</Typography>
                                        </Grid>
                                    </>}
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">{moment(dateCreated).format("MMMM D, YYYY")}</Typography>
                            </Grid>
                        </Grid>

                        <CardContent style={{ paddingTop: '0px' }}>
                            <Grid container gap={1} alignItems="center">
                                <Grid item>
                                    <PersonIcon color="error" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" color="textSecondary">
                                        {clientEmployeeName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container gap={1} alignItems="center">
                                <Typography variant="body1">
                                    {description}
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
