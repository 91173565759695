import React from 'react'
import { Avatar, Card, CardContent, Grid, IconButton, Modal, Typography } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import moment from 'moment';
import Close from '@material-ui/icons/Close'
import "../../../assets/css/ClientHome.css"
import { Edit } from '@material-ui/icons';
import { BIBlue } from '../../../assets/buildidColors';
import { useState } from 'react';
import { AddSafetyObservationModal } from './AddSafetyObservationModal';

export const ViewObservationModal = ({ isOpen, onClose, individualSafetyObservation, getSafetyIncidentsList, activeTab }) => {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const { profilePictureUrl, userName, safetyObservationDate, clientJobCode, description, pictureList } = individualSafetyObservation;

    const handleEditClick = () => {
        setEditModalOpen(true);
    }

    return (
        <>
            <Modal
                open={isOpen}
                onClose={onClose}>
                <div className="modal-center" style={{ border: "none", outline: "none" }}>
                    <div className="modal-card" style={{ height: "auto", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", padding: "10px", gap: "10px" }}>
                            <IconButton
                                color="primary"
                                onClick={onClose} style={{ padding: "0px" }}>
                                <Close />
                            </IconButton>
                        </div>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <Grid gap={1} container justifyContent="space-between" alignItems={"center"} padding={2}>
                                    <Grid item><Avatar alt={profilePictureUrl} src={profilePictureUrl} /></Grid>
                                    <Grid item><Typography variant='p'>{userName}</Typography></Grid>
                                    <Grid item sx={{ marginLeft: 'auto' }}><Typography variant='p'>{moment(safetyObservationDate).format("MMMM D, YYYY")}</Typography></Grid>
                                    {activeTab === "My Observations" &&
                                        <IconButton onClick={handleEditClick} style={{ color: BIBlue }}>
                                            <Edit />
                                        </IconButton>}
                                </Grid>
                                <CardContent>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <LocationOnIcon color="error" />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" color="textSecondary">
                                                {clientJobCode}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Typography variant="body1" style={{ marginTop: '10px' }}>
                                        {description}
                                    </Typography>

                                    <Grid container style={{ overflowX: 'scroll', width: '600px', flexWrap: 'nowrap' }}>
                                        {pictureList?.length > 0
                                            && pictureList?.map((picture, index) => {
                                                return (
                                                    <Grid
                                                        marginRight={1}
                                                        key={`${index}`}
                                                    >
                                                        <img
                                                            src={picture?.fileUrl}
                                                            alt={`Picture ${index}`}
                                                            style={{
                                                                width: "100px",
                                                                height: "100px",
                                                                borderRadius: "10px"
                                                            }}
                                                        />
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                    <span style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        flexDirection: "row",
                                        gap: "10px",
                                        marginTop:'10px'
                                    }} >
                                        {
                                            individualSafetyObservation?.udFieldsList?.map((item, index) => {
                                                if (item?.value == "true"
                                                ) {
                                                    return <Typography style={{ backgroundColor: "lightgrey", padding: "5px", borderRadius: "5px" }} variant="body2" color="textSecondary">
                                                        {item?.label}
                                                    </Typography>
                                                }
                                            })
                                        }

                                    </span>

                                </CardContent>
                            </Card>
                        </Grid>
                    </div>
                </div>
            </Modal>
            {editModalOpen &&
                <AddSafetyObservationModal
                    isOpen={editModalOpen}
                    onClose={() => setEditModalOpen(false)}
                    individualSafetyObservation={individualSafetyObservation}
                    getSafetyIncidentsList={getSafetyIncidentsList}
                    closeViewObservationModal={onClose}
                />}
        </>
    )
}
