import React, { useState, useEffect } from "react";
import { Container, Stack } from "@mui/material";
import ProfileHeader from "../../components/profile/ProfileHeader";
import AboutSection from "../../components/profile/AboutSection";
import { deleteCompanyTrades, fetchCompanies, fetchCompaniesTrades, fetchCompanyAddress, saveCompanyProduct } from "../../api/CompanyApi";
import { fetchProducts, fetchProjectsForCompany, fetchProjectsManufacturers, saveProducts } from "../../api/ProductsApi";
import { fetchSiteProducts, fetchSitesPictures } from "../../api/SitesApi";
import ListSection from "../../components/profile/ListSection";
import defaultProjectProfileThumbnail from "../../assets/images/project-profile-placeholder-thumbnail.png";
import BuildIcon from "@mui/icons-material/Build";
import {
	LocationOn as MapMarkerIcon,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Loader";
import { fetchUserWorkHistory } from "../../api/UserApi";


/*
Manufacturer Profile Page
	- About
	- Products
	- Sites
	- Companies (coming soon)
*/
export default function ManufacturerProfile(props) {

	const [manufacturerInfo, setManufacturerInfo] = useState({});
	const [activeTab, setActiveTab] = useState("products");
	const [manufacturerTrades, setManufacturerTrades] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isProductEmpty, setIsProductEmpty] = useState(false);
	const [isSiteEmpty, setIsSiteEmpty] = useState(false);
	const history = useHistory();
	const [manufacturerAddress, setManufacturerAddress] = useState([]);
	const [showLoader, setShowLoader] = useState(true);
	const [getUpdatedData, setGetUpdatedData] = useState(false);
	const [workingHistory, setWorkingHistory] = useState([]);
	const [isProjectEmpty, setIsProjectEmpty] = useState(false);

	useEffect(() => {
		const getData = async () => {
			try {
				await getManufactureDetails();
				await getManufacturerAddressList();
				await getManufacturerTradeList();
				await fetchUserWorkHistoryDetails();
				setShowLoader(false);
			} catch (err) {
				setShowLoader(false);
				console.log("Error while recving the manufacture data :: ", err)
			}
		}
		if (props?.manufacturerId) {
			getData();
		}
	}, [props?.manufacturerId])


	// Wrapper function for fetchSiteProducts to pre-process the removal of duplicate sites,
	// and retrieve the first ProjectPicture to be used as the list thumbnail
	async function getManufacturerSites() {
		const res = await fetchSiteProducts({
			FullSearch: "",
			SearchList: [{ manufacturerId: props.manufacturerId }],
			userId: localStorage.userId,
			FetchAllowedRecordsOnly: true
		})

		// Overwrite API response res.data with an updated array where the duplicate items are omitted
		let uniqueSites = [];
		res.data.forEach(project => {
			let alreadyInArray = false;
			uniqueSites.forEach(uniqueProject => {
				if (project.siteId === uniqueProject.siteId) alreadyInArray = true;
			})

			if (!alreadyInArray) uniqueSites.push(project);
		})

		// Retrieve Project pictures and use the first one as the list thumbnail
		// If there is no pictures for a Project, use the default image for Sites
		await Promise.all(uniqueSites.map(project => fetchSitesPictures({ SearchList: [{ SiteId: project.siteId }] })
			.then(res => {
				if (res.length > 0) project.image = res[0].thumbImageUrl;
				else project.image = defaultProjectProfileThumbnail;
			})
		))

		res.data = uniqueSites;
		setIsSiteEmpty(uniqueSites?.length === 0 ? true : false)
		return res;
	}


	const getManufactureDetails = () => {
		fetchCompanies({ SearchList: [{ manufacturer: true, id: props.manufacturerId }] })
			.then((manufacturerRes) => {
				// There should only be 1 returned record
				if (manufacturerRes.data.length >= 0) setManufacturerInfo(manufacturerRes?.data[0])
				// If nothing returned, then there wasn't a record with matching id				
			});
	}

	const fetchUserWorkHistoryDetails = async () => {
		try {
			const workHistoryObj = {
				PageNr: 1,
				FullSearch: "",
				IncludeRecordNr: false,
				SearchList: [{ Id: 0, UserId: Number(localStorage.userId) }],
			};
			const res = await fetchUserWorkHistory(workHistoryObj);
			setWorkingHistory(res);
		} catch (err) {
			console.log("Error while fetching user work history details :: ", err);
		}
	};

	const fetchProjectsForACompany = async () => {
		const obj = {
			PageNr: 1,
			NrOfRecPerPage: 20,
			FullSearch: "",
			UserId: 1,
			IncludeRecordNr: true,
			TypeOfObjectReturned: "",
			FetchAllowedRecordsOnly: false,
			SearchList: [
				{
					companyId: props?.manufacturerId,
				},
			],
			SortList: [{}],
		};
		try {
			setIsLoading(true);
			const res = await fetchProjectsForCompany(obj);
			setIsProjectEmpty(res?.data?.length === 0 ? true : false)
			setIsLoading(false);
			return res;
		} catch (err) {
			setIsLoading(false);
			console.log("error while fetching projects for a company. ", err);
		}
	};

	const getManufacturerTradeList = async () => {
		try {
			const reqObj = {
				PageNr: 1,
				NrOfRecPerPage: 0,
				FullSearch: "",
				UserId: Number(localStorage.userId),
				IncludeRecordNr: true,
				TypeOfObjectReturned: "",
				SearchList: [{ CompanyId: props?.manufacturerId }],
			}
			fetchCompaniesTrades(reqObj)
				.then((companyTrades) => {
					setManufacturerTrades(companyTrades)
				}).catch((err) => {
					console.log("Error while getting trade list ::", err)
				})
		} catch (error) {
			console.log("Error while getting trade list ::", error)
		}
	}

	const getManufacturerAddressList = async () => {
		try {
			const obj = {
				PageNr: 1,
				NrOfRecPerPage: 0,
				FullSearch: "",
				UserId: Number(localStorage.userId),
				IncludeRecordNr: true,
				TypeOfObjectReturned: "",
				SearchList: [{ companyId: props?.manufacturerId }]
			}
			await fetchCompanyAddress(obj).then((res) => {
				setManufacturerAddress(res ? res : [])
			}).catch((err) => {
				console.log("Error while getting comapny address list:-", err)
			})
		} catch (err) {
			console.log("Error while getting comapny address list:-", err)
		}
	}


	const onDataSave = (data) => {
		if (data?.addressSave) {
			getManufacturerAddressList()
		} else {
			getManufactureDetails()
		}
	}

	async function getManufactureProductsData() {
		try {
			const obj = {
				FullSearch: "",
				SearchList: [{ manufacturerId: props.manufacturerId }],
				userId: Number(localStorage.userId),
				TypeOfObjectReturned: '',
				FetchAllowedRecordsOnly: true
			}
			const manfactureProducts = await fetchProducts(obj)
			setIsProductEmpty(manfactureProducts?.data?.length === 0 ? true : false)
			return manfactureProducts;

		} catch (Error) {
			console.log("GET SITE_COMPANIES-2 [ERROR]::", Error)
		}
	}

	async function onProductSave(productId) {
		try {
			setIsLoading(true)
			const obj = {
				UserId: Number(localStorage.userId),
				ReturnRecordId: true,
				ReturnRecordError: true,
				SaveList: [{
					Id: 0,
					ProductId: productId,
					CompanyId: props?.manufacturerId,
					ModifiedBy: Number(localStorage.userId),
					RecordStatusId: 1
				}]
			}
			await saveCompanyProduct(obj);
			setGetUpdatedData(true)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log("SAVE SITE_COMPANIES [ERROR]::", error)
		}
	}

	const onMenuOptionClick = (data) => {
		console.log(":DDD", data)
	}


	const deleteTrade = async (tradeId) => {
		try {
			if (!tradeId) return;
			const deleteObj = {
				UserId: Number(localStorage.userId),
				ReturnRecordError: true,
				SoftDelete: true,
				DeleteList: [{ TradeId: tradeId, CompanyId: props?.manufacturerId }]
			}
			await deleteCompanyTrades(deleteObj).then(async () => {
				await getManufacturerTradeList()
			}).catch((err) => {
				console.log("Error while deleting the trade from the company proifle ::", err)
			})
		} catch (error) {
			console.log("Error while deleting the trade from the company proifle ::", error)
		}
	}

	if (showLoader) {
		return (
			<div className="profile-loader">
				<Loader />
			</div>
		)
	}


	return (
		<div className="page" >
			<div className="page-content">
				<Stack spacing={2} style={{ margin: "16px 0px" }}>
					<ProfileHeader
						title={manufacturerInfo?.name}
						subtitle="Manufacturer"
						tabs={[
							"products",
							"Projects",
						]}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						menuOptions={["Send in Chat", "Share"]}
						onMenuOptionClick={onMenuOptionClick}
						shareDetails={{ type: "ManufacturerInfo", id: props?.manufacturerId }}
						from="Manufacturer"
					/>

					<Stack direction="row" spacing={2}>
						<div style={{ flex: 1 }}>
							<AboutSection
								withCard
								content={{
									website: manufacturerInfo?.webSite,
									phone: manufacturerInfo?.phone,
									formattedAddress: {
										address: manufacturerInfo?.address,
										city: manufacturerInfo?.city,
										province: manufacturerInfo?.province,
										postalCode: manufacturerInfo?.postalCode,
										country: manufacturerInfo?.country
									}
								}}
								multipleAddress={manufacturerAddress}
								Id={manufacturerInfo?.id}
								name={manufacturerInfo?.name}
								trades={manufacturerTrades}
								manufacturer_and_company_id={manufacturerInfo?.id ?? ""}
								onDataSave={onDataSave}
								deleteTrade={deleteTrade}
								aboutSectionType={"manufacturer"}
							/>
						</div>

						<div style={{ flex: 2 }}>
							{
								(activeTab === "products")
									? (
										<ListSection
											getData={getManufactureProductsData}
											itemIdKey="id"
											itemNameKey="name"
											itemRecordType="product"
											searchPlaceholder="Search this Manufacturer's Products"
											searchTopDescription={"The Products that this Manufacturer has manufactured"}
											addButtonLabel="Add Product"
											modalTitle="Add Product to Manufacturer"
											addItemLabel="Product Name"
											categoryRecordType="manufacturer"
											listSectionType="manufacturer"
											manufacturerInfo={{
												manufacturerId: props?.manufacturerId,
												manufacturerName: manufacturerInfo?.name ?? ""
											}}
											onProductSave={onProductSave}
											getUpdatedData={getUpdatedData}
											changeGetUpdateDataValue={() => { setGetUpdatedData(false) }}
											emptyData={isProductEmpty}
											NoResultBtn="Add Product"
											NOResultIcon={<BuildIcon />}
											NoResultText="No information for this manufacturer."
											NoResultText2="Know any information? Add it here for builderbucks"
										/>
									)
									: <></>
							}

							{/* {
								(activeTab === "sites")
									? (
										<ListSection
											getData={getManufacturerSites}
											itemIdKey="siteId"
											itemImgKey="image"
											itemNameKey="siteName"
											itemSecondaryKey="address"
											itemRecordType="site"
											searchPlaceholder="Search this Manufacturer's Sites"
											searchTopDescription='The Sites that the Manufacturer products have been used at'
											buttonLabel="View on map"
											onButtonClick={() => {
												navigate.push('/sites');
											}}
											emptyData={isSiteEmpty}
											NOResultIcon={<MapMarkerIcon />}
											NoResultText="No information for this manufacturer."
											NoResultText2="Know any information? Add it here for builderbucks"
										/>
									)
									: <></>
							} */}

							{activeTab === "Projects" ? (
								<ListSection
									getData={fetchProjectsForACompany}
									itemIdKey="projectId"
									itemImgKey="thumbUrl"
									itemNameKey="projectName"
									// itemSecondaryKey="projectAddress"
									itemRecordType="projects"
									searchPlaceholder="Search this Manufacturer's Projects"
									searchTopDescription="The Projects that this Manufacturers products have been used at"
									buttonLabel="Add Project"
									// companySites
									workingHistory={workingHistory}
									// onButtonClick={() => {
									// 	navigate.push("/projects/project");
									// }}
									onButtonClick={() => {
										history.push({
											pathname: "/new-map",
											state: {
												from: "manufactureProfile",
												selectedManufacturer: manufacturerInfo
											}
										})
									}}
									emptyData={isProjectEmpty}
									NOResultIcon={<MapMarkerIcon />}
									NoResultText="No information for this Manufacturer."
									NoResultText2="Know any information? Add it here for builderbucks"
								/>
							) : (
								<></>
							)}
						</div>
					</Stack>
				</Stack>
			</div>
		</div>
	)
}
