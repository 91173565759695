import React, { useState } from "react";
import { useHistory } from "react-router-dom"; // Import useHistory
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { MoreVert as MoreIcon } from "@mui/icons-material";
import { BIRed } from "../../assets/buildidColors";
import ReportModal from "../../components/ReportModal";
import { saveChatGroupUsers } from "../../api/ChatApi";

const ContactList = (props) => {
  const history = useHistory();

  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [rideShareId, setRideShareId] = useState(0);

  const handleReportTest = (uID) => {
    setMoreMenuAnchorEl(false);
    setRideShareId(uID);
    setReportModalOpen(true);
  };

  const handleProfileNavigation = () => {
    history.push(`/user-dashboard-profile/${props.userId}`);
  };

  const navigateTochat = async (e, userId) => {
    e.preventDefault();
    console.log("fff", userId);
    const saveListArray = [
      {
        Id: 0,
        ChatGroupId: 0,
        UserId: userId,
        ModifiedBy: Number(localStorage.getItem("userId")),
        Muted: false,
      },
    ];
    const chatGroupObj = {
      UserId: Number(localStorage.getItem("userId")),
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: saveListArray,
    };
    let result = await saveChatGroupUsers(chatGroupObj);
    if (result.data.length > 0) {
      let chatGroupId = result.data[0]?.objData.chatGroupId;
      history.push({
        pathname: "/chat",
        state: {
          from: "New Chat",
          chatGroupId: chatGroupId,
          showInList: true
        },
      });
    }
  };
  return (
    <>
      <ReportModal
        isOpen={reportModalOpen}
        onClose={() => {
          setReportModalOpen(false);
          setMoreMenuAnchorEl(false);
        }}
        name={"Ride Share"}
        recordId={rideShareId}
      />

      <ListItem
        secondaryAction={
          <>
            <IconButton
              onClick={(event) => setMoreMenuAnchorEl(event.currentTarget)}>
              <MoreIcon />
            </IconButton>
            <Menu
              anchorEl={moreMenuAnchorEl}
              open={Boolean(moreMenuAnchorEl)}
              onClose={() => setMoreMenuAnchorEl(false)}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}>
              <MenuItem
                onClick={(e) => navigateTochat(e, Number(props?.userId))}
                dense>
                Message
              </MenuItem>
              <MenuItem onClick={handleProfileNavigation} dense>
                Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleReportTest(props.userId);
                }}
                style={{ color: BIRed }}
                dense>
                Report
              </MenuItem>
            </Menu>
          </>
        }
        key={props.itemIndex}
        disablePadding>
        <ListItemButton onClick={handleProfileNavigation}>
          {
            <Avatar
              variant="rounded"
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                marginRight: "15px",
              }}
              src={props.pictureUrl}></Avatar>
          }
          <ListItemText
            primary={props.firstName}
            secondary={
              <>
                <span>{props.userTrade}</span>
                <br />
                <span>{`${props.distance} km away`}</span>
              </>
            }
          />
        </ListItemButton>
      </ListItem>
    </>
  );
};

export default ContactList;
