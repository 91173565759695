import { Container, Grid, Stack } from "@mui/material";
import BuildIcon from "@mui/icons-material/Build";
import { useEffect, useState } from "react";
import { fetchCompaniesTradesForWeb, fetchCompanyAddressForWeb, getCompaniesDetails, getManufacturerDetails } from "../../api/CompanyApi";
import { fetchProductsPostsPicturesForWeb, fetchTradeProductsForWeb, getProductDetails } from "../../api/ProductsApi";
import { fetchSiteNamesForWeb, fetchSitesForWeb, fetchSitesPostsPicturesForWeb, getSiteDetails } from "../../api/SitesApi";
import { fetchRelatedTradesForWeb, fetchTradeNamesForWeb, getTradeDetails } from "../../api/TradesApi";
import AboutSection from "../../components/profile/AboutSection";
import ProfileHeader from "../../components/profile/ProfileHeader";
import SignInCard from "./SignInCard";
import Placeholder from "../../components/Placeholder";
import { fetchProjectsPostsPicturesForWeb, getProjectDetails } from "../../api/ProjectsApi";
import { async } from "q";

const ProxySite = (props) => {
    const [activeTab, setActiveTab] = useState("");
    const [pageName, setPageName] = useState("");
    const [proxySiteData, setproxySiteData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tabArr, setTabArr] = useState([]);
    const [otherTradeNameList, setOtherTradeNameList] = useState([]);
    const [relatedTrades, setRelatedTrades] = useState([]);
    const [tradeList, setTradeList] = useState([]);
    const [siteType, setSiteType] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [companyAddressList, setCompanyAddressList] = useState([]);
    const [manufacturerAddressList, setManufacturerAddressList] = useState([]);
    const [imageLoading, setImageLoading] = useState(false)
    var isAndroid = /Android/i.test(navigator.userAgent);
    var isIphone = /iPhone/i.test(navigator.userAgent);
    var isIpad = /iPad/i.test(navigator.userAgent);
    const pageNameArr = window.location.pathname.split('/');

    const detailsObj = {
        screenName: props?.screenName,
        itemId: props?.itemId
    }


    const getRelatedTrades = async () => {
        try {
            const relatedTradesRes = await fetchRelatedTradesForWeb({ SearchList: [{ tradeId: props?.itemId }] });
            setRelatedTrades(relatedTradesRes?.data)
        } catch (err) {
            console.log("Error while getting get Related Trades list:-", err)
        }
    }


    const getOtherTradeNameList = async () => {
        try {
            await fetchTradeNamesForWeb({ SearchList: [{ TradeId: props?.itemId }] }).then((res) => {
                setOtherTradeNameList(res?.data ?? [])
            }).catch((err) => {
                console.log("Error while getting trade name list:-", err)
            })
        } catch (err) {
            console.log("Error while getting site trade list:-", err)
        }
    }

    const getCompanyTrades = () => {
        fetchCompaniesTradesForWeb({ SearchList: [{ CompanyId: props?.itemId }] })
            .then((companyTrades) => {
                setTradeList(companyTrades)
            });
    }

    const productTradesList = () => {
        fetchTradeProductsForWeb({ SearchList: [{ ProductId: props?.itemId }] }).then((res) => {
            setTradeList(res?.data ?? [])
        }).catch((err) => {
            console.log("Error while getting the product trades list:-", err)
        })
    }

    const getSiteType = async () => {
        const siteRes = await fetchSitesForWeb({ SearchList: [{ Id: props?.itemId }] })	// Clear dropped pin, if there is one
        setSiteType(siteRes?.data[0]?.buildingTypeName);
    }

    const getOtherSiteNameList = async () => {
        try {
            fetchSiteNamesForWeb({ SearchList: [{ SiteId: props?.itemId }] }).then((res) => {
                setOtherTradeNameList(res?.data ?? [])
            }).catch((err) => {
                console.log("Error while getting site name list:-", err)
            })
        } catch (err) {
            console.log("Error while getting site name list:-", err)
        }
    }

    const getSitePicturesList = () => {
        const postPicObj = {
            SearchList: [{ appRecordId: Number(props?.itemId) }],
            SortList: [{ FieldName: 'Id', Direction: 'DESC' }]
        };
        fetchSitesPostsPicturesForWeb(postPicObj).then((res) => {
            setImageUrl(res[0]?.pictureUrl)
        }).catch((err) => {
            console.log("Error while getting site images:-", err)
        })
    }

    const getManufacturerTradesList = () => {
        fetchCompaniesTradesForWeb({ SearchList: [{ CompanyId: props?.itemId }] })
            .then((trades) => {
                setTradeList(trades)
            });
    }

    function openInPhone(param) {
        if (isAndroid) {
            const url = window.location.pathname.includes("TradeDetail") ?
                `intent://?screenName=${props?.screenName}&id=${props?.itemId}&pictureUrl=${param}#Intent;scheme=mybuilderid;package=com.mybuilderid;end`
                : window.location.pathname.includes("ProductInfo") ?
                    `intent://?screenName=${props?.screenName}&id=${props?.itemId}&name=${param}#Intent;scheme=mybuilderid;package=com.mybuilderid;end`
                    : `intent://?screenName=${props?.screenName}&id=${props?.itemId}#Intent;scheme=mybuilderid;package=com.mybuilderid;end`
            window.location.replace(url);
        } else if (isIphone || isIpad) {
            const iPhoneUrl = window.location.pathname.includes("TradeDetail") ?
                `mybuilderid://?screenName=${props?.screenName}&id=${props?.itemId}&pictureUrl=${param}`
                : window.location.pathname.includes("ProductInfo") ?
                    `mybuilderid://?screenName=${props?.screenName}&id=${props?.itemId}&name=${param}`
                    : `mybuilderid://?screenName=${props?.screenName}&id=${props?.itemId}`
            window.location.replace(iPhoneUrl);
        }
    }

    const getCompanyAddressList = async () => {
        try {
            await fetchCompanyAddressForWeb({ SearchList: [{ companyId: props?.itemId }] }).then((res) => {
                setCompanyAddressList(res ? res : [])
            }).catch((err) => {
                console.log("Error while getting comapny address list:-", err)
            })
        } catch (err) {
            console.log("Error while getting comapny address list:-", err)
        }
    }

    const getManufacturerAddressList = async () => {
        try {
            await fetchCompanyAddressForWeb({ SearchList: [{ companyId: props?.itemId }] }).then((res) => {
                setManufacturerAddressList(res ? res : [])
            }).catch((err) => {
                console.log("Error while getting comapny address list:-", err)
            })
        } catch (err) {
            console.log("Error while getting comapny address list:-", err)
        }
    }


    //pradip 442024
    const getProjectCoverPicture = async () => {
        try {
            const obj = {
                SearchList: [{ appRecordId: Number(props?.itemId) }],
                SortList: [{ FieldName: "Id", Direction: "DESC" }]
            }
            const fetchProjectsPostsPicturesRes = await fetchProjectsPostsPicturesForWeb(obj);
            setImageUrl(fetchProjectsPostsPicturesRes[0]?.pictureUrl || "")
        } catch (err) {
            console.log("Error while getting getProjectCoverPicture:-", err)
        }
    }
    // end

    const renderProfileHeader = (page) => {
        switch (String(page)) {
            case "Site": return (
                <ProfileHeader
                    proxySite
                    images={[]}
                    imageUrl={imageUrl}
                    title={proxySiteData && proxySiteData?.name}
                    subtitle={pageName}
                    tabs={tabArr}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            )
            case "Company": return (
                <ProfileHeader
                    proxySite
                    title={proxySiteData && proxySiteData?.name}
                    subtitle={pageName}
                    tabs={tabArr}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            )
            case "Manufacturer": return (
                <ProfileHeader
                    proxySite
                    title={proxySiteData && proxySiteData?.name}
                    subtitle={pageName}
                    tabs={tabArr}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            )
            case "Product": return (
                <ProfileHeader
                    proxySite
                    images={[]}
                    imageUrl={imageUrl}
                    title={proxySiteData && proxySiteData?.name}
                    subtitle={pageName}
                    tabs={tabArr}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />

            )
            case "Trade": return (
                <ProfileHeader
                    proxySite
                    images={[]}
                    // images={[{ imageUrl: imageUrl }]}
                    imageUrl={imageUrl}
                    title={proxySiteData && proxySiteData?.name}
                    subtitle={pageName}
                    tabs={tabArr}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    imageLoading={imageLoading}
                />
            )
            // pradip 442024
            case "Project": return (
                <ProfileHeader
                    proxySite
                    images={[]}
                    imageUrl={imageUrl}
                    members={[]}
                    title={proxySiteData?.projectName || ""}
                    subtitle={pageName}
                    tabs={tabArr}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            )
            // end
            default: return (<></>)
        }
    }


    const renderAboutSection = (pageName) => {
        switch (String(pageName)) {
            case "Site": return (
                <AboutSection
                    withCard
                    proxySite
                    content={{
                        formattedAddress: {
                            address: proxySiteData?.address || "",
                            city: proxySiteData?.city || "",
                            province: proxySiteData?.province || "",
                            postalCode: proxySiteData?.postalCode || "",
                            country: proxySiteData?.country || ""
                        },
                        siteType: siteType,
                        website: proxySiteData?.webSite,
                        siteTimeframe: {
                            startDate: proxySiteData?.startDate,
                            endDate: proxySiteData?.endDate,
                            constructionStatusId: proxySiteData?.constructionStatusId
                        },
                    }}
                    otherNameList={otherTradeNameList}
                />
            )
            case "Company": return (
                <>
                    {proxySiteData?.webSite !== "" || companyAddressList.length > 0 || tradeList.length > 0 || proxySiteData?.phone !== ""
                        || proxySiteData?.address !== "" || proxySiteData?.city !== "" || proxySiteData?.province !== "" || proxySiteData?.country !== "" ?
                        <AboutSection
                            withCard
                            proxySite
                            content={{
                                website: proxySiteData?.webSite,
                                phone: proxySiteData?.phone,
                                formattedAddress: {
                                    address: proxySiteData?.address || "",
                                    city: proxySiteData?.city || "",
                                    province: proxySiteData?.province || "",
                                    postalCode: proxySiteData?.postalCode || "",
                                    country: proxySiteData?.country || ""
                                }
                            }}
                            multipleAddress={companyAddressList}
                            trades={tradeList}
                            name={proxySiteData && proxySiteData?.name} /> : (
                            <Placeholder
                                text="No information about this Company."
                            />
                        )
                    }
                </>
            )
            case "Manufacturer": return (
                <AboutSection
                    withCard
                    proxySite
                    content={{
                        website: proxySiteData?.webSite,
                        phone: proxySiteData?.phone,
                        formattedAddress: {
                            address: proxySiteData?.address || "",
                            city: proxySiteData?.city || "",
                            province: proxySiteData?.province || "",
                            postalCode: proxySiteData?.postalCode || "",
                            country: proxySiteData?.country || ""
                        },
                    }
                    }
                    multipleAddress={manufacturerAddressList}
                    trades={tradeList}
                    name={proxySiteData && proxySiteData?.name
                    } />
            )
            case "Product": return (
                <>
                    <AboutSection
                        withCard
                        proxySite
                        content={{
                            manufacturer: proxySiteData?.manufacturerName || "",  //for product tab
                            website: proxySiteData?.webSite || proxySiteData?.productWebSite || "",
                            description: proxySiteData?.info || "",
                        }}
                        trades={tradeList}
                    />
                </>
            )
            case "Trade": return (
                <>
                    {proxySiteData?.description !== "" || relatedTrades.length > 0 || otherTradeNameList.length > 0 || tradeList.length > 0 ?
                        <AboutSection
                            withCard
                            proxySite
                            from="TradeProxyPage"
                            trades={tradeList}
                            relatedTrades={relatedTrades} //new list
                            otherNameList={otherTradeNameList}
                            tradeAboutSection
                            content={isLoading ? "" : {
                                description: proxySiteData?.description || ""
                            }} /> : (
                            <Placeholder
                                icon={<BuildIcon style={{ height: "100px", width: "100px" }} />}
                                text="No information about this Trade."
                            />
                        )
                    }
                </>
            )
            case "Project": return (
                <AboutSection
                    withCard
                    proxySite
                    content={{
                        description: proxySiteData?.description ?? "",
                        projectTypeName: proxySiteData?.projectTypeName,
                        projectTimeFrame: {
                            startDate: proxySiteData?.startDate ?? null,
                            endDate: proxySiteData?.endDate ?? null,
                        },
                        website: proxySiteData?.projectWebsite ?? "",
                    }}
                    aboutSectionType={"project"}
                    // onDataSave={onDataSave}
                    projectDetails={proxySiteData ?? {}}
                />
            )
            default: return (<></>)
        }
    }


    useEffect(() => {
        async function getData(page) {
            switch (String(page)) {
                case "TradeDetail": try {
                    setTabArr(["posts", "companies", "products", "sites"]);
                    setActiveTab("posts");
                    setPageName("Trade");
                    setIsLoading(true);
                    setImageLoading(true);
                    const tradeDetailData = await getTradeDetails(props?.itemId);
                    setproxySiteData(tradeDetailData[0]);
                    setImageUrl(tradeDetailData[0]?.tradePictureUrl)
                    getOtherTradeNameList();
                    getRelatedTrades();
                    setImageLoading(false);
                    setIsLoading(false);
                    openInPhone(tradeDetailData[0]?.tradePictureUrl);
                } catch (err) {
                    console.warn(err);
                }
                    break;
                case "CompanyInfo": try {
                    setTabArr(["sites", "products"]);
                    setActiveTab("sites");
                    setPageName("Company");
                    setIsLoading(true);
                    const companyInfoData = await getCompaniesDetails(props?.itemId);
                    setproxySiteData(companyInfoData[0]);
                    getCompanyTrades();
                    getCompanyAddressList();
                    setIsLoading(false);
                    openInPhone();
                } catch (err) {
                    console.warn(err);
                }
                    break;
                case "ProductInfo": try {
                    setTabArr(["posts", "photos", "reviews", "sites", "companies"]);
                    setActiveTab("posts");
                    setPageName("Product");
                    setIsLoading(true);
                    setImageLoading(true);
                    const productInfoData = await getProductDetails(props?.itemId);
                    setproxySiteData(productInfoData[0]);
                    const productPicture = await fetchProductsPostsPicturesForWeb({ SearchList: [{ appRecordId: props?.itemId }], SortList: [{ FieldName: 'Id', Direction: 'DESC' }] })
                    setImageUrl(productPicture.data[0]?.pictureUrl ?? "");
                    productTradesList();
                    setImageLoading(false);
                    setIsLoading(false);
                    openInPhone(productInfoData[0]?.name);
                } catch (err) {
                    console.warn(err);
                }
                    break;
                case "SiteDetail": try {
                    setTabArr(["posts", "photos", "companies", "products", "projects"]);
                    setActiveTab("posts");
                    setPageName("Site");
                    setIsLoading(true);
                    const siteDetailData = await getSiteDetails(props?.itemId);
                    setproxySiteData(siteDetailData[0]);
                    getSitePicturesList();
                    getSiteType();
                    getOtherSiteNameList();
                    setIsLoading(false);
                    openInPhone();
                } catch (err) {
                    console.warn(err);
                }
                    break;
                case "ManufacturerInfo": try {
                    setTabArr(["products", "sites"]);
                    setActiveTab("products");
                    setPageName("Manufacturer");
                    setIsLoading(true);
                    const manufacturerInfoData = await getManufacturerDetails(props?.itemId);
                    setproxySiteData(manufacturerInfoData[0]);
                    getManufacturerTradesList();
                    getManufacturerAddressList();
                    setIsLoading(false);
                    openInPhone();
                } catch (err) {
                    console.warn(err);
                }
                    break;
                case "ProjectPage": try {
                    setTabArr(["posts", "photos", "companies", "products"]);
                    setActiveTab("posts");
                    setPageName("Project");
                    setIsLoading(true);
                    const projectDetailData = await getProjectDetails({ SearchList: [{ Id: Number(props?.itemId) }] });
                    setproxySiteData(projectDetailData[0]);
                    getProjectCoverPicture();
                    setIsLoading(false);
                    openInPhone();
                } catch (err) {
                    console.warn(err);
                }
                    break;
            }
        }

        switch (String(pageNameArr[2])) {
            case "TradeDetail": getData("TradeDetail");
                break;
            case "CompanyInfo": getData("CompanyInfo");
                break;
            case "ProductInfo": getData("ProductInfo");
                break;
            case "SiteDetail": getData("SiteDetail");
                break;
            case "ManufacturerInfo": getData("ManufacturerInfo");
                break;
            case "ProjectPage": getData("ProjectPage");
                break;
        }

        return () => {
            setproxySiteData([]);
            setActiveTab("");
            setIsLoading(false);
            setImageUrl("");
        }
    }, [])

    return (
        <div className="page" style={{ alignItems: "center" }}>
            <Container>
                <Stack spacing={2} style={{ marginBottom: "16px" }}>
                    {renderProfileHeader(pageName)}
                    <Grid container >
                        <Grid container spacing={2}>
                            {/* AboutSection */}
                            <Grid item xs={12} md={4}>
                                {renderAboutSection(pageName)}
                            </Grid>

                            {/* Content based on activeTab */}
                            <Grid item xs={12} md={8}>
                                {activeTab === "posts" && (
                                    <Stack spacing={2}>
                                        <SignInCard detailsObj={detailsObj} />
                                    </Stack>
                                )}

                                {activeTab === "sites" && (
                                    <Stack spacing={2}>
                                        <SignInCard detailsObj={detailsObj} />
                                    </Stack>
                                )}

                                {activeTab === "reviews" && (
                                    <Stack spacing={2}>
                                        <SignInCard detailsObj={detailsObj} />
                                    </Stack>
                                )}

                                {activeTab === "photos" && (
                                    <Stack spacing={2}>
                                        <SignInCard detailsObj={detailsObj} />
                                    </Stack>
                                )}

                                {activeTab === "companies" && (
                                    <Stack spacing={2}>
                                        <SignInCard detailsObj={detailsObj} />
                                    </Stack>
                                )}

                                {activeTab === "products" && (
                                    <Stack spacing={2}>
                                        <SignInCard detailsObj={detailsObj} />
                                    </Stack>
                                )}

                                {activeTab === "projects" && (
                                    <Stack spacing={2}>
                                        <SignInCard detailsObj={detailsObj} />
                                    </Stack>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Stack>
            </Container>
        </div>
    );
};

export default ProxySite;
