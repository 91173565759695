import { Button, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, MenuItem, Modal, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import "../../../assets/css/Addexpenses.css";
import { Close as CloseIcon } from "@material-ui/icons";
import SearchIcon from '@mui/icons-material/Search';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HelpIcon from '@mui/icons-material/Help';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { fetchClientexpenses, fetchClientexpensescodes, fetchClientjobs, saveClientexpenses, saveExpensesReceipt } from "../../../api/ClientApi";
import moment from "moment";
import Clientjobs from "./Clientjobs";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/Loader";

export default function Addexpenses(props) {

    const [codelist, setCodelist] = useState([]);
    const [showSubtotal, setShowsubtotal] = useState(false);
    const [showJobs, setShowjobs] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const history = useHistory();
    const [loader,setLoader]=useState(false)
    const [addExpense, setAddexpense] = useState({
        id: 0,
        dateSubmitted: new Date(),
        title: "",
        clientExpenseCodeId: "",
        clientExpenseCode: "",
        clientEmployeeId: "",
        clientJobCodeId: "",
        clientJobCodeData:'',
        milage: 0,
        milageUnit: "km",
        subTotal: 0,
        tax: 0,
        tip: 0,
        total: 0,
        paidWith: 1,
        paidWithName: '',
        description: "",
        status: 1,
        receiptUrl: "",
        receiptObj: "",
    });


    useEffect(() => {
        console.log('from -----------',props.for)
        if(props.for){
            fetchExpenses(props.id)
        }
        fetchCodes();
    }, [])

    const fetchExpenses = async (id) => {
        setLoader(true)
        let obj = {
            PageNr: 1,
            NrOfRecPerPage: 20,
            FullSearch: "",
            UserId: Number(localStorage?.userId),
            IncludeRecordNr: true,
            TypeOfObjectReturned: "",
            FetchAllowedRecordsOnly: false,
            SearchList: [{ id: id }],
            SortList: [
                {
                    FieldName: "Id",
                    Direction: "DESC"
                }
            ]
        };
    
        let getExpenses = await fetchClientexpenses(obj);
        let item = getExpenses?.data[0];
    
        if (item) {
            console.log('expenses for particular id is-----', item);
    
            await setAddexpense((prevAddExpense) => ({
                ...prevAddExpense,
                id: item.id,
                clientEmployeeId: item.clientEmployeeId,
                title: item.title,
                clientJobCodeId: item.clientJobCodeId,
                clientExpenseCodeId: item.clientExpenseCodeId,
                clientExpenseCode: item.clientExpenseCode,
                milage: item.mileage,
                milageUnit: item.mileageUnit,
                subTotal: item.subTotal,
                tax: item.tax,
                tip: item.tip,
                total: item.total,
                paidWithName: item.paidWith,
                paidWith: item.paidWith === 'Paid personally' ? 1 : 2,
                description: item.description,
                status: item.status,
                dateSubmitted: item.dateCreated,
                receiptObj: item.expenseReceiptList?.[0],
                receiptUrl: item.expenseReceiptList?.[0]?.thumbFileUrl,
            }));
    
            if (id) {
                let data = await getCodeData(item.clientJobCodeId);
                setAddexpense((prevAddExpense) => ({
                    ...prevAddExpense,
                    clientJobCodeData: data,
                }));
            }
        setLoader(false)
        } else {
            console.warn('No expenses found for the given ID:', id);
            setLoader(false)
        }
    };
    
    const getCodeData = async (jobId) => {
        let obj = {
            PageNr: 1,
            NrOfRecPerPage: 20,
            FullSearch: "",
            UserId: Number(localStorage?.userId),
            IncludeRecordNr: true,
            FetchAllowedRecordsOnly: false,
            SearchList: [
                {
                    Id: jobId
                }
            ]
        };
        let getResponse = await fetchClientjobs(obj);
        return getResponse?.data[0];
    };
    

    const fetchCodes = async () => {
        let obj = {
            PageNr: 1,
            NrOfRecPerPage: 20,
            FullSearch: "",
            UserId: Number(localStorage?.userId),
            IncludeRecordNr: true,
            FetchAllowedRecordsOnly: false,
            SearchList: [
                {
                    ClientEmployeeId : props?.clientEmployeeId ? props?.clientEmployeeId : 1
                }
            ]
        }
        let getExpensescode = await fetchClientexpensescodes(obj);
        setCodelist(getExpensescode?.data);
    }


    const onDatechange = (e) => {
        setAddexpense({
            ...addExpense,
            dateSubmitted: e
        })
    }

    const onFieldChange = (e) => {
        setAddexpense({
            ...addExpense,
            [e.target.name]: e.target.value
        })
    }

    const onCodechange = (e) => {
        setAddexpense({
            ...addExpense,
            clientExpenseCodeId: e?.target?.value,
            clientExpenseCode: e?.target?.value
        })
    };


    const closeAddexpenses = () => {
        props.setShowadd(false);
    }

    const onRadiogroupchange = (e) => {
        setAddexpense({
            ...addExpense,
            paidWith: Number(e.target.value)
        })
    }

    const onJoblistopen = () => {
        setShowjobs(true);
    }


    const onSubtotal = () => {
        if (showSubtotal) {
            setShowsubtotal(false);
        } else {
            setShowsubtotal(true);
        }
    }


    const clearJob = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAddexpense({
            ...addExpense,
            clientJobCodeId: ""
        })
    }

    const uploadDocument = () => {
        document.getElementById("receipt-img").click();
    }

    const onImagechange = (e) => {
        let getUrl = URL.createObjectURL(e.target.files[0]);
        setAddexpense({
            ...addExpense,
            receiptUrl: getUrl,
            receiptObj: e.target.files[0]
        })
    }

    const addExpenses = async () => {
        if(addExpense.clientExpenseCodeId===""||addExpense.clientExpenseCodeId===""){
            setInvalid(true);
            return;
        }
        setLoader(true)
        let request = {
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
                {
                    Id: addExpense?.id,
                    ClientEmployeeId: localStorage.getItem("clientEmployeeId"),
                    DateSubmitted: moment(addExpense?.dateSubmitted).format("YYYY/MM/DD"),
                    Title: addExpense?.title,
                    ClientExpenseCodeId: addExpense?.clientExpenseCodeId,
                    ClientJobCodeId: addExpense?.clientJobCodeId?.id,
                    Mileage: Number(addExpense?.milage),
                    MileageUnit: addExpense?.milageUnit,
                    SubTotal: addExpense?.subTotal,
                    Tax: addExpense?.tax,
                    Tip: addExpense?.tip,
                    Total: Number(addExpense?.total),
                    PaidWith: addExpense?.paidWith,
                    Description: addExpense?.description,
                    Status: 1,
                    ModifiedBy: Number(localStorage?.userId),
                }]
        }
        let getRepsonse = await saveClientexpenses(request);
        if (getRepsonse?.data?.length > 0) {
            const formData = new FormData();
            formData.append('Id', 0);
            formData.append('ClientExpenseId', getRepsonse?.data[0]?.Id);
            formData.append('PictureUrl', addExpense?.receiptObj);
            formData.append('PictureDateTime', moment(new Date()).format("YYYY-MM-DD"));
            formData.append('ModifiedBy', Number(localStorage?.userId));
            await saveExpensesReceipt(formData);
        }
        setLoader(false)
        props.setShowadd(false);
        history.push("/client-expenses")
    }

    const clearImage = () => {
        setAddexpense((prevAddExpense) => ({
            ...prevAddExpense,
            receiptUrl: ""
        }));
    };
    

    console.log('paid with--------',addExpense)
    return (
        <>
            <Modal
             open={props?.showAdd}
             onClose={async () => closeAddexpenses()}>
                {
                    loader?(
                        <Loader/>
                    ):(
                        <div className="modal-center add-expenses" style={{ maxHeight: "760px" }}>
                    <div className="addexpenses-form">
                        <div className="form-header">
                            <Typography variant="h6" component="label" className="heading">
                                New Expenses
                            </Typography>
                            <IconButton
                                color="primary"
                                onClick={async () => closeAddexpenses()}
                                className="close-btn">
                                <CloseIcon style={{ color: "#fffff" }} />
                            </IconButton>
                        </div>
                        <div className="form-content">
                            <div>
                                <Typography variant="h6" component="label" className="form-label" htmlFor="my-input">
                                    Date
                                </Typography>
                                <KeyboardDatePicker
                                    className="form-field"
                                    variant="inline"
                                    inputVariant={"outlined"}
                                    placeholder="MM/DD/YYYY"
                                    format="MM/DD/YYYY"
                                    onChange={onDatechange}
                                    value={addExpense?.dateSubmitted}
                                    fullWidth
                                    size="small"
                                    autoOk={true}
                                />
                            </div>
                            <div style={{ marginTop: "13px" }}>
                                <Typography variant="h6" component="label" className="form-label" htmlFor="my-input">
                                    Title
                                </Typography>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    placeholder="Enter a title"
                                    fullWidth
                                    value={addExpense?.title}
                                    onChange={onFieldChange}
                                    name="title"
                                    className="form-field"
                                    size="small"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: 'none',
                                            },
                                        },
                                    }}
                                />
                            </div>
                            <div style={{ marginTop: "13px" }}>
                                <Typography variant="h6" component="label" className="form-label" htmlFor="my-input">
                                    Code
                                </Typography>
                                <Select
                                    placeholder="Select a Code"
                                    value={addExpense?.clientExpenseCodeId || ""}
                                    onChange={onCodechange}
                                    onClick={() => setInvalid(false)}
                                    fullWidth
                                    size="small"
                                    className="form-field"
                                >
                                    {codelist?.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {invalid === true && (
                                    <span style={{ color: 'red' }}>
                                        Please select a code
                                    </span>
                                )}
                            </div>

                            {addExpense?.clientExpenseCode !== "" && (
                                <>
                                    <div style={{ marginTop: "13px" }}>
                                        <Typography variant="h6" component="label" className="form-label" htmlFor="my-input">
                                            Job
                                        </Typography>
                                        <TextField
                                            onClick={onJoblistopen}
                                            placeholder={"Search for a Job"}
                                            value={addExpense?.clientJobCodeData?.name?addExpense?.clientJobCodeData?.name:addExpense?.clientJobCodeId?.name}
                                            className="form-field"
                                            size="small"
                                            color="primary"
                                            fullWidth
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {addExpense.clientJobCodeData?.name && (
                                                            <CloseIcon style={{ cursor: "pointer" }} onClick={(e) => clearJob(e)} />
                                                        )}
                                                    </InputAdornment>
                                                ),
                                                readOnly: true
                                            }} 
                                        />
                                    </div>
                                    <div style={{ marginTop: "13px" }}>
                                        <Typography variant="h6" component="label" className="form-label" htmlFor="my-input">
                                            Mileage
                                        </Typography>
                                        <div style={{ display: "flex", gap: "12px" }}>
                                            <TextField
                                                type="number"
                                                variant="outlined"
                                                placeholder="Enter mileage"
                                                fullWidth
                                                value={addExpense?.milage}
                                                onChange={onFieldChange}
                                                name="milage"
                                                className="form-field"
                                                size="small"
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: 'none',
                                                        },
                                                    },
                                                }}
                                            />
                                            <Select
                                                placeholder="Select a Code"
                                                value={"km"}
                                                onChange={onCodechange}
                                                size="small"
                                                className="form-field"
                                            >
                                                <MenuItem value={"km"}>{"km"}</MenuItem>
                                                <MenuItem value={"mi"}>{"mi"}</MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div style={{ marginTop: "13px" }}>
                                <Typography variant="h6" component="label" className="form-label" htmlFor="my-input">
                                    Amount
                                </Typography>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <TextField
                                        placeholder={"0"}
                                        className="form-field"
                                        value={addExpense?.total}
                                        onChange={onFieldChange}
                                        name="total"
                                        size="small"
                                        type="number"
                                        color="primary"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><AttachMoneyIcon style={{ color: "rgb(176 67 62)" }} /></InputAdornment>,
                                        }} />
                                    {showSubtotal ? (
                                        <ArrowDropDownIcon style={{ marginTop: "10px", color: "rgb(176 67 62)", fontSize: "35px", cursor: "pointer" }} onClick={onSubtotal} />
                                    ) : (
                                        <ArrowDropUpIcon style={{ marginTop: "10px", color: "rgb(176 67 62)", fontSize: "35px", cursor: "pointer" }} onClick={onSubtotal} />
                                    )}
                                </div>
                            </div>
                            {showSubtotal === true && (
                                    <>
                                        <div style={{ marginTop: "13px", display: "flex" }}>
                                            <Grid container alignItems={"center"}>
                                                <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingRight: "14px" }}>
                                                    <Typography variant="h6" component="label" className="form-label" htmlFor="my-input">
                                                        Tax (optional)
                                                    </Typography>
                                                    <TextField
                                                        placeholder={"0"}
                                                        className="form-field"
                                                        size="small"
                                                        color="primary"
                                                        value={addExpense?.tax}
                                                        onChange={onFieldChange}
                                                        type="number"
                                                        name="tax"
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"><AttachMoneyIcon /></InputAdornment>,
                                                        }} />
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                                    <Typography variant="h6" component="label" className="form-label" htmlFor="my-input">
                                                        Tip (optional)
                                                    </Typography>
                                                    <TextField
                                                        placeholder={"0"}
                                                        className="form-field"
                                                        value={addExpense?.tip}
                                                        onChange={onFieldChange}
                                                        type="number"
                                                        name="tip"
                                                        size="small"
                                                        color="primary"
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"><AttachMoneyIcon /></InputAdornment>,
                                                        }} />
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div style={{ marginTop: "13px" }}>
                                            <Typography variant="h6" component="label" className="form-label" htmlFor="my-input">
                                                Total
                                            </Typography>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                                                <Typography variant="h6" style={{ fontSize: "17px" }} component="label" className="form-label" htmlFor="my-input">
                                                    $ {addExpense?.subTotal}
                                                </Typography>
                                                <HelpIcon style={{ fontSize: "25px", color: "rgb(176, 67, 62)" }} />
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            <div style={{ marginTop: "13px" }}>
                                <FormControl>
                                    <FormLabel className="form-label">Select 1</FormLabel>
                                    <RadioGroup
                                        defaultValue="paid"
                                        name="controlled-radio-buttons-group"
                                        value={addExpense?.paidWith}
                                        onChange={(e) => onRadiogroupchange(e)}
                                        sx={{ my: 1 }}
                                    >
                                        <FormControlLabel value={1} control={<Radio style={{ color: "#b0433e" }} />} label="Paid personally" />
                                        <FormControlLabel value={2} control={<Radio style={{ color: "#b0433e" }} />} label="Paid with company card" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div style={{ marginTop: "13px" }}>
                                <Typography variant="h6" component="label" className="form-label" htmlFor="my-input">
                                    Description
                                </Typography>
                                <TextField
                                    multiline
                                    value={addExpense?.description}
                                    onChange={onFieldChange}
                                    name="description"
                                    fullWidth
                                    rows={4}
                                    variant="outlined"
                                    className="form-field"
                                />
                            </div>

                            <div className="receipt-docunment" style={{ marginTop: "13px", textAlign: "center" }}>
                                <input type="file" style={{ display: "none" }} id="receipt-img" onChange={(e) => onImagechange(e)} />
                                {addExpense?.receiptUrl !== "" && (
                                    <span style={{position:'relative'}}>
                                        <img className="recipt-img" src={addExpense?.receiptUrl} />
                                        <IconButton
                                        style={{ 
                                            top: "10px", 
                                            right: "8px", 
                                            backgroundColor: "white", 
                                            color:'red',
                                            height:'15px',
                                            width:'15px',
                                            display:'flex',
                                            position:'absolute',
                                            padding:'10px',
                                            justifyContent:'center',
                                            alignitems:'center',
                                        }}
                                        onClick={() => clearImage()}
                                        size="small"
                                    ><CloseIcon fontSize="small" style={{ fontSize: 16 }} /> </IconButton>
                                    </span>
                                )}
                                <Button color="primary" className="upload-btn" onClick={() => uploadDocument()}>
                                    Upload receipt
                                </Button>
                            </div>
                            <div style={{ marginTop: "13px" }}>
                                <Button fullWidth className="submit-btn" onClick={() => addExpenses()}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                    )
                }
             
            </Modal>
            {showJobs && (
                <Clientjobs setShowjobs={setShowjobs} setAddexpense={setAddexpense} addExpense={addExpense} data={addExpense} />
            )}
        </>
    )

}
