import React, { useState } from 'react'
import BuildIDLogo from "../../assets/images/Build-ID-Transparent.png";
import '../../assets/css/referral.css'
import { Button } from '@material-ui/core';
import AlertModal from '../../components/AlertModal';
import { useEffect } from 'react';
import { fetchWorkBuddies, updateIsReverifiedWorkBuddy, updateIsVerifiedWorkBuddy } from '../../api/UserApi';
import Loader from '../../components/Loader';
const WorkBuddyVerify = (props) => {
    const [alertModalOpen_VerifyNum, setAlertModalOpen_VerifyNum] = useState(false);
    const [alertModalOpen_NotVerifyNum, setAlertModalOpen_NotVerifyNum] = useState(false);
    const [workBuddyName, setWorkBuddyName] = useState(null);
    const [userName, setUserName] = useState(null);
    const [isVerified, setIsVerified] = useState(false);
    const [isReVerified, setIsReVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(null);
    const [showVerifiedBanner, setShowVerifiedBanner] = useState(false);

    async function handleVerifyNumber() {
        try {
            const obj = {
                Id: props?.workBuddyId,
                IsVerified: true
            }
            const res = await updateIsVerifiedWorkBuddy(props?.dbId, obj);
            await updateIsReverifiedWorkBuddy(props?.workBuddyId,true);

            if (res.message === "OK") {
                setIsVerified(true);
                setIsReVerified(true);
                setShowVerifiedBanner(false);
                setAlertModalOpen_VerifyNum(true);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    async function handleNotVerifyNumber() {
        try {
            const obj = {
                Id: props?.workBuddyId,
                IsVerified: false
            }
            const res = await updateIsVerifiedWorkBuddy(props?.dbId, obj);
            await updateIsReverifiedWorkBuddy(props?.workBuddyId,false);

            if (res.message === "OK") {
                setIsReVerified(false);
                setAlertModalOpen_NotVerifyNum(true)
            }
        }
        catch (error) {
            console.log(error);
        }
    }


    async function fetchWorkBuddy() {

        const obj = { SearchList: [{ userId: props?.userId }] }

        try {
            setIsLoading(true);
            const res = await fetchWorkBuddies(props?.dbId, obj);
            const newRes = res.data.filter((item) => item?.id == props?.workBuddyId);
            setShowVerifiedBanner(newRes[0]?.isVerified);
            setIsVerified(false);
            setWorkBuddyName(newRes[0]?.name);
            setUserName(newRes[0]?.userName);
            setIsLoading(false);
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchWorkBuddy();
    }, [])

    return (
        <>
            <AlertModal
                isOpen={alertModalOpen_VerifyNum}
                onClose={() => setAlertModalOpen_VerifyNum(false)}
                body="Your number has been successfully verified."
            />
            <AlertModal
                isOpen={alertModalOpen_NotVerifyNum}
                onClose={() => setAlertModalOpen_NotVerifyNum(false)}
                body="Your phone number is not verified."
            />

            {isLoading ? <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center', alignItems: 'center' }}> <Loader /> </div> :
                <>
                    {isVerified ? <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center', alignItems: 'center' }}> <h2>Thank you for verifying...</h2> </div>
                        : showVerifiedBanner ? <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center', alignItems: 'center' }}> <h2>You have already verified...</h2> </div> :
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '80vh' }}>
                                <div className="with-referral" >
                                    <div
                                        className="flex-container-horizontal"
                                        style={{ width: "100%", justifyContent: "center", padding: "25px 0px" }}>
                                        <span style={{ maxWidth: "90%", display: 'flex', justifyContent: 'center' }}>
                                            <img
                                                src={BuildIDLogo}
                                                alt=""
                                                style={{
                                                    maxWidth: "100%", maxHeight: "100%",
                                                    width: "200px", height: "auto"
                                                }} />
                                        </span>
                                    </div>
                                    <div className="flex-container-horizontal"
                                        style={{ width: "100%", justifyContent: "center", padding: "8px 0px", flexDirection: 'column', alignItems: 'center', fontSize: '15px ', lineHeight: '35px' }}>
                                        <p> Hello <b>{workBuddyName},</b> </p>
                                        <p><b>{userName}</b> has added you as their Work Buddy on their app BuildID. </p>
                                    </div>
                                    <div className="flex-container-horizontal" style={{ justifyContent: 'center', paddingTop: '20px' }}>
                                        <Button variant='outlined' color='primary' style={{ marginRight: '15px' }} onClick={handleNotVerifyNumber}>Decline</Button>
                                        <Button variant='contained' color='primary' style={{ marginLeft: '15px' }} onClick={handleVerifyNumber}>Accept</Button>
                                    </div>
                                    <div className="flex-container-horizontal"
                                        style={{ width: "100%", justifyContent: "center", padding: "8px 0px", flexDirection: 'column', alignItems: 'center', fontSize: '15px ', lineHeight: '35px' }}>
                                        <br></br>
                                        <p><b>Please note : </b> This verification process will only work for a 24hr period.</p>
                                    </div>
                                    <p>Please read our <a target={"_blank"} rel="noreferrer" href="https://www.iubenda.com/terms-and-conditions/90161377"><b>Terms and Conditions</b></a> and <a target={"_blank"} rel="noreferrer" href="https://www.iubenda.com/privacy-policy/90161377"><b> Privacy Policy </b></a> before using our services.</p>
                                </div>
                            </div>
                    }
                </>
            }
        </>
    )
}

export default WorkBuddyVerify