// Converts ISO Date string to {MONTH YYYY}
export function stringToLocale(isoString) {
    return new Date(isoString).toLocaleDateString([], { month: "long", year: "numeric" });
}


// Formats ISO date string to {MONTH DD, YYYY}
export function isoToMonthDateYear(isoString) {
    return new Date(isoString).toLocaleDateString([], { month: "long", year: "numeric", day: "numeric" });
}


// Formats ISO date string to time {HH:MM AM/PM}
export function isoToTime(isoString) {
    return new Date(isoString).toLocaleTimeString([], { hour: "numeric", minute: "numeric" });
}


// Removes time portion of ISO Date (eg. YYYY-MM-DDTXX:XX:XX)
export function removeISOTime(isoString) {
    return isoString.split("T")[0];
}


// Checks if input string is an ISO Date
export function isISODate(string) {
    let date = new Date(string);
    return (date.toString() !== "Invalid Date");
}


// Pushes JSON item into array if ID is unique
export function addJSONIfNewID(array, newItem) {
    let alreadyIncluded = false;

    array.forEach(item => {
        if (item.id === newItem.id)
            alreadyIncluded = true;
    })

    if (!alreadyIncluded)
        array.push(newItem);

    return array;
}


// Counts 2D array elements (where each subarray is of differing lengths)
export function count2DArrayElements(array) {
    let count = 0;
    array.forEach(subarray => {
        subarray.forEach(item => count++)
    })

    return count;
}


// Removes array item at specified index
export function arrayRemoveByIndex(array, indexToRemove) {
    let newArray = [];
    array.forEach((item, index) => {
        if (index !== indexToRemove)
            newArray.push(item)
    })

    return newArray;
}


//formats the time to display time elapsed since posted
export function timeFormatter(timeObj) {
    let dateFormatObj = new Date(timeObj)
    // let difference = new Date(Date.now() - dateFormatObj);
    let difference = Date.now() - (dateFormatObj.getTime() - 21600000);

    function msToTime(ms, date) {

        // let seconds = (ms / 1000).toFixed(1);
        let minutes = Math.floor(ms / 60000);
        let hours = Math.floor(ms / (1000 * 60 * 60));
        let days = Math.floor(ms / (1000 * 60 * 60 * 24));

        if (minutes < 60) return minutes + " min"
        else if (hours < 24) return hours + " hr"
        else if (days < 2) return days + " day"
        else if (days < 7) return days + " days"
        else return (date.toDateString())
    }

    return (msToTime(difference, dateFormatObj))
}


// Extracts the Base URL of input string URL
// eg. "http://www.example.website.com/path/to/file/" is returned as "example.website.com"
export function extractBaseURL(inputUrl) {
    try {
        let fullUrl = new URL(inputUrl);
        let baseUrl = `${fullUrl.hostname}`;
        return baseUrl;
    }
    catch (error) {
        console.log(error);
        return inputUrl;
    }
}


// format the given address
export const getFormattedAddress = (address1, address2, city, province, country, postalcode, lines) => {
    let response = [];
    let finalAddress;
    //for address1 and address2
    if (address1 != '' && address1 != null && address2 != '' && address2 != null) response.push(address1.trim() + ', ' + address2);
    else if (address1 != '' && address1 != null && (address2 == '' || address2 == null)) response.push(address1);
    else if ((address1 == '' || address1 == null) && address2 != '' && address2 != null) response.push(address2);
    else if ((address1 == '' || address1 == null) && (address2 == '' || address2 == null)) response.push('');
    // for city, province and postal code
    if (country != 'United Kingdom') {
        if (city != '' && city != null && province != '' && province != null && postalcode != '' && postalcode != null) response.push(city + ', ' + province + ' ' + postalcode);
        else if (city != '' && city != null && province != '' && province != null && (postalcode == '' || postalcode == null)) response.push(city + ', ' + province);
        else if (city != '' && city != null && (province == '' || province == null) && postalcode != '' && postalcode != null) response.push(city + ', ' + postalcode);
        else if ((city == '' || city == null) && province != '' && province != null && postalcode != '' && postalcode != null) response.push(province + ' ' + postalcode);
        else if ((city == '' || city == null) && (province == '' || province == null) && (postalcode != '' && postalcode != null)) response.push(postalcode);
        else if ((city == '' || city == null) && (province != '' && province != null) && (postalcode == '' || postalcode == null)) response.push(province);
        else if ((city != '' && city != null) && (province == '' || province == null) && (postalcode == '' || postalcode == null)) response.push(city);
        else if ((city == '' || city != null) && (province == '' || province == null) && (postalcode == '' || postalcode == null)) response.push('');
    } else {
        if (city != '' && city != null && province != '' && province != null && postalcode != '' && postalcode != null) response.push(city + ', ' + postalcode);
        else if (city != '' && city != null && province != '' && province != null && (postalcode == '' || postalcode == null)) response.push(city);
        else if (city != '' && city != null && (province == '' || province == null) && postalcode != '' && postalcode != null) response.push(city + ', ' + postalcode);
        else if ((city == '' || city == null) && province != '' && province != null && postalcode != '' && postalcode != null) response.push(postalcode);
        else if ((city == '' || city == null) && (province == '' || province == null) && (postalcode != '' && postalcode != null)) response.push(postalcode);
        else if ((city == '' || city == null) && (province != '' && province != null) && (postalcode == '' || postalcode == null)) response.push('');
        else if ((city != '' && city != null) && (province == '' || province == null) && (postalcode == '' || postalcode == null)) response.push(city);
        else if ((city == '' || city != null) && (province == '' || province == null) && (postalcode == '' || postalcode == null)) response.push('');
    }
    //for country
    if (country != '' && country != null) response.push(country);
    else if (country == '' || country == null) response.push('');
    //updated address
    if (lines == 1) {
        if (response[0] != '' && response[1] != '' && response[2] != '') finalAddress = response[0].trim() + ', ' + response[1].trim() + ', ' + response[2].trim();
        else if (response[0] != '' && response[1] != '' && response[2] == '') finalAddress = response[0].trim() + ', ' + response[1].trim();
        else if (response[0] != '' && response[1] == '' && response[2] != '') finalAddress = response[0].trim() + ', ' + response[2].trim();
        else if (response[0] == '' && response[1] != '' && response[2] != '') finalAddress = response[1].trim() + ' ' + response[2].trim();
        else if (response[0] == '' && response[1] == '' && response[2] != '') finalAddress = response[2].trim();
        else if (response[0] == '' && response[1] != '' && response[2] == '') finalAddress = response[1].trim();
        else if (response[0] != '' && response[1] == '' && response[2] == '') finalAddress = response[0].trim();
        else if (response[0] == '' && response[1] == '' && response[2] == '') finalAddress = ''
    }
    else if (lines == 3) {
        if (response[0] != '' && response[1] != '' && response[2] != '') finalAddress = response[0].trim() + '\n' + response[1].trim() + '\n' + response[2].trim();
        else if (response[0] != '' && response[1] != '' && response[2] == '') finalAddress = response[0].trim() + '\n' + response[1].trim();
        else if (response[0] != '' && response[1] == '' && response[2] != '') finalAddress = response[0].trim() + '\n' + response[2].trim();
        else if (response[0] == '' && response[1] != '' && response[2] != '') finalAddress = response[1].trim() + '\n' + response[2].trim();
        else if (response[0] == '' && response[1] == '' && response[2] != '') finalAddress = response[2].trim();
        else if (response[0] == '' && response[1] != '' && response[2] == '') finalAddress = response[1].trim();
        else if (response[0] != '' && response[1] == '' && response[2] == '') finalAddress = response[0].trim();
        else if (response[0] == '' && response[1] == '' && response[2] == '') finalAddress = '';
    }
    else {
        return
    }
    return finalAddress;
}


export const formatNumericToTime = (numericValue) => {
    let formattedHours = "00";
    let formattedMinutes = "00";
    let finaltime = "00";
    let hoursPart;
    let minutesPart;

    if (numericValue.toString().includes('.')) {
        // Split the numeric value into hours and minutes
        [hoursPart, minutesPart] = numericValue.toString().split(".");
        if (hoursPart != 0) {
            const hours = parseInt(hoursPart) || 0;
            const minutes = minutesPart;
            formattedHours = hours.toString().padStart(2, "0");
            formattedMinutes = minutes.toString().length == 1 ? minutes.toString().padEnd(2, "0") : minutes;
            finaltime = `${formattedHours}:${formattedMinutes}`;
        } else {
            // only minutes
            formattedMinutes = minutesPart.toString().length == 1 ? `00:${minutesPart.toString().padEnd(2, "0")}` : `00:${minutesPart} `;
            finaltime = `${formattedMinutes} `;
        }
    } else {
        let hoursPart = numericValue.toString();
        // formattedHours = hoursPart.length == 1 ? `${hoursPart.toString().padStart(2, "0")}:00` : `${hoursPart}:00`;
        formattedHours = hoursPart.length == 1 ? `${hoursPart.toString().padStart(2, "0")}:00` : hoursPart.length == 2 ? `${hoursPart}:00` : hoursPart;
        finaltime = `${formattedHours} `;


        // finaltime = numericValue;
    }

    return `${finaltime} `;
};


export const showTutorialForNewUser = (key) => {
    const showTutorial = localStorage.getItem('newUser');
    const tutorialObj = showTutorial === "true" ? JSON.parse(localStorage.getItem('tutorial')) : {};
    return tutorialObj[key] === "true"
}


export const updateTutorialForNewUser = (key) => {
    const showTutorial = localStorage.getItem('newUser');
    const tutorialObj = showTutorial === "true" ? JSON.parse(localStorage.getItem('tutorial')) : {};
    let updatedTutorialObj = { ...tutorialObj };
    updatedTutorialObj[key] = "false";
    localStorage.setItem('tutorial', JSON.stringify(updatedTutorialObj));
}

