import { restGet, restPost } from "./RestClient";
import axios from "axios";
import { headers, form_headers } from "./UserApi";

// let headers = {
// 	"Content-Type": "application/json",
// 	"Accept": "application/json",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }

// let form_headers = {
// 	"Content-Type": "multipart/form-data",
// 	"Accept": "application/json",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }


// Special Google Maps API call for getting address from latitude/longitude

const GOOGLE_MAPS_BASE_URL = "https://maps.googleapis.com/maps/api/";
async function googleMapsRestGet(apiURL, headers) {
	try {
		const res = await axios.get(GOOGLE_MAPS_BASE_URL + apiURL, headers);
		if (res && res.data && res.data.status && res.data.status === "OK")
			return res.data;
		else
			throw res.statusText;
	}
	catch (error) {
		throw (error);
	}
}
export async function reverseGeocode(lat, lng) {
	const res = await googleMapsRestGet("geocode/json?latlng="
		+ lat + ","
		+ lng
		+ "&key=" + process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
	return res;
}





// Gets all projects within latitude/longitude bounds
export async function getProjectsByMapBounds(activeOnly, sLat, nLat, wLng, eLng) {
	const res = await restGet("projects/getProjectsByMapBounds/" + activeOnly.toString() + "/" + sLat + "/" + nLat + "/" + wLng + "/" + eLng + "/", headers);
	return res;
}

// Gets projects based on args
export async function fetchProjects(args) {
	const res = await restPost("projects/fetchProjects/", args, headers);
	return res;
}


// Saves projects
export async function saveProject(args) {
	const res = await restPost("projects/saveProjects/", args, headers);
	return res.data;
}

// Gets project type options by arg searchString
export async function getProjectTypes(searchString) {
	const res = await restGet("projects/GetBuildingTypes/" + searchString, headers);
	return res
}


// Returns project pictures by arg
export async function fetchProjectPictures(args) {
	const res = await restPost("projects/FetchProjectPictures/", args, headers);
	return res.data;
}

// Saves a single picture to a project
/* Args:
	{
		id: projectId,
		createdBy: userId,
		modifiedBy: userId,
		photo: {
			uri,
			type,
			name
		}
	}
*/
export async function saveProjectPictures(args) {
	const res = await restPost("projects/SaveProjectPictures/", args, form_headers);
	return res.data;
}


// Project Companies
// Get companies (and role) associated with a project
export async function fetchProjectCompanies2(args) {
	const res = await restPost("projects/FetchProjectCompanies2/", args, headers);
	return res;
}

// Adds/edits a company (and/or role) to a project
// export async function saveProjectCompany(args){	
// 	const res = restPost("projects/saveProjectCompany", args, headers);
// 	return res;
// }

// Get comapny role options for Dropdown
export async function fetchProjectCompanyRoles(args) {
	const res = await restPost("Projects/FetchProjectCompanyRoles/", args, headers);
	return res.data;
}
// TODO saveSiteCompanyRoles		- Add new role to autocomplete options


// Gets projects by products (eg. used in ManufacturerProfiles)
export async function fetchProjectProducts(args) {
	const res = await restPost("projects/fetchProjectProducts/", args, headers);
	return res;
}

export async function saveProjectProducts(args) {
	const res = await restPost("projects/saveProjectProducts/", args, headers);
	return res.data;
}


// Saves project Address
export async function saveProjectAddress(args) {
	const res = await restPost("projects/saveprojectaddress/", args, headers);
	return res.data;
}


export async function getProjectMembers(projectId) {
	const res = await restGet("projects/getProjectMembers/" + localStorage.userId + "/" + projectId, headers);
	return res;
}

export async function saveProjectCompanyRoles(args) {
	const res = restPost("projects/SaveProjectCompanyRoles", args, headers);
	return res;
}


export async function fetchUsersExperiencesProducts(args) {
	const res = await restPost("sites/FetchUsersExperiencesProducts/", args, headers);
	return res;
}

export async function getProjectDetails(args) {
	const res = await restPost("sites/FetchUserSitesProjectsForWeb/", args, {
		"Content-Type": "application/json",
		"Accept": "application/json"
	});
	return res?.data;
}

export async function fetchProjectsPostsPicturesForWeb(args) {
	const res = await restPost("projects/FetchProjectsPostsPicturesForWeb/", args, {
		"Content-Type": "application/json",
		"Accept": "application/json"
	});
	return res?.data;
}