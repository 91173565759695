import React, { useState, useEffect } from 'react'
import { Modal, IconButton, Button, TextField, FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import { Close as CloseIcon, Save as SaveIcon, Delete as DeleteIcon } from "@material-ui/icons";
import moment from "moment/moment";
import {
    KeyboardDatePicker, TimePicker,
} from '@material-ui/pickers';
import { BIBlue, BIGrey, BIRed } from '../../assets/buildidColors';
import { Autocomplete } from "@material-ui/lab";
import { deleteSafetyIncidents, fetchSites, saveSafetyIncident, searchUsersMembers } from '../../api/SafetyIncidentsApi';
import AlertModal from '../../components/AlertModal';
import PromptModal from '../../components/PromptModal';

export default function SafetyIncidentsModal(props) {

    const today = moment();
    const [isClientNotified, setIsClientNotified] = useState(false);
    const [timeValue, setTimeValue] = useState(today);
    const [description, setDescription] = useState("");
    const [selectedDate, setSelectedDate] = useState(today.format("YYYY-MM-DD"));
    const [userMemberList, setUserMemberList] = useState([]);
    const [reportedBy, setReportedBy] = useState(0);
    const [reportedByInputValue, setReportedByInputValue] = useState("")
    const [flag, setFlag] = useState(false);
    const [siteList, setSiteList] = useState([]);
    const [siteFlag, setSiteFlag] = useState(false);
    const [siteInputValue, setSiteInputValue] = useState("");
    const [site, setSite] = useState(0);
    const [safetyIncidentsId, setSafetyIncidentsId] = useState(0);
    const [deleteSafetyIncidentsAlert, setDeleteSafetyIncidentsAlert] = useState(false);
    const [saveSafetyIncidentsAlert, setSaveSafetyIncidentsAlert] = useState(false)

    async function handleSaveSafetyIncidents() {

        const obj =
        {
            UserId: localStorage.userId,
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
                {
                    Id: props.editSafetyIncidentsData?.id || 0,
                    ReportedById: reportedBy,
                    IncidentDate: selectedDate,
                    IncidentTime: timeValue,
                    SiteId: site,
                    IsClientNotified: isClientNotified,
                    IncidentDescription: description,
                    RecordStatusId: 1,
                    ModifiedBy: localStorage.userId
                }
            ]
        }

        const saveSafetyIncidentRes = await saveSafetyIncident(obj);
        props.onClose();

        if (saveSafetyIncidentRes.message === "OK") {
            props.getUpdatedSafetyIncidentList()
            setSaveSafetyIncidentsAlert(true);
            props.onClose();
            await clearEditData();
        } else {
            await clearEditData();
        }

    }

    function handleDescription(e) {
        setDescription(e.target.value);
    }

    function handleIsClientNotifiedChecked() {
        if (isClientNotified)
            setIsClientNotified(false);
        else if (!isClientNotified)
            setIsClientNotified(true);
    }

    function handleDateChange(date) {
        const newDate = moment(date).format("YYYY-MM-DD");
        setSelectedDate(newDate);
    }



    useEffect(() => {
        async function fetchUserMembers() {
            const searchMemeberRes = await searchUsersMembers();
            setUserMemberList(searchMemeberRes);
        }

        async function fetchSiteList() {
            const obj = {
                PageNr: 1,
                NrOfRecPerPage: 100,
                FullSearch: "",
                UserId: localStorage.userId,
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [
                    {

                    }
                ],
                SortList: [
                    {}
                ]
            }
            const fetchSiteRes = await fetchSites(obj);
            setSiteList(fetchSiteRes.data)
        }

        try {
            fetchUserMembers();
            fetchSiteList();
        }
        catch (err) {
            console.log(err);
        }
    }, [])

    function clearEditData() {
        setIsClientNotified(false);
        setSelectedDate(today.format("YYYY-MM-DD"));
        setTimeValue(today);
        setDescription("");
        setReportedBy(0);
        setReportedByInputValue("");
        setFlag(false);
        setSiteFlag(false);
        setSiteInputValue("")
        setSite(0);
        setSafetyIncidentsId(0);
    }

    async function handleDeleteSafetyIncidents(safetyIncidentId) {
        try {
            const deleteSafetyIncidentObj = {
                UserId: Number(localStorage.getItem("userId")),
                ReturnRecordId: false,
                ReturnRecordError: true,
                DeleteList: [
                    {
                        Id: safetyIncidentId,
                        ModifiedBy: Number(localStorage.getItem("userId"))
                    }
                ]
            }
            const deleteSafetyIncidentRes = await deleteSafetyIncidents(deleteSafetyIncidentObj);
            props.onClose();
            if (deleteSafetyIncidentRes.message === "OK") {
                props.getUpdatedSafetyIncidentList()
                setDeleteSafetyIncidentsAlert(false);
                await clearEditData();
            } else {
                await clearEditData();
            }
        }
        catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        const fetchEditSafetyIncidents = () => {
            if (!props.editSafetyIncidentsData.id) {
                // props.setSafetyIncidentsModalOpen();
                setSafetyIncidentsId(0)
            } else {
                props.setPageNr(1);
                setSafetyIncidentsId(props.editSafetyIncidentsData?.id);
                setSelectedDate(moment(props.editSafetyIncidentsData?.incidentDate).format('YYYY-MM-DD'));
                setIsClientNotified(props.editSafetyIncidentsData?.isClientNotified);
                setTimeValue(props.editSafetyIncidentsData?.incidentTime);
                setDescription(props.editSafetyIncidentsData?.incidentDescription);
                Promise.resolve(props.editSafetyIncidentsData?.siteId).then(() => setSite(props.editSafetyIncidentsData?.siteId));
                Promise.resolve(props.editSafetyIncidentsData?.reportedById).then(() => setReportedBy(props.editSafetyIncidentsData?.reportedById));
                Promise.resolve(props.editSafetyIncidentsData?.siteName).then(() => setSiteInputValue(props.editSafetyIncidentsData?.siteName));
                Promise.resolve(props.editSafetyIncidentsData?.reportedBy).then(() => setReportedByInputValue(props.editSafetyIncidentsData?.reportedBy));
            }
        }
        fetchEditSafetyIncidents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.editSafetyIncidentsData])

    return (
        <div>
            <AlertModal
                isOpen={saveSafetyIncidentsAlert}
                onClose={() => setSaveSafetyIncidentsAlert(false)}
                title={"Safety Incident Saved Successfully..."}
            />

            <PromptModal
                isOpen={deleteSafetyIncidentsAlert}
                onClose={() => {
                    setDeleteSafetyIncidentsAlert(false)
                }}
                title="Are you sure you want to Delete?"
                redButtonText="Delete"
                onRedButtonClick={() => handleDeleteSafetyIncidents(props.editSafetyIncidentsData?.id)} />

            <Modal
                open={props.isOpen}
                onClose={async () => {
                    props.onClose();
                    await clearEditData();
                }}>

                <div className="modal-center timesheet-modal">
                    <div className="modal-card">
                        <div className="modal-card-header-buttons">
                            <IconButton
                                color="primary"
                                onClick={async () => {
                                    props.onClose();
                                    await clearEditData();
                                }}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <div className="modal-card-body" style={{ padding: '10px 0px' }}>
                            <h2>{safetyIncidentsId !== 0 ? "Edit" : "Add" }  Safety Incident</h2>

                            <div className="input-container">
                                <Autocomplete
                                    freeSolo
                                    inputValue={reportedByInputValue}
                                    onInputChange={(event, value) => { setReportedByInputValue(value); setFlag(true) }}
                                    label="Reported By"
                                    id="reported-by"
                                    options={userMemberList}
                                    onChange={(event, value) => { setReportedBy(value?.userId); setFlag(false) }}
                                    getOptionLabel={(option) => `${option?.firstName}  ${option?.lastName}`}
                                    style={{ flex: 1 }}
                                    renderInput={(params) => {
                                        return (
                                            <>
                                                <TextField {...params} label="Reported By" variant="outlined" />
                                            </>)
                                    }}
                                    open={reportedByInputValue?.length > 0 && flag}
                                />
                            </div>

                            <div className="input-container">
                                <div className="flex-container-vertical" style={{ alignItems: "stretch" }}>
                                    <KeyboardDatePicker
                                        autoOk
                                        variant="inline"
                                        inputVariant="outlined"
                                        label="Incident Date"
                                        format="YYYY-MM-DD"
                                        value={selectedDate}
                                        InputAdornmentProps={{ position: "start" }}
                                        onChange={date => handleDateChange(date)}
                                    />
                                </div>
                            </div>

                            <div className="input-container">
                                <TimePicker
                                    label="Time"
                                    value={timeValue}
                                    onChange={(timeValue) => setTimeValue(timeValue)}
                                />
                            </div>

                            <div className="input-container">
                                <Autocomplete
                                    freeSolo
                                    inputValue={siteInputValue}
                                    onInputChange={(event, value) => { setSiteInputValue(value); setSiteFlag(true) }}
                                    label="Site"
                                    id="site"
                                    options={siteList}
                                    onChange={(event, value) => { setSite(value?.id); setSiteFlag(false) }}
                                    getOptionLabel={(option) => `${option?.name}`}
                                    style={{ flex: 1 }}
                                    renderInput={(params) => {
                                        return (
                                            <>
                                                <TextField {...params} label="Site" variant="outlined" />
                                            </>)
                                    }}
                                    open={siteInputValue?.length > 0 && siteFlag}
                                />
                            </div>

                            <div className="input-container">
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                style={{ color: BIBlue }}
                                                checked={isClientNotified}
                                                onChange={handleIsClientNotifiedChecked}
                                            />}
                                        label={<Typography style={{ color: BIGrey }}>Client Notified</Typography>}
                                    /> </div>
                                <div>
                                </div>



                                <div className="input-container">
                                    <div className="flex-container-vertical" style={{ alignItems: "stretch" }}>
                                        <TextField
                                            type="text"
                                            variant="outlined"
                                            label="Description"
                                            value={description}
                                            onChange={handleDescription}
                                        />
                                    </div>
                                </div>

                                <div className="input-container" style={{ padding: '20px 0px', display: 'flex', }}>
                                    {safetyIncidentsId !== 0 ? <Button
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        size="medium"
                                        component="label"
                                        disabled={false}
                                        startIcon={<DeleteIcon />}
                                        onClick={() => setDeleteSafetyIncidentsAlert(true)}
                                        style={{ marginRight: '10px', color: BIRed, borderColor: BIRed }}
                                    >
                                        Delete
                                    </Button> : <></>}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        size="medium"
                                        component="label"
                                        disabled={false}
                                        startIcon={<SaveIcon />}
                                        onClick={handleSaveSafetyIncidents}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
