import React from 'react'
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";
import ShareIcon from '@mui/icons-material/Share';
import { IconButton } from "@material-ui/core";
import { deleteMontages } from '../../api/MontagesApi';
import AlertModal from '../../components/AlertModal';
import { useState } from 'react';
import ShareMontageModal from './ShareMontageModal';
import { getDbValue } from '../../api/RestClient';
import PromptModal from '../../components/PromptModal';
import PreviewMontageModal from './PreviewMontageModal';

export default function MontageCard(props) {
    const [deleteMontageAlert, setDeleteMontageAlert] = useState(false);
    const [flag, setFlag] = useState(false);
    const [imageList, setImageList] = useState([]);
    const [play, setPlay] = useState(false);
    const [toggleBtn, setToggleBtn] = useState(false);
    const [openShareMontageModal, setOpenShareMontageModal] = useState(false)
    const [montId, setMontId] = useState(0);
    const [discardModalOpen, setDiscardModalOpen] = useState(false);
    const [musicFileUrl, setMusicFileUrl] = useState("");

    const handleDeleteMontage = async () => {
        const obj = {
            id: montId,
            uid: Number(localStorage.getItem("userId")),
        }
        const deleteMontageRes = await deleteMontages(obj)
        if (deleteMontageRes === 1) {
            await setDeleteMontageAlert(true);
        }
        // props.setNewMontage();
        props.setActivePage("montage-list");
    }

    function handleDelete(montId) {
        setMontId(montId);
        setDiscardModalOpen(true);
    }

    const handleClick = (data) => {
        setMusicFileUrl(data?.musicUrl);
        setFlag(true);
        setImageList(data.fileList);
        // setMusicFileUrl(data.musicUrl);
    }

    return (
        <>
            <PromptModal
                isOpen={discardModalOpen}
                onClose={() => setDiscardModalOpen(false)}
                title="Delete Montage"
                desc="Are you sure you want to delete this montage?"
                redButtonText="Yes"
                blueButtonTextFirst="No"
                onRedButtonClick={() => { setDiscardModalOpen(false); handleDeleteMontage() }} />

            <PreviewMontageModal
                isOpen={flag}
                onClose={()=>setFlag(false)}
                arr={props.arr}
                setToggleBtn={setToggleBtn}
                toggleBtn={toggleBtn}
                setImageList={setImageList}
                imageList={imageList}
                musicFileUrl={musicFileUrl}
                setMusicFileUrl={setMusicFileUrl}
            />

            <ShareMontageModal
                isOpen={openShareMontageModal}
                onClose={() => setOpenShareMontageModal(false)}
                montId={montId}
            />

            <AlertModal
                isOpen={deleteMontageAlert}
                onClose={() => { setDeleteMontageAlert(false); setDiscardModalOpen(false); props.fetchMontage() }}
                title={"Montage Deleted Successfully..."}
            />

            <div className="store-card">
                <div className="store-card-image" style={{ cursor: 'pointer' }} onClick={() => handleClick(props.data)}>
                    {
                        (props.imageFile !== "")
                            ? (<img src={props.imageFile} alt="" />)
                            : (<img style={{ display: "none" }} alt="" />)
                    }
                </div>

                <div className="store-card-content">
                    <div className="flex-container-horizontal" style={{ justifyContent: "space-between" }}>
                        <span className="store-card-title" onClick={() => handleClick(props.data)} style={{cursor: 'pointer', display:'inline-block',maxWidth:'13ch',textOverflow:'ellipsis',overflow: 'hidden', whiteSpace: 'nowrap'}}>
                            {props.name}
                            {/* <p className="store-card-title" style={{ cursor: 'pointer' }} onClick={() => handleClick(props.data)}>{props.name}</p> */}
                        </span>
                        <span>
                            <IconButton
                                color="primary"
                                onClick={() => { props.setActivePage("new-montage"); props.setAddMontageBtnVisible(false); setPlay(false); props.editMontage(props.data); }}>
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                color="primary"
                                onClick={() => { setMontId(props.id); getDbValue(); setOpenShareMontageModal(true) }}>
                                <ShareIcon />
                            </IconButton>
                            <IconButton
                                color="primary"
                                onClick={() => handleDelete(props.data?.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}
