import React, { useState, useEffect, useCallback, useRef } from "react";
import {
    Checkbox,
    Divider,
    IconButton,
    ListItemText,
    Typography,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Box, Button, Stack, Modal, ListItemButton, CircularProgress } from "@mui/material";
import ProductSearchField from "./ProductSearchField";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchTrades } from "../api/TradesApi";
import { fetchRoles } from "../api/RolesApi";


const SelectRoleModal = (props) => {

    const [pageNo, setPageNo] = useState(1);
    const [list, setList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [noData, setNoData] = useState();

    {
        /* new code Yash 19-03-2024  */
      }
    
      const [allDataLoaded, setAllDataLoaded] = useState(false);
      {
        /* new code Yash 19-03-2024  */
      }
    

    useEffect(() => {
        getData(1);
    }, []);

    async function getData(pageNo, searchTerm) {
        try {
            const res = await fetchRoles({
                PageNr: pageNo,
                IncludeRecordNr: true,
                NrOfRecPerPage: 20,
                SearchList: [{ manufacturer: false, name: searchTerm }],
                SortList: [{ FieldName: "Name", Direction: "ASC" }],
            });

            let finalArray = res?.data ?? [];
            const noOfRecords = res?.nrOfRecords;
            setNoData(noOfRecords);
            if (pageNo > 1) {
                setTimeout(() => {
                    setList((prevProducts) => [...prevProducts, ...finalArray]);
                }, 500);
            } else {
                setList(finalArray);
            }
            {
                /* new code Yash 19-03-2024  */
              }
        
              if (res?.data?.length === 0) {
                setAllDataLoaded(true);
              }
              {
                /* new code Yash 19-03-2024  */
              }
            setPageNo(pageNo + 1);
        } catch (e) {
            console.log("Error while getting data :: ", e);
        }
    }

    const handleCloseModal = () => {
        props?.onClose();
    };


    const onItemClick = (item) => {
        props?.onItemClick(item);
    };

    return (
        <>
            <Modal
                aria-labelledby="add-description-modal"
                aria-describedby="add-description-form"
                open={props?.open}
                onClose={handleCloseModal}>

 {/* new code Yash 19-03-2024  */}

 <Box className="modal-center" sx={{ width: 600 }}>
          <div className="modal-card" style={{ padding: "15px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}>
              <Typography
                variant="h6"
                style={{ fontWeight: "600", fontSize: "17px" }}>
                Select Title/Role
              </Typography>
              <IconButton onClick={handleCloseModal} style={{ color: "blue" }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Stack spacing={2} padding={"5px"}>
              <ProductSearchField
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e);
                  getData(1, e);
                }}
                isFocused={() => {}}
                placeholder={"Search Trade"}
              />
            </Stack>

            <p style={{ margin: "5px 0px 5px 7px", color: "#8d8d8d" }}>
              {noData} results
            </p>
            <Divider style={{ margin: "10px" }} />

            <div
              id="scrollableDiv_role"
              style={{ height: 300, overflow: "auto" }}>
              <InfiniteScroll
                dataLength={list?.length}
                next={() => getData(pageNo, searchTerm)}
                hasMore={!allDataLoaded}
                loader={
                  allDataLoaded ? null : (
                    <h4 style={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </h4>
                  )
                }
                scrollableTarget="scrollableDiv_role">
                {list.map((option) => (
                  <ListItemButton
                    key={option.id}
                    onClick={() => onItemClick(option)}>
                    <ListItemText>
                      <b>{option?.name}</b>
                    </ListItemText>
                  </ListItemButton>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </Box>
        {/* new code Yash 19-03-2024  */}



             
            </Modal>
        </>
    );
}

export default SelectRoleModal