import React, { useState, useEffect } from 'react'
import {
    Dialog, DialogTitle, DialogContent,
    Button, Chip, IconButton, Avatar, List,
    Stack, ListItem, ListItemButton, ListItemText, DialogActions, Typography
} from '@mui/material'
import { Close as CloseIcon, Edit as EditIcon, } from "@material-ui/icons";
import CircularProgress from '@mui/material/CircularProgress';
import { deleteListings, fetchUserSavedListings, updateBuySellUserItemsForRepost, updateExpiredItems } from '../../api/BuySellApi';
import Loader from '../../components/Loader';
import defaultProjectImage from "../../assets/images/project-profile-placeholder.jpg"
import { BIBlue, BIGrey, BIWhite } from '../../assets/buildidColors';
import moment from "moment";
import PromptModal from '../../components/PromptModal';
import ErrorIcon from '@mui/icons-material/Error';
import AddListing from './AddListing';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';

const MyListings = (props) => {

    const [loading, setLoading] = useState(true);
    const [activeItem, setActiveItem] = useState([]);
    const [expiredItem, setExpiredItem] = useState([]);
    const [deleteAlertModal, setDeleteAlertModal] = useState(false);
    const [selectItemDetails, setSelectedItemDetails] = useState(null);
    const [repostLoading, setRepostLoading] = useState(false);
    const [showAddListingModal, setShowAddListingModal] = useState(false);
    const [modalType, setModalType] = useState("my-listing");
    const [editListItemDetails, setEditListItemDetails] = useState(null);


    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            const obj = {
                // PageNr: pageNo,
                NrOfRecPerPage: 20,
                FullSearch: "",
                UserId: Number(localStorage.userId),
                IncludeRecordNr: true,
                TypeOfObjectReturned: "",
                FetchAllowedRecordsOnly: false,
                SearchList: [{ ModifiedBy: Number(localStorage.userId) }],
                SortList: [{ FieldName: "Id", Direction: "DESC" }]
            };
            const res = await fetchUserSavedListings(obj);
            const finalData = res?.data?.map(async (i) => {
                const obj2 = {
                    Id: i.id,
                    ModifiedBy: Number(localStorage.userId)
                }
                await updateExpiredItems(obj2)
            });
            const res3 = await fetchUserSavedListings(obj);
            const isActiveItems = res3?.data.filter((i) => !i.isExpired);
            const isExpiredItems = res3?.data.filter((i) => i.isExpired);
            setActiveItem(isActiveItems);
            setExpiredItem(isExpiredItems)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('Error[--GET_DATA--]:', error?.message ?? error);
        }
    };


    const onClose = () => {
        setActiveItem([])
        setExpiredItem([])
        setDeleteAlertModal(false)
        setSelectedItemDetails(null)
        setRepostLoading(false)
        setModalType("my-listing");
        setEditListItemDetails(null);
        props?.onClose();
    }

    const onDeletePressed = async (item) => {
        await setSelectedItemDetails(item);
        setModalType("delete");
        return setDeleteAlertModal(true);
    }

    const onDeleteListingPressed = async () => {
        try {
            const obj = {
                UserId: Number(localStorage.userId),
                ReturnRecordId: false,
                ReturnRecordError: true,
                SoftDelete: true,
                DeleteList: [{
                    Id: selectItemDetails?.id,
                    ModifiedBy: Number(localStorage.userId)
                }]
            }
            const res = await deleteListings(obj);
            getData();
            setModalType("my-listing");
            setSelectedItemDetails(null);
            setDeleteAlertModal(false)
        } catch (e) {
            setModalType("my-listing");
            setSelectedItemDetails(null);
            setDeleteAlertModal(false)
            console.log("ERROR", e);
        }
    };

    const onListingRePost = async (item) => {
        try {
            if (repostLoading) return null;
            await setSelectedItemDetails(item);
            setRepostLoading(true)
            const obj = {
                Id: item.id,
            }
            await updateBuySellUserItemsForRepost(obj);
            await getData();
            setSelectedItemDetails(null);
            setRepostLoading(false);
        } catch (e) {
            setSelectedItemDetails(null);
            setRepostLoading(false)
            console.log("ERROR:[onListingRePost]:", e);
        }
    };

    const onAddMyListingPressed = () => {
        setModalType('add-listing');
        return setShowAddListingModal(true)
    }


    const onEditItemClick = async (item) => {
        await setEditListItemDetails(item);
        setModalType('add-listing');
        return setShowAddListingModal(true)
    }

    const renderItem = (item, type) => {
        const targetDate = new Date(item.expiryDate);
        const currentDate = new Date();
        const timeDifference = targetDate - currentDate;
        const daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        return (
            <>
                <ListItem
                    sx={{ width: '100%', bgcolor: BIWhite, borderRadius: '5px', margin: '15px 0', height: '84px' }}
                    secondaryAction={
                        <>
                            <IconButton
                                onClick={() => { onEditItemClick(item) }}
                                sx={{ color: BIBlue }}
                            >
                                <EditIcon />
                            </IconButton>
                        </>
                    }
                    disablePadding>
                    <ListItemButton onClick={() => {
                        console.log("ITEM++++", item)
                        props?.onMyListingItemClick(item)
                    }}>
                        <Avatar
                            variant="rounded"
                            style={{ marginRight: "10px", width: '52px', height: '52px' }}
                            src={item?.pictureList?.length != 0 ? item?.pictureList?.[0].name : defaultProjectImage}>
                        </Avatar>
                        <ListItemText
                            style={{ marginLeft: '10px' }}
                            primary={item?.title}
                            secondary={
                                <>
                                    {
                                        item.forTrade ?
                                            (
                                                <>
                                                    <Chip label="For Trade" />
                                                </>
                                            )
                                            : `$${item.price}`
                                    }
                                    {
                                        type === "Expired" ?
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <ErrorIcon style={{ fontSize: '17px', marginRight: '4px', color: 'red' }} size={10} />
                                                <span style={{ color: 'red' }}>Expired</span>
                                            </div>
                                            :
                                            <p>Expires in {daysRemaining} day</p>
                                    }

                                </>
                            }
                        />
                    </ListItemButton>
                </ListItem>
                <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                    {
                        (moment(item?.expiryDate)).diff((new Date()), 'days') > 3 ?
                            <Button variant="outlined" color="error" sx={{ width: '100%' }} onClick={() => { onDeletePressed(item) }}>
                                DELETE
                            </Button>
                            :
                            (
                                <>
                                    <Button variant="outlined" color="error" sx={{ width: '100%' }} onClick={() => { onDeletePressed(item) }}>
                                        DELETE
                                    </Button>
                                    <Button variant="outlined" sx={{ width: '100%' }} style={{ color: BIBlue }} onClick={() => { onListingRePost(item) }}>
                                        {repostLoading && selectItemDetails?.id === item?.id ? <CircularProgress size={24} /> : "REPOST"}
                                    </Button>
                                </>
                            )
                    }
                </Stack>
            </>
        )
    }

    const renderActiveItmes = () => {
        return (
            <>
                <h3>Active</h3>
                <List sx={{ width: '100%' }}>
                    {
                        activeItem.map((item, index) => {
                            return (
                                <div key={index}>
                                    {renderItem(item, "Active")}
                                </div>

                            )
                        })
                    }
                </List>
            </>
        )
    }

    const renderExpiredListItem = () => {
        return (
            <>
                <h3>Expired</h3>
                <List sx={{ width: '100%' }}>
                    {
                        expiredItem.map((item, index) => {
                            return (
                                <div key={index}>
                                    {renderItem(item, "Expired")}
                                </div>
                            )
                        })
                    }
                </List>
                <div style={{ margin: '10px 0' }}>
                    <hr />
                </div>
            </>
        )
    }


    const renderEmptyPlaceHolder = () => {
        return (
            <>
                <Stack alignItems={'center'} style={{ color: BIGrey }}>
                    <Stack style={{ width: '300px', height: '300px' }} alignItems={'center'}>
                        <BusinessCenterRoundedIcon style={{ fontSize: '200px', marginTop: '25px' }} />
                    </Stack>
                    <Typography>
                        Looking to sell or trade items or materials from your Sites?
                    </Typography>
                </Stack>
            </>
        )
    }



    return (
        <>
            {
                modalType === "my-listing" &&
                <Dialog
                    open={props?.open}
                    onClose={onClose}
                    fullWidth
                    maxWidth="sm"
                    scroll={"paper"}
                >
                    <DialogTitle>
                        <div className="modal-card-header-buttons" style={{ alignItems: 'center' }}>
                            <h2>My Listings</h2>
                            <IconButton
                                color="primary"
                                onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent style={{ paddingTop: 0 }}>
                        {
                            !loading ?
                                <Stack spacing={0}>
                                    {expiredItem.length === 0 && activeItem.length === 0 && renderEmptyPlaceHolder()}
                                    {expiredItem?.length > 0 && renderExpiredListItem()}
                                    {activeItem?.length > 0 && renderActiveItmes()}
                                </Stack>
                                :
                                <Loader />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onAddMyListingPressed}
                            style={{ backgroundColor: BIBlue, color: 'white', width: '90%', margin: 'auto' }} >
                            CREATE A LISTING
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {modalType === "delete" &&
                <PromptModal
                    isOpen={deleteAlertModal}
                    onClose={() => {
                        setModalType("my-listing");
                        setSelectedItemDetails(null);
                        setDeleteAlertModal(false)
                    }}
                    title={''}
                    desc="Are you sure you want to delete this Listing?"
                    redButtonText="Yes"
                    blueButtonTextFirst="No"
                    onRedButtonClick={() => {
                        onDeleteListingPressed()
                    }}
                />
            }
            {modalType === "add-listing" &&
                <AddListing
                    open={showAddListingModal}
                    onClose={() => {
                        setModalType("my-listing");
                        setSelectedItemDetails(null);
                        setDeleteAlertModal(false);
                        setEditListItemDetails(null);
                    }}
                    getUpdatedData={getData}
                    editListItemDetails={editListItemDetails}
                />
            }
        </>
    )
}

export default MyListings