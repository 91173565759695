import React from "react";
import {
  Modal,
  Stack,
  Typography,
  Card,
  Chip,
  Button,
  Grid,
} from "@mui/material";
import { BIBlue } from "../../assets/buildidColors";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import { MoreVert as MoreIcon } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ErrorIcon from "@mui/icons-material/Error";
import { Delete as DeleteIcon } from "@material-ui/icons";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import '../../assets/css/JobPosting.css'

const JobPostingTutorial = (props) => {
  return (
    <>
      <Modal
        open={props?.tutorialIndex == 0 ? false : true}
        onClose={props.onClose}
        style={{
          backgroundColor: "rbga(0,0,0,0.1)",
        }}>
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", height: "100%" }}
          onClick={props.onPress}>
          {props.tutorialIndex === 1 && (
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}>
              <Stack sx={{ textAlign: "center", color: "white" }}>
                <Typography variant="h6">Job Postings</Typography>
                <Typography variant="subtitle1">
                  Quickly find and apply to jobs that need to help around the
                  area
                </Typography>
                <Typography variant="body1">
                  <span style={{ color: BIBlue }}>
                    {" "}
                    (Tap anywhere to continue)
                  </span>
                </Typography>
              </Stack>
            </Grid>
          )}

          {props.tutorialIndex === 2 && (
            <Grid
              container
              justifyContent="center"
              alignItems="flex-start"
              height="100%">
              <div className="jobposting-tutorial-2-body">
                <Card
                  style={{
                    background: "#d8d8d8",
                    marginTop: "18.5%",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}>
                  <div>
                    <h4>Calgary Cancer Center ( 1.7 km away)</h4>
                    <p>Thermal System</p>
                    <Chip label="Cladder" />
                    <Chip label="Permanent" />
                  </div>
                  <div>
                    <MoreIcon />
                  </div>
                </Card>
                <div
                  style={{
                    marginLeft: "25%",
                    color: "white",
                    marginTop: "10px",
                  }}>
                  <NorthWestIcon style={{ fontSize: "40px" }} />
                  <div>
                    After entering the location,select the job that interests
                    you and apply with the resume you make on our Resume
                    Builder!
                  </div>
                </div>
              </div>
            </Grid>
          )}

          {props.tutorialIndex === 3 && (
            <Grid
              container
              justifyContent="center"
              alignItems="flex-start"
              height="100%">
              <div className="jobposting-tutorial-3-body">
                <div style={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    style={{
                      background: BIBlue,
                      color: "white",
                      marginTop: "19.5%",
                      marginLeft: "5px",
                    }}>
                    My Postings
                  </Button>
                  <p
                    style={{
                      color: "white",
                      marginTop: "19.5%",
                      marginLeft: "40px",
                    }}>
                    <ArrowBackIcon /> {"  "}After My Postings click
                  </p>
                </div>
                <div
                  style={{
                    color: "white",
                  }}>
                  <div style={{ width: "100%" }}>
                    <Card
                      className="jobposting-tutorial-3-card">
                      <div>
                        <h2>Expired</h2>
                        <h4>Calgary Cancer Center ( 1.7 km away)</h4>
                        <p>Thermal System</p>
                        <p>hr@thermalsystems.ca</p>
                        <Chip label="Cladder" />
                        <Chip label="Permanent" />
                        <div>
                          <Stack
                            direction="row"
                            spacing={4}
                            style={{ marginTop: "20px" }}>
                            <Button
                              variant="outlined"
                              startIcon={<DeleteIcon />}
                              style={{ color: "red" }}>
                              DELETE
                            </Button>
                            <Button
                              variant="outlined"
                              startIcon={<AutorenewIcon />}
                              style={{ color: "blue" }}>
                              REPOST
                            </Button>
                          </Stack>
                        </div>
                      </div>
                      <div>
                        <ErrorIcon style={{ color: "red" }} />
                      </div>
                    </Card>
                    <div
                      style={{
                        marginLeft: "50%",
                        color: "white",
                        marginTop: "10px",
                      }}>
                      <ArrowUpwardIcon />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      Users are able to create jobs for only sites they work at.{" "}
                      <br />
                      Once created, unless extended by the user, <br />
                      job postings expire in 14 days
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          )}
        </div>
      </Modal>
    </>
  );
};

export default JobPostingTutorial;
