import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../assets/css/ClientHome.css";

const HomeModel = (props) => {
  return (
    <Modal open={props?.open} onClose={props?.handleClose}>
      <Box className="modal-box">
        <IconButton
          aria-label="close"
          onClick={props?.handleClose}
          className="close-button"
          sx={{
            position: "absolute",
            top: 7,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        {props?.selectedItem && (
          <>
            <img
              src={props?.selectedItem.imageUrl}
              alt="Ticket front"
              className="ticket-image"
            />
            <div className="flex-container-left">
              <div className="user-info">
                <img
                  src={props?.selectedItem?.userImgeUrl}
                  alt="User"
                  className="user-image"
                />
                <p className="user-name">
                  <b>{props?.selectedItem?.name}</b>
                </p>
              </div>
              <div className="date-info">
                <p className="date-text">{props?.selectedItem?.date}</p>
              </div>
            </div>
            <Typography variant="body1" className="description-text">
              {props?.selectedItem.discreption}
            </Typography>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default HomeModel;
