import React, { useState } from 'react'
import { Button, ListItemText, TextField } from '@material-ui/core'
import { Publish as UploadIcon, Close as CloseIcon, Save as SaveIcon } from "@material-ui/icons";
import { PlayCircleFilled } from "@material-ui/icons";
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IconButton } from "@material-ui/core";
import RefreshIcon from '@mui/icons-material/Refresh';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AddPicturesModal from './AddPicturesModal';
import { getUserProjectPictures, getUserSitePicture, saveGalleryPictures } from '../../api/MontagesApi';
import { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { saveMontages, saveMontagesFile, deleteMontageFile } from '../../api/MontagesApi';
import { Card, CardMedia, Container, Grid } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import AlertModal from '../../components/AlertModal';
import { BIBlue, BIYellow } from '../../assets/buildidColors';
import GetMusicModal from '../../components/GetMusicModal';
import ImageChooseFromModal from './ImageChooseFromModal';
import '../../assets/css/AddMontage.css'
import PromptModal from '../../components/PromptModal';
import Loader from '../../components/Loader';
import { isEqual } from "lodash";


export default function AddMontageForm(props) {

    const [pictureList, setPictureList] = useState([]);
    const [deletedImages, setDeletedImages] = useState([]);
    const [addPictureModalOpen, setAddPictureModalOpen] = useState(false)
    const [pictureFile, setPictureFile] = useState([]);
    const [previewFlag, setPreviewFlag] = useState(true);
    const [montageName, setMontageName] = useState("");
    const [saveMontageAlert, setSaveMontageAlert] = useState(false);
    const [getMusicModalOpen, setGetMusicModalOpen] = useState(false)
    const [musicName, setMusicName] = useState("Add Sound");
    const [imageChooseFromModalOpen, setImageChooseFromModalOpen] = useState(false);
    const [addIconPosition, setAddIconPosition] = useState(0);
    const [selectedFromEmpty, setSelectedFromEmpty] = useState(false);
    const [imageCount, setImageCount] = useState(0);
    const [play, setPlay] = useState(false);
    const [toggleBtn, setToggleBtn] = useState(false);
    const [musicFileUrl, setMusicFileUrl] = useState("");
    const [discardModalOpen, setDiscardModalOpen] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(0);
    const [count, setCount] = useState(0);
    const [timer, setTimer] = useState(null);
    const [inappropriateImageAlert, setInappropriateImageAlert] = useState(false);

    const sound = document.getElementById("fromSitePicture");
    const getMusicModalSound = document.getElementById("audioFile");

    const handlePlay = () => {
        if (play) {
            setPlay(false);
            stopCount();
            // sound.muted = true;
            sound.pause();
            sound.currentTime = 0;
        }
        else {
            setPlay(true)
            startCount();
            if (pictureList.length !== 0 && musicFileUrl !== "") {
                sound.play();
                sound.muted = false;
            }
        }
    }


    const startCount = () => {
        let arrLen = pictureList.length;
        let tmpVal = 0;

        if (pictureList.length != 0) {
            let timerVal = setInterval(() => {
                if (arrLen * 5 === tmpVal) {
                    setCount(0);
                    tmpVal = 0;
                } else {
                    setCount(count => count + 1);
                    tmpVal = tmpVal + 1;
                }
            }, 1000);
            setTimer(timerVal);
        }
    }

    const stopCount = () => {
        clearInterval(timer);
        setPlay(false);
        setCount(0);
        sound.pause();
        sound.currentTime = 0;
    }

    function handleChangeMusic(fileUrl) {
        setMusicFileUrl(fileUrl)
        if (pictureList.length != 0) {
            if (play) {
                Promise.resolve(fileUrl).then(() => sound.play());
            }
        }
        else {
            setPlay(false);
            sound.pause();
            sound.currentTime = 0;
        }
    }

    function handleCancel() {
        setMontageName("");
        setPictureFile([]);
        setPreviewFlag(true);
        // setMusicFileUrl("");
        // setMusicName("Add Sound");
        // setPictureList([]);
        // props.setEditMontageData({});
        // props.fetchMontage();

        // setImageCount(0);
        // setDiscardModalOpen(false)
        // setToggleBtn(false)
        // setAddIconPosition(0)
        // setSelectedFromEmpty(false)

        // setImageChooseFromModalOpen(false);
        // setSelectedFromEmpty(false);
        // window.location.reload();
        // props.setActivePage("montage-list");
        props.setActivePage("montage-list");
        props.setAddMontageBtnVisible(true);
        // props.setNewMontage(false);
        // sound.pause();
        // sound.currentTime = 0;
        // props.setAddMontageBtnVisible(true);
    }

    const removeDuplicates = (originalArray, prop) => {
        let newArray = [];
        let lookupObject = {};
        for (let i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }
        for (let i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }

    const handlePreview = () => {
        setPreviewFlag(true);
    }

    function arraysMatch(arrayA, arrayB) {
        let match = true;
        if (arrayA.length !== arrayB.length) match = false;
        arrayA.forEach((itemA, index) => {
            if (!isEqual(itemA, arrayB[index])) match = false;
        })
        return match;
    }

    function handleBack() {
        if (!isEqual(montageName, props.editMontageData.name)
            || !isEqual(musicFileUrl, props.editMontageData?.musicUrl)
            || !arraysMatch(pictureList, [...props.editMontageData?.fileList]))
            setDiscardModalOpen(true)

        else {
            handleCancel();
        }
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(pictureList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setPictureList(items);
    }


    useEffect(() => {
        console.log("props.editMontageData?.fileList",props.editMontageData?.fileList)
        if (props.editMontageData?.id) {
            setMusicFileUrl(props.editMontageData?.musicUrl);
            const musicFileName = props.editMontageData?.musicUrl.split('/').pop().replace(/([A-Z])/g, ' $1').trim().replace('.mp3', '')
            setMontageName(props?.editMontageData?.name);
            setPictureList([...props.editMontageData?.fileList]);
            props.editMontageData?.musicUrl !== '' ? setMusicName(musicFileName.replace(/[0-9$@%]/g, '')) : setMusicName("Add Sound");
            // setPreviewFlag(true);
        }
    }, [props.editMontageData]);




    const handleSave = async () => {

        if (montageName.trim() === "") {
            alert("Please add montage name");
            return;
        }
        if (pictureList.length == 0) {
            alert("Please add one or more pictures to save");
            return;
        }

        try {

            setIsloading(true);

            deletedImages.length !== 0 && deletedImages.map(async (deletePic) => {
                if (deletePic?.id) {
                    await deleteMontageFile(deletePic?.id, Number(localStorage.getItem("userId")));
                }
            });

            const obj = {
                Id: props.editMontageData?.id || 0,
                Name: montageName || "",
                MusicUrl: musicFileUrl ?? '',
                Description: '',
                ModifiedBy: Number(localStorage.userId),
                DateModified: moment().format('YYYY-MM-DD'),
            }
            const newMontageId = await saveMontages(obj);
            if (newMontageId.data === 0) {
                alert(`Montage with ${montageName} name already exist!`)
                setIsloading(false);
                return;
            }

            if (pictureList.length != 0) {

                const promiseRes = await Promise.all(pictureList.map(async (item, index) => {
                    let res = null
                    if (item.imageFrom == 'Gallery') {
                        const formData = new FormData();
                        formData.append('UserId', Number(localStorage.userId));
                        formData.append('PictureUrl', item.galleryUploadUrl);
                        res = await saveGalleryPictures(formData);
                    }
                    const obj = {
                        Id: item.imageFrom && item.imageFrom != '' ? 0 : item.id ?? 0,
                        MontageId: newMontageId.data,
                        SiteId: item.siteId,
                        FileUrl: res != null ? res.data : item.fileUrl,
                        FileOrder: index + 1,
                        ModifiedBy: Number(localStorage.userId),
                        ImageDescription: item.imageDescription ?? '',
                        DateModified: moment().format('YYYY-MM-DD'),
                    }
                    await saveMontagesFile(obj);
                }))

                if (promiseRes.length !== 0) {
                    setIsloading(false)
                    // setPictureList([])
                    props.fetchMontage();
                    // window.location.reload();
                    props.setActivePage("montage-list");
                    props.setAddMontageBtnVisible(true);
                    // handleCancel();
                }


            } else {
                console.log("ERROR:SAVE_MONTAGE");
            }

        } catch (error) {
            console.log("Error : ", error);
            setIsloading(false);
            if (error === "Content Inappropriate") {
                setInappropriateImageAlert(true);
            }
        } finally {
            setIsloading(false);
        }
    }

    const handleImageDescriptionChange = (e, id) => {
        let a = [];
        const tempArr = pictureList.map((item) => {
            a = item
            if (id == item.id) {
                item.imageDescription = e.target.value;
            }
            return a;
        })
        setPictureList(tempArr)
    }

     {/* new code sanket 01-04-2024 */ }
     useEffect(() => {
        const fetchUserprojectpicture = async () => {
            try {
                const temp = await getUserProjectPictures(Number(localStorage.getItem("userId")));
                console.log("temp-->", temp)
                let uniqueArray = removeDuplicates(temp, "projectId");
                const pictures = uniqueArray.map((unique) => {
                    let a = unique;
                    a['file'] = [];
                    temp.map((tempData) => {
                        if (tempData.projectId === unique.projectId) {
                            let obj = {
                                id: tempData.id, fileUrl: tempData.imageUrl, thumbImageUrl: tempData.thumbImageUrl, dateCreated: tempData.dateCreated,
                                isSelected: false, projectId: tempData.projectId, projectName: tempData.projectName, selectCount: 0, imageFrom: 'Project'
                            }
                            a['file'].push(obj);
                        }
                    })
                    return a;
                })
                console.log("Pictures", pictures);
                setPictureFile(pictures);
            } catch (error) {
                console.log("Error", error);
            }
        }
        fetchUserprojectpicture();
    }, [addPictureModalOpen])


    const handleDeletePic = async (item, index) => {
        if (item.imageFrom === "Gallery") {
            setImageCount(imageCount - 1);
        }
        let pictures = [];
        await deletedImages.push(item);
        deletedImages.map((deletePic) => {
            pictures = pictureList.filter((pic) => pic?.id !== deletePic?.id)
            return deletePic;
        })
        setDeletedImages(deletedImages);
        setPictureList(pictures);
    }

    const handleMontageName = (e) => {
        setMontageName(e.target.value);
    }


    return (
        <>

            {isLoading ? <Loader /> :
                <>
                    <audio id="fromSitePicture" src={musicFileUrl} />

                    <PromptModal
                        isOpen={discardModalOpen}
                        onClose={() => setDiscardModalOpen(false)}
                        title="Discard Changes?"
                        desc="Are you sure you want to discard the changes you made?"
                        redButtonText="Discard"
                        blueButtonTextFirst="No Thanks"
                        onRedButtonClick={handleCancel} />

                    <AlertModal
                        isOpen={inappropriateImageAlert}
                        onClose={() => setInappropriateImageAlert(false)}
                        title={"Content Rejected"}
                        body={'The text picture you entered has been automatically identified as inappropriate.'}
                        inappropriateImage
                        text1={'Please remove the content and try again.'}
                    />

                    <GetMusicModal
                        isOpen={getMusicModalOpen}
                        onClose={() => { getMusicModalSound.pause(); getMusicModalSound.currentTime = 0; setGetMusicModalOpen(false) }}
                        setMusicName={setMusicName}
                        handleChangeMusic={handleChangeMusic}
                    />

                    <AddPicturesModal
                        isOpen={addPictureModalOpen}
                        onClose={() => setAddPictureModalOpen(false)}
                        pictureList={pictureList}
                        pictures={pictureFile}
                        setPictureList={setPictureList}
                        setImageChooseFromModalOpen={setImageChooseFromModalOpen}
                        addIconPosition={addIconPosition}
                        setSelectedFromEmpty={setSelectedFromEmpty}
                        selectedFromEmpty={selectedFromEmpty}
                    />

                    <ImageChooseFromModal
                        isOpen={imageChooseFromModalOpen}
                        onClose={() => { setImageChooseFromModalOpen(false) }}
                        setAddPictureModalOpen={setAddPictureModalOpen}
                        setPictureList={setPictureList}
                        pictureList={pictureList}
                        addIconPosition={addIconPosition}
                        setSelectedFromEmpty={setSelectedFromEmpty}
                        selectedFromEmpty={selectedFromEmpty}
                        setImageCount={setImageCount}
                        imageCount={imageCount}
                        musicFileUrl={musicFileUrl}
                    />

                    <AlertModal
                        isOpen={saveMontageAlert}
                        onClose={() => setSaveMontageAlert(false)}
                        title={"Montage Saved Successfully..."}
                    />

                    <div className='custom-slider-container2' style={{ backgroundColor: 'white', margin: '40px 0px', paddingBottom: '20px' }}>
                        <div style={{ padding: '10px 10px 10px 10px' }}>
                            <div className="input-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '53%' }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            component="label"
                                            // fullWidth
                                            startIcon={<CancelOutlinedIcon />}
                                            style={{ marginRight: '10px' }}
                                            onClick={handleBack}
                                        >
                                            Back
                                        </Button>

                                        <TextField
                                            type="text"
                                            variant="outlined"
                                            label="Montage Name"
                                            value={montageName}
                                            size="small"
                                            onChange={handleMontageName}
                                        />
                                    </div>
                                    <div className="flex-container-vertical" style={{ alignSelf: "center" }}>
                                        <ListItemText style={{ textAlign: "center", color: "var(--buildid-blue)", cursor: "pointer" }} onClick={() => setGetMusicModalOpen(true)}>
                                            {musicName}
                                        </ListItemText>
                                    </div>
                                </div>

                                <div>

                                    <IconButton onClick={() => { setSelectedImage(0); stopCount(); }} aria-label="Replay" style={{ color: BIBlue, marginRight: '10px' }} size="medium">
                                        <RefreshIcon fontSize="inherit" />
                                    </IconButton>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        // fullWidth
                                        component="label"
                                        startIcon={<SaveIcon />}
                                        onClick={handleSave}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>

                        {previewFlag ?
                            <>
                                {pictureList.length !== 0 ?
                                    <div className="flex-container-vertical" onMouseOver={() => setToggleBtn(true)} onMouseOut={() => setToggleBtn(false)}>
                                        <Container maxWidth="md" >
                                            <div className='card-container'>
                                                <Grid item xs={12} md={8} sm={12} lg={8} >

                                                    <div className='mute-icon' style={{ zIndex: toggleBtn ? '99999' : "0" }}>
                                                        <IconButton style={{ color: BIBlue, transition: toggleBtn && '0.8s' }} onClick={handlePlay}>
                                                            {play ? <PauseCircleFilledIcon /> : <PlayCircleFilled />}
                                                        </IconButton>
                                                    </div>
                                                    <div sx={{ width: "100%", maxHeight: 500, }}>
                                                        <Carousel
                                                            autoPlay={play}
                                                            infiniteLoop
                                                            interval={5000}
                                                            showArrows={false}
                                                            showThumbs={false}
                                                            showStatus={false}
                                                            onChange={(index) => { setSelectedImage(index) }}
                                                            showIndicators={false}
                                                            selectedItem={selectedImage}
                                                            stopOnHover={false}
                                                        >
                                                            {pictureList?.map((item, picIndex) => {
                                                                return (
                                                                    <div key={picIndex} className="montage-img-container" style={{ display: 'flex', justifyContent: 'center' }} >

                                                                        <img src={item.fileUrl} alt="" />

                                                                        {item.imageDescription == "" ? <></> :
                                                                            <div className='card-desc-div'>
                                                                                <p className='card-descriptions' >
                                                                                    {item.imageDescription}
                                                                                </p>
                                                                            </div>}

                                                                    </div>)
                                                            })}
                                                        </Carousel>
                                                        <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{count} sec</p>
                                                    </div>
                                                </Grid>
                                            </div>
                                        </Container>
                                    </div>
                                    :
                                    <></>}
                            </> : <></>
                        }

                        <div style={{ position: "relative", display: pictureList.length !== 0 ? 'flex' : 'contents', justifyContent: "space-between" }}>

                            <Container maxWidth="md" style={{ overflow: 'auto', marginLeft: '60px', display: 'flex' }}>
                                <div className='card-container custom-slider-container' style={{ display: 'flex', justifyContent: 'center', padding: '20px 0px 30px 0px', }}>
                                    {
                                        (pictureList?.length > 0) &&
                                        <>
                                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                                <Grid item xs={12} md={8} sm={12} lg={8}>
                                                    <Droppable droppableId="droppable" direction="horizontal">
                                                        {(provided) => (
                                                            <div style={{ display: '-webkit-inline-box', width: '100%' }} {...provided.droppableProps} ref={provided.innerRef}>
                                                                {
                                                                    (pictureList?.length > 0)
                                                                        ?
                                                                        (pictureList?.map((item, picIndex, arr) => {
                                                                            return (<React.Fragment key={picIndex}>
                                                                                <Draggable key={item.id} draggableId={String(item.id)} index={picIndex}>
                                                                                    {(provided) => (
                                                                                        <span key={picIndex}>
                                                                                            <div onClick={() => { stopCount(); setSelectedImage(picIndex); setCount(picIndex * 5) }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}  >

                                                                                                <Card className='main-div' sx={{ maxWidth: 200, height: 130, }} style={{ position: 'relative', border: `2px solid ${item.imageFrom === "Blank" || item.imageFrom === "Gallery" ? BIYellow : item.imageFrom === "Site" ? 'red' : ''}` }}>
                                                                                                    <span style={{ position: 'absolute', cursor: 'pointer', height: '10px' }} onClick={() => { handleDeletePic(item, picIndex); handleChangeMusic(); }}>
                                                                                                        <CloseIcon style={{ color: BIBlue }} />
                                                                                                    </span>
                                                                                                    <CardMedia
                                                                                                        component="img"
                                                                                                        image={item.fileUrl ? item.fileUrl : URL.createObjectURL(item)}
                                                                                                        alt="green iguana"
                                                                                                        style={{ objectFit: 'contain' }}
                                                                                                    />
                                                                                                    <div className='card-desc-div' style={{ bottom: 0, margin: "0px 10px" }}>
                                                                                                        <div className='card-descriptions' style={{ paddingBottom: 0, marginBottom: '5px' }} >
                                                                                                            <TextField
                                                                                                                placeholder='Add On Text'
                                                                                                                type="text"
                                                                                                                variant="standard"
                                                                                                                value={item.imageDescription ?? ''}
                                                                                                                onChange={(e) => handleImageDescriptionChange(e, item.id)}
                                                                                                                inputProps={{ style: { height: "2px", color: 'white' } }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Card>

                                                                                            </div>
                                                                                        </span>
                                                                                    )}
                                                                                </Draggable>
                                                                                {arr.length - 1 !== picIndex ?
                                                                                    <span onClick={() => { setAddIconPosition(picIndex); setSelectedFromEmpty(false); setImageChooseFromModalOpen(true) }}
                                                                                        style={{ marginLeft: '5px', marginRight: '5px', cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}><AddCircleIcon style={{ color: BIBlue }} /></span>
                                                                                    : <></>
                                                                                }

                                                                            </React.Fragment>
                                                                            )
                                                                        }))
                                                                        : (<img style={{ display: "none" }} alt="" />)
                                                                } {provided.placeholder}
                                                            </div>)}
                                                    </Droppable>
                                                </Grid>
                                            </DragDropContext>

                                        </>

                                    }
                                </div>

                            </Container>
                            <Button variant="outlined" style={{ borderStyle: 'solid', marginBottom: '17px', marginLeft: pictureList.length !== 0 ? '25px' : '44%', color: BIBlue, width: '200px', height: '135px', right: '20px', top: '20px' }} onClick={() => { setImageChooseFromModalOpen(true); setSelectedFromEmpty(true); }} startIcon={<AddIcon />} />
                        </div>
                    </div> </>}
        </>
    )
}
