import { Avatar, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { BIGreen, BIRed ,BILightGrey} from '../../../assets/buildidColors'
import { ClientHeader } from '../ClientHeader'


const ReviewIndividualTimesheet = ({ id }) => {
    
    const imageList = [
        "https://picsum.photos/200/300",
        "https://picsum.photos/600",
        "https://picsum.photos/200/100",
        "https://picsum.photos/200/200",
        "https://picsum.photos/200/500",
        "https://picsum.photos/700",
        "https://picsum.photos/200/800",
        "https://picsum.photos/1000",
        "https://picsum.photos/250",
        "https://picsum.photos/350",
        "https://picsum.photos/150/250",
        "https://picsum.photos/150/50",
        "https://picsum.photos/300/200",
        "https://picsum.photos/400",
    ]
    return (
        <>
        <ClientHeader screenName={"Timesheets"}/>
            <div className="page">
                <div className="page-content">
                    <div className="flex-container-vertical timesheet-detail" style={{ flex: 2 }}>
                        <div style={{ margin: "15px" }}>
                            <Typography variant="subtitle1" className='detail-lable' gutterBottom>
                                Employee
                            </Typography>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                marginBottom={3}
                            >
                                <Avatar style={{}} alt="user" src={'https://picsum.photos/200/300'} />
                                <Typography variant='subtitle1' gutterBottom>Nathan Becker</Typography>
                            </Stack>

                            <Grid container rowSpacing={1}>
                                <Grid item md={4} xs={12}>
                                    <Typography>Date</Typography>
                                    <Typography fontWeight={'bold'}  > 28 November, 2002</Typography>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Typography> Submitted On</Typography>
                                    <Typography fontWeight={'bold'}  >12 January, 2021</Typography>
                                </Grid>
                            </Grid>
                            <Typography fontWeight={'bold'} marginTop={1} marginBottom={1}>Jobs</Typography>
                            <div style={{ backgroundColor: 'white', marginBottom: '10px', padding: '20px', borderRadius: '20px', cursor: 'pointer', maxWidth: '1350px' }}>
                                <Grid container rowSpacing={1}>
                                    <Grid md={4} xs={12}>
                                        <Typography>MW2-25166 Astra Petrofina</Typography>
                                        <Typography>50306A Accoustic Ceilings - Repair Grid</Typography>
                                    </Grid>
                                </Grid>

                                <Grid marginTop={1} marginBottom={1} container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography>Hours</Typography>
                                        <Typography>12.75</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>Mileage</Typography>
                                        <Typography>2.0 Km</Typography>
                                    </Grid>
                                </Grid>
                                <span style={{ background: BILightGrey, padding: '4px', borderRadius: '5px' }}>LOA</span>
                                <Grid marginTop={1} lg={12} md={12} padding={2} container spacing={2}>
                                    <Typography variant='subheading2' gutterBottom>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Vestibulum ornare laoreet urna, a tempus tellus lacinia id.
                                        Pellentesque eu leo vitae nibh imperdiet efficitur sed in leo. Sed auctor tristique mi.
                                        Fusce fringilla cursus turpis, et consequat leo condimentum eget.
                                    </Typography>
                                </Grid>
                                <Grid container spacing={1} className="job-imglist">
                                    {imageList?.length > 0
                                        && imageList?.map((picture, index) => {
                                            return (
                                                <Grid
                                                    marginRight={1}
                                                    key={`${index}`}
                                                >
                                                    <img
                                                        src={picture}
                                                        alt={`Picture ${index}`}
                                                        style={{
                                                            width: "100px",
                                                            height: "100px",
                                                            marginBottom: "10px",
                                                            borderRadius: "10px"
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </div>


                            <div style={{ backgroundColor: 'white', marginBottom: '10px', padding: '20px', borderRadius: '20px', cursor: 'pointer', maxWidth: '1350px' }}>
                                <Grid container rowSpacing={1}>
                                    <Grid md={4} xs={12}>
                                        <Typography>MW2-25166 Astra Petrofina</Typography>
                                        <Typography>50306A Accoustic Ceilings - Repair Grid</Typography>
                                    </Grid>
                                </Grid>

                                <Grid marginTop={1} marginBottom={1} container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography>Hours</Typography>
                                        <Typography>12.75</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>Mileage</Typography>
                                        <Typography>0.0 Km</Typography>
                                    </Grid>
                                </Grid>
                                <Grid marginTop={1} lg={12} md={12} padding={2} container spacing={2}>
                                    <Typography variant='subheading2' gutterBottom>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Vestibulum ornare laoreet urna, a tempus tellus lacinia id.
                                        Pellentesque eu leo vitae nibh imperdiet efficitur sed in leo. Sed auctor tristique mi.
                                        Fusce fringilla cursus turpis, et consequat leo condimentum eget.
                                    </Typography>
                                </Grid>
                            </div>

                            <div style={{ backgroundColor: 'white', marginBottom: '10px', padding: '20px', borderRadius: '20px', cursor: 'pointer', maxWidth: '1350px' }}>
                                <Grid container rowSpacing={1}>
                                    <Grid md={4} xs={12}>
                                        <Typography>MW2-25166 Astra Petrofina</Typography>
                                        <Typography>50306A Accoustic Ceilings - Repair Grid</Typography>
                                    </Grid>
                                </Grid>

                                <Grid marginTop={1} marginBottom={1} container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography>Hours</Typography>
                                        <Typography>12.75</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>Mileage</Typography>
                                        <Typography>2.0 Km</Typography>
                                    </Grid>
                                </Grid>
                                <Grid marginTop={2} container spacing={1} className="job-imglist">
                                    {imageList?.length > 0
                                        && imageList?.slice(9,15).map((picture, index) => {
                                            return (
                                                <Grid
                                                    marginRight={1}
                                                    key={`${index}`}
                                                >
                                                    <img
                                                        src={picture}
                                                        alt={`Picture ${index}`}
                                                        style={{
                                                            width: "100px",
                                                            height: "100px",
                                                            marginBottom: "10px",
                                                            borderRadius: "10px"
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </div>

                            <div style={{ backgroundColor: 'white', marginBottom: '10px', borderRadius: '20px', padding: '20px', }}>
                                <TextField
                                    fullWidth
                                    label="Site Name"
                                    type="text"
                                    multiline
                                    rows={4}
                                    value={""}
                                    onChange={(e) => { }}
                                />
                            </div>
                            <Stack direction={'row'} justifyContent={"flex-end"}>
                                <Button variant='contained' style={{ marginRight: '10px', background: BIRed }}>
                                    Reject
                                </Button>
                                <Button variant='contained' style={{ background: BIGreen }}>
                                    Approve
                                </Button>

                            </Stack>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReviewIndividualTimesheet