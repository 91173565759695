import React, { useState, useEffect, useCallback, useRef } from "react";
import { Container, Stack } from "@mui/material";
import ProfileHeader from "../../components/profile/ProfileHeader";
import AboutSection from "../../components/profile/AboutSection";
import Post from "../../components/post/Post";
import PostInput from "../../components/post/PostInput";
import {
	fetchSiteCompanies2, fetchSiteProducts, fetchFollowEntities,
	saveFollowEntities, saveSiteCompany, saveSiteProducts, FetchSitesPostsPictures,
	SaveSiteNames, fetchSiteNames, saveSitePosts, fetchSitesFavouriteImages, saveSitePostPicture
} from "../../api/SitesApi";
import { getSiteMembers } from "../../api/SitesApi";
import { getPosts } from "../../api/PostsApi";
import { fetchSites } from "../../api/SitesApi";
import { fetchUserTrades, fetchUserWorkHistory } from "../../api/UserApi";
import ProjectListSection from "../../components/profile/ProjectListSection";
import AlertProjectModal from "../../components/AlertProjectModal";
import PromptModal from "../../components/PromptModal";
import MemberListModal from "../../components/profile/MemberListModal";
import moment from "moment";
import { saveCompanies2 } from "../../api/CompanyApi";
import CompanyListSection from "../../components/profile/CompanyListSection";
import ListSection from "../../components/profile/ListSection";
import ProfilePic from "../../components/profile/ProfilePicListSection";
import AddAnotherNameModal from "../../components/AddAnotherNameModal";
import ProjectForm from "../profile/sections/ProjectForm";
import AddSiteModal from "../profile/sections/AddSiteModal";
import AlertModal from "../../components/AlertModal";
import BuildIcon from "@mui/icons-material/Build";
import WorkIcon from "@mui/icons-material/Work";
import Loader from "../../components/Loader";
import { inviteFriendsByEmail } from "../../api/ContactsApi";
import { saveChatGroupUsers } from "../../api/ChatApi";
import { useHistory } from "react-router-dom";

/*
Site Profile page
Site Tabs: About, Posts, Companies, Products
*/


export default function SiteProfile(props) {
	const userId = localStorage.userId;
	const appId = 2;	// 2 is the magic number for Sites
	const [siteInfo, setSiteInfo] = useState({});
	const [members, setMembers] = useState([]);
	const [activeTab, setActiveTab] = useState("posts");
	const [posts, setPosts] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isMember, setIsMember] = useState(false);
	const [isFollow, setIsFollow] = useState(false);
	const [followId, setFollowId] = useState(0);
	const [isAddToProfile, setIsAddToProfile] = useState(false);
	const [projectSectionModalOpen, setProjectSectionModalOpen] = useState(false);
	const [projectSectionAlert, setProjectSectionAlert] = useState(false)
	const [removeProjectSectionAlert, setRemoveProjectSectionAlert] = useState(false)
	const [memberListModalOpen, setMemberListModalOpen] = useState(false)
	const [otherSiteNameList, setOtherSiteNameList] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const fileInputRef = useRef(null);
	const [imageUrl, setImageUrl] = useState("");
	const [userTrade, setUserTrade] = useState([]);
	const [isUpdated, setIsUpdated] = useState(false);
	const [inappropriateImageAlert, setInappropriateImageAlert] = useState(false);
	const [recordId, setRecordId] = useState(0);
	const [imageLoading, setImageLodaing] = useState(false);
	const [isProductEmpty, setIsProductEmpty] = useState(false);
	const [isCompanyEmpty, setIsCompanyEmpty] = useState(false);
	const [getUpdatedImages, setGetUpdatedImages] = useState(false);
	const [showBtnLoader, setShowBtnLoader] = useState(false);
	const history = useHistory();

	useEffect(() => {
		getSiteDetails();
		getSiteMembersList();
		// getData();
		updatePosts();
		getUsertrades();
		return () => {
			setSiteInfo({});
			setMembers([]);
			setActiveTab("posts");
			setPosts([]);
			setIsLoading(false);
			setIsMember(false);
			setIsFollow(false);
			setFollowId(0);
			setIsAddToProfile(false);
			setProjectSectionModalOpen(false);
			setProjectSectionAlert(false);
			setRemoveProjectSectionAlert(false);
			setMemberListModalOpen(false);
			setOtherSiteNameList([]);
			setShowModal(false);
			setImageUrl("");
		}
	}, [])


	const updatePosts = useCallback(() => {
		getPosts(appId, Number(props.siteId), 1, 25).then(res => {
			if (res) { setPosts(res); setIsLoading(false) }
		})
	}, [props])

	// On load, retrieve Site info, members, and posts from API

	async function getSiteDetails() {
		setIsLoading(true);
		const siteRes = await fetchSites({ SearchList: [{ id: Number(props.siteId) }] });
		// There should only be 1 returned record
		// If nothing returned, then there wasn't a record with matching id
		if (siteRes.data.length >= 0) {
			setSiteInfo(siteRes.data[0])
			// If Site address exists, default tab is Posts
			// if (siteRes.data[0].address !== "") setActiveTab("posts");
		}
		await getSiteMembersList();
	}

	async function getSiteMembersList() {
		const memberRes = await getSiteMembers(Number(props.siteId));

		let currentUser = memberRes.filter((i) => i.userId == Number(localStorage.userId))
		let otherUsers = memberRes.filter((user) => user.userId != Number(localStorage.userId))
		otherUsers = otherUsers.sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()));
		const sortedUsers = currentUser.concat(otherUsers)
		setMembers(sortedUsers);
		await getOtherSiteNameList();
		await getSitePicturesList();
		await getData();

	}

	useEffect(() => {
		getOtherSiteNameList()
		getSitePicturesList()
	}, [props?.siteId])




	const getUsertrades = async () => {
		const userTradeObj = {
			PageNr: 1,
			FullSearch: "",
			IncludeRecordNr: false,
			TypeOfObjectReturned: "",
			FetchAllowedRecordsOnly: false,
			SearchList: [{ UserId: localStorage.userId }],
		}
		const userTradeRes = await fetchUserTrades(userTradeObj);
		const mainTrade = userTradeRes.filter((item) => item.tradeType.toLowerCase() == 'main')
		setUserTrade(mainTrade[0]);
	}



	const getSitePicturesList = async () => {
		const postPicObj = {
			PageNr: 1,
			NrOfRecPerPage: 12,
			FullSearch: "",
			UserId: Number(userId),
			IncludeRecordNr: true,
			FetchAllowedRecordsOnly: false,
			SearchList: [{ appRecordId: Number(props.siteId) }],
			SortList: [{ FieldName: 'Id', Direction: 'DESC' }]
		};
		await FetchSitesPostsPictures(postPicObj).then((res) => {
			setImageUrl(res?.[0]?.pictureUrl ?? "");
			setImageLodaing(false);
		}).catch((err) => {
			setImageLodaing(false);
			console.log("Error while getting site images:-", err)
		})
	}

	const getOtherSiteNameList = async () => {
		try {
			const obj = {
				PageNr: 1,
				FullSearch: "",
				IncludeRecordNr: false,
				TypeOfObjectReturned: "",
				SearchList: [{ SiteId: props?.siteId }]
			}
			await fetchSiteNames(obj).then((res) => {
				setOtherSiteNameList(res?.data ?? [])
			}).catch((err) => {
				console.log("Error while getting site name list:-", err)
			})
		} catch (err) {
			console.log("Error while getting site name list:-", err)
		}
	}

	async function getData() {
		//setIsLoading(true);
		try {
			await fetchFollowsEntity();
			await getWorkHistory();
			//setIsLoading(false);
		} catch (ERROR) {
			console.log("ERROR", ERROR)
			//setIsLoading(false);
		}
	}

	async function fetchFollowsEntity() {
		try {
			setIsLoading(true);
			const obj = {
				PageNr: 1,
				NrOfRecPerPage: 20,
				FullSearch: "",
				UserId: Number(localStorage.userId),
				IncludeRecordNr: true,
				TypeOfObjectReturned: "",
				SearchList: [{ EntityId: Number(props.siteId), userId: Number(localStorage.userId) }],
			}
			const followingData = await fetchFollowEntities(obj);
			if (followingData.length != 0) {
				setIsLoading(false);
				setFollowId(followingData.data[0]?.id ?? 0)
				setIsFollow(followingData.data[0]?.isFollowing)
			}
		} catch (Error) {
			console.log("GET_Follow Entity [ERROR]::", Error)
			setIsLoading(false);
		}
	}

	async function getSiteUsers() {
		try {
			await getSiteMembers(Number(props.siteId)).then((memberRes) => {
				const isSiteMember = memberRes.some((member) => member.userId === Number(localStorage.userId));
				setIsMember(isSiteMember);
				let currentUser = memberRes.filter((i) => i.userId == Number(localStorage.userId))
				let otherUsers = memberRes.filter((user) => user.userId != Number(localStorage.userId))
				otherUsers = otherUsers.sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()));
				const sortedUsers = currentUser.concat(otherUsers)
				setMembers(sortedUsers);
			});
		} catch (Error) {
			console.log("GET_Site Members [ERROR]::", Error)
			setIsLoading(false);
		}
	}

	async function handleSaveFollowClick(isFollow) {
		setIsLoading(true)
		try {
			const saveEntitiesObject = {
				UserId: Number(localStorage.userId),
				ReturnRecordId: true,
				ReturnRecordError: true,
				SaveList: [{
					Id: followId,
					EntityTypeId: 2,
					EntityId: Number(props.siteId),
					UserId: Number(localStorage.userId),
					IsFollowing: isFollow,
					ModifiedBy: Number(localStorage.userId)
				}]
			}
			const res = await saveFollowEntities(saveEntitiesObject);
			await getSiteUsers();
			await fetchFollowsEntity();
			await getWorkHistory();
			setIsLoading(false)
		} catch (error) {
			console.log("ERROR_SAVE_FOLLOW__:", error);
			setIsLoading(false);
		}

	}


	function isSubmitButtonPressed() {
		setIsUpdated(true)
	}


	function handleFollowPressed() {
		handleSaveFollowClick(true)
	}

	function handleFollowingPressed() {
		setRemoveProjectSectionAlert(true)
	}


	async function getWorkHistory() {
		try {
			// setIsLoading(true);
			const obj = {
				PageNr: 1,
				FullSearch: "",
				IncludeRecordNr: "true",
				SearchList: [{ Id: 0, UserId: Number(localStorage.userId), }]
			}
			const userWorkHistoryres = await fetchUserWorkHistory(obj);
			const currentSite = userWorkHistoryres?.filter((p) => p.siteId == Number(props.siteId))
			if (currentSite?.length > 0)
				setIsAddToProfile(true);
			// setIsLoading(false);
		} catch (Error) {
			console.log("GET_WORK_HISTORY[ERROR]::", Error)
			// setIsLoading(false);
		}
	}

	function handleAddToProfileButton() {
		setProjectSectionModalOpen(true)
	}

	function handleRightIconPressed() {
		// setProjectSectionAlert(true)
		setProjectSectionModalOpen(true)
	}

	function afterSaveSite() {
		if (!isFollow) {
			handleSaveFollowClick(true);
		}
		setProjectSectionModalOpen(false);
		setIsUpdated(true);
	}

	function handleMemberListPressed() {
		setMemberListModalOpen(true)
	}




	function saveComp(params) {
		if (params.companyName === "") {
			getSiteCompany(params);
		} else {
			handleSaveCompanies(params)
		}
	}

	async function handleSaveCompanies(params) {
		try {
			const saveObject = {
				userId: Number(userId),
				ReturnRecordId: true,
				ReturnRecordError: true,
				SaveList: [{
					Id: 0,
					Name: params.companyName,
					ModifiedBy: Number(userId),
					RecordStatusId: 1,
					Manufacturer: false
				}]
			}
			const siteCompaniesRes = await saveCompanies2(saveObject);
			let newItem = {
				id: siteCompaniesRes[0]?.objData?.id,
				roleId: params.roleId,
				otherRoleName: params.otherRoleName
			};
			getSiteCompany(newItem)
		} catch (error) {
			console.log("SAVE SITE_COMPANIES [ERROR]::", error)
		}
	}

	async function getSiteCompany(params) {
		try {
			setIsLoading(true);
			const obj = {
				Id: 0,
				SiteId: Number(props.siteId),
				CompanyId: params.companyName == '' ? params.id : params.id,
				CreatedBy: Number(userId),
				DateCreated: moment().format(),
				DateModified: moment().format(),
				ModifiedBy: Number(userId),
				SiteCompanyRoleId: params.roleId,
				OtherSiteCompanyRole: params.otherRoleName
			}
			await saveSiteCompany(obj);
			setIsLoading(false);
		} catch (ERROR) {
			console.log("GET SITE_COMPANIES [ERROR]::", ERROR)
			setIsLoading(false);
		}
	}

	function handleAddToProfileButton() {
		setProjectSectionModalOpen(true)
	}

	async function getSiteCompaniesData() {
		try {
			const obj = {
				Id: 0,
				PageNr: 1,
				FullSearch: "",
				IncludeRecordNr: false,
				SearchList: [{ siteId: Number(props.siteId) }],
				SortList: [{ FieldName: "CompanyName", Direction: "ASC" }]
			}
			const siteCompanies2 = await fetchSiteCompanies2(obj)
			setIsCompanyEmpty(siteCompanies2?.data?.length === 0 ? true : false)
			return siteCompanies2;
		} catch (Error) {
			console.log("GET SITE_COMPANIES-2 [ERROR]::", Error)
			setIsLoading(false);
		}
	}

	async function addProductToSitesData() {
		try {
			const obj = {
				FullSearch: "",
				SearchList: [{ siteId: Number(props.siteId) }],
				userId: Number(localStorage.userId),
				TypeOfObjectReturned: '',
				FetchAllowedRecordsOnly: true
			}
			const siteproducts = await fetchSiteProducts(obj)
			setIsProductEmpty(siteproducts?.data?.length === 0 ? true : false)
			return siteproducts;
		} catch (Error) {
			console.log("GET SITE_COMPANIES-2 [ERROR]::", Error)
			setIsLoading(false);
		}
	}

	async function fetchSitePostPictures(userId) {
		try {
			const postObj = {
				PageNr: 1,
				NrOfRecPerPage: 12,
				FullSearch: "",
				UserId: Number(userId),
				IncludeRecordNr: true,
				FetchAllowedRecordsOnly: false,
				SearchList: [{ appRecordId: Number(props.siteId) }],
				SortList: [{ FieldName: 'Id', Direction: 'DESC' }]
			};

			const postPictures = await FetchSitesPostsPictures(postObj);

			const favObj = {
				PageNr: 1,
				NrOfRecPerPage: 12,
				FullSearch: "",
				UserId: Number(localStorage.userId),
				IncludeRecordNr: true,
				TypeOfObjectReturned: "",
				FetchAllowedRecordsOnly: false,
				SearchList: [
					{ siteId: props?.siteId, ModifiedBy: Number(localStorage.userId) },
				],
			};

			const favoriteImagesResponse = await fetchSitesFavouriteImages(favObj);
			let b = [];
			const siteWithFavImages = postPictures.map((fav) => {
				b = fav;
				b["isFavorite"] = false;
				favoriteImagesResponse.map((img) => {
					if (img.sitePictureId === fav.pictureId) {
						b["isFavorite"] = true;
					}
				});
				return b;
			});
			return siteWithFavImages;
		} catch (error) {
			setIsLoading(false);
		}
	}

	const onProductSave = async (productId) => {
		if (!productId) return;
		try {
			setIsLoading(true);
			const obj = {
				UserId: Number(userId),
				ReturnRecordId: true,
				ReturnRecordError: true,
				SaveList: [{
					Id: 0,
					ProductId: productId,
					SiteId: Number(props.siteId),
					ModifiedBy: Number(userId),
					RecordStatusId: 1
				}]
			}
			await saveSiteProducts(obj);
			setIsLoading(false);
		} catch (ERROR) {
			console.log("GET SITE_Products [ERROR]::", ERROR)
			setIsLoading(false);
		}
	}

	const onDataSave = async () => {
		await fetchSites({ SearchList: [{ id: Number(props.siteId) }] }).then((res) => {
			setSiteInfo(res?.data?.[0] ?? {})
		}).catch((err) => {
			console.log("error while getting site data:-", err)
		})
	}


	const onMenuOptionClick = (data) => {
		if (data === "Add another Site name") {
			setShowModal(true)
		} else if (data === "Add Site picture") {
			fileInputRef.current.click();
		}
		else {
		}
	}


	const handleFileSelect = async (e) => {
		let files = e.target.files;
		if (files && files.length > 0) {
			let allFilesValid = true;

			for (let i = 0; i < files.length; i++) {
				if (!files[i].type.startsWith('image/')) {
					allFilesValid = false;
					break;
				}
			}

			if (allFilesValid) {
				setImageLodaing(true);
				const obj = {
					UserId: Number(localStorage.userId),
					ReturnRecordId: true,
					ReturnRecordError: false,
					SaveList: [
						{
							Id: 0,
							FormatTypeId: 1,
							PostedByUserId: Number(localStorage.userId),
							AppId: 2,
							AppRecordId: props?.siteId,
							Message: '<<<picture>>>',
							Reported: false,
							ModifiedBy: Number(localStorage.userId),
						},
					],
				};

				try {
					const postRes = await saveSitePosts(obj);

					for (let i = 0; i < files.length; i++) {
						const formData = new FormData();
						formData.append('Id', 0);
						formData.append('PostId', postRes[0].objData.id);
						formData.append('ModifiedBy', localStorage.userId);
						formData.append('Pictures', files[i]);
						await saveSitePostPicture(formData).catch((err) => {
							console.log("Error while calling the save post pictures API:", err);
							if (err === "Content Inappropriate") {
								setImageLodaing(false);
								setInappropriateImageAlert(true);
							}
						});
					}
					await getSitePicturesList();
					updatePosts();
					if (activeTab === "photos") {
						setGetUpdatedImages(true)
					}
				} catch (err) {
					setImageLodaing(false);
					console.log("Error while calling the save site API:", err);
				}
			} else {
				alert('Please select valid image files (JPEG, PNG, GIF, etc.).');
			}
		}
	};

	const onAotherNameSave = async (name) => {
		try {
			const obj = {
				UserId: Number(localStorage?.userId),
				ReturnRecordId: false,
				ReturnRecordError: true,
				SaveList: [{ Id: 0, SiteId: props?.siteId, Name: name, Current: false, RecordStatusId: 1, ModifiedBy: Number(localStorage?.userId) }]
			}
			await SaveSiteNames(obj).then((res) => {
				setOtherSiteNameList([...otherSiteNameList, { name: name }])
				setShowModal(false);
				updatePosts();
			}).catch((err) => {
				setShowModal(false)
				console.log("Error while saving another site name:-", err)
			})
		} catch (err) {
			setShowModal(false)
			console.log("Error while saving another site name:-", err)
		}
	}

	if (isLoading) {
		return (
			<div className="profile-loader">
				<Loader />
			</div>
		)
	}


	const handleAddContact = async (data) => {
		try {
			setShowBtnLoader(true);
			let obj = {
                UserId: localStorage.userId,
                FriendsEmails: data?.userEmail,
                Notification: {
                  ActionUrl: `${process.env.REACT_APP_CONTACTPROFILE}${Number(localStorage.userId)}`,
                  title: "Connect Request",
                  Body: "has requested to Connect you.",
                  //NotifyUserList: `${contact?.userId}`
                }
              };
			await inviteFriendsByEmail(obj);
			await getSiteUsers();
			setShowBtnLoader(false);
		} catch (error) {
			setShowBtnLoader(false);
			console.log("handleAddContact error", error);
		}
	}

	const navigateTochat = async (data) => {
		try {
			setShowBtnLoader(true)
			const saveListArray = [
				{
					Id: 0, ChatGroupId: 0,
					UserId: data?.userId,
					ModifiedBy: Number(localStorage.getItem('userId')),
					Muted: false
				}
			]
			const chatGroupObj = {
				UserId: Number(localStorage.getItem('userId')),
				ReturnRecordId: true,
				ReturnRecordError: true,
				SaveList: saveListArray
			}
			let result = await saveChatGroupUsers(chatGroupObj);
			if (result?.data?.length > 0) {
				let chatGroupId = result.data[0]?.objData.chatGroupId;
				history.push({
					pathname: "/chat",
					state: {
						from: "New Chat",
						chatGroupId: chatGroupId,
						showInList: true
					}
				})
			}
			setShowBtnLoader(false)
		} catch (error) {
			setShowBtnLoader(false)
			console.log("Error while sending to chat screen :: ", error)
		}
	}
	return (
		<div className="page"  >

			<AlertModal
				isOpen={inappropriateImageAlert}
				onClose={() => setInappropriateImageAlert(false)}
				title={"Content Rejected"}
				body={'The text picture you entered has been automatically identified as inappropriate.'}
				inappropriateImage
				text1={'Please remove the content and try again.'}
			/>
			<AddSiteModal
				setAddSiteModalOpen={() => {
					setProjectSectionModalOpen(false)
				}}
				addSiteModalOpen={projectSectionModalOpen}
				resetSelected={() => { setProjectSectionModalOpen(false) }}
				projectForm={
					<ProjectForm
						siteName={siteInfo?.name}
						siteAddress={siteInfo?.address}
						siteId={siteInfo?.id}
						tradeId={userTrade?.tradeId ?? 0}
						tradeName={userTrade?.tradeName ?? ""}
						isSiteNewForm={true}
						onReturnClick={() => {
							setProjectSectionModalOpen(false)
						}}
						onSaveFunction={true}
						onProjectSave={afterSaveSite}
					/>
				}
			/>

			<MemberListModal
				isOpen={memberListModalOpen}
				onClose={() => { setMemberListModalOpen(false) }}
				members={members}
				profileMemberList={false}
				handleConnectClick={handleAddContact}
				handleMessageClick={navigateTochat}
				showBtnLoader={showBtnLoader}
			/>


			<AlertProjectModal
				isOpen={projectSectionAlert}
				onClose={() => setProjectSectionAlert(false)}
				title={"This Site has been added to your Profile"}
				content={"To remove this, go to the Experience section on your Profile."}
			/>


			<PromptModal
				isOpen={removeProjectSectionAlert}
				onClose={() => { setRemoveProjectSectionAlert(false) }}
				title="Are you sure you want to unfollow this Site Page"
				redButtonText="Yes"
				blueButtonTextFirst="No"
				onRedButtonClick={() => {
					handleSaveFollowClick(false);
					setProjectSectionModalOpen(false);
					setRemoveProjectSectionAlert(false)
				}}
			/>

			<div className="page-content">
				<Stack spacing={2} style={{ margin: "16px 0px" }}>
					<ProfileHeader
						isFollow={isFollow}
						title={siteInfo.name}
						imageUrl={imageUrl}
						images={[]}
						recordId={recordId}
						setRecordId={setRecordId}
						subtitle={"Site"}
						handleFollowClick={() => handleFollowPressed()}
						handleFollowingClick={() => handleFollowingPressed()}
						handleAddToProfileButton={() => handleAddToProfileButton()}
						handleRightIconPressed={() => handleRightIconPressed()}
						handleMemberListPressed={() => handleMemberListPressed()}
						isLoading={isLoading}
						isMember={isMember}
						isAddToProfile={isAddToProfile}
						btnTitle1={"Follow"}
						btnTitle={"ADD TO PROFILE"}
						warningText={
							(siteInfo.address === "" && siteInfo.city === "" && siteInfo.province === "" && siteInfo.country === "" && siteInfo.postalCode === "")
								? "This Site will not be visible to others until an address is added."
								: false
						}
						members={members}
						// tabs={	// If no Site address, don't show Posts tab
						// 	(siteInfo.address === "")
						// 		? ["companies", "products"]
						// 		: ["posts", "photos", "companies", "products", "projects"]
						// }
						tabs={["posts", "photos", "companies", "products", "projects"]}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						menuOptions={["Add Site picture", "Add another Site name", "Send in Chat", "Share"]}
						onMenuOptionClick={onMenuOptionClick}
						shareDetails={{ type: "SiteDetail", id: props?.siteId }}
						imageLoading={imageLoading}
						from="Site"
					/>


					<Stack direction="row" spacing={2}>
						<div style={{ flex: 1 }}>
							<AboutSection
								withCard
								content={{
									siteType: siteInfo?.buildingTypeName,
									siteTimeframe: {
										startDate: siteInfo.startDate,
										endDate: siteInfo.endDate,
										constructionStatusId: siteInfo?.constructionStatusId
									},
									formattedAddress: {
										address: siteInfo.address,
										city: siteInfo.city,
										province: siteInfo.province,
										country: siteInfo.country,
										postalCode: siteInfo.postalCode
									},
									website: siteInfo.webSite,
								}}
								aboutSectionType={"site"}
								onDataSave={onDataSave}
								siteDetails={siteInfo}
								otherNameList={otherSiteNameList}

							/>
						</div>

						<div style={{ flex: 2 }}>
							{
								(activeTab === "posts")
									? (
										<Stack spacing={2}>
											<PostInput
												post={props.post}
												appId={2}
												appRecordId={Number(props.siteId)}
												updatePosts={updatePosts}
												onSaveFunction={true}
												onPostSave={getSitePicturesList}
											/>
											{
												posts.map((post, index) => (
													<Post key={index} post={post} from={"site"}/>
												))
											}
										</Stack>
									)
									: <></>
							}

							{
								(activeTab === "companies")
									? (
										<CompanyListSection
											getData={getSiteCompaniesData}
											itemIdKey="companyId"
											itemNameKey="companyName"
											itemRecordType="company"
											categoryIdKey="siteCompanyRoleId"
											categoryNameKey="siteCompanyRole"
											otherSiteCompanyRole="otherSiteCompanyRole"
											searchPlaceholder="Search this Site's Companies"
											addButtonLabel="Add Company"
											saveItem={saveComp}
											recordId={recordId}
											setRecordId={setRecordId}

											emptyData={isCompanyEmpty}
											NoResultBtn="Add Company"
											NOResultIcon={<WorkIcon />}
										/>
									)
									: <></>
							}

							{
								(activeTab === "products")
									? (
										<ListSection
											getData={addProductToSitesData}
											itemIdKey="productId"
											itemNameKey="productName"
											categoryIdKey="manufacturerId"
											categoryNameKey="manufacturerName"
											itemRecordType="product"
											categoryRecordType="manufacturer"
											searchPlaceholder="Search this Site's Products"
											addButtonLabel="Add Product"
											onProductSave={onProductSave}
											recordId={recordId}
											setRecordId={setRecordId}
											emptyData={isProductEmpty}
											NoResultBtn="Add Product"
											NOResultIcon={<BuildIcon />}
											NoResultText="No information for this site."
											NoResultText2="Know any information? Add it here for builderbucks"
											addItemLabel="Product Name"
											showManufactureNameInNewAddProduct={true}
										/>
									)
									: <></>
							}

							{
								(activeTab === "projects")
									? (
										<ProjectListSection
											siteId={Number(props.siteId)}
											isUpdated={isUpdated}
											siteInfo={{ name: siteInfo?.name ?? "", address: siteInfo?.address ?? "", id: Number(props.siteId) }}
											userTrade={userTrade}
										/>
									)
									: <></>
							}
							{
								(activeTab === "photos")
									? (
										<ProfilePic siteId={props.siteId} getData={() => fetchSitePostPictures(userId, props.siteId)}
											getUpdatedImages={getUpdatedImages}
											updateValueOfGetUpdatedUmages={() => {
												setGetUpdatedImages(false);
											}}
										/>
									) : <></>
							}
						</div>
					</Stack>
				</Stack>
			</div>

			<input
				type="file"
				ref={fileInputRef}
				style={{ display: 'none' }}
				onChange={handleFileSelect}
				accept="image/*"
				multiple
			/>
			<AddAnotherNameModal
				onClose={() => {
					setShowModal(false)
				}}
				open={showModal}
				label="Another Site Name"
				onAotherNameSave={onAotherNameSave}
			/>
		</div>
	)
}
