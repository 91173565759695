import React, { useState, useEffect, useCallback } from "react";
import {
	Card,
	Typography,
	IconButton, Button,
	Menu, MenuItem
} from "@material-ui/core";
import {
	Close as CloseIcon,
	MoreVert as MoreIcon
} from "@material-ui/icons";

import {
	fetchSitesPictures,
	saveSitesPictures
} from "../../api/SitesApi";
import { useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Viewer from "react-viewer";
import AboutSection from "../../components/profile/AboutSection";
import placeholder from "../../assets/images/project-profile-placeholder.jpg";
import { BIRed } from "../../assets/buildidColors";
import AddSiteModal from "../profile/sections/AddSiteModal";
//import { ProjectForm } from "../profile/sections/ExperienceCard";


/*
	A detail overlay sidebar displaying details for a single site
*/
export default function SiteDetail2(props) {
	const history = useHistory();

	// If anchor for "More" menu exists, show the menu 
	const [moreMenuAnchor, setMoreMenuAnchor] = useState(null);
	const [imageViewMode, setImageViewMode] = useState(false);
	const [viewerIndex, setViewerIndex] = useState(0);

	const [pictures, setPictures] = useState([]);

	const [addSiteModalOpen, setAddSiteModalOpen] = useState(false);
	const [siteName, setSiteName] = useState(props.site?.siteName);
	const [siteAddress, setSiteAddress] = useState("");
	const [siteId, setSiteId] = useState(props.site?.id);


	// API save uploaded picture to this site's pictures
	function handleUploadSitePicture(event) {
		let formData = new FormData();
		formData.append("Id", props.site.id);
		formData.append("CreatedBy", localStorage.userId);
		formData.append("ModifiedBy", localStorage.userId);
		formData.append("photo", event.target.files[0]);

		saveSitesPictures(formData)	// Works, but interestingly no response?
			.then(res => {
				updateSitePictures();
			})
	}


	// Retrieve Site Pictures from API and display them in the Carousel
	const updateSitePictures = useCallback(() => {
		fetchSitesPictures({ SearchList: [{ SiteId: props.site.id }] })
			.then(pictureRes => setPictures(pictureRes))
	}, [props.site.id])

	// On props.site change, fetch props.site's associated pictures
	useEffect(() => {
		updateSitePictures();
	}, [props.site, updateSitePictures])




	return (
		<>
			{/* {props.companyData && <AddSiteModal
				setAddSiteModalOpen={setAddSiteModalOpen}
				addSiteModalOpen={addSiteModalOpen}
				projectForm={<ProjectForm
					siteName={siteName}
					siteAddress={siteAddress}
					project={{
						id: 0,
						siteId: siteId,
						siteName: siteName,
						companyId: props.companyData.projectSelectCompany?.companyId,
						companyName: props.companyData.projectSelectCompany?.companyName,
						roleId: props.companyData.projectSelectCompany?.roleId,
						roleName: props.companyData.projectSelectCompany?.roleName,
						tradeId: 0,
						tradeName: "",
						startDate: null,
						endDate: null,
						description: ""
					}}
					company={props.companyData?.projectSelectCompany}
					onReturnClick={() => {
						// props.updateWorkHistory();
						// props.resetSelected();
						setAddSiteModalOpen(false);
					}}
				/>}

			/>} */}

			<Card raised className="site-overlay-container" style={{ height: "100%" }}>
				<Viewer
					visible={imageViewMode}
					activeIndex={viewerIndex}
					onClose={() => setImageViewMode(false)}
					onMaskClick={() => setImageViewMode(false)}
					zoomSpeed={0.75}
					minScale={1}
					zoomable={false}
					scalable={false}
					rotatable={false}
					noImgDetails={true}
					customToolbar={(toolbars) => {
						return toolbars.filter((button) => { return button.key !== "reset" })
					}}
					images={pictures.map(pic => (
						{
							src: pic.imageUrl,
							alt: ""
						}
					))}
				/>

				<div>
					{/* SitePictures Section, with viewer */}
					<div className="picture-container" style={{ width: "100%", height: "320px" }}>
						{
							(pictures.length > 0)
								? (
									<div style={{ height: "100%" }}>
										<div className="picture-container-overlay"
											onClick={() => {
												setViewerIndex(0);
												setImageViewMode(true);
											}} />

										<img
											src={pictures[0].imageUrl}
											alt=""
											style={{ width: "100%", objectFit: "cover" }} />
									</div>
								)
								: (
									<img
										src={placeholder}
										alt="placeholder"
										style={{ width: "100%", objectFit: "cover" }} />
								)
						}

						{/* Floating Close IconButton is declared after picture-container-overlay to appear on top */}
						<div className="picture-container-button">
							<IconButton
								size="small"
								onClick={props.handleClose}>
								<CloseIcon />
							</IconButton>
						</div>
					</div>

					{/* Site overview section */}
					<div className="flex-container-vertical" style={{ margin: "16px 24px" }}>
						<div className="flex-container-horizontal" style={{ justifyContent: "space-between" }}>
							<Typography variant="h5">{props.site.name}</Typography>

							{/* TODO change this into an IconButton */}
							<MoreIcon
								className="link"
								color="action"
								onClick={event => setMoreMenuAnchor(event.currentTarget)} />
							<Menu
								open={Boolean(moreMenuAnchor)}
								onClose={() => setMoreMenuAnchor(null)}
								anchorEl={moreMenuAnchor}
								anchorOrigin={{ vertical: "top", horizontal: "right" }}>
								<MenuItem component="label">
									Add site picture
									<input
										type="file"
										onChange={event => {
											handleUploadSitePicture(event);
											setMoreMenuAnchor(null);	// Uploaded image, close menu
										}}
										hidden />
								</MenuItem>
								<MenuItem>Add another site name</MenuItem>
								<MenuItem>Mute notifications</MenuItem>
								<MenuItem >Hide posts on newsfeed</MenuItem>
								<MenuItem style={{ color: BIRed }}>Report</MenuItem>
							</Menu>
						</div>

						<div style={{ marginTop: "24px" }}>
							<AboutSection
								content={{
									siteType: props.site.buildingTypeName,
									siteTimeframe: {
										startDate: props.site.startDate,
										endDate: props.site.endDate
									},
									formattedAddress: {
										address: props.site.address,
										city: props.site.city,
										province: props.site.province,
										country: props.site.country,
										postalCode: props.site.postalCode
									},
									website: props.site.webSite,
								}} />
						</div>
					</div>

					<div style={{ margin: "24px 16px", display: 'flex' }}>
						<Button
							color="primary"
							style={{ marginRight: '5px' }}
							variant="contained"
							fullWidth
							onClick={() => history.push("/sites/site/" + props.site.id)}>
							View Site
						</Button>
						<Button
							color="primary"
							variant="contained"
							fullWidth
							onClick={() => {
								setSiteName(props.site.name);
								setSiteId(props.site.id);
								setSiteAddress(props.site.address)
								props.companyData ? setAddSiteModalOpen(true) : history.push("/sites/site/" + props.site.id);
							}
							}>
							Add To Profile
						</Button>
					</div>
				</div>
			</Card>
		</>
	)
}
