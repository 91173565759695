import React, { useState, useEffect } from "react";
import { IconButton, Button, TextField, Typography } from "@material-ui/core";
import {
  Edit as EditIcon,
  Work as WorkIcon,
  DirectionsCar as DirectionsCarIcon,
  Save as SaveIcon,
  Close as CloseIcon,
  Backup as UploadIcon,
} from "@material-ui/icons";
import PictureViewer from "../../components/PictureViewer";
import ProfilePicturePlaceholder from "../../assets/images/profile-picture-placeholder.png";
import Loader from "../../components/Loader";
import PromptModal from "../../components/PromptModal";
import {
  fetchUserTrades,
  getUserInfo,
  savePersonalInfo,
} from "../../api/UserApi";
import { Box, Chip, Divider, Modal, Stack } from "@mui/material";
import { BIBlue, BIGrey, BIRed } from "../../assets/buildidColors";
import TradeCardModal from "./sections/TradeCardModal";
import AlertModal from "../../components/AlertModal";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { isEmpty } from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
export default function ProfileHeader(props) {
  const isLargeScreen = useMediaQuery("(max-width:720px)");
  const [profilePictureViewerOpen, setProfilePictureViewerOpen] =
    useState(false);
  // Details
  const [profileDetail, setProfiledetail] = useState({
    firstName: "",
    lastName: "",
    userEmail: "",
  });
  // Employment is misspelled in the API as "employEment"
  // Code is spelled correctly (as "employment"); make note of this difference for API calls
  const [employmentStatusId, setEmploymentStatusId] = useState(0);
  const [employmentStatus, setEmploymentStatus] = useState("");

  // Profile picture
  const [displayPicture, setDisplayPicture] = useState(null);
  const [rideShareInterested, setRideShareInterested] = useState(false);

  const [userTrade, setUserTrade] = useState([]);

  const [displayedTradeCount, setDisplayedTradeCount] = useState(0);
  const [initialViewMore, setInitialViewMore] = useState(true);

  const [tradeLoading, setTradeLoading] = useState(true);
  const [tradeCardOpen, setTradeCardOpen] = useState(false);
  const [formModalOpen, setFormModalOpen] = useState(false);

  // On load (and ProfileEditMode change), get profile details from API
  useEffect(() => {
    try {
      getUserInfo(localStorage.userId).then((res) => {
        setProfiledetail({
          ...profileDetail,
          firstName: res.firstName,
          lastName: res.lastName,
          userEmail: res.userEmail,
        });
        // Note mispelling of "employEment" in API response
        setEmploymentStatus(res.employementStatus || "");
        setEmploymentStatusId(res.employementStatusId || 0);
        setRideShareInterested(res.rideShareInterested || false);
        setDisplayPicture(res.pictureUrl || "");
      });
      if (isLargeScreen) {
        setDisplayedTradeCount(5);
      } else {
        setDisplayedTradeCount(10);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }, []);

  useEffect(() => {
    async function getDashboard() {
      const userTradeObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
        SearchList: [{ UserId: localStorage.userId }],
      };
      const userTradeRes = await fetchUserTrades(userTradeObj);
      const userTradeRes2 = userTradeRes.map((trade) => {
        trade.tradeType = trade.tradeType.toLowerCase();
        return trade;
      });
      setUserTrade(userTradeRes2);
      setTradeLoading(false);
    }
    getDashboard();
    if (isLargeScreen) {
      setDisplayedTradeCount(5);
    }
  }, []);

  const onDataSave = () => {
    fetchUserTrades({ SearchList: [{ UserId: localStorage.userId }] })
      .then((manufacturerRes) => {
        const userTradeRes2 = manufacturerRes.map((trade) => {
          trade.tradeType = trade.tradeType.toLowerCase();
          return trade;
        });
        setUserTrade(userTradeRes2);
      })
      .catch((err) => {
        console.log("Error while getting info after saving the data", err);
      });
  };

  const handleShowMoreTrades = () => {
    setInitialViewMore(false);
    setDisplayedTradeCount(userTrade.length);
  };

  const handleShowLessTrades = () => {
    setInitialViewMore(true);
    setDisplayedTradeCount(10);
  };

  if (props.loading) {
    return <></>;
  }

  return (
    <>
      <PictureViewer
        isOpen={profilePictureViewerOpen}
        onClose={() => {
          setProfilePictureViewerOpen(false);
        }}
        src={displayPicture || ProfilePicturePlaceholder}
      />

      {/* new code sanket 19-03-2024  */}
      <ProfileHeaderForm
        profileDetail={profileDetail}
        setProfiledetail={setProfiledetail}
        employmentStatusId={employmentStatusId}
        employmentStatus={employmentStatus}
        rideShareInterested={rideShareInterested}
        setDisplayPicture={setDisplayPicture}
        onChangeSidebarInfo={props.onChangeSidebarInfo}
        onOpenModal={formModalOpen}
        onCloseMOdal={() => {
          setFormModalOpen(false);
        }}
        reOpenMOdal={() => {
          setFormModalOpen(true);
        }}
        displayPicture={displayPicture}
        userTradeList={userTrade}
        onDataSave={onDataSave}
        emptyTradeList={!tradeLoading && userTrade.length === 0}
      />

      {tradeCardOpen && (
        <TradeCardModal
          isOpen={tradeCardOpen}
          onClose={() => {
            setTradeCardOpen(false);
          }}
          userTradeList={userTrade}
          onDataSave={onDataSave}
        />
      )}

      <div
        className="profile-header-details"
        style={{ alignItems: "flex-start" }}
      >
        <div className="circle-picture-container" style={{ margin: "10px" }}>
          <div
            onClick={() => setProfilePictureViewerOpen(true)}
            className="profile-pic"
          >
            <img
              src={displayPicture || ProfilePicturePlaceholder}
              alt="User profile pic"
              className="profile-pic-in-profile"
            />
          </div>
        </div>

        <div className="trade-detail" style={{ width: "80%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {profileDetail?.firstName || profileDetail?.lastName ? (
              <h1 className="user-name" style={{ fontSize: "35px" }}>
                {profileDetail?.firstName} {profileDetail?.lastName}
              </h1>
            ) : (
              <Typography
                variant="subtitle1"
                style={{ cursor: "pointer", fontWeight: "bold" }}
                onClick={() => setFormModalOpen(true)}
              >
                Please enter name
              </Typography>
            )}
            <div>
              <IconButton
                color="primary"
                onClick={() => setFormModalOpen(true)}
              >
                <EditIcon />
              </IconButton>
            </div>
          </div>

          <Stack
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "center",
            }}
            direction="row"
            spacing={0}
          >
            {!tradeLoading ? (
              userTrade.length > 0 ? (
                <>
                  {userTrade
                    ?.slice(0, displayedTradeCount)
                    .sort(
                      (a, b) =>
                        a.tradeType
                          ?.toLowerCase()
                          .localeCompare(b.tradeType?.toLowerCase()) ||
                        a.tradeName
                          ?.trim()
                          .toLowerCase()
                          .localeCompare(b.tradeName?.trim().toLowerCase())
                    )
                    .map((item, index) => {
                      return (
                        <Chip
                          size="small"
                          key={index.toString()}
                          label={item.tradeName?.trim()}
                          style={{
                            backgroundColor:
                              item?.tradeType?.toLowerCase() == "main"
                                ? BIBlue
                                : "null",
                            color:
                              item?.tradeType?.toLowerCase() == "main"
                                ? "white"
                                : "null",
                          }}
                        ></Chip>
                      );
                    })}
                  {userTrade.length > 10 && (
                    <>
                      {initialViewMore ? (
                        <Button
                          style={{ borderRadius: "50px" }}
                          color="primary"
                          size="small"
                          variant="text"
                          onClick={handleShowMoreTrades}
                        >
                          View More <KeyboardArrowDown fontSize="small" />
                        </Button>
                      ) : (
                        <Button
                          style={{ borderRadius: "50px" }}
                          color="primary"
                          size="small"
                          variant="text"
                          onClick={handleShowLessTrades}
                        >
                          View Less <KeyboardArrowUp fontSize="small" />
                        </Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div>
                    <div style={{ color: "gray" }}>
                      Connect with similar BuildID users by adding your Trade(s){" "}
                    </div>
                    <div>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setTradeCardOpen(true);
                        }}
                        style={{ margin: "10px 0" }}
                      >
                        Add My Trade
                      </Button>
                    </div>
                  </div>
                </>
              )
            ) : (
              <></>
            )}
          </Stack>
        </div>
      </div>
    </>
  );
}

function ProfileHeaderForm(props) {
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  // Details
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [newDisplayPicture, setNewDisplayPicture] = useState(
    props.displayPicture
  );
  // Form functionality vars
  const [selectedFile, setSelectedFile] = useState("");

  const [pictureViewerOpen, setPictureViewerOpen] = useState(false);
  const [tradeCardOpen, setTradeCardOpen] = useState(false);
  //const [buttonDisable, setButtonDisable] = useState(false)
  const [inappropriateImageAlert, setInappropriateImageAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  // Updates filename and file in state based on form input
  function handleChooseFile(event) {
    setSelectedFile(event.target.files[0]);
    // setSelectedFileName(event.target.value);
    setNewDisplayPicture(URL.createObjectURL(event.target.files[0]));
  }

  function handleFirstName(event) {
    setFirstName(event.target.value);
  }

  function handleLastName(event) {
    const containsSpace = event.target.value.includes(" ");
    console.log("cont", containsSpace);
    if (containsSpace) {
      setLastName("");
    } else {
      setLastName(event.target.value);
    }
  }

  // API saves values from form
  async function handleSaveButtonClick() {
    try {
      setLoading(true);
      var formData = new FormData();
      formData.append("Id", Number(localStorage.userId));
      formData.append("UserEmail", props?.profileDetail?.userEmail);
      formData.append("FirstName", firstName);
      formData.append("LastName", lastName);
      formData.append("ModifiedBy", Number(localStorage.userId));
      formData.append("PictureUrl", selectedFile);
      console.log("selectedFile", selectedFile);
      if (!isEmpty(selectedFile?.name)) {
        props.setDisplayPicture(URL.createObjectURL(selectedFile));
      }
      await savePersonalInfo(formData);
      await getUserInfo(localStorage.userId);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      console.log("error : ", error);
      if (error) {
        setInappropriateImageAlert(true);
      }
    }
    try {
      setLoading(true);
      // Retrieve new profile picture link for localStorage
      let newPicLink = "";
      await getUserInfo(localStorage.userId).then(
        (res) => (newPicLink = res.pictureUrl)
      );
      // Propagate name/picture to update in sidebar
      console.log("props.userTradeList", props.userTradeList);
      /* new code sanket 19-03-2024 */
      props.setProfiledetail({
        ...props.profileDetail,
        firstName: firstName,
        lastName: lastName,
      });

      props.onChangeSidebarInfo(
        newPicLink,
        firstName,
        lastName,
        props?.userTradeList[0]?.tradeName
      );
      props.onCloseMOdal();
      setLoading(false);
      //setButtonDisable(false);
    } catch (error) {
      console.log(error);
      alert("Error retrieving profile picture from API!");
      setLoading(false);
    } finally {
      //setButtonDisable(false);
    }
  }

  useEffect(() => {
    if (props) {
      setFirstName(props?.profileDetail?.firstName);
      setLastName(props?.profileDetail?.lastName);
      setNewDisplayPicture(props.displayPicture);
    }
  }, [props]);

  return (
    <>
      <PictureViewer
        isOpen={pictureViewerOpen}
        onClose={() => {
          setPictureViewerOpen(false);
          props.reOpenMOdal();
        }}
        src={newDisplayPicture || ProfilePicturePlaceholder}
      />

      <AlertModal
        isOpen={inappropriateImageAlert}
        onClose={() => setInappropriateImageAlert(false)}
        title={"Content Rejected"}
        body={
          "The text picture you entered has been automatically identified as inappropriate."
        }
        inappropriateImage
        text1={"Please remove the content and try again."}
      />

      <PromptModal
        isOpen={discardModalOpen}
        onClose={() => setDiscardModalOpen(false)}
        title="Are you sure you want to leave?"
        desc="Any unsaved changes will be discarded."
        redButtonText="Discard"
        blueButtonTextFirst="Go Back"
      />

      {tradeCardOpen && (
        <TradeCardModal
          isOpen={tradeCardOpen}
          onClose={() => {
            setTradeCardOpen(false);
            props.reOpenMOdal();
          }}
          userTradeList={props.userTradeList}
          onDataSave={() => {
            props.onDataSave();
          }}
        />
      )}

      <Modal open={props.onOpenModal} onClose={props.onCloseMOdal}>
        <>
          <div
            className="modal-center timesheet-modal"
            style={{ top: "43%", overflow: "scroll", maxHeight: "90%" }}
          >
            <div
              className="modal-card"
              style={{ paddingTop: "0px", margin: "10px 20px" }}
            >
              <>
                <div
                  className="modal-card-header-buttons"
                  style={{ alignItems: "center" }}
                >
                  <h2>Edit Profile</h2>
                  <IconButton
                    color="primary"
                    onClick={async () => {
                      props.onCloseMOdal();
                      //setButtonDisable(false)
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="modal-card-body" style={{ padding: "0px" }}>
                  <h3 style={{ paddingLeft: "10px" }}>Profile Picture</h3>
                  <div
                    className="flex-container-vertical"
                    style={{ alignItems: "center" }}
                  >
                    <div
                      className="circle-picture-container"
                      style={{ margin: "10px" }}
                    >
                      <div
                        className="picture-container-overlay"
                        onClick={() => {
                          setPictureViewerOpen(true);
                        }}
                      />
                      <div className="profile-pic">
                        <img
                          src={newDisplayPicture || ProfilePicturePlaceholder}
                          alt="User profile pic"
                        />
                      </div>
                    </div>

                    <div className="input-container">
                      <Button
                        variant="outlined"
                        color="primary"
                        component="label"
                        startIcon={<UploadIcon />}
                      >
                        Change Profile Picture
                        <input
                          type="file"
                          value={""}
                          onChange={handleChooseFile}
                          hidden
                        />
                      </Button>
                    </div>
                  </div>
                  <h3 style={{ paddingLeft: "10px" }}>Name</h3>
                  <div
                    className="flex-container-vertical"
                    style={{ alignItems: "stretch" }}
                  >
                    <div className="input-container">
                      <TextField
                        fullWidth
                        id="firstname-textfield"
                        label="First Name"
                        variant="outlined"
                        value={firstName}
                        onChange={handleFirstName}
                        // onChange={event => setFirstName(event.target.value)}
                      />
                    </div>
                    <div className="input-container">
                      <TextField
                        fullWidth
                        id="lastname-textfield"
                        label="Last Name"
                        variant="outlined"
                        value={lastName}
                        onChange={handleLastName}
                      />
                    </div>
                  </div>
                  <Divider sx={{ margin: "10px 0px" }} />
                  <div
                    className="modal-card"
                    style={{ paddingTop: "0px", margin: "0px" }}
                  >
                    <div
                      className="modal-card-header-buttons"
                      style={{ alignItems: "flex-start" }}
                    >
                      {props?.emptyTradeList ? (
                        <>
                          <div style={{ margin: "auto", textAlign: "center" }}>
                            <div style={{ color: "gray" }}>
                              Connect with similar BuildID users by adding your
                              Trade(s)
                            </div>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                setTradeCardOpen(true);
                                props.onCloseMOdal();
                              }}
                              style={{ margin: "10px 0" }}
                            >
                              Add My Trade
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <Stack
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "10px 10px",
                            }}
                            direction="row"
                            spacing={0}
                          >
                            {props.userTradeList
                              ?.sort(
                                (a, b) =>
                                  a.tradeType
                                    ?.toLowerCase()
                                    .localeCompare(
                                      b.tradeType?.toLowerCase()
                                    ) ||
                                  a.tradeName
                                    ?.trim()
                                    .toLowerCase()
                                    .localeCompare(
                                      b.tradeName?.trim().toLowerCase()
                                    )
                              )
                              .map((item, index) => {
                                return (
                                  <Chip
                                    key={index.toString()}
                                    label={item.tradeName?.trim()}
                                    style={{
                                      backgroundColor:
                                        item?.tradeType?.toLowerCase() == "main"
                                          ? BIBlue
                                          : "null",
                                      color:
                                        item?.tradeType?.toLowerCase() == "main"
                                          ? "white"
                                          : "null",
                                    }}
                                  ></Chip>
                                );
                              })}
                          </Stack>
                          <IconButton
                            color="primary"
                            onClick={() => {
                              setTradeCardOpen(true);
                              props.onCloseMOdal();
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </div>
                  {loading ? (
                    <Button
                      disabled={true}
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="medium"
                      component="label"
                    >
                      <CircularProgress size={24} />
                    </Button>
                  ) : (
                    <div
                      className="input-container"
                      style={{ padding: "10px 0px", display: "flex" }}
                    >
                      <Button
                        disabled={!firstName || !lastName}
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="medium"
                        component="label"
                        startIcon={<SaveIcon />}
                        onClick={handleSaveButtonClick}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}
