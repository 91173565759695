import axios from "axios";

//export const process.env.REACT_APP_BASE_URL = "https://bimiscwebapi-test-3-8.azurewebsites.net/api/"

export function getDbValue() {
	if (process.env.REACT_APP_BASE_URL.includes("test")) {
		localStorage.setItem("db", 3);
	} else if (process.env.REACT_APP_BASE_URL.includes("staging")) {
		localStorage.setItem("db", 2);
	} else {
		localStorage.setItem("db", 1);
	}
}


export async function restGet(apiURL, headers) {
	try {
		const res = await axios.get(process.env.REACT_APP_BASE_URL + apiURL, { headers });
		if (res && res.data && res.data.status && res.data.status === true)
			return res.data.data;
		// Special case for IsValidResetCode GET
		// The response.data is a string with either "OK" or "Not a valid Email address !", instead of the
		// typical {data, message, status} so the normal check is not appropriate
		else if (res?.data === "OK")
			return true;
		else if (res?.data === "Not a valid Email address !")
			return false;
		else if (res?.data === "Invalid reset code!")
			throw res.data;
		else if (res?.data === "Verification code is invalid!" || res?.data === "Invalid email !")
			throw res.data;
		else if (res?.data === "Error ! Cannot generate reset code ! See logs for details !")
			return false;
		else if (res.data !== '' && typeof res.data === 'string') return res.data;

		else throw res.data.message;
	}
	catch (error) {
		// if (error.message.includes('401')) {
		// 	localStorage.clear();
		// 	window.location.href = '/';
		// 	return;
		// }
		// throw (error);
	}
}

export async function restGetResetCode(apiURL, headers) {
	try {
		const res = await axios.get(process.env.REACT_APP_BASE_URL + apiURL, { headers });
		return res;
	}
	catch (error) {
		throw (error?.response);
	}
}

// TODO This was changed to return res.data instead of res.data.data; do the same for restGet and
// restDelete as well (will also need to change all API calls using those axios functions)
export async function restPost(apiURL, data, headers) {
	try {
		const res = await axios.post(process.env.REACT_APP_BASE_URL + apiURL, data, { headers });
		console.log("res in axios",res)
		if (res && res.data && res.data.status && res.data.status === true)
			return res.data;
		else {
			throw res.data.message
		}
	}
	catch (error) {
		throw (error)
		// return error;
		// if (error.message.includes('401')) {
		// 	localStorage.clear();
		// 	window.location.href = '/';
		// 	return;
		// }
		// throw (error);
	}
}


export async function restPostLogin(apiURL, data, headers) {
	try {
		const res = await axios.post(process.env.REACT_APP_BASE_URL + apiURL, data, { headers });
		return res;
	}
	catch (error) {
		throw (error);
		// if (error.message.includes('401')) {
		// 	localStorage.clear();
		// 	window.location.href = '/';   
		// 	return;
		// }
	}
}

export async function restDelete(apiURL, headers) {
	try {
		const res = await axios.delete(process.env.REACT_APP_BASE_URL + apiURL, { headers });
		if (res && res.data && res.data.status && res.data.status === true)
			return res.data.data;
		else
			throw res.data.message;
	}
	catch (error) {
		if (error.message.includes('401')) {
			localStorage.clear();
			window.location.href = '/';
			return;
		}
		throw error;
	}
}