import React, { useEffect, useState } from "react";
import { Button, TextField, IconButton } from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FaCheck, FaTimes } from "react-icons/fa";
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AlertModal from "../../components/AlertModal";
import DialogContentText from '@mui/material/DialogContentText';
import { saveNewPassword } from "../../api/UserApi";
import { BIBlack, BIGrey, BIGreen, BIRed, BIYellow } from "../../assets/buildidColors";
import { sha256 } from "crypto-hash";

import "../../assets/css/Settings.css";
import { ToastProvider, useToasts } from "react-toast-notifications";
let userEmail = '';

function ChangePasswordModal(props) {
	const [isShowConfirmPassword, setIsShowConfirmPassword] = React.useState(false);
	const [isShowNewPassword, setIsShowNewPassword] = React.useState(false);
	const [newPassword, setNewPassword] = React.useState('');
	const [confirmPassword, setConfirmPassword] = React.useState('');
	const [passwordStrengthCount, setPasswordStrengthCount] = React.useState(0);
	const [differentPasswordAlert, setDifferentPasswordAlert] = useState(false);

	const { addToast } = useToasts();

	const hash = (str) => {
		return sha256(str);
	  };

	useEffect(() => {
		userEmail = localStorage.getItem('primaryEmail');
	}, [])

	// Reset form states on modal open/close
	useEffect(() => {
		setNewPassword('');
		setIsShowNewPassword(false);
		setPasswordStrengthCount(0);
		setConfirmPassword('');
		setIsShowConfirmPassword(false);
	}, [props.isOpen]);

	async function handleChangePasswordClick() {
		// userPassword doesn't exist - it was previously stored in plaintext in localStorage,
		// which was removed since it is a security vulnerability. If userPassword was securely
		// passed into this Modal on load and stored in userPassword, it could work.
		// if (newPassword === userPassword) {
		// 	await setDifferentPasswordAlert(true);
		// 	return;
		// }

		try {
			const pass = await hash(newPassword);
			const obj = {
				Email: userEmail,
				Password: pass
			}
			await saveNewPassword(obj);
			props.onClose();
			addToast('Password Changed Successfully', { appearance: 'success', autoDismiss: true });
		} catch (error) { }
	}

	const getPasswordStrength = () => {
		let strength = '';
		if (passwordStrengthCount === 0) strength = '';
		else if (passwordStrengthCount === 1) strength = 'Weak';
		else if (passwordStrengthCount > 1 && passwordStrengthCount < 4) strength = 'Medium';
		else if (passwordStrengthCount === 4) strength = 'Strong';
		return strength;
	}

	return (
		<div>
			<AlertModal
				isOpen={differentPasswordAlert}
				onClose={() => setDifferentPasswordAlert(false)}
				title={"Password Error"}
				body={"New password must be different from current password."} />
			<ToastProvider>
				<Dialog
					open={props.isOpen}
					onClose={() => {
						props.onClose();
						setNewPassword('');
						setConfirmPassword('');
						setPasswordStrengthCount(0);
					}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle style={{ color: BIBlack }} id="alert-dialog-title">
						Change Password
					</DialogTitle>

					<DialogContent>
						<div style={{ width: "400px" }}>
							<div className="input-container">
								<TextField
									autoComplete="new-password"
									variant="outlined"
									fullWidth
									placeholder=" New Password"
									value={newPassword}
									type={isShowNewPassword ? "text" : "password"}
									onChange={(e) => {
										let count = 0;
										if (e.target.value.length >= 6) count += 1;
										if (e.target.value.match(/(?=.*\d)/)) count += 1;
										if (e.target.value.match(/(?=.*[A-Z])/)) count += 1;
										if (e.target.value.match(/(?=.*[a-z])/)) count += 1;
										setNewPassword(e.target.value);
										setPasswordStrengthCount(count);
									}}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													tabIndex="-1"	// Unfocusable button
													onClick={() => setIsShowNewPassword(!isShowNewPassword)}>
													{isShowNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</div>

							<div className="flex-container-horizontal">
								<div className="flex-container-left">
									<div className="input-container">
										<span>Password strength</span>
									</div>
								</div>

								<div className="flex-container-right">
									<div className="input-container">
										<span className="right" style={{ color: passwordStrengthCount === 1 ? BIRed : passwordStrengthCount > 1 && passwordStrengthCount < 4 ? BIYellow : passwordStrengthCount === 4 ? BIGreen : BIGrey }}>{getPasswordStrength()}</span>
									</div>
								</div>
							</div>

							<div style={{ width: '100%', height: '5px', borderRadius: '4px', backgroundColor: BIGrey }}>
								<div style={{ borderRadius: '4px', height: '5px', width: passwordStrengthCount === 1 ? '33%' : passwordStrengthCount > 1 && passwordStrengthCount < 4 ? '66%' : passwordStrengthCount === 4 ? '100%' : 0, backgroundColor: passwordStrengthCount === 1 ? BIRed : passwordStrengthCount > 1 && passwordStrengthCount < 4 ? BIYellow : passwordStrengthCount === 4 ? BIGreen : BIGrey }}></div>
							</div>

							<div className="input-container">
								<DialogContentText id="alert-dialog-description"
									style={{ color: newPassword !== '' ? newPassword.length >= 6 ? BIGreen : BIRed : BIGrey }}>
									{
										newPassword === '' || newPassword.length >= 6
											? <FaCheck className="icon-position" style={{ color: newPassword.length >= 6 ? BIGreen : BIGrey }} />
											: <FaTimes className="icon-position" style={{ color: BIRed }} />
									} At least 6 characters
								</DialogContentText>

								<DialogContentText id="alert-dialog-description"
									style={{ color: newPassword !== '' ? newPassword.match(/(?=.*\d)/) ? BIGreen : BIRed : BIGrey }}>
									{
										newPassword === '' || newPassword.match(/(?=.*\d)/)
											? <FaCheck className="icon-position" style={{ color: newPassword.match(/(?=.*\d)/) ? BIGreen : BIGrey }} />
											: <FaTimes className="icon-position" style={{ color: BIRed }} />
									} At least 1 number
								</DialogContentText>

								<DialogContentText id="alert-dialog-description"
									style={{ color: newPassword !== '' ? newPassword.match(/(?=.*[A-Z])/) ? BIGreen : BIRed : BIGrey }}>
									{
										newPassword === '' || newPassword.match(/(?=.*[A-Z])/)
											? <FaCheck className="icon-position" style={{ color: newPassword.match(/(?=.*[A-Z])/) ? BIGreen : BIGrey }} />
											: <FaTimes className="icon-position" style={{ color: BIRed }} />
									} At least 1 capital letter
								</DialogContentText>

								<DialogContentText id="alert-dialog-description"
									style={{ color: newPassword !== '' ? newPassword.match(/(?=.*[a-z])/) ? BIGreen : BIRed : BIGrey }}>
									{
										newPassword === '' || newPassword.match(/(?=.*[a-z])/)
											? <FaCheck className="icon-position" style={{ color: newPassword.match(/(?=.*[a-z])/) ? BIGreen : BIGrey }} />
											: <FaTimes className="icon-position" style={{ color: BIRed }} />
									} At least 1 lowercase letter
								</DialogContentText>
							</div>

							<div className="input-container">
								<TextField
									variant="outlined"
									fullWidth
									placeholder="Confirm Password"
									value={confirmPassword}
									type={isShowConfirmPassword ? "text" : "password"}
									onChange={(e) => setConfirmPassword(e.target.value)}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													tabIndex="-1"	// Unfocusable button
													onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}>
													{isShowConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							</div>
							<div className="input-container">
								{confirmPassword === '' || newPassword === confirmPassword ?
									<DialogContentText id="alert-dialog-description" style={{ color: newPassword === confirmPassword && confirmPassword !== '' ? BIGreen : BIGrey }}>
										<FaCheck className="icon-position" style={{ color: newPassword === confirmPassword && confirmPassword !== '' ? BIGreen : BIGrey }} /> Password match
									</DialogContentText> :
									<DialogContentText id="alert-dialog-description" style={{ color: BIRed }}>
										<FaTimes className="icon-position" style={{ color: BIRed }} /> Password match
									</DialogContentText>
								}
							</div>
						</div>
					</DialogContent>

					<DialogActions>
						<Button
							onClick={() => {
								props.onClose();
								setNewPassword('');
								setConfirmPassword('');
								setPasswordStrengthCount(0);
							}}
							color="primary"> Cancel </Button>

						<Button
							variant="contained"
							color="primary"
							disabled={passwordStrengthCount === 4 && newPassword === confirmPassword ? false : true}
							onClick={handleChangePasswordClick}
						>
							Change Password
						</Button>
					</DialogActions>
				</Dialog>
			</ToastProvider>
		</div>
	)
}

export default ChangePasswordModal;