import { restGet, restPost, restDelete } from "./RestClient";
import { headers, form_headers } from "./UserApi";

// let headers = {
// 	"Content-Type": "application/json",
// 	"Accept": "application/json",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }

// let form_headers = {
// 	"Content-Type": "multipart/form-data",
// 	"Accept": "application/json",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }

export async function getChatGroupDashboard(userId) {
	const res = await restGet("chatGroups/GetChatGroupDashboard/" + userId, headers);
	return res;
}

// Get all channels that userId is a member of
export async function getUserChannels(userId) {
	const res = await restGet("chat/getUserChannels/" + userId, headers);
	return res;
}


// (Mismatched API name)
// Clears nrUnreadMessages for arg user and channel
export async function clearChannelUnreadCount(userId, channelId) {
	const res = await restGet("chat/GetMyChannelChatMessages/" + userId + "/" + channelId, headers);
	return res.data;
}


// Gets messages from channels
export async function fetchChatChannelsMessages(data) {
	const res = await restPost("chat/fetchChatChannelsMessages/", data, headers);
	return res.data;
}


// (Mismatched API name)
// Clears nrUnreadMessages for arg user and channel
export async function clearDMUnreadCount(userId, contactId) {
	const res = await restGet("chat/GetMyFriendChatMessages/" + userId + "/" + contactId, headers);
	return res;
}

// Gets direct messages from chat
export async function fetchUserChatMessages(data) {
	const res = await restPost("chat/fetchUserChatMessages/", data, headers);
	return res.data;
}


// Saves an image to Azure backend, and returns URL for use in hub function
export async function saveMessagePictures(data) {
	const res = await restPost("chat/saveMessagePictures/", data, form_headers);
	return res.data;
}


// Gets array of users details, filtered by fetch object
export async function fetchChatChannelUsers(data) {
	const res = await restPost("chat/fetchChatChannelUsers/", data, headers);
	return res.data;
}


// Saves channel details (name, description, picture)
export async function saveChatChannel(data) {
	const res = await restPost("chat/saveChatChannel/", data, form_headers);
	return res.data;
}


// (Mismatched API name)
// Sets user as new channel admin
export async function setNewChannelAdmin(data) {
	const res = await restPost("chat/SaveNewAdminForChannel/", data, headers);
	return res.data;
}


// (Mismatched API name)
export async function removeChatChannelUser(data) {
	const res = await restPost("chat/UnsubscribeMe/", data, form_headers);
	return res.data;
}
// Remove user from channel


// Saves (ie. adds) user to channel
export async function saveChatChannelUser(data) {
	const res = await restPost("chat/SaveChatChannelUser/", data, headers);
	return res.data;
}


/*
(Mismatched API name)
Mutes channel for specific user based on params
data:
	{
		UserId		- int
		ChannelId	- int
		Muted		- boolean
		ModifiedBy	- int
	}
*/
export async function muteChannelForUser(data) {
	const res = await restPost("chat/MuteChannelForTheUser/", data, headers);
	return res.data;
}


// Deletes channel
export async function deleteChatChannel(channelId, userId) {
	const res = await restDelete("chat/DeleteChatChannel/" + channelId + "/" + userId, headers);
	return res;
}


// Leaves DM
export async function deleteDirectMessage(data) {
	const res = await restPost("chat/UserChatDeleteFriend/", data, headers);
	return res;
}


// Accepts/declines a Channel Join Request (from Dashboard)
export async function saveJoinChannelRequest(data) {
	const res = await restPost("chat/SaveJoinChannelRequest/", data, headers);
	return res;
}