import Backdrop from "@mui/material/Backdrop";
import {
  Button,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import "../../../assets/css/Addexpenses.css";
import { Close, Close as CloseIcon } from "@material-ui/icons";
import {
  fetchClientEmployeesJobs,
  saveClientTracker,
  saveClientTrackerPicture,
} from "../../../api/ClientApi";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";

export default function ClientChangeTrackerAddModal({
  open,
  close,
  data,
  updateData,
}) {
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientTracketData, setClientTracketData] = useState([]);
  const [changeTrackerInputData, setChangeTrackerInputData] = useState({
    date: "",
    description: "",
  });

  useEffect(() => {
    getChangeTrackerData();
  }, []);

  const getChangeTrackerData = async () => {
    let obj = {
      PageNr: 1,
      NrOfRecPerPage: 100,
      FullSearch: "",
      UserId: Number(localStorage.getItem("userId")),
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: true,
      SearchList: [
        {
          id: data.id,
        },
      ],
    };
    let res = await fetchClientEmployeesJobs(obj);
    setClientTracketData(res?.data[0]);
  };

  const handelsaveClientTracker = async () => {
    setLoading(true);
    try {
      let obj = {
        UserId: Number(localStorage.getItem("userId")),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            ClientEmployeeId: data?.clientEmployeeId || localStorage?.clientEmployeeId,
            ClientEmployeeJobId: data?.id,
            Date: moment(changeTrackerInputData?.date).format("YYYY-MM-DD"),
            Description: changeTrackerInputData?.description,
            ModifiedBy: Number(localStorage.getItem("userId")),
          },
        ],
      };

      let res = await saveClientTracker(obj);

      let id_for_Img = res.data[0]?.objData.id;

      if (image.length > 0 && id_for_Img) {
        let formDataArray = image.map((img) => {
          let formData = new FormData();
          formData.append("file", img);
          formData.append("id", 0);
          formData.append("ChangeTrackerId", id_for_Img);
          formData.append("ModifiedBy", Number(localStorage.getItem("userId")));
          return formData;
        });

        let promises = formDataArray.map((formData) =>
          saveClientTrackerPicture(formData)
        );

        let a = await Promise.all(promises);

        setImage([]);
        setLoading(false);
        setChangeTrackerInputData({
          date: "",
          description: "",
        });
        updateData();
        handleClose();
      } else {
        setImage([]);
        setLoading(false);
        setChangeTrackerInputData({
          date: "",
          description: "",
        });
        updateData();
        handleClose();
      }
    } catch (error) {
      setLoading(false);
      console.log(`error`, error);
      handleClose();
    }

    setLoading(false);
    handleClose();
  };

  const clearImage = (index) => {
    let array = [...image];
    array.splice(index, 1);
    setImage(array);
  };

  const onImagechange = (e) => {
    const files = e.target.files;
    let array = [];
    for (let i = 0; i < files.length; i++) {
      array.push(files[i]);
    }
    setImage(array);
  };

  const chekObject = (obj) => {
    try {
      if (obj) {
        if (typeof obj === "object") {
          return URL.createObjectURL(obj);
        } else {
          return obj;
        }
      } else {
        return "";
      }
    } catch (error) {
      return "";
    }
  };

  const handleClose = () => {
    close();
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <div
          className="modal-center add-expenses"
          style={{
            maxHeight: "600px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="modal-card-header-buttons"
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton color="error" onClick={handleClose}>
              <Close color="error" />
            </IconButton>
          </div>
          <div className="addexpenses-form">
            <div style={{ marginBottom: "13px" }}>
              <Typography
                variant="h6"
                component="label"
                className="form-label"
                htmlFor="my-input"
              >
                Job
              </Typography>
              <Typography
                variant="overline"
                component="div"
                className="form-label"
              >
                {clientTracketData?.jobCode + "-" + clientTracketData?.jobName}
              </Typography>
            </div>
            <div className="form-content">
              <div>
                <Typography
                  variant="h6"
                  component="label"
                  className="form-label"
                  htmlFor="my-input"
                >
                  Date
                </Typography>
                <KeyboardDatePicker
                  className="form-field"
                  variant="inline"
                  inputVariant={"outlined"}
                  placeholder="MM/DD/YYYY"
                  format="MM/DD/YYYY"
                  onChange={(date) => {
                    setChangeTrackerInputData({
                      ...changeTrackerInputData,
                      date: moment(date).format(),
                    });
                  }}
                  value={
                    changeTrackerInputData?.date
                      ? moment(changeTrackerInputData?.date).format(
                          "MM/DD/YYYY"
                        )
                      : null
                  }
                  fullWidth
                  size="small"
                  autoOk={true}
                />
              </div>

              <div style={{ marginTop: "13px" }}>
                <Typography
                  variant="h6"
                  component="label"
                  className="form-label"
                  htmlFor="my-input"
                >
                  Description
                </Typography>
                <TextField
                  multiline
                  value={changeTrackerInputData?.description}
                  onChange={(e) => {
                    setChangeTrackerInputData({
                      ...changeTrackerInputData,
                      description: e.target.value,
                    });
                  }}
                  name="description"
                  fullWidth
                  rows={4}
                  variant="outlined"
                  className="form-field"
                />
              </div>

              <div
                className="receipt-docunment"
                style={{ marginTop: "13px", textAlign: "center" }}
              >
                <input
                  type="file"
                  multiple={true}
                  style={{ display: "none" }}
                  id="receipt-img"
                  onChange={(e) => onImagechange(e)}
                />
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <div
                    onClick={() => {
                      document.getElementById("receipt-img").click();
                    }}
                    style={{
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px dashed #ccc",
                      borderRadius: "5px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                  >
                    <AddIcon />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      overflow: "auto",
                      maxWidth: "450px",
                    }}
                  >
                    {image &&
                      image.map((img, index) => (
                        <div>
                          <img className="recipt-img" src={chekObject(img)} />
                          <IconButton
                            style={{
                              top: "-110px",
                              right: "-80px",
                              backgroundColor: "white",
                              color: "red",
                              height: "15px",
                              width: "15px",
                              display: "flex",
                              padding: "10px",
                              justifyContent: "center",
                              alignitems: "center",
                              zIndex: 1,
                            }}
                            onClick={() => clearImage(index)}
                            size="small"
                          >
                            <CloseIcon
                              fontSize="small"
                              style={{ fontSize: 16 }}
                            />
                          </IconButton>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                width: "100%",
              }}
            >
              {!loading ? (
                <Button
                  onClick={() => handelsaveClientTracker()}
                  fullWidth
                  disabled={
                    changeTrackerInputData?.date === "" ||
                    changeTrackerInputData?.description === ""
                      ? true
                      : false
                  }
                  style={{
                    padding: "5px",
                    width: "95%",
                    margin: "10px auto",
                    background:
                      changeTrackerInputData?.date === "" ||
                      changeTrackerInputData?.description === ""
                        ? "#e4dede"
                        : localStorage?.clientAccentColor,
                    color: "white",
                    borderRadius: "5px",
                  }}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  fullWidth
                  disabled
                  style={{
                    background: localStorage?.clientAccentColor,
                    color: "white",
                    padding: "5px",
                    width: "95%",
                    margin: "10px auto",
                    borderRadius: "5px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "white", height: "26px", width: "26px" }}
                  />
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>

      {/* API PROBLEM NEE TO BE FIX  ========    PENDING */}

      {/* <AlertModal
        isOpen={prompt}
        title="
        inapproprate image"
        onClose={() => handelPromptClose()}
      /> */}
    </div>
  );
}
