import { Modal } from "@material-ui/core";

export default function AddSiteModal(props) {
    return (<>
        <Modal
            open={props.addSiteModalOpen}
            onClose={() => {
                props?.resetSelected();
            }}>
            <div className="modal-center">
                <div className="modal-card" style={{ width: "800px" }}>
                    <div className="modal-card-body">
                        {props.projectForm}
                    </div>
                </div>
            </div>
        </Modal>
    </>)
}