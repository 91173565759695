import React from "react";
import AdSense from 'react-adsense';

export default function Externaladd() {



    return (
        <>
            {/* Other content */}
            <AdSense.Google
                client="ca-pub-8809921336549043"  // Replace with your AdSense client ID
                slot="5569859590"    // Replace with your AdSense ad unit ID
                style={{ display: 'block', width:"850px",height:"90px" }}
                format='auto'
            />
            {/* Other content */}
        </>
    )


}