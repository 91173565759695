import React, { useEffect, useState } from "react";
import { Button, TextField, IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AlertModal from "../../components/AlertModal";
import DialogContentText from '@mui/material/DialogContentText';
import { deactivateUserAccount } from "../../api/UserApi";
import { BIBlack, BIRed, BIBlue, BILightGrey } from "../../assets/buildidColors";
import { userLogin } from "../../api/UserApi";
import "../../assets/css/Settings.css";
import Bowser from "bowser";
import packageInfo from '../../../package.json'
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js"
import { sha256 } from "crypto-hash";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardActions';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

let userEmail = '';
let bowserRes = Bowser.getParser(window.navigator.userAgent)


async function getDeviceName() {
	const visitorId = await getCurrentBrowserFingerPrint();
	return visitorId;
}

function CloseAccountModal(props) {
	const [isShowCurrentPassword, setIsShowCurrentPassword] = React.useState(false);
	const [currentPassword, setCurrentPassword] = React.useState('');
	const [differentPasswordAlert, setDifferentPasswordAlert] = useState(false);
	const [isIncorrectPassword, setIsIncorrectPassword] = useState(false);
	let history = useHistory();

	const hash = (str) => {
		return sha256(str)
	};

	useEffect(() => {
		userEmail = localStorage.getItem('primaryEmail');
		setCurrentPassword(null);
	}, [])

	useEffect(() => {
		setCurrentPassword(null);
		setIsShowCurrentPassword(false);
		setIsIncorrectPassword(false);
	}, [props.isOpen]);

	async function handleCloseAccountClick(event) {
		event.preventDefault();

		if (localStorage?.loginWith === "Email") {
			try {
				if (userEmail === "" || currentPassword === "")
					throw new Error("Password field is required!")

				const pwd = await hash(currentPassword);
				const deviceName = await getDeviceName();
				const loginObj = {
					Email: userEmail,
					Password: pwd,
					NotifyToken: "",
					AppName: "BuilID-Web",
					AppVersion: packageInfo?.version,
					Browser: bowserRes.parsedResult.browser?.name,
					BrowserVersion: bowserRes.parsedResult.browser?.version,
					Platform: bowserRes.parsedResult.os.name,
					PlatformVersion: "NA",
					DeviceName: deviceName
				}
				let loginResult = await userLogin(loginObj);
				await localStorage.setItem("token", loginResult?.data?.token ?? null);

				if (loginResult.data.message === "OK" || loginResult.data.status) {
					// const obj = {
					// 	UserId: localStorage.userId
					// }
					await deactivateUserAccount();

					await localStorage.clear();
					window.history.replaceState(null, '', '/');
					window.location.reload();
					// history.push("/login");
				} else setIsIncorrectPassword(true);
			}
			catch (error) {
				(error.message === "Request failed with status code 400")
					? alert("Wrong email or password!")
					: setIsIncorrectPassword(true);
			}
		}
		else {
			await deactivateUserAccount();
			await localStorage.clear();
			window.history.replaceState(null, '', '/');
			window.location.reload();
		}
	}

	let handleCancelAccountClick = () => {
		setIsOpenDeleteConfirmModal(false)
		setCurrentPassword('');
		setIsIncorrectPassword(false);

		setCheckboxState({ firstBox: false })
		setCheckboxState({ firstSecond: false })
	}


	const [checkboxState, setCheckboxState] = React.useState({
		firstBox: false,
		secondBox: false,
	});
	const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = React.useState(false);

	const handleChange = (event) => {
		setCheckboxState({
			...checkboxState,
			[event.target.name]: event.target.checked,
		});
	};

	const { firstBox, secondBox } = checkboxState;
	const error = [firstBox, secondBox].filter((v) => v).length !== 2;


	return (
		<div>
			<AlertModal
				isOpen={differentPasswordAlert}
				onClose={() => setDifferentPasswordAlert(false)}
				title={"Password Error"}
				body={"New password must be different from current password."} />

			{/* <Dialog
				open={props.isOpen}
				onClose={() => {
					props.onClose();
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">

				<DialogTitle style={{ color: BIBlack }} id="alert-dialog-title">
					Delete your account
				</DialogTitle>
				<DialogContent> */}
			<div className="page">
				<div className="header">
					<div className="page-content">
						<h1>Close Account</h1>
					</div>
				</div>

				<div className="page-content">

					<div className="input-container">
						<DialogContentText id="alert-dialog-description">
							You are about to delete your BuildID account <b>({userEmail})</b>.
						</DialogContentText>
						<DialogContentText style={{ marginTop: "5px" }} id="alert-dialog-description">
							Please select all of the following to confirm you understand.
						</DialogContentText>
					</div>

					<Card sx={{ minWidth: 275 }}>
						<CardContent style={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', padding: "10px" }}>
							<FormControl
								required
								error={error}
								component="fieldset"
								sx={{ m: 3 }}
								variant="standard"
							>
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox style={{ margin: "0px 14px 80px 0px" }} checked={firstBox} onChange={handleChange} name="firstBox" />
										}
										label={<div>
											The following content will be <b>permanently deleted:</b>
											<ul style={{ margin: "10px 0px 0px 30px" }}>
												<li>Your Profile (including profile picture, contact information, work experience, and education items)</li>
												<li>Builder Network connections</li>
												<li>Timesheets, Expenses, Montages, and Resumes</li>
											</ul>
										</div>}
									/>
									<FormLabel component="legend">Select both checkboxes to confirm you understand.</FormLabel>

									<FormControlLabel
										control={
											<Checkbox style={{ margin: "0px 14px 100px 0px" }} checked={secondBox} onChange={handleChange} name="secondBox" />
										}
										label={<div><div style={{ marginTop: "30px" }}>
											<div>
												The following content you have added to BuildID will be kept, but <b> any association with you or your account will be removed:</b>
											</div>

											<ul style={{ margin: "10px 0px 0px 30px" }}>
												<li>Sites and Projects</li>
												<li>Companies</li>
												<li>Products</li>
												<li>Posts, images, and comments</li>
												<li>Chat messages</li>
											</ul>
										</div></div>}
									/>
									<FormLabel component="legend">Select both checkboxes to confirm you understand.</FormLabel>
								</FormGroup>
							</FormControl>
						</CardContent>
					</Card>


					<Dialog open={isOpenDeleteConfirmModal} onClose={() =>
						() => setIsOpenDeleteConfirmModal(false)
					}>

						<DialogContent>

							<div style={{ display: "flex", flexDirection: "column", padding: "15px" }}>
								<Card sx={{ minWidth: 275 }} style={{ borderColor: BIRed }}>

									<CardContent style={{ color: BIRed, fontWeight: "600", backgroundColor: 'pink', borderStyle: "solid", borderRadius: "6px", padding: "15px", borderWidth: "2px" }}>
										🛑 This action cannot be undone.
									</CardContent>
								</Card>
							</div>

							{localStorage?.loginWith === "Email" && <DialogContent>
								Please enter your password to confirm.

								<Card sx={{ minWidth: 275 }} style={{ borderColor: BIRed, padding: "5px", marginTop: "5x" }}>

									<CardContent >
										<div className="input-container">
											<TextField
												fullWidth
												placeholder="Password"
												value={currentPassword}
												autoComplete="new-password"
												type={isShowCurrentPassword ? "text" : "password"}
												onChange={(e) => {
													setCurrentPassword(e.target.value)
												}}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																tabIndex="-1"	// Unfocusable button
																onClick={() => setIsShowCurrentPassword(!isShowCurrentPassword)}>
																{isShowCurrentPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
															</IconButton>
														</InputAdornment>
													)
												}}
											/>
										</div>

										<div className="input-container" style={{ marginBottom: '20px' }}>
											{isIncorrectPassword ?
												<DialogContentText id="alert-dialog-description" style={{ color: BIRed }}>
													Incorrect Password
												</DialogContentText>
												: <></>
											}
										</div>
									</CardContent>
								</Card>
							</DialogContent>}




							<div className="input-container" style={{ marginTop: "25px" }}>
								<Button
									disabled={localStorage?.loginWith !== "Email" ? false : currentPassword ? false : true}
									variant="contained"
									fullWidth
									color="secondary"
									onClick={handleCloseAccountClick}
									to="/"
								>
									Delete my account
								</Button>
							</div>

							<div className="input-container" style={{ textAlign: 'center' }}>
								<Button
									onClick={() => { handleCancelAccountClick(); }}
									color="primary"> Cancel
								</Button>
							</div>
						</DialogContent>
					</Dialog>



					<div className="input-container" style={{ alignSelf: 'center', marginTop: '20px' }}>
						<Button
							variant="contained"
							style={{ background: error ? BILightGrey : BIRed, color: 'white' }}
							disabled={error}
							onClick={() => !error && setIsOpenDeleteConfirmModal(true)}
							to="/"
						>
							Delete my account
						</Button>
					</div>

					{/* <div className="input-container" style={{ textAlign: 'center' }}>
						<Button
							onClick={() => {
								props.onClose();
								setCheckboxState({ firstBox: false });
								setCheckboxState({ firstSecond: false });
							}}
							color="primary"> Cancel </Button>
					</div> */}
				</div>
			</div>
			{/* </DialogContent>
			</Dialog> */}
		</div>
	)
}

export default CloseAccountModal;