import React, { useState, useEffect, useCallback } from "react";
import { TextField, Button, InputAdornment, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import ContactCard from "./ContactCard";
import RequestsModal from "./RequestsModal";
import AddContactModal from "./AddContactModal";
import { getUserContacts } from "../../api/ContactsApi";
import { getDashboardNotifications } from "../../api/DashboardApi";
import {
	getUserInfo,
	fetchUserWorkHistory
} from "../../api/UserApi";
import { BIWhite } from "../../assets/buildidColors";
import Loader from "../../components/Loader";
import UserUsages from "../../components/userUsages/UserUsages";
import { List } from "@mui/material";

function Contacts() {
	const [newContactModalOpen, setNewContactModalOpen] = useState(false);
	const [requestsModalOpen, setRequestsModalOpen] = useState(false);
	const [showLoader, setShowLoader] = useState(true);
	/*
		allContacts and displayContacts have the form of:
		[{
			data: {
				firstName
				lastName
				userEmail
				phones: [{
					phoneNo
					phoneType
				}, ...]
			},
			sites: [{
				siteId
				siteName
				...
			}, ...]
		}, ...]
	*/
	const [allContacts, setAllContacts] = useState([]);
	const [displayContacts, setDisplayContacts] = useState([]);
	const [searchString, setSearchString] = useState("");
	const [isContactSearch, setIsContactSearch] = useState(true);

	const [requestNotifications, setRequestNotifications] = useState([]);
	const [selfData, setSelfData] = useState([])

	// Removes request item from array
	function deleteRequestNotification(indexToRemove) {
		let newArray = [];
		requestNotifications.forEach((notif, index) => {
			if (index !== indexToRemove)
				newArray.push(notif);
		})

		setRequestNotifications(newArray);
	}


	// Returns all elements of contactWorkHistory whose siteId is a project that is found in 
	// userWorkHistory (ie. all sites that both user and contact have both worked on)
	function getMutualProjects(userWorkHistory, contactWorkHistory) {
		let mutualProjects = [];
		contactWorkHistory.forEach(exp => {
			userWorkHistory.forEach(userExp => {
				if (exp.siteId === userExp.siteId)
					mutualProjects.push(exp);
			})
		})

		return mutualProjects;
	}

	// Update search results when searchString is changed; only contacts whose name, email, or 
	// phone number matches search string are shown
	useEffect(() => {
		UserUsages('BuilderNetwork');
		setDisplayContacts(allContacts.filter(contact => (
			contact.data.firstName.toLowerCase().includes(searchString.toLowerCase())
			|| contact.data.lastName.toLowerCase().includes(searchString.toLowerCase())
			// || contact.data.userEmail.toLowerCase().includes(searchString.toLowerCase())
			// || contact.data.phones.some(phone => phone.phoneNo.includes(searchString))
		)));
	}, [searchString, allContacts])

	const getContacts = useCallback(() => {

		// Get user's work history and contacts
		Promise.all([
			fetchUserWorkHistory({ SearchList: [{ "UserId": localStorage.userId }] }),
			getUserContacts(localStorage.userId)
		]).then(([userWorkHistory, contacts]) => {
			let contactInfoAPICalls = [];	// Batch API calls to get each contact's information
			let contactWHAPICalls = [];		// Batch API calls to get each contact's work history
			let SelfInfoAPICalls = [];	// Batch API calls to get each contact's information

			SelfInfoAPICalls.push(getUserInfo(localStorage.userId))

			contacts.forEach(contact => {
				contactInfoAPICalls.push(getUserInfo(contact.contactId))
				contactWHAPICalls.push(fetchUserWorkHistory({ SearchList: [{ "UserId": contact.contactId }] }))
			})
			// Parallelized API calls
			Promise.all([
				Promise.all(contactInfoAPICalls),
				Promise.all(contactWHAPICalls),
				Promise.all(SelfInfoAPICalls)
			])
				.then(([contactsInfo, contactsWorkHistory, SelfInfoAPICalls]) => {
					setSelfData(SelfInfoAPICalls)
					let newContacts = [];
					contactsInfo.forEach((contact, index) => {
						newContacts.push({
							data: contact,
							// Filter for mutual sites
							sites: getMutualProjects(userWorkHistory, contactsWorkHistory[index])
						})
					})
					setAllContacts(newContacts);
					setShowLoader(false);
				});
		});
	}, []);

	// Get user's contacts and contact requests on page load
	useEffect(() => {
		setShowLoader(true)
		getContacts();
		// Get notifications and filter them for only "Contact Invitations"
		getDashboardNotifications(localStorage.userId)
			.then(res => setRequestNotifications(res.filter(notif => notif.title === "Contact Invitation")));
	}, [getContacts])


	return (
		<>
			<AddContactModal
				isOpen={newContactModalOpen}
				onClose={() => setNewContactModalOpen(false)} />

			<RequestsModal
				isOpen={requestsModalOpen}
				onClose={() => setRequestsModalOpen(false)}
				requests={requestNotifications}
				deleteRequestNotification={deleteRequestNotification}
				updateContacts={getContacts} />

			<div className="page">
				<div className="header">
					<div className="page-content">
						<h1>Builder Network</h1>
					</div>
				</div>

				{
					!showLoader ?
						(
							<>
								<div className="page-content">
									<div className="flex-container-horizontal" style={{ padding: "20px 0px", justifyContent: "space-between", alignItems: "center" }}>
										<div style={{ backgroundColor: BIWhite, borderRadius: "5px" }}>
											<TextField
												variant="outlined"
												placeholder="Search"
												size="small"
												value={searchString}
												onChange={event => {
													if (event.target.value == '') {
														setIsContactSearch(true)
													} else {
														setIsContactSearch(false)
													}
													setSearchString(event.target.value)
												}}
												InputProps={{
													endAdornment: <InputAdornment position="end">
														{	// Only show "X" button when field not empty
															(searchString !== "")
																? (
																	<IconButton
																		onClick={() => {
																			setSearchString("");
																			setIsContactSearch(true);
																		}}>
																		<CloseIcon />
																	</IconButton>
																)
																: <></>
														}
													</InputAdornment>,
												}} />
										</div>
										<div className="flex-container-horizontal">
											<div>
												<Button
													variant="contained"
													color="primary"
													onClick={() => setNewContactModalOpen(true)}>
													Look for people
												</Button>
											</div>
										</div>
									</div>

									{!isContactSearch ? <></> :
										(
											<List sx={{ padding: 0 }}>
												<ContactCard
													contact1={selfData}
													updateContacts={getContacts}
													selfInfo={"selfInfo"}
												/>
											</List>
										)}

									{displayContacts.length === 0 ? (<div></div>) :
										(
											<List sx={{ padding: 0 }}>
												{displayContacts.map((contact, index) => {
													return (
														contact !== undefined && (
															<ContactCard
																key={index}
																contact={contact}
																updateContacts={getContacts}
															/>
														));
												})
												}
											</List>
										)}
								</div>

							</>
						)
						:
						(
							<div style={{ marginTop: '20%' }}><Loader /></div>
						)
				}
			</div >
		</>
	)
}

export default Contacts;
