import React, { useState, useEffect } from "react";
import { Card, Grid } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import defaultProjectImage from "../../assets/images/profile-picture-placeholder.png";
import Loader from "../Loader";
import CustomImageViewer from "./CustomImageViewer";
import {
  saveSitesFavouriteImages,
  deleteSitesFavouriteImages,
} from "../../api/SitesApi";
import { deleteProductsFavouriteImages, saveProductsFavouriteImages } from "../../api/ProductsApi";
import { deleteProjectsFavouriteImages, saveProjectsFavouriteImages } from "../../api/UserApi";

const ProfilePic = (props) => {
  const [images, setImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [viewerVisible, setViewerVisible] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (props?.getUpdatedImages) {
      fetchData();
    }
  }, [props?.getUpdatedImages])

  const fetchData = async () => {
    try {
      setImageLoading(true);
      const pictures = await props?.getData();
      setImages(pictures ?? []);
      setImageLoading(false);
      if (props?.getUpdatedImages) props?.updateValueOfGetUpdatedUmages()
    } catch (error) {
      setImageLoading(false);
      console.error("Error fetching images:", error);
    }
  };

  const openPictureViewer = (index) => {
    setSelectedImageIndex(index);
    setViewerVisible(true);
  };

  const closePictureViewer = () => {
    setViewerVisible(false);
  };




  const handleSaveImage = async (image) => {
    if (props?.from === "product") {
      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [{ Id: 0, ProductId: props?.productId, ProductPictureId: image?.pictureId, ModifiedBy: Number(localStorage.userId) }]
      }
      await saveProductsFavouriteImages(obj).then((response) => {
        fetchData();
      })
        .catch((error) => {
          console.error("Error saving image:", error);
        });
    } else {
      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            ProjectId: props?.projectId,
            projectPictureId: image?.pictureId,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
     
      saveProjectsFavouriteImages(obj)
        .then((response) => {
          fetchData();
        })
        .catch((error) => {
          console.error("Error saving image:", error);
        });
    }
  };


const handleDeleteImage = (image) => {
  if (props?.from === "product") {
    const deleteObj = {
      UserId: Number(localStorage.userId),
      ReturnRecordId: false,
      ReturnRecordError: true,
      DeleteList: [
        {
          ProductPictureId: image?.pictureId,
          ModifiedBy: Number(localStorage.userId),
        },
      ],
    };

    deleteProductsFavouriteImages(deleteObj)
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting image:", error);
      });
  } else {
    const deleteObj = {
      UserId: Number(localStorage.userId),
      ReturnRecordId: false,
      ReturnRecordError: true,
      DeleteList: [
        {
          projectPictureId: image?.pictureId,
          ModifiedBy: Number(localStorage.userId),
        },
      ],
    };
   
    deleteProjectsFavouriteImages(deleteObj)
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting image:", error);
      });
  }
};

  return (
    <>
     {imageLoading ? (
          <Loader />
        )  :(
      <Card className="cardBody" style={{ padding: "15px 15px 10px 15px" }}>
       {images?.length > 0 ? (
          <Grid container gap={1} columnSpacing={1}>
            {images?.map((image, index) => (
              <Grid item key={index} xs={12} sm={6} md={3.9} lg={3.9}>
                <div style={{ position: "relative" }}>
                  {image?.isFavorite && (
                    <StarIcon
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        color: "#fff",
                        zIndex: 1,
                      }}
                    />
                  )}
                  <img
                    src={image?.pictureUrl}
                    alt={`Image ${index}`}
                    style={{
                      width: "100%",
                      height: "200px",
                      cursor: "pointer",
                    }}
                    onError={(e) => {
                      e.target.src = defaultProjectImage;
                    }}
                    onClick={() => openPictureViewer(index)}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        ) : (
          <p>No photos available.</p>
          )}
      </Card>
        )}

      {
        viewerVisible &&
        <CustomImageViewer
          images={images}
          selectedImageIndex={selectedImageIndex}
          setSelectedImageIndex={setSelectedImageIndex}
          open={viewerVisible}
          onClose={closePictureViewer}
          handleSaveImage={handleSaveImage}
          handleDeleteImage={handleDeleteImage}
          getUpdatedPicList={async () => {
            try {
              const pictures = await props?.getData();
              setImages(pictures);
            } catch (error) {
              console.error("Error fetching images:", error);
            }
          }}
        />
      }

    </>
  );
};

export default ProfilePic;
