import { restGet, restPost } from "./RestClient";
import { form_headers, headers } from "./UserApi";



export async function fetchUserListingByDistances(params) {
    try {
        const res = await restPost(`BuySell/FetchBuySellUserItemsDistance`, params, headers);
        return res.data;
    }
    catch (error) {
        console.log(error);
        throw error;
    }
}

export async function fetchUserSavedListings(params) {
    try {
        const res = await restPost(`BuySell/FetchBuySellUserItems`, params, headers);
        return res;
    }
    catch (error) {
        throw error;
    }
}
export async function updateExpiredItems(params) {
    try {
        const res = await restPost(`BuySell/UpdateExpiredItems`, params, headers);
        return res.data;
    }
    catch (error) {
        throw error;
    }
}

export async function deleteListings(params) {
    try {
        const res = await restPost(`BuySell/DeleteBuySellUserItems`, params, headers);
        return res.data;
    }
    catch (error) {
        throw error;
    }
}


export async function repostExpiredListings(params) {
    try {
        const res = await restPost(`BuySell/SaveUserItem`, params, headers);
        return res.data;
    }
    catch (error) {
        throw error;
    }
}

export async function updateBuySellUserItemsForRepost(params) {
    try {
        const res = await restPost(`BuySell/UpdateBuySellUserItemsForRepost`, params, headers);
        return res.data;
    }
    catch (error) {
        throw error;
    }
}




export async function deleteUserListingPictures(params) {
    try {
        const res = await restPost(`BuySell/DeleteBuySellUserItemPictures`, params, headers);
        return res.data;
    }
    catch (error) {
        throw error;
    }
}


export async function saveUserListings(params) {
    try {
        const res = await restPost(`BuySell/SaveUserItem`, params, headers);
        return res.data;
    }
    catch (error) {
        throw error;
    }
}

export async function saveUserListingPictures(params) {
    try {
        const res = await restPost(`BuySell/SaveUserItemPicture2`, params, headers);
        return res.data;
    }
    catch (error) {
        throw error;
    }
}
