import { restGet, restPost } from "./RestClient";
import { headers } from "./UserApi";

// let headers = {
//     "Content-Type": "application/json",
//     "Accept": "application/json",
//     "Authorization": ('Bearer ' + localStorage.getItem('token')),
// }

export async function shareResumeWith(data) {
  const res = await restPost("users/shareResumeWith", data, headers);
  return res;
}

export async function getUserContact(data) {
  const res = await restGet(`users/getUserContacts/${data.UserId}/`, headers);
  return res;
}

export async function saveUsersResumes(data) {
  const res = await restPost("users/saveUsersResumes/", data, headers);
  return res;
}

export async function FetchUsersResumes(data) {
  const res = await restPost(`Users/FetchUsersResumes/`, data, headers);
  return res;
}
export async function DeleteUsersResumes(data) {
  const res = await restPost("Users/DeleteUsersResumes/", data, headers);
  return res;
}
