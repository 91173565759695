import React, { useState, useEffect } from 'react'
import { Card, CircularProgress, FormControlLabel, Typography } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    IconButton,
    ListItem, ListItemButton, ListItemText,
    ListItemIcon,
    Stack,
} from "@mui/material";
import moment from "moment";
import Checkbox from '@mui/material/Checkbox';
import { Edit as EditIcon } from "@material-ui/icons";
import { BIBlue, BIGrey } from '../../assets/buildidColors';
import ErrorIcon from '@mui/icons-material/Error';

export default function SafetyObservationListSection(props) {
    const [response, setResponse] = useState(props.observationDataList);
    const [nrOfRecords, setNrOfRecords] = useState(props.noObservationList);

    
    useEffect(() => {
         setResponse(props.observationDataList)
         setNrOfRecords(props.noObservationList)
    }, [props?.observationDataList,props?.noObservationList])


    return (
        <div className='other-tab'>
            {response.length > 0
                ?
                <InfiniteScroll
                    dataLength={response.length}
                    next={async () => {
                        // await props.increaseOffset();
                        props.getData()
                            .then(res => {
                                setNrOfRecords(res.nrOfRecords);
                                setResponse(res.data);
                            });
                    }}

                    hasMore={true}
                // loader={props.fetchExpensesLength !== 0 && <div><Loader/></div>}
                >

                    <Stack spacing={2}>
                        {response.map((item, itemIndex) => {
                            return (
                                <div key={itemIndex}>
                                    <ListSectionItem
                                        itemData={item}
                                        itemVisitedDate={item.visitedDate}
                                        itemVisitedBy={item.visitedBy}
                                        itemSiteName={item.siteName}
                                        itemObservation={item.observation}
                                        itemActionTaken={item.actionTaken}
                                        itemIsImminentDanger={item.isImminentDanger}
                                        itemIsPositiveObservation={item.isPositiveObservation}
                                        itemIndex={itemIndex}
                                        itemGetEditSafetyObservationData={props.getEditSafetyObservationsData}
                                    /></div>)
                        })}
                    </Stack>
                </InfiniteScroll>
                : <>{!nrOfRecords !== 0
                    ? <CircularProgress style={{ textAlign: "center", marginTop: "50%", marginLeft: "50%" }} /> : null}</>}
        </div>
    )
}

function ListSectionItem(props) {

    return (
        <>
            <Card>
                <ListItem key={props.itemIndex}
                    secondaryAction={
                        <div style={{ display: 'flex' }}>
                            <ListItemText
                                style={{ marginRight: '2rem' }}
                                primaryTypographyProps={{ fontSize: '5px', color: BIBlue }}
                                primary={<>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    style={{ color: BIBlue }}
                                                    checked={props.itemIsImminentDanger}
                                                />}
                                            label={<Typography style={{ color: BIGrey }}>Imminent Danger</Typography>}
                                        /> </div>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    style={{ color: BIBlue }}
                                                    checked={props.itemIsPositiveObservation}
                                                />}
                                            label={<Typography style={{ color: BIGrey }}>Positive Observation</Typography>}
                                        />
                                    </div>
                                </>}
                            />

                            <IconButton
                                style={{ color: BIBlue }}
                                onClick={() => props.itemGetEditSafetyObservationData(props.itemData)}>
                                <EditIcon />
                            </IconButton>
                        </div>
                    }
                    disablePadding
                >
                    <ListItemButton>
                        <div key={props.itemIndex} style={{ display: 'flex', flexDirection: 'row' }}>
                            <ListItemText
                                primary={<div>{props.itemSiteName} {props.itemIsImminentDanger && <ErrorIcon style={{ color: 'red', fontSize: '15px' }} />} </div>}
                                secondary={<div key={props.itemIndex}>
                                    <div>{props.itemObservation}</div>
                                    <div>{props.itemVisitedBy}</div>
                                    <div>{moment(props.itemVisitedDate).format("MMMM DD, YYYY")}</div>
                                </div>}
                            />
                        </div>
                    </ListItemButton>
                </ListItem>
            </Card>
        </>
    )
}