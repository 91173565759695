import React from "react";
import { Box, Container, IconButton } from "@mui/material";

const StyleItem = ({ icon, data }) => {
  return (
    <Container>
      <div className="heading">
        <h2 className="styleitemheading">{data.heading}</h2>
        <div className="content" >
          <div className="itemicon">
            <IconButton color="primary" className="styleitemicon" >
              {icon}
            </IconButton>
          </div>
          <Box className="styleitemtext" >{data.text}</Box>
        </div>
      </div>
    </Container>
  );
};

export default StyleItem;
