import React from "react";
import {
    Box,
    Modal,
    Typography,
    IconButton,
    Stack,
} from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import { useState } from "react";
import { saveSitesDetails } from "../../api/SitesApi";
import { TextField, Button } from "@material-ui/core";

const AddSiteNameModal = (props) => {

    const { siteDetails } = props;
    const [name, setName] = useState("");

    const onClose = () => {
        props.onClose();
        setName("");
    }

    const onSave = async () => {
        try {
            if (!siteDetails) return onClose();
            const obj = {
                UserId: Number(localStorage?.userId),
                ReturnRecordId: true,
                ReturnRecordError: true,
                SaveList: [
                    {
                        Id: siteDetails.id,
                        Name: name,
                        Address: siteDetails?.address,
                        City: siteDetails?.city,
                        Province: siteDetails?.province,
                        PostalCode: siteDetails?.postalCode,
                        Country: siteDetails?.country,
                        Latitude: siteDetails?.latitude,
                        Longitude: siteDetails?.longitude,
                        RecordStatusId: 1,
                        ModifiedBy: Number(localStorage?.userId),
                    },
                ],
            };
            await saveSitesDetails(obj);
            props.onDataSave();
            onClose();
        } catch (error) {
            console.log("Error while saving description :: ", error);
        }
    }


    return (
        <Modal
            open={props.open}
            onClose={onClose}
            aria-labelledby="add-description-modal"
            aria-describedby="add-description-form">
            <Box className="modal-center" sx={{ width: 600 }}>
                <div className="modal-card" style={{ padding: "15px" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 2,
                        }}>
                        <Typography variant="h6">Add Site Name</Typography>
                        <IconButton
                            onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {/* <Typography variant="h6">Add New Description</Typography> */}
                    <Stack style={{ marginBottom: '15px' }}>
                        <TextField
                            fullWidth
                            label="Enter site name"
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                    </Stack>
                    <Stack direction={'row'} justifyContent={'end'} gap={2}>
                        <Button
                            onClick={onClose}
                            variant="outlined"
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={onSave}
                            variant="contained"
                            color="primary"
                            disabled={name?.trim() === ""}
                        >
                            Save
                        </Button>
                    </Stack>

                </div>{" "}
            </Box>
        </Modal>
    );
};

export default AddSiteNameModal;
