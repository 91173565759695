import React from "react";
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import Data from "../Data";
import "../Taketour.css"
import { Button } from "@mui/material";
import StyleItem from "../StyleItem";

const Chat = (props) => {
  const { chatData } = Data();
  var isAndroid = /Android/i.test(navigator.userAgent);
  var isIphone = /iPhone/i.test(navigator.userAgent);
  var isIpad = /iPad/i.test(navigator.userAgent);

  const handleGetStarted = () => {
    props.onClose();
    if (localStorage?.userId)
      if (isAndroid) {
        const url = `intent://#Intent;scheme=mybuilderid;package=com.mybuilderid;end`
        window.location.replace(url);
      } else if (isIphone || isIpad) {
        const start = new Date().getTime();
        const url = 'mybuilderid://'; // Custom URL scheme for your app
        const appStoreURL = 'https://apps.apple.com/in/app/buildid-connect-share-build/id1490164104'; // Replace with your App Store URL
        let isAppOpened = false;
      
        const handleVisibilityChange = () => {
          if (document.visibilityState === 'hidden') {
            isAppOpened = true;
          }
        };
        // Add the visibility change listener
        document.addEventListener('visibilitychange', handleVisibilityChange);
        // Attempt to open the app
        window.location = url;
        setTimeout(() => {
          // If the app wasn't opened, redirect to the App Store
          if (!isAppOpened && new Date().getTime() - start < 500) {
            window.location = appStoreURL;
          }
          // Cleanup the event listener
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        }, 0);
      }
      else {
        window.location.href = "/dashboard";
      }
  };

  return (
    <div>
      <QuestionAnswerRoundedIcon style={{ height: "100px", width: "100px" }} className="headicon" />
      {chatData.map((item, index) => (
        <StyleItem key={index} icon={item.icon} data={item} />
      ))}
      <Button onClick={handleGetStarted} variant="contained" color="primary">
        Get Started
      </Button>
    </div>
  );
};

export default Chat;
