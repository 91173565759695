import { restGet, restPost } from "./RestClient";
import { form_headers, headers } from "./UserApi";

// let tokenNo = localStorage.getItem('token')

// let headers = {
// 	"Content-Type": "application/json",
// 	"Accept": "application/json",
// 	"Authorization" : ('Bearer ' + tokenNo ),
// }

// Gets Trade options for Experience Dropdown
export async function fetchTrades(args) {
	const res = await restPost("misc/fetchTrades/", args, headers);
	return res;
}


export async function saveTrade(args) {
	const res = await restPost("misc/SaveTrade/", args, headers);
	return res.data;
}

export async function requestTrade(args) {
	const res = await restPost("misc/SaveTrades/", args, form_headers);
	return res.data;
}

export async function fetchUserTrades(args) {
	const res = await restPost("users/FetchUserTrades/", args, headers);
	return res.data;
}

export async function saveUserTrades(args) {
	const res = await restPost("users/SaveUserTrades/", args, headers);
	return res.data;
}

export async function deleteUserTrades(args) {
	const res = await restPost("users/DeleteUserTrades/", args, headers);
	return res.data;
}

export async function getTradeMembers(tradeId) {
	const res = await restGet("users/getTradeMembers/" + localStorage.userId + "/" + tradeId, headers);
	return res;
}

export async function getTradeDetails(tradeId) {
	const res = await restGet("Misc/GetTradeDetailsForWeb/" + tradeId);
	return res;
}

export async function fetchRelatedTradesForWeb(args) {
	const res = await restPost("misc/FetchRelatedTradesForWeb/", args);
	return res;
}

export async function fetchTradeNamesForWeb(args) {
	const res = await restPost("misc/FetchTradeNamesForWeb/", args);
	return res;
}

export async function fetchTradeNames(args) {
	const res = await restPost("misc/FetchTradeNames/", args, headers);
	return res;
}
export async function saveTradeNames(args) {
	const res = await restPost("misc/SaveTradeNames/", args, headers);
	return res;
}
export async function fetchTradesJobPosts(args) {
	const res = await restPost("Sites/FetchTradesJobPosts", args, headers);
	return res;
}

export async function fetchRelatedTrades(args) {
	const res = await restPost("misc/FetchRelatedTrades", args, headers);
	return res;
  }

export async function searchTrade(args) {
	const res = await restPost("misc/searchTrades", args, headers);
	return res;
}
