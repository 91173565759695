import React, { useState, useRef, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Card, Avatar, TextField, InputAdornment, Input, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { savePost, savePostPicture } from "../../api/PostsApi";
import PromptModal from "../../components/PromptModal";
import Loader from "../../components/Loader";
import AlertModal from "../AlertModal";
import { fetchUserTrades } from "../../api/UserApi";
import { Button } from "@material-ui/core";

export default function PostInput(props) {
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [discardModalOpen, setDiscardModalOpen] = useState(false);
	const [inappropriateImageAlert, setInappropriateImageAlert] = useState(false);
	const [userComment, setUserComment] = useState("");
	const [tempPic, setTempPic] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const fileInputRef = useRef(null);
	const [tradeName, setTradeName] = useState("");

	useEffect(async () => {
		if (dialogOpen) {
			try {
				await fetchUserTrades({ SearchList: [{ UserId: localStorage.userId }] })
					.then((res) => {
						const mainTradeDetails = res?.filter((trade) => trade?.tradeType?.toLowerCase() === "main");
						return mainTradeDetails?.[0]?.tradeName ? setTradeName(mainTradeDetails?.[0]?.tradeName) : setTradeName(null)
					}).catch((err) => {
						setTradeName(null)
						console.log("Error while getting user trade details:-", err)
					})
			} catch (err) {
				console.log("Error while getting user trade details:-", err)
			}
		}

	}, [dialogOpen])


	const handleClickOpen = () => {
		setUserComment("");
		setTempPic([])
		setDialogOpen(true);
	};

	const handleClose = () => {
		setUserComment("");
		setTempPic([])
		setDialogOpen(false);
	};

	async function onDeletePic(index) {
		const updatedPic = tempPic.filter((i) => i !== index)
		setTempPic(updatedPic)
	}

	function handleDiscardClick() {
		setUserComment("");
		setTempPic([]);
		setDiscardModalOpen(false);
		setDialogOpen(false);
	}

	const handleFileSelect = (event) => {
		const files = event.target.files;
		let images = [];
		for (let i = 0; i < files.length; i++) {
			images.push(files[i]);
		}
		setTempPic([...images, ...tempPic]);
	}

	async function handlePostClick() {
		try {
			let allFilesValid = true;
			if (tempPic?.length > 0) {
				for (let i = 0; i < tempPic.length; i++) {
					if (!tempPic[i].type.startsWith('image/')) {
						allFilesValid = false;
						break;
					}
				}
			}
			if (!allFilesValid) return alert('Please select valid image files (JPEG, PNG, GIF, etc.).');
			setIsLoading(true);
			const obj = {
				UserId: Number(localStorage.userId),
				ReturnRecordId: true,
				ReturnRecordError: false,
				SaveList: [{
					Id: 0,
					FormatTypeId: 1,
					PostedByUserId: Number(localStorage.userId),
					AppId: props.appId,
					AppRecordId: props.appRecordId,
					Message: userComment !== '' ? userComment : '<<<picture>>>',
					Reported: false,
					ModifiedBy: Number(localStorage.userId)
				}],
			}
			setDialogOpen(false);
			const postResult = await savePost(obj);
			if (tempPic.length !== 0) {
				setDialogOpen(false);
				await savePostImages(postResult[0].objData.id, props.productId);

			}
			else {
				setDialogOpen(false);
				await props.updatePosts()
			}

			if (props?.onSaveFunction) {
				props?.onPostSave()
			}

			setTempPic([]);
			setUserComment("");
		}
		catch (error) {
			console.log(error);
		}
		setIsLoading(false);
	}

	async function savePostImages(postId) {
		setIsLoading(true);
		try {
			for (let i = 0; i < tempPic.length; i++) {
				let data = new FormData();
				data.append('Id', 0);
				data.append('PostId', postId);
				data.append('ModifiedBy', Number(localStorage.userId));
				data.append('Pictures', tempPic[i]);
				setDialogOpen(false);
				await savePostPicture(data);
			}

			await props.updatePosts();

			setTempPic([]);
			setUserComment("")
		}

		catch (error) {
			setIsLoading(true);
			console.log('Error from save post pictures:', error)
			if (error === "Content Inappropriate") {
				setInappropriateImageAlert(true);
			}
		}
		setIsLoading(false);
	}

	function handleCancelButtonClick() {
		// If there are changes to the form, prompt user before discarding
		if (tempPic.length > 0 || userComment !== "") {
			setDiscardModalOpen(true);
		}
		else setDialogOpen(false);
	}


	return (
		<div>

			<AlertModal
				isOpen={inappropriateImageAlert}
				onClose={() => setInappropriateImageAlert(false)}
				title={"Content Rejected"}
				body={'The text picture you entered has been automatically identified as inappropriate.'}
				inappropriateImage
				text1={'Please remove the content and try again.'}
			/>

			<PromptModal
				isOpen={discardModalOpen}
				onClose={() => {
					setDiscardModalOpen(false);
					setDialogOpen(true);
				}}
				title="Are you sure you want to leave?"
				desc="Your review will be discarded."
				redButtonText="Discard"
				onRedButtonClick={handleDiscardClick} />

			{
				isLoading
					? <Loader />
					:
					(
						<Card>
							<div className="flex-container-horizontal" style={{ alignItems: "center", padding: "0px 16px" }}>
								<Avatar
									alt="Profile Picture"
									src={localStorage.pictureUrl} />

								{/* comment writing body description */}
								<div className="input-container" style={{ flex: 1 }}>
									<TextField
										variant="outlined"
										size="small"
										fullWidth
										onClick={handleClickOpen}
										// InputProps={{
										// 	endAdornment: (
										// 		<InputAdornment value={userComment} position="end">
										// 			<label htmlFor="icon-button-file-new">
										// 				<input
										// 					accept="image/*"
										// 					id="icon-button-file-new"
										// 					multiple={true}
										// 					type="file" onChange={event => {
										// 						const files = event.target.files;
										// 						const pictures = [];
										// 						Array.from(files).forEach(file => pictures.push(file));
										// 						setTempPic(tempPic.concat(pictures))
										// 					}}
										// 					style={{ display: "none" /* Hide underlying HTML component */ }} />
										// 			</label>
										// 		</InputAdornment>
										// 	)
										// }}
										placeholder="What do you want to talk about?" />
								</div>
							</div>
						</Card>
					)

			}

			<Dialog open={dialogOpen} onClose={handleClose} >
				<DialogTitle>Write a Post</DialogTitle>
				<DialogContent style={{ width: '600px', maxHeight: '500px' }}>
					<div className="flex-container-horizontal" style={{ marginBottom: '15px' }}>
						<div className="flex-container-vertical">
							<Avatar
								alt="Profile Picture"
								src={localStorage.pictureUrl} />
						</div>

						<div className="flex-container-vertical" style={{ flex: 1, marginLeft: "10px" }}>
							<div>{localStorage?.firstName + " " + localStorage?.lastName}</div>
							<div>{tradeName ?? "No Trade"}</div>
						</div>
					</div>

					<div className="flex-container-horizontal">
						<TextField
							variant="outlined"
							size="small"
							multiline
							sx={{ backgroundColor: "white" }}
							fullWidth
							value={userComment}
							onChange={event => setUserComment(event.target.value)}
							placeholder="What do you want to talk about?" />
					</div>


					<div style={{ maxHeight: '350px', overflowY: 'auto', marginTop: '10px' }}>
						{
							tempPic?.length !== 0 &&
							(
								<>
									<div style={{ display: "flex", justifyContent: "center", flexWrap: 'wrap' }}>
										{
											tempPic?.map((picture, index) => (
												<div
													className="picture-container"
													style={{ margin: "0px 16px", height: "350px", display: "flex" }}
													key={index}
												>
													<div className="picture-container-button">
														<IconButton size="small" onClick={() => onDeletePic(picture, index)}>
															<CloseIcon />
														</IconButton>
													</div>
													<img
														src={URL.createObjectURL(picture)} alt={picture?.name}
														width="100%"
													/>
												</div>
											))

										}

									</div>

								</>
							)
						}
					</div>
				</DialogContent>

				<div className="flex-container-horizontal" style={{ justifyContent: "space-between", alignItems: "center", margin: '0 10px' }}>
					<div className="input-container">
						<Button color={tempPic?.length !== 0 ? "secondary" : "primary"} aria-label="upload picture" component="span"
							onClick={() => { fileInputRef?.current?.click() }}
						>
							Add Photos
						</Button>
					</div>


					<div className="flex-container-horizontal">
						<div className="input-container">
							<Button onClick={handleCancelButtonClick}>Cancel</Button>
						</div>

						<div className="input-container">
							<Button
								onClick={() => handlePostClick()}
								disabled={(tempPic?.length === 0 && userComment === "")}>
								Post</Button>
						</div>
					</div>
				</div>
			</Dialog>
			<input
				type="file"
				ref={fileInputRef}
				style={{ display: 'none' }}
				onChange={handleFileSelect}
				accept="image/*"
				multiple
			/>
		</div>
	);
}
