import React, { useState, useEffect, useCallback } from "react";

import {
	Stack,
	Card,
	CardContent,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	ListSubheader,
	Avatar,
	Menu,
	MenuItem,
	Divider,
	TextField,
	InputAdornment,
} from "@mui/material";
import { MoreVert as MoreIcon } from "@mui/icons-material";
import SearchField from "../SearchField";
import { useHistory } from "react-router-dom";
import { BIGrey, BIRed } from "../../assets/buildidColors";
import { fetchProducts } from "../../api/ProductsApi";
import ProductSearchField from "../ProductSearchField";
import "../../assets/css/Timesheets.css";
import Loader from "../Loader";
import Placeholder from "../Placeholder";
import BuildIcon from "@mui/icons-material/Build";
import { Search as SearchIcon } from "@mui/icons-material";
import ReportModal from "../ReportModal";
import defaultProjectImage from "../../assets/images/project-profile-placeholder.jpg";
import AddItemModelProducts from "./AddItemModelProducts";
import { Button } from "@material-ui/core";

/*
List component
Props (*required):
	*getData			- function; API function that returns the items in the list (uncategoried)
	*itemIdKey			- string; key name in JSON item for ID, used for redirection
	*itemNameKey		- string; key name in JSON item for name, displayed as primary text
	*itemRecordType		- string; name of record type to redirect to

	To display more information for each list item, use the following props to define the keys:
	itemImgKey			- string; key name in JSON item for image URL, displayed as thumbnail
	itemSecondaryKey	- string; key name for JSON item for text to be displayed as secondary text

	Lists are uncategorized, unless category props are used (all must be used, or none):
	categoryIdKey		- string; key name in JSON item for category ID, used for redirection
	categoryNameKey		- string; key name in JSON item used to categorize items
	categoryRecordType	- string; name of record type to redirect to

	searchPlaceholder	- string; text displayed in search field when empty
	
	To render an "Add Item" button, use the following props (all except addItemCategoryLabel are required):
	addButtonLabel		- string; text displayed for button to add an item to the list
	addItemLabel		- string; placeholder text for Add Item form
	addItemCategoryLabel	-	string; placeholder text for Add Item form (optional, if not specified
								field will not be rendered)
	onSaveItem			- function; API function that saves the item
	
Usage:
<ListSection
	getData={() => }
	itemIdKey=""
	itemNameKey=""
	searchPlaceholder=""
	/>
*/
export default function ListSection(props) {
	const history = useHistory();
	const [addItemModalOpen, setAddItemModalOpen] = useState(
		props?.addItemModalOpen ?? false
	);
	const [searchTerm, setSearchTerm] = useState("");
	const projectIdArray = props?.workingHistory
		? props?.workingHistory.map((work) => work.id)
		: [];
	const [itemRecordId, setItemRecordId] = useState(0);

	/*
		  formattedData is an array with elements with the following format:
		  {
			  {props.categoryIdKey}:		int
			  {props.categoryNameKey}:	string
			  items: [
				  { JSON object from API }
			  ]
		  }
	  */
	const [formattedData, setFormattedData] = useState([]);
	/*
		  filteredData is a copy of formattedData that contains only elements whose category[categoryNameKey] or
		  item[itemNameKey] matches the search term
	  */
	const [filteredData, setFilteredData] = useState([]);


	function removeDuplicates(originalArray, prop) {
		let newArray = [];
		let lookupObject = {};
		for (let i in originalArray) {
			lookupObject[originalArray[i][prop]] = originalArray[i];
		}
		for (let i in lookupObject) {
			newArray.push(lookupObject[i]);
		}
		return newArray;
	}

	const updateList = useCallback(() => {
		props.getData().then((res) => {
			// Format response from list of elements into a list of category objects with a list
			// of items under that category
			let formatted = [];
			let filterDataProduct = [];
			if (props.productProjects) {
				filterDataProduct = removeDuplicates(res?.data, "projectId");
			} else {
				filterDataProduct = res?.data;
			}
			filterDataProduct.forEach((element) => {

				{
					/* udpate Projects cahnges Yash 19-03 */
				}

				if (props.itemRecordType === "projects")
					setItemRecordId(element?.projectId);
				if (props.itemRecordType === "product")
					setItemRecordId(element?.productId);
				{
					/* udpate Projects cahnges Yash 19-03 */
				}

				let existingCategory = false;
				formatted.forEach((obj) => {
					if (element[props.categoryIdKey] === obj[props.categoryIdKey]) {
						existingCategory = true;
						obj.items.push(element);
					}
				});

				if (!existingCategory) {
					let newCategory = {};
					newCategory[props.categoryIdKey] = element[props.categoryIdKey];
					newCategory[props.categoryNameKey] =
						// If element[props.categoryIdKey] is 0, API will have no name (correct)
						// Display element[props.categoryNameKey] as "No Role"
						element[props.categoryIdKey] !== 0 &&
							element[props.categoryIdKey] !== 7
							? element[props.categoryNameKey]
							: element[props.categoryIdKey] == 7
								? element[props.categoryNameKey]
								: "No Role";
					newCategory.items = [element];
					formatted.push(newCategory);
				}
			});

			formatted.sort((a, b) => {
				// Check if 'title' is empty or is "No Role"
				if (
					!a[props.categoryNameKey].trim() ||
					a[props.categoryNameKey] === "No Role"
				) {
					return 1; // Place empty or "No Role" items at the end
				} else if (
					!b[props.categoryNameKey].trim() ||
					b[props.categoryNameKey] === "No Role"
				) {
					return -1; // Place empty or "No Role" items at the end
				} else {
					// Sort other items based on the title
					return a[props.categoryNameKey].localeCompare(
						b[props.categoryNameKey]
					);
				}
			});

			// Sort each items subarray in alphabetical order of item names
			formatted.forEach((obj) => {
				obj.items.sort(function (a, b) {
					if (a[props.itemNameKey] < b[props.itemNameKey]) return -1;
					if (a[props.itemNameKey] > b[props.itemNameKey]) return 1;
					return 0;
				});
			});
			setFormattedData(formatted);
		});
	}, []);

	// On searchTerm change, update to only display data that is matching searchTerm
	useEffect(() => {
		let data = [];
		formattedData.forEach((category) => {
			// Base case; if searchTerm matches categoryNameKey then display the entire section
			if (
				category[props.categoryNameKey]
					?.toLowerCase()
					.includes(searchTerm.toLowerCase())
			)
				data.push(category);
			// If searchTerm matches itemNameKey or itemSecondaryKey of item, then display the item(s) as
			// well as the category subheading
			else {
				let matchingItems = [];
				category.items.forEach((item) => {
					if (
						item[props.itemNameKey]
							?.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
						item[props.itemSecondaryKey]
							?.toLowerCase()
							.includes(searchTerm.toLowerCase())
					)
						matchingItems.push(item);
				});

				if (matchingItems.length > 0) {
					let newCategory = {};
					newCategory[props.categoryIdKey] = category[props.categoryIdKey];
					newCategory[props.categoryNameKey] = category[props.categoryNameKey];
					newCategory.items = matchingItems;
					data.push(newCategory);
				}
			}
		});

		if (props?.companySites) {
			let locationData = [];
			if (projectIdArray.length != 0) {
				let a = [];
				locationData = data?.[0]?.items.map((res) => {
					a = res;
					a["isProjectExist"] = false;
					if (projectIdArray.includes(res.projectId)) {
						a["isProjectExist"] = true;
					}
					return a;
				});
				if (data?.[0]) {
					data[0].items = locationData;
				}
			}
		}
		setFilteredData(data);
	}, [searchTerm, formattedData, props]);

	// On load, retrieve data
	useEffect(() => {
		updateList();
	}, []);

	// get updated data after save/edit/delete
	useEffect(async () => {
		if (props?.getUpdatedData) {
			try {
				await updateList();
				props?.changeGetUpdateDataValue();
			} catch (err) {
				props?.changeGetUpdateDataValue();
			}
		}
	}, [props?.getUpdatedData]);

	const renderListItemSection = (data) => {
		return (
			<>
				{data.map((item, itemIndex) => (
					<ListSectionItem
						key={itemIndex}
						data={item}
						itemIdKey={props.itemIdKey}
						itemNameKey={props.itemNameKey}
						itemRecordType={props.itemRecordType}
						itemImgKey={props.itemImgKey}
						itemSecondaryKey={
							props.itemRecordType == "comapany"
								? props.otherSiteCompanyRole
								: props.itemSecondaryKey
						}
						recordId={itemRecordId}
						setRecordId={setItemRecordId}
					/>
				))}
			</>
		);
	};

	const renderSiteListByCategory = () => {
		const mySitesLists =
			filteredData?.[0]?.items?.filter((s) => s.isSiteExist) ?? [];
		const otherSitesLists =
			filteredData?.[0]?.items?.filter((s) => !s.isSiteExist) ?? [];

		return (
			<>
				{mySitesLists.length > 0 && (
					<div>
						<List style={{ padding: "0" }}>
							<ListSubheader style={{ marginBottom: "-10px" }}>
								My Sites
							</ListSubheader>
							{renderListItemSection(mySitesLists)}
						</List>
						<Divider />
					</div>
				)}

				{otherSitesLists.length > 0 && (
					<div>
						<List style={{ padding: "0" }}>
							{mySitesLists.length !== 0 && (
								<ListSubheader style={{ marginBottom: "-10px" }}>
									Other Sites
								</ListSubheader>
							)}

							{renderListItemSection(otherSitesLists)}
						</List>
						<Divider />
					</div>
				)}
			</>
		);
	};

	return (
		<>
			{props?.emptyData ? (
				<div>
					{props?.NoResultBtn && (
						<div style={{ display: "flex", justifyContent: "flex-end" }}>
							<Button
								variant="text"
								color="primary"
								size="small"
								onClick={() => setAddItemModalOpen(true)}>
								{props?.NoResultBtn ?? ""}
							</Button>
						</div>
					)}
					{/* <h1>hello</h1> */}
					{props?.buttonLabel && (
						<div style={{ display: "flex", justifyContent: "flex-end" }}>
							<Button
								variant="text"
								color="primary"
								size="small"
								onClick={() => props?.onButtonClick()}>
								{props?.buttonLabel ?? ""}
							</Button>
						</div>
					)}

					<Placeholder
						icon={props?.NOResultIcon ?? <BuildIcon />}
						text={props?.NoResultText ?? ""}
						text2={props?.NoResultText2 ?? ""}
					/>
				</div >
			) : (
				<>

					{props.isLoading ? (
						<Loader />
					) :
						<Card>
							<CardContent>
								{props?.searchTopDescription && (
									<>
										<div
											style={{
												color: "gray",
												padding: "10px 0px",
												fontSize: "14px",
											}}>
											{props?.searchTopDescription ?? ""}
										</div>
									</>
								)}
								<SearchField
									value={searchTerm}
									onChange={setSearchTerm}
									placeholder={props.searchPlaceholder}
								/>

								{props?.buttonLabel && (
									<div
										className="flex-container-horizontal"
										style={{ justifyContent: "flex-end" }}>
										<Button
											variant="text"
											color="primary"
											size="small"
											onClick={() => {
												props?.onButtonClick();
											}}>
											{props.buttonLabel}
										</Button>
									</div>
								)}

								{
									// Only render "Add Item" button if specified
									props.addButtonLabel ? (
										<div
											className="flex-container-horizontal"
											style={{ justifyContent: "flex-end" }}>
											<Button
												variant="text"
												color="primary"
												size="small"
												onClick={() => setAddItemModalOpen(true)}>
												{props.addButtonLabel}
											</Button>
										</div>
									) : (
										<></>
									)
								}
							</CardContent>
							<div>
								<List sx={{ padding: 0 }}>
									{!props?.companySites &&
										filteredData.map((category, categoryIndex) => (
											<div key={categoryIndex}>
												<ListSubheader style={{ lineHeight: "30px" }}>
													{
														// If props.categoryRecordType is not defined, it shouldn't redirect anywhere (ie. non-clickable)
														props.categoryRecordType ? (
															<span
																className={
																	category[props.categoryNameKey] !== ""
																		? "link"
																		: ""
																}
															// onClick={() => {
															// 	if (
															// 		props.categoryRecordType ===
															// 		"manufacturer" &&
															// 		category[props.categoryNameKey] != ""
															// 	)
															// 		history.push(
															// 			`/manufacturer/${category[props.categoryIdKey]
															// 			}`
															// 		);
															// }}

															>
																{category[props.categoryNameKey] == "" ? (
																	<span>Unknown Manufacturer</span>
																) : (
																	category[props.categoryNameKey]
																)}
															</span>
														) : (
															<> {category[props.categoryNameKey]}</>
														)
													}
												</ListSubheader>
												{renderListItemSection(category.items)}

												{/* <Divider /> */}
											</div>
										))}
									{props?.companySites && renderSiteListByCategory()}
								</List>
							</div>
						</Card>
					}
				</>
			)
			}

			<AddItemModelProducts
				open={addItemModalOpen}
				onClose={() => {
					setAddItemModalOpen(false);
				}}
				onProductSave={props?.onProductSave}
				updateList={updateList}
				title={props.addButtonLabel}
				modalTitle={props.modalTitle}
				addItemLabel={props.addItemLabel}
				itemNameKey={props.itemNameKey}
				addItemCategoryLabel={props.addItemCategoryLabel}
				categoryNameKey={props.categoryNameKey}
				updateData={formattedData}
				listSectionType={props?.listSectionType ?? ""}
				manufacturerInfo={props?.manufacturerInfo ?? {}}
				showManufactureNameInNewAddProduct={
					props?.showManufactureNameInNewAddProduct ?? false
				}
			/>
		</>
	);
}

function ListSectionItem(props) {
	const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
	const [isReportModalOpen, setReportModalOpen] = useState(false);
	const history = useHistory();
	const handleOpenReportModal = (id) => {
		props.setRecordId(id);
		setReportModalOpen(true);
		setMoreMenuAnchorEl(null);
	};
	return (
		<ListItem
			style={{ padding: '0px' }}
			secondaryAction={
				<>
					<IconButton
						onClick={(event) => setMoreMenuAnchorEl(event.currentTarget)}>
						<MoreIcon />
					</IconButton>
					<Menu
						anchorEl={moreMenuAnchorEl}
						open={Boolean(moreMenuAnchorEl)}
						onClose={() => setMoreMenuAnchorEl(null)}
						anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
						transformOrigin={{ horizontal: "right", vertical: "top" }}>
						<MenuItem
							onClick={() => handleOpenReportModal(props.data[props.itemIdKey])}
							style={{ color: BIRed }}
							dense>
							Report
						</MenuItem>
					</Menu>
					<ReportModal
						name={
							props?.itemRecordType?.charAt(0).toUpperCase() +
							props?.itemRecordType?.slice(1)?.toLowerCase()
						}
						isOpen={isReportModalOpen}
						onClose={() => setReportModalOpen(false)}
						recordId={props.recordId}
					/>
				</>
			}
		// disablePadding={true}
		>
			<ListItemButton
				onClick={() => {
					if (props.itemRecordType === "site")
						history.push(`/sites/site/${props.data[props.itemIdKey]}`);
					if (props.itemRecordType === "projects")
						history.push(`/projects/project/${props.data[props.itemIdKey]}`);
					if (props.itemRecordType === "product")
						history.push(`/product/${props.data[props.itemIdKey]}`);
					if (props.itemRecordType === "company")
						history.push(`/company/${props.data[props.itemIdKey]}`);
				}}>

				{props?.data[props?.itemImgKey] ? (
					<Avatar
						variant="rounded"
						style={{ margin: "5px 15px 5px 5px" }}
						src={props.data[props.itemImgKey]}>
						{
							// Use first letter of item name as Avatar default
							// If a default image is to be used, it must be in props.data[props.itemImgKey]
							props.data[props.itemNameKey][0]
						}
					</Avatar>
				) : (
					<>
						{props?.data[props?.itemImgKey] == "" ? (
							<Avatar
								variant="rounded"
								style={{ margin: "5px 15px 5px 5px" }}
								src={defaultProjectImage}>
								{
									// Use first letter of item name as Avatar default
									// If a default image is to be used, it must be in props.data[props.itemImgKey]
									props.data[props.itemNameKey][0]
								}
							</Avatar>
						) : (
							<></>
						)}
					</>
				)}
				<ListItemText
					primary={<span> {props.data[props.itemNameKey]}</span>}
					secondary={
						props.data[props.itemSecondaryKey] == "" || props.data[props.itemSecondaryKey] == undefined ? (
							<div style={{ display: "none" }}></div>
						) : (
							<div style={{ marginBottom: "10px" }}>
								{props.data[props.itemSecondaryKey]}
							</div>
						)
					}
				/>
			</ListItemButton>
			<ReportModal
				name={
					props?.itemRecordType?.charAt(0).toUpperCase() +
					props?.itemRecordType?.slice(1)?.toLowerCase()
				}
				isOpen={isReportModalOpen}
				onClose={() => setReportModalOpen(false)}
				recordId={props.recordId}
			/>
		</ListItem>
	);
}
