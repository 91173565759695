import React, { useState } from 'react'
import {
    Dialog, DialogTitle, DialogContent,
    Button, IconButton,
    Stack, ListItemButton, ListItemText, DialogActions, Typography, FormControlLabel
    , Checkbox, ListItem, CircularProgress
} from '@mui/material'
import { BIBlue, BIGrey } from '../../assets/buildidColors'
import { Close as CloseIcon } from "@material-ui/icons";
import { sendTimeSheetsGoals } from '../../api/TimesheetApi';


const ExportGoalsSummaryModal = (props) => {

    const data = props?.userGoalData ?? [];
    const [selectedGoals, setSelectedGoals] = useState([]);
    const [loading, setLoading] = useState(false);

    const onClose = () => {
        setLoading(false);
        setSelectedGoals([])
        props?.onClose();
    }

    const onGenrateClick = async () => {
        try {
            setLoading(true)
            const pdfObj = {
                Emails: [localStorage?.primaryEmail],
                Goals: selectedGoals,
                UserId: Number(localStorage.userId)
            }
            await sendTimeSheetsGoals(pdfObj);
            onClose()
        } catch (err) {
            onClose()
            console.log("Error :: ", err)
        }
    }

    const handleCheckboxClick = (item) => {
        let updatedSelectedGoals;
        if (selectedGoals.includes(item.id)) {
            updatedSelectedGoals = selectedGoals.filter((goalId) => goalId !== item.id);
        } else {
            updatedSelectedGoals = [...selectedGoals, item.id];
        }
        setSelectedGoals(updatedSelectedGoals);
    };

    return (
        <>
            <Dialog
                open={props?.open}
                onClose={onClose}
                fullWidth
                maxWidth="sm"
                scroll={"paper"}
            >
                <DialogTitle>
                    <div className="modal-card-header-buttons" style={{ alignItems: 'center' }}>
                        <h2>Export Goals Summary</h2>
                        <IconButton
                            color="primary"
                            onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent style={{ paddingTop: 0 }}>
                    <Stack spacing={0}>
                        <Typography style={{ textAlign: 'center' }}>Select one or more Goals to generate a PDF summary of your progress.</Typography>
                        {
                            data?.length > 0 ?
                                <>
                                    <div style={{ margin: '20px 0 10px' }}>
                                        {data.map((item, index) => {
                                            return (
                                                <div key={index.toString()}
                                                    onClick={() => handleCheckboxClick(item)}
                                                >
                                                    <ListItem key={index.toString()}
                                                        disablePadding >
                                                        <ListItemButton sx={{ display: 'flex', flexDirection: 'row' }}>
                                                            <FormControlLabel
                                                                control={<Checkbox color="primary" checked={selectedGoals.includes(item?.id)} onChange={() => handleCheckboxClick(item)} />} />
                                                            <ListItemText>{item?.name?.trim()}</ListItemText>
                                                        </ListItemButton>
                                                    </ListItem>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>
                                :
                                <Typography style={{ color: BIGrey, textAlign: 'center', margin: '100px 0px' }}>
                                    You have not added any Goals yet.
                                </Typography>
                        }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {
                        loading ?
                            <Button
                                style={{ backgroundColor: BIBlue, color: 'white', width: '90%', margin: 'auto' }}>
                                <CircularProgress style={{ color: 'white', height: '26px', width: '26px' }} />
                            </Button>
                            :
                            <Button onClick={onGenrateClick}
                                disabled={selectedGoals?.length === 0}
                                style={{ backgroundColor: selectedGoals?.length === 0 ? BIGrey : BIBlue, color: 'white', width: '90%', margin: 'auto' }} >
                                GENERATE
                            </Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ExportGoalsSummaryModal