import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MenuList from "./MenuList";
import { BrowserRouter, Link, useLocation } from "react-router-dom";
import { makeStyles, IconButton } from "@material-ui/core";
import MapLogo from "../assets/images/map-marker-yellow.png";
import { useState } from "react";
import { version } from "../../package.json";
import TopAppBar from "./TopAppBar";
import BuilderBucksBar from "./BuilderBucksBar";
import Toastify from "../Toastify";
import { Notifications } from "react-push-notification";
import { ToastProvider } from "react-toast-notifications";
import { Avatar, Drawer, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getChatGroupDashboard } from "../api/ConnectApi";
import "../assets/css/Menubar.css";

const MiniDrawer = (props) => {
  const drawerWidthLarge = 235; // Large drawer width
  const drawerWidthSmall = 185; // Small drawer width

  const isLargeScreen = useMediaQuery("(min-width:1920px)");
  const drawerWidth = isLargeScreen ? drawerWidthLarge : drawerWidthSmall;

  // const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [chatNotification, setChatnotification] = useState(false);
  const [chatGroupData, setChatGroupData] = useState(null);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const fetchData = async () => {
    try {
      const data = await getChatGroupDashboard(
        Number(localStorage?.getItem("userId"))
      );
      let getChatGroupData = data?.sort((a, b) => {
        let dateA = new Date(a?.dateSent),
          dateB = new Date(b?.dateSent);
        return dateB - dateA;
      });
      let getValue = getChatGroupData
        ?.filter((group) => group?.nrOfUsersInGroup > 3)
        ?.slice(0, 3);
      setChatGroupData(getValue);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const drawer = (
    <div>
      <MenuList
        setChatnotification={setChatnotification}
        // value={open}
        chatGroupData={chatGroupData}
        fetchData={fetchData}
      />
    </div>
  );

  // Remove this const when copying and pasting into your project.
  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: "none",
              backgroundColor: "#f2f2f2",
            },
          }}
          // style={{ width: window.innerWidth < 1024 ? "auto" : "0px" }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "none",
              borderRight: "none",
              backgroundColor: "#f2f2f2",
              // width: window.innerWidth < 1024 ? "0px" : drawerWidth,
            },
          }}
          // open
          // open={window.innerWidth > 1024 ? true : false}
          className={"main-drawer"}
        >
          {drawer}
        </Drawer>
      </Box>

      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <div
          className={
            navigator.userAgent.indexOf("Chrome") !== -1
              ? "topbar-div-width-chrome"
              : "topbar-div-width"
          }
        >
          <TopAppBar
            setChatnotification={setChatnotification}
            chatNotification={chatNotification}
            // handleDrawerOpen={handleDrawerOpen}
            profilePictureUrl={props.profilePictureUrl}
            firstName={props.firstName}
            lastName={props.lastName}
            onLogOut={props.onLogOut}
            setUserId={props.setUserId}
            tradeName={props.tradeName}
            drawerWidth={drawerWidth}
            handleDrawerToggle={handleDrawerToggle}
          />
        </div>
        <div className="topbar-body">
          <Toastify />
          {props.children}
        </div>
      </Box>
    </Box>
  );
};

export default MiniDrawer;
