import React from "react";

import { Modal, IconButton, Button } from "@material-ui/core";


/*
Message modal that acts like alert(), but styled like other BuildID modals
AlertModal code in parent must be under any other modals in order to appear on top
Props (* required):
    *isOpen				- boolean; modal visibility variable
    *onClose			- function; to modify visibility state variable
    title				- string; alert message title
    body					- string; alert message body

Usage:
    <AlertProjectModal
        isOpen={}
        onClose={() => }
        title=""
        body=""/>
*/
function AlertProjectModal(props) {
    return (
        <Modal
            open={props.isOpen}
            onClose={props.onClose}>
            <div className="modal-center">
                <div className="modal-card" style={{ width: "350px" }}>
                    <div className="modal-card-body">
                        <div style={{ paddingBottom: "20px" }}>
                            <h3>{props.title}</h3>
                            <br />
                            <p>{props.content}</p>
                        </div>

                        <div className="flex-container-horizontal" style={{ justifyContent: "right" }}>
                            <Button
                                // variant="outlined"
                                color="primary"
                                onClick={props.onClose}>
                                <b>Close</b>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AlertProjectModal;