import React, { useState, useEffect, useCallback } from "react";

import {
    fetchUserTradeCertifications2,
} from "../../../api/UserApi";
import { Card, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import moment from "moment";
// import Loader from "../../../components/Loader";
// import PromptModal from "../../../components/PromptModal";

export default function UserCertificationsCard(props) {
    const [certifications, setCertifications] = useState([]);
    const updateCertifications = useCallback(
        () => {
            fetchUserTradeCertifications2({ SearchList: [{ "UserId": props.contactId }] })
                .then(res => {
                    if (res) {
                        let certificationsData = [];
                        res.forEach(cert => {
                            certificationsData.push({
                                id: cert.id || 0,
                                userId: cert.userId || 0,
                                userName: cert.userName || "",
                                school: cert.school || "",
                                years: cert.years || 0,
                                startDate: cert.startDate || null,
                                endDate: cert.endDate || null,
                                fieldOfStudy: cert.fieldOfStudy || "",
                                isPresent: cert.isPresent || false,
                                recordStatus: cert.recordStatus || "",
                                recordStatusId: cert.recordStatusId || 0,
                                dateCreated: cert.dateCreated || "",
                                dateModified: cert.dateModified || "",
                                createdBy: cert.createdBy || "",
                                modifiedBy: cert.modifiedBy || "",
                                createdByUserName: cert.createdByUserName || "",
                                modifiedByUserName: cert.modifiedByUserName || ""
                            })
                        })

                        certificationsData = certificationsData.sort((a, b) => {
                            let dateA = new Date(a.startDate), dateB = new Date(b.startDate);
                            return dateB - dateA;
                        });
                        setCertifications(certificationsData);
                    }
                });
        }, [props.contactId]);

    useEffect(() => {
        updateCertifications();
    }, [updateCertifications])

    useEffect(() => {
        return () => {
            setCertifications([]);
        }
    }, [])

    if (props?.showLoader) {
        return null;
    }

    return (
        <>
            {certifications.length != 0 && certifications != '' ?
                < div className="exp-card" >
                    <div className="flex-container-horizontal">
                        <div className="flex-container-left">
                            <div className="input-container">
                                <h3>Education</h3>
                            </div>
                        </div>
                    </div>
                    <List sx={{ padding: 0 }}>
                        {certifications.map((cert) => (
                            <Certification
                                key={cert.id}
                                cert={cert}
                                onUpdate={updateCertifications} />
                        ))}
                    </List>
                </div> : <></>}
        </>
    )
}


export function Certification(props) {
    // Display/TextField
    const id = props.cert.id;
    const school = props.cert.school;
    const isPresent = props.cert.isPresent;
    const fieldOfStudy = props.cert.fieldOfStudy;
    const startDate = props.cert.startDate;
    const endDate = props.cert.endDate;
    return (
        <Card sx={{ marginBottom: "10px" }}>
            <ListItem
                disablePadding>
                <ListItemButton >
                    <ListItemText
                        primary={fieldOfStudy}
                        secondary={<>
                            {props.cert.school ? (<span> {school} <br /> </span>) : (<span> {null} <br /> </span>)}

                            {	// If startDate is null or empty, then it's "No date"
                                (startDate !== null && startDate !== "")
                                    ? (
                                        <span>{moment(startDate).format('MMM YYYY')} - {
                                            isPresent === false ? endDate == null || endDate == "Invalid date" ? null : (moment(endDate).format('MMM YYYY')) : "Present"
                                        } <br /> </span>
                                    )
                                    : null
                            }
                        </>}
                    />
                </ListItemButton>
            </ListItem>
        </Card>
    )
}
