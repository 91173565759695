import { restGet, restPost } from "./RestClient";
import { headers } from "./UserApi";

// let headers = {
// 	"Content-Type": "application/json",
// 	"Accept": "application/json",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }

// Gets store items
// itemId is optional; if itemId = "", returns all items
export async function getStoreItems(itemId) {
	const res = await restGet("store/getStoreItems/" + itemId, headers);
	return res;
}

export async function fetchStoreItems(args) {
	const res = await restPost("store/FetchStoreItems/", args, headers);
	return res.data;
}


// Gets store items containing or matching searchString
export async function searchStoreItems(searchString) {
	const res = await restGet("store/searchStoreItems/" + searchString, headers);
	return res;
}

// Adds item to cart
// TODO supposedly removes and buys items too?
export async function saveUserPurchase(data) {
	const res = await restPost("store/saveUserPurchase/", data, headers);
	return res;
}

// Gets number of items in user's cart by userId
export async function getNrItemsShoppingCart(userId) {
	const res = await restGet("store/getNrItemsShoppingCart/" + userId, headers);
	return res;
}

// Gets user's shopping cart items by userId
export async function getUserShoppingCart(userId) {
	const res = await restPost("store/getUserShoppingCart/" + userId, headers);
	return res.data;
}

// Gets user's previous purchases items by userId
export async function getUserPurchases(userId) {
	const res = await restPost("store/getUserPurchases/" + userId, headers);
	return res.data;
}

// Gets user's previous purchases and answered questions
// export async function getUserRewardActivities(userId){
// 	const res = await restPost("store/getUserRewardActivities/" + userId, headers);
// 	return res.data;
// }


export async function getUserRewardActivities(userId) {
	const res = await restGet("users/getUserRewardActivities/" + userId, headers);
	return res;
}


export async function getUserRewardActivities2(userId, string, pageNr, nrOfRecords) {
	const res = await restGet(`users/GetUserRewardActivities2/${userId}/${string}/${pageNr}/${nrOfRecords}`, headers);
	return res;
}

export async function fetchRewardActivities(args) {
	const res = await restPost("misc/fetchRewardActivities/", args, headers);
	return res.data;
}

export async function fetchUserRewardactivities(args) {
	const res = await restPost("users/FetchUserRewardActivities/", args, headers);
	console.log("res 04+09-2024-->",res);
	return res.data;
}