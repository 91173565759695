import React from "react";

import {
    TextField,
    InputAdornment,
    IconButton,
} from "@mui/material";

import {
    Search as SearchIcon,
    Close as CloseIcon
} from "@mui/icons-material";


/*
SearchField component with "clear" button when there is a value
Props (*required):
    *value		- string; controlled state contents of SearchField
    *onChange	- function; changes the controlled state value
    onSubmit	- function; executed when "Enter" is pressed
    placeholder	- string; text to be displayed when there is no content

Usage:
    <SearchField
        value={}
        onChange={}/>
*/
export default function ProductSearchField(props) {
    return (
        <TextField
            value={props.value}
            onChange={event => props.onChange(event.target.value)}
            onKeyPress={(event) => {
                if (event.key === "Enter" && props.onSubmit) {
                    props.onSubmit();
                }
            }}
            // focused={() => props.isFocused()}
            onFocus={() => props.isFocused()}
            placeholder={props.placeholder}
            size={props?.size}
            color="primary"
            fullWidth
            InputProps={{
                startAdornment: ((props.icon == true) ? <></> : (<InputAdornment position="start"><SearchIcon /></InputAdornment>)),
                endAdornment: (
                    // Display an "X" button to clear contents if value not empty
                    (props.value !== "")
                        ? (
                            <IconButton
                                onClick={() => props.onChange("")}>
                                <CloseIcon />
                            </IconButton>
                        )
                        : <></>
                )
            }} />
    )
}