import React, { useEffect, useState, useRef } from 'react'
import { Button, Grid, IconButton, Modal, TextField, Box, Typography, CircularProgress, Checkbox } from '@mui/material'
import { Close } from '@material-ui/icons'
import { fetchClientEmployee, saveClientCoachings } from "../../../api/ClientApi"
import Autocomplete from '@mui/material/Autocomplete';
import "../../../assets/css/ClientHome.css"


export const AddClientCoachingModal = ({ isOpen, onClose, getCoachingList }) => {
    const hint = useRef('');
    const [isLoading, setIsLoading] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [description, setDescription] = useState("");
    const [selectedValue, setSelectedValue] = useState(null);
    const [isAnonymous, setIsAnonymous] = useState(false);

    const getClientEmployeeList = async () => {
        try {
            const obj = {
                PageNr: 1,
                NrOfRecPerPage: 20,
                FullSearch: "",
                UserId: localStorage.userId,
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [{ ClientEmployeeId: localStorage.getItem("clientEmployeeId") }],
            };

            const res = await fetchClientEmployee(obj);
            const options = [];

            res.data.forEach((item) => {
                const option = {
                    value: item.firstName + " " + item.lastName,
                    label: item.firstName + " " + item.lastName,
                    id: item.id,
                };
                options.push(option);
            });
            setEmployeeList(options);
        } catch (err) {
            console.log("ERROR WHILE FETCHING CLIENT EMPLOYEES", err);
        }
    };

    const handleCoachingSubmit = async () => {
        try {
            setIsLoading(true);
            const { employeeId } = selectedValue;
            const obj = {
                ReturnRecordId: true,
                ReturnRecordError: true,
                SaveList: [
                    {
                        Id: 0,
                        ClientEmployeeId: localStorage.getItem("clientEmployeeId"),
                        ClientEmployeeId: employeeId,
                        Description: description,
                        SubmitAnonymously: isAnonymous,
                        ModifiedBy: localStorage.userId
                    }
                ]
            }

            const saveCoachingRes = await saveClientCoachings(obj);
            if (saveCoachingRes?.message === "OK") {
                onClose();
                setIsLoading(false);
                getCoachingList();
            }
        } catch (err) {
            console.log("Error while saving client coaching ", err);
            setIsLoading(false);
        }
    }

    const handleAnonymousFlag = () => {
        setIsAnonymous(!isAnonymous);
    }

    useEffect(() => {
        getClientEmployeeList();
    }, [])

    return (
        <>
            <Modal
                open={isOpen}
                onClose={onClose}>
                <div className="modal-center">
                    <div className="modal-card" style={{ height: "500px", width: "600px" }}>
                        <div style={{ display: "flex", alignItems: "center", padding: "10px", gap: "10px" }}>
                            <IconButton
                                color="primary"
                                onClick={onClose} style={{ padding: "0px" }}>
                                <Close />
                            </IconButton>
                        </div>
                        <div style={{ height: "420px", overflowY: 'scroll', padding: "10px" }}>
                            <Grid marginLeft={4} marginRight={4} gap={2} marginTop={2}>
                                <Typography variant='subtitle1' gutterBottom>Employee</Typography>
                                <Autocomplete
                                    fullWidth
                                    onKeyDown={(event) => {
                                        if (event.key === 'Tab') {
                                            if (hint.current) {
                                                setInputValue(hint.current);
                                                event.preventDefault();
                                            }
                                        }
                                    }}
                                    onBlur={() => {
                                        hint.current = '';
                                    }}
                                    disablePortal
                                    inputValue={inputValue}
                                    filterOptions={(options, state) => {
                                        const displayOptions = options.filter((option) =>
                                            option.label.toLowerCase().includes(state.inputValue.toLowerCase().trim()) ||
                                            (option.name && option.name.toLowerCase().includes(state.inputValue.toLowerCase().trim())),
                                        );

                                        return displayOptions;
                                    }}
                                    id="combo-box-hint-demo"
                                    options={employeeList}
                                    renderOption={(props, option) => (
                                        <Box {...props}>
                                            <Typography>{option.label}</Typography>
                                        </Box>
                                    )}
                                    onChange={(event, newValue) => {
                                        setSelectedValue({ employeeId: newValue?.id })
                                        if (newValue) {
                                            setInputValue(newValue.label); // Update the input value to the selected label
                                        } else {
                                            setInputValue('');
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <Box sx={{ position: 'relative' }}>
                                            <Typography sx={{ position: 'absolute', opacity: 0.5, left: 14, top: 16 }}>
                                                {hint.current}
                                            </Typography>
                                            <TextField
                                                {...params}
                                                onFocus={() => setInputValue("")}
                                                // onBlur={() => {
                                                //     if (individualSafetyObservation) {
                                                //         if (!inputValue)
                                                //             setInputValue(individualSafetyObservation?.clientJobCode)
                                                //     }
                                                // }}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    setInputValue(newValue);
                                                    const matchingOption = employeeList.find((option) =>
                                                        option.label.startsWith(newValue),
                                                    );

                                                    if (newValue && matchingOption) {
                                                        hint.current = matchingOption.label;
                                                    } else {
                                                        hint.current = '';
                                                    }
                                                }}
                                                label="Search for an Employee"
                                            />
                                        </Box>
                                    )}
                                />

                            </Grid>
                            <Grid marginLeft={4} marginRight={4} gap={2} marginTop={2}>
                                <Typography variant='subtitle1' gutterBottom>Description</Typography>
                                <TextField fullWidth multiline rows={5} value={description} onChange={(e) => setDescription(e.target.value)} />
                            </Grid>

                            <Grid marginLeft={4} marginRight={4} gap={2} marginTop={2} style={{ cursor: 'pointer' }}>
                                <Checkbox
                                    color="primary"
                                    checked={isAnonymous}
                                    onChange={handleAnonymousFlag}
                                />
                                <span onClick={handleAnonymousFlag}>
                                    Submit Anonymously
                                </span>
                            </Grid>

                            <Grid marginLeft={4} marginRight={4} gap={2} marginTop={2}>
                                {!isLoading ? <Button
                                    onClick={() => handleCoachingSubmit()}
                                    fullWidth
                                    disabled={!selectedValue || !description}
                                    style={{ background: localStorage?.clientAccentColor, color: 'white' }}>
                                    Submit
                                </Button>
                                    :
                                    <Button
                                        fullWidth
                                        disabled
                                        style={{ background: localStorage?.clientAccentColor, color: 'white' }}>
                                        <CircularProgress style={{ color: 'white', height: '26px', width: '26px' }} />
                                    </Button>}
                            </Grid>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
