import {
  Avatar,
  Box,
  Card,
  Chip,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import placeholder from "../../assets/images/project-profile-placeholder-thumbnail.png";
import blueMarker from "../../assets/images/map-marker-blue.png";
import { BIBlue, BIRed } from "../../assets/buildidColors";
import ReportModal from "../../components/ReportModal";
import "../../assets/css/Projects.css";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useLocation } from "react-router-dom";

const ProjectList = (props) => {
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(false);
  const [reportModalOpenSearch, setReportModalOpenSearch] = useState(false);
  const history = useHistory();
  const appLocation = useLocation();
  const handleReport = (id) => {
    setMoreMenuAnchorEl(false);
    setReportModalOpenSearch(true);
  };

  const getDate = () => {
    let displayStartDate = props?.project?.startDate
      ? moment(props?.project?.startDate).format("MM YYYY")
      : "";
    let displayEndDate = props?.project?.endDate
      ? moment(props?.project?.endDate).format("MM YYYY")
      : "";
    let displayFinalData = null;
    if (props?.project?.present) {
      if (displayStartDate.length !== 0) {
        displayFinalData = `${displayStartDate} - Present`;
        return displayFinalData;
      } else {
        displayFinalData = "- Present";
        return displayFinalData;
      }
    } else {
      if (displayStartDate.length !== 0 && displayEndDate.length !== 0) {
        displayFinalData = `${displayStartDate} - ${displayEndDate}`;
        return displayFinalData;
      } else if (displayStartDate.length === 0 && displayEndDate.length === 0) {
        return null;
      } else {
        displayFinalData = `${displayStartDate} - ${displayEndDate}`;
        return displayFinalData;
      }
    }
  };

  const onAddToProfile = () => {
    if (props?.project) {
      props?.onAddToProfile(props?.project);
    }
    setMoreMenuAnchorEl(false);
  };
  const onGoToProfile = () => {
    if (appLocation?.state?.from === "quiz") {
      let obj = {
        from: "map",
        question: appLocation?.state?.obj?.questionData,
        project: props?.project,
      };

      // history.push({
      //   pathname: "/rewards/earn-rewards",
      //   state: { obj },
      // });
      if (appLocation?.state?.fromQuestion == "dashboard-profile") {
        history.push({
          pathname: "/dashboard",
          state: { obj },
        });
      } else {
        history.push({
          pathname: "/rewards/earn-rewards",
          state: { obj },
        });
      }
    } else if (
      props?.from === "experience" ||
      props?.from === "companyProfile" ||
      props?.from === "manufactureProfile" ||
      props?.from === "productProfile"
    ) {
      props?.onAddToProfile(props?.project);
    } else {
      history.push(`/projects/project/${props?.project?.id}`);
    }
    setMoreMenuAnchorEl(false);
  };

  return (
    <Card className="expCardBgc">
      <ListItem
        secondaryAction={
          <>
            {props?.project?.addToProfileList?.length != 0 ? (
              <IconButton disabled>
                <img src={blueMarker} height={"25px"} />
              </IconButton>
            ) : (
              <></>
            )}
            {props?.from === "experience" ||
            props?.from == "companyProfile" ||
            props?.from == "manufactureProfile" ||
            props?.from == "productProfile" ? (
              <></>
            ) : (
              <IconButton
                onClick={(event) => {
                  setMoreMenuAnchorEl(event.currentTarget);
                }}
                sx={{ color: (theme) => theme.palette.grey[500] }}
              >
                <MoreHorizIcon />
              </IconButton>
            )}
            <Menu
              transformOrigin={{ horizontal: "right", vertical: "bottom" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              style={{ top: "12px", left: "10px" }}
              anchorEl={moreMenuAnchorEl}
              open={Boolean(moreMenuAnchorEl)}
              onClose={(event) => {
                setMoreMenuAnchorEl(false);
              }}
            >
              <MenuItem
                onClick={onAddToProfile}
                style={{ color: BIBlue }}
                dense
              >
                Add To Profile
              </MenuItem>
              <MenuItem
                onClick={() => handleReport(props?.project?.id ?? 0)}
                style={{ color: BIRed }}
                dense
              >
                Report
              </MenuItem>
            </Menu>
          </>
        }
        disablePadding
      >
        <ListItemButton onClick={onGoToProfile}>
          {/* <ListItemButton onClick={} > */}
          <Avatar
            variant="square"
            style={{
              marginRight: "12px",
              cursor: "pointer",
              width: "75px",
              height: "55px",
              borderRadius: "5px",
            }}
            src={
              props?.project?.pictureList?.length > 0
                ? props?.project?.pictureList?.[0]?.thumbUrl
                : placeholder
            }
          ></Avatar>
          <ListItemText
            primary={props?.project?.projectName}
            secondary={
              <>
                {props?.project?.isActive ? (
                  <span> {<Chip label="Active" />} </span>
                ) : null}
                {getDate()}
              </>
            }
          />
        </ListItemButton>
      </ListItem>

      <ReportModal
        name={props?.project?.projectName ?? ""}
        isOpen={reportModalOpenSearch}
        onClose={() => {
          setReportModalOpenSearch(false);
        }}
        recordId={props?.project?.id}
      />
    </Card>
  );
};

export default ProjectList;
