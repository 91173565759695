import { restGet, restPost } from "./RestClient";
import { headers, form_headers } from "./UserApi";

export async function fetchClientPicturesBoard2(args) {
  const res = await restPost(
    "Client/FetchClientPicturesBoard2/",
    args,
    headers
  );
  return res;
}

export async function fetchClientPicturesBoard(args) {
  const res = await restPost("Client/FetchClientPicturesBoard/", args, headers);
  return res;
}

export async function fetchClientBoardAlbums(args) {
  const res = await restPost("Client/FetchClientBoardAlbums2/", args, headers);
  return res;
}

export async function saveClientPicturesBoard(args) {
  const res = await restPost("Client/SaveClientPicturesBoard/", args, headers);
  return res;
}

export async function deleteClientPicturesBoard(args) {
  const res = await restPost(
    "Client/DeleteClientPicturesBoard/",
    args,
    headers
  );
  return res;
}

export async function fetchClientEmployeeLicenses(args) {
  const res = await restPost(
    "Client/FetchClientEmployeeLicenses2",
    args,
    headers
  );
  return res;
}

export async function updateEmployeeLicensesIsPinFlag(args) {
  const res = await restPost(
    "Client/UpdateEmployeeLicensesIsPinFlag",
    args,
    headers
  );
  return res;
}

export async function getClientApprovedPictureBoard(clientId) {
  const res = await restGet(
    "Client/GetClientApprovedPictureBoard/" + clientId,
    headers
  );
  return res;
}

export async function fetchClientexpenses(args) {
  try {
    const res = await restPost("Client/FetchClientExpenses/", args, headers);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function fetchClientexpensescodes(args) {
  try {
    const res = await restPost("Client/FetchClientExpenseCodes", args, headers);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function saveClientexpenses(args) {
  try {
    const res = await restPost("Client/SaveClientExpenses", args, headers);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function fetchClientjobs(args) {
  try {
    const res = await restPost("Client/FetchClientJobs", args, headers);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function saveExpensesReceipt(args) {
  try {
    const res = await restPost(
      "Client/SaveClientExpenseReceipt",
      args,
      form_headers
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function fetchClientexpensesReceipts(args) {
  try {
    const res = await restPost(
      "Client/FetchClientExpensesReceipts",
      args,
      headers
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function deleteClientExpenses(args) {
  try {
    const res = await restPost("Client/DeleteClientExpenses", args, headers);
    return res;
  } catch (error) {
    throw error;
  }
}

//Client Safety Observation's
export async function fetchClientSafetyObservations(args) {
  const res = await restPost(
    "Client/FetchClientSafetyObservations2/",
    args,
    headers
  );
  return res;
}

export async function fetchClientJobs(args) {
  const res = await restPost("Client/FetchClientJobs/", args, headers);
  return res;
}

export async function saveClientSafetyObservations(args) {
  const res = await restPost(
    "Client/SaveClientSafetyObservations/",
    args,
    headers
  );
  return res;
}

export async function saveClientSafetyObservationPictures(args) {
  const res = await restPost(
    "Client/SaveClientSafetyObservationsPictures/",
    args,
    form_headers
  );
  return res;
}

export async function deleteClientSafetyObservationsPictures(args) {
  const res = await restPost(
    "Client/DeleteClientSafetyObservationsPictures",
    args,
    headers
  );
  return res;
}

export async function fetchClientEmployees(args) {
  const res = await restPost("Client/FetchClientEmployees", args, headers);
  return res;
}

export async function getClientUDFieldsbyClientId({
  clientId,
  appId,
  entityRecordId,
}) {
  const res = await restGet(
    `Client/GetClientUDFieldsbyClientId/${clientId}/${appId}/${entityRecordId}`,
    headers
  );
  return res;
}

// Client Coaching

export async function fetchClientCoachings(args) {
  const res = await restPost("Client/FetchClientCoachings", args, headers);
  return res;
}

export async function fetchClientEmployee(args) {
  const res = await restPost("Client/FetchClientEmployees2", args, headers);
  return res;
}

export async function updateClientEmployeeInfo(args) {
  const res = await restPost("Client/UpdateClientEmployeesInfo", args, headers);
  return res;
}

export async function saveClientCoachings(args) {
  const res = await restPost("Client/SaveClientCoachings", args, headers);
  return res;
}

// Client Safety Incidents

export async function fetchClientSafetyIncidents(args) {
  const res = await restPost(
    "Client/FetchClientSafetyIncidents",
    args,
    headers
  );
  return res;
}

export async function saveClientSafetyIncidents(args) {
  const res = await restPost("Client/SaveClientSafetyIncidents", args, headers);
  return res;
}

// client Tracker

export async function fetchClientTracker2(args) {
  const res = await restPost(
    "Client/FetchClientChangeTrackers2",
    args,
    headers
  );
  return res;
}

export async function fetchClientEmployeesJobs(args) {
  const res = await restPost("Client/FetchClientEmployeesJobs2", args, headers);
  return res;
}

export async function saveClientTracker(args) {
  const res = await restPost("Client/SaveClientChangeTrackers", args, headers);
  return res;
}

export async function saveClientTrackerPicture(args) {
  const res = await restPost(
    "Client/SaveClientChangeTrackersPictures",
    args,
    headers
  );
  return res;
}

// Client Site Estimation

export async function saveClientSiteEstimationsEntries(args) {
  const res = await restPost(
    "Client/SaveClientSiteEstimationsEntries",
    args,
    headers
  );
  return res;
}
export async function saveClientSiteEstimations(args) {
  const res = await restPost("Client/SaveClientSiteEstimations", args, headers);
  return res;
}
export async function fetchClientSiteEstimations(args) {
  const res = await restPost(
    "Client/FetchClientSiteEstimations",
    args,
    headers
  );
  return res;
}
