import React, { useState, useRef } from 'react'
import {
    Button, IconButton, Typography, TextField, InputAdornment, CircularProgress
} from "@mui/material";
import { BIBlue, BIGrey } from '../../assets/buildidColors'
import { useEffect } from 'react';
import { getAddress } from '../../api/SitesApi';
import { Modal } from '@material-ui/core';
import { Close as CloseIcon, Search as SearchIcon } from "@material-ui/icons";
import { GoogleMap, Marker } from '@react-google-maps/api';
import mapMarkerYellow from "../../assets/images/map-marker-yellow.png";
import NearMeIcon from '@mui/icons-material/NearMe';
import { Autocomplete as GooglePlacesAutocomplete } from "@react-google-maps/api";


const ChooseLocation = (props) => {

    const [map, setMap] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [autocomplete, setAutocomplete] = useState(null);	// Google Places Autocomplete API instance
    const [center, setCenter] = useState(null);
    const [deviceLocation, setDeviceLoaction] = useState({});
    const [showUsingCurrentLocation, setshowUsingCurrentLocation] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const mapRef = useRef(null);
    const containerStyle = {
        width: '100%',
        height: '550px',
        // borderRadius: "10px",
    };

    useEffect(() => {
        if (typeof map !== "undefined") {
            getCurrentLocation();
        }
    }, [map])


    const onLoadAuto = (newInstance) => {
        // Access the suggestion list container and add a custom class
        setAutocomplete(newInstance);
    }


    const currentLocation = async (position) => {
        var lat = position?.coords?.latitude;
        var lng = position?.coords?.longitude;
        setDeviceLoaction({
            lat: Number(lat),
            lng: Number(lng)
        })
        props.setCurrentLocationLatLong({
            lat: Number(lat),
            lng: Number(lng)
        })
        setShowLoader(false);
    };

    const showError = (error) => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                getCurrentLocation();
                break;
            case error.POSITION_UNAVAILABLE:
                break;
            case error.TIMEOUT:
                break;
            case error.UNKNOWN_ERROR:
                break;
            default:
                break;
        }
    };

    const getCurrentLocation = () => {
        // props.setLocationButtonText("Using Current Location");
        try {
            if (navigator.geolocation) {
                setShowLoader(true);
                navigator.geolocation.getCurrentPosition(currentLocation, showError);
            } else {
                alert("Geolocation is not supported by this browser.");
            }
        } catch (err) {
            setShowLoader(false);
            console.log("Error while getting current location :: ", err)
        }

    };


    const handleMapDrag = () => {
        if (mapRef?.current) {
            const center = mapRef.current.getCenter(); // Get the center of the map
            const lat = center.lat();
            const lng = center.lng();
            props.setLocation({
                lat: Number(lat),
                lng: Number(lng)
            })
            props.setIsLocationChanged(true);
            setshowUsingCurrentLocation(false);
            // setIsBuySellCurrentLocation(false);
            // localStorage.setItem("isBuySellCurrentLocation", false)
        }
    };

    const selectLocationcenter = (e) => {
        props.setLocation({
            lat: Number(e.latLng.lat()),
            lng: Number(e.latLng.lng())
        })
        props.setIsLocationChanged(true);
    }

    const mapLoad = (map) => {
        mapRef.current = map
        setMap(map)
    }


    async function handleChooseLocationClick() {
        await props.setIsLocationChanged(true);
        let lat_;
        let lng_;
        if (!localStorage?.latForBuySell) {
            lat_ = deviceLocation?.lat;
            lng_ = deviceLocation?.lng;
            props.setLocation({
                lat: Number(lat_),
                lng: Number(lng_)
            })
        } else {
            lat_ = props.location?.lat;
            lng_ = props.location?.lng;
        }
        localStorage.setItem("latForBuySell", lat_);
        localStorage.setItem("lngForBuySell", lng_);
        localStorage.setItem("isLocationChangedForBuySell", true);
        // localStorage.setItem("isBuySellCurrentLocation", isBuySellCurrentLocation)
        const address = await getAddress({ latitude: lat_, longitude: lng_ });
        localStorage.setItem("postalCodeForBuySell", address?.objAddress?.postalCode ?? "");
        props.setLocationButtonText(address?.objAddress?.postalCode ?? " ", true);
        setSearchTerm("");
        setshowUsingCurrentLocation(false);
        setShowLoader(false);
        props.setOpenChooseLocationModal(false);
    }




    const handleSearch = async () => {
        let place = autocomplete.getPlace();
        if (place?.geometry) {
            setSearchTerm(place.formatted_address);
            props.setLocation({
                lat: Number(place.geometry.location.lat()),
                lng: Number(place.geometry.location.lng())
            })
            props?.setIsLocationChanged(true)
        }
    }


    // const getCurrentLocationForUseCurrentLocationClick = (position) => {
    //     console.log(4)
    //     var lat = position?.coords?.latitude;
    //     var lng = position?.coords?.longitude;
    //     props.setLocation({
    //         lat: Number(lat),
    //         lng: Number(lng)
    //     })
    //     // setIsBuySellCurrentLocation(true);
    //     // localStorage.setItem("isBuySellCurrentLocation", true)
    // }


    // const useCurrentLocationClick = async () => {
    //     try {
    //         if (navigator.geolocation) {
    //             setShowLoader(true);
    //             console.log(1)
    //             const getCurrentLocationDetails = () => {
    //                 console.log(3)
    //                 navigator.geolocation.getCurrentPosition(getCurrentLocationForUseCurrentLocationClick, showError);
    //                 mapRef.current.setZoom(13); //13
    //             }
    //             console.log(2)
    //             await getCurrentLocationDetails();
    //             setshowUsingCurrentLocation(true);
    //             console.log(5)
    //             setShowLoader(false);
    //         } else {
    //             setshowUsingCurrentLocation(false);
    //             setShowLoader(false);
    //             alert("Geolocation is not supported by this browser.");
    //         }
    //     } catch (err) {
    //         setshowUsingCurrentLocation(false);
    //         setShowLoader(false);
    //         console.log("Error useCurrentLocationClick ::", err)
    //     }
    // }



    const getCurrentLocationForUseCurrentLocationClick = (position) => {
        return new Promise((resolve) => {
            var lat = position?.coords?.latitude;
            var lng = position?.coords?.longitude;
            props.setLocation({
                lat: Number(lat),
                lng: Number(lng)
            });
            resolve();
        });
    };

    const useCurrentLocationClick = async () => {
        try {
            if (navigator.geolocation) {
                setShowLoader(true);
                const getCurrentLocationDetails = async () => {
                    await new Promise((resolve) => {
                        navigator.geolocation.getCurrentPosition(async (position) => {
                            await getCurrentLocationForUseCurrentLocationClick(position);
                            mapRef.current.setZoom(13); //13
                            resolve();
                        }, showError);
                    });
                };
                await getCurrentLocationDetails();
                setshowUsingCurrentLocation(true);
                setShowLoader(false);
            } else {
                setshowUsingCurrentLocation(false);
                setShowLoader(false);
                alert("Geolocation is not supported by this browser.");
            }
        } catch (err) {
            setshowUsingCurrentLocation(false);
            setShowLoader(false);
            console.log("Error useCurrentLocationClick ::", err);
        }
    };


    const handleBoundsChanged = () => {
        const mapCenter = mapRef?.current.getCenter(); //get map center
        setCenter(mapCenter);
        setshowUsingCurrentLocation(false);
    };


    const threshold = 0.0001;
    const getText = () => {
        if (center) {
            const lat = center.lat();
            const lng = center.lng();
            // if (deviceLocation?.lat == lat && lng == deviceLocation?.lng) {
            //     return true
            // } else {
            //     return false
            // }
            const latitudeDiff = Math.abs(deviceLocation.lat - lat);
            const longitudeDiff = Math.abs(deviceLocation.lng - lng);
            if (latitudeDiff <= threshold && longitudeDiff <= threshold) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }


    const onModalClose = () => {
        props.setLocationButtonText(localStorage?.postalCodeForBuySell ?? "Using Current Location", false);
        props.setLocation({
            lat: localStorage?.latForBuySell ? localStorage?.latForBuySell : 51.044270,
            lng: localStorage?.lngForBuySell ? localStorage?.lngForBuySell : -114.062019,
        })
        props.setIsLocationChanged(localStorage?.isLocationChangedForBuySell ? true : false);
        setshowUsingCurrentLocation(false);
        setShowLoader(false);

    }

    return (
        <>
            <Modal className="choose-opt choose-location" open={props.open} onClose={() => {
                onModalClose()
                props.setOpenChooseLocationModal(false)
                setSearchTerm("");
                setshowUsingCurrentLocation(false);
                setShowLoader(false);
            }}>
                <div className="modal-center">
                    <div className="modal-card chooseopt-card location-card" style={{ height: '600px' }}>
                        <div className="header-section">
                            <Typography className="addsite-title" style={{ fontSize: "16px" }} variant="h6" component="h2">
                                Choose Location
                            </Typography>
                            <IconButton style={{ color: BIBlue }} onClick={() => {
                                onModalClose();
                                props.setOpenChooseLocationModal(false)
                                setSearchTerm("");
                                setshowUsingCurrentLocation(false);
                                setShowLoader(false);
                            }}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <GoogleMap
                            center={props?.coordinates}
                            zoom={13}
                            mapContainerStyle={containerStyle}
                            onDragEnd={handleMapDrag}
                            onClick={(e) => selectLocationcenter(e)}
                            onLoad={mapLoad}
                            ref={mapRef} // Assign the reference to the map
                            options={{
                                draggableCursor: "default",
                                disableDefaultUI: true,
                                fullscreenControl: false
                            }}
                            onBoundsChanged={handleBoundsChanged}
                        >
                            <div style={{ backgroundColor: 'white', margin: '10px', position: 'relative', zIndex: '9999', width: '480px', borderRadius: '5px' }}>
                                <GooglePlacesAutocomplete
                                    onLoad={newInstance => onLoadAuto(newInstance)}	// Sets instance of Autocomplete to be referenced
                                    onPlaceChanged={handleSearch}
                                    options={{
                                        componentRestrictions: { country: ['US', 'CA', 'GB'] }, // Restrict to USA, Canada, and UK
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        placeholder="Search map"
                                        fullWidth
                                        value={searchTerm}
                                        onChange={(event) => setSearchTerm(event.target.value)}
                                        InputProps={{
                                            endAdornment: <div>
                                                <InputAdornment position="start">
                                                    {
                                                        (searchTerm !== "")
                                                            ? (
                                                                <IconButton
                                                                    onClick={() => setSearchTerm("")}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            )
                                                            : <></>
                                                    }
                                                </InputAdornment>
                                            </div>,
                                            startAdornment: <SearchIcon style={{ color: BIGrey, marginRight: '10px' }} />
                                        }} />
                                </GooglePlacesAutocomplete>
                            </div>

                            <Marker
                                // position={props?.coordinates}
                                position={center}
                                icon={{
                                    url: mapMarkerYellow,
                                    scaledSize: (new window.google.maps.Size(30, 40))
                                }} />
                            <div className="chooseloc-footer">
                                {
                                    showLoader ? (
                                        <>
                                            <CircularProgress size={24} />
                                        </>
                                    )
                                        :
                                        (
                                            <>
                                                {
                                                    getText() || showUsingCurrentLocation ?
                                                        (
                                                            <p className="currentloc">
                                                                <span style={{ fontSize: "14px", color: BIGrey }}>
                                                                    Using current location
                                                                </span>
                                                            </p>
                                                        )
                                                        :
                                                        (
                                                            <p className="currentloc" onClick={useCurrentLocationClick}><NearMeIcon style={{ color: "#083DB8" }} />
                                                                <span style={{ fontSize: "14px" }}>
                                                                    Use current location
                                                                </span>
                                                            </p>
                                                        )
                                                }

                                                <Button className="chooseloc-btn" variant="contained" fullWidth onClick={handleChooseLocationClick}>
                                                    Done
                                                </Button>
                                            </>
                                        )
                                }

                            </div>
                        </GoogleMap>
                    </div>
                </div >
            </Modal >
        </>
    )
}

export default ChooseLocation;