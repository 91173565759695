import React, { useEffect } from "react";
import { IconButton, MenuItem, Modal, Avatar } from "@material-ui/core";
import ListItemText from '@mui/material/ListItemText';
import { Close as CloseIcon } from "@material-ui/icons";
import "../../assets/css/Timesheets.css";
import { Stack, Typography } from "@mui/material";
import defaultProjectImage from "../../assets/images/project-profile-placeholder.jpg"
import { BIGrey } from "../../assets/buildidColors";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

function JoblistModal(props) {

    useEffect(() => {
        console.log("props?.options", props?.options)
    }, [])

    const handleMenuItemClick = (item) => {
        const newEntry = {
            ...item,
            labelIndex: 1,
            pictureList: [],
            hours: 0,
            distance: 0,
            comments: '',
            isNew: true,
            isDeleted: false,
            unitOfDistance: 'km',
            key: props?.entriesDataLength,
            isJobItemExpanded: false,
            isJobItemAddGoalClicked: false,
            timesheetGoalsArray: [],
            isError: false,
        }
        props?.addNewSite(newEntry);
    }

    const onClose = () => {
        props?.onClose()
    }

    return (
        <Modal
            open={props.open}
            className="add-job-modal"
        >
            <div className="modal-center timesheet-modal">
                <div className="modal-card" style={{margin:"10px"}}>
                    <div className="modal-card-header-buttons">
                        <IconButton
                            color="primary"
                            onClick={onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className="job-list" style={{ height: props?.options?.length === 0 && "auto" }}>
                        {props?.options.map((item, index) => {
                            return (
                                <MenuItem key={item?.id} value={item?.siteName} onClick={() => handleMenuItemClick(item, index)} style={{ padding: '10px' }}>
                                    <ListItemText
                                        primary={(
                                            <>
                                                <Stack>
                                                    <Typography style={{ color: BIGrey,marginBottom:"10px" }}>
                                                        {item?.companyName}
                                                    </Typography>
                                                    <Stack direction={'row'} gap={2}>
                                                        <Avatar
                                                            variant="rounded"
                                                            style={{ width: '52px', height: '52px',border:"1px solid #8d8d8d" }}
                                                            src={item?.mainPictureUrl && item?.mainPictureUrl !== '' ? item?.mainPictureUrl : defaultProjectImage}>
                                                        </Avatar>
                                                        <Stack>
                                                            <Typography>
                                                                {item?.projectName}
                                                            </Typography>
                                                            <Typography style={{color:"#8d8d8d"}}>
                                                                {item?.siteName}
                                                            </Typography>
                                                            <Typography className="project-address" style={{ color: BIGrey }}>
                                                                {item?.siteAddress.trim() + ", "}{item?.siteCity.trim() + ", "}{item?.siteProvince.trim() + ", "}{item?.sitePostalCode.trim() + ", "}{item?.siteCountry}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </>
                                        )}
                                    />
                                </MenuItem>
                            )
                        })}
                        {/* new code sanket 21-03-2024 */}
                        {props?.options.length === 0 && (
                                <div className="add-job-modal-body">
                                    <BusinessCenterIcon
                                        className="add-job-modal-body-icon"
                                    />
                                    <p style={{ color: 'gray', textAlign: 'center', }}>
                                        No jobs found
                                    </p>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default JoblistModal;


