import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AlertModal from "../../components/AlertModal";
import ContactUsModal from "./ContactUsModal";
import ListItemIcon from '@mui/material/ListItemIcon';

function HelpAndSupport() {
	const [alertModalOpen, setAlertModalOpen] = useState(false);
	const [guideAlertModalOpen, setGuideAlertModalOpen] = useState(false);
	const [contactUsModalOpen, setContactUsModalOpen] = useState(false);

	return (
		<>
			<AlertModal
				isOpen={alertModalOpen}
				onClose={() => setAlertModalOpen(false)}
				title="Subscription Page"
				body="Coming soon!" />

			<AlertModal
				isOpen={guideAlertModalOpen}
				onClose={() => setGuideAlertModalOpen(false)}
				title="Quick Start Guide Page"
				body="Coming soon!" />

			<ContactUsModal
				isOpen={contactUsModalOpen}
				onClose={() => setContactUsModalOpen(false)} />

			<div className="page">
				<div className="header">
					<div className="page-content">
						<h1>Help & Support</h1>
					</div>
				</div>

				<div className="page-content">
					<div className="flex-container-vertical" style={{ padding: "25px 0px", justifyContent: "space-between", alignItems: "center" }}>
						<div className="flex-container-vertical" component={Paper} style={{ width: '100%' }}>

							<List
								sx={{ width: '100%', maxWidth: 1960, bgcolor: 'background.paper' }}
								component="nav"
							>
								<ListItemButton onClick={() => setGuideAlertModalOpen(true)}>
									<ListItemText primary="Quick Start Guide" />
									<ListItemIcon>
										<ChevronRightIcon />
									</ListItemIcon>
								</ListItemButton>

								{/* <ListItemButton href='https://mybuilderid.com/faq.html' target='_blank'>
									<ListItemText primary="FAQs" />
									<ListItemIcon>
										<ChevronRightIcon />
									</ListItemIcon>
								</ListItemButton> */}

								<ListItemButton href='https://mybuilderid.com/buildid-updates.html' target='_blank'>
									<ListItemText primary="What’s New" />
									<ListItemIcon>
										<ChevronRightIcon />
									</ListItemIcon>
								</ListItemButton>

								<ListItemButton onClick={() => setContactUsModalOpen(true)}>
									<ListItemText primary="Contact Us" />
									<ListItemIcon>
										<ChevronRightIcon />
									</ListItemIcon>
								</ListItemButton>
							</List>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default HelpAndSupport;
