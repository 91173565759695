import { saveUserUsages } from "../../api/UserApi";

const UserUsages = async (props) => {
    try {
        const obj = {
            ReturnRecordId: false,
            ReturnRecordError: true,
            UserId: Number(localStorage.userId),
            SaveList: [
                {
                    Id: 0,
                    ModuleName: props,
                    UserId: Number(localStorage.userId),
                },
            ],
        }
        const res = await saveUserUsages(obj);
    } catch (err) {
        console.log("Error while fetching user work history details :: ", err)
    }
}

export default UserUsages