import React, { useState, useEffect } from "react";
import "../../assets/css/Timesheets.css";
import moment from "moment/moment";
import { Stack, Card, ListItem, ListItemButton, Typography, CardContent, CircularProgress } from "@mui/material";
import { Edit as EditIcon } from "@material-ui/icons";
import { IconButton, Button, ListItemText } from "@material-ui/core";
import { fetchUserCompanies, fetchUserTrades, fetchUserWorkHistory } from "../../api/UserApi";
import AddSiteModal from "../../pages/profile/sections/AddSiteModal";
import ProjectForm from "../../pages/profile/sections/ProjectForm";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import DescriptionIcon from '@mui/icons-material/Description';
import Placeholder from "../Placeholder";


export default function ProjectListSection(props) {

    const [pageNr, setPageNr] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [projectData, setProjectData] = useState([]);
    const [projectSectionModalOpen, setProjectSectionModalOpen] = useState(false);
    const [editProjectDetails, setEditProjectDetails] = useState({})
    const [allCompanies, setAllCompanies] = useState([]);

    useEffect(() => {
        getCompanyList();
        return () => {
            setPageNr(1);
            setIsLoading(false);
            setProjectData([]);
            setProjectSectionModalOpen(false);
            setEditProjectDetails(false);
            setAllCompanies([]);
        }
    }, []);



    const getCompanyList = async () => {
        try {
            const userCompanyObj = {
                PageNr: 1,
                FullSearch: "",
                IncludeRecordNr: false,
                NrOfRecPerPage: 0,
                SearchList: [{ UserId: Number(localStorage.userId) }]
            }
            const userCompanies = await fetchUserCompanies(userCompanyObj);
            setAllCompanies(userCompanies)
        } catch (err) {
            console.log("fetchUserCompanies Error-", err)
        }
    }

    const increaseOffset = () => { setPageNr(pageNr + 1); }

    async function getWorkHistory() {
        try {
            setIsLoading(true);
            const obj = {
                PageNr: 1,
                FullSearch: "",
                IncludeRecordNr: "true",
                SearchList: [{
                    SiteId: Number(props?.siteId),
                    CreatedBy: Number(localStorage.userId),
                }]
            }
            const userWorkHistoryres = await fetchUserWorkHistory(obj);
            setProjectData(userWorkHistoryres)
            await increaseOffset();
            setIsLoading(false);
        } catch (Error) {
            console.log("GET_WORK_HISTORY[ERROR]::", Error)
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getWorkHistory();
    }, [props.isUpdated])

    const onAddBtnPressed = async () => {
        await setEditProjectDetails({})
        await setProjectSectionModalOpen(true);
    }

    const onEditPressed = async (data) => {
        await setEditProjectDetails(data)
        await setProjectSectionModalOpen(true)
    }

    const onProjectSave = async () => {
        setEditProjectDetails({})
        setProjectSectionModalOpen(false)
        await getWorkHistory()
    }

    return (
        <>
            {
                isLoading ?
                    (
                        <div style={{ textAlign: "center", marginTop: '10%' }}>
                            <CircularProgress />
                        </div>
                    ) :
                    (
                        <>
                            {
                                projectData.length != 0 ?
                                    (
                                        <>
                                            <Card>
                                                <CardContent>
                                                    <div className="flex-container-horizontal" style={{ justifyContent: "flex-end" }}>
                                                        <Button
                                                            variant="text"
                                                            color="primary"
                                                            size="small"
                                                            onClick={() => {
                                                                onAddBtnPressed();
                                                            }}>
                                                            Add Project
                                                        </Button>
                                                    </div>
                                                </CardContent>
                                                <Stack spacing={0}>

                                                    {
                                                        projectData
                                                            .sort((a, b) => {
                                                                let dateA = new Date(a?.startDate), dateB = new Date(b?.startDate);
                                                                return dateB - dateA
                                                            })
                                                            .map((item, index) => {
                                                                return (
                                                                    <div key={index.toString()}>
                                                                        <ListItem key={index.toString()}
                                                                            secondaryAction={
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <IconButton color="primary" onClick={() => onEditPressed(item)}>
                                                                                        <EditIcon />
                                                                                    </IconButton>
                                                                                </div>
                                                                            }
                                                                            disablePadding
                                                                        >
                                                                            <ListItemButton sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                                <ListItemText
                                                                                    primary={item.projectName ?? " "}
                                                                                    secondary={
                                                                                        <>
                                                                                            <Typography sx={{ display: 'inline' }} component="span">
                                                                                                {/* {item.startDate ? moment(item.startDate).format("MMMM DD, YYYY") : null} - {item.endDate ? moment(item.endDate).format("MMMM DD, YYYY") : null} */}
                                                                                                {/* {moment(item.startDate).format('MMM YYYY') + ' - ' + (item.present ? 'Present' : item.endDate != null ? moment(item.endDate).format('MMM YYYY') : '')} */}
                                                                                                {
                                                                                                    (item.startDate !== null && item.startDate !== "")
                                                                                                        ? (
                                                                                                            <>{moment(item.startDate).format('MMM YYYY')} - {
                                                                                                                // If endDate is null, then it's to "Present"
                                                                                                                item.present === false ? item.endDate == null || item.endDate == "Invalid date" ? null : moment(item.endDate).format('MMM YYYY') : "Present"
                                                                                                            }
                                                                                                            </>
                                                                                                        )
                                                                                                        : null
                                                                                                }
                                                                                            </Typography>
                                                                                            {<br />}
                                                                                            {item.description}
                                                                                        </>
                                                                                    }
                                                                                />
                                                                            </ListItemButton>
                                                                        </ListItem>
                                                                    </div>
                                                                )
                                                            })
                                                    }

                                                </Stack>
                                            </Card>
                                        </>
                                    ) : (
                                        <div>
                                            {
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Button
                                                        variant="text"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => setProjectSectionModalOpen(true)}
                                                    >
                                                        Add Project
                                                    </Button>
                                                </div>
                                            }

                                            <Placeholder
                                                icon={<DescriptionIcon />}
                                                text={"No Personal Projects"}
                                                text2={"Note: Added Projects Are Only Visible To You "}
                                            />
                                        </div>
                                    )
                            }
                        </>
                    )
            }



            <AddSiteModal
                // setAddSiteModalOpen={() => {
                //     setEditProjectDetails({})
                //     setProjectSectionModalOpen(false)
                // }}
                addSiteModalOpen={projectSectionModalOpen}
                resetSelected={() => {
                    setEditProjectDetails({})
                    setProjectSectionModalOpen(false)
                }}
                projectForm={
                    Object.keys(editProjectDetails).length === 0 ?
                        <ProjectForm
                            siteName={props?.siteInfo?.name}
                            siteAddress={props?.siteInfo?.address}
                            siteId={props?.siteInfo?.id}
                            tradeId={props?.userTrade?.tradeId ?? 0}
                            tradeName={props?.userTrade?.tradeName ?? ""}
                            isSiteNewForm={true}
                            onReturnClick={() => {
                                setEditProjectDetails({})
                                setProjectSectionModalOpen(false)
                            }}
                            onSaveFunction={true}
                            onProjectSave={onProjectSave}
                            siteProfileInFollow={true}
                        />
                        :
                        <ProjectForm
                            isExperienceCard={true}
                            project={editProjectDetails}
                            allCompanies={allCompanies}
                            onReturnClick={() => {
                                setEditProjectDetails({})
                                setProjectSectionModalOpen(false)
                            }}
                            onSaveFunction={true}
                            onProjectSave={onProjectSave}
                        />

                }
            />
        </>
    )
}