function ChangeLog(){
	return(
		<div className="page">
			<div className="header">
				<div className="page-content">
					<h1>Changelog</h1>
				</div>
			</div>

			<div className="page-content">
				{/*
					Template for new release
					
					<Release
						version=""
						date=""
						body={
							<div className="changelog-body">
								<p><b>Page (Beta)</b></p>
								<ul>
									<li>
										<DevelopmentPoint
											link=""
											pointNumber=""
											content={[
												""
											]}/>
									</li>
								</ul>
							</div>
						}/>
				*/}
				<Release
					version="1.10.0"
					date="Thursday, May 26, 2022"
					body={
						<div className="changelog-body">
							<p><b>General</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1657&rl=bbrb&noPrevNext=true"
										pointNumber="BDW 58-1657"
										content={[
											"Added bearer tokens for API authorization"
										]}/>
								</li>
							</ul>
							
							<p><b>Profile (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=bgh&rl=eh6q"
										pointNumber="BDW 222-1223"
										content={[
											"Fixed bug where companies without sites and sites without companies were not being displayed"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1337&rl=cpz3&noPrevNext=true"
										pointNumber="BDW 222-1337"
										content={[
											"Added visual indicator for UserSites whose associated Site has no address"
										]}/>
								</li>
							</ul>
							
							<p><b>Dashboard (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1305&rl=d32k"
										pointNumber="BDW 266-1305"
										content={[
											"Dashboard data now being populated from API",
											"Years in Summary now calculated correctly"
										]}/>
								</li>
							</ul>
							
							<p><b>Rewards (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=er&rid=1306&rl=d33q"
										pointNumber="BDW 225-1306"
										content={[
											"Unique URLs for Rewards pages"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1458&rl=cmx7"
										pointNumber="BDW 225-1458"
										content={[
											'New "How to Earn BuilderBucks" help modals'
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=bpv&rl=chc9"
										pointNumber="BDW 225-1459"
										content={[
											'"Earnings" tab now correctly shows all net positive BuilderBucks items'
										]}/>
								</li>
							</ul>
							
							<p><b>Profile Search (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=er&r=bi6&rl=cnp6"
										pointNumber="BDW 263-1308"
										content={[
											"Non-manufacturer Profiles now display Sites they have been involved in"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=brk&rl=cg95"
										pointNumber="BDW 263-1514"
										content={[
											"Preload search results on page visit and tab change"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=bi5&rl=chba"
										pointNumber="BDW 263-1307"
										content={[
											"Only show non-empty fiellds for company/manufacturer profiles"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=bqf&rl=chic"
										pointNumber="BDW 263-1477"
										content={[
											"X button to clear search term",
											'Pressing "Enter" in the search field will search for results'
										]}/>
								</li>
							</ul>

							<p><b>Notifications (Beta, New)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=er&rid=1455&rl=fe9p"
										pointNumber="BDW 266-1455"
										content={[
											"Moved Notifications from Dashboard into separate page"
										]}/>
								</li>
							</ul>
							
							<p><b>Tickets (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=bpt&rl=ck2n"
										pointNumber="BDW 224-1457"
										content={[
											"Fixed width for TicketCards"
										]}/>
								</li>
							</ul>
						</div>
					}/>
				
				<Release
					version="1.9.0"
					date="Friday, January 28, 2022"
					body={
						<div className="changelog-body">
							<p><b>Login (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1126&rl=bern"
										pointNumber="BDW 222-1126"
										content={[
											"Email verification on create account"
										]}/>
								</li>
							</ul>
							
							<p><b>Profile Search (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1140&rl=chma"
										pointNumber="BDW 263-1140"
										content={[
											"Fixed bug with products not being displayed on a manufacturer's profile"
										]}/>
								</li>
							</ul>
							
							<p><b>Dashboard (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=bd7&rl=d2vp"
										pointNumber="BDW 262-1149"
										content={[
											"Icons for all notifications",
											"Different styling for system-generated messages (blue background with white text)",
											"System-generated messages appear before all other notifications",
											'System-generated messages are non-dismissable, except for "Shared Tickets"'
										]}/>
								</li>
							</ul>
							
							<p><b>Sites (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1213&rl=bpjc"
										pointNumber="BDW 226-1213"
										content={[
											"New option in the site details page to add role to a site company without one"
										]}/>
								</li>
							</ul>
							
							<p><b>Rewards (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1214&rl=bpjg"
										pointNumber="BDW 225-1214"
										content={[
											"New Earn BuilderBucks page for quiz questions"
										]}/>
								</li>
							</ul>
						</div>
					}/>
				
				<Release
					version="1.8.0"
					date="Friday, December 17, 2021"
					body={
						<div className="changelog-body">
							<p><b>Rewards (Beta)</b></p>
							<p>Experience (ie. work history) update for Profile and Sites pages, minor fixes to Profile &gt; Trades and Rewards</p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=bcw&rl=bcva"
										pointNumber="BDW 225-1108"
										content={[
											'Removed "Current Entries" text from store items'
										]}/>
								</li>
							</ul>
							
							<p><b>Profile (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=bcv&rl=bqv3"
										pointNumber="BDW 222-1107"
										content={[
											'Single "Other" heading for one or more trades with type "other"',
											'Main trade is shown at the top of the list (not including default trade)',
											'Default trade name changed to "BuildID Recruit"',
											'Updated TradeForm to always show as a popup for consistency, dynamic form title and delete button (only shown for editing, not adding new).',
											'There is a known bug with deleting trades, which is an error with the API. Web app is low priority at this time, will be addressed after mobile MVP release - see code comments for more details.'
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=bb9&rl=bhdz"
										pointNumber="BDW 222-1087"
										content={[
											"Updated Experience section with new structure (company items, site subitems) viewing and forms"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1116&rl=ehyf"
										pointNumber="BDW 222-1116"
										content={[
											"Added popup if adding a site whose timeline is out of bounds of parent company, and option to update the company with the new child's timeline"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=er&rid=1128&rl=eh2e"
										pointNumber="BDW 222-1128"
										content={[
											"Fixed Summary Years calculation"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1129&rl=fr8j"
										pointNumber="BDW 222-1129"
										content={[
											"Added separate section at bottom of Experience card showing sites associated with Unknown Company"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=bdh&rl=fsaa"
										pointNumber="BDW 222-1127"
										content={[
											"Allowed role changes for Sites without a role (once it is assigned a role, it cannot change)"
										]}/>
								</li>
							</ul>
							
							<p><b>Sites (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=bcx&rl=bf48"
										pointNumber="BDW 226-1109"
										content={[
											"Unique UI and form for Experience items, as opposed to reusing the one in Profile"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1115&rl=efsv"
										pointNumber="BDW 226-1115"
										content={[
											"When saving new sites with a company not already associated with the user's profile, adds the company first then adds the site under it",
											"When saving new sites, checks if the site timeframe is within bounds of the parent UserCompany's timeframe - if not, displays a popup with an option to update the company's timeframe with the new child site"
										]}/>
								</li>
							</ul>
						</div>
					}/>
				
				<Release
					version="1.7.0"
					date="Friday, December 3, 2021"
					body={
						<div className="changelog-body">
							<p>Rewards Beta Release</p>
							
							<p><b>Rewards (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1053&rl=r5z"
										pointNumber="BDW 225-1053"
										content={[
											"Rewards Store",
											"Account Activity"
										]}/>
								</li>
							</ul>

							<p><b>Profile (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1075&rl=cfuc"
										pointNumber="BDW 222-1075"
										content={[
											"New Trades section to view, edit, delete, and add new Trades."
										]}/>
								</li>
							</ul>
							
							<p><b>Profile Search (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=bca&rl=t4f"
										pointNumber="BDW 263-1088"
										content={[
											'Removed item count from "Products" tab title, tab content changed to "Coming Soon"'
										]}/>
								</li>
							</ul>
						</div>
					}/>

				<Release
					version="1.6.2"
					date="Monday, November 22, 2021"
					body={
						<div className="changelog-body">
							<p><b>Sites (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=98&rl=bacj"
										pointNumber="BDW 226-1022"
										content={[
											"Company and Product links to corresponding profiles from SiteDetail"
										]}/>
								</li>
							</ul>

							<p><b>Profile Search (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=74&rl=bjx8"
										pointNumber="BDW 263-954"
										content={[
											"Clicking on Site cards on a Manufacturer's profile redirect to Site Details page",
											"Clicking on \"View on Map\" button redirects to map view of that company's sites"
										]}/>
								</li>
							</ul>
							
							<p><b>Profile (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=76&rl=cibv"
										pointNumber="BDW 222-956"
										content={[
											"Restyled header",
											"New Contact Info cards that shows user address, emails, and phone numbers, as well as edit functionality"
										]}/>
								</li>
							</ul>
							
							<p><b>Dashboard (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=9j&rl=6cu"
										pointNumber="BDW 173-1001"
										content={[
											" Added Accept/Decline buttons and functionality to Channel Join Request notifications in Dashboard"
										]}/>
								</li>
							</ul>
						</div>
					}/>

				<Release
					version="1.6.1"
					date="Friday, November 12, 2021"
					body={
						<div className="changelog-body">
							<p><b>Profile Search (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=9u&rl=2ax"
										pointNumber="BDW 263-1010"
										content={[
											"Manufacturer Profile - comma only appears if city and province are not null."
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=9t&rl=bmwn"
										pointNumber="BDW 263-1009"
										content={[
											"Non-Manufacturer Profile - Site/Manufacturers/Products tabs"
										]}/>
								</li>
							</ul>
							<p><b>Sites (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=998&rl=b4tc"
										pointNumber="BDW 226-998"
										content={[
											"Two column, sorted layout for Companies tab in Site Details - and update form functionality"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1037&rl=cu7n"
										pointNumber="BDW 226-1037"
										content={[
											"Add product functionality, report buttons"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1042&rl=cvtb"
										pointNumber="BDW 226-1042"
										content={[
											"Updated \"Add Site Picture\" functionality to match new form style (no save button)"
										]}/>
								</li>
							</ul>
						</div>
					}/>

				<Release
					version="1.6.0"
					date="Friday, October 29, 2021"
					body={
						<div className="changelog-body">
							Sites page revisited (Manufacturers removal), bugfixes, and ad placeholders
							<p><b>Sites (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=9b&rl=be7t"
										pointNumber="BDW 226-993"
										content={[
											"Site Details - Removed Manufacturers tab",
											"Site Details - Added number counts to Companies and Products tab titles",
											"Site Details - Removed duplicate headings for Companies and Product tabs",
											"Now retrieves correct products from backend"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1012&rl=b6vp"
										pointNumber="BDW 226-1012"
										content={[
											"Site Details Form - Removed Manufacturers tab",
											"Site Details Form - Added number counts to Companies and Products tab titles",
											"Site Details Form - Removed duplicate headings for Companies and Product tabs",
											"Now retrieves correct products from backend"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=9h&rl=b984"
										pointNumber="BDW 226-999"
										content={[
											"Fullscreen picture-viewer's footer (ie. navigation buttons and thumbnails) fixed to properly change picture to view"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=z3&rl=cavi"
										pointNumber="BDW 226-761"
										content={[
											"\"Add New Site Here\" button wording changed to \"+ Add\""
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=9c&rl=cncc"
										pointNumber="BDW 226-994"
										content={[
											"Styling for alert message on saving new site with empty site name"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=9a&rl=cskm"
										pointNumber="BDW 261-992"
										content={[
											"On autocomplete dropdown option select, a map pin is dropped on map, and map is updated to that location"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=9f&rl=z3v"
										pointNumber="BDW 226-997"
										content={[
											"Added Work Experience section to \"Add New Site\" form, and functionality"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=9d&rl=bave"
										pointNumber="BDW 226-995"
										content={[
											"Selected map pin's icons are larger, and shows the site name as a label above it."
										]}/>
								</li>
							</ul>

							<p><b>Connect (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=9i&rl=3dx"
										pointNumber="BDW 173-1000"
										content={[
											"Channel and Direct Message list items are sorted in order of most recent first"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=6b&rl=beq8"
										pointNumber="BDW 173-1000"
										content={[
											"Channel builders list fixed to properly update and correctly display new admin on admin change (without refreshing)"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=958&rl=b9xe"
										pointNumber="BDW 173-958"
										content={[
											"AM/PM formatting settings for Channel/Direct Messages changed to apply to all locales"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=1005&rl=b6y9"
										pointNumber="BDW 173-1005"
										content={[
											"Clicking on Builders' profile picture in Builders List opens full screen viewer",
											"Builder profile pictures are shown instead of a placeholder image"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=897&rl=b63a&noPrevNext=true"
										pointNumber="BDW 259-897"
										content={[
											"Channel builders list correctly updates on admin change (without refresh)"
										]}/>
								</li>
							</ul>
							
							<p><b>Profile Search (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=9n&rl=bc96"
										pointNumber="BDW 263-1004"
										content={[
											"Functionality added to view and add barcodes to a product's profile"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=9r&rl=bfqa"
										pointNumber="BDW 263-1007"
										content={[
											"Loading animation for adding/deleting product pictures"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=9q&rl=bgr7"
										pointNumber="BDW 263-1006"
										content={[
											"Improved aesthetics by changing to a 2-column layout, adjusted spacing between items"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=er&rid=1003&rl=bhpa"
										pointNumber="BDW 263-1003"
										content={[
											"Report popup now uses the same overview sections from Company and Product Profiles to display information about the reported item"
										]}/>
								</li>
							</ul>

							<p><b>Dashboard (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=7x&rl=bekr"
										pointNumber="BDW 266-949"
										content={[
											"Fixed issue with double scroll bars"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=959&rl=b9n3"
										pointNumber="BDW 262-959"
										content={[
											"AM/PM formatting settings for Dashboard notifications changed to apply to all locales"
										]}/>
								</li>
							</ul>
							
							<p><b>Tickets (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=742&rl=cpiv"
										pointNumber="BDW 224-742"
										content={[
											"Styling for alert message on saving with empty fields"
										]}/>
								</li>
							</ul>
							
							<p><b>Sidebar</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=75&rl=crsh"
										pointNumber="BDW 225-955"
										content={[
											"\"Earn BuilderBucks\" on sidebar redirects to Rewards page"
										]}/>
								</li>
							</ul>
							
							<p><b>Misc</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=9e&rl=b3pn"
										pointNumber="BDW 268-996"
										content={[
											"Added placeholders for ads"
										]}/>
								</li>
							</ul>

						</div>
					}/>
				
				<Release
					version="1.5.0"
					date="Friday, October 22, 2021"
					body={
						<div className="changelog-body">
							<p><b>General</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=950&rl=vjt&noPrevNext=true"
										pointNumber="BDW 263-950"
										content={[
											"Combined \"Manufacturer Profiles\" and \"Company Profiles\" into \"Profile Search\""
										]}/>
								</li>
							</ul>
							
							<p><b>Profile Search (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=7z&rl=yaz"
										pointNumber="BDW 263-951"
										content={[
											"Tab titles updated to include number count",
											"Removed duplicate headings"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=6e&rl=32b"
										pointNumber="BDW 263-900"
										content={[
											"Links open in new tabs"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=952&rl=cc62&noPrevNext=true"
										pointNumber="BDW 263-952"
										content={[
											"Company search functionality added, and search bar updated to search for all companies, or manufacturing companies only.",
											"Unique URLs for each Company Profile",
											"Products list items redirect to corresponding Product Profile"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=953&rl=cda2&noPrevNext=true"
										pointNumber="BDW 263-953"
										content={[
											"Product search functionality added",
											"Product Profiles created; each profile shows product name, manufacturer, pictures, barcodes (TODO for now), and reviews",
											"Unique URLs for each Product Profile",
											"Manufacturer name redirects to corresponding Manufacturer Profile",
											"Pictures can be clicked on to view in full screen",
											"Functionality to add and delete product pictures",
											"Functionality to add and edit reviews"
										]}/>
								</li>
							</ul>
							
							<p><b>Dashboard (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=949&rl=tk9"
										pointNumber="BDW 266-949"
										content={[
											"Reused Connect dashboard notifications for homepage dashboard on login"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=960&rl=vbf&noPrevNext=true"
										pointNumber="BDW 262-960"
										content={[
											"Dismiss notifications functionality"
										]}/>
								</li>
							</ul>

							<p><b>Connect (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&rid=959&rl=vd9&noPrevNext=true"
										pointNumber="BDW 262-959"
										content={[
											"Removed badge for number of notifications for Dashboard",
											"Removed comma between date and time for notification timestamps"
										]}/>
								</li>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=78&rl=wrc"
										pointNumber="BDW 173-958"
										content={[
											"Updated message details in message bubble to show date/time",
											"Removed divider between messages whose time difference is greater than TIME_INTERVAL"
										]}/>
								</li>
							</ul>

							<p><b>Tickets (Beta)</b></p>
							<ul>
								<li>
									<DevelopmentPoint
										link="https://smarthub.quickbase.com/db/bpy2qpbcc?a=dr&r=8b&rl=xqb"
										pointNumber="BDW 224-961"
										content={[
											"Prompt popup for confirming unsharing valid tickets with user"
										]}/>
								</li>
							</ul>
						</div>
					}/>

				<Release
					version="1.4.1"
					date="Friday, October 15, 2021"
					body={
						<div className="changelog-body">
							<p>General:</p>
							<ul>
								<li>Clickable images properly show darkened overlay</li>
								<li>Removed yellow borders from all circular images</li>
							</ul>
							
							<p><b>Profile (Beta)</b></p>
							<ul>
								<li>"Share Resume" confirmation modal body with updated aesthetic (more in line with mobile app)</li>
								<li>Discard popup fixed to appear for phone and email changes, as well as phone and email types</li>
							</ul>
							
							<p><b>Sites (Alpha)</b></p>
							<ul>
								<li>New site popup wording text changed to match mobile app</li>
								<li>Maxium zoom level increased</li>
								<li>Map styling now shows building outlines</li>
								<li>Unique URLs for each site; automatically selects site specified by siteId in URL on page load</li>
							</ul>

							<p><b>Connect (Beta)</b></p>
							<ul>
								<li>Unique URLs for each channel and direct message chat</li>
								<li>Ride Share notification in Dashboard opens popup where nearby users interested in ride sharing are listed (distance from current user is shown, and clicking redirects to the corresponding direct message chat)</li>
							</ul>
							
							<p><b>Contacts (Beta)</b></p>
							<ul>
								<li>"Send Message" button redirects to corresponding chat window in Connect</li>
								<li>Clicking on any site name under "Common Sites" redirects to corresponding SiteDetail view in Sites</li>
							</ul>
							
							<p><b>Tickets (Beta)</b></p>
							<ul>
								<li>ID# and Expiry Date are no longer mandatory fields</li>
								<li>Updated "Issued On" and "Expiry" date selectors for aesthetics and consistency</li>
							</ul>
						</div>
					}/>
				
				<Release
					version="1.4.0"
					date="Friday, October 8, 2021"
					body={
						<div className="changelog-body">
							<p>Profile Search beta release!</p>
							<p><b>Profile Search (Beta)</b></p>
							<p>Currently only supports Manufacturers (Companies and Products to follow shortly)</p>
							<ul>
								<li>Search field functionality</li>
								<li>Search category checkboxes functionality</li>
								<li>Pagination functionality</li>
								<li>Profile display with tabs</li>
								<li>Search result reporting (no API functionality for now, just modal)</li>
							</ul>

							<p>Bugfixes and changes for the following pages:</p>
							<p><b>Connect (Beta) - styling updates</b></p>
							<ul>
								<li>Search in sidebar doesn't scroll</li>
								<li>Section headers can be clicked to expand/collapse section</li>
								<li>Hover animation change</li>
								<li>Renamed "BuildID Info" channel to "Dashboard"</li>
								<li>Dashboard notification colours and timestamp</li>
								<li>Prompt user to mute channel instead when attempting to leave (only if channel is not muted)</li>
								<li>Channel admin change functionality - also prompts before submitting changes to API</li>
								<li>Leave Chat button for direct messages</li>
							</ul>
							<p><b>Contacts (Beta)</b></p>
							<ul>
								<li>Fixed duplicate getDashboardNotifications API function</li>
								<li>Renamed "Mutual Sites" to "Common Sites"</li>
								<li>Sites under "Common Sites" are clickable (functionality will be added when Sites is revisited, and unique URLs are generated for each site)</li>
								<li>BuildID Map Marker for contacts with common sites</li>
								<li>Primary email subtext</li>
							</ul>
							<p><b>Profile (Beta)</b></p>
							<ul>
								<li>Summary count of companies fixed to show number of unique companies of all experience items</li>
							</ul>
							<p><b>Sites (Alpha, paused development)</b></p>
							<ul>
								<li>Map styling updated to include street names</li>
							</ul>
						</div>
					}/>
				
				<Release
					version="1.3.4"
					date="Wednesday, September 29, 2021"
					body={
						<div className="changelog-body">
							<p><b>Connect (Beta) - minor fixes</b></p>
							<ul>
								<li>Messages render from bottom up; properly shows newest message on chat load</li>
								<li>Messages that are received while chat is active (ie. no notification) are marked as read in API so there is no unread count on reload</li>
								<li>Restyled message divider to be less emphasized</li>
							</ul>
						</div>
					}/>

				<Release
					version="1.3.3"
					date="Tuesday, September 28, 2021"
					body={
						<div className="changelog-body">
							<p><b>Connect (Beta) - minor changes and refactors:</b></p>
							<ul>
								<li>Send button disabled if no message content</li>
								<li>Fixed undefined sender's name for text above bubble</li>
								<li>Maintain aspect ratio for images</li>
								<li>Even padding on all sides for message bubble</li>
								<li>Loading animations</li>
								<li>Null checking for API gets/fetches</li>
								<li>General code cleanup and commenting</li>
							</ul>
						</div>
					}/>

				<Release
					version="1.3.2"
					date="Monday, September 27, 2021"
					body={
						<div className="changelog-body">
							<p><b>Connect (Beta)</b></p>
							<ul>
								<li>Replaced native push notifications with in-app notificiations, changes selected chat on click</li>
							</ul>
						</div>
					}/>

				<Release
					version="1.3.1"
					date="Monday, September 27, 2021"
					body={
						<div className="changelog-body">
							<p><b>Connect (Beta) hotfixes</b></p>
							<ul>
								<li>Fixed sidebar notification badge updating (notifications not always functional, eg. in incognito)</li>
							</ul>
						</div>
					}/>

				<Release
					version="1.3.0"
					date="Friday, September 24, 2021"
					body={
						<div className="changelog-body">
							<p>Connect beta release!</p>
							<p><b>Connect (Beta)</b></p>
							<ul>
								<li>Add channel user functionality (admin-only)</li>
								<li>Reworked "Remove Channel User" functionality to save changes on "Save" button click (like "Add Channel User" above)</li>
								<li>Popup to confirm discarding changes</li>
								<li>Fixed 100 message retrieving cap</li>
								<li>Moved mute button to chat window header, added functionality</li>
								<li>Delete channel functionality (admin-only)</li>
								<li>Add new channel functionality</li>
								<li>Direct Messages: contact list populated from API, instant messaging functionality</li>
								<li>Dashboard channel as the default view for Connect page</li>
								<li>Styling - selected chat window is highlighted in sidebar</li>
								<li>Search functionality for sidebar Channels/Direct Messages lists</li>
								<li>Unread messages badge for channel/direct messages in sidebar is cleared when corresponding chat is opened</li>
							</ul>
						</div>
					}/>

				<Release
					version="1.2.4"
					date="Monday, September 20, 2021"
					body={
						<div className="changelog-body">
							<p>Weekly alpha release (Monday meeting this week)</p>
							<p><b>Connect (Alpha)</b></p>
							<ul>
								<li>Leave channel functionality (non-admins only)</li>
								<li>Full screen viewer for channel pictures (from channel settings modal)</li>
								<li>Remove channel member functionality</li>
								<li>Big styling change for entire page</li>
								<li>Add user to channel autocomplete dropdown populated by API (no functionality yet)</li>
							</ul>
							<p><b>App-wide Styling Changes</b></p>
							<ul>
								<li>Changed design language to flat design (no shadows)</li>
								<li>Sharper corners for cards</li>
							</ul>
						</div>
					}/>

				<Release
					version="1.2.3"
					date="Thursday, September 16, 2021"
					body={
						<div className="changelog-body">
							<p>Weekly alpha release.</p>
							<p><b>Connect (Alpha)</b></p>
							<ul>
								<li>Fixed instant messaging bugs, ready for beta testing</li>
								<li>Chat window styling; name, timestamps, consecutive messages, dividers</li>
								<li>General styling: show channel/user profile pictures</li>
								<li>Messages with image bodies
									<ul>
										<li>Uploading images functionality</li>
										<li>Sent/received images can be clicked to view in full screen</li>
									</ul>
								</li>
								<li>Unread messages badge (shown, but does not clear on viewing messages)</li>
								<li>Channel settings modal
									<ul>
										<li>Functionality to edit/save channel picture, name, and/or description for channel admin</li>
										<li>View functionality only for non-admin channel members</li>
									</ul>
								</li>
								<li>Basic native push notifcations (only appears on Connect page)</li>
								<li>"Add New Channel" button opens modal (currently non-functional)</li>
								<li>"Add New Direct Message" button redirects to Contacts page</li>
							</ul>
							<p><b>Profile</b></p>
							<ul>
								<li>Styling for non-editable primary email field</li>
							</ul>
							<p><b>Misc</b></p>
							<ul>
								<li>Changed global h2 and added grey p class</li>
							</ul>
						</div>
					}/>

				<Release
					version="1.2.2"
					date="Monday, September 13, 2021"
					body={
						<div className="changelog-body">
							<p>Alpha release for testing SignalR chat functionality.</p>
							<p><b>Connect (Alpha)</b></p>
							<ul>
								<li>Channel list populated from API</li>
								<li>Channel selection retrieves corresponding messages from API</li>
								<li>Styling for chat window</li>
								<li>Instant message retrieval/sending (in alpha testing)</li>
							</ul>
						</div>
					}/>

				<Release
					version="1.2.1"
					date="Friday, September 10, 2021"
					body={
						<div className="changelog-body">
							<p>Weekly alpha release.</p>
							<p><b>Connect (Alpha)</b></p>
							<ul>
								<li>Structure and components created, no functionality at the moment</li>
							</ul>
							<p><b>Other</b></p>
							<ul>
								<li>Modal aesthetic update, header buttons now appear in the corners and all modals should be consistent</li>
							</ul>
						</div>
					}/>
					
				<Release
					version="1.2.0"
					date="Wednesday, September 8, 2021"
					body={
						<div className="changelog-body">
							<p><b>Contacts (Beta release)</b></p>
							<p>Full functionality:</p>
							<ul>
								<li>
									<b>Contacts viewing</b> (more info can be revealed by clicking on the arrow on the right side of a contact card)
									<ul>
										<li><b>Delete</b> and <b>Block</b> by clicking on the corresponding button</li>
										<li><b>Mutual sites</b> section only appears when there is at least 1 mutual site</li>
									</ul>
								</li>
								<li><b>Searching</b> by name, email, or phone number</li>
								<li><b>Contact requests</b> can be viewed by clicking on the "Requests" button
									<ul>
										<li>Badge with notification count</li>
										<li>Each request can be <b>accepted</b> or <b>declined</b> with the corresponding button</li>
									</ul>
								</li>
								<li><b>Add Contacts</b> by clicking on the "Add Contacts" button, entering the email(s) of the user you wish to add, and clicking "Send"</li>
							</ul>
							<p>"Send Message" button functionality will be implemented once Connect page is complete</p>
							<p><b>Profile</b></p>
							<ul>
								<li><p>Added regexp email format checking</p></li>
							</ul>
						</div>
					}/>

				<Release
					version="1.1.0"
					date="Thursday, September 2, 2021"
					body={
						<div className="changelog-body">
							<p><b>Profile (Beta Release)</b></p>
							<ul>
								<li><p>Site selection functionality for New Experience form</p></li>
								<li><p>Updated all date selectors with improved aesthetics and functionality; field titles should no longer overlap blank field text</p></li>
								<li><p>"Share Resume" button opens popup where user can enter one or more emails, and send a resume (generated by the backend)</p></li>
								<li><p>Closing forms will show a popup asking the user to confirm discarding unsaved changes</p></li>
								<li><p>Deleting an item in the Experience or Education/Certification sections will show a popup asking the user to confirm their action</p></li>
								<li><p>Added nickname, birthday, and employment status fields to Profile Details Edit Form</p></li>
								<li><p>Improved aesthetics for profile details section (also now shows full address)</p></li>
								<li><p>BuilderBucks balance (also displayed in Sidebar)</p></li>
								<li><p>Full-screen viewer for profile picture</p></li>
								<li><p>Renamed section to "Education/Certification" to match mobile app</p></li>
								<li><p>Experience section appears before Education/Certification section to match mobile app</p></li>
								<li><p>More error details when saving user profile details.</p></li>
							</ul>
							<p><b>Sites</b></p>
							<ul>
								<li><p>Products popup for entering product description and rating</p></li>
								<li><p>DEVELOPMENT PAUSED</p></li>
							</ul>
						</div>
					}/>

				<Release
					version="1.0.5"
					date="Monday, August 30, 2021"
					body={
						<div className="changelog-body">
							<p>Sites beta release.</p>
							<p>Autocomplete options from Google Places API for search field</p>
							<ul>
								<li>Searching for an address moves the map to show sites in the area</li>
								<li>Searching for a site name shows all sites with matching names</li>
							</ul>
							<p>Fetch API refactors</p>
							<p>Site List "My Timeline" fixed to show correct dates</p>
						</div>
					}/>

				<Release
					version="1.0.4"
					date="Thursday, August 26, 2021"
					body={
						<div className="changelog-body">
							<p>Sites: </p>
							<ul>
								<li>Tab sections for Detail and Edit Form views</li>
								<li>Restyled "Add" buttons in Edit Form for consistency</li>
								<li>Grid view for site iamges in Edit Form view</li>
								<li>Edit Form API saving functionality (manufacturers, companies, products, images)</li>
								<li>Experience (in "My Timeline" section of Detail view) save functionality</li>
								<li>"Add New Site" functionality - click on an empty space in the map, click "Add" button on popup, and complete the form</li>
								<li>Hovering search field (non-functional)</li>
						</ul>
						<p>FOR DEVELOPER USE: v1.0.4 does not have a separate commit in the repo, this release is under <b>"Moved search field handler into own function"</b> (b5087917cb265e1d189dae5dc9672c8beceb45b8)</p>
						</div>
					}/>

				<Release
					version="1.0.3"
					date="Thursday, August 19, 2021"
					body={
						<div className="changelog-body">
							<p>Weekly alpha release.</p>
							<p>Sites: Fullpage edit form, plus most functionality.</p>
						</div>
					}/>

				<Release
					version="1.0.2"
					date="Thursday, August 12, 2021"
					body={
						<div className="changelog-body">
							<p>Weekly alpha release.</p>
							<p>Retrieved API values for manufacturers/products/companies, and site images</p>
							<p>Interface changes:</p>
							<ul>
								<li>Timeline and site start dates for "My Timeline" view</li>
								<li>Site detail card manufacturer/companies sections</li>
								<li>Site detail edit forms</li>
								<li>Carousel and full screen viewers in detail cards</li>
								<li>Map cluster styling</li>
							</ul>
						</div>
					}/>
					
				<Release
					version="1.0.1"
					date="Tuesday, August 10, 2021"
					body={
						<div className="changelog-body">
							<p>Alpha release for initial testing of Sites interface.</p>
						</div>
					}/>

				<Release
					version="1.0.0"
					date="Friday, August 6, 2021"
					body={
						<div className="changelog-body">
							<p>First release.</p>
							<p>Tickets: 100% finished, ready for testing</p>
							<p>User Profile: 95% done (Sites needs to be completed to finish site selection for Experience section)</p>
							<p>Sites: In progress</p>
							<p>Rewards: Prototype complete, very rough implementation while API is being updated</p>
						</div>
					}/>
				
			</div>
		</div>
	)
}

export default ChangeLog;


function Release(props){
	return(
		<div className="card">
			<div style={{ paddingBottom: "10px" }}>
				<h3>v{props.version}</h3>
				<h4>{props.date}</h4>
			</div>
			{props.body}
		</div>
	)
}


function DevelopmentPoint(props){
	return(
		<>
			<a
				href={props.link}
				target="_blank" rel="noreferrer">
				{props.pointNumber}
			</a>

			{
				(props.content.length > 1)
					? (
						<ul>
							{
								props.content.map((line, index) => 
									<li key={index}>{line}</li>
								)
							}
						</ul>
					)
					: (<span> - {props.content[0]}</span>)
			}
		</>
	)
}