import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import QRCode from "react-qr-code";
import { BIBlue } from "../assets/buildidColors";
import { Button } from "@material-ui/core";

const ReferralPage = (props) => {
  const handleCopyLink = () => {
    if (props?.referLink) {
      navigator.clipboard
        .writeText(props.referLink)
        .then(() => {
          alert("Link copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy the link: ", err);
        });
    }
  };

  return (
    <Modal open={props?.open} onClose={props?.onClose}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          color: "#fff",
          p: 2,
        }}>
        <Box
          sx={{
            width: "500px",
            bgcolor: BIBlue,
            color: "#fff",
            borderRadius: "8px",
            boxShadow: 3,
            p: 2,
            position: "relative",
          }}>
          <IconButton
            color="inherit"
            onClick={props?.onClose}
            sx={{ position: "absolute", top: 8, right: 8 }}>
            <CloseIcon />
          </IconButton>

          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Typography id="refer-modal-description" sx={{ mb: 2 }}>
              Invite people to join BuildId and earn bonus BuilderBucks for each
              person that signs up!
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 2,
                padding: "10px",
              }}>
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "10px",
                  borderRadius: "10px",
                }}>
                <QRCode
                  size={200}
                  style={{
                    height: "auto",
                    maxWidth: "200px",
                    width: "200px",
                  }}
                  value={props?.referLink}
                  viewBox={`0 0 200 200`}
                />
              </div>
            </Box>

            <Button
              variant="outlined"
              color="primary"
              style={{ backgroundColor: "white" }}
              onClick={handleCopyLink}>
              <b>Copy Link</b>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReferralPage;
