import React from "react";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Data from "../Data";
import StyleItem from "../StyleItem";
import "../Taketour.css";
const Tickets = ({}) => {
  const { ticketData } = Data();

  return (
    <div>
      <CreditCardIcon style={{ height: "100px", width: "100px" }} className="headicon" />
      {ticketData.map((item, index) => (
        <StyleItem key={index} icon={item.icon} data={item} />
      ))}
    </div>
  );
};

export default Tickets;
