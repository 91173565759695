// TODO props.companyId is sites/company/XXX -> show ONLY those company's sites

import React, { useState, useEffect, useCallback } from "react";

import mapStyle from "../../assets/mapStyle";
import {
	GoogleMap,
	LoadScript,
	Marker,
	MarkerClusterer
} from "@react-google-maps/api";

import SitesOverlay from "./SitesOverlay";
import SiteDetail from "./SiteDetail";

import { useLocation } from "react-router-dom";

import { fetchUserWorkHistory } from "../../api/UserApi";
import {
	getAddress,
	// reverseGeocode,
	getSitesByMapBounds,
	//  fetchSites,
	//  fetchSitesPictures,
	//  fetchSiteProducts,
	//  fetchSiteCompanies2
} from "../../api/SitesApi";



import { fetchSites } from "../../api/SitesApi";

import mapMarkerYellow from "../../assets/images/map-marker-yellow.png";
import mapMarkerBlue from "../../assets/images/map-marker-blue.png";

import { BIBlack } from "../../assets/buildidColors";
import "../../assets/css/Sites.css";



// const MAP_MARKER_SIZE_LARGE = new window.google.maps.Size(45, 60);

// Style all MarkerClusters to be black on yellow
const clusterOptions = {
	styles: [
		{
			textColor: BIBlack,
			textSize: "13",
			height: 56,
			width: 56,
			// Icon image file needs to be hosted somewhere
			// This is the default m2.png from Google Maps
			url: "https://unpkg.com/@googlemaps/markerclustererplus@1.0.3/images/m2.png"
		},
	]
}


export default function Sites2(props) {

	// Map state vars
	const [map, setMap] = useState(null);

	/*
		Sites that are shown on map (as pins). When the map bounds change, this array is updated
		Elements have the form:
		{
			data: { ... },			// Site res from API
			workHistory: [{ ... }]	// Array of UserSite res from API
		}
	*/
	const [mapSites, setMapSites] = useState([]);
	/*
		Work history array to determine which pins are blue
		{
			data: { ... },			// Site res from API
			workHistory: [{ ... }]	// Array of UserSite res from API
		}
	*/
	const [userSites, setUserSites] = useState([]);
	const [overlayTab, setOverlayTab] = useState("explore");
	const [selectedSite, setSelectedSite] = useState(null);
	const [droppedMarker, setDroppedMarker] = useState(null);		//  Dropped "pin"
	const [scriptLibraries] = useState(["places"]);			// LoadScript prop for search autocomplete options
	const [lat, setLat] = useState(null);
	const [lng, setLng] = useState(null);
	const location = useLocation();

	useEffect(() => {
		updateMapSites();
	}, [])


	// Retrieves sites within map bounds
	const updateMapSites = useCallback(async () => {
		if (map && map.getBounds()) {
			if (overlayTab === "my-timeline") {
				setMapSites(userSites);
			}
			else {
				const addressData = await getAddress({ latitude: lat, longitude: lng });
				const addressObj = addressData.objAddress;

				const obj = {
					Active: 1,
					Id: 0,
					Latitude: addressObj.latitude,
					Longitude: addressObj.longitude,
					VP_Latitude_NE: map?.getBounds()?.getNorthEast()?.lat(),
					VP_Latitude_SW: map?.getBounds()?.getSouthWest()?.lat(),
					VP_Longitude_NE: map?.getBounds()?.getNorthEast()?.lng(),
					VP_Longitude_SW: map?.getBounds()?.getSouthWest()?.lng(),
					City: addressObj?.city ?? '',
					Province: addressObj?.province ?? '',
					Country: addressObj?.country ?? '',
					Address: addressObj?.address ?? '',
					PostalCode: addressObj?.postalCode ?? '',
					AllowedRecordsOnly: true,
					IsSelectedOnGoogleSearch: false
				}
				getSitesByMapBounds(obj)
					.then(res => {
						let formattedRes = [];
						res.forEach(site => {
							let workHistoryData = [];
							// Use the workHistory of the userSites, if there is one
							userSites.forEach(exp => {
								if (site.id === exp.data.id) workHistoryData = exp.workHistory;
							})

							// Push new object to newData
							formattedRes.push({
								data: site,
								workHistory: workHistoryData,
							})
						})
						setMapSites(formattedRes);
					});

				const promise1 = Promise.resolve(await getSitesByMapBounds(obj));
				promise1.then((mapRes) => console.log("mapRes : ", mapRes));

			}
		}
	}, [map, overlayTab, userSites, lng])


	function handleMapClick(event) {
		// Deselect selectedSite, if there is one
		if (selectedSite) setSelectedSite(null);
		// Deselect droppedMarker, if there is one
		else if (droppedMarker) setDroppedMarker(null);
		// Drop new marker at clicked location
		else {
			setDroppedMarker({
				latitude: event.latLng.lat(),
				longitude: event.latLng.lng()
			});
		}
	}


	async function handleMarkerClick(site) {
		const obj = {
			PageNr: 1,
			NrOfRecPerPage: 20,
			FullSearch: "",
			UserId: localStorage.userId,
			IncludeRecordNr: "false",
			TypeOfObjectReturned: "",
			SearchList: [
				{
					Id: site.data.id
				}
			],
			SortList: [
				{}
			]
		}
		const siteRes = await fetchSites({ SearchList: [{ Id: site.data.id }] })
		if (droppedMarker) setDroppedMarker(null);	// Clear dropped pin, if there is one
		setSelectedSite(siteRes.data[0]);
	}


	function moveMapToViewport(viewport) {
		map.fitBounds(viewport);
	}


	// When overlayTab is changed, update the map to show the appropriate site pins
	// ie. My Timeline -> only show userSites projsiteNameects on map
	useEffect(() => {
		updateMapSites()
		setSelectedSite(null);	// Also clear selected site
	}, [overlayTab, updateMapSites])


	// On load, get user's work history items
	useEffect(() => {
		if (!navigator.geolocation) {
		} else {
			navigator.geolocation.getCurrentPosition((position) => {
				setLat(position.coords.latitude);
				setLng(position.coords.longitude);
			}, () => {
				console.log('Unable to retrieve your location');
			});
		}

		fetchUserWorkHistory({ SearchList: [{ UserId: localStorage.userId }] })
			.then(async res => {
				if (res) {
					let workHistoryData = [];
					res.forEach(exp => {
						// TODO need to handle multiple EXPs at the same site (ie. check if it exists first)
						fetchSites({ SearchList: [{ "id": exp.siteId }] })
							.then(expSite => {
								workHistoryData.push({
									data: expSite.data[0] || {},
									workHistory: [{
										id: exp.id || 0,
										userId: exp.userId || 0,
										siteId: exp.siteId || 0,
										startDate: exp.startDate || "",
										endDate: exp.endDate || "",
										tradeId: exp.tradeId || 0,
										companyId: exp.companyId || 0,
										siteName: exp.siteName || "",
										tradeName: exp?.tradeName || "",
										companyName: exp.companyName || "",
										includeInResume: exp.includeInResume ? exp.includeInResume : false,
										present: exp.present ? exp.present : false,
										roleId: exp.roleId || 0,
										roleName: exp.roleName || "",
										latitude: exp.latitude || 0.0,
										longitude: exp.longitude || 0.0
									}]
								})
							})
					})

					// Sort workHistory in order of oldest start date
					workHistoryData = workHistoryData.sort((a, b) => (Date.parse(a.startDate) - Date.parse(b.startDate)))
					setUserSites(workHistoryData);
				}
			});
	}, [])


	return (
		<div className="page">
			<LoadScript
				googleMapsApiKey={'AIzaSyDFLELJHL-t3S5DkTgHRPWVBNpg8uW76Kc'}
				libraries={scriptLibraries}>
				<GoogleMap
					mapContainerClassName="site-map-container"
					options={{
						styles: mapStyle,
						center: { lat, lng },
						maxZoom: 20,
						zoom: 13,
						draggableCursor: "default",
						disableDefaultUI: true,
						clickableIcons: false,
					}}
					onLoad={async map => setMap(map)}
					// onIdle={() => updateMapSites()}
					onClick={event => handleMapClick(event)}>

					<SitesOverlay
						setLat={setLat}
						setLng={setLng}
						overlayTab={overlayTab}
						setOverlayTab={setOverlayTab}
						userSites={userSites}
						moveMapToViewport={moveMapToViewport} />

					{	// Only show SiteDetail view if there is a site selected
						selectedSite
							? <SiteDetail
								companyData={location.state}
								site={selectedSite}
								handleClose={() => setSelectedSite(null)} />
							: <></>
					}

					{/* Markers and Clusters for sites */}
					<MarkerClusterer options={clusterOptions}>
						{
							clusterer => {
								return mapSites.map(site => (
									<Marker
										key={site.data.id}
										position={{
											lat: site.data.latitude,
											lng: site.data.longitude
										}}
										clusterer={clusterer}
										onClick={() => handleMarkerClick(site)}
										icon={{
											url: (site.workHistory.length === 0)
												? mapMarkerYellow
												: mapMarkerBlue,
											scaledSize: (
												// Selected map marker is slightly larger
												(selectedSite && site.data.id === selectedSite.id)
													? new window.google.maps.Size(45, 60)
													: new window.google.maps.Size(30, 40)
											)
										}} />
								))
							}
						}
					</MarkerClusterer>
					{	// Dropped map marker
						(droppedMarker !== null)
							? (
								<Marker
									position={{
										lat: droppedMarker.latitude,
										lng: droppedMarker.longitude
									}}
									onClick={() => console.log("Test")}
									icon={{
										url: mapMarkerYellow,
										scaledSize: new window.google.maps.Size(45, 60)
									}} />
							)
							: <></>
					}
				</GoogleMap>
			</LoadScript>
		</div>
	)
}