import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Grid } from '@mui/material';
import { BIBlue } from '../assets/buildidColors';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useActions } from './ActionsContext';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffeebf',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function BuilderBucksBar(props) {
    const history = useHistory();
    const { builderBucksBalance, nrOfSites, workYears, numUniqueCompanies } = useActions();
    const location = useLocation();
    const pathname = location?.pathname;
    const showTutorialBtn = () => {
        if (
            pathname === "/rewards/store/" ||
            pathname === "/rewards/earn-builderbucks/" ||
            pathname === "/rewards/account-activity/" ||
            pathname === "/rewards/earn-rewards"
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div>
            <Accordion className='builder-bucks-bar-main'>
                {/* <div className={'builder-bucks-bar-header'}> */}
                <div className={navigator.userAgent.indexOf("Chrome") !== -1 ? 'builder-bucks-bar-header-chrome' : 'builder-bucks-bar-header'} >

                    <AccordionSummary expandIcon={<MenuIcon />} className='accordionSummary-height'>
                        {!showTutorialBtn() &&
                            <div className={'builder-bucks-bar-header-div'} >
                                <span className={"link"} style={{ color: BIBlue }}>
                                    <p className='accordionSummary-font' onClick={() => { history.push('/rewards/earn-builderbucks') }}>
                                        <b>{builderBucksBalance} Builder Bucks</b>
                                    </p></span>
                            </div>}
                    </AccordionSummary>
                </div>
                <AccordionDetails>
                    <Box className={'builder-bucks-bar-child-div'}>
                        <Grid container columnSpacing={5} rowSpacing={2}>
                            <Grid item md={4} xs={12}>
                                <Item>
                                    <Typography fontWeight={'bold'} color={BIBlue} padding={'10px 0px'} style={{cursor:'pointer'}} onClick={() => { history.push('/sites') }}>{nrOfSites} Sites</Typography>
                                </Item>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Item>
                                    <Typography fontWeight={'bold'} color={BIBlue} padding={'10px 0px'} style={{cursor:'pointer'}} onClick={() => { history.push('/sites') }}>{workYears} Years</Typography>
                                </Item>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Item>
                                    <Typography fontWeight={'bold'} color={BIBlue} padding={'10px 0px'} style={{cursor:'pointer'}} onClick={() => { history.push('/sites') }}>{numUniqueCompanies} Companies</Typography>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
