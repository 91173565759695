import React, { useState, useEffect } from "react";
import {
  FormControl,
  IconButton,
  Modal,
  TextField,
  Button,
  Typography,
  Autocomplete,
  Card,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import {
  fetchClientJobs,
  saveClientSiteEstimations,
  saveClientSiteEstimationsEntries,
} from "../../../api/ClientApi";

function ClientAddSiteEstimation({ open, close }) {
  const [jobOptions, setJobOptions] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [data, setData] = useState({
    jobCodeId: "",
    items: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const getJobList = async () => {
    try {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: localStorage.getItem("userId"),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            clientEmployeeId: localStorage.getItem("clientEmployeeId"),
          },
        ],
      };

      const res = await fetchClientJobs(obj);
      setJobOptions(res.data);
    } catch (err) {
      console.log("Error while fetching jobs list", err);
    }
  };

  const handleAddItem = () => {
    setData({
      ...data,
      items: [...data.items, { description: "", days: "", employees: "" }],
    });
  };

  const handleRemoveItem = (index) => {
    const items = data.items.filter((_, i) => i !== index);
    setData({ ...data, items });
  };

  const handleChangeItem = (index, field, value) => {
    const items = data.items.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setData({ ...data, items });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const userId = localStorage.getItem("userId");
    const clientEmployeeId = localStorage.getItem("clientEmployeeId");
    const payload = {
      UserId: Number(userId),
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: 0,
          ClientEmployeeId: clientEmployeeId,
          ClientJobId: selectedJob?.id,
          EstimationDate: "",
          ModifiedBy: Number(userId),
        },
      ],
    };

    try {
      const response = await saveClientSiteEstimations(payload);
      const objData = response.data[0].objData;
      const modifiedSaveList = data.items.map((item) => ({
        Id: 0,
        ClientSiteEstimationId: objData.id,
        Description: item.description,
        Days: Number(item.days).toFixed(2),
        Employees: Number(item.employees),
        SubTotal: Number(
          (Number(item.employees) * Number(item.days)).toFixed(2)
        ),
        ModifiedBy: Number(userId),
      }));

      const entriesPayload = {
        UserId: localStorage.getItem("userId"),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: modifiedSaveList,
      };

      await saveClientSiteEstimationsEntries(entriesPayload);
      setIsLoading(false);
      close();
      window.location.href = "/client-site-estimation";
    } catch (err) {
      setIsLoading(false);
      console.log("Error while saving estimate", err);
    }
  };

  useEffect(() => {
    getJobList();
  }, []);

  const isSubmitDisabled =
    !data.jobCodeId ||
    data.items.some(
      (item) => !item.description || !item.days || !item.employees
    );

  return (
    <Modal open={open} onClose={close}>
      <div className="modal-center timesheet-modal">
        <div className="modal-card">
          <div className="modal-card-body">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">Create Estimate</Typography>
              <IconButton onClick={close}>
                <Close />
              </IconButton>
            </div>
            <FormControl fullWidth margin="normal">
              <Autocomplete
                options={jobOptions}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  setData({ ...data, jobCodeId: newValue?.id || "" });
                  setSelectedJob(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Job"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>

            {data.items.length === 0 ? (
              <Button
                onClick={handleAddItem}
                fullWidth
                style={{ backgroundColor: "rgb(183, 62, 59)", color: "#fff" }}>
                Add Item
              </Button>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 16,
                  }}>
                  <h4>Area/Item</h4>
                  <Button onClick={handleAddItem}>Add Item</Button>
                </div>
                {data.items.map((item, index) => (
                  <Card key={index} style={{ marginTop: 16, padding: "10px" }}>
                    <div style={{ display: "flex" }}>
                      <TextField
                        label="Description"
                        value={item.description}
                        onChange={(e) =>
                          handleChangeItem(index, "description", e.target.value)
                        }
                        style={{ marginRight: 8 }}
                        fullWidth
                      />
                      <IconButton onClick={() => handleRemoveItem(index)}>
                        <Close />
                      </IconButton>
                    </div>
                    <div key={index} style={{ display: "flex", marginTop: 16 }}>
                      <TextField
                        label="Days"
                        type="number"
                        value={item.days}
                        onChange={(e) =>
                          handleChangeItem(index, "days", e.target.value)
                        }
                        style={{ marginRight: 5 }}
                        inputProps={{ step: 0.01 }}
                      />

                      <TextField
                        label="Employees"
                        type="number"
                        value={item.employees}
                        onChange={(e) =>
                          handleChangeItem(
                            index,
                            "employees",
                            parseInt(e.target.value)
                          )
                        }
                        style={{ marginRight: 5 }}
                      />
                      <TextField
                        label="Subtotal"
                        value={(item.days * item.employees).toFixed(2) || 0}
                        style={{ marginRight: 8 }}
                        disabled
                      />
                    </div>
                  </Card>
                ))}
              </>
            )}

            <Button
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
              fullWidth
              style={{
                marginTop: 16,
                backgroundColor: isSubmitDisabled
                  ? "grey"
                  : localStorage?.clientAccentColor,
                color: "white",
              }}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ClientAddSiteEstimation;
