import React, { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { Divider, IconButton, Typography } from '@material-ui/core'
import { BIGreen, BIGrey, BILightGreen, BILightRed, BIRed, BIWhite } from '../../../assets/buildidColors'
import { Edit as EditIcon } from '@mui/icons-material';
import "../../../assets/css/ClientHome.css";
import { useHistory, useParams } from 'react-router-dom';
import { deleteClientExpenses, fetchClientexpenses, fetchClientexpensesReceipts } from '../../../api/ClientApi';
import { Delete as DeleteIcon } from "@material-ui/icons";
import PromptModal from '../../../components/PromptModal';
import Addexpenses from './Addexpenses';
import Loader from "../../../components/Loader";
import { ClientHeader } from '../ClientHeader';

const ExpensesDetails = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [expensesDetails, setExpensesdetails] = useState();
  const [images, setImages] = useState([]);
  const [openAlert, setOpenalert] = useState(false);
  const [showAddExpenses, setShowAddExpenses] = useState(false);
  const [loader, setLoader] = useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState("");

  useEffect(async () => {
    await fetchExpensesdata();
  }, []);

  const fetchExpensesdata = async () => {
    setLoader(true);
    let obj = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: Number(localStorage?.userId),
      IncludeRecordNr: true,
      TypeOfObjectReturned: "",
      FetchAllowedRecordsOnly: false,
      SearchList: [{ id: id }],
      SortList: [
        {
          FieldName: "Id",
          Direction: "DESC",
        },
      ],
    };
    let getExpenses = await fetchClientexpenses(obj);
    setExpensesdetails(getExpenses?.data[0]);
    setLoader(false);
  };

  const openAlertbox = async () => {
    setOpenalert(true);
  };

  const deleteExpenses = async () => {
    setLoader(true)
    let object = {
      userId: Number(localStorage?.userId),
      returnRecordError: true,
      softDelete: false,
      deleteList: [
        {
          id: id,
        },
      ],
    };
    await deleteClientExpenses(object);
    setLoader(false)
    history.push("/client-expenses");
  };

  if (loader) {
    return <div className="page">
      <ClientHeader
        screenName={"Expenses"}
        clientSearchTerm={clientSearchTerm}
        setClientSearchTerm={setClientSearchTerm}
      />
      <Loader />
    </div>
  }

  return (
    <>
      <ClientHeader
        screenName={"Expenses"}
        clientSearchTerm={clientSearchTerm}
        setClientSearchTerm={setClientSearchTerm}
      />
      <div className="page">
        <div className="page-content" >
          <div className="expense-detail">
            <PromptModal
              isOpen={openAlert}
              onClose={() => setOpenalert(false)}
              title="Are you shure want to delete this expenses"
              desc="Please choose your option."
              redButtonText="Delete"
              blueButtonText="Cancel"
              onBlueButtonClick={() => setOpenalert(false)}
              onRedButtonClick={() => deleteExpenses()}
            />
            {expensesDetails?.status === "1" ? (
              <Stack style={{ margin: "10px" }}>
                <Typography
                  style={
                    "Approved" === "Approved"
                      ? {
                        backgroundColor: BILightGreen,
                        color: BIGreen,
                        padding: "10px",
                        borderRadius: "5px",
                      }
                      : {
                        backgroundColor: BILightRed,
                        color: BIRed,
                        padding: "10px",
                        borderRadius: "5px",
                      }
                  }
                >
                  {"Approved" === "Approved"
                    ? "This Expense has been approved and cannot be edited."
                    : "This Expense has been rejected."}
                </Typography>
              </Stack>
            ) : (
              <></>
            )}
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography style={{ color: BIGrey, fontSize: "14px" }}>
                {"October 27, 2016"}
              </Typography>
              <Stack style={{ flexDirection: "row" }}>
                {expensesDetails?.status !== "1" && (
                  <IconButton onClick={() => setShowAddExpenses(true)}>
                    <EditIcon sx={{ color: BIRed }} />
                  </IconButton>
                )}

                <IconButton onClick={openAlertbox}>
                  <DeleteIcon sx={{ color: BIWhite }} />
                </IconButton>
              </Stack>
            </Stack>
            <Stack>
              <Typography variant="h6" style={{ fontWeight: "500" }}>
                {expensesDetails?.title}
              </Typography>
              <Typography style={{ color: BIGrey, fontSize: "16px" }}>
                {"62540 - Auto"}
              </Typography>
            </Stack>
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              style={{ margin: "15px 0px" }}
            >
              <Stack>
                <Typography style={{ color: BIGrey }}>Job</Typography>
                <Typography>MW25-25166 Astra Petrofina</Typography>
              </Stack>
              <Stack>
                <Typography style={{ color: BIGrey }}>Distance</Typography>
                <Typography>
                  {expensesDetails?.milage} { }
                </Typography>
              </Stack>
            </Stack>
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              style={{ margin: "15px 0px" }}
            >
              <Stack>
                <Typography>Subtotal</Typography>
                <Typography>Taxes</Typography>
                <Typography>Tip</Typography>
                <Typography style={{ fontWeight: "600", marginTop: "10px" }}>
                  Total
                </Typography>
              </Stack>
              <Stack style={{ textAlign: "right" }}>
                <Typography>$ {expensesDetails?.subTotal}</Typography>
                <Typography>$ {expensesDetails?.tax}</Typography>
                <Typography style={{ color: BIGrey }}>
                  $ {expensesDetails?.tip}
                </Typography>
                <Typography
                  style={{
                    fontWeight: "600",
                    fontSize: "25px",
                    marginTop: "10px",
                  }}
                >
                  CA$ {expensesDetails?.total}
                </Typography>
                <Typography style={{ color: BIGrey }}>Paid personally</Typography>
              </Stack>
            </Stack>

            <Stack>
              <Divider />
              <Typography style={{ margin: "20px 0px", color: BIGrey }}>
                {expensesDetails?.description}
              </Typography>
              <Stack style={{ margin: "10px 0px" }}>
                <Typography style={{ color: BIGrey }}>Receipts</Typography>
                <div style={{ marginTop: "10px" }}>
                  <Stack
                    display={"flex"}
                    justifyContent={"start"}
                    alignItems={"center"}
                    direction={"row"}
                    gap={2}
                  >
                    {expensesDetails?.expenseReceiptList?.map((item, index) => (
                      <img
                        src={item?.fileUrl}
                        alt={index}
                        style={{
                          width: "150px",
                          height: "150px",
                          borderRadius: "20px",
                        }}
                      />
                    ))}
                  </Stack>
                </div>
              </Stack>
            </Stack>
            <div
              style={{
                margin: "10px 0px",
                padding: "0px1 10px",
                backgroundColor: BIWhite,
              }}
            >
              <Typography
                style={{ textAlign: "center", margin: "15px", color: BIGrey }}
              >
                Submitted on February 27, 2016, 3:12pm.
              </Typography>
              {"In Review" !== "In Review" && (
                <>
                  <Stack
                    display={"flex"}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography style={{ fontWeight: "600" }}>
                      TS Admin
                    </Typography>
                    <Typography style={{ color: BIGrey }}>
                      February 17, 2017
                    </Typography>
                  </Stack>
                  <Typography>
                    Time passed on again, and the youngest son too wished to set
                    out into the wide world to seek for the.
                  </Typography>
                  <Typography
                    style={{
                      textAlign: "center",
                      margin: "15px",
                      color: BIGrey,
                    }}
                  >
                    {"Approved" === "Approved" ? "Approved" : "Rejected"} on
                    February 27, 2016, 3:12pm.
                  </Typography>
                </>
              )}
              {showAddExpenses && (
                // <Addexpenses showAddExpenses={showAddExpenses} setShowAddExpenses={setShowAddExpenses} />
                <Addexpenses
                  showAdd={showAddExpenses}
                  setShowadd={setShowAddExpenses}
                  for={"edit"}
                  id={id}
                  clientId={expensesDetails?.clientId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpensesDetails;