import React from "react";
import Externaladd from "../profile/Externaladd";

export default function Add() {
  return (
    <>
      <Externaladd />
    </>
  );
}
