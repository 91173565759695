import React, { useState } from "react";

import { Button, TextField } from "@material-ui/core";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { saveUserSupport } from "../../api/UserApi";
import { BIBlack, BIRed } from "../../assets/buildidColors";
import "../../assets/css/Settings.css";

function ContactUsModal(props) {
	const [emptyMessageError, setEmptyMessageError] = useState(false);
	const [description, setDescription] = useState("");

	async function handleSubmitClick() {

		if (description === "") {
			 setEmptyMessageError(true);
			return;
		}

		try {
			const obj = {
				Id: 0,
				Description: description,
				RecordStatusId: 1,
				modifiedBy: localStorage.userId
			}

			await saveUserSupport(obj);
			props.onClose();
			setDescription('');
			setEmptyMessageError(false);
		} catch (error) {
			console.log(error);
		}
		await setDescription("");
	}

	return (
		<div>
			<Dialog
				open={props.isOpen}
				onClose={() => {
					props.onClose();
					setDescription('');
					setEmptyMessageError(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle style={{color:BIBlack}} id="alert-dialog-title">
					Contact Us
				</DialogTitle>

				<DialogContent>
					<div style={{ width: "100%" }}>
						<DialogContentText id="alert-dialog-description">
							Your feedback is extremely important to us!
						</DialogContentText>
						<TextField
							variant="outlined"
							fullWidth
							value={description}
							onChange={event => setDescription(event.target.value)}
							error={emptyMessageError}
							multiline
							rows={4} />

						<div className="input-container">
							{emptyMessageError ?
								<DialogContentText id="alert-dialog-description" style={{ color: BIRed }}>
									Message cannot be blank.
								</DialogContentText>
								:
								<> </>}
						</div>
					</div>
				</DialogContent>

				<DialogActions>
					<Button
						color="primary"
						onClick={() => {
							props.onClose();
							setDescription('');
							setEmptyMessageError(false);
						}}
						> Cancel </Button>
					<Button onClick={handleSubmitClick} color="primary" autoFocus> Submit </Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default ContactUsModal;