import React, { useRef, useState, useEffect } from 'react'
import { Close, VpnLock } from '@material-ui/icons'
import { Button, Grid, IconButton, Modal, TextField, Box, Typography, CircularProgress } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import "../../../assets/css/ClientHome.css"
import DateSelector from '../../../components/DateSelector'
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { deleteClientSafetyObservationsPictures, fetchClientJobs, getClientUDFieldsbyClientId, saveClientSafetyObservationPictures, saveClientSafetyObservations } from '../../../api/ClientApi';
import moment from 'moment';
import PictureViewer from '../../../components/PictureViewer';
import { Checkbox } from '@material-ui/core';
import { BILightGrey } from '../../../assets/buildidColors';

export const AddSafetyObservationModal = ({ isOpen, onClose, individualSafetyObservation, getSafetyIncidentsList, closeViewObservationModal }) => {
    const hint = useRef('');
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [uploadedImageURL, setUploadedImageURL] = useState([]);
    const [uploadedImageList, setUploadedImageList] = useState([]);
    const [clientJobList, setClientJobList] = useState([]);
    const [description, setDescription] = useState("");
    const [safetyObservationDate, setSafetyObservationDate] = useState(null);
    const [deleteImageList, setDeleteImageList] = useState([])
    const [loading, setLoading] = useState(false);
    const [pictureViewerOpen, setPictureViewerOpen] = useState(false);
    const [pictureViewIndex, setPictureViewIndex] = useState(0);
    const [clientUDFields, setClientUDFields] = useState([]);
    const imgRef = useRef(null);

    const handleAddImage = () => {
        imgRef.current.click();
    }

    const handleImageChange = async (event) => {
        const files = event.target.files;
        setUploadedImageList(files);
        const fileArray = Array.from(files)?.map((file) => URL.createObjectURL(file));
        setUploadedImageURL((prevImages) => prevImages.concat(fileArray));
    }

    function handleDate(date) {
        if (date) setSafetyObservationDate(date.format().substring(0, 10));
        else setSafetyObservationDate(null);
    }

    const handleRemoveImage = (picture, index) => {
        const deleteList = deleteImageList

        if (picture.id) {
            deleteList.push({ id: picture?.id })
        }
        setDeleteImageList(deleteList)
        setUploadedImageURL(uploadedImageURL.filter((_, i) => i !== index));
    };

    const handleImagePreviewClick = (index) => {
        setPictureViewIndex(index)
        setPictureViewerOpen(true);
    }

    const handleSafetyObservationSubmit = async () => {

        try {
            //! Client/DeleteClientSafetyObservationsPictures
            setLoading(true);
            if (deleteImageList.length > 0) {
                deleteImageList.map(async (item) => {
                    const deletePicObj = {
                        UserId: localStorage.userId,
                        ReturnRecordId: true,
                        ReturnRecordError: true,
                        SoftDelete: true,
                        DeleteList: [{ Id: item.id }]
                    }
                    const deletePicRes = await deleteClientSafetyObservationsPictures(deletePicObj);
                })

                // setDeletedPictureList([]);
            }


            //! Client/SaveClientSafetyObservations
            const { clientEmployeeId, clientJobCodeId } = selectedValue;


            let ud = {};
            clientUDFields.forEach((field) => {
                ud[field.id] = field.fieldDefaultValue == "true" ? 1 : 0;
            });
            const convertedJson = JSON.stringify(ud);

            const obj = {
                UserId: localStorage.userId,
                ReturnRecordId: true,
                ReturnRecordError: true,
                SaveList: [
                    {
                        Id: individualSafetyObservation?.id || 0,
                        ClientEmployeeId: Number(clientEmployeeId),
                        SafetyObservationDate: safetyObservationDate,
                        ClientJobId: Number(clientJobCodeId),
                        Description: description,
                        SARId: 0,
                        udFields: convertedJson,
                        ModifiedBy: localStorage.userId,
                    },
                ],
            };

            const res = await saveClientSafetyObservations(obj);

            if (res.message === "OK") {
                if (uploadedImageList.length > 0) {
                    const uploadPromises = Array.from(uploadedImageList)?.map(async (picture) => {
                        const formData = new FormData();
                        formData.append("Id", picture.id ?? 0);
                        formData.append("SafetyObservationId", res.data[0]?.objData?.id);
                        formData.append("ModifiedBy", localStorage.userId);
                        formData.append("FileName", picture);
                        await saveClientSafetyObservationPictures(formData);
                    });
                    try {
                        await Promise.all(uploadPromises);
                    } catch (error) {
                        console.error("Error uploading picture URLs:", error);
                    }
                }
                onClose();
                closeViewObservationModal && closeViewObservationModal();
                setLoading(false);
                getSafetyIncidentsList();
            }
            setLoading(false)
        }
        catch (error) {
            console.log("saveClientSafetyObservations Error ", error);
            setLoading(false);
        }
    }

    useEffect(async () => {
        const obj =
        {
            PageNr: 1,
            NrOfRecPerPage: 20,
            FullSearch: "",
            UserId: localStorage.userId,
            IncludeRecordNr: true,
            FetchAllowedRecordsOnly: true,
            SearchList: [{ ClientEmployeeId: localStorage.getItem("clientEmployeeId") }]
        }
        const clientJobRes = await fetchClientJobs(obj)
        if (Array.isArray(clientJobRes?.data) && clientJobRes.data.length > 0) {
            clientJobRes.data.forEach(obj => {
                if (obj.jobCode) {
                    obj.label = obj.jobCode;
                }
            });
            setClientJobList(clientJobRes?.data);
        }
    }, [])

    useEffect(() => {
        if (individualSafetyObservation) {
            let udfields = []
            const { description, pictureList, safetyObservationDate, clientJobId, clientJobCode, udFieldsList } = individualSafetyObservation;
            setSelectedValue({ clientEmployeeId: localStorage.clientEmployeeId, clientJobCodeId: clientJobId });
            setInputValue(clientJobCode);
            setDescription(description);
            setUploadedImageURL(pictureList);
            setSafetyObservationDate(safetyObservationDate);
            // getUDfields(clientJobId)
            udFieldsList.map((item) => {
                udfields.push({
                    fieldDefaultValue: item?.value, fieldLabel: item?.label, id: item.id
                })
            })
            setClientUDFields(udfields)
        }
    }, [individualSafetyObservation])

    // useEffect(async () => {
    //     getUDfields();
    // }, [selectedValue])

    const getUDfields = async (clientJOBid) => {
        try {
            const obj = {
                clientId: localStorage?.clientId,
                appId: 4,
                entityRecordId: selectedValue?.clientJobCodeId || clientJOBid,
            };
            const res = await getClientUDFieldsbyClientId(obj);
            setClientUDFields(res);

            const initialCheckboxState = res.reduce((acc, ele) => {
                if (ele.udFieldTypeId == 1) {
                    acc[ele.id] = ele.fieldDefaultValue === "true";
                }
                return acc;
            }, {});


            //   if (from !== undefined && from === "viewObservation") {
            //     let mergedObj = {}
            //     if (clientJobName != jobName) {
            //       mergedObj = {}
            //     } else {
            //       mergedObj = { ...udFields };
            //     }
            //     Object.keys(initialCheckboxState).forEach((key) => {
            //       if (!mergedObj.hasOwnProperty(key)) {
            //         mergedObj[key] = initialCheckboxState[key];
            //       }
            //     });
            //     setClientUdFieldChecks(mergedObj);
            //   } else {
            //     setClientUdFieldChecks(initialCheckboxState);
            //   }
        }
        catch (err) {
        }
    }

    const handelCheckboxChange = (e, id) => {
        const { checked } = e.target;
        setClientUDFields((prev) => {
            return prev.map((field) => {
                if (field.id === id) {
                    field.fieldDefaultValue = checked ? "true" : "false";
                }
                return field;
            })
        })
    }

    return (
        <>
            <input
                type='file'
                accept="image/*"
                onChange={handleImageChange}
                ref={imgRef}
                style={{ display: 'none' }}
                multiple
            />

            <Modal
                open={isOpen}
                onClose={onClose}>
                <div className="modal-center" style={{ outline: "none" }} >
                    <div className="modal-card" style={{ height: "500px", width: "600px", outline: "none" }}>
                        <div style={{ display: "flex", alignItems: "center", padding: "10px", gap: "10px" }}>
                            <IconButton
                                color="primary"
                                onClick={onClose} style={{ padding: "0px" }}>
                                <Close />
                            </IconButton>
                        </div>
                        <div style={{ height: "420px", overflowY: 'scroll', padding: "10px" }}>
                            <Grid marginLeft={4} marginRight={4} gap={2} marginTop={2}>
                                <Typography variant='subtitle1' gutterBottom>Date</Typography>
                                <DateSelector
                                    label="Date"
                                    value={safetyObservationDate ? moment(safetyObservationDate).format("YYYY-MM-DD") : null}
                                    setValue={handleDate}
                                />
                            </Grid>
                            <Grid marginLeft={4} marginRight={4} gap={2} marginTop={2}>
                                <Typography variant='subtitle1' gutterBottom>Job</Typography>
                                <Autocomplete
                                    fullWidth
                                    onKeyDown={(event) => {
                                        if (event.key === 'Tab') {
                                            if (hint.current) {
                                                setInputValue(hint.current);
                                                event.preventDefault();
                                            }
                                        }
                                    }}
                                    onBlur={() => {
                                        hint.current = '';
                                    }}
                                    disablePortal
                                    inputValue={inputValue}
                                    filterOptions={(options, state) => {
                                        const displayOptions = options.filter((option) =>
                                            option.label.toLowerCase().includes(state.inputValue.toLowerCase().trim()) ||
                                            (option.name && option.name.toLowerCase().includes(state.inputValue.toLowerCase().trim())),
                                        );

                                        return displayOptions;
                                    }}
                                    id="combo-box-hint-demo"
                                    options={clientJobList}
                                    renderOption={(props, option) => (
                                        <Box {...props}>
                                            <Typography>{option.label}</Typography>
                                            {option.name && (
                                                <>
                                                    <br />
                                                    <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft: '5px' }}>({option.name})</Typography>
                                                </>
                                            )}
                                        </Box>
                                    )}
                                    onChange={(event, newValue) => {
                                        getUDfields(newValue?.clientId);
                                        setSelectedValue({ clientEmployeeId: localStorage.clientEmployeeId, clientJobCodeId: newValue?.id })
                                        if (newValue) {
                                            setInputValue(newValue.label); // Update the input value to the selected label
                                        } else {
                                            setInputValue('');
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <Box sx={{ position: 'relative' }}>
                                            <Typography sx={{ position: 'absolute', opacity: 0.5, left: 14, top: 16 }}>
                                                {hint.current}
                                            </Typography>
                                            <TextField
                                                {...params}
                                                onFocus={() => setInputValue("")}
                                                onBlur={() => {
                                                    if (individualSafetyObservation) {
                                                        if (!inputValue)
                                                            setInputValue(individualSafetyObservation?.clientJobCode)
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    setInputValue(newValue);
                                                    const matchingOption = clientJobList.find((option) =>
                                                        option.label.startsWith(newValue),
                                                    );

                                                    if (newValue && matchingOption) {
                                                        hint.current = matchingOption.label;
                                                    } else {
                                                        hint.current = '';
                                                    }
                                                }}
                                                label="Job"
                                            />
                                        </Box>
                                    )}
                                />

                            </Grid>
                            <Grid marginLeft={4} marginRight={4} gap={2} marginTop={2}>
                                <Typography variant='subtitle1' gutterBottom>Description</Typography>
                                <TextField fullWidth multiline rows={5} value={description} onChange={(e) => setDescription(e.target.value)} />
                            </Grid>
                            <Grid display={'flex'} marginLeft={4} gap={2} marginTop={2}>
                                <div onClick={handleAddImage} style={{ cursor: 'pointer', height: '100px', width: '100px', border: '2px solid lightgrey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <AddIcon />
                                </div>
                                <div style={{ display: 'flex', gap: '10px', width: '400px', overflow: 'scroll' }}>
                                    {uploadedImageURL?.map((picture, index) => (
                                        <Grid item key={index} xs={6} sm={4} md={3} style={{ position: 'relative' }}>
                                            <img
                                                src={picture?.fileUrl || picture}
                                                alt={`Selected Picture ${index}`}
                                                style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius: "10px",
                                                    marginBottom: "10px",
                                                }}
                                                onClick={() => handleImagePreviewClick(index)}
                                            />
                                            <IconButton
                                                onClick={() => handleRemoveImage(picture, index)}
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                    padding: '5px',
                                                    backgroundColor: BILightGrey,
                                                    color: 'white'
                                                }}
                                            >
                                                <CloseIcon style={{ fontSize: '16px' }} />
                                            </IconButton>
                                        </Grid>
                                    ))}
                                </div>
                            </Grid>
                            <Grid marginLeft={3} marginRight={4} gap={2} marginTop={2}>
                                {clientUDFields.length != 0 && clientUDFields.map((field, index) => {
                                    return (<>
                                        <Checkbox
                                            color="primary"
                                            checked={field?.fieldDefaultValue == "true"
                                                ? true : false
                                            }
                                            onChange={(e) => handelCheckboxChange(e, field?.id)}
                                        />
                                        <span style={{ color: "grey" }}>
                                            {field?.fieldLabel}
                                        </span></>)
                                }
                                )
                                }

                            </Grid>
                            <Grid marginLeft={4} marginRight={4} gap={2} marginTop={2}>
                                {!loading ? <Button
                                    onClick={handleSafetyObservationSubmit}
                                    fullWidth
                                    disabled={!safetyObservationDate || !description || !selectedValue?.clientJobCodeId}
                                    style={{ background: !safetyObservationDate || !description || !selectedValue?.clientJobCodeId ? BILightGrey : localStorage?.clientAccentColor, color: 'white' }}>
                                    Submit
                                </Button>
                                    :
                                    <Button
                                        fullWidth
                                        disabled
                                        style={{ background: localStorage?.clientAccentColor, color: 'white' }}>
                                        <CircularProgress style={{ color: 'white', height: '26px', width: '26px' }} />
                                    </Button>}
                            </Grid>
                        </div>
                    </div>
                </div>
            </Modal>
            {pictureViewerOpen &&
                <PictureViewer
                    isOpen={pictureViewerOpen}
                    onClose={() => setPictureViewerOpen(false)}
                    pictures={uploadedImageURL?.map((pic) => ({ src: pic?.fileUrl || pic, alt: "Description" }))}
                    viewerIndex={pictureViewIndex}
                />
            }
        </>
    )
}
