import { restGet, restPost } from "./RestClient";
import { headers } from "./UserApi";

export async function fetchSafetyIncidents(data) {
    const res = await restPost("Sites/FetchSafetyIncidents", data, headers);
    return res;
}

export async function searchUsersMembers() {
    const res = await restGet(`Users/SearchUsersMembers/${localStorage.userId}/${0}/`, headers);
    return res;
}

export async function fetchSites(data) {
    const res = await restPost("Sites/FetchSites", data, headers);
    return res;
}

export async function saveSafetyIncident(data) {
    const res = await restPost("Sites/SaveSafetyIncidents", data, headers);
    return res;
}

export async function deleteSafetyIncidents(data) {
    const res = await restPost("Sites/DeleteSafetyIncidents", data, headers);
    return res;
}