import React from 'react'
import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import moment from 'moment';

export const ClientSafetyObservationCard = ({ safetyObservationItem }) => {
    const { profilePictureUrl, userName, safetyObservationDate, clientJobCode, clientJobName, description } = safetyObservationItem;
    return (
        <>
            <Grid style={{ cursor: 'pointer' }} marginBottom={1} container marginLeft={2} alignContent={"center"} md={12} lg={12} >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Card>
                        <Grid gap={1} container justifyContent="space-between" alignItems={"center"} padding={2}>
                            <Grid item><Avatar alt={profilePictureUrl} src={profilePictureUrl} /></Grid>
                            <Grid item><Typography variant='p'>{userName}</Typography></Grid>
                            <Grid item sx={{ marginLeft: 'auto' }}><Typography variant='p'>{moment(safetyObservationDate).format("MMMM D, YYYY")}</Typography></Grid>
                        </Grid>
                        <CardContent>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <LocationOnIcon color="error" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" color="textSecondary">
                                        {`${clientJobCode} - ${clientJobName}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                {description}
                            </Typography>
                            {safetyObservationItem?.udFieldsList?.length > 0 &&
                                safetyObservationItem?.udFieldsList?.map((item, index) => {
                                    if (item?.value == "true"
                                    ) {
                                        return <Typography style={{ backgroundColor: "lightgrey", padding: "5px", borderRadius: "5px", width: 'fit-content' }} variant="body2" color="textSecondary">
                                            {item?.label}
                                        </Typography>
                                    }
                                })
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
