import { restGet, restPost } from "./RestClient";
import { form_headers, headers } from "./UserApi";

// let headers = {
// 	"Content-Type": "application/json",
// 	"Accept": "application/json",
// 	"Authorization": ('Bearer ' + localStorage.getItem('token')),
// }

// let form_headers = {
// 	"Accept": "application/json",
// 	"Content-Type": "multipart/form-data",
// 	"Authorization" : ('Bearer ' + token),
// }

export async function fetchTimesheet(data) {
	const res = await restPost("users/FetchTimeSheets", data, headers);
	return res;
}

export async function sendUnsentTimesheets(data) {
	const res = await restPost("users/SendTimeSheets", data, headers);
	return res;
}

export async function saveTimeSheet(data) {
	const res = await restPost("users/SaveTimeSheets", data, headers);
	return res;
}

export async function fetchTimeSheetsGoals(data) {
	const res = await restPost("users/FetchTimeSheetsGoals", data, headers);
	return res;
}

export async function saveTimeSheetsPictures(data) {
	const res = await restPost("users/SaveTimeSheetsPictures", data, form_headers);
	return res;
}


export async function deleteTimesheeetGoals(data) {
	const res = await restPost("users/DeleteTimeSheetsGoals", data, headers);
	return res;
}


export async function saveTimeSheetsEntries(data) {
	const res = await restPost("users/SaveTimeSheetsEntries", data, headers);
	return res;
}
export async function saveTimeSheetsGoals(data) {
	const res = await restPost("users/SaveTimeSheetsGoals", data, headers);
	return res;
}



export async function deleteTimeSheetsPictures(data) {
	const res = await restPost("users/DeleteTimeSheetsPictures", data, headers);
	return res;
}

export async function deleteTimeSheetsEntries(data) {
	const res = await restPost("users/DeleteTimeSheetsEntries", data, headers);
	return res;
}

export async function deleteTimeSheets(data) {
	const res = await restPost("users/DeleteTimesheets", data, headers);
	return res;
}

export async function fetchTimeSheetsEntries(data) {
	const res = await restPost("users/FetchTimeSheetsEntries", data, headers);
	return res;
}

export async function saveGoals(data) {
	const res = await restPost("users/SaveGoals", data, headers);
	return res;
}

export async function saveGoalsCategories(data) {
	const res = await restPost("users/SaveGoalsCategories", data, headers);
	return res;
}

export async function fetchGoals(data) {
	const res = await restPost("users/FetchGoals", data, headers);
	return res;
}

export async function updateIsGoalFlag(data) {
	const res = await restPost("users/UpdateIsGoalFlag", data, headers);
	return res;
}

export async function deleteGoals(data) {
	const res = await restPost("users/DeleteGoals", data, headers);
	return res;
}

export async function sendTimeSheetsGoals(data) {
	const res = await restPost("users/SendTimeSheetsGoals", data, headers);
	return res;
}

export async function getTimeSheetsGoalsDetailsForProgressBar(data) {
	const res = await restGet("Users/GetTimeSheetsGoalsDetailsForProgressBar/" + data, headers);
	return res;
}