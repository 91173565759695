import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Card,
  CardContent,
  Stack,
  Typography,
  Link,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Chip,
  InputAdornment,
} from "@mui/material";
import { Button } from "@material-ui/core";
import {
  Work as BriefcaseIcon,
  AccessTime as TimeIcon,
  LocationOn as MapMarkerIcon,
  Phone as PhoneIcon,
  Public as GlobeIcon,
  Close as CloseIcon,
  AddCircle as AddIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  stringToLocale,
  removeISOTime,
  extractBaseURL,
} from "../../assets/helpers";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  DialogActions,
} from "@mui/material";
import SearchField from "../SearchField";
import {
  fetchProducts,
  fetchTradeProducts,
  saveProductTrades,
  saveProducts,
} from "../../api/ProductsApi";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { fetchTrades } from "../../api/TradesApi";
import {
  fetchCompanies,
  fetchCompaniesTrades,
  getProvinceByCountryName,
  saveCompanies2,
  saveCompanyAddress,
  saveCompanyTrades,
} from "../../api/CompanyApi";
import { BIBlue, BIGrey } from "../../assets/buildidColors";
import ApartmentIcon from "@mui/icons-material/Apartment";
import {
  fetchSiteTypes,
  getAddress,
  saveSitesDetails,
  saveUserSitesProjects,
} from "../../api/SitesApi";
import Radio from "@material-ui/core/Radio";
import {
  Autocomplete as GooglePlacesAutocomplete,
  LoadScript,
} from "@react-google-maps/api";
import moment from "moment";
import PromptModal from "../PromptModal";
import InputMask from "react-input-mask";
import ProductSearchField from "../ProductSearchField";
import mapMarkerYellow from "../../assets/images/map-marker-yellow.png";
import { GoogleMap, Marker } from "@react-google-maps/api";

/*
About Section component for profile pages
Props (*required):
  *content	- array of key:value pairs, in the order to display them
  withCard	- boolean; if true, renders AboutSection with a MUI Card background

Usage:
  <AboutSection
    content={[]}
    withCard/>
*/

// const containerStyle = {
//   width: "100%",
//   height: "250px",
//   border: "1px solid #000000d6",
//   marginBottom: "14px",
// };

const containerStyle = {
  width: '100%',
  height: '300px',
};


export default function AboutSection(props) {

  const history = useHistory();

  // On load, check if props.content items are non-empty or not
  // If all are empty, don't render AboutSection card
  const [isContentEmpty, setIsContentEmpty] = useState(true);

  const [openAddTradesModal, setAddTradeOpenModal] = useState(false);
  const [tradeList, setTradeList] = useState(props?.trades ?? null);
  const [selectedTradeId, setSelectedTradeId] = useState(0);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  useEffect(() => {
    setTradeList(props?.trades ?? null);
  }, [props?.trades]);

  useEffect(() => {
    if (props?.tradeAboutSection) {
      return setIsContentEmpty(false);
    } else {
      Object.keys(props.content).forEach((key, index) => {
        if (props.content[key] !== "") {
          setIsContentEmpty(false);
          return;
        }
      });
    }
  }, [props]);

  const onTradesSaveSuccessfully = async () => {
    try {
      //get trades list based on about section type
      if (props?.aboutSectionType == "product") {
        const obj = {
          PageNr: 1,
          NrOfRecPerPage: 0,
          FullSearch: "",
          UserId: Number(localStorage.userId),
          IncludeRecordNr: true,
          TypeOfObjectReturned: "",
          SearchList: [{ ProductId: Number(props.productId) }],
        };
        await fetchTradeProducts(obj)
          .then((res) => {
            setTradeList(res?.data ?? []);
          })
          .catch((err) => {
            console.log("Error while getting the product trades list:-", err);
          });
      } else {
        const reqObj = {
          PageNr: 1,
          NrOfRecPerPage: 0,
          FullSearch: "",
          UserId: Number(localStorage.userId),
          IncludeRecordNr: true,
          TypeOfObjectReturned: "",
          SearchList: [{ CompanyId: props.manufacturer_and_company_id }],
        };
        await fetchCompaniesTrades(reqObj).then((trades) => {
          setTradeList(trades);
        });
      }
    } catch (err) {
      console.log("Err while getting trades after saving");
    }
  };

  const handleCloseAddTradesModal = () => {
    setAddTradeOpenModal(false);
  };

  const handleRelatedTradeRedirect = (tradeId) => {
    window.location.href =
      props.from === "TradeProxyPage"
        ? `/proxysite/TradeDetail/${tradeId}`
        : `/trade/${tradeId}`;
    // history.push(`/proxysite/TradeDetail/${tradeId}`)
  };

  const onTradeItemClick = (id) => {
    if (!id) return;
    setSelectedTradeId(id);
    setConfirmDeleteModalOpen(true);
  };

  return (
    // Only render AboutSection if there is at least one non-empty content item
    !isContentEmpty ? (
      props.withCard ? (
        <>
          <Card>
            <CardContent>
              <h3>About </h3>
              <div style={{ paddingBottom: "10px" }}>
                {props.aboutSectionType === "company" ||
                  props.aboutSectionType === "manufacturer" ? (
                  props?.trades.length == 0 &&
                    props?.content?.formattedAddress?.address === "" &&
                    props?.content?.phone === "" &&
                    props?.content?.website === "" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        color: "gray",
                      }}>
                      <span>
                        No Information about this {props.aboutSectionType}.
                      </span>
                      <span>
                        Known any information? Add it here for Builderbucks
                      </span>
                    </div>
                  ) : null
                ) : null}
                {props.aboutSectionType === "product" ? (
                  props?.trades.length == 0 &&
                    props?.content?.description === "" &&
                    props?.content?.manufacturer === "" &&
                    props?.content?.website === "" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        color: "gray",
                      }}>
                      <span>
                        No Information about this {props.aboutSectionType}.
                      </span>
                      <span>
                        Known any information? Add it here for Builderbucks
                      </span>
                    </div>
                  ) : null
                ) : null}
              </div>

              {props.from === "TradeProxyPage" && (
                <AboutSectionBody
                  Id={props?.Id}
                  from={props?.from}
                  name={props?.name}
                  content={props.content}
                  saveItem={(newItem) => props.saveItem(newItem)}
                  manufacturer_and_company_id={
                    props.manufacturer_and_company_id
                  }
                  onDataSave={(data) => {
                    props.onDataSave(data);
                  }}
                  aboutSectionType={props?.aboutSectionType ?? ""}
                  productDetails={props?.productDetails ?? ""}
                  siteDetails={props?.siteDetails ?? ""}
                  proxySite={props?.proxySite}
                  manufacturerName={props?.manufacturerName}
                  info={props?.info}
                  tradeAboutSection={props?.tradeAboutSection}
                  multipleAddress={props?.multipleAddress ?? []}
                  projectDetails={props?.projectDetails}
                />
              )}

              {props?.tradeDescription && (
                <>
                  <Typography>{props?.tradeDescription}</Typography>
                </>
              )}

              {props?.otherNameList && props?.otherNameList?.length > 0 && (
                <div
                  style={{
                    marginTop: "5%",
                    marginBottom: props?.from !== "TradeProxyPage" && "8px",
                    marginLeft: props?.from === "TradeProxyPage" && "5px",
                  }}>
                  <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                    Also known as
                  </span>{" "}
                  &nbsp;
                  {props?.otherNameList.map((val, i) => {
                    return props.otherNameList.length === i + 1
                      ? val?.name
                      : `${val?.name}, `;
                  })}
                </div>
              )}

              {props?.relatedTrades && props?.relatedTrades?.length > 0 && (
                <div
                  style={{
                    paddingBottom: "15px",
                    marginTop: "2%",
                    marginLeft: props?.from === "TradeProxyPage" && "5px",
                  }}>
                  <div
                    style={{
                      color: "rgba(0, 0, 0, 0.54)",
                      marginBottom: "10px",
                    }}>
                    Related Trades
                  </div>
                  {props?.relatedTrades.map((val, i) => {
                    return (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleRelatedTradeRedirect(val.relatedWithTradeId)
                        }>
                        {" "}
                        {props.relatedTrades.length === i + 1 ? (
                          val?.relatedWithTradeName
                        ) : (
                          <>
                            {`${val?.relatedWithTradeName}`}
                            <br />
                          </>
                        )}
                      </span>
                    );
                  })}
                </div>
              )}
              {tradeList ? (
                <>
                  {tradeList.length == 0 ? (
                    props?.proxySite ? (
                      <></>
                    ) : (
                      <Stack direction="row" paddingBottom={2}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setAddTradeOpenModal(true);
                          }}
                          style={{ borderRadius: "30px" }}>
                          Add Trade
                        </Button>
                      </Stack>
                    )
                  ) : (
                    <div style={{ paddingBottom: "15px" }}>
                      <Stack
                        style={{
                          marginTop: "2%",
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "10px 10px",
                        }}
                        direction="row"
                        spacing={0}>
                        {tradeList
                          ?.sort(
                            (a, b) =>
                              a.tradeType
                                ?.toLowerCase()
                                .localeCompare(b.tradeType?.toLowerCase()) ||
                              a.tradeName
                                ?.trim()
                                .toLowerCase()
                                .localeCompare(
                                  b.tradeName?.trim().toLowerCase()
                                )
                          )
                          .map((item, index) => {
                            return (
                              <Chip
                                key={index.toString()}
                                label={item.tradeName?.trim()}
                                style={{
                                  cursor: "pointer",
                                  backgroundColor:
                                    item?.tradeType?.toLowerCase() == "main"
                                      ? BIBlue
                                      : "null",
                                  color:
                                    item?.tradeType?.toLowerCase() == "main"
                                      ? "white"
                                      : "null",
                                }}
                                onClick={() => {
                                  onTradeItemClick(item?.tradeId ?? 0);
                                }}></Chip>
                            );
                          })}
                        {!props?.proxySite && (
                          <IconButton color="primary" size="small"
                            onClick={() => { setAddTradeOpenModal(true); }}
                          >
                            <AddIcon />
                          </IconButton>
                        )}
                      </Stack>
                    </div>
                  )}
                  <AddTradesModal
                    open={openAddTradesModal}
                    onClose={handleCloseAddTradesModal}
                    alreadyTradesSelected={tradeList}
                    manufacturer_and_company_id={
                      props.manufacturer_and_company_id
                    }
                    onTradesSaveSuccessfully={onTradesSaveSuccessfully}
                    aboutSectionType={props?.aboutSectionType ?? ""}
                    productId={props?.productId ?? ""}
                    proxySite={props?.proxySite}
                  />
                </>
              ) : null}
              {props.from !== "TradeProxyPage" && (
                <AboutSectionBody
                  Id={props?.Id}
                  name={props?.name}
                  content={props.content}
                  saveItem={(newItem) => props.saveItem(newItem)}
                  manufacturer_and_company_id={
                    props.manufacturer_and_company_id
                  }
                  onDataSave={(data) => {
                    props.onDataSave(data);
                  }}
                  aboutSectionType={props?.aboutSectionType ?? ""}
                  productDetails={props?.productDetails ?? ""}
                  siteDetails={props?.siteDetails ?? ""}
                  proxySite={props?.proxySite}
                  manufacturerName={props?.manufacturerName}
                  info={props?.info}
                  tradeAboutSection={props?.tradeAboutSection}
                  multipleAddress={props?.multipleAddress ?? []}
                  projectDetails={props?.projectDetails}
                />
              )}
            </CardContent>
          </Card>
          {confirmDeleteModalOpen && (
            <PromptModal
              isOpen={confirmDeleteModalOpen}
              onClose={() => {
                setSelectedTradeId(0);
                setConfirmDeleteModalOpen(false);
              }}
              title={`Delete ${props?.aboutSectionType === "company"
                ? "Company"
                : props?.aboutSectionType === "manufacturer"
                  ? "Manufacturer"
                  : "Product"
                } Trades`}
              desc="Are you sure you want to delete this Trade?"
              redButtonText="OK"
              blueButtonTextFirst="CANCEL"
              onRedButtonClick={async () => {
                await props?.deleteTrade(selectedTradeId);
                setSelectedTradeId(0);
                setConfirmDeleteModalOpen(false);
              }}
            />
          )}
        </>
      ) : (
        <AboutSectionBody
          Id={props?.Id}
          name={props?.name}
          content={props.content}
          saveItem={(newItem) => props.saveItem(newItem)}
          manufacturer_and_company_id={props.manufacturer_and_company_id}
          onDataSave={(data) => {
            props.onDataSave(data);
          }}
          aboutSectionType={props?.aboutSectionType ?? ""}
          siteDetails={props?.siteDetails ?? ""}
          tradeAboutSection={props?.tradeAboutSection}
          multipleAddress={props?.multipleAddress ?? []}
          projectDetails={props?.projectDetails}
        />
      )
    ) : (
      <></>
    )
  );
}

function AboutSectionBody(props) {
  const itemSpacing = 2; // Vertical spacing between each item
  const iconSpacing = 2; // Horizontal spacing between icon and text
  const [addItemModalOpen, setAddItemModalOpen] = useState(false);
  const [openAddBasicDetailsModal, setOpenAddBasicDetailsModal] =
    useState(false);
  const [basicDetailsModalType, SetBasicDetailsModalType] = useState("");
  const [openSiteTypeModal, setOpenSiteTypeModal] = useState(false);
  const [openProjectTypeModal, setOpenProjectTypeModal] = useState(false);

  function handleWebRedirect(inputUrl) {
    try {
      let fullUrl = new URL(inputUrl);
      let baseUrl = `${fullUrl.hostname}`;
      window.open(`http://${baseUrl}`, "_blank");
    } catch (error) {
      window.open(`http://${inputUrl}`, "_blank");
    }
  }

  function AddItemModalPressed() {
    setAddItemModalOpen(true);
  }

  function renderItem(key) {
    switch (key) {
      case "projectTypeName":
        return (
          <Stack direction="row" spacing={iconSpacing}>
            <BriefcaseIcon color="primary" />
            <Typography>{props.content[key]}</Typography>
          </Stack>
        );

      case "siteType":
        return (
          <Stack direction="row" spacing={iconSpacing}>
            <ApartmentIcon style={{ color: BIBlue }} />
            <Typography>{props.content[key]}</Typography>
          </Stack>
        );

      case "projectTimeframe":
        return (
          <Stack direction="row" spacing={iconSpacing}>
            <TimeIcon color="primary" />
            <Typography>
              {props.content[key].startDate
                ? stringToLocale(removeISOTime(props.content[key].startDate))
                : "Unknown"}{" "}
              -{" "}
              {props.content[key].endDate
                ? stringToLocale(removeISOTime(props.content[key].endDate))
                : "Present"}
            </Typography>
          </Stack>
        );

      case "siteTimeframe":
        return (
          <>
            {(props.content[key].constructionStatusId != 0 ||
              props.content[key].startDate != null ||
              props.content[key].endDate != null) &&
              (props.content[key].startDate != "" ||
                props.content[key].endDate != "") ? (
              <Stack direction="row" spacing={iconSpacing}>
                <TimeIcon color="primary" />
                <Typography>
                  {props.content[key].constructionStatusId != 0
                    ? props.content[key].constructionStatusId == 1
                      ? "Completed"
                      : props.content[key].constructionStatusId == 2
                        ? "Under Construction"
                        : props.content[key].constructionStatusId == 3
                          ? "Inactive"
                          : ""
                    : null}
                  {props.content[key].constructionStatusId == 1 ||
                    props.content[key].constructionStatusId == 2 ||
                    props.content[key].constructionStatusId == 3
                    ? " "
                    : ""}
                  <Typography>
                    {moment(props.content[key].startDate).format("YYYY") ===
                      "Invalid date" &&
                      moment(props.content[key].endDate).format("YYYY") ===
                      "Invalid date" ? (
                      <Typography></Typography>
                    ) : (
                      <>
                        {moment(props.content[key].startDate).format("YYYY") !==
                          "Invalid date" &&
                          moment(props.content[key].endDate).format("YYYY") ===
                          "Invalid date" ? (
                          <Typography>
                            (
                            {`${moment(props.content[key].startDate).format(
                              "YYYY"
                            )} - `}
                            )
                          </Typography>
                        ) : null}
                        {moment(props.content[key].startDate).format("YYYY") ===
                          "Invalid date" &&
                          moment(props.content[key].endDate).format("YYYY") !==
                          "Invalid date" ? (
                          <Typography>
                            (
                            {`${moment(props.content[key].endDate).format(
                              "YYYY"
                            )}`}
                            )
                          </Typography>
                        ) : null}
                        {moment(props.content[key].startDate).format("YYYY") !==
                          "Invalid date" &&
                          moment(props.content[key].endDate).format("YYYY") !==
                          "Invalid date" ? (
                          moment(props.content[key].startDate).format(
                            "YYYY"
                          ) ===
                            moment(props.content[key].endDate).format("YYYY") ? (
                            <Typography>
                              (
                              {`${moment(props.content[key].endDate).format(
                                "YYYY"
                              )}`}
                              )
                            </Typography>
                          ) : (
                            <Typography>
                              (
                              {`${moment(props.content[key].startDate).format(
                                "YYYY"
                              )} - ${moment(props.content[key].endDate).format(
                                "YYYY"
                              )}`}
                              )
                            </Typography>
                          )
                        ) : null}
                      </>
                    )}
                  </Typography>

                  {/* {
									props.content[key].startDate
										? stringToLocale(removeISOTime(props.content[key].startDate))
										: "Unknown1"
								} - {
									props.content[key].endDate
										? stringToLocale(removeISOTime(props.content[key].endDate))
										: "Present"
								} */}
                </Typography>
              </Stack>
            ) : null}
          </>
        );

      case "projectTimeFrame":
        return (
          <>
            {props.content[key].startDate != null || props.content[key].endDate != null ? (
              <Stack direction="row" spacing={iconSpacing}>
                <TimeIcon color="primary" />
                <Typography>
                  {props.content[key].startDate != null || props.content[key].endDate != null ? (
                    <>
                      {
                        props.content[key].startDate && props.content[key].endDate ?
                          `${moment(props.content[key].startDate).format('MMM-YYYY')} - ${moment(props.content[key].endDate).format('MMM-YYYY')}` :
                          props.content[key].startDate ? moment(props.content[key].startDate).format('MMM-YYYY') :
                            null
                      }
                    </>
                  ) : null}
                </Typography>
              </Stack>
            ) : null}
          </>
        )

      case "formattedAddress":
        return (
          <>
            {props?.multipleAddress?.length > 0 ? (
              <>
                {props.multipleAddress.map((data, i) => (
                  <div key={i}>
                    <Stack
                      direction="row"
                      spacing={iconSpacing}
                      key={i}
                      style={i !== 0 ? { marginTop: "8px" } : {}}>
                      <MapMarkerIcon color="primary" />
                      <Stack direction="column" spacing={0}>
                        <Typography>
                          {data.address} {data.city ? "," : ""} {data.city}
                          {data.province ? "," : ""} {data.province}
                          {data.postalCode ? "," : ""}
                          {data.postalCode}
                          {data.country ? "," : ""} {data.country}
                        </Typography>
                      </Stack>
                    </Stack>
                  </div>
                ))}
                {props?.proxySite ? (
                  <></>
                ) : (
                  <div style={{ marginTop: "8px", textAlign: "end" }}>
                    <Button
                      color="primary"
                      onClick={() => {
                        setOpenAddBasicDetailsModal(true);
                        SetBasicDetailsModalType("address");
                      }}>
                      Add Another Address
                    </Button>
                  </div>
                )}
              </>
            ) : props.content[key]?.address ? (
              <>
                <Stack direction="row" spacing={iconSpacing}>
                  <MapMarkerIcon color="primary" />
                  <Stack direction="column" spacing={0}>
                    <Typography>
                      {props.content[key].address}
                      {props.content[key].city ? "," : ""}{" "}
                      {props.content[key].city}
                      {props.content[key].province ? "," : ""}{" "}
                      {props.content[key].province}
                      {props.content[key].postalCode ? "," : ""}
                      {props.content[key].postalCode}
                      {props.content[key].country ? "," : ""}{" "}
                      {props.content[key].country}
                    </Typography>
                  </Stack>
                </Stack>
              </>
            ) : (
              <>
                {props?.proxySite ? (
                  <></>
                ) : (
                  <>
                    {props?.aboutSectionType !== "site" ? (
                      <Stack direction="row" spacing={iconSpacing}>
                        <MapMarkerIcon color="primary" />
                        <Stack
                          direction="column"
                          spacing={0}
                          style={{ width: "100%" }}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              setOpenAddBasicDetailsModal(true);
                              SetBasicDetailsModalType("address");
                            }}
                            style={{ width: "100%" }}>
                            Add Address
                          </Button>
                        </Stack>
                      </Stack>
                    ) : (
                      <Stack direction="row" spacing={iconSpacing}>
                        <MapMarkerIcon color="primary" />
                        <Stack direction="column" spacing={0}>
                          <Typography>
                            {props?.content[key]?.address}
                            {props?.content[key]?.address &&
                              props?.content[key]?.city
                              ? ","
                              : ""}{" "}
                            {props?.content[key]?.city}
                            {props?.content[key]?.province ? "," : ""}{" "}
                            {props?.content[key]?.province}
                            {props?.content[key]?.postalCode ? "," : ""}
                            {props?.content[key]?.postalCode}
                            {props?.content[key]?.country ? "," : ""}{" "}
                            {props?.content[key]?.country}
                          </Typography>
                        </Stack>
                      </Stack>
                    )}
                  </>
                )}
              </>
            )}
          </>
        );

      case "website":
        return (
          <Stack direction="row" spacing={iconSpacing}>
            <GlobeIcon color="primary" />
            <span
              style={{
                color: BIBlue,
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => handleWebRedirect(props.content[key])}>
              {extractBaseURL(props.content[key])}
            </span>
          </Stack>
        );

      case "manufacturer":
        return (
          <Stack direction="row" spacing={iconSpacing}>
            <BriefcaseIcon color="primary" />
            <Typography>{props.content[key]}</Typography>
          </Stack>
        );

      case "description":
        return (
          // pre-wrap to preserve linebreaks
          <div style={{
            width: '350px'
          }}>
            <Typography
              style={{
                whiteSpace: "pre-wrap",
                marginLeft: props?.from === "TradeProxyPage" && "5px",
              }}>
              {props.content[key]}
            </Typography>
          </div>
        );

      case "phone":
        return (
          <>
            <Stack direction="row" spacing={iconSpacing}>
              <PhoneIcon color="primary" />
              <Typography>{props.content[key]}</Typography>
            </Stack>
          </>
        );

      case "address":
        return (
          <Stack direction="row" spacing={iconSpacing}>
            <MapMarkerIcon color="primary" />
            <Typography>{props.content[key]}</Typography>
          </Stack>
        );

      case "manufacturerName":
        return (
          <>
            <Stack direction="row" spacing={iconSpacing}>
              <BriefcaseIcon color="primary" />
              <Typography>{props.content[key]}</Typography>
            </Stack>
          </>
        );
      case "info":
        return (
          <>
            <Stack
              direction="row"
              style={{ marginLeft: "40px" }}
              spacing={iconSpacing}>
              <Typography>{props.content[key]}</Typography>
            </Stack>
          </>
        );

      case "description":
        return (
          <>
            <Stack
              direction="row"
              style={{ marginLeft: "10px" }}
              spacing={iconSpacing}>
              <Typography>{props.content[key]}</Typography>
            </Stack>
          </>
        );

      default:
        return null;
    }
  }

  function renderButtons(key) {
    switch (key) {
      case "projectTypeName":
        return (
          <>
            <Stack direction="row" alignItems={"center"} spacing={iconSpacing}>
              <BriefcaseIcon color="primary" />
              <Button
                variant="outlined"
                color="primary"
                style={{ width: "100%" }}
                onClick={() => {
                  setOpenProjectTypeModal(true);
                }}
              >
                Add Project Type
              </Button>
            </Stack>
          </>
        );
      case "siteType":
        return (
          <Stack direction="row" alignItems={"center"} spacing={iconSpacing}>
            <ApartmentIcon style={{ color: BIBlue }} />
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setOpenSiteTypeModal(true);
              }}
              style={{ width: "100%" }}>
              Add Site Type
            </Button>
          </Stack>
        );

      case "projectTimeframe":
        return (
          <Stack direction="row" alignItems={"center"} spacing={iconSpacing}>
            <TimeIcon color="primary" />
            <Button
              variant="outlined"
              color="primary"
              style={{ width: "100%" }}>
              Add projectType
            </Button>
          </Stack>
        );

      case "formattedAddress":
        return (
          <>
            <Stack direction="row" alignItems={"center"} spacing={iconSpacing}>
              <MapMarkerIcon color="primary" />
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setOpenAddBasicDetailsModal(true);
                  SetBasicDetailsModalType("address");
                }}
                style={{ width: "100%" }}>
                Add Address
              </Button>
            </Stack>
          </>
        );

      case "website":
        return (
          <Stack direction="row" alignItems={"center"} spacing={iconSpacing}>
            <GlobeIcon color="primary" />
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setOpenAddBasicDetailsModal(true);
                SetBasicDetailsModalType("website");
              }}
              style={{ width: "100%" }}>
              Add website
            </Button>
          </Stack>
        );

      case "manufacturer":
        return (
          <Stack direction="row" alignItems={"center"} spacing={iconSpacing}>
            <BriefcaseIcon color="primary" />
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                AddItemModalPressed();
              }}
              style={{ width: "100%" }}>
              Add Manufacture
            </Button>
          </Stack>
        );

      case "description":
        return (
          // pre-wrap to preserve linebreaks
          <>
            {!props?.tradeAboutSection && !props?.proxySite ? (
              <Stack
                direction="row"
                alignItems={"center"}
                spacing={iconSpacing}
                style={{ width: "100%" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setOpenAddBasicDetailsModal(true);
                    SetBasicDetailsModalType("description");
                  }}
                  style={{ width: "100%" }}>
                  Add description
                </Button>
              </Stack>
            ) : null}
          </>
        );

      case "phone":
        return (
          <Stack direction="row" alignItems={"center"} spacing={iconSpacing}>
            <PhoneIcon color="primary" />
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setOpenAddBasicDetailsModal(true);
                SetBasicDetailsModalType("phone");
              }}
              style={{ width: "100%" }}>
              Add phone
            </Button>
          </Stack>
        );

      case "address":
        return (
          <Stack direction="row" alignItems={"center"} spacing={iconSpacing}>
            <MapMarkerIcon color="primary" />
            <Button
              variant="outlined"
              color="primary"
              style={{ width: "100%" }}>
              Add Address
            </Button>
          </Stack>
        );

      default:
        return <></>;
    }
  }

  return (
    <>
      <Stack spacing={itemSpacing}>
        {Object.keys(props?.content).map((key, index) => {
          return (
            //  Only render item if the content item isn't empty
            props.content[key] ? (
              <div key={index}>{renderItem(key)}</div>
            ) : (
              !props.proxySite && <div key={index}>{renderButtons(key)}</div>
            )
          );
        })}

        {
          props?.aboutSectionType === "project" &&
          (
            <Stack>
              <Stack direction="row" spacing={iconSpacing}>
                <LocationOnIcon style={{ color: BIBlue }} />
                <Stack>
                  <Typography style={{ fontWeight: 'bold' }}>{props?.projectDetails?.siteName ?? ""}</Typography>
                  <div>
                    {props?.projectDetails?.address == '' || props?.projectDetails?.address == null ?
                      <div>
                        {props?.projectDetails?.latitude && props?.projectDetails?.latitude != 0 && props?.projectDetails?.longitude && props?.projectDetails?.longitude != 0 ?
                          <Typography style={{ color: BIGrey }}>
                            {`Latitude: ${props?.projectDetails?.latitude}, Longitude: ${props?.projectDetails?.longitude}`}
                          </Typography> : null
                        }
                      </div>
                      :
                      <div>
                        <Typography style={{ color: BIGrey }}>
                          {props?.projectDetails?.address.trim() + ", "}
                          {props?.projectDetails?.city.trim() + ", "}{props?.projectDetails?.province.trim() + ", "}
                          {props?.projectDetails?.postalCode.trim() + ", "}{props?.projectDetails?.country.trim()}
                        </Typography>
                      </div>
                    }
                  </div>
                </Stack>
              </Stack>

              <Stack style={{ marginTop: '10px' }}>
                <div className="chooseopt-card location-card">
                  <GoogleMap
                    mapContainerClassName="site-map-container"
                    mapContainerStyle={containerStyle}
                    center={{ lat: props?.projectDetails?.latitude ?? 0, lng: props?.projectDetails?.longitude ?? 0 }}
                    zoom={13}
                    options={{
                      fullscreenControl: false,
                      zoomControl: false,
                      streetViewControl: false,
                      scrollwheel: false,
                      fullscreenControlOptions: false,
                      mapTypeControl: false,
                    }}
                    onDrag={false}
                  >
                    <Marker
                      position={{ lat: props?.projectDetails?.latitude ?? 0, lng: props?.projectDetails?.longitude ?? 0 }}
                      icon={{
                        url: mapMarkerYellow,
                        scaledSize: new window.google.maps.Size(30, 40),
                      }}
                    />
                  </GoogleMap>
                </div>
              </Stack>

            </Stack>
          )
        }
      </Stack>

      <AddItemModal
        open={addItemModalOpen}
        onClose={() => setAddItemModalOpen(false)}
        saveItem={(newItem) => props.saveItem(newItem)}
      />
      <AddBasicDetailsModal
        open={openAddBasicDetailsModal}
        onClose={() => {
          setOpenAddBasicDetailsModal(false);
        }}
        type={basicDetailsModalType}
        content={props?.content}
        onDataSave={(data) => {
          setOpenAddBasicDetailsModal(false);
          props.onDataSave(data);
        }}
        Id={props?.Id}
        name={props?.name}
        aboutSectionType={props?.aboutSectionType ?? ""}
        productDetails={props.productDetails}
        siteDetails={props?.siteDetails}
        addressList={props?.multipleAddress ?? []}
        projectDetails={props?.projectDetails ?? {}}
      />
      <AddSiteTypeModal
        open={openSiteTypeModal}
        onClose={() => {
          setOpenSiteTypeModal(false);
        }}
        siteDetails={props?.siteDetails}
        proxySite={props?.proxySite}
        onDataSave={() => {
          props.onDataSave();
        }}
      // siteDetails={props?.siteDetails}
      // onSiteTypeSaveSuccessfully={onSiteTypeSaveSuccessfully}
      />
      <AddProjectTypeModal
        open={openProjectTypeModal}
        onClose={() => {
          setOpenProjectTypeModal(false);
        }}
        projectDetails={props?.projectDetails}
        proxySite={props?.proxySite}
        onDataSave={() => {
          props.onDataSave();
        }}
      />
    </>
  );
}

function AddItemModal(props) {
  const [inputValue, setInputValue] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [submit, setSubmit] = useState(false);

  function onCloseClick() {
    setFilterData([]);
    setInputValue("");
    setSubmit(false);
    props.onClose();
  }

  function onTextChange(text) {
    setInputValue(text != "" ? text : "");
    getFilterData(text);
  }

  function removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  const getFilterData = async (text) => {
    try {
      if (text.length <= 1) {
        return setFilterData([]);
      }
      const res = await fetchCompanies({
        IncludeRecordNr: true,
        NrOfRecPerPage: 0,
        SearchList: [{ name: text, manufacturer: true }],
        SortList: [{ FieldName: "Name", Direction: "ASC" }],
      });
      let a;
      res.data.map((x) => {
        a = x;
        a["title"] = x.name;
        return a;
      });
      const response = await removeDuplicates(res.data, "name");
      return setFilterData(response);
    } catch (error) {
      console.log("Error while getting data::", error);
    }
  };

  const showNewValue = (value) => {
    const searchData = filterData.filter(
      (i) => i?.name?.toUpperCase()?.trim() === value.toUpperCase()?.trim()
    );
    return searchData?.length === 0 ? true : false;
  };

  async function handleAddExistingItem(params) {
    let newItem = { manufacturerId: params?.id ?? 0 };
    await props.saveItem(newItem);
    onCloseClick();
  }

  async function handleAddNewItem() {
    let newItem = {
      manufacturerName: inputValue,
      manufacturerId: 0,
    };
    await props.saveItem(newItem);
    onCloseClick();
  }

  return (
    <Dialog open={props.open} onClose={onCloseClick} fullWidth maxWidth="sm">
      <DialogTitle>Search</DialogTitle>
      <Stack spacing={2} padding={"0px 40px"}>
        <ProductSearchField
          value={inputValue}
          onChange={(e) => onTextChange(e)}
          isFocused={() => { }}
          placeholder={"Select manufacturer"}
        />
      </Stack>
      <DialogContent style={{ paddingTop: 0 }}>
        <Stack spacing={0}>
          <>
            {inputValue?.trim().length > 1 && showNewValue(inputValue) ? (
              <div>
                <ListItem
                  secondaryAction={
                    <div style={{ display: "flex" }}>
                      {!submit ? (
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={() => handleAddNewItem()}>
                          Add
                        </Button>
                      ) : (
                        <Button variant="contained" size="small">
                          {/* <CircularProgress size={24} style={{ color: 'white' }} /> */}
                        </Button>
                      )}
                    </div>
                  }
                  disablePadding>
                  <ListItemButton
                    sx={{ display: "flex", flexDirection: "row" }}>
                    <ListItemText primary={inputValue} />
                  </ListItemButton>
                </ListItem>
              </div>
            ) : (
              <></>
            )}
            {inputValue != "" ? (
              <div>
                {filterData.length != 0 ? (
                  <ListSubheader>Existing Manufacturers</ListSubheader>
                ) : (
                  <></>
                )}
                {filterData.length != 0 ? (
                  <>
                    {filterData.map((item, index) => {
                      return (
                        <div key={index.toString()}>
                          <ListItem
                            key={index.toString()}
                            secondaryAction={
                              <div style={{ display: "flex" }}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  onClick={() => {
                                    handleAddExistingItem(item);
                                  }}>
                                  Add
                                </Button>
                              </div>
                            }
                            disablePadding>
                            <ListItemButton
                              sx={{ display: "flex", flexDirection: "row" }}>
                              <ListItemText primary={item?.name} />
                            </ListItemButton>
                          </ListItem>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

const AddTradesModal = ({
  open,
  onClose,
  alreadyTradesSelected,
  manufacturer_and_company_id,
  onTradesSaveSuccessfully,
  aboutSectionType,
  productId,
  proxySite,
}) => {
  const [allTradesList, setAllTradesList] = useState([]);
  const [selectedTrades, setSelectedTrades] = useState([]);

  useEffect(async () => {
    {
      !proxySite &&
        fetchTrades({})
          .then((res) => {
            setAllTradesList(res?.data ?? []);
          })
          .catch((err) => {
            console.log("Error while getting trades list", err);
          });
    }
  }, []);

  const handleCheckboxChange = (event, item) => {
    if (selectedTrades.some((t) => t?.id === item?.id)) {
      setSelectedTrades(selectedTrades.filter((t) => t.id !== item.id));
    } else {
      setSelectedTrades([...selectedTrades, item]);
    }
  };

  const onSaveTrades = async () => {
    try {
      if (selectedTrades.length == 0) {
        return handleClose();
      }
      //checking where to save trades to product or manufacture/company
      if (aboutSectionType == "product") {
        const SaveList = selectedTrades.map((item) => {
          return {
            Id: 0,
            ProductId: productId,
            TradeId: item.id,
            ModifiedBy: Number(localStorage.userId),
            RecordStatusId: 2,
          };
        });
        const saveObject = {
          userId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList,
        };
        await saveProductTrades(saveObject)
          .then((res) => {
            onTradesSaveSuccessfully();
            handleClose();
          })
          .catch((err) => {
            console.log("error while saving trades:-", err);
          });
      } else {
        const SaveList = selectedTrades.map((item) => {
          return {
            Id: 0,
            CompanyId: manufacturer_and_company_id,
            TradeId: item.id,
            ModifiedBy: Number(localStorage.userId),
          };
        });
        const saveObject = {
          userId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList,
        };
        await saveCompanyTrades(saveObject)
          .then((res) => {
            onTradesSaveSuccessfully();
            handleClose();
          })
          .catch((err) => {
            console.log("error while saving trades:-", err);
          });
      }
    } catch (err) {
      console.log("error while saving trades:-", err);
    }
  };

  const handleClose = () => {
    setSelectedTrades([]);
    onClose();
  };
  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Select Trade
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
          {allTradesList.map((item, index) => (
            <div key={index}>
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={
                      alreadyTradesSelected.some(
                        (selectedItem) => selectedItem?.tradeId === item?.id
                      ) || selectedTrades.some((t) => t?.id === item?.id)
                    }
                    onChange={(event) => handleCheckboxChange(event, item)}
                    disabled={alreadyTradesSelected.some(
                      (selectedItem) => selectedItem?.tradeId === item?.id
                    )}
                  />
                }
                label={
                  <span
                    style={{
                      color: alreadyTradesSelected.some(
                        (selectedItem) => selectedItem?.tradeId === item?.id
                      )
                        ? "grey"
                        : "black",
                    }}>
                    {item?.name ?? ""}
                  </span>
                }
              />
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button
          onClick={onSaveTrades}
          disabled={selectedTrades.length == 0}
          color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddBasicDetailsModal = ({
  open,
  onClose,
  type,
  content,
  onDataSave,
  Id,
  name,
  aboutSectionType,
  productDetails,
  siteDetails,
  addressList,
  projectDetails
}) => {
  const [website, setWebsite] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const countries = ["Canada", "United Kingdom", "United States"]; // Add more countries as needed
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [apartment, setApartment] = useState("");
  const [province, setProvince] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [postalCode, setPostalCode] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [phoneCode, setPhoneCode] = useState("+1");
  const [description, setDescription] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);

  useEffect(async () => {
    if (country !== "") {
      const obj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        NrOfRecPerPage: 50,
        SearchList: [
          { Country: country === "United States" ? "USA" : country },
        ],
      };
      await getProvinceByCountryName(obj)
        .then((res) => {
          setProvinceList(res);
        })
        .catch((err) => {
          console.log("err while getting province:-", err);
        });
    }
  }, [country]);

  useEffect(() => {
    if (type == "address") {
      if (address == "" || country == "" || city == "" || postalCode == "") {
        setButtonDisable(true);
      } else {
        if (country == "United Kingdom") {
          setButtonDisable(false);
        } else {
          if (province == "") {
            setButtonDisable(true);
          } else {
            setButtonDisable(false);
          }
        }
      }
    } else if (type == "website") {
      if (website?.trim().length > 0) {
        setButtonDisable(false);
      } else {
        setButtonDisable(true);
      }
    } else if (type == "phone") {
      phoneNumber?.replace(/[^a-zA-Z0-9]/g, "").length == 10
        ? setButtonDisable(false)
        : setButtonDisable(true);
    } else if (type == "description") {
      description != "" ? setButtonDisable(false) : setButtonDisable(true);
    } else {
      setButtonDisable(true);
    }
  }, [
    phoneNumber,
    website,
    address,
    city,
    country,
    province,
    postalCode,
    description,
  ]);

  const checkDuplicateAddress = () => {
    return new Promise((resolve) => {
      const isDuplicate = addressList.some((item) => {
        return (
          item.address.toLowerCase().localeCompare(address.toLowerCase()) === 0
        );
      });
      resolve(isDuplicate);
    });
  };

  const handleClose = () => {
    setWebsite("");
    setPhoneNumber("");
    setCountry("");
    setAddress("");
    setCity("");
    setProvince("");
    setPostalCode("");
    setDescription("");
    setApartment("");
    onClose();
  };

  const validateURL = (url) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    const wwwUrlRegex = /^(www)\.[^ "]+$/;
    if (
      !urlRegex.test(url.trim()) &&
      !wwwUrlRegex.test(url.trim()) &&
      url !== ""
    ) {
      return false;
    }
    return true;
  };

  const onSaveData = async (e) => {
    e.preventDefault();
    if (type == "website") {
      try {
        const isValidUrl = validateURL(website);
        if (isValidUrl) {
          setIsValid(true);
          if (aboutSectionType == "product") {
            const saveObject = {
              userId: Number(localStorage.userId),
              ReturnRecordId: true,
              ReturnRecordError: true,
              SaveList: [
                {
                  Id: productDetails?.Id,
                  Name: productDetails?.Name,
                  Info: productDetails?.Info,
                  webSite: website, //<-- website
                  RecordStatusId: 1,
                  ModifiedBy: Number(localStorage.userId),
                  ManufacturerId: productDetails?.ManufacturerId,
                },
              ],
            };
            await saveProducts(saveObject)
              .then((res) => {
                onDataSave();
              })
              .catch((err) => {
                console.log("server error while saving product website", err);
              });
          } else if (aboutSectionType == "site") {
            const saveObject = {
              UserId: Number(localStorage.userId),
              ReturnRecordId: true,
              ReturnRecordError: true,
              SaveList: [
                {
                  Id: siteDetails?.id,
                  Name: siteDetails?.name,
                  Address: siteDetails?.address,
                  City: siteDetails?.city,
                  Province: siteDetails?.province,
                  PostalCode: siteDetails?.postalCode,
                  Country: siteDetails?.country,
                  Latitude: siteDetails?.latitude,
                  Longitude: siteDetails?.longitude,
                  StartDate: siteDetails?.startDate,
                  EndDate: siteDetails?.endDate,
                  Active: siteDetails?.active,
                  BuildingTypeId: siteDetails?.buildingTypeId,
                  RecordStatusId: siteDetails?.recordStatusId,
                  ContractorId: siteDetails?.contractorId,
                  JobNo: siteDetails?.jobNo,
                  ConstructionStatusId: siteDetails?.constructionStatusId,
                  WebSite: website, // <--- WEBSITE
                  Description: siteDetails?.description, // <--- DESCRIPTION
                  ModifiedBy: Number(localStorage.userId),
                },
              ],
            };
            await saveSitesDetails(saveObject)
              .then((res) => {
                onDataSave();
              })
              .catch((err) => {
                console.log("server error while saving site webiste", err);
              });
          } else if (aboutSectionType == "project") {
            const projObj = {
              UserId: Number(localStorage.userId),
              ReturnRecordId: true,
              ReturnRecordError: true,
              SaveList: [
                {
                  Id: projectDetails?.id,
                  UserId: Number(localStorage.userId),
                  SiteId: projectDetails?.siteId,
                  projectName: projectDetails.projectName,
                  ProjectType: projectDetails?.projectType,
                  ProjectWebsite: website,
                  Description: projectDetails?.description,
                  IsActive: projectDetails?.isActive,
                  RecordStatusId: 1,
                  ModifiedBy: Number(localStorage.userId),
                }
              ]
            }
            await saveUserSitesProjects(projObj).then((res) => {
              onDataSave();
            }).catch((err) => {
              console.log("server error while saving description in project :: ", err);
            });
          } else {
            const saveObject = {
              userId: Number(localStorage.userId),
              ReturnRecordId: true,
              ReturnRecordError: true,
              SaveList: [
                {
                  Id: Id,
                  Name: name,
                  webSite: website, //<-- website
                  phone: content?.phone,
                  ModifiedBy: Number(localStorage.userId),
                  RecordStatusId: 1,
                  Manufacturer: true,
                },
              ],
            };

            await saveCompanies2(saveObject)
              .then((res) => {
                onDataSave();
              })
              .catch((err) => {
                console.log("server error while saving webiste", err);
              });
          }
        } else {
          return setIsValid(false);
        }
      } catch (error) {
        console.log("server error while saving webiste", error);
      }
    } else if (type == "phone") {
      try {
        const saveObject = {
          userId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              Id: Id,
              Name: name,
              webSite: content?.website ?? "",
              phone: phoneNumber,
              ModifiedBy: Number(localStorage.userId),
              RecordStatusId: 1,
              Manufacturer: true,
            },
          ],
        };
        await saveCompanies2(saveObject)
          .then((res) => {
            onDataSave();
          })
          .catch((err) => {
            console.log("server error while phone number", err);
          });
      } catch (error) {
        console.log("server error while saving phone number", error);
      }
    } else if (type == "address") {
      try {
        const isDuplicate = await checkDuplicateAddress();
        if (isDuplicate) {
          return alert("This address has already been added.");
        }
        const saveObject = {
          userId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              Id: 0,
              Type: "Main",
              CompanyId: Id,
              Address: address,
              City: city,
              Province: province,
              PostalCode: postalCode,
              Country: country,
              ModifiedBy: Number(localStorage.userId),
              RecordStatusId: 1,
              Manufacturer: aboutSectionType === "company" ? false : true,
            },
          ],
        };
        await saveCompanyAddress(saveObject)
          .then((res) => {
            setCountry("");
            setAddress("");
            setCity("");
            setProvince("");
            setPostalCode("");
            setApartment("");
            onDataSave({ addressSave: true });
          })
          .catch((err) => {
            console.log("server error while saving address", err);
          });
      } catch (error) {
        console.log("server error while saving address", error);
      }
    } else if (type == "description") {

      if (aboutSectionType === "project") {
        const projObj = {
          UserId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              Id: projectDetails?.id,
              UserId: Number(localStorage.userId),
              SiteId: projectDetails?.siteId,
              projectName: projectDetails.projectName,
              ProjectType: projectDetails?.projectType,
              ProjectWebsite: projectDetails?.projectWebsite,
              Description: description,
              IsActive: projectDetails?.isActive,
              RecordStatusId: 1,
              ModifiedBy: Number(localStorage.userId),
            }
          ]
        }
        await saveUserSitesProjects(projObj).then((res) => {
          onDataSave();
        })
          .catch((err) => {
            console.log("server error while saving description in project :: ", err);
          });
      } else {
        const saveObject = {
          userId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              Id: productDetails?.Id,
              Name: productDetails?.Name,
              Info: description,
              webSite: productDetails?.webSite,
              RecordStatusId: 1,
              ModifiedBy: Number(localStorage.userId),
              ManufacturerId: productDetails?.ManufacturerId,
            },
          ],
        };
        await saveProducts(saveObject)
          .then((res) => {
            onDataSave();
          })
          .catch((err) => {
            console.log("server error while saving address", err);
          });
      }
    } else {
      return null;
    }
  };

  async function handleSearch() {
    let place = autocomplete.getPlace();
    if (place?.address_components) {
      const street =
        place?.address_components.find((address) =>
          address.types.includes("street_number")
        )?.long_name ?? "";
      const route =
        place?.address_components.find((address) =>
          address.types.includes("route")
        )?.long_name ?? "";
      setCity(
        place?.address_components.find((address) =>
          address.types.includes("locality")
        )?.long_name ?? ""
      );
      setProvince(
        place?.address_components.find((address) =>
          address.types.includes("administrative_area_level_1")
        )?.long_name ?? ""
      );
      setCountry(
        place?.address_components.find((address) =>
          address.types.includes("country")
        )?.long_name ?? ""
      );
      setAddress(place?.street != "" ? street + " " + route : route);
      setPostalCode(
        place?.address_components.find((address) =>
          address.types.includes("postal_code")
        )?.long_name ?? ""
      );
    }
  }

  const getComponentRestrictions = () => {
    if (
      country?.toLowerCase() == "usa" ||
      country?.toLowerCase() == "united states"
    ) {
      return { country: ["US"] };
    } else if (
      country?.toLowerCase() == "uk" ||
      country?.toLowerCase() == "united kingdom"
    ) {
      return { country: ["GB"] };
    } else {
      return { country: ["CA"] };
    }
  };

  const renderAddressFrom = () => {
    return (
      <>
        <div className="input-container">
          <FormControl variant="outlined" fullWidth>
            <TextField
              select
              label="Select Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}>
              {countries.map((c, i) => (
                <MenuItem key={i} value={c}>
                  {c}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </div>
        <div className="input-container">
          <GooglePlacesAutocomplete
            onLoad={(newInstance) => {
              setAutocomplete(newInstance);
            }} // Sets instance of Autocomplete to be referenced
            onPlaceChanged={handleSearch}
            options={{
              // componentRestrictions:{ country: ['US', 'CA', 'GB'] }, // Restrict to USA, Canada, and UK
              componentRestrictions: getComponentRestrictions(),
            }}>
            <TextField
              variant="outlined"
              placeholder="Address"
              fullWidth
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      // Only show "X" button when field not empty
                      address !== "" ? (
                        <IconButton
                          onClick={() => {
                            setCountry("");
                            setAddress("");
                            setCity("");
                            setProvince("");
                            setPostalCode("");
                            setApartment("");
                          }}>
                          <CloseIcon />
                        </IconButton>
                      ) : (
                        <></>
                      )
                    }
                  </InputAdornment>
                ),
              }}
            />
          </GooglePlacesAutocomplete>
        </div>

        <div className="input-container">
          <TextField
            label="Apartment"
            variant="outlined"
            fullWidth
            value={apartment}
            onChange={(e) => setApartment(e.target.value)}
          />
        </div>
        <div className="input-container">
          <TextField
            label="City"
            variant="outlined"
            fullWidth
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        {country !== "United Kingdom" ? (
          <div className="input-container">
            <FormControl variant="outlined" fullWidth>
              <TextField
                select
                label="Province"
                value={province}
                onChange={(e) => setProvince(e.target.value)}>
                {provinceList.map((p, i) => (
                  <MenuItem key={i} value={p.province}>
                    {p.province}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
        ) : (
          <> </>
        )}

        <div className="input-container">
          <TextField
            label={country !== "United States" ? "Postal Code" : "State"}
            variant="outlined"
            fullWidth
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </div>
      </>
    );
  };

  const renderPhoneFrom = () => {
    return (
      <>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="phone-code">Phone Code</InputLabel>
                <Select
                  label="Phone Code"
                  id="phone-code"
                  value={phoneCode}
                  onChange={(e) => {
                    setPhoneCode(e.target.value);
                  }}>
                  <MenuItem value="+1">+1</MenuItem>
                  <MenuItem value="+91">+91</MenuItem>
                  <MenuItem value="+44">+44</MenuItem>
                  {/* Add more phone codes as needed */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <InputMask
                mask="(999) 999 - 9999"
                alwaysShowMask={true}
                value={phoneNumber}
                onChange={(event) => {
                  setPhoneNumber(event.target.value);
                }}
                disabled={false}
                maskChar=" ">
                {() => (
                  <TextField
                    id={"phone-text-field"}
                    label="Phone Number"
                    placeholder="(123) 456-7890"
                    variant="outlined"
                    fullWidth
                  />
                )}
              </InputMask>
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  const renderWebisteFrom = () => {
    return (
      <>
        <div className="input-container">
          <TextField
            autoFocus
            variant="outlined"
            label="Webiste"
            fullWidth
            defaultValue={website}
            onChange={(e) => {
              setWebsite(e.target.value);
              setIsValid(true);
            }}
            error={!isValid}
            helperText={!isValid ? "Invalid URL" : ""}
            onKeyPress={(event) => {
              if (event.key === "Enter") onSaveData(event);
            }}
          />
        </div>
      </>
    );
  };

  const renderDescriptionFrom = () => {
    return (
      <>
        <div className="input-container">
          <TextField
            id="outlined-multiline-static"
            multiline
            minRows={4}
            autoFocus
            variant="outlined"
            label="Description"
            fullWidth
            defaultValue={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") onSaveData(event);
            }}
          />
        </div>
      </>
    );
  };

  const renderConentBasedOnType = () => {
    if (type == "address") {
      return renderAddressFrom();
    } else if (type == "website") {
      return renderWebisteFrom();
    } else if (type == "phone") {
      return renderPhoneFrom();
    } else if (type == "description") {
      return renderDescriptionFrom();
    } else {
      return <></>;
    }
  };

  const getTitleByType = () => {
    if (type == "address") {
      return "Add Address";
    } else if (type == "website") {
      return "Add Webiste";
    } else if (type == "phone") {
      return "Add Phone Number";
    } else if (type == "description") {
      return "Add Description";
    } else {
      return "";
    }
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {getTitleByType()}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>{renderConentBasedOnType()}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={onSaveData} disabled={buttonDisable} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AddSiteTypeModal = ({
  open,
  onClose,
  siteDetails,
  onDataSave,
  proxySite,
}) => {
  const [allSiteTypeList, setAllSiteList] = useState([]);
  const [selectedTypeId, setSelectedTypeId] = useState("");

  useEffect(async () => {
    {
      !proxySite &&
        fetchSiteTypes({
          SortList: [
            {
              FieldName: "name",
              Direction: "ASC",
            },
          ],
        })
          .then((res) => {
            setAllSiteList(res?.data ?? []);
          })
          .catch((err) => {
            console.log("Error while getting site type list", err);
          });
    }
  }, [open]);

  if (!open) {
    return false;
  }

  const onSaveTrades = async () => {
    try {
      const saveObject = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: siteDetails?.id,
            Name: siteDetails?.name,
            Address: siteDetails?.address,
            City: siteDetails?.city,
            Province: siteDetails?.province,
            PostalCode: siteDetails?.postalCode,
            Country: siteDetails?.country,
            Latitude: siteDetails?.latitude,
            Longitude: siteDetails?.longitude,
            StartDate: siteDetails?.startDate,
            EndDate: siteDetails?.endDate,
            Active: siteDetails?.active,
            BuildingTypeId: selectedTypeId,
            RecordStatusId: siteDetails?.recordStatusId,
            ContractorId: siteDetails?.contractorId,
            JobNo: siteDetails?.jobNo,
            ConstructionStatusId: siteDetails?.constructionStatusId,
            WebSite: siteDetails?.webSite,
            Description: siteDetails?.description,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      await saveSitesDetails(saveObject)
        .then((res) => {
          onDataSave();
          onClose();
        })
        .catch((err) => {
          console.log("server error while saving site webiste", err);
        });
    } catch (err) {
      console.log("error while saving trades:-", err);
    }
  };

  const handleClose = () => {
    setSelectedTypeId("");
    onClose();
  };

  const handleTypeChange = (event) => {
    setSelectedTypeId(event.target.value);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Choose Site Type
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
          {allSiteTypeList.map((item, index) => (
            <div key={index}>
              <FormControlLabel
                value={item.id}
                control={
                  <Radio
                    checked={selectedTypeId == item.id}
                    onChange={handleTypeChange}
                    value={item.id}
                    name="site-type-radio"
                    style={{
                      color: "#1976d2",
                    }}
                  />
                }
                label={item.name}
              />
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button
          onClick={onSaveTrades}
          disabled={selectedTypeId == ""}
          color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const AddProjectTypeModal = ({
  open,
  onClose,
  projectDetails,
  onDataSave,
  proxySite,
}) => {
  const [allTypeList, setAlltypeList] = useState([]);
  const [selectedTypeId, setSelectedTypeId] = useState("");

  useEffect(async () => {
    {
      !proxySite &&
        fetchSiteTypes({
          SortList: [
            {
              FieldName: "name",
              Direction: "ASC",
            },
          ],
        })
          .then((res) => {
            setAlltypeList(res?.data ?? []);
          })
          .catch((err) => {
            console.log("Error while getting site type list", err);
          });
    }
  }, [open]);

  if (!open) {
    return false;
  }

  const onSave = async () => {
    try {
      const projObj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: projectDetails?.id,
            UserId: Number(localStorage.userId),
            SiteId: projectDetails?.siteId,
            projectName: projectDetails?.projectName,
            ProjectType: selectedTypeId,
            ProjectWebsite: projectDetails?.projectWebsite,
            Description: projectDetails?.description,
            IsActive: projectDetails?.isActive,
            RecordStatusId: 1,
            ModifiedBy: Number(localStorage.userId),
          }
        ]
      }
      await saveUserSitesProjects(projObj)
        .then((res) => {
          onDataSave();
          onClose();
        })
        .catch((err) => {
          console.log("server error while saving project type :: ", err);
        });
    } catch (err) {
      console.log("error while saving trades:-", err);
    }
  };

  const handleClose = () => {
    setSelectedTypeId("");
    onClose();
  };

  const handleTypeChange = (event) => {
    setSelectedTypeId(event.target.value);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Choose Project Type
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
          {allTypeList.map((item, index) => (
            <div key={index}>
              <FormControlLabel
                value={item.id}
                control={
                  <Radio
                    checked={selectedTypeId == item.id}
                    onChange={handleTypeChange}
                    value={item.id}
                    name="site-type-radio"
                    style={{
                      color: "#1976d2",
                    }}
                  />
                }
                label={item.name}
              />
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button
          onClick={onSave}
          disabled={selectedTypeId == ""}
          color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};