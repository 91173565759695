import React, { useState, useEffect, useCallback } from "react";

import { getUserRewardAmount } from "../api/UserApi";
import { Help as HelpIcon } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

import { HelpModal } from "../pages/rewards/HelpModal";

function BuilderBucksBalance(props) {
	const [builderBucksBalance, setBuilderBucksBalance] = useState(0);

	const [modalOpen, setModalOpen] = useState(false);


	const updateBuilderBucksBalance = useCallback(() => {
		getUserRewardAmount(localStorage.userId)
			.then(res => {
				if (res) setBuilderBucksBalance(res.bucks)
				else console.log("Error: No BuilderBucks balance found")
			});
	}, [])

	function handleModalOpen() {
		setModalOpen(true);
	}

	function handleModalOpenHelp() {
		handleModalOpen();
	}

	function handleModalClose() {
		setModalOpen(false);
	}

	// On page load, retrieve store from API
	useEffect(() => {
		updateBuilderBucksBalance();
	}, [updateBuilderBucksBalance])

	return (
		<>
			{/* <HelpModal
				config={props.config}
				modalOpen={modalOpen}
				onModalClose={handleModalClose}/> */}

			<div className="store-header">
				<div className="flex-container-horizontal" style={{ alignItems: "center" }}>
					<div style={{ flex: 1 }}>
						<p>Your Balance</p>
						<h1><span id="buckBalance">{builderBucksBalance}</span> <span> BuilderBucks</span></h1>
					</div>

					{/* <div className="flex-container-horizontal rewards-help-icon-container">
						<IconButton

							onClick={handleModalOpenHelp} >
							<HelpIcon style={{ fill: "white" }} />
						</IconButton>
					</div> */}
				</div>
			</div>
		</>
	)
}

export default BuilderBucksBalance;