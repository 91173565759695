import React, { useState } from "react";

import { IconButton, Tooltip, FormControlLabel, Checkbox } from "@material-ui/core";
import {
    Mail as MailIcon,
    ExpandMore as ExpandIcon,
    ExpandLess as CollapseIcon,
    Phone as PhoneIcon,
    Error as ErrorOutlineOutlinedIcon
} from "@material-ui/icons";

function YourInfoCard(props) {

    const [isExpanded, setIsExpanded] = useState(true);


    return (
        <>

            <div className="card" >
                <div className="flex-container-horizontal">
                    <div className="flex-container-horizontal" style={{ flex: 1 }}>
                        <span style={{
                            display: 'flex', justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <h3>
                                Your Info
                            </h3></span>
                    </div>
                    <div>

                        {	// Show appropriate expand/collapse icon
                            isExpanded
                                ? (
                                    <IconButton
                                        color="primary"
                                        onClick={() => setIsExpanded(false)}>
                                        <CollapseIcon />
                                    </IconButton>
                                )
                                : (
                                    <IconButton
                                        color="primary"
                                        onClick={() => setIsExpanded(true)}>
                                        <ExpandIcon />
                                    </IconButton>
                                )
                        }
                    </div>
                </div>

                <div>
                    {
                        isExpanded
                            ? (
                                <>
                                    <UserInfoList emails={props.emails}
                                        phones={props.phones}
                                        handleToggle={props.handleToggle} />
                                </>
                            )
                            : <></>
                    }
                </div>
            </div>
        </>
    )
}

export default YourInfoCard;


export function UserInfoList(props) {
    return (
        <>
            <div className="flex-container-horizontal">
                <div className="content-container">
                    <MailIcon color="primary" />
                </div>

                <div style={{ marginLeft: "5px", marginTop: "5px", flex: 1 }}>
                    {
                        props.emails.map((email, index) => {
                            return <EmailList key={index} email={email} index={index} handleToggle={props.handleToggle} />
                        })
                    }
                </div>
            </div>

            {	// Only show phones sections if there are phone(s) to display
                (props.phones.length > 0)
                    ? (
                        <>
                            <hr />

                            <div className="flex-container-horizontal">
                                <div className="content-container">
                                    <PhoneIcon color="primary" />
                                </div>

                                <div style={{ marginLeft: "5px", marginTop: "5px", flex: 1 }}>
                                    {	// Display each phone
                                        props.phones.map((num, index) => {
                                            return (<PhoneList key={index} num={num} index={index} handleToggle={props.handleToggle} />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </>
                    )
                    : <></>
            }
        </>
    )
}

export function EmailList(props) {
    return (
        <div key={props.index} className="flex-container-horizontal" style={{ justifyContent: "space-between", alignItems: "center" }} >
            <div key={props.index} className="contact-info-item">
                <p>{props.email.email}</p>
                <p className="greytext">
                    {(props.email.emailType === "Primary") ? "Primary" : ""}
                    {(props.email.emailType === "P") ? "Personal" : ""}
                    {(props.email.emailType === "W") ? "Work" : ""}
                    {(props.email.emailType === "O") ? "Other" : ""}

                </p>
            </div>
            <div>

                {(props.email.verified) ?
                    <FormControlLabel
                        control={
                            <Checkbox checked={props.email.isSelected} color="primary" onChange={() => props.handleToggle(props.email.id, "EmailCard")} />

                        }
                    />
                    :
                    <Tooltip
                        title="Verify Email"
                        placement="top">
                        <IconButton
                        // onClick={() => {
                        //     setClickedEmail(email.email);
                        //     setClickedEmailId(email.id);
                        //     setVerifyEmailModalOpen(true)
                        // }}
                        >

                            <ErrorOutlineOutlinedIcon />

                        </IconButton>
                    </Tooltip>
                }
            </div>
        </div>
    )
}

export function PhoneList(props) {
    return (<div key={props.index} className="flex-container-horizontal" style={{ justifyContent: "space-between", alignItems: "center" }} >
        <div key={props.index} className="contact-info-item">
            <p>{props.num.phoneNo}</p>
            <p className="greytext">
                {(props.num.phoneType === "H") ? "Home" : ""}
                {(props.num.phoneType === "M") ? "Mobile" : ""}
                {(props.num.phoneType === "W") ? "Work" : ""}
                {(props.num.phoneType === "O") ? "Other" : ""}
            </p>
        </div>
        <div>
            <FormControlLabel
                control={
                  <Checkbox checked={props.num.isSelected} color="primary" onChange={() => props.handleToggle(props.num.id, "PhoneCard")} />
                }
            />
        </div>
    </div>)
}