import { restGet, restPost } from "./RestClient";
import { headers } from "./UserApi";

// let headers = {
// 	"Content-Type": "application/json",
// 	"Accept": "application/json",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }

// Companies options for Dropdowns
export async function getUserContacts(userId) {
	const res = await restGet("users/GetUserContacts/" + userId, headers);
	return res;
}

// Add contacts
export async function inviteFriendsByEmail(data) {
	console.log("data", data)
	const res = await restPost("users/invitefriendsbyemail", data, headers);
	console.log("res------------", res)
	return res.data;
}

/*
Multi-use API function for Contact actions:
data = {
	Id: 0,
	UserId: {logged in user}
	ContactEmail: {email of contact of action}
	EventStatusId: {4 = block, 5 = delete},
	ModifiedBy: {logged in user},
}
*/
export async function saveContactEvent(data) {
	const res = await restPost("users/SaveContactEvent/", data, headers);
	return res.data;
}
