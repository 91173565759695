import React, { useState } from "react";
import {
  Box,
  Modal,
  Typography,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Divider,
  List,
  CircularProgress,
  Avatar,
  Card,
} from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import placeholder from "../../assets/images/project-profile-placeholder-thumbnail.png";
import { BIBlue, BIRed } from "../../assets/buildidColors";
import ReportModal from "../../components/ReportModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InfiniteScroll from "react-infinite-scroll-component";

const DescriptionDialog = (props) => {
  const history = useHistory();
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(false);
  const [reportModalOpenSearch, setReportModalOpenSearch] = useState(false);
  const [reportId, setReportId] = useState(false);

  const handleReport = (id) => {
    setMoreMenuAnchorEl(false);
    setReportModalOpenSearch(true);
    setReportId(id)
  };

  return (
    <>
      <Modal
        disableEnforceFocus={true}
        open={props.otherDescriptionDialogOpen}
        onClose={() => props.setOtherDescriptionDialogOpen(false)}
        aria-labelledby="add-description-modal"
        aria-describedby="add-description-form">
        <Box className="modal-center" sx={{ width: 600 }}>
          {/* Mahindra Code 28-mar-2024 */}
          <div className="modal-card" style={{ padding: "20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", marginRight: '15px' }}>
              <Typography
                variant="h6"
                className="no-location-message"
                sx={{ marginLeft: "15px" }}
              >
                {props.SiteName}
              </Typography>
              <IconButton onClick={() => props.setOtherDescriptionDialogOpen(false)}>
                <CloseIcon style={{ color: BIBlue }} />
              </IconButton>
            </div>


            <div
              className={"scrollableMaxDiv"}
              id="scrollableDiv"
              style={{ overflow: "auto" }}
            >
              <InfiniteScroll
                dataLength={props?.siteCardDescription?.length}
                next={() => { props?.getSiteDescriptions() }}
                hasMore={!props?.allDataLoaded}
                loader={
                  props?.allDataLoaded ? <></> : (
                    <h4
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}>
                      <CircularProgress />
                    </h4>
                  )
                }
                scrollableTarget="scrollableDiv">
                {/* Mahindra Code 28-mar-2024 */}
                <List sx={{ padding: 0 }}>
                  {props?.siteCardDescription?.map((desc, index) => (
                    <div key={index}>
                      <Card className='expCardBgc'>
                        <ListItem
                          secondaryAction={
                            <>
                              <IconButton
                                onClick={(event) => { setMoreMenuAnchorEl(event.currentTarget); }}
                                sx={{ color: (theme) => theme.palette.grey[500], }}>
                                <MoreHorizIcon />
                              </IconButton>
                              <Menu
                                anchorEl={moreMenuAnchorEl}
                                open={Boolean(moreMenuAnchorEl)}
                                onClose={(event) => {
                                  setMoreMenuAnchorEl(false);
                                }}>
                                <MenuItem
                                  onClick={() => {
                                    handleReport(desc?.id);
                                    // setMoreMenuAnchorEl(false);
                                  }}
                                  style={{ color: BIRed }}
                                  dense>
                                  Report
                                </MenuItem>
                              </Menu>
                            </>
                          }
                        >
                          {/* Mahindra Code 28-mar-2024 */}
                          <Avatar
                            variant="circle"
                            style={{ marginRight: "15px", cursor: "pointer" }}
                            src={desc?.pictureUrl?.length > 0 ? desc?.pictureUrl : placeholder}
                            onClick={() => { desc?.createdBy !== Number(localStorage?.userId) ? history.push(`/user-dashboard-profile/${desc?.createdBy}`) : history.push(`/profile`) }}
                          >
                          </Avatar>
                          <ListItemText
                            primary={
                              <span
                                onClick={() => { desc?.createdBy !== Number(localStorage?.userId) ? history.push(`/user-dashboard-profile/${desc?.createdBy}`) : history.push(`/profile`) }}

                                style={{ cursor: "pointer" }}>
                                {desc.authorName}
                              </span>
                            }
                            secondary={
                              <span
                                onClick={() => { desc?.createdBy !== Number(localStorage?.userId) ? history.push(`/user-dashboard-profile/${desc?.createdBy}`) : history.push(`/profile`) }}
                                style={{ cursor: "pointer" }}>
                                {desc.tradeName}
                              </span>
                            }
                          />
                        </ListItem>
                        <ListItemText sx={{ padding: '0px 15px' }}>
                          {desc.description}
                        </ListItemText>
                      </Card>
                    </div>
                  ))}
                </List>
              </InfiniteScroll>
            </div>
          </div>
        </Box>

      </Modal >
      <ReportModal
        name={"Site Descripton"}
        isOpen={reportModalOpenSearch}
        onClose={() => {
          setReportModalOpenSearch(false);
          props.setOtherDescriptionDialogOpen(false)
        }}
        recordId={reportId}
      />
    </>
  );
};

export default DescriptionDialog;
