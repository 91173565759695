import React from 'react'
import { Modal, Stack, Typography } from '@mui/material'
import { BIBlue } from '../../assets/buildidColors'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Avatar, Button, Card, CardMedia, IconButton, TextField, } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from "@material-ui/core/styles";
import SummarizeIcon from '@mui/icons-material/Summarize';
import RoomIcon from '@mui/icons-material/Room';
import { PlayCircleFilled } from "@material-ui/icons";
import NorthIcon from '@mui/icons-material/North';
import { Publish as UploadIcon, Close as CloseIcon, Save as SaveIcon } from "@material-ui/icons";
import CallMadeIcon from '@mui/icons-material/CallMade';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import '../../assets/css/AddMontage.css'


const useStyles = makeStyles(() => ({
    blankPage: {
        backgroundColor: 'white',
        border: `3px solid grey`,
        color: 'black',
    },
    fromSite: {
        backgroundColor: 'white',
        border: `3px solid red`,
        color: 'black'
    },
    fromGallery: {
        backgroundColor: 'white',
        border: `3px solid yellow`,
        color: 'black'
    }
}));

const MontageTutorial = (props) => {
    const classes = useStyles();

    console.log(props?.tutorialIndex)
    return (
        <>
            <Modal
                open={props?.tutorialIndex == 0 ? false : true}
                onClose={props.onClose}
                style={{
                    backgroundColor: 'rbga(0,0,0,0.1)'
                }}
            >
                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', height: '100%' }} onClick={props.onPress}>
                    {
                        props.tutorialIndex === 1 && <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <Stack sx={{ textAlign: 'center', color: 'white' }}>
                                <Typography variant="h6">
                                    Montage
                                </Typography>
                                <Typography variant="subtitle1">
                                    Create video collages using Photos of your work to share with all your friends and family
                                </Typography>
                                <Typography variant="body1" >
                                    <span style={{ color: BIBlue }}> (Tap anywhere to continue)</span>
                                </Typography>
                            </Stack>
                        </div>
                    }
                    {
                        props.tutorialIndex === 2 &&
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '100%',
                            }}
                        >
                            <Stack className='montage-tutorial-2-body'>
                                <Typography variant="subtitle1" style={{ marginRight: "-100px" }}>
                                    This is the video timeline. Add photos by tappong on the add buttons
                                </Typography>
                                <Typography variant="subtitle1" style={{ marginRight: "-70px" }}>
                                    <ArrowDownwardIcon />
                                </Typography>
                                <Typography variant="body1" >
                                    <Button variant="outlined" style={{ borderStyle: 'solid', marginBottom: '17px', marginLeft: '120px', color: BIBlue, width: '200px', height: '135px', right: '20px', top: '20px', backgroundColor: "#F0EFEF" }} startIcon={<AddIcon />} />
                                </Typography>
                            </Stack>

                        </div>
                    }
                    {
                        props.tutorialIndex === 3 &&
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '100%',
                            }}
                        >
                            <Stack sx={{ textAlign: 'center', color: 'white', marginTop: "10%" }}>
                                <Typography variant="subtitle1">
                                    Add a photo or blank text screen.
                                    <div>
                                        Photos bordered in red are from BuildID Sites.
                                    </div>
                                    Phtos in yellow are your Phone gallery (limited to 5)
                                </Typography>
                                <Typography variant="body1" >
                                    <ArrowDownwardIcon />
                                </Typography>
                                <Typography variant="body1" >
                                    <div className="modal-center" >
                                        <div className="modal-card" style={{ width: "500px", }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', padding: "10px" }}>
                                                <div style={{ marginBottom: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                                                    <Avatar>
                                                        <SummarizeIcon />
                                                    </Avatar>
                                                    <Typography variant="subtitle1" gutterBottom color="#000">Add Blank Page</Typography>
                                                </div>
                                                <div style={{ marginBottom: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                                                    <Avatar >
                                                        <RoomIcon />
                                                    </Avatar>
                                                    <Typography variant="subtitle1" gutterBottom color="#000">Photos From Site</Typography>
                                                </div>
                                                <div style={{ marginBottom: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                                                    <Avatar className={classes.fromGallery}>
                                                        <Typography style={{ margin: 'auto' }} variant="subtitle1">{`0/5`}</Typography>
                                                    </Avatar>
                                                    <Typography variant="subtitle1" gutterBottom color="#000">Add From Computer</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Typography>
                            </Stack>
                        </div>
                    }
                    {
                        props.tutorialIndex === 4 &&
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '100%',
                            }}
                        >
                            <Stack sx={{ color: 'white', marginTop: "10%", }}>
                                <Typography variant="body1" >
                                    <Typography variant="subtitle1" style={{ textAlign: "center", color: BIBlue }}>
                                        <Typography>
                                            ADD SOUND
                                        </Typography>
                                        <Button variant="outlined" style={{ borderStyle: 'solid', marginBottom: '17px', marginLeft: '100px', color: BIBlue, width: '400px', height: '400px', right: '40px', top: '20px', backgroundColor: "#F0EFEF" }} startIcon={<PlayCircleFilled />} />
                                    </Typography>
                                </Typography>
                                <Typography variant="subtitle1" style={{ textAlign: "center", marginTop: "3%", }}>
                                    <Typography variant="subtitle1">
                                        <NorthIcon />
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        This is the main screen.
                                        Preview montage here.
                                    </Typography>
                                </Typography>

                            </Stack>

                        </div>
                    }
                    {
                        props.tutorialIndex === 5 &&
                        <div
                            style={{
                                display: 'flex',
                                height: '100%',
                            }}
                        >
                            <Stack sx={{ color: 'white', marginTop: "25%", marginLeft: "200px" }}>
                                <Typography variant="subtitle1" style={{ textAlign: "center" }}>
                                    This is the edit bar. <br />
                                    Select photo to delete,add text.
                                </Typography>
                                <Typography variant="subtitle1" style={{ textAlign: "center" }}>
                                    <ArrowDownwardIcon />
                                </Typography>
                                <Typography variant="body1" >
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginTop: "10px"
                                        }}
                                    >
                                        <Card className='main-div' sx={{ maxWidth: 200, height: 130, }} style={{ position: 'relative', border: `2px solid`, }}>
                                            <span style={{ position: 'absolute', cursor: 'pointer', height: '10px' }} >
                                                <CloseIcon style={{ color: BIBlue }} />
                                            </span>
                                            <CardMedia
                                                component="img"
                                                image="https://biappstoragetest2.blob.core.windows.net/userprofiles/3/20230728062624980img.jpg"
                                                alt="seleted image show"
                                                style={{ objectFit: 'contain', height: "150px", width: "150px" }}
                                            />
                                            <div className='card-desc-div' style={{ bottom: 0, margin: "0px 10px" }}>
                                                <div className='card-descriptions' style={{ paddingBottom: 0, marginBottom: '5px' }} >
                                                    <TextField
                                                        placeholder='Add On Text'
                                                        type="text"
                                                        variant="standard"
                                                        value=""
                                                        inputProps={{ style: { height: "2px", color: 'white' } }}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </Typography>
                            </Stack>

                        </div>
                    }
                    {
                        props.tutorialIndex === 6 &&
                        <div
                            style={{
                                display: 'flex',
                                height: '100%',
                            }}
                        >
                            <Stack className='montage-tutorial-6-body'>
                                <Typography variant="subtitle1">
                                    <div>
                                        <IconButton aria-label="Replay" style={{ color: BIBlue, marginRight: '10px' }} size="medium">
                                            <HelpOutlineIcon fontSize="inherit" />
                                        </IconButton>

                                        <IconButton aria-label="Replay" style={{ color: BIBlue, marginRight: '10px' }} size="medium">
                                            <RefreshIcon fontSize="inherit" />
                                        </IconButton>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            component="label"
                                            startIcon={<SaveIcon />}

                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Typography>
                                <Typography variant="subtitle1">
                                    <CallMadeIcon />
                                </Typography>
                                <Typography variant="subtitle1" style={{ marginRight: "100px" }}>
                                    Save, or play montage from the beginning and help if you need it
                                </Typography>
                            </Stack>

                        </div>
                    }
                </div>
            </Modal>
        </>
    )
}

export default MontageTutorial