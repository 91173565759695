import React, { useState, useEffect } from "react";
import { Checkbox, IconButton } from "@material-ui/core";
import { Save as SaveIcon, Close as CloseIcon, Delete as DeleteIcon, } from "@material-ui/icons";
import { saveUserCompanies, deleteUserCompanies, } from "../../../api/UserApi";
import { fetchCompanies, saveCompanies2 } from "../../../api/CompanyApi";
import { fetchRoles, saveRole } from "../../../api/RolesApi";
import Loader from "../../../components/Loader";
import PromptModal from "../../../components/PromptModal";
import AlertModal from "../../../components/AlertModal";
import moment from "moment";
import { TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useActions } from "../../../components/ActionsContext";

const filter = createFilterOptions();

const CompanyForm = (props) => {
    const today = moment();
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [discardModalOpen, setDiscardModalOpen] = useState(false);
    const [id, setId] = useState(0);
    const [companyId, setCompanyId] = useState(0);
    const [companyName, setCompanyName] = useState("");
    const [roleId, setRoleId] = useState(0);
    const [roleName, setRoleName] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [description, setDescription] = useState("");
    const [currentCompany, setCurrentCompany] = useState(false);
    const [modalDateChecker, setModalDateChecker] = useState(null);
    const [modalDescription, setModalDescription] = useState(null);
    const [timeframeModalType, setTimeframeModalType] = useState(null);
    const [timeframeModalTitle, setTimeframeModalTitle] = useState(null);
    const [timeframeModalBody, setTimeframeModalBody] = useState(null);
    const [updateModalButton, setUpdateModalButton] = useState("");
    const [addDetailModalButton, setAddDetailModalButton] = useState("");
    const [companyStartDateNew, setCompanyStartDateNew] = useState("");
    const [companyEndDateNew, setCompanyEndDateNew] = useState("");
    const [companyData, setCompanyData] = useState([]);
    const [isRoleChanged, setIsRoleChanged] = useState(false)
    const [rolesData, setRolesData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [roleFlag, setRoleFlag] = useState(false);
    const [filterData, setFilterData] = useState([])
    const [filterRolesData, setfilterRolesData] = useState([])
    const [isSearching, setIsSearching] = useState(false);
    const { getBuilderBucksCount } = useActions()
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [openEndDatePicker, setOpenEndDatePicker] = useState(false);
    const [isCompanyOutOfFrameFlag, setIsCompanyOutOfFrameFlag] = useState(false);


    function removeDuplicates(originalArray, prop) {
        let newArray = [];
        let lookupObject = {};
        for (let i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }
        for (let i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }

    const getCompanies = async () => {
        const res = await fetchCompanies({
            IncludeRecordNr: true,
            NrOfRecPerPage: 0,
            SearchList: [{ manufacturer: false }],
            SortList: [{ FieldName: 'Name', Direction: 'ASC' }]
        });
        let a;
        res.data.map((x) => {
            a = x;
            a['title'] = x.name;
            return a;
        });
        const response = removeDuplicates(res.data, 'name');
        setCompanyData(response);
    };

    const getRoles = async () => {
        const res = await fetchRoles({
            IncludeRecordNr: true,
            NrOfRecPerPage: 0,
            SearchList: [{ manufacturer: false }],
            SortList: [{ FieldName: 'Name', Direction: 'ASC' }]
        });
        let a;
        res.data.map((x) => {
            a = x;
            a['title'] = x.name;
            return a;
        });
        const response = await removeDuplicates(res.data, 'name')
        setRolesData(response);

    };

    const getFilterCompanies = async (props) => {
        if (props !== '') {
            setIsSearching(true);
        } else {
            setIsSearching(false);
        }
        const res = await fetchCompanies({
            IncludeRecordNr: true,
            NrOfRecPerPage: 0,
            SearchList: [{ name: props, manufacturer: false }],
            SortList: [{ FieldName: 'Name', Direction: 'ASC' }]
        });
        let a;
        res.data.map((x) => {
            a = x;
            a['title'] = x.name;
            return a;
        });
        const response = await removeDuplicates(res.data, 'name');
        setFilterData(response)
    }


    const getFilterRoles = async (props) => {
        if (props !== '') {
            setIsSearching(true);
        } else {
            setIsSearching(false);
        }
        const res = await fetchRoles({
            IncludeRecordNr: true,
            NrOfRecPerPage: 0,
            SearchList: [{ name: props, manufacturer: false }],
            SortList: [{ FieldName: 'Name', Direction: 'ASC' }]
        });
        let a;
        res.data.map((x) => {
            a = x;
            a['title'] = x.name;
            return a;
        });
        const response = await removeDuplicates(res.data, 'name');
        setfilterRolesData(response)
    }

    function handleCancelButtonClick() {
        // If there are changes to the form, prompt user before discarding
        if (!handleEditDisable()) setDiscardModalOpen(true);
        else props.onReturnClick();
    }

    const handleCompanySaveButton = async () => {

        let getRoleId = roleId;
        try {
            if (companyName?.trim() == '') {
                setModalDateChecker("company-name");
                setModalDescription('Please Enter Company Name');
                return;
            }

            if (!currentCompany && startDate > endDate) {
                setModalDateChecker("start");
                setModalDescription('Start Date should be before the End Date, or click I currently work here if you are still working on this site.');
                return;
            }

            if (!currentCompany && startDate && endDate == null) {
                setModalDateChecker("start");
                setModalDescription('Start Date should be before the End Date, or click I currently work here if you are still working on this site.');
                return;
            }

            if ((currentCompany && startDate == null)) {
                setModalDateChecker("start-date");
                setModalDescription('Please select Start Date or unchecked present.');
                return;
            }

            if (getRoleId == 0 && roleName != "") {
                getRoleId = await saveSelectedItem(roleName, 'role');
                setRoleId(getRoleId);
            }

            if (companyId == 0 && companyName != "") {
                let getComponyId = await saveSelectedItem(companyName, 'company');
                setCompanyId(getComponyId);
                formatTimeframe(getRoleId, getComponyId);
            } else {
                formatTimeframe(getRoleId, companyId);
            }
        } catch (err) {
            console.log("Error-----------", err)
        }
    }

    const formatTimeframe = async (getRoleId, getComponyId) => {
        let isCompanyAdd = true;
        let isCompanyOutOfFrame = false;
        let isNewCompany = false;
        const comp = props.allCompanies.filter(comp => comp.companyId == getComponyId).sort((a, b) => {
            let dateA = new Date(a.startDate), dateB = new Date(b.startDate);
            return dateA - dateB;
        });
        if (comp.length != 0) {
            let companyStartDate = comp[0].startDate ? moment(comp[0]?.startDate).format('YYYY-MM-DD') : null;
            let companyEndDate = comp[0].endDate ? moment(comp[0]?.endDate).format('YYYY-MM-DD') : null;
            let newStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
            let newEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : null;


            if (!companyStartDate && !companyEndDate && !newStartDate && !newEndDate) {
                isCompanyAdd = false; if (props?.edit) {
                    if (newEndDate == null && endDate == null) {
                        isCompanyAdd = true;
                    }
                }
                // 1. no company update
            } else if (!companyStartDate && !companyEndDate && newStartDate && (!newEndDate || newEndDate)) {
                isCompanyOutOfFrame = true;
                companyStartDate = newStartDate;
                companyEndDate = newEndDate;                // 2. update company, show out of frame dialog, CSD1 = CSD2, CED1 = CED2
            } else if (companyStartDate && companyEndDate && newEndDate < companyStartDate) {
                isCompanyAdd = true;
                isCompanyOutOfFrame = true; if (moment(companyStartDate).diff(moment(newEndDate), 'months') == 1) {
                    companyStartDate = newStartDate;
                } else isNewCompany = true;
                // 3. Add separate company
            } else if (companyStartDate && companyEndDate && newStartDate > companyEndDate) {
                isCompanyAdd = true;
                isCompanyOutOfFrame = true; if (moment(newStartDate).diff(moment(companyEndDate), 'months') == 1) {
                    companyEndDate = newEndDate;
                } else isNewCompany = true;
                // 4. Add separate company
            } else if (companyStartDate && companyEndDate && newStartDate >= companyStartDate && newEndDate <= companyEndDate) {
                isCompanyAdd = false;                // 5. no company update
            } else if (companyStartDate && companyEndDate && newStartDate < companyStartDate && (newEndDate >= companyStartDate && newEndDate <= companyEndDate)) {
                isCompanyOutOfFrame = true;
                companyStartDate = newStartDate;                // 6. update company, show out of frame dialog, CSD1 = CSD2
            } else if (companyStartDate && companyEndDate && newStartDate < companyStartDate && !newEndDate) {
                isCompanyOutOfFrame = true;
                companyStartDate = newStartDate;                // 7. update company, show out of frame dialog, CSD1 = CSD2
            } else if (companyStartDate && companyEndDate && (newStartDate >= companyStartDate && newStartDate <= companyEndDate) && newEndDate > companyEndDate) {
                isCompanyOutOfFrame = true;
                companyEndDate = newEndDate;
                // 8. update company, show out of frame dialog, CED1 = CED2
            } else if (companyStartDate && companyEndDate && (newStartDate >= companyStartDate && newStartDate <= companyEndDate) && newEndDate == null) {
                isCompanyAdd = true;
                // 9. no company update
            } else if (companyStartDate && companyEndDate && newStartDate < companyStartDate && newEndDate > companyEndDate) {
                isCompanyOutOfFrame = true;
                companyStartDate = newStartDate;
                companyEndDate = newEndDate;
                // 10. update company, show out of frame dialog, CSD1 = CSD2, CED1 = CED2
            } else if (companyStartDate && !companyEndDate && newStartDate < companyStartDate && !newEndDate) {
                isCompanyOutOfFrame = true;
                companyStartDate = newStartDate;
                // 11. update company, show out of frame dialog, CSD1 = CSD2
            } else if (companyStartDate && !companyEndDate && newStartDate < companyStartDate && newEndDate) {
                isCompanyOutOfFrame = true;
                companyStartDate = newStartDate;
                companyEndDate = newEndDate;
                // 12. update company, show out of frame dialog, CSD1 = CSD2, CED1 = CED2
            } else if (companyStartDate && !companyEndDate && newStartDate > companyStartDate && !newEndDate) {
                isCompanyAdd = false;
                // 13. no company update
            } else if (companyStartDate && !companyEndDate && newStartDate > companyStartDate && newEndDate) {
                isCompanyOutOfFrame = true;
                companyEndDate = newEndDate;
                // 14. update company, show out of frame dialog, update CED1 = CED2;
            }
            if (isCompanyOutOfFrame) {
                setTimeframeModalType('isCompanyOutOfFrame');
                setTimeframeModalBody(`You have already added ${companyName}. Would you like to ${isNewCompany ? 'add new entry' : 'update the timeframe'}?`);
                setUpdateModalButton(!isNewCompany ? 'UPDATE' : '')
                setAddDetailModalButton('ADD NEW')
                setCompanyData(comp[0]);
                setCompanyStartDateNew(companyStartDate);
                setCompanyEndDateNew(companyEndDate);
                setIsCompanyOutOfFrameFlag(isCompanyOutOfFrame)
                return;
            }
            if (props?.edit) {
                if ((description !== props.company.description) && !(!companyStartDate && !companyEndDate && !newStartDate && !newEndDate)) {
                    isCompanyAdd = true;
                }
            }

            if (!isCompanyAdd) {
                setCompanyData(comp[0]);
                setCompanyStartDateNew(companyStartDate);
                setCompanyEndDateNew(companyEndDate);
                setModalDateChecker("start");
                setModalDescription('This Company already exist within the selected timeframe.');
                return;
            }


            let obj = {
                data: comp[0],
                companyStartDateNew: companyStartDate,
                companyEndDateNew: companyEndDate == null ? newEndDate : companyEndDate,
                currentCompany: currentCompany,
                description: description
            }
            handleUpdate(id, getRoleId, obj);
        }
        else {
            handleSaveCompany(0, getRoleId, getComponyId);
        }
    }

    const saveSelectedItem = async (selectedItem, option) => {
        const obj = {
            Id: 0,
            Name: selectedItem,
            CreatedBy: localStorage.userId,
            DateCreated: moment().format(),
            ModifiedBy: localStorage.userId,
            DateModified: moment().format(),
        }
        try {
            if (option == 'company') {
                const mainObj = { UserId: Number(localStorage.userId), ReturnRecordId: true, ReturnRecordError: true };
                const saveObject = {
                    ...mainObj,
                    SaveList: [{ Id: 0, Name: selectedItem, ModifiedBy: Number(localStorage.userId), RecordStatusId: 1, Manufacturer: false }]
                }
                const res = await saveCompanies2(saveObject);
                setCompanyName(selectedItem);
                return res[0]?.objData?.id;
            } else {
                const roleId = await saveRole(obj);
                setRoleName(selectedItem);
                return roleId;
            }
        } catch (err) {
            console.log('Error:[ADD_EXPERIENCE_COMPANY] SAVE_SELECTED_ITEM:', err);
        }
    }

    const handleSaveCompany = async (id, getRoleId, getComponyId) => {
        try {
            setIsLoading(true);
            const obj = {
                userId: Number(localStorage.userId),
                saveList: [{
                    id: id,
                    userId: Number(localStorage.userId),
                    companyId: getComponyId,
                    // roleId: getRoleId,
                    startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
                    endDate: currentCompany == true ? null : endDate ? moment(endDate).format("YYYY-MM-DD") : null,
                    currentCompany: currentCompany,
                    includeInResume: false,
                    description: description,
                    CreatedBy: Number(localStorage.userId),
                    modifiedBy: Number(localStorage.userId),
                }]
            }
            await saveUserCompanies(obj)
            localStorage.setItem("flag", true)
            getBuilderBucksCount()
            props.onReturnClick();
            setIsLoading(false);
        } catch (error) {
            console.log("Companies Error---", error)
        }



    }

    const handleUpdate = async (id, getRoleId, params) => {
        try {
            const companyObj = {
                UserId: Number(localStorage.userId),
                ReturnRecordId: true,
                ReturnRecordError: true,
                SaveList: [{
                    Id: isCompanyOutOfFrameFlag ? params?.data?.id : id,
                    UserId: Number(localStorage.userId),
                    RoleId: isCompanyOutOfFrameFlag ? params?.data?.roleId : getRoleId,
                    CompanyId: params.data?.companyId,
                    StartDate: params.companyStartDateNew ? moment(params.companyStartDateNew).format('YYYY-MM-DD') : null,
                    endDate: params?.currentCompany == true ? null : params.companyEndDateNew ? moment(params.companyEndDateNew).format('YYYY-MM-DD') : null,
                    ModifiedBy: Number(localStorage.userId),
                    CurrentCompany: params?.currentCompany,
                    IncludeInResume: params.data?.includeResume ? params.data?.includeResume : false,
                    Description: params?.description
                }]
            }
            await saveUserCompanies(companyObj)
            getBuilderBucksCount();
            setIsCompanyOutOfFrameFlag(false);
            props.onReturnClick();               // uncomment later
        } catch (error) {
            console.log('Error:[ADD_EXPERIENCE_COMPANY] SAVE_USER_COMPANIES_2:', error);
        }
    }

    async function handleDeleteButtonClick() {
        try {
            const obj1 = {
                UserId: Number(localStorage.userId),
                ReturnRecordId: false,
                ReturnRecordError: true,
                DeleteList: [{ Id: id, ModifiedBy: Number(localStorage.userId) }]
            }
            await deleteUserCompanies(obj1);
            getBuilderBucksCount()
            props.onReturnClick();
            setIsLoading(false);
        } catch (error) {
            console.log("deleteUserCompanies::Error", error)
        }
    }

    function handleDescription(e) {
        setDescription(e.target.value)
    }

    useEffect(() => {
        if (props.company) {
            setId(props.company.id);
            setCompanyId(props.company.companyId);
            setCompanyName(props.company.companyName);
            setRoleId(props.company.roleId);
            setRoleName(props.company.roleName);
            setStartDate(props.company.startDate);
            setEndDate(props.company.endDate);
            setDescription(props.company.description);
            setCurrentCompany(props.company.currentCompany);
        }
    }, []);

    useEffect(() => {
        getCompanies();
        getRoles();
    }, []);

    const onPresentChange = () => {
        setCurrentCompany(!currentCompany);
        setEndDate(null);
    }

    const handleStartDateChange = (date) => {
        let getDate = moment(date).startOf('month');
        const newDate = moment(getDate).format("YYYY-MM-DD");
        console.log("newDate===>", newDate)
        setStartDate(newDate);

    };

    const handleEndDateChange = (date) => {
        let getDate = moment(date).startOf('month');
        const newDate = moment(getDate).format("YYYY-MM-DD");
        setEndDate(newDate);
    };

    const handleClearStartDate = (e) => {
        e.stopPropagation();
        setStartDate(null);
        setOpenDatePicker(false); // Close the date picker when clearing the date
    };

    const handleClearEndDate = (e) => {
        e.stopPropagation();
        setEndDate(null);
        setOpenEndDatePicker(false); // Close the date picker when clearing the date
    };

    const handleAddDisable = () => {
        return companyName?.trim() === ""
        // return companyName?.trim() === "" || !startDate || (!currentCompany && endDate == null)
    }

    const handleEditDisable = () => {
        if (!currentCompany && endDate == null && description?.trim() === "" && startDate == null) { console.log("If currentCompany-------------", currentCompany); return true; }
        else return (
            moment(props?.company?.startDate).format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD') &&
            moment(props?.company?.endDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD')
            && props?.company?.description.trim() === description.trim() && props?.company?.currentCompany === currentCompany
        );
    };

    return (
        <>
            <PromptModal
                isOpen={discardModalOpen}
                onClose={() => setDiscardModalOpen(false)}
                title="Are you sure you want to leave?"
                desc="Any unsaved changes will be discarded."
                redButtonText="Discard"
                blueButtonTextFirst="Go Back"
                onRedButtonClick={props.onReturnClick} />

            <PromptModal
                isOpen={confirmationModalOpen}
                onClose={() => setConfirmationModalOpen(false)}
                title="Are you sure you want to delete this company?"
                desc="This action cannot be undone."
                redButtonText="Delete"
                blueButtonTextFirst="Go Back"
                onRedButtonClick={handleDeleteButtonClick} />

            <PromptModal
                isOpen={(timeframeModalType !== null)}
                onClose={() => {
                    setTimeframeModalType(null);
                    setTimeframeModalTitle(null);
                    setTimeframeModalBody(null);
                }}
                title={timeframeModalTitle}
                desc={timeframeModalBody}

                redButtonText="Cancel"
                onRedButtonClick={() => {
                    setTimeframeModalType(null);
                    setTimeframeModalTitle(null);
                    setTimeframeModalBody(null);
                }}

                blueButtonText={updateModalButton}
                onBlueButtonClick={() => {
                    let companyDataNew = props.allCompanies.filter(comp => comp.id == id);
                    let obj = {
                        data: isCompanyOutOfFrameFlag ? companyData : companyDataNew[0],
                        companyStartDateNew: companyStartDateNew,
                        companyEndDateNew: companyEndDateNew,
                        currentCompany: currentCompany,
                        description: description
                    }
                    handleUpdate(id, roleId, obj);
                    setTimeframeModalType(null);
                    setTimeframeModalTitle(null);
                    setTimeframeModalBody(null);
                    props.onReturnClick();
                }}

                blueButtonText2={addDetailModalButton}
                onBlueButtonClick2={() => {
                    handleSaveCompany(0, roleId, companyId);
                    setTimeframeModalType(null);
                    setTimeframeModalTitle(null);
                    setTimeframeModalBody(null);
                    props.onReturnClick();
                }}

            />

            <AlertModal
                isOpen={(modalDateChecker !== null)}
                onClose={() => {
                    setModalDateChecker(null);
                    setModalDescription(null);
                }}
                body={modalDescription}
                expCloseIcon={true}
            />

            {
                isLoading
                    ? <Loader />
                    : (
                        <>
                            <div className="flex-container-vertical">
                                <div className="flex-container-horizontal" style={{ justifyContent: 'space-between' }}>
                                    <div>
                                        <IconButton
                                            color="primary"
                                            onClick={handleCancelButtonClick}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>

                                    <div>
                                        <IconButton
                                            color="primary"
                                            onClick={handleCompanySaveButton}
                                            disabled={id === 0 ? handleAddDisable() : handleEditDisable()}
                                        >
                                            <SaveIcon />
                                        </IconButton>
                                        {	// Only show delete button if form is not for a new company
                                            (props.company.id !== 0)
                                                ? (
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={() => setConfirmationModalOpen(true)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )
                                                : <></>
                                        }
                                    </div>
                                </div>
                                <div className="flex-container-vertical" style={{ flex: 1 }}>
                                    <h2 style={{ paddingLeft: '10px' }}>{(id === 0) ? "Add Company to Profile" : "Edit Company to Profile"}</h2>
                                    <span style={{ paddingLeft: '10px' }}>*Required</span>
                                    <div className="input-container">
                                        <Autocomplete
                                            disabled={props.edit ? true : false}
                                            inputValue={companyName}
                                            onChange={(event, newValue) => {
                                                if (newValue && newValue.inputValue) {
                                                    setCompanyName(newValue?.inputValue);
                                                    setCompanyId(0);
                                                    setFlag(false)
                                                }
                                                else {
                                                    setCompanyId(newValue?.id);
                                                    setFlag(false)
                                                }
                                            }}

                                            onInputChange={(event, newInputValue) => {
                                                setCompanyName(newInputValue);
                                                setFlag(true);
                                                if (newInputValue !== '') {
                                                    getFilterCompanies(newInputValue);
                                                } else {
                                                    getCompanies();
                                                }
                                            }}

                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                const { inputValue } = params;

                                                const isExisting = options.some((option) => inputValue === option.title);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        title: `Add "${inputValue}"`,
                                                    });
                                                }
                                                return filtered;
                                            }}
                                            options={isSearching ? filterData : companyData}
                                            getOptionLabel={(option) => {
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                return option.title;
                                            }}
                                            renderOption={(props, option) => <li {...props}>{option.title}</li>}
                                            style={{ flex: 1 }}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label="Company*" variant={props.edit ? "filled" : "outlined"} />
                                            )}
                                            open={companyName?.length > 1 && flag}
                                        />

                                    </div>

                                    {/* <div className="input-container">
                                        <Autocomplete
                                            disabled={props.isExperienceCard ? true : false}
                                            inputValue={roleName}
                                            onChange={(event, newValue) => {
                                                if (newValue && newValue.inputValue) {
                                                    setIsRoleChanged(true);
                                                    setRoleName(newValue?.inputValue);
                                                    setRoleId(0);
                                                    setRoleFlag(false)
                                                }
                                                else {
                                                    setIsRoleChanged(true);
                                                    setRoleId(newValue?.id);
                                                    setRoleFlag(false)
                                                }
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                setRoleName(newInputValue);
                                                setRoleFlag(true);
                                                setIsRoleChanged(true);
                                                if (newInputValue !== '') {
                                                    getFilterRoles(newInputValue);
                                                } else {
                                                    getRoles();
                                                }
                                            }}

                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                const { inputValue } = params;

                                                const isExisting = options.some((option) => inputValue === option.title);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        title: `Add "${inputValue}"`,
                                                    });
                                                }
                                                return filtered;
                                            }}
                                            options={isSearching ? filterRolesData : rolesData}
                                            getOptionLabel={(option) => {
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                if (option.inputValue) {
                                                    return option.inputValue;
                                                }
                                                return option.title;
                                            }}
                                            renderOption={(props, option) => <li {...props}>{option.title}</li>}
                                            style={{ flex: 1 }}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField {...params} label="Title/Role" variant={props.isExperienceCard ? "filled" : "outlined"} />
                                            )}
                                            open={roleName?.length > 1 && roleFlag}
                                        />

                                    </div> */}

                                    <div className="input-container">
                                        <div className="flex-container-vertical" style={{ alignItems: "stretch" }}>
                                            <KeyboardDatePicker
                                                autoOk
                                                open={openDatePicker}
                                                onClose={() => setOpenDatePicker(false)}
                                                variant="inline"
                                                inputVariant="outlined"
                                                label="Start Date"
                                                placeholder="Month-Year"
                                                // value={startDate === null ? null : stringToLocale(startDate)}
                                                value={startDate === null ? null : startDate}
                                                views={["year", "month"]}
                                                InputAdornmentProps={{ position: "start" }}
                                                onChange={(date) => handleStartDateChange(date)}
                                                maxDate={moment(new Date()).format("YYYY-MM")}
                                                InputProps={{
                                                    readOnly: true,
                                                    onClick: () => setOpenDatePicker(true),
                                                    // endAdornment: startDate !== "Invalid date" ? (
                                                    //     startDate != null ? (
                                                    //         <IconButton style={{ order: 1 }} onClick={(e) => handleClearStartDate(e)}>
                                                    //             <CloseIcon />
                                                    //         </IconButton>
                                                    //     ) : null
                                                    // ) : null,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="input-container">
                                        <div className="flex-container-vertical" style={{ alignItems: "stretch" }}>
                                            <KeyboardDatePicker
                                                autoOk
                                                open={openEndDatePicker}
                                                onClose={() => setOpenEndDatePicker(false)}
                                                variant="inline"
                                                inputVariant={currentCompany ? "filled" : "outlined"}
                                                label="End Date"
                                                placeholder="Month-Year"
                                                // value={!currentCompany ? endDate === null ? null : stringToLocale(endDate) : null}
                                                value={!currentCompany ? endDate === null ? null : endDate : null}
                                                views={["year", "month"]}
                                                disabled={currentCompany}
                                                InputAdornmentProps={{ position: "start" }}
                                                onChange={date => handleEndDateChange(date)}
                                                maxDate={today.format("YYYY-MM-DD")}
                                                InputProps={{
                                                    readOnly: true,
                                                    onClick: () => setOpenEndDatePicker(true),
                                                    // endAdornment:
                                                    //     (endDate !== "Invalid date") ?
                                                    //         ((!currentCompany && endDate != null) ?
                                                    //             <IconButton style={{ order: 1 }} onClick={(e) => handleClearEndDate(e)}>
                                                    //                 <CloseIcon />
                                                    //             </IconButton> : null) : null
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <Checkbox
                                            color="primary"
                                            checked={currentCompany}
                                            onChange={() => onPresentChange()}
                                        />
                                        <span>Present</span>
                                    </div>
                                    <div className="input-container">
                                        <TextField
                                            fullWidth
                                            rows={4}
                                            multiline
                                            label="Job Description"
                                            type="text"
                                            value={description}
                                            onChange={(e) => handleDescription(e)}
                                        />
                                    </div>
                                </div>
                            </div>


                        </>
                    )
            }
        </>
    )
}

export default CompanyForm
