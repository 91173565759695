import React, { useState } from "react";
import { Button, IconButton, TextField } from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AlertModal from "../../components/AlertModal";
import DialogContentText from '@mui/material/DialogContentText';
import { BIBlack } from "../../assets/buildidColors";
import { saveDataIssues } from "../../api/UserApi";
import "../../assets/css/Settings.css";
import { Close as CloseIcon } from "@material-ui/icons";

function SendFeedbackModal(props) {
    const [feedbackDescription, setFeedbackDescription] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [sendFeedbackModalAlert, setSendFeedbackModalAlert] = useState(false);
    const [reason, setReason] = React.useState({
        Incorrect: false,
        Offensive_Inappropriate: false,
        Something_Else: false
    });

    const [isBtnDisabled, setIsBtnDisabled] = useState(true);

    async function handleSubmitClick() {
        const obj =
        {
            UserId: Number(localStorage.userId),
            ReturnRecordId: false,
            ReturnRecordError: true,
            SaveList: [{
                Id: 0,
                RecordId: props.recordId,
                DataSource: "feedback",
                DataIncorrect: selectedOption === "Incorrect" ? true : false,
                DataOffensive: selectedOption === "Offensive_Inappropriate" ? true : false,
                DataOther: selectedOption === "Something_Else" ? true : false,
                DataComments: feedbackDescription,
                ModifiedBy: Number(localStorage.userId),
            }]
        }
        const res = await saveDataIssues(obj);
        if (res.message === "OK") {
            // alert("Report Successfully Sent")
            setSendFeedbackModalAlert(true);
            setSelectedOption("");
            setFeedbackDescription("")
            setReason({
                Incorrect: false,
                Offensive_Inappropriate: false,
                Something_Else: false
            });
        }

    }

    return (
        <div>
            <AlertModal
                isOpen={sendFeedbackModalAlert}
                onClose={() => { props.onClose(); setSendFeedbackModalAlert(false) }}
                title={"Feedback Sent Successfully..."}
            />

            <Dialog
                open={props.isOpen}
                onClose={() => {
                    props.onClose();
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">


                <DialogTitle style={{ color: BIBlack }} id="alert-dialog-title">
                    <IconButton
                        color="primary"
                        onClick={() => { props.onClose() }}>
                        <CloseIcon />
                    </IconButton>
                    <div className="feedbackModalHeader">
                        Send Feedback
                    </div>
                </DialogTitle>


                <DialogContent>
                    <div style={{ width: "100%" }}>
                        <div className="input-container">
                            <DialogContentText id="alert-dialog-description">
                                Tell us about what you like about BuildID, or what we could be doing better.
                            </DialogContentText>

                        </div>



                        <div className="input-container">
                            <TextField
                                variant="outlined"
                                fullWidth
                                placeholder="Enter Feedback"
                                value={feedbackDescription}
                                onChange={(e) => {
                                    setIsBtnDisabled(false);
                                    setFeedbackDescription(e.target.value)
                                }}

                            />
                        </div>


                        <div style={{ width: "100%" }}>
                            <div className="input-container">
                                <DialogContentText style={{ textAlign: "center" }}>
                                    Want to share more detailed feedback? Send us an email at
                                </DialogContentText>
                                <DialogContentText style={{ textAlign: "center" }}>
                                    <a style={{ textDecoration: "none" }} href="mailto:info@mybuilderid.com">
                                        info@mybuilderid.com
                                    </a>
                                </DialogContentText>

                            </div>
                        </div>

                        <div className="input-container">
                            <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                disabled={isBtnDisabled}
                                onClick={handleSubmitClick}
                            >
                                SUBMIT
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default SendFeedbackModal;