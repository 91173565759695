import { useState } from "react";
import { Card, Avatar, TextField, InputAdornment, Input, IconButton, Button, } from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from '@mui/material/CircularProgress';
import { savePostComment } from "../../api/PostsApi";
import AlertModal from "../AlertModal";
export default function PostCommentInput(props) {
	const [userComment, setUserComment] = useState("");
	const [picUrl, setPicUrl] = useState([]);
	const [loading, setLoading] = useState(false);
	const [inappropriateImageAlert, setInappropriateImageAlert] = useState(false);

	//saves comment to api
	async function handleCommentPost() {
		try {
			if (picUrl?.length > 0) {
				if (!picUrl?.[0]?.type.startsWith('image/')) {
					return alert("Please select image only.")
				}
			}
			let data = new FormData();
			data.append("Id", "0");
			data.append("PostId", props?.post?.id);
			data.append("UserId", localStorage?.userId);
			data.append("Comment", userComment);
			data.append("ModifiedBy", localStorage?.userId);
			data.append("AppId", props?.post?.appId);
			picUrl?.length > 0 && data.append("Pictures", picUrl?.[0]);

			await savePostComment(data).then(async (res) => {
				await props.updateComments();
				if (props?.from !== "dashboardNotification") {
					await props.getNoOfComments();
				}
			}).catch((err) => {
				if (err === "Content Inappropriate") {
					setInappropriateImageAlert(true);
				}
				console.log("Error while saving the comment:-", err)
			})
			// Reset input states
			setPicUrl([]);
			setUserComment("");
			setLoading(false)
		} catch (err) {
			setLoading(false)
			console.log("Error while saving the comment:-", err)
		}
	}

	return (
		<>
			<AlertModal
				isOpen={inappropriateImageAlert}
				onClose={() => setInappropriateImageAlert(false)}
				title={"Content Rejected"}
				body={'The text picture you entered has been automatically identified as inappropriate.'}
				inappropriateImage
				text1={'Please remove the content and try again.'}
			/>

			<Card className="newsfeed-comment-card" style={{ marginBottom: "0px" }}>
				<div
					className="flex-container-horizontal"
					style={{ alignItems: "center", padding: "0px 16px" }}
				>
					<Avatar alt="Profile Picture" src={localStorage?.pictureUrl} />
					<div className="input-container" style={{ flex: 1 }}>
						<TextField
							variant="outlined"
							label='Comment'
							disabled={loading}
							size="small"
							sx={{ backgroundColor: "white" }}
							fullWidth
							value={userComment}
							onChange={(event) => setUserComment(event.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment value={userComment} position="end" >
										<label htmlFor={`file-input-${props.post.id}`}>
											<Input
												accept="image/*"
												id={`file-input-${props?.post?.id}`}
												type="file"
												onChange={(event) => {
													setPicUrl([event.target.files[0]])
													event.target.value = null
												}
												}
												style={{
													display: "none" /* Hide underlying HTML component */,
												}}
												disabled={loading}
											/>
											<IconButton
												color={picUrl.length !== 0 ? "inherit" : "primary"}
												aria-label="upload picture"
												component="span"
											>
												<InsertPhotoIcon />
											</IconButton>
										</label>
									</InputAdornment>
								),
							}}
						/>
					</div>

					{
						!loading ?
							<IconButton
								color="primary"
								disabled={userComment?.trim() != '' || picUrl?.length != 0 ? false : true}
								onClick={() => handleCommentPost()}
							>
								<SendIcon />
							</IconButton> :
							<CircularProgress size={24} />
					}
				</div>

				{/* Uploaded image preview */}
				<div style={{ paddingBottom: "10px" }}>
					{Array.isArray(picUrl) &&
						picUrl.map((pic) => (
							<div style={{ display: "flex", justifyContent: "center" }}>
								<div
									className="picture-container"
									style={{ margin: "0px 16px", height: "350px", display: "flex" }}
								>
									<div className="picture-container-button">
										<IconButton size="small" onClick={() => setPicUrl([])}>
											<CloseIcon />
										</IconButton>
									</div>
									<img
										src={URL.createObjectURL(pic)}
										alt={pic?.name}
										width="100%"
									/>
								</div>
							</div>
						))}
				</div>
			</Card>
		</>
	);
}