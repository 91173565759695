import React, { useState, useEffect } from 'react'
import {
    Dialog, DialogTitle, DialogContent,
    Button, IconButton, Avatar,
    Stack, ListItem, ListItemText, Divider, Typography, ListItemAvatar, DialogActions, CircularProgress
} from '@mui/material'
import { Close as CloseIcon } from "@material-ui/icons";
import defaultProjectImage from "../../assets/images/project-profile-placeholder.jpg"
import defaultUserProfilePic from "../../assets/images/AvatarImage.jpeg"
import { BIBlue } from '../../assets/buildidColors';
import { getUserDashboard } from '../../api/UserApi';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import mapMarkerYellow from "../../assets/images/map-marker-yellow.png";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useHistory } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import PictureViewer from '../../components/PictureViewer';
import { saveChatGroupUsers } from '../../api/ChatApi';

const ItemDetailsModal = (props) => {

    const { item } = props;
    const [userDetails, setUserDetails] = useState(null);
    const [pictureViewerOpen, setPictureViewerOpen] = useState(false);
    const [pictureViewIndex, setPictureViewIndex] = useState(0);
    const [btnLoader, setBtnLoader] = useState(false);
    const containerStyle = {
        width: '100%',
        height: '200px',
        border: "1px solid #000000d6",
        marginBottom: "14px"
    };
    const history = useHistory();


    useEffect(() => {
        if (item) {
            getUserDetails();
        }
    }, [item])

    const getUserDetails = async () => {
        try {
            const res = await getUserDashboard(item?.userId);
            setUserDetails(res ?? null)
        } catch (error) {
            console.log("Error while getting user details :: ", error)
        }
    }

    const onClose = () => {
        setUserDetails(null);
        setPictureViewIndex(0);
        setPictureViewerOpen(false);
        props?.onClose()
    }

    const onMessgaeClick = async () => {
        try {
            if (!item?.userId) return null;
            setBtnLoader(true);
            const saveListArray = [
                {
                    Id: 0,
                    ChatGroupId: 0,
                    UserId: item?.userId,
                    ModifiedBy: Number(localStorage.getItem('userId')),
                    Muted: false
                }
            ]
            const chatGroupObj = {
                UserId: Number(localStorage.getItem('userId')),
                ReturnRecordId: true,
                ReturnRecordError: true,
                SaveList: saveListArray
            }
            let result = await saveChatGroupUsers(chatGroupObj);
            if (result?.data?.length > 0) {
                let chatGroupId = result.data[0]?.objData.chatGroupId;
                history.push({
                    pathname: "/chat",
                    state: {
                        from: "New Chat",
                        chatGroupId: chatGroupId,
                        showInList: true
                    }
                })
            }
            setBtnLoader(false);
        } catch (err) {
            setBtnLoader(false);
            console.log("Error while sending to chat screen :: ", err)
        }
    }

    const onUserClick = () => {
        if (!item?.userId) return null;
        history.push(`/user-dashboard-profile/${item?.userId}`)
    }

    const onImagePreview = (index) => {
        setPictureViewerOpen(true);
        setPictureViewIndex(index);
    }

    return (
        <>
            <Dialog
                open={props?.open}
                onClose={onClose}
                fullWidth
                maxWidth="sm"
                scroll={"paper"}
            >
                <DialogTitle>
                    <div className="modal-card-header-buttons" style={{ alignItems: 'center' }}>
                        <h2>{item?.title ?? ""}</h2>
                        <IconButton
                            color="primary"
                            onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent style={{ paddingTop: 0 }} >
                    <Stack spacing={0}>
                        {
                            item?.pictureList?.length > 0 ?
                                (
                                    <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                                        <Carousel showThumbs={false} showIndicators={item?.pictureList?.length > 1 ? true : false}>
                                            {
                                                item?.pictureList?.map((picture, index) => (
                                                    <div style={{ maxHeight: '400px', objectFit: 'contain', cursor: 'pointer' }}
                                                        onClick={() => { onImagePreview(index) }}
                                                        key={index}
                                                    >
                                                        <img
                                                            key={index}
                                                            src={picture.name}
                                                            alt={item?.title ?? "image"}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </Carousel>
                                    </div>
                                )
                                :
                                <img
                                    src={defaultProjectImage}
                                    alt={item?.title ?? "image"}
                                    style={{ maxHeight: '400px', objectFit: 'contain' }}
                                />
                        }

                        <Typography variant="h6">
                            {item?.title ?? ""}
                        </Typography>
                        <Typography variant="subtitle1">
                            {item?.forTrade ? (
                                <>
                                    For Trade
                                </>
                            )
                                : (
                                    <>$ {item?.price ?? ""}</>
                                )
                            }
                        </Typography>
                    </Stack>
                    <Divider />
                    <Stack>
                        <ListItem alignItems="flex-start" disablePadding onClick={onUserClick} style={{ cursor: 'pointer' }}>
                            <ListItemAvatar>
                                <Avatar alt="" src={userDetails?.pictureUrl != '' ? userDetails?.pictureUrl : defaultUserProfilePic} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${userDetails?.firstName ?? ""} ${userDetails?.lastName ?? ""} `}
                                secondary={
                                    <React.Fragment>
                                        {userDetails?.tradeName != '' && userDetails?.tradeName != null &&
                                            (
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {userDetails?.tradeName ?? ""}
                                                </Typography>
                                            )
                                        }
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider />
                        {
                            item?.description !== '' && (
                                <Typography variant="subtitle1">
                                    {item?.description}
                                </Typography>
                            )
                        }
                        <ListItem alignItems="flex-start" disablePadding>
                            <ListItemAvatar style={{ display: 'flex', alignItems: 'center', height: '40px' }}>
                                <LocationOnIcon style={{ color: BIBlue }} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <>
                                        <Stack direction={'row'} justifyContent="space-between">
                                            <Typography variant="subtitle1">
                                                {item?.siteName ?? ""}
                                            </Typography>
                                            <Typography variant="subtitle1">
                                                ({item?.distance ?? ""} km away)
                                            </Typography>
                                        </Stack>
                                    </>
                                }
                                secondary={
                                    <React.Fragment>
                                        {userDetails?.tradeName != '' && userDetails?.tradeName != null &&
                                            (
                                                <Typography
                                                    sx={{ width: '70%' }}
                                                // component="span"
                                                // variant="body2"
                                                // color="text.primary"
                                                >
                                                    {item?.address !== undefined ? item?.address + ", " : ''}
                                                    {item?.city !== undefined ? item?.city + ", " : ''}
                                                    {item?.province + ", "}{item?.postalCode + ", "}
                                                    {item?.country}
                                                </Typography>
                                            )
                                        }
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Stack>
                            <GoogleMap
                                options={{ fullscreenControl: false }}
                                center={{ lat: item?.latitude ?? 0, lng: item?.longitude ?? 0 }}
                                zoom={13}
                                mapContainerStyle={containerStyle}
                            >
                                <Marker
                                    position={{ lat: item?.latitude ?? 0, lng: item?.longitude ?? 0 }}
                                    icon={{
                                        url: mapMarkerYellow,
                                        scaledSize: (
                                            new window.google.maps.Size(30, 40)
                                        )
                                    }} />
                            </GoogleMap>
                        </Stack>
                    </Stack>
                    <Divider />
                </DialogContent>
                <DialogActions>

                    {
                        item?.userId != localStorage?.userId ?
                            btnLoader ?
                                <Button style={{ backgroundColor: BIBlue, color: 'white', width: '90%', margin: 'auto' }}><CircularProgress size={24} style={{ color: 'white' }} /></Button>
                                :
                                <Button onClick={onMessgaeClick} style={{ backgroundColor: BIBlue, color: 'white', width: '90%', margin: 'auto' }}>Message</Button>
                            : null
                    }
                </DialogActions>
            </Dialog>
            {
                pictureViewerOpen &&
                <PictureViewer
                    isOpen={pictureViewerOpen}
                    onClose={() => setPictureViewerOpen(false)}
                    pictures={item?.pictureList.map(pic => (
                        {
                            src: pic.name,
                            alt: ""
                        }
                    ))}
                    viewerIndex={pictureViewIndex}
                />
            }
        </>
    )
}

export default ItemDetailsModal