import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Modal, IconButton } from "@material-ui/core";
import {
    Close as CloseIcon, PlayCircleFilled
} from "@material-ui/icons";
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import { useRef } from 'react';
import { BIBlue } from '../../assets/buildidColors';
import { useState } from 'react';

function PreviewMontageModal(props) {

    const musicRef = useRef(null);
    const [play, setPlay] = useState(false);

    const handlePlay = () => {
        if (play) {
            setPlay(false);
            musicRef.current.muted = true;
        }
        else {
            setPlay(true);
            if (props.musicFileUrl !== "") {
                try {
                    musicRef.current.play();
                    musicRef.current.muted = false;
                }
                catch (err) {
                    console.log(err);
                }
            }
        }
    }

    return (
        <>
            <audio id="sliderAudio" ref={musicRef} src={props.musicFileUrl || ""} />

            <Modal
                open={props.isOpen}
                onClose={() => { setPlay(false); musicRef.current.pause(); musicRef.current.currentTime = 0; props.setMusicFileUrl(""); props.onClose() }}>
                <div className="modal-center">
                    <div className="modal-card" style={{ width: "500px", height: "auto" }}>
                        <div className="modal-card-header-buttons">
                            <IconButton
                                color="primary"
                                onClick={() => { setPlay(false); musicRef.current.pause(); musicRef.current.currentTime = 0; props.setMusicFileUrl(""); props.onClose() }}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <div className="modal-card-body">
                            <div className="flex-container-vertical" onMouseOver={() => props.setToggleBtn(true)} onMouseOut={() => props.setToggleBtn(false)}>
                                <div className="input-container">
                                    <div style={{ position: 'absolute', top: '50%', left: '45%', zIndex: props.toggleBtn ? '99999' : "0" }}>
                                        <IconButton style={{ color: BIBlue, transition: props.toggleBtn && '0.8s' }} onClick={() => handlePlay()}>
                                            {play ? <PauseCircleFilledIcon /> : <PlayCircleFilled />}
                                        </IconButton>
                                    </div>
                                    <Carousel
                                        autoPlay={play}
                                        infiniteLoop
                                        interval={5000}
                                        showArrows={false}
                                        showThumbs={false}
                                        showStatus={false}
                                        showIndicators={false}
                                        stopOnHover={false}
                                    // width="100%"
                                    >
                                        {props.imageList?.map((item, picIndex) => {
                                            return (
                                                <div key={picIndex} style={{ display: 'flex', justifyContent: 'center', width: "100%", height: "400px", objectFit: 'contain' }}>
                                                    <img src={item.fileUrl} alt="" />
                                                    {item.imageDescription == "" ? <></> :
                                                        <div className='card-desc-div'>
                                                            <p className='card-descriptions' >
                                                                {item.imageDescription}
                                                            </p>
                                                        </div>}
                                                </div>
                                            )
                                        })}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default PreviewMontageModal;