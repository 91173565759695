import React, { useState, useEffect } from "react";
import {
  IconButton,
  TextField,
  Button,
  Modal,
  Tooltip,
  Select,
  MenuItem,
} from "@material-ui/core";

import {
  Close as CloseIcon,
  Save as SaveIcon,
  Home as HomeIcon,
  RemoveCircle as RemoveCircleIcon,
  Mail as MailIcon,
  Phone as PhoneIcon,
  Error as ErrorOutlineOutlinedIcon,
} from "@material-ui/icons";

import Loader from "../../../components/Loader";
import PromptModal from "../../../components/PromptModal";
import InputMask from "react-input-mask";

import {
  getUserAddress,
  fetchUserEmails2,
  fetchUserPhones2,
  saveUserAddress,
  getUserDashboard,
  sendSMSToUserPhone,
  isValidUserPhoneResetCode,
} from "../../../api/UserApi";
import {
  saveUserEmails,
  saveUserPhones,
  deleteUserEmails,
  deleteUserPhones,
  isValidUserEmailResetCode,
  resendUserEmailVerificationCode,
} from "../../../api/UserApi";
import { isEqual } from "lodash"; // JSON object comparison function

import AlertModal from "../../../components/AlertModal";
import OtpInput from "react-otp-input";
import moment from "moment";
import {
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import { FormControl, InputAdornment } from "@mui/material";
import {
  Autocomplete as GooglePlacesAutocomplete,
  LoadScript,
} from "@react-google-maps/api";
import { getProvinceByCountryName } from "../../../api/CompanyApi";
import { BIGrey } from "../../../assets/buildidColors";

export default function ContactInfoCard(props) {
  const [address, setAddress] = useState(null);
  const [phones, setPhones] = useState([]);
  const [emails, setEmails] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [formModalOpen, setFormModalOpen] = useState(false);

  // On load and mode change, get profile details from API
  useEffect(() => {
    try {
      Promise.all([
        getUserAddress(localStorage.userId),
        fetchUserEmails2({ SearchList: [{ UserId: localStorage.userId }] }),
        fetchUserPhones2({ SearchList: [{ UserId: localStorage.userId }] }),
        getUserDashboard(localStorage.userId),
      ]).then(([addressRes, emailRes, phoneRes, dashboardRes]) => {
        setAddress({
          id: addressRes?.id || 0,
          userId: addressRes?.userId || localStorage.userId,
          address1: addressRes?.address1 || "",
          address2: addressRes?.address2 || "",
          city: addressRes?.city || "",
          province: addressRes?.province || "",
          postalCode: addressRes?.postalCode || "",
          country: addressRes?.country || "",
          modifiedBy: addressRes?.modifiedBy || localStorage.userId,
        });

        let emailData = [];
        if (emailRes) {
          emailRes.forEach((email) => {
            emailData.push({
              id: email.id,
              userId: email.userId || localStorage.userId,
              emailType: email.emailType,
              email: email.email,
              verified: email.verified,
              isPrivateEmail: email.isPrivateEmail,
              isPublicEmail: email.isPublicEmail,
              modifiedBy: email.modifiedBy || localStorage.userId,
            });
          });
        }

        setEmails(emailData);

        let phoneData = [];
        if (phoneRes) {
          phoneRes.forEach((phone) => {
            phoneData.push({
              id: phone.id,
              userId: phone.userId || localStorage.userId,
              phoneType: phone.phoneType,
              phoneNo: phone.phoneNo,
              countryCode: phone.countryCode,
              verified: phone.verified,
              isPublicPhone: phone.isPublicPhone,
              isPrivatePhone: phone.isPrivatePhone,
              modifiedBy: phone.modifiedBy || localStorage.userId,
            });
          });
        }

        setPhones(phoneData);
        setProfileData(dashboardRes);
      });
    } catch (error) {
      console.log("Error", error);
      throw error;
    }
  }, [formModalOpen]);

  if (props.loading) {
    return <></>;
  }

  return (
    <div className="exp-card" style={{ maxHeight: "1350px" }}>
      <div
        className="flex-container-horizontal"
        style={{ alignItems: "center" }}
      >
        <h3 className="flex-container-left">About</h3>
        <div className="flex-container-right ">
          <Button
            size="small"
            className=" input-container"
            variant="contained"
            color="primary"
            onClick={() => {
              setFormModalOpen(true);
            }}
          >
            Edit
          </Button>
        </div>
      </div>

      <ContactInfoView
        address={address}
        emails={emails}
        phones={phones}
        profileData={profileData}
        onAddAddressClick={() => {
          setFormModalOpen(true);
        }}
      />
      {formModalOpen && (
        <ContactInfoForm
          address={address}
          emails={emails}
          phones={phones}
          isOpen={formModalOpen}
          onClose={() => {
            setFormModalOpen(false);
          }}
        />
      )}
    </div>
  );
}

// View-mode body for ContactInfoCard
function ContactInfoView(props) {
  const [verifyEmailModalOpen, setVerifyEmailModalOpen] = useState(false);

  const [verifyPhoneModalOpen, setVerifyPhoneModalOpen] = useState(false);

  return (
    <>
      <Modal
        open={verifyEmailModalOpen}
        onClose={() => {
          setVerifyEmailModalOpen(false);
        }}
      >
        <div className="modal-center">
          <div className="modal-card" style={{ width: "400px" }}>
            <div className="modal-card-body">
              <div className="flex-container-verticle">
                <p>This Email is still needs to be verified.</p>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    color="primary"
                    onClick={() => {
                      setVerifyEmailModalOpen(false);
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={verifyPhoneModalOpen}
        onClose={() => {
          setVerifyPhoneModalOpen(false);
        }}
      >
        <div className="modal-center">
          <div className="modal-card" style={{ width: "400px" }}>
            <div className="modal-card-body">
              <div className="flex-container-verticle">
                <p>This Phone Number is still needs to be verified.</p>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    color="primary"
                    onClick={() => {
                      setVerifyPhoneModalOpen(false);
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div
        className="flex-container-horizontal "
        style={{ justifyContent: "space-between", maxHeight: "1250px" }}
      >
        <div style={{ flex: 1 }}>
          <List sx={{ padding: 0 }}>
            <div className="flex-container-horizontal">
              <div style={{ marginTop: "10px" }}>
                <MailIcon color="primary" fontSize="small" />
              </div>


							<div style={{ marginLeft: "15px", marginTop: "5px", flex: 1, maxHeight: "540px", overflow: "scroll" }}>

								{
									localStorage?.IsPrivateEmail !== "true" && (
										<ListItemText
											primary={localStorage.primaryEmail}
											secondary={"Primary"}
										/>
									)
								}

								{(props.emails.length > 0) ?
									<>{props?.emails?.map((email, index) => (
										<ListItem
											key={index}
											secondaryAction={
												(email.verified) ? "" :
													<Tooltip title="Verify Email">
														<IconButton onClick={(event) => { setVerifyEmailModalOpen(true) }}>
															<ErrorOutlineOutlinedIcon fontSize="small" />
														</IconButton>
													</Tooltip>
											}
											disablePadding>
											<ListItemText
												sx={{ color: (email.verified) ? 'black' : 'GrayText' }}
												primary={email.email}
												secondary={<>
													{(email.emailType === "P") ? "Personal" : ""}
													{(email.emailType === "W") ? "Work" : ""}
													{(email.emailType === "O") ? "Other" : ""}
												</>}
											/>
										</ListItem>
									))}</> : <></>}
							</div>
						</div>
					</List>

          {props.phones.length > 0 ? (
            <>
              <hr />
              <List sx={{ padding: 0 }}>
                <div className="flex-container-horizontal">
                  <div style={{ marginTop: "10px" }}>
                    <PhoneIcon color="primary" fontSize="small" />
                  </div>

                  <div
                    style={{
                      marginLeft: "15px",
                      marginTop: "5px",
                      flex: 1,
                      maxHeight: "540px",
                      overflow: "scroll",
                    }}
                  >
                    {props.phones?.map((num, index) => (
                      <ListItem
                        key={index}
                        secondaryAction={
                          num.verified ? (
                            ""
                          ) : (
                            <Tooltip title="Verify Number">
                              <IconButton
                                onClick={(event) => {
                                  setVerifyPhoneModalOpen(true);
                                }}
                              >
                                <ErrorOutlineOutlinedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )
                        }
                        disablePadding
                      >
                        <ListItemText
                          sx={{ color: num.verified ? "black" : "GrayText" }}
                          primary={num.phoneNo}
                          secondary={
                            <>
                              {num.phoneType === "H" ? "Home" : ""}
                              {num.phoneType === "M" ? "Mobile" : ""}
                              {num.phoneType === "W" ? "Work" : ""}
                              {num.phoneType === "O" ? "Other" : ""}
                            </>
                          }
                        />
                      </ListItem>
                    ))}
                  </div>
                </div>
              </List>
            </>
          ) : (
            <></>
          )}
          <hr />
          <div className="flex-container-horizontal">
            <div className="content-container">
              <HomeIcon color="primary" fontSize="small" />
            </div>
            <div>
              <div style={{ padding: "10px" }}>
                {
                  // Only show address section if there is something to display
                  props.address &&
                  (props.address.address1 !== "" ||
                    props.address.address2 !== "" ||
                    props.address.city !== "" ||
                    props.address.province !== "" ||
                    props.address.postalCode !== "" ||
                    props.address.country !== "") ? (
                    <>
                      {
                        // Show address1 if not empty
                        props.address.address1 !== "" &&
                        props.address.address2 !== "" ? (
                          <Typography variant="body2">
                            {props.address.address1}, {props.address.address2}
                          </Typography>
                        ) : (
                          <></>
                        )
                      }
                      {
                        // Only show comma if both city and province are visible
                        props.address.city !== "" &&
                        props.address.province !== "" ? (
                          <Typography variant="body2">
                            {props.address.city}, {props.address.province}{" "}
                            {props.address.postalCode}
                          </Typography>
                        ) : (
                          <Typography variant="body2">
                            {props.address.city} {props.address.province}{" "}
                            {props.address.postalCode}
                          </Typography>
                        )
                      }
                      {
                        // Show space between postal code and country if both are non-empty
                        props.address.country !== "" ? (
                          <Typography variant="body2">
                            {props.address.country}
                          </Typography>
                        ) : (
                          <></>
                        )
                      }
                    </>
                  ) : (
                    <>
                      <div
                        className="flex-container-horizontal"
                        style={{
                          justifyContent: "space-between",
                          color: "gray",
                        }}
                      >
                        <Typography variant="body2">
                          {" "}
                          Add an address to take advantage of the ride share
                          opportunities in the BuildID community.
                        </Typography>
                      </div>
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => {
                          props.onAddAddressClick();
                        }}
                        style={{ margin: "5px", marginLeft: "0px" }}
                      >
                        Add My Address
                      </Button>
                    </>
                  )
                }
              </div>
            </div>
          </div>

          <hr />
          <div
            className="flex-container-horizontal"
            style={{
              justifyContent: "space-between",
              color: "gray",
              paddingTop: "10px",
            }}
          >
            <Typography variant="body2">
              {" "}
              Joined {moment(props.profileData.joinedBuildIdDate).format("LL")}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

function ContactInfoForm(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [address, setAddress] = useState(props.address);
  const [emails, setEmails] = useState(props.emails);
  const [phones, setPhones] = useState(props.phones);
  const [emailsToDelete, setEmailsToDelete] = useState([]);
  const [phonesToDelete, setPhonesToDelete] = useState([]);

  const [isEmailModified, setIsEmailModified] = useState(false);
  const [isPhoneModified, setIsPhoneModified] = useState(false);
  const [isAddressModified, setIsAddressModified] = useState(false);

  const [verifyEmailModalOpen, setVerifyEmailModalOpen] = useState(false);
  const [clickedEmail, setClickedEmail] = useState("");
  const [clickedEmailId, setClickedEmailId] = useState("");

  const [verifyPhoneModalOpen, setVerifyPhoneModalOpen] = useState(false);
  const [clickedPhone, setClickedPhone] = useState("");
  const [clickedPhoneId, setClickedPhoneId] = useState("");
  const [clickedCountryCode, setClickedCountryCode] = useState("");

  const countries = ["Canada", "United Kingdom", "United States"]; // Add more countries as needed
  const [provinceList, setProvinceList] = useState([]);

  const [scriptLibraries] = useState(["places"]);
  const [autocomplete, setAutocomplete] = useState(null);

  const [modalDateChecker, setModalDateChecker] = useState(null);
  const [modalDescription, setModalDescription] = useState(null);

  useEffect(async () => {
    if (address?.country !== "") {
      const obj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        NrOfRecPerPage: 50,
        SearchList: [
          {
            Country:
              address?.country === "United States" ? "USA" : address?.country,
          },
        ],
      };
      await getProvinceByCountryName(obj)
        .then((res) => {
          setProvinceList(res);
        })
        .catch((err) => {
          console.log("err while getting province:-", err);
        });
    }
  }, [address?.country]);

  async function sendMessage(item) {
    try {
      const smsObj = {
        userId: item.userId,
        phoneNo: Number(item.phoneNo),
        countryCode: item.countryCode,
      };
      await sendSMSToUserPhone(smsObj);
    } catch (error) {
      console.log("error:", error);
    }
  }

  // Changes a email type in the state array
  function handleEmailCheckChange(newType, index) {
    let newEmailsData = [...emails];
    newEmailsData[index].isPublicEmail = newType;
    setEmails(newEmailsData);
    setIsEmailModified(true);
  }
  // Changes a email type in the state array
  function handleEmailTypeChange(newType, index) {
    let newEmailsData = [...emails];
    newEmailsData[index].emailType = newType;
    setEmails(newEmailsData);
    setIsEmailModified(true);
  }

  // Changes a email in the state array
  function handleEmailChange(newEmail, index) {
    let newEmailsData = [...emails];
    newEmailsData[index].email = newEmail;
    setEmails(newEmailsData);
  }

  // Removes an email item from the emails state array
  async function removeEmail(index) {
    let keepEmails = [...emails];
    let deletedEmail = keepEmails.splice(index, 1)[0]; // Remove email at index

    // Add deletedEmail to array for API delete (on save)
    let newDeleteArray = [...emailsToDelete];
    newDeleteArray.push(deletedEmail);
    await setEmailsToDelete(newDeleteArray);

    setEmails(keepEmails);
  }

  // Adds a new (type: personal) email item to the emails state array, with empty email string
  function addEmail() {
    let newEmailArray = [...emails];
    newEmailArray.push({
      id: 0, // New items hardcoded id=0
      userId: localStorage.userId,
      emailType: "P", // Type: Personal
      email: "",
      modifiedBy: localStorage.userId,
      verified: true,
      isPublicEmail: false,
      isPrivateEmail: false,
    });

    setEmails(newEmailArray);
    setIsEmailModified(true);
  }

  // Changes a phone type in the state array
  function handlePhoneTypeChange(newType, index) {
    let newPhonesData = [...phones];
    newPhonesData[index].phoneType = newType;
    setPhones(newPhonesData);
    setIsPhoneModified(true);
  }

  function handlePhoneCheckChange(newType, index) {
    let newPhonesData = [...phones];
    newPhonesData[index].isPublicPhone = newType;
    setPhones(newPhonesData);
    setIsPhoneModified(true);
  }

  function handleCountryCodeChange(countrycodeType, index) {
    let newPhonesData = [...phones];
    newPhonesData[index].countryCode = countrycodeType;
    setPhones(newPhonesData);
    setIsPhoneModified(true);
  }

  // Changes a phone in the state array
  function handlePhoneChange(newPhone, index) {
    let newPhonesData = [...phones];
    newPhonesData[index].phoneNo = newPhone;
    setPhones(newPhonesData);
  }

  // Removes a phone item in the phones state array
  async function removePhone(index) {
    let keepPhones = [...phones];
    let deletedPhone = keepPhones.splice(index, 1)[0]; // Remove phone at index

    // Add deletedPhone to array for API delete (on save)
    let newDeleteArray = [...phonesToDelete];
    newDeleteArray.push(deletedPhone);

    await setPhonesToDelete(newDeleteArray);

    // Update local display
    setPhones(keepPhones);
  }

  // Adds a new (type: home) phone item to the phones state array, with empty phone string
  function addPhone() {
    let newPhoneArray = [...phones];
    newPhoneArray.push({
      id: 0, // New items hardcoded id=0
      userId: Number(localStorage.userId),
      phoneType: "H", // Type: Home
      phoneNo: "",
      modifiedBy: Number(localStorage.userId),
      countryCode: "",
      verified: true,
      isPublicPhone: false,
      isPrivatePhone: false,
    });

    setPhones(newPhoneArray);
    setIsPhoneModified(true);
  }

  function clearAddress() {
    setAddress({
      id: 0,
      userId: Number(localStorage.userId),
      address1: "",
      address2: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
      modifiedBy: Number(localStorage.userId),
    });
    setIsAddressModified(true);
  }

  /* Helper function for handleCloseButtonClick() that compares each element of 2 arrays to
		determine if they match (assumes both arrays are sorted in the same order) */
  function arraysMatch(arrayA, arrayB) {
    let match = true;

    if (arrayA.length !== arrayB.length) match = false;

    arrayA.forEach((itemA, index) => {
      if (!isEqual(itemA, arrayB[index])) match = false;
    });

    return match;
  }

  // If form has changes, then show discard confirmation popup
  function handleCloseButtonClick() {
    if (
      !isEqual(address, props.address) ||
      !arraysMatch(emails, props.emails) ||
      !arraysMatch(phones, props.phones) ||
      !arraysMatch(emailsToDelete, []) ||
      !arraysMatch(phonesToDelete, [])
    ) {
      setDiscardModalOpen(true);
    } else {
      props.onClose();
    }
  }

  function editAboutModalClose() {
    if (
      !isEqual(address, props.address) ||
      !arraysMatch(emails, props.emails) ||
      !arraysMatch(phones, props.phones) ||
      !arraysMatch(emailsToDelete, []) ||
      !arraysMatch(phonesToDelete, [])
    ) {
      setDiscardModalOpen(true);
    } else {
      setIsEditAboutModal(false);
      props.onClose();
    }
  }

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phoneNumberString;
  };

  async function handleSaveButtonClick() {
    try {
      let showEmailError = false;
      let showPhoneError = false;
      let emailsToSave = [];
      let phonesToSave = [];

      if (isEmailModified) {
        const deleteEmailArray = emailsToDelete.filter((p) => p.id != 0);
        if (deleteEmailArray.length > 0) {
          await deleteUserEmails(deleteEmailArray);
        }

        let emailRegexp = new RegExp(
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        );
        const vaildEmail = emails.filter(
          (e) => e.email != "" && emailRegexp.test(e.email)
        );
        if (vaildEmail.length === emails.length) {
          showEmailError = false;
          emailsToSave = [...vaildEmail];
        } else {
          emailsToSave = [];
          setModalDateChecker("company-name");
          setModalDescription("Enter Valid Email Id");
          showEmailError = true;
          return;
        }
      }

      if (showEmailError) {
        return;
      }

      if (isPhoneModified) {
        const vaildPhoneNumber = phones.filter((p) => p.phoneNo !== "");
        if (vaildPhoneNumber.length === phones.length) {
          const vaildPhoneNumberCode = phones.filter(
            (p) => p.countryCode !== ""
          );
          if (vaildPhoneNumberCode.length === phones.length) {
            showPhoneError = false;
            phonesToSave = [...vaildPhoneNumber];
          } else {
            phonesToSave = [];
            setModalDateChecker("Invalid Phone Error");
            setModalDescription("Please enter a valid Country Code.");
            showPhoneError = true;
            return;
          }
        } else {
          phonesToSave = [];
          setModalDateChecker("Invalid Phone Error");
          setModalDescription("Please enter a valid Phone Number.");
          showPhoneError = true;
          return;
        }
      }

      if (showPhoneError) {
        return;
      }

      setIsLoading(true);
      if (emailsToSave.length !== 0) {
        const emailIdArray = emailsToSave.map((em) => {
          return {
            Id: em.id,
            UserId: Number(em.userId),
            EmailType: em.emailType,
            Email: em.email,
            ModifiedBy: Number(em.userId),
            isPublicEmail: em.isPublicEmail,
            isPrivateEmail: em.isPrivateEmail,
          };
        });
        await saveUserEmails(emailIdArray);
      }

      if (phonesToSave.length > 0) {
        const phoneArray = phonesToSave.map((ph) => {
          return {
            Id: ph.id,
            UserId: Number(ph.userId),
            PhoneType: ph.phoneType,
            PhoneNo: ph.phoneNo.replace(/[^a-zA-Z0-9]/g, ""),
            ModifiedBy: Number(ph.userId),
            CountryCode: ph.countryCode,
            isPublicPhone: ph.isPublicPhone,
            isPrivatePhone: ph.isPrivatePhone,
          };
        });

        await saveUserPhones(phoneArray);
      }

      const deletePhoneArray = phonesToDelete.filter((p) => p.id != 0);
      if (deletePhoneArray.length > 0) {
        await deleteUserPhones(deletePhoneArray);
      }

      if (isAddressModified) {
        const addressObj = {
          Id: address.id,
          UserId: address.userId,
          Address1: address.address1,
          Address2: address.address2,
          Country: address.country,
          Province: address.province,
          City: address.city,
          PostalCode: address.postalCode,
          CreatedBy: address.userId,
          ModifiedBy: address.userId,
          DateCreated: moment().format(),
          DateModified: moment().format(),
        };

        await saveUserAddress(addressObj);
      }
      props.onClose();
      setIsLoading(false);
    } catch (error) {
      console.log("error --->", error);
      setIsLoading(false);
    }
  }

  const [isEditAboutModal, setIsEditAboutModal] = useState(props.isOpen);

  function verifyEmailModalClose() {
    setVerifyEmailModalOpen(false);
  }

  function openVerifyEmailModal(email) {
    setClickedEmail(email.email);
    setClickedEmailId(email.id);
    setVerifyEmailModalOpen(true);
  }

  async function handleSearch() {
    let place = autocomplete.getPlace();
    if (place?.address_components) {
      const street =
        place?.address_components.find((address) =>
          address.types.includes("street_number")
        )?.long_name ?? "";
      const route =
        place?.address_components.find((address) =>
          address.types.includes("route")
        )?.long_name ?? "";

      setAddress({
        ...address,
        city:
          place?.address_components.find((address) =>
            address.types.includes("locality")
          )?.long_name ?? "",
        province:
          place?.address_components.find((address) =>
            address.types.includes("administrative_area_level_1")
          )?.long_name ?? "",
        country:
          place?.address_components.find((address) =>
            address.types.includes("country")
          )?.long_name ?? "",
        address1: place?.street != "" ? street + " " + route : route,
        postalCode:
          place?.address_components.find((address) =>
            address.types.includes("postal_code")
          )?.long_name ?? "",
      });
      setIsAddressModified(true);
    }
  }

  return (
    <>
      {/* EMAIL MODAL ---> */}

      <AlertModal
        isOpen={modalDateChecker !== null}
        onClose={() => {
          setModalDateChecker(null);
          setModalDescription(null);
        }}
        body={modalDescription}
        expCloseIcon={true}
      />

      <Modal
        open={verifyEmailModalOpen}
        onClose={() => verifyEmailModalClose()}
      >
        <div className="modal-center">
          <div className="modal-card" style={{ width: "400px" }}>
            <div className="modal-card-body">
              <VerifyEmail
                email={clickedEmail}
                emailId={clickedEmailId}
                closeForm={() => {
                  setVerifyEmailModalOpen(false);
                }}
                oncloseModal={() => {
                  props.onClose();
                }}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={verifyPhoneModalOpen}
        onClose={() => {
          setVerifyPhoneModalOpen(false);
        }}
      >
        <div className="modal-center">
          <div className="modal-card" style={{ width: "400px" }}>
            <div className="modal-card-body">
              <VerifyPhone
                phone={clickedPhone}
                phoneId={clickedPhoneId}
                countryCode={clickedCountryCode}
                closeForm={() => {
                  setVerifyPhoneModalOpen(false);
                }}
                oncloseModal={() => {
                  props.onClose();
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={isEditAboutModal} onClose={() => editAboutModalClose()}>
        <div className="modal-center" style={{ width: "600px" }}>
          <div className="modal-card">
            <div className="modal-card-body" style={{ padding: "15px" }}>
              <div
                className="flex-container-horizontal"
                style={{ justifyContent: "space-between" }}
              >
                <IconButton color="primary" onClick={handleCloseButtonClick}>
                  <CloseIcon />
                </IconButton>

                <IconButton
                  disabled={
                    isEmailModified ||
                    isPhoneModified ||
                    isAddressModified == true
                      ? false
                      : true
                  }
                  color="primary"
                  onClick={handleSaveButtonClick}
                >
                  <SaveIcon />
                </IconButton>
              </div>
              <div className="flex-container-horizontal">
                <PromptModal
                  isOpen={discardModalOpen}
                  onClose={() => setDiscardModalOpen(false)}
                  title="Are you sure you want to leave?"
                  desc="Any unsaved changes will be discarded."
                  redButtonText="Discard"
                  blueButtonTextFirst="Go Back"
                  onRedButtonClick={props.onClose}
                />
                <div
                  style={{
                    maxHeight: "550px",
                    overflow: "auto",
                    padding: "0px 15px",
                    width: "100%",
                  }}
                >
                  {
                    // Form body
                    isLoading ? (
                      <Loader />
                    ) : (
                      <div style={{ flex: 1 }}>
                        <div className="content-container">
                          <h2 style={{ paddingLeft: "10px" }}>Edit About</h2>
                        </div>
                        {/* Primary Email (uneditable) field */}
                        <div style={{ marginLeft: "17px" }}>
                          <Typography style={{ color: BIGrey }}>
                            Selected emails and phone numbers will be visible to
                            your Builder Network.
                          </Typography>
                          <Typography
                            style={{
                              marginTop: "10px",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            Emails
                          </Typography>
                        </div>
                        <div className="flex-container-horizontal">
                          <div className="input-container"></div>
                          <div className="input-container">
                            <div
                              style={{
                                backgroundColor: "lightgrey",
                                borderRadius: "5px",
                              }}
                            >
                              <Select
                                variant="outlined"
                                value="P"
                                disabled
                                style={{ width: "110px" }}
                              >
                                <MenuItem value="P">Primary</MenuItem>
                              </Select>
                            </div>
                          </div>
                          <div className="input-container" style={{ flex: 1 }}>
                            <div
                              style={{
                                backgroundColor: "lightgrey",
                                borderRadius: "5px",
                              }}
                            >
                              <TextField
                                id={"email-textfield-primary"}
                                variant="outlined"
                                defaultValue={localStorage.primaryEmail}
                                fullWidth
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        {
                          // Non-primary emails list
                          emails.map((email, index) => (
                            <div key={index}>
                              {!email.verified ? (
                                <div
                                  className="flex-container-verticle"
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    boxShadow: "0px 0px 3px gray",
                                    borderRadius: "5px",
                                    margin: "13px 10px",
                                  }}
                                >
                                  <div className="input-container">
                                    <p>
                                      <span>
                                        <b>{email.email}</b>
                                      </span>{" "}
                                      needs to be verified
                                    </p>
                                  </div>
                                  <div
                                    className="flex-container-horizontal"
                                    style={{
                                      justifyContent: "space-between",
                                      width: "50%",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      onClick={() => {
                                        removeEmail(index);
                                        setIsEmailModified(true);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        openVerifyEmailModal(email)
                                      }
                                    >
                                      Verify
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  key={index}
                                  className="flex-container-horizontal"
                                  style={{ alignItems: "center" }}
                                >
                                  <div className="input-container">
                                    <Checkbox
                                      color="primary"
                                      checked={email.isPublicEmail}
                                      onChange={(event) =>
                                        handleEmailCheckChange(
                                          event.target.checked,
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="input-container">
                                    <Select
                                      name={"email-select"}
                                      variant="outlined"
                                      style={{ width: "110px" }}
                                      value={email.emailType}
                                      onChange={(event) =>
                                        handleEmailTypeChange(
                                          event.target.value,
                                          index
                                        )
                                      }
                                    >
                                      <MenuItem value="P">Personal</MenuItem>
                                      <MenuItem value="W">Work</MenuItem>
                                      <MenuItem value="O">Other</MenuItem>
                                    </Select>
                                  </div>
                                  <div
                                    className="input-container"
                                    style={{ width: "100%" }}
                                  >
                                    <TextField
                                      key={index}
                                      id={"email-textfield-" + index}
                                      label="Email"
                                      placeholder="name@buildid.com"
                                      variant="outlined"
                                      defaultValue={email.email}
                                      onChange={(event) => {
                                        if (event.target.value != "") {
                                          setIsEmailModified(true);
                                        } else {
                                          setIsEmailModified(false);
                                        }
                                        handleEmailChange(
                                          event.target.value,
                                          index
                                        );
                                      }}
                                      fullWidth
                                    />
                                  </div>
                                  {/* {!isValid && <p >Invalid email address</p>} */}
                                  <div className="input-container">
                                    <IconButton
                                      id={index}
                                      color="secondary"
                                      onClick={() => {
                                        setIsEmailModified(true);
                                        removeEmail(index);
                                      }}
                                    >
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))
                        }
                        <div className="input-container">
                          <Button color="primary" fullWidth onClick={addEmail}>
                            Add Email
                          </Button>
                        </div>

                        <hr style={{ marginBottom: "10px" }} />
                        <div style={{ marginLeft: "17px" }}>
                          <Typography
                            style={{
                              marginTop: "10px",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            Phone Numbers
                          </Typography>
                        </div>

                        {
                          // Phone form
                          phones.map((num, index) => (
                            <div style={{ paddingTop: "10px" }} key={index}>
                              {!num.verified ? (
                                <div
                                  className="flex-container-verticle"
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    boxShadow: "0px 0px 3px gray",
                                    borderRadius: "5px",
                                    margin: "12px 0px",
                                  }}
                                >
                                  <div className="input-container">
                                    <p>
                                      <span>
                                        <b>{formatPhoneNumber(num.phoneNo)}</b>
                                      </span>{" "}
                                      needs to be verified
                                    </p>
                                  </div>
                                  <div
                                    className="flex-container-horizontal"
                                    style={{
                                      justifyContent: "space-between",
                                      width: "50%",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      onClick={() => {
                                        removePhone(index);
                                        setIsPhoneModified(true);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        setClickedPhone(num.phoneNo);
                                        setClickedPhoneId(num.id);
                                        setClickedCountryCode(num.countryCode);
                                        setVerifyPhoneModalOpen(true);
                                        sendMessage(num);
                                      }}
                                    >
                                      Verify
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  key={index}
                                  className="flex-container-horizontal"
                                  style={{ alignItems: "center" }}
                                >
                                  <div className="input-container">
                                    <Checkbox
                                      color="primary"
                                      checked={num.isPublicPhone}
                                      onChange={(event) =>
                                        handlePhoneCheckChange(
                                          event.target.checked,
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="input-container">
                                    <Select
                                      name={"phone-select"}
                                      variant="outlined"
                                      style={{ width: "110px" }}
                                      value={num.phoneType}
                                      onChange={(event) =>
                                        handlePhoneTypeChange(
                                          event.target.value,
                                          index
                                        )
                                      }
                                    >
                                      <MenuItem value="H">Home</MenuItem>
                                      <MenuItem value="M">Mobile</MenuItem>
                                      <MenuItem value="W">Work</MenuItem>
                                      <MenuItem value="O">Other</MenuItem>
                                    </Select>
                                  </div>
                                  <div className="input-container">
                                    <Select
                                      variant="outlined"
                                      value={num.countryCode}
                                      onChange={(event) =>
                                        handleCountryCodeChange(
                                          event.target.value,
                                          index
                                        )
                                      }
                                    >
                                      <MenuItem value="+1">+1</MenuItem>
                                      {/* <MenuItem value="+91">+91</MenuItem> */}
                                      <MenuItem value="+44">+44</MenuItem>
                                    </Select>
                                  </div>
                                  <div
                                    className="input-container"
                                    style={{ flex: 1 }}
                                  >
                                    <InputMask
                                      key={index}
                                      mask="(999) 999 - 9999"
                                      alwaysShowMask={true}
                                      value={num.phoneNo}
                                      onChange={(event) => {
                                        if (event.target.value != "") {
                                          setIsPhoneModified(true);
                                        } else {
                                          setIsPhoneModified(false);
                                        }
                                        handlePhoneChange(
                                          event.target.value,
                                          index
                                        );
                                      }}
                                      disabled={false}
                                      maskChar=" "
                                    >
                                      {() => (
                                        <TextField
                                          id={"phone-textfield-" + index}
                                          label="Phone Number"
                                          placeholder="(123) 456-7890"
                                          variant="outlined"
                                          fullWidth
                                        />
                                      )}
                                    </InputMask>
                                  </div>
                                  <div className="input-container">
                                    <IconButton
                                      id={index}
                                      color="secondary"
                                      onClick={() => {
                                        removePhone(index);
                                        setIsPhoneModified(true);
                                      }}
                                    >
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))
                        }

                        <div className="input-container">
                          <Button color="primary" fullWidth onClick={addPhone}>
                            Add Phone
                          </Button>
                        </div>

                        <hr style={{ marginBottom: "10px" }} />

                        {/* Address Form */}

                        <div style={{ marginLeft: "17px" }}>
                          <Typography
                            style={{
                              marginTop: "10px",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            Address
                          </Typography>
                          <Typography style={{ color: BIGrey }}>
                            Take advantage of the Ride Share opportunities in
                            the BuildID community. Your address is never
                            displayed and only used to match you with people in
                            your area.
                          </Typography>
                        </div>
                        <div
                          style={{ padding: "10px 0px", marginLeft: "12px" }}
                        >
                          <div className="input-container">
                            <FormControl variant="outlined" fullWidth>
                              <TextField
                                select
                                variant="outlined"
                                label="Select Country"
                                value={address?.country ?? ""}
                                onChange={(e) => {
                                  setAddress({
                                    ...address,
                                    country: e.target.value,
                                  });
                                  setIsAddressModified(true);
                                }}
                              >
                                {countries.map((c, index) => (
                                  <MenuItem key={c} value={c}>
                                    {c}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </FormControl>
                          </div>

                          <div className="input-container">
                            <GooglePlacesAutocomplete
                              onLoad={(newInstance) => {
                                setAutocomplete(newInstance);
                              }}
                              onPlaceChanged={handleSearch}
                              options={{
                                componentRestrictions: {
                                  country: ["US", "CA", "GB"],
                                }, // Restrict to USA, Canada, and UK
                              }}
                            >
                              <TextField
                                variant="outlined"
                                placeholder="Address"
                                fullWidth
                                value={address?.address1 ?? ""}
                                onChange={(event) => {
                                  setAddress({
                                    ...address,
                                    address1: event.target.value,
                                  });
                                  setIsAddressModified(true);
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {
                                        // Only show "X" button when field not empty
                                        address?.address1 !== "" ? (
                                          <IconButton
                                            onClick={() =>
                                              setAddress({
                                                ...address,
                                                address1: "",
                                              })
                                            }
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        ) : (
                                          <></>
                                        )
                                      }
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </GooglePlacesAutocomplete>
                          </div>

                          <div className="input-container">
                            <TextField
                              label="Apartment"
                              variant="outlined"
                              fullWidth
                              value={address?.address2 ?? ""}
                              onChange={(e) => {
                                setAddress({
                                  ...address,
                                  address2: e.target.value,
                                });
                                setIsAddressModified(true);
                              }}
                            />
                          </div>
                          <div className="input-container">
                            <TextField
                              label="City"
                              variant="outlined"
                              fullWidth
                              value={address?.city ?? ""}
                              onChange={(e) => {
                                setAddress({
                                  ...address,
                                  city: e.target.value,
                                });
                                setIsAddressModified(true);
                              }}
                            />
                          </div>
                          {address?.country !== "United Kingdom" ? (
                            <div className="input-container">
                              <FormControl variant="outlined" fullWidth>
                                <TextField
                                  select
                                  label="Province"
                                  variant="outlined"
                                  value={address?.province ?? ""}
                                  onChange={(e) => {
                                    setAddress({
                                      ...address,
                                      province: e.target.value,
                                    });
                                    setIsAddressModified(true);
                                  }}
                                >
                                  {provinceList.map((p, i) => (
                                    <MenuItem key={p} value={p.province}>
                                      {p.province}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </FormControl>
                            </div>
                          ) : (
                            <> </>
                          )}

                          <div className="input-container">
                            <TextField
                              label={
                                address?.country !== "United States"
                                  ? "Postal Code"
                                  : "State"
                              }
                              variant="outlined"
                              fullWidth
                              value={address?.postalCode ?? ""}
                              onChange={(e) => {
                                setAddress({
                                  ...address,
                                  postalCode: e.target.value,
                                });
                                setIsAddressModified(true);
                              }}
                            />
                          </div>
                        </div>

													<div className="content-container">
														<h2 style={{ paddingLeft: '10px' }}>Edit About</h2>
													</div>
													{/* Primary Email (uneditable) field */}
													<div style={{ marginLeft: '17px' }}>
														<Typography style={{ color: BIGrey }}>
															Selected emails and phone numbers will be visible to your Builder Network.
														</Typography>
														<Typography style={{ marginTop: '10px', fontWeight: 'bold', fontSize: '16px' }}>
															Emails
														</Typography>
													</div>
													{localStorage?.IsPrivateEmail !== "true" &&
														(<div className="flex-container-horizontal">
															<div className="input-container">
															</div>
															<div className="input-container">
																<div style={{ backgroundColor: "lightgrey", borderRadius: "5px" }}>
																	<Select
																		variant="outlined"
																		value="P"
																		disabled
																		style={{ width: "110px" }}>
																		<MenuItem value="P">Primary</MenuItem>
																	</Select>
																</div>
															</div>
															<div className="input-container" style={{ flex: 1 }}>
																<div style={{ backgroundColor: "lightgrey", borderRadius: "5px" }}>
																	<TextField
																		id={"email-textfield-primary"}
																		variant="outlined"
																		defaultValue={localStorage.primaryEmail}
																		fullWidth
																		disabled />
																</div>
															</div>
														</div>
														)}

													{	// Non-primary emails list
														emails.map((email, index) => (
															<div key={index}>
																{!email.verified ?
																	<div className="flex-container-verticle" style={{ alignItems: "center", display: 'flex', flexDirection: 'column', boxShadow: '0px 0px 3px gray', borderRadius: '5px', margin: '13px 10px' }}>
																		<div className="input-container">
																			<p><span><b>{email.email}</b></span> needs to be verified</p>
																		</div>
																		<div className="flex-container-horizontal" style={{ justifyContent: 'space-between', width: '50%', marginBottom: '15px' }}>
																			<Button
																				variant="contained"
																				onClick={() => {
																					removeEmail(index)
																					setIsEmailModified(true);
																				}}>Delete</Button>
																			<Button
																				variant="contained"
																				color="primary"
																				onClick={() => openVerifyEmailModal(email)}
																			>Verify</Button>
																		</div>
																	</div> :
																	<div key={index} className="flex-container-horizontal" style={{ alignItems: "center" }}>
																		<div className="input-container">
																			<Checkbox color="primary"
																				checked={email.isPublicEmail}
																				onChange={event => handleEmailCheckChange(event.target.checked, index)}
																			/>
																		</div>
																		<div className="input-container">
																			<Select
																				name={"email-select"}
																				variant="outlined"
																				style={{ width: "110px" }}
																				value={email.emailType}
																				onChange={event => handleEmailTypeChange(event.target.value, index)}>
																				<MenuItem value="P">Personal</MenuItem>
																				<MenuItem value="W">Work</MenuItem>
																				<MenuItem value="O">Other</MenuItem>
																			</Select>
																		</div>
																		<div className="input-container" style={{ width: '100%' }}>
																			<TextField
																				key={index}
																				id={"email-textfield-" + index}
																				label="Email"
																				placeholder="name@buildid.com"
																				variant="outlined"
																				defaultValue={email.email}
																				onChange={event => {
																					if (event.target.value != '') {
																						setIsEmailModified(true);
																					} else {
																						setIsEmailModified(false);
																					}
																					handleEmailChange(event.target.value, index)
																				}}
																				fullWidth />
																		</div>
																		{/* {!isValid && <p >Invalid email address</p>} */}
																		<div className="input-container">
																			<IconButton
																				id={index}
																				color="secondary"
																				onClick={() => {
																					setIsEmailModified(true);
																					removeEmail(index)
																				}}>
																				<RemoveCircleIcon />
																			</IconButton>
																		</div>
																	</div>}
															</div>
														))
													}
													<div className="input-container">
														<Button
															color="primary"
															fullWidth
															onClick={addEmail}>
															Add Email
														</Button>
													</div>

													<hr style={{ marginBottom: "10px" }} />
													<div style={{ marginLeft: '17px' }}>
														<Typography style={{ marginTop: '10px', fontWeight: 'bold', fontSize: '16px' }}>
															Phone Numbers
														</Typography>
													</div>

													{	// Phone form
														phones.map((num, index) => (
															<div style={{ paddingTop: '10px' }} key={index}>
																{!num.verified ?
																	<div className="flex-container-verticle" style={{ alignItems: "center", display: 'flex', flexDirection: 'column', boxShadow: '0px 0px 3px gray', borderRadius: '5px', margin: '12px 0px' }}>
																		<div className="input-container">
																			<p><span><b>{formatPhoneNumber(num.phoneNo)}</b></span> needs to be verified</p>
																		</div>
																		<div className="flex-container-horizontal" style={{ justifyContent: 'space-between', width: '50%', marginBottom: '15px' }}>
																			<Button
																				variant="contained"
																				onClick={() => {
																					removePhone(index);
																					setIsPhoneModified(true);
																				}}>Delete</Button>
																			<Button
																				variant="contained"
																				color="primary"
																				onClick={() => {
																					setClickedPhone(num.phoneNo);
																					setClickedPhoneId(num.id);
																					setClickedCountryCode(num.countryCode);
																					setVerifyPhoneModalOpen(true);
																					sendMessage(num);
																				}}
																			>Verify</Button>
																		</div>
																	</div> :
																	<div key={index} className="flex-container-horizontal" style={{ alignItems: "center" }}>
																		<div className="input-container">
																			<Checkbox color="primary"
																				checked={num.isPublicPhone}
																				onChange={event => handlePhoneCheckChange(event.target.checked, index)}
																			/>
																		</div>
																		<div className="input-container">
																			<Select
																				name={"phone-select"}
																				variant="outlined"
																				style={{ width: "110px" }}
																				value={num.phoneType}
																				onChange={event => handlePhoneTypeChange(event.target.value, index)}>
																				<MenuItem value="H">Home</MenuItem>
																				<MenuItem value="M">Mobile</MenuItem>
																				<MenuItem value="W">Work</MenuItem>
																				<MenuItem value="O">Other</MenuItem>
																			</Select>
																		</div>
																		<div className="input-container">
																			<Select
																				variant="outlined"
																				value={num.countryCode}
																				onChange={event => handleCountryCodeChange(event.target.value, index)}
																			>
																				<MenuItem value="+1">+1</MenuItem>
																				{/* <MenuItem value="+91">+91</MenuItem> */}
																				<MenuItem value="+44">+44</MenuItem>
																			</Select>
																		</div>
																		<div className="input-container" style={{ flex: 1 }}>
																			<InputMask
																				key={index}
																				mask="(999) 999 - 9999"
																				alwaysShowMask={true}
																				value={num.phoneNo}
																				onChange={event => {
																					if (event.target.value != '') {
																						setIsPhoneModified(true);
																					} else {
																						setIsPhoneModified(false);
																					}
																					handlePhoneChange(event.target.value, index)
																				}}
																				disabled={false}
																				maskChar=" ">
																				{() => (
																					<TextField
																						id={"phone-textfield-" + index}
																						label="Phone Number"
																						placeholder="(123) 456-7890"
																						variant="outlined"
																						fullWidth />
																				)}
																			</InputMask>

																		</div>
																		<div className="input-container">
																			<IconButton
																				id={index}
																				color="secondary"
																				onClick={() => {
																					removePhone(index);
																					setIsPhoneModified(true);
																				}}>
																				<RemoveCircleIcon />
																			</IconButton>
																		</div>
																	</div>}
															</div>
														))
													}

													<div className="input-container">
														<Button
															color="primary"
															fullWidth
															onClick={addPhone}>
															Add Phone
														</Button>
													</div>

													<hr style={{ marginBottom: "10px" }} />


													{/* Address Form */}


													<div style={{ marginLeft: '17px' }}>
														<Typography style={{ marginTop: '10px', fontWeight: 'bold', fontSize: '16px' }}>
															Address
														</Typography>
														<Typography style={{ color: BIGrey }}>
															Take advantage of the Ride Share opportunities in the BuildID community. Your address is never displayed and only used to match you with people in your area.
														</Typography>

													</div>
													<div style={{ padding: '10px 0px', marginLeft: '12px' }}>
														<div className="input-container">
															<FormControl variant="outlined" fullWidth>
																<TextField
																	select
																	variant="outlined"
																	label="Select Country"
																	value={address?.country ?? ""}
																	onChange={(e) => {
																		setAddress({ ...address, country: e.target.value })
																		setIsAddressModified(true);
																	}}
																>
																	{countries.map((c, index) => (
																		<MenuItem key={c} value={c}>
																			{c}
																		</MenuItem>
																	))}
																</TextField>
															</FormControl>
														</div>

														<div className="input-container">

															<GooglePlacesAutocomplete
																onLoad={newInstance => {
																	setAutocomplete(newInstance)
																}}
																onPlaceChanged={handleSearch}
																options={{
																	componentRestrictions: { country: ['US', 'CA', 'GB'] }, // Restrict to USA, Canada, and UK
																}}
															>
																<TextField
																	variant="outlined"
																	placeholder="Address"
																	fullWidth
																	value={address?.address1 ?? ""}
																	onChange={(event) => {
																		setAddress({ ...address, address1: event.target.value })
																		setIsAddressModified(true);
																	}}
																	InputProps={{
																		endAdornment: <InputAdornment position="end">
																			{	// Only show "X" button when field not empty
																				(address?.address1 !== "")
																					? (
																						<IconButton
																							onClick={() => setAddress({ ...address, address1: "" })}>
																							<CloseIcon />
																						</IconButton>
																					)
																					: <></>
																			}
																		</InputAdornment>,
																	}} />
															</GooglePlacesAutocomplete>

														</div>

														<div className="input-container">
															<TextField
																label="Apartment"
																variant="outlined"
																fullWidth
																value={address?.address2 ?? ""}
																onChange={(e) => {
																	setAddress({ ...address, address2: e.target.value })
																	setIsAddressModified(true)
																}}
															/>
														</div>
														<div className="input-container">
															<TextField
																label="City"
																variant="outlined"
																fullWidth
																value={address?.city ?? ""}
																onChange={(e) => {
																	setAddress({ ...address, city: e.target.value })
																	setIsAddressModified(true)
																}}
															/>
														</div>
														{
															address?.country !== "United Kingdom" ?

																<div className="input-container">
																	<FormControl variant="outlined" fullWidth>
																		<TextField
																			select
																			label="Province"
																			variant="outlined"
																			value={address?.province ?? ""}
																			onChange={(e) => {
																				setAddress({ ...address, province: e.target.value })
																				setIsAddressModified(true)
																			}}
																		>
																			{provinceList.map((p, i) => (
																				<MenuItem key={p} value={p.province}>
																					{p.province}
																				</MenuItem>
																			))}
																		</TextField>
																	</FormControl>
																</div>

																: <> </>
														}

														<div className="input-container">
															<TextField
																label={address?.country !== "United States" ? "Postal Code" : "State"}
																variant="outlined"
																fullWidth
																value={address?.postalCode ?? ""}
																onChange={(e) => {
																	setAddress({ ...address, postalCode: e.target.value })
																	setIsAddressModified(true)
																}}
															/>
														</div>
													</div>


													<hr style={{ marginBottom: "10px" }} />
													<div className="input-container">
														<Button
															color="primary"
															fullWidth
															onClick={clearAddress}
														>
															Clear Address
														</Button>
													</div>

												</div>
											)
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}

function VerifyEmail(props) {
  const [isSaving, setIsSaving] = useState(false);
  const [otp, setOtp] = useState("");
  const [alertModalOpen_OtpError, setAlertModalOpen_OtpError] = useState(false);

  const [alertModalOpen_EmailVerified, setAlertModalOpen_EmailVerified] =
    useState(false);
  const [openAlertModal_CodeReceived, setOpenAlertModal_CodeReceived] =
    useState(false);

  const [alertModalOpen_IncorrectOtp, setAlertModalOpen_IncorrectOtp] =
    useState(false);

  function handleCancelButtonClick() {
    props.closeForm();
  }
  function handlecloseButtonClick() {
    props.oncloseModal();
  }

  function handleOtpChange(otp) {
    setOtp(otp);
  }

  async function handleResendOtp() {
    try {
      const res = await resendUserEmailVerificationCode(
        localStorage.userId,
        props.emailId
      );
      if (res) {
        setOpenAlertModal_CodeReceived(true);
      }
    } catch (error) {}
  }

  async function onVerifyClicked() {
    if (otp.length < 6) {
      setAlertModalOpen_OtpError(true);
      return false;
    } else {
      setIsSaving(true);
      try {
        const res = await isValidUserEmailResetCode(
          localStorage.userId,
          props.emailId,
          otp
        );

        if (res) {
          setIsSaving(false);
          setAlertModalOpen_EmailVerified(true);
        }
      } catch (error) {
        setAlertModalOpen_IncorrectOtp(true);
        setIsSaving(false);
      }
    }
  }

  return isSaving ? (
    <Loader />
  ) : (
    <div>
      <AlertModal
        isOpen={alertModalOpen_IncorrectOtp}
        onClose={() => setAlertModalOpen_IncorrectOtp(false)}
        title="Verification Code Error"
        body="The verification code entered is incorrect."
      />

      <AlertModal
        isOpen={alertModalOpen_OtpError}
        onClose={() => setAlertModalOpen_OtpError(false)}
        title="Reset Code Error"
        body="Please enter a valid reset code."
      />

      <AlertModal
        isOpen={alertModalOpen_EmailVerified}
        onClose={handlecloseButtonClick}
        title="Email Verified!"
        body={
          <>
            <b> {props.email} </b> has been verified!
          </>
        }
      />

      <AlertModal
        isOpen={openAlertModal_CodeReceived}
        onClose={() => setOpenAlertModal_CodeReceived(false)}
        title="Verification code resend"
        body={
          <>
            The verification code has been resent to <b> {props.email} </b>.
          </>
        }
      />

      <div className="modal-center">
        <div className="modal-otp">
          <div className="modal-card-header-buttons">
            <IconButton color="primary" onClick={handleCancelButtonClick}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className="modal-card-body">
            <h3>Verify Email </h3>
            <p style={{ padding: "10px 0px" }}>
              Enter the 6 digit code sent to <b>{props.email}</b> to verify that
              this email belongs to you.
            </p>
            <p className="link" onClick={handleResendOtp}>
              {" "}
              Didn't receive a code?
            </p>

            <div
              className="flex-container-horizontal"
              style={{ justifyContent: "center" }}
            >
              <div className="input-container">
                <OtpInput
                  value={otp}
                  isInputNum={true}
                  onChange={(otp) => handleOtpChange(otp)}
                  numInputs={6}
                  inputStyle={{
                    width: "45px",
                    height: "45px",
                    margin: "5px",
                    fontSize: "1rem",
                    borderRadius: 4,
                    marginBottom: "20px",
                    border: "1px solid #484848",
                  }}
                />
              </div>
            </div>

            <div
              className="flex-container-horizontal"
              style={{ justifyContent: "center" }}
            >
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={onVerifyClicked}
              >
                <b>Verify</b>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function VerifyPhone(props) {
  const [isSaving, setIsSaving] = useState(false);
  const [otp, setOtp] = useState("");
  const [alertModalOpen_OtpError, setAlertModalOpen_OtpError] = useState(false);

  const [alertModalOpen_EmailVerified, setAlertModalOpen_EmailVerified] =
    useState(false);
  const [openAlertModal_CodeReceived, setOpenAlertModal_CodeReceived] =
    useState(false);

  const [alertModalOpen_IncorrectOtp, setAlertModalOpen_IncorrectOtp] =
    useState(false);

  function handleCancelButtonClick() {
    props.closeForm();
  }

  function handleCloseButtonClick() {
    props.oncloseModal();
  }

  function handleOtpChange(otp) {
    setOtp(otp);
  }

  async function sendMessage(item) {
    try {
      const smsObj = {
        userId: localStorage.userId,
        phoneNo: Number(item.phone),
        countryCode: item.countryCode,
      };
      const res = await sendSMSToUserPhone(smsObj);
      if (res) {
        setOpenAlertModal_CodeReceived(true);
      }
    } catch (error) {
      console.log("error:", error);
    }
  }

  async function onVerifyClicked() {
    if (otp.length < 6) {
      setAlertModalOpen_OtpError(true);
      return false;
    } else {
      setIsSaving(true);
      try {
        const res = await isValidUserPhoneResetCode(
          localStorage.userId,
          props.phoneId,
          otp
        );

        if (res) {
          setIsSaving(false);
          setAlertModalOpen_EmailVerified(true);
        }
      } catch (error) {
        setAlertModalOpen_IncorrectOtp(true);
        setIsSaving(false);
      }
    }
  }

  return isSaving ? (
    <Loader />
  ) : (
    <div>
      <AlertModal
        isOpen={alertModalOpen_IncorrectOtp}
        onClose={() => setAlertModalOpen_IncorrectOtp(false)}
        title="Verification Code Error"
        body="The verification code entered is incorrect."
      />

      <AlertModal
        isOpen={alertModalOpen_OtpError}
        onClose={() => setAlertModalOpen_OtpError(false)}
        title="Reset Code Error"
        body="Please enter a valid reset code."
      />

      <AlertModal
        isOpen={alertModalOpen_EmailVerified}
        onClose={handleCloseButtonClick}
        title="Phone Number Verified!"
        body={
          <>
            <b> {props.phone} </b> has been verified!
          </>
        }
      />

      <AlertModal
        isOpen={openAlertModal_CodeReceived}
        onClose={() => setOpenAlertModal_CodeReceived(false)}
        title="Verification code resend"
        body={
          <>
            The verification code has been resent to <b> {props.phone} </b>.
          </>
        }
      />

      <div className="modal-center">
        <div className="modal-otp">
          <div className="modal-card-header-buttons">
            <IconButton color="primary" onClick={handleCancelButtonClick}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className="modal-card-body">
            <h3>Verify Phone </h3>
            <p style={{ padding: "10px 0px" }}>
              Enter the 6 digit code sent to <b>{props.phone}</b> to verify that
              this Phone belongs to you.
            </p>
            <p
              className="link"
              onClick={() => {
                sendMessage(props);
              }}
            >
              {" "}
              Didn't receive a code?
            </p>

            <div
              className="flex-container-horizontal"
              style={{ justifyContent: "center" }}
            >
              <div className="input-container">
                <OtpInput
                  value={otp}
                  isInputNum={true}
                  onChange={(otp) => handleOtpChange(otp)}
                  numInputs={6}
                  inputStyle={{
                    width: "45px",
                    height: "45px",
                    margin: "5px",
                    fontSize: "1rem",
                    borderRadius: 4,
                    marginBottom: "20px",
                    border: "1px solid #484848",
                  }}
                />
              </div>
            </div>

            <div
              className="flex-container-horizontal"
              style={{ justifyContent: "center" }}
            >
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={onVerifyClicked}
              >
                <b>Verify</b>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
