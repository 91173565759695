import React from "react";

import { Modal, Button, TextField, FormControlLabel } from "@material-ui/core";
import { useState } from "react";
import { saveDataIssues } from "../api/UserApi";
import AlertModal from "./AlertModal";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { Checkbox } from "@mui/material";
/*
Popup component for data reporting function
ReportModal code in parent must be under any other modals in order to appear on top
Props (* required):
	*isOpen				- boolean; modal visibility variable
	*onClose			- function; to modify visibility state variable

Usage:
	<ReportModal
		isOpen={}
		onClose={() => }/>
*/

function ReportModal(props) {
  const [reportDescription, setReportDescription] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [reportModalAlert, setReportModalAlert] = useState(false);
  const [reason, setReason] = React.useState({
    Suggestion: false,
    Incorrect: false,
    Offensive_Inappropriate: false,
    Something_Else: false,
  });

  const { addToast } = useToasts();
  const { Suggestion, Incorrect, Offensive_Inappropriate, Something_Else } =
    reason;

  function handleDescription(e) {
    setReportDescription(e.target.value);
  }

  async function handleReportClick() {
    const obj = {
      UserId: Number(localStorage.userId),
      ReturnRecordId: false,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: 0,
          RecordId: props.recordId || 0,
          DataSource: props.name,
          DataIncorrect: selectedOption === "Incorrect" ? true : false,
          DataOffensive:
            selectedOption === "Offensive_Inappropriate" ? true : false,
          DataOther: selectedOption === "Something_Else" ? true : false,
          DataSuggestion: selectedOption === "Suggestion" ? true : false,
          DataComments: reportDescription,
          ModifiedBy: Number(localStorage.userId),
        },
      ],
    };
    const res = await saveDataIssues(obj);
    if (res.message === "OK") {
      // alert("Report Successfully Sent")
      // setReportModalAlert(true);
      addToast("Report Sent Successfully...", {
        style: {
          cursor: "pointer",
          marginTop: "50px",
          background: "#fce7b0",
          color: "orange",
        },
        appearance: "success",
        autoDismiss: true,
      });
      setSelectedOption("");
      setReportDescription("");
      setReason({
        Suggestion: false,
        Incorrect: false,
        Offensive_Inappropriate: false,
        Something_Else: false,
      });
      props.onClose();
    } else {
      props.onClose();
    }
  }

  async function handleCancelClick() {
    setSelectedOption("");
    setReportDescription("");
    setReason({
      Suggestion: false,
      Incorrect: false,
      Offensive_Inappropriate: false,
      Something_Else: false,
    });
    props.onClose();
  }

  function handleCheckboxChange(e) {
    if (e.target.name === "Incorrect") {
      setSelectedOption("Incorrect");
      setReason({
        Incorrect: true,
        Offensive_Inappropriate: false,
        Something_Else: false,
        Suggestion: false,
      });
    } else if (e.target.name === "Offensive_Inappropriate") {
      setSelectedOption("Offensive_Inappropriate");
      setReason({
        Incorrect: false,
        Offensive_Inappropriate: true,
        Suggestion: false,
        Something_Else: false,
      });
    } else if (e.target.name === "Something_Else") {
      setSelectedOption("Something_Else");
      setReason({
        Incorrect: false,
        Offensive_Inappropriate: false,
        Suggestion: false,
        Something_Else: true,
      });
    } else if (e.target.name === "Suggestion") {
      setSelectedOption("Suggestion");
      setReason({
        Incorrect: false,
        Offensive_Inappropriate: false,
        Something_Else: false,
        Suggestion: true,
      });
    }
  }

  return (
    <>
      {/* {
				    left: 50%;
					width: 630px;
					margin-left: 5px;
				
} */}
      <AlertModal
        isOpen={reportModalAlert}
        onClose={() => {
          props.onClose();
          setSelectedOption("");
          setReportDescription("");
          setReason({
            Suggestion: false,
            Incorrect: false,
            Offensive_Inappropriate: false,
            Something_Else: false,
          });
        }}
        title={"Report Sent Successfully..."}
      />
      <ToastProvider>
        <Modal
          open={props.isOpen}
          onClose={() => {
            props.onClose();
            setSelectedOption("");
            setReportDescription("");
            setReason({
              Suggestion: false,
              Incorrect: false,
              Offensive_Inappropriate: false,
              Something_Else: false,
            });
          }}
        >
          <div
            className="modal-center"
            style={{
              left:
                props?.name == "Ad" || props?.name == "Post" ? "50%" : "56%",
              width:
                props?.name == "Ad" || props?.name == "Post"
                  ? "630px"
                  : "800px",
              marginLeft:
                props?.name == "Ad" || props?.name == "Post" ? "5px" : "0px",
            }}
          >
            <div className="modal-card">
              <div className="modal-card-body">
                <div style={{ paddingBottom: "20px" }}>
                  <h4>Is something wrong with this data?</h4>

                  <div className="content-container">
                    <h3>{props?.name}</h3>
                    {
                      // TODO
                    }
                  </div>

                  <p>This data is:</p>

                  <div className="flex-container-horizontal">
                    <div style={{ width: "50%" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Suggestion}
                            onChange={handleCheckboxChange}
                            name="Suggestion"
                          />
                        }
                        label="Suggestion/Requests"
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Incorrect}
                            onChange={handleCheckboxChange}
                            name="Incorrect"
                          />
                        }
                        label="Incorrect Information"
                      />
                    </div>
                  </div>
                  <div className="flex-container-horizontal">
                    <div style={{ width: "50%" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Offensive_Inappropriate}
                            onChange={handleCheckboxChange}
                            name="Offensive_Inappropriate"
                          />
                        }
                        label="Offensive Or Inappropriate"
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Something_Else}
                            onChange={handleCheckboxChange}
                            name="Something_Else"
                          />
                        }
                        label="Something Else"
                      />
                    </div>
                  </div>

                  <TextField
                    variant="outlined"
                    autoFocus={true}
                    fullWidth
                    multiline
                    minRows={5}
                    placeholder="Comments"
                    onChange={(e) => handleDescription(e)}
                    value={reportDescription}
                  />
                </div>

                <div
                  className="flex-container-horizontal"
                  style={{ justifyContent: "space-between" }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    // onClick={props.onClose
                    onClick={handleCancelClick}
                  >
                    <b>Cancel</b>
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={reportDescription.length === 0}
                    onClick={handleReportClick}
                  >
                    Report
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </ToastProvider>
    </>
  );
}

export default ReportModal;
