import React, { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Carousel } from "react-responsive-carousel";
import Welcome from "./caroselcontent/Welcome";
import Tickets from "./caroselcontent/Tickets";
import BuildIDLogo from "./../../../assets/images/Build-ID-Transparent.png";
import Sites from "./caroselcontent/Sites";
import Rewards from "./caroselcontent/Rewards";
import Chat from "./caroselcontent/Chat";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useEffect } from "react";

const TakeTour = (props) => {
  const [open, setOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  const handleButtonClick = () => {
    setActiveSlide(0);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if(props?.closeOpenTourFromSignUp)
    props?.closeOpenTourFromSignUp();
  };

  const handleSlideChange = (index) => {
    setActiveSlide(index);
  };

  useEffect(()=>{
    if(props?.refSignUpPage){
      handleButtonClick();
    }
  },[])

  return (
    <div className="taketour">
      { !props?.refSignUpPage &&
      <Button onClick={handleButtonClick} color="primary">
        Take A Tour
      </Button>}

      <Dialog open={open} onClose={handleClose} >
        <DialogTitle>
          <div className="logonbutton">
            {activeSlide !== 0 && (
              <img style={{ height: "50px" }} src={BuildIDLogo} alt="" />
            )}
            <div style={{ flex: 1 }} />
            {activeSlide !== 4 && (
              <IconButton edge="end" color="primary" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </DialogTitle>
        <DialogContent className="dialogcontent">
          <Carousel
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            dynamicHeight={false}
            width="100%"
            selectedItem={activeSlide}
            onChange={handleSlideChange}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              const style = {
                width: "8px",
                height: "8px",
                margin: "0 5px",
                background: isSelected ? "yellow" : "gray",
                borderRadius: "50%",
                cursor: "pointer",
                display: "inline-block",
                textAlign: "center",
              };
              return (
                <li
                  style={style}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  title={`${label} ${index + 1}`}
                />
              );
            }}
          >
            <Welcome />
            <Tickets />
            <Sites />
            <Rewards />
            <Chat onClose={handleClose} />
          </Carousel>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TakeTour;

