import React, { useEffect, useState } from "react";
import {
  Modal,
  IconButton,
  Button,
  TextField,
  Box,
  Grid,
  Typography,
  Avatar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Close as CloseIcon } from "@material-ui/icons";
import AlertModal from "../../components/AlertModal";
import moment from "moment";
import {
  getRecentlyInteractUsers,
  saveRecentlyInteractUsers,
} from "../../api/UserApi";
import { getUserContacts } from "../../api/ContactsApi";
import { Divider } from "@mui/material";

export default function SendTimesheet(props) {
  const [toSendWith, setToSendWith] = useState([]);
  const [sendTimesheetAlert, setSendTimeSheetAlert] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [recentInteractUsers, setRecentInteractUsers] = useState([]);

  async function getContacts() {
    const res = await getUserContacts(localStorage.userId);
    setSearchData(res);
  }

  async function fetchRecentInteractUsers() {
    const res = await getRecentlyInteractUsers(localStorage?.userId);
    setRecentInteractUsers(res);
  }

  useEffect(() => {
    getContacts();
    fetchRecentInteractUsers();
  }, []);

  const handleInputChange = (e) => {
    setNewEmail(e.target.value.trim());
  };
  function handleChange(_, newValue) {
    handleEmail(newValue);
  }
  const handleEmail = (email) => {
    let tmpArr = email.map((item) => {
      if (item.contactEmail) {
        return {
          contactEmail: item.contactEmail,
          contactId: item.contactId,
        };
      } else {
        return item;
      }
    });
    setNewEmail("");
    setToSendWith(tmpArr);
  };
  const handleRecentInteractUserClick = (user) => {
    if (user && user.interactUserEmail) {
      setToSendWith((prevToSendWith) => [
        ...prevToSendWith,
        {
          contactEmail: user.interactUserEmail,
          contactId: user.interactUserId,
        },
      ]);
    }
  };

  const handleSendTimeSheet = () => {
    const selectedUsers = toSendWith.map((user) => {
      if (user.contactEmail) {
        return user?.contactEmail;
      } else {
        return user;
      }
    });

    props.handleSendUnsentTimeSheet(selectedUsers).then((resp) => {
      if (resp.message === "OK") {
        props.closeTimeSheetModal(false);
        toSendWith.map(async (item) => {
          const interactionObj = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                Id: 0,
                UserId: Number(localStorage.userId),
                InteractUserId: item.contactId,
                ModifiedBy: Number(localStorage.userId),
                fromScreen: "TimeSheet",
              },
            ],
          };
          saveRecentlyInteractUsers(interactionObj);
        });
        fetchRecentInteractUsers(localStorage?.userId);
        setToSendWith([]);
        props.setLoading(true);
        setSendTimeSheetAlert(true);
        props.clearCheckBox();
        props.setLoading(false);
        props.setSendButtonVisible();
        props.getSentTimesheet();
      }
    });
    props.setSendButtonVisible();
  };

  return (
    <>
      <AlertModal
        isOpen={sendTimesheetAlert}
        onClose={() => setSendTimeSheetAlert(false)}
        title={"Timesheet Sent Successfully..."}
      />

      <Modal open={props.isOpen} onClose={props.onClose}>
        <div className="modal-center">
          <div className="modal-card" style={{ width: "500px" }}>
            <div className="modal-card-header-buttons">
              <IconButton color="primary" onClick={props.onClose}>
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-card-body">
              <div className="flex-container-vertical">
                <h2>Send Timesheet</h2>

                <div className="input-container" style={{ flex: 1 }}>
                  <div
                    className="flex-container-horizontal"
                    style={{ alignItems: "stretch" }}>
                    <Autocomplete
                      id="sendWith-autocomplete"
                      inputValue={newEmail}
                      multiple
                      freeSolo
                      filterSelectedOptions
                      options={newEmail.length >= 2 ? searchData : []}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.contactEmail) {
                          return option.contactEmail;
                        }
                        if (option.contactName) {
                          return option.contactName;
                        }
                      }}
                      renderOption={(props, option) => {
                        return (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}>
                            <div style={{ display: "flex" }}>
                              <img
                                style={{
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                                loading="lazy"
                                width="50px"
                                height="50px"
                                src={props.contactPictureUrl}
                                alt=""
                              />
                              <div>
                                {props.contactName} <br />
                                {props.contactEmail}
                              </div>
                            </div>
                          </Box>
                        );
                      }}
                      value={toSendWith}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          onChange={handleInputChange}
                          onBlur={(e) => {
                            if (e.target.value !== "") {
                              setToSendWith(toSendWith.concat(e.target.value));
                              setNewEmail("");
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 32 || e.key === 32) {
                              if (e.target.value !== "") {
                                setToSendWith(
                                  toSendWith.concat(e.target.value)
                                );
                                setNewEmail("");
                              }
                            }
                          }}
                          fullWidth
                          label="Add Emails"
                          value={newEmail}
                        />
                      )}
                      style={{ flex: 1 }}
                    />
                    {
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "10px" }}
                        disabled={toSendWith.length !== 0 ? false : true}
                        onClick={handleSendTimeSheet}>
                        Send
                      </Button>
                    }
                  </div>
                </div>
              </div>

              <Grid container spacing={2} className="flex-container-vertical">
                <Grid item xs={12}>
                  <Typography variant="h5">
                    <b>Recent</b>
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-around" }}>
                  {recentInteractUsers.map((user) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={user.userId}>
                      <div onClick={() => handleRecentInteractUserClick(user)}>
                        <div
                          style={{
                            display: "flex",
                          }}>
                          <div>
                            <Avatar
                              style={{ width: "40px", height: "40px" }}
                              alt={user.interactUserName}
                              src={user.interactUserImageUrl}
                            />
                          </div>
                          <div style={{ marginLeft: "10px" }}>
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}>
                              <b>
                                {user.interactUserName.length > 7
                                  ? user.interactUserName.substring(0, 7) +
                                    "..."
                                  : user.interactUserName}
                              </b>
                              <br />
                              {user.interactUserTradeName.length > 8
                                ? user.interactUserTradeName.substring(0, 8) +
                                  "..."
                                : user.interactUserTradeName}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <div className="flex-container-vertical">
                <span style={{ marginTop: "10px" }}>Expenses to Send</span>
                <Divider
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    borderBottom: "2px solid #EDEDED",
                  }}
                />
                {props.selectedTimeSheet.map((timesheets, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    <div>
                      <h4>
                        {moment(props.timesheetDate).format("MMMM DD, YYYY")}
                      </h4>
                      <span>
                        {timesheets.noOfJobs} Jobs, {timesheets.totalHours} hrs
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
