import React, { useState, useEffect } from "react";
import { Button, Checkbox, IconButton, ListItemText, Typography, } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Box, Stack, Modal, ListItemButton, CircularProgress, Chip } from "@mui/material";
import ProductSearchField from "./ProductSearchField";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchProducts, saveUsersExperiencesProducts } from "../api/SitesApi";
import { deleteUserProducts, saveUserProducts } from "../api/ProductsApi";
import { BIBlue } from "../assets/buildidColors";
import Loader from "./Loader";

const SelectProductModal = (props) => {
  const [pageNo, setPageNo] = useState(1);
  const [product, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [noData, setNoData] = useState();
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(props?.selectedProducts ?? []);
  const [showSelectedProduct, setShowSelectedProduct] = useState(false);
  const [productType, setProductType] = useState(null);
  const [existingProductList, setExistingProductList] = useState(props?.selectedProducts ?? [])
  const [loadingData, setLoadingData] = useState(false);
  const [deleteLoadingData, setDeleteLoadingData] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [unSelectedProductList, setUnSelectedProductList] = useState([]);


  useEffect(() => {
    handleFetchProducts(1, searchTerm, productType);
  }, []);

  useEffect(() => {
    setSelectedProduct(props?.selectedProducts ?? [])
  }, [props?.selectedProducts])

  async function handleFetchProducts(pageNo, searchTerm, productType) {
    try {
      let filterDataFor;
      if (productType) {
        filterDataFor = productType === "Tools" ? "Tool" : "Material";
      }
      let subObj = productType ? { 'ProductType': filterDataFor, name: searchTerm } : { name: searchTerm }
      const obj = {
        PageNr: pageNo,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: "true",
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: "true",
        SearchList: [subObj],
        DoNotSearchInSystemFields: true,
        SortList: [{ FieldName: "Name", Direction: "ASC" }],
      };
      const res = await fetchProducts(obj);
      const noOfRecords = res?.nrOfRecords;
      setNoData(noOfRecords);
      if (pageNo > 1) {
        setTimeout(() => {
          setProducts((prevProducts) => [...prevProducts, ...res.data]);
        }, 500);
      } else {
        setProducts(res.data);
      }

      if (res?.data?.length === 0) {
        setAllDataLoaded(true);
      }
      if (res?.data?.length < 20) {
        setAllDataLoaded(true);
      } else {
        setAllDataLoaded(false);
      }
      setPageNo(pageNo + 1);
    } catch (e) {
      console.log("SAVE_FOLLOW__[ERROR]:", e);
    }
  }

  const handleCloseModal = async () => {
    // new code sanket 15-03-2024
    props?.onClose();
    if (props.from === "profile") {
      await props.handleFetchProducts();
    }
  };

  const handleSaveProduct = async () => {
    try {
      setLoadingData(true);
      if (selectedProduct?.length != 0) {
        const productIds = selectedProduct.map(item => item.productId).join(',');
        const saveObj = {
          UserId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              Id: 0,
              UserId: Number(localStorage.userId),
              UserExperinceId: 0,
              ProductIdList: productIds,
              ModifiedBy: Number(localStorage.userId)
            }
          ]
        }
        await saveUsersExperiencesProducts(saveObj)
      }
      if (props.from === "profile") {
        await props.handleFetchProducts();
      }
      props?.onClose();
      setIsModified(false);
      setLoadingData(false);
    } catch (error) {
      console.log("handleSaveProduct Error", error)
      setLoadingData(false);
    }
  };


  const handleSaveProductForExperience = () => {
    props?.onSelectClick(selectedProduct, unSelectedProductList);
    props?.onClose();
  }

  const onProductClick = async (product) => {
    if (props.from === "profile") {
      const isPresent = selectedProduct.filter((item) => item?.productId === product?.id)
      if (isPresent.length > 0) {
        let getUpdatedlist = selectedProduct.filter(
          (item) => item?.productId !== product?.id
        );

        const deleteProduct = existingProductList.filter((item) => item?.productId === product?.id)

        if (deleteProduct?.length != 0) {
          setDeleteLoadingData(true);
          try {
            const productDeleteObj = {
              UserId: Number(localStorage.userId),
              ReturnRecordId: false,
              ReturnRecordError: true,
              SoftDelete: true,
              DeleteList: [
                {
                  ProductId: product?.id,
                  UserId: Number(localStorage.userId)
                }
              ]
            };
            await deleteUserProducts(productDeleteObj);
            setDeleteLoadingData(false);
          } catch (error) {
            console.log("deleteUserProducts-Error", error)
            setDeleteLoadingData(false);
          }
        }

        let getUpdatedExistingProduct = existingProductList.filter((item) => item?.productId !== product?.id)
        setExistingProductList(getUpdatedExistingProduct);
        setSelectedProduct(getUpdatedlist);
      } else {
        setIsModified(true);
        let getList = [...selectedProduct];
        getList.push({ ...product, productId: product?.id });
        setSelectedProduct(getList);
      }
    } else {
      await onProductClickForExperience(product);
      // await props?.onProductClick(product);
    }
  };

  const onProductClickForSelectedProduct = async (product) => {
    if (props.from === "profile") {
      const isPresent = selectedProduct.filter(
        (item) => item?.productId === product?.productId
      );

      if (isPresent.length > 0) {
        let getUpdatedlist = selectedProduct.filter(
          (item) => item?.productId !== product?.productId
        );

        const deleteProduct = existingProductList.filter((item) => item?.productId === product?.productId)
        if (deleteProduct?.length != 0) {
          setDeleteLoadingData(true);
          try {
            const productDeleteObj = {
              UserId: Number(localStorage.userId),
              ReturnRecordId: false,
              ReturnRecordError: true,
              SoftDelete: true,
              DeleteList: [
                {
                  ProductId: product?.productId,
                  UserId: Number(localStorage.userId)
                }
              ]
            };
            await deleteUserProducts(productDeleteObj);
            setDeleteLoadingData(false);
          } catch (error) {
            console.log("deleteUserProducts-Error", error);
            setDeleteLoadingData(false);
          }
        }

        let getUpdatedExistingProduct = existingProductList.filter(
          (item) => item?.productId !== product?.productId
        );
        setSelectedProduct(getUpdatedlist);
        setExistingProductList(getUpdatedExistingProduct);
      }
    }
    else {
      await onProductClickForExperience(product);
      // await props?.onProductClick(product);
    }
  };

  const onProductClickForExperience = (item) => {
    const objectIndex = selectedProduct.findIndex(obj => obj.id === item?.id);
    let selectedProductsOriginalApiCall = props?.selectedProductsOriginalApiCall ?? []
    if (objectIndex !== -1) {
      let updatedArray = [...selectedProduct];
      updatedArray.splice(objectIndex, 1);
      setSelectedProduct(updatedArray);
      if (selectedProductsOriginalApiCall.some(obj => obj.id === item?.id)) {
        const objectIndex = selectedProductsOriginalApiCall.findIndex(obj => obj.id === item?.id);
        if (objectIndex !== -1) {
          let obj = { ...selectedProductsOriginalApiCall[objectIndex], mainId: selectedProductsOriginalApiCall[objectIndex]?.mainId }
          setUnSelectedProductList((preValue) => [...preValue, obj]);
        }
      }
    } else {
      let updatedArray = [...selectedProduct, item];
      setSelectedProduct(updatedArray);
      if (selectedProductsOriginalApiCall.some(obj => obj.id === item?.id) && unSelectedProductList.some(obj => obj.id === item?.id)) {
        setUnSelectedProductList((preValue) => [...preValue.filter((obj) => obj?.id !== item?.id)]);
      }
    }
  }

  const renderProductcheck = (option) => {
    const isChecked = selectedProduct?.some((obj) =>
      props.from === "profile"
        ? obj?.productId === option?.id
        : obj.id === option?.id
    );
    return isChecked;
  };

  const onProductTypeClick = (type) => {
    if (productType !== type) {
      handleFetchProducts(1, searchTerm, type);
    } else {
      handleFetchProducts(1, searchTerm, null);
    }
    setProductType((prevValue) => prevValue !== type ? type : null);
  }




  function arraysOfObjectsEqual(arr1, arr2) {
    // Check if arrays have the same length
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Sort arrays of objects based on stringified representation
    const sortedArr1 = arr1.slice().sort((a, b) => JSON.stringify(a) > JSON.stringify(b) ? 1 : -1);
    const sortedArr2 = arr2.slice().sort((a, b) => JSON.stringify(a) > JSON.stringify(b) ? 1 : -1);

    // Convert sorted arrays to strings and compare
    const str1 = JSON.stringify(sortedArr1);
    const str2 = JSON.stringify(sortedArr2);

    return str1 === str2;
  }


  const checkIsSelectBtnDisableFormExperience = () => {
    return arraysOfObjectsEqual(selectedProduct, props?.selectedProductsOriginalApiCall ?? []);
  }

  return (
    <>
      <Modal
        aria-labelledby="add-description-modal"
        aria-describedby="add-description-form"
        open={props?.open}
        onClose={handleCloseModal}
      >
        <Box className="modal-center" sx={{ width: 600 }}>
          <div className="modal-card" style={{ padding: "15px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" style={{ fontWeight: "600", fontSize: "17px" }}>Select Products</Typography>
              <IconButton onClick={handleCloseModal} style={{ color: "blue" }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Stack spacing={2} padding={"5px"}>
              <ProductSearchField
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e);
                  handleFetchProducts(1, e, productType);
                }}
                isFocused={() => { }}
                placeholder={"Search Products"}
              />
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <p style={{ margin: "5px 0px 5px 7px", color: "#8d8d8d" }}>{showSelectedProduct ? selectedProduct?.length : noData} results</p>
              <p style={{ margin: "5px 0px 5px 7px", color: BIBlue, cursor: 'pointer' }} onClick={() => {
                setShowSelectedProduct((preValue) => !preValue)
              }}> {showSelectedProduct ? "Show all" : `Show Selected (${selectedProduct?.length ?? 0})`}</p>
            </Stack>

            <Stack direction={'row'} gap={1}>
              <Chip label="Tools" onClick={() => { onProductTypeClick('Tools') }}
                style={productType === "Tools" ? { backgroundColor: BIBlue, color: 'White' } : {}}
              />
              <Chip label="Material" onClick={() => { onProductTypeClick('Material') }}
                style={productType === "Material" ? { backgroundColor: BIBlue, color: 'White' } : {}}
              />
            </Stack>

            {/* new code sanket 18-03-2024 id changed */}
            <div id="scrollablediv_prodcut" style={{ height: 300, overflow: "auto" }}>
              {showSelectedProduct ?
                <>
                  {deleteLoadingData ? <h4 style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </h4> :
                    (<> {selectedProduct?.map((option, index) => (
                      <ListItemButton
                        key={index}
                        onClick={() => onProductClickForSelectedProduct(option)}
                      >
                        <ListItemText>
                          <b>{props.from === "profile" ? option?.productName ? option?.productName : option?.name : option?.name}</b>
                          <br />
                          {option?.manufacturerName} {option?.manufacturerName && option?.productType && "|"} {option?.productType}
                        </ListItemText>

                        <Checkbox checked={true} style={{ color: BIBlue }} />
                      </ListItemButton>

                    ))}
                    </>)}
                </>
                : (
                  <>
                    <InfiniteScroll
                      dataLength={
                        product?.length
                      }
                      next={() => handleFetchProducts(pageNo, searchTerm, productType)}
                      hasMore={!allDataLoaded}
                      loader={
                        allDataLoaded ? null : (
                          <h4 style={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress />
                          </h4>
                        )
                      }
                      scrollableTarget="scrollablediv_prodcut"
                    >

                      {deleteLoadingData ? <></> :
                        <>
                          {
                            product.map((option, index) => (

                              <ListItemButton
                                key={index}
                                onClick={() => onProductClick(option)}
                              >
                                <ListItemText>
                                  <b>{option?.name}</b>
                                  <br />
                                  {option?.manufacturerName} {option?.manufacturerName && option?.productType && "|"} {option?.productType}
                                </ListItemText>

                                <Checkbox checked={renderProductcheck(option)} style={{ color: BIBlue }} />
                              </ListItemButton>
                            ))
                          }</>}

                    </InfiniteScroll>
                  </>
                )
              }
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>

              {!loadingData ?
                <Button
                  disabled={props?.from === "experience" ? checkIsSelectBtnDisableFormExperience() : isModified ? false : true}
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="medium"
                  component="label"
                  onClick={() => props?.from === "experience" ? handleSaveProductForExperience() : handleSaveProduct()}
                >
                  {props?.from === "experience" ? "Submit" : "Select"}
                </Button>
                :
                <Button
                  disabled={true}
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="medium"
                  component="label"
                >
                  <CircularProgress size={24} />
                </Button>}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default SelectProductModal;