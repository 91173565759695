import React, { useState } from 'react'
import { ClientHeader } from '../ClientHeader'
import { Tab, Tabs } from '@mui/material'

export const ClientCommunicator = () => {

    const [activeTab,setActiveTab] = useState("All");
    const tabsArray = ["All", "Acknowledge","Acknowledged"];

    const handleTabChange = (event, newTab) => {
        setActiveTab(newTab);
    };

    return (
        <>
            <ClientHeader screenName={"Communicator"} />
            <div className="page">
                <div className="page-content">
                    <div className="flex-container-vertical" style={{ flex: 2 }}>
                        <div style={{ margin: "15px" }}>
                            <div
                                className="flex-container-horizontal"
                                style={{ justifyContent: "space-between" }}>
                                <Tabs
                                    sx={{
                                        ".Mui-selected": {
                                            color: localStorage?.clientAccentColor,
                                        }
                                    }}
                                    TabIndicatorProps={{ sx: { backgroundColor: localStorage?.clientAccentColor } }}
                                    value={activeTab}
                                    onChange={handleTabChange}>
                                    {tabsArray.map((tab, index) => (
                                        <Tab
                                            key={index}
                                            value={tab}
                                            label={tab}
                                            style={{
                                                backgroundColor: "white",
                                                borderRadius: "5px",
                                                marginRight: "20px",
                                            }}
                                        />
                                    ))}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                                        

                </div>
            </div>

        </>
    )
}
