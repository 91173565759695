import React, { useState } from "react";
import {
  Modal,
  Stack,
  Typography,
  TextField,
  Container,
  ListItem,
  Grid,
  Box,
  Chip,
} from "@mui/material";
import { BIBlue } from "../../assets/buildidColors";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import BuildIcon from "@mui/icons-material/Build";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import '../../assets/css/ContentSearch.css'


const ContentSearchTutorial = (props) => {
  const [typelist] = useState([
    { text: "Companies", checked: false, itemRecordType: "company" },
    { text: "Manufactures", checked: false, itemRecordType: "manufacturer" },
    { text: "Products", checked: false, itemRecordType: "product" },
    { text: "Trades", checked: false, itemRecordType: "trade" },
  ]);
  const [label] = useState([
    { label: "Boilermaker" },
    { label: "Bricklayer" },
    { label: "Cabinetmaker" },
    { label: "Carpenter" },
    { label: "Concrete Finisher" },
    { label: "Construction Craft Worker" },
    { label: "Cladder" },
    { label: "Crane and Hoisting Equipment Operator" },
    { label: "Electrician" },
    { label: "Elevator Constructor" },
    { label: "Floorcovering installer" },
    { label: "Gus Utility Operator" },
  ]);
  return (
    <>
      <Modal
        open={props?.tutorialIndex == 0 ? false : true}
        onClose={props.onClose}
        style={{
          backgroundColor: "rbga(0,0,0,0.1)",
        }}>
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", height: "100%" }}
          onClick={props.onPress}>
          {props.tutorialIndex === 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}>
              <Stack sx={{ textAlign: "center", color: "white" }}>
                <Typography variant="h6">Universal Search</Typography>
                <Typography variant="subtitle1">
                  Search through BuildIDs database of <br />
                  Companies, manufacturers, Products and Trades
                </Typography>
                <Typography variant="body1">
                  <span style={{ color: BIBlue }}>
                    {" "}
                    (Tap anywhere to continue)
                  </span>
                </Typography>
              </Stack>
            </div>
          )}

          {props.tutorialIndex === 2 && (
            <div
            style={{
              display: "flex",
              justifyContent: "start",
              height: "100%",
            }}>
              <div className="contentsearch-tutorial-2-body">
                <Container
                  className="contentsearch-tutorial-2-container">
                  <div className="universal-search">
                    <span className="search-type" style={{ color: "#fff" }}>
                      Type
                    </span>
                    <Grid xs={12} container className="type-list" columnGap={1}>
                      {typelist.map((item, index) => (
                        <Grid xs={2}>
                          <ListItem
                            className={`list-item ${
                              item?.checked ? "active" : ""
                            }`}>
                            {item.itemRecordType == "product" ||
                            item.itemRecordType == "trade" ? (
                              <BuildIcon />
                            ) : (
                              <BusinessCenterIcon />
                            )}
                            <span>{item?.text}</span>
                          </ListItem>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </Container>
                <div
                  style={{
                    marginLeft: "25%",
                    color: "white",
                    marginTop: "10px",
                  }}>
                  <NorthWestIcon style={{ fontSize: "40px" }} />
                  <div>
                    Search our system using keywords and / or <br />
                    selecting the type of item you are looking for.
                  </div>
                </div>
              </div>
            </div>
          )}

          {props.tutorialIndex === 3 && (
            <div
            className="contentsearch-tutorial-3-body">
              <Container className="contentsearch-tutorial-3-container">
                <Container
                  className="contentsearch-tutorial-3-inner-container">
                  <Grid
                    sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{marginBottom:'20px', marginLeft:'13.5px', fontSize:'1rem'}}>Advanced</Box>
                    <Box sx={{marginRight:'5px'}}>
                      <ExpandLessIcon />
                    </Box>
                  </Grid>
                  <Grid sx={{marginLeft:'10px'}}>
                    <Box>
                      <TextField
                        variant="outlined"
                        placeholder="Location - Type-Here"
                        size="small"
                        className="search-place"
                        style={{
                          marginBottom: "14px",
                          backgroundColor: "#fff",
                          color: "#000",
                        }}
                      />
                    </Box>
                    <Box>
                      <span className="quick-text">Quick Location</span>
                      <Grid
                        xs={12}
                        container
                        columnGap={3}
                        className="quickloc-grid">
                        <Grid xs={2}>
                          <div
                            className="firstQuick"
                            style={{
                              backgroundColor: "#fff",
                              color: "#000",
                              padding: "10px",
                              borderRadius:'5px',
                            }}>
                            <span>Pimpari - Chinchwad</span>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid
                      xs={12}
                      columnGap={2}
                      container
                      sx={{ marginTop: "15px" }}>
                      <div>
                        {label.map((item, index) => (
                          <Chip
                            label={item?.label}
                            className="contentsearch-tutorial-3-chips"
                          />
                        ))}
                      </div>
                      <span
                        style={{
                          color: "#0000ff",
                          marginLeft: "85%",
                          cursor: "pointer",
                        }}>
                        Show More Trades
                      </span>
                    </Grid>
                  </Grid>
                </Container>
                <Stack
                  direction="row"
                  justifyContent={"center"}
                  sx={{ marginTop: "10px" }}>
                  <ArrowUpwardIcon />
                </Stack>
                <div>
                  <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                    Use the advanced search for location and trade to help
                    narrow the search
                  </Typography>
                </div>
              </Container>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ContentSearchTutorial;
