import React from "react";

import Viewer from "react-viewer";

/*
Single image fullscreen viewer
Props (* required):
	*isOpen		- boolean; viewer visibility variable
	*onClose	- function; to modify visibility state variable
	*src		- string; URL of picture to view

Overload: Multiple image fullscreen viewer
Props (* required):
	*isOpen			- boolean; viewer visibility variable
	*onClose		- function; to modify visibility state variable
	*pictures		- array; URLs of pictures to view
	*viewerIndex	- int

Usage:
	<PictureViewer
		isOpen={}
		onClose={() => }
		src={}/>
	
	<PictureViewer
		isOpen={}
		onClose={() => }
		pictures={}
		viewerIndex={}/>
*/
function PictureViewer(props){
	return(
		props.src
			? (
				<Viewer
					visible={props.isOpen}
					onClose={props.onClose}
					onMaskClick={props.onClose}
					drag={false}
					disableMouseZoom={true}
					noClose={true}
					noFooter={true}
					images={[{ src: props.src, alt: "" }]}/>
			)
			: (
				<Viewer
					visible={props.isOpen}
					activeIndex={props.viewerIndex}
					onClose={props.onClose}
					onMaskClick={props.onClose}
					zoomSpeed={0.75}
					minScale={1}
					zoomable={false}
					scalable={false}
					rotatable={false}
					noImgDetails={true}
					customToolbar={(toolbars) => {
						return toolbars.filter((button) => {return button.key !== "reset"} )
					}}
					images={props.pictures}
					/>
			)
	)
}

export default PictureViewer;