import React from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Data from "../Data";
import StyleItem from "../StyleItem";
import "../Taketour.css";

const Sites = () => {
  const { sitesData } = Data();

  return (
    <>
      <LocationOnIcon style={{ height: "100px", width: "100px"}} className="headicon" />
      <div style={{ marginBottom: "40px" }}>
        {sitesData.map((item, index) => (
          <StyleItem key={index} icon={item.icon} data={item}  />
        ))}
      </div>
    </>
  );
};

export default Sites;
