import React from "react";
import { Modal, IconButton, Button, ListItem, ListItemAvatar, Avatar, ListItemText, List, Grid } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import InfoIcon from '@mui/icons-material/Info';
import { useState } from "react";
import AlertModal from "./AlertModal";
import { useEffect } from "react";
import { getMusicFiles } from "../api/MontagesApi";

function GetMusicModal(props) {

    const [alertModalOpen, setAlertModalOpen] = useState(false);
    const [alertModalTitle, setAlertModalTitle] = useState("");    
    const [alertModalBody, setAlertModalBody] = useState("");
    const [musicFiles, setMusicFiles] = useState([]);
    const [musicUrl,setMusicUrl] = useState("");
    const sound = document.getElementById("audioFile");

    function handlePlayAudio(url) {
        Promise.resolve(setMusicUrl(url)).then(() =>sound.play());
    }

    function handleMusicNameToggle(fileUrl){
        props.handleChangeMusic(fileUrl);
    }

    function handleInfoClick(item){
        setAlertModalTitle(item.name);
        setAlertModalBody(item.description);
        setAlertModalOpen(true);
    }

    useEffect(() => {
        async function getMusicFile() {
            const res = await getMusicFiles();
            setMusicFiles(res);
        }
        getMusicFile();
    }, [])

    return (
        <>
            <audio id="audioFile" src={musicUrl} />
            
            <AlertModal 
            isOpen={alertModalOpen}
            onClose={()=>setAlertModalOpen(false)}
            title={alertModalTitle}
            body={alertModalBody}
            />

            <Modal
                open={props.isOpen}
                onClose={()=>{props.onClose()}}>
                <div className="modal-center">
                    <div className="modal-card" style={{ width: "500px" }}>
                        <div className="modal-card-header-buttons">
                            <IconButton
                                color="primary"
                                onClick={props.onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <div>
                            <List dense={true}>
                                {musicFiles.map((item,index) => {
                                    return <ListItem key={index}>
                                        <ListItemAvatar style={{ cursor: 'pointer' }} onClick={() => handlePlayAudio(item.fileUrl)}>
                                            <Avatar>
                                                <PlayCircleIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => { props.setMusicName(item.name); handleMusicNameToggle(item.fileUrl); props.onClose() }}
                                            primary={item.name}
                                        />
                                        <IconButton style={{ cursor: 'pointer' }} onClick={()=>handleInfoClick(item)}>
                                            <InfoIcon />
                                        </IconButton>
                                    </ListItem>
                                })}
                            </List>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default GetMusicModal;