import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Button, Dialog, DialogContent, DialogTitle,
	InputAdornment, ListItem, ListItemText, ListSubheader, TextField,
	Select,
	MenuItem, FormControl, InputLabel
} from '@mui/material';
import { ListItemButton, Stack } from '@mui/material';
import ProductSearchField from '../ProductSearchField';
import { fetchProducts, saveProducts } from '../../api/ProductsApi';
import {
	Search as SearchIcon,
} from "@mui/icons-material";
import { fetchCompanies, saveCompanies2 } from '../../api/CompanyApi';
import Loader from '../Loader';

function AddItemModelProducts(props) {

	const [productInputValue, setProductInputValue] = useState("")
	const [productsFilterData, setProductsFilterData] = useState([])
	const [manufacturerInputValue, setManufacturerInputValue] = useState("");
	const [isProductSearch, setIsProductSearch] = useState(false);
	const [productType, setProductType] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();

	const productTypeOptions = [
		{
			id: 1,
			name: 'Tools',
			key: 1,
			value: 'Tools',
		},
		{
			id: 2,
			name: 'Materials',
			key: 2,
			value: 'Materials',
		}
	]


	// Reset fields on close
	function onCloseClick() {
		setProductInputValue('');
		setProductsFilterData([]);
		setManufacturerInputValue("");
		setProductType("");
		props.onClose();
	}



	function onChnageAddProduct(props) {
		setProductInputValue(props != '' ? props : '')
		getFilterProducts(props)
	}

	function onChnageAddManufaturer(props) {
		setManufacturerInputValue(props)
		getFilterProducts(props)
	}

	const getFilterProducts = async (props) => {
		try {
			const object = {
				IncludeRecordNr: false,
				SearchList: isProductSearch ? [{ name: props }] : [{ manufacturerName: props }],
				NrOfRecPerPage: 0,
				SortList: [{ FieldName: 'Name', Direction: 'ASC' }],
				PageNr: 1,
				FullSearch: "",
				TypeOfObjectReturned: 'NameOnly'
			}
			const res = await fetchProducts(object);
			let a;
			res.data.map((x) => {
				a = x;
				a['title'] = x.name;
				return a;
			});
			setProductsFilterData(res.data)
		} catch (error) {
			console.log("ERROR[getFilterProducts]:", error)
		}
	}


	const saveNewManufacturer = async (name) => {
		try {
			const saveObject = {
				UserId: Number(localStorage.userId),
				ReturnRecordId: true,
				ReturnRecordError: true,
				SaveList: [{
					Id: 0,
					Name: name,
					ModifiedBy: Number(localStorage.userId),
					RecordStatusId: 1,
					Manufacturer: true
				}]
			}
			const res = await saveCompanies2(saveObject);
			return res;
		} catch (err) {
			console.log("Error while saving new manfacturer :: ", err)
		}
	}

	async function handleAddProduct() {
		try {
			setIsLoading(true);
			var check = false;

			if (props?.updateData?.length != 0) {
				for (let i = 0; i < props.updateData.length; i++) {
					for (let j = 0; j < props.updateData[i].items.length; j++) {

						if (props?.listSectionType === "manufacturer") {
							if (props.updateData[i].items[j].name.toLowerCase() == productInputValue.toLowerCase() && props.updateData[i].items[j].manufacturerName.toLowerCase() == manufacturerInputValue.toLowerCase()) {
								check = false;
								alert("The Product is Already Added...")
								return;
							} else {
								check = true;
							}
						} else {
							if (props.updateData[i].items[j].productName.toLowerCase() == productInputValue.toLowerCase() && props.updateData[i].items[j].manufacturerName.toLowerCase() == manufacturerInputValue.toLowerCase()) {
								check = false;
								alert("The Product is Already Added...")
								return;
							} else {
								check = true;
							}
						}
					}
				}
			} else {
				check = true;
			}

			if (check) {
				let newManufacturerRes = [];
				let manufacturerData = [];
				if (manufacturerInputValue.trim()) {
					const obj = {
						PageNr: 1,
						FullSearch: "",
						SearchList: [{ Name: manufacturerInputValue.trim(), Manufacturer: true }],
						SortList: [{ FieldName: "Name", Direction: "ASC" }],
						IncludeRecordNr: false,
						TypeOfObjectReturned: 'NameOnly'
					}
					const manufacturers = await fetchCompanies(obj);
					if (manufacturers?.data?.length === 0) {
						newManufacturerRes = await saveNewManufacturer(manufacturerInputValue.trim());
					}
					manufacturerData = manufacturers.data.filter((m) => m.name.trim() === manufacturerInputValue.trim());
				}
				let manfacturerId = props?.manufacturerInfo?.manufacturerId ? props?.manufacturerInfo?.manufacturerId : manufacturerData.length > 0 ? manufacturerData[0]?.id : newManufacturerRes[0]?.objData?.id ?? 0;
				const productObj = {
					UserId: Number(localStorage.userId),
					ReturnRecordId: true,
					ReturnRecordError: true,
					SaveList: [{
						Id: 0,
						Name: productInputValue,
						Info: '',
						RecordStatusId: 1,
						ModifiedBy: Number(localStorage.userId),
						ManufacturerId: manfacturerId,
						productType: productType
					}]
				}
				const resProduct = await saveProducts(productObj);
				const productId = resProduct?.data?.[0]?.objData?.id;
				let goToProductPage = true;
				props?.onProductSave(productId ?? 0, goToProductPage);
				onCloseClick();
				setIsLoading(false);
				return;
			} else {
				setIsLoading(false);
			}
		} catch (err) {
			setIsLoading(false);
			console.log("Error :: ", err)
		}
	}


	const handleAddExistProduct = (item) => {
		var check = false;
		if (props?.updateData.length != 0) {
			for (let i = 0; i < props?.updateData.length; i++) {
				for (let j = 0; j < props?.updateData[i].items.length; j++) {
					if (props?.updateData[i]?.items[j]?.productId == item.id) {
						check = false;
						alert("The Product is Already Added...")
						return;
					} else {
						check = true;
					}
				}
			}
		} else {
			check = true;
		}
		if (check) {
			let goToProductPage = false;
			props?.onProductSave(item?.id ?? 0, goToProductPage);
			onCloseClick();
			return;
		} else {
			console.log("CHECK :: ", check)
		}
	}

	return (
		<Dialog
			open={props.open}
			onClose={onCloseClick}
			fullWidth
			maxWidth="sm">
			<DialogTitle>{props.modalTitle ?? "Add Product"}</DialogTitle>
			<Stack spacing={2} padding={"0px 40px"}>
				<ProductSearchField
					value={productInputValue}
					onChange={(e) => onChnageAddProduct(e)}
					isFocused={() => {
						setIsProductSearch(true)
					}}
					placeholder={'Product Name'}

				/>
				{props?.manufacturerInfo?.manufacturerName ? <>
					<TextField
						value={props?.manufacturerInfo?.manufacturerName}
						fullWidth
						InputProps={{
							startAdornment: ((props.icon == true) ? <></> : (<InputAdornment position="start"><SearchIcon /></InputAdornment>)),

						}}
						disabled
					/>
				</> :
					<ProductSearchField
						value={manufacturerInputValue}
						onChange={(e) => onChnageAddManufaturer(e)}
						isFocused={() => {
							setIsProductSearch(false)
						}}
						placeholder={'Manufacturer (Optional)'}
					/>}


				<FormControl sx={{ m: 1, minWidth: 120 }}>
					<InputLabel id="product-type-label">Type</InputLabel>
					<Select
						labelId="product-type-label"
						value={productType}
						label="Type"
						onChange={(e) => { setProductType(e.target.value) }}
					>
						{
							productTypeOptions?.map((type, i) => (
								<MenuItem value={type?.value} key={i}>
									{type?.value}
								</MenuItem>
							))
						}


					</Select>
				</FormControl>
			</Stack>
			<DialogContent style={{ paddingTop: 0 }}>
				<Stack spacing={0}>
					<>
						{productInputValue || manufacturerInputValue != "" ?
							<div>
								<ListSubheader>
									New Product
								</ListSubheader>
								<ListItem
									secondaryAction={
										<div style={{ display: 'flex' }}>
											{isLoading ? <Loader /> :
												<Button variant="contained" size="small"
													disabled={productInputValue.trim() === '' || productType === "" ? true : false}
													onClick={() => handleAddProduct()}
												>Add</Button>}
										</div>
									}
									disablePadding
								>
									<ListItemButton sx={{ display: 'flex', flexDirection: 'row' }}>
										<ListItemText
											primary={productInputValue}
											secondary={props?.showManufactureNameInNewAddProduct ? manufacturerInputValue.trim() !== "" ? manufacturerInputValue : "(no Manufacture)" : ""}
										/>
									</ListItemButton>
								</ListItem>
							</div> : <></>
						}
						{productInputValue || manufacturerInputValue != "" ?
							<div>
								{productsFilterData.length != 0 ?
									<ListSubheader >
										Existing Product
									</ListSubheader> : <></>}
								{productsFilterData.length != 0 ? (
									<>{productsFilterData.map((item, index) => {
										return (

											<div key={index.toString()} >
												<ListItem key={index.toString()}
													secondaryAction={
														<div style={{ display: 'flex' }}>
															{
																props?.listSectionType === "manufacturer" ?
																	<Button variant="contained" size="small" onClick={() => {
																		history.push(`/product/${item?.id}`)
																	}} >View</Button>
																	:
																	<Button variant="contained" size="small" onClick={() => handleAddExistProduct(item)} >Add</Button>
															}

														</div>
													}
													disablePadding
												>
													<ListItemButton sx={{ display: 'flex', flexDirection: 'row' }}>
														<ListItemText
															primary={item.name ?? " "}
															secondary={
																props?.listSectionType === "manufacturer" ?
																	props?.showManufactureName ? item.manufacturerName : " " : item.manufacturerName
															}
														/>
													</ListItemButton>
												</ListItem>
											</div>

										)
									})}
									</>
								) : (<></>)}
							</div>
							: <></>
						}
					</>
				</Stack>
			</DialogContent>
		</Dialog>
	)
}

export default AddItemModelProducts;
