import React, { useEffect, useState } from "react";
import { TextField, Button, Switch, FormControlLabel } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import HelpIcon from "@mui/icons-material/Help";
import { BIBlue } from "../../assets/buildidColors";
import { deleteGoals, saveGoals, saveGoalsCategories } from "../../api/TimesheetApi";
import PromptModal from "../../components/PromptModal";

const AddGoalModal = (props) => {

  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [isUntrackedHours, setIsUntrackedHours] = useState(props?.editGoalDetails?.untrackedHoursFlag ?? false);
  const [categories, setCategories] = useState(props?.editGoalDetails?.categoryList ?? []);
  const [goalName, setGoalName] = useState(props?.editGoalDetails?.name ?? "");
  const [totalHours, setTotalHours] = useState(props?.editGoalDetails?.totalHours ?? "");
  const [untrackedHours, setUntrackedHours] = useState(props?.editGoalDetails?.untrackedHours ?? "");
  const [categoryError, setCategoryError] = useState(false);
  const [deletePrompt, setDeletePrompt] = useState(false);


  useEffect(() => {
    if (props?.editGoalDetails) {
      setGoalName(props?.editGoalDetails?.name ?? "");
      setIsUntrackedHours(props?.editGoalDetails?.untrackedHoursFlag ?? false);
      setTotalHours(props?.editGoalDetails?.totalHours ?? "")
      setUntrackedHours(props?.editGoalDetails?.untrackedHours ?? "");
      setCategories(props?.editGoalDetails?.categoryList ?? []);
    }
  }, [props?.editGoalDetails])
  const handleDialogOpen = () => {
    setOpenHelpDialog(true);
  };

  const handleDialogClose = () => {
    setOpenHelpDialog(false);
  };

  const handleClose = () => {
    setGoalName("");
    setIsUntrackedHours(false);
    setCategories([]);
    setTotalHours("");
    setUntrackedHours("");
    setCategoryError(false);
    setDeletePrompt(false);
    props.onClose();
  };

  const handleIsUntrackedHours = (e) => {
    setIsUntrackedHours(e.target.checked);
  };

  const handleAddCategory = () => {
    setCategoryError(false);
    if (isUntrackedHours) {
      setCategories([
        ...categories,
        { categoryName: "", untrackedHours: "", id: 0 },
      ]);
    } else {
      setCategories([...categories, { categoryName: "", id: 0 }]);
    }
  };

  const handleRemoveCategory = (index) => {
    const newCategories = [...categories];
    newCategories.splice(index, 1);
    setCategories(newCategories);
  };

  const handleCategoryChange = (index, type, value) => {
    const newCategories = [...categories];
    newCategories[index][type] = value;
    setCategories(newCategories);
  };

  const onUpdateSave = async () => {
    try {

      const checkCategoryEmpty = categories?.filter((item) => item.categoryName === "");
      if (checkCategoryEmpty?.length > 0) return setCategoryError(true)
      const obj = {
        UserId: Number(localStorage?.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: props?.editGoalDetails?.id ?? 0,
            Name: goalName,
            TotalHours: totalHours,
            UntrackedHoursFlag: isUntrackedHours,
            UntrackedHours: untrackedHours === "" ? 0 : untrackedHours,
            ModifiedBy: Number(localStorage?.userId),
          },
        ],
      };

      const res = await saveGoals(obj)
    } catch (error) {

    }
  }



  const onGoalSave = async () => {
    try {
      if (goalName?.trim() === "" || Number(totalHours <= 0)) {
        return setCategoryError(true)
      }
      const checkCategoryEmpty = categories?.filter((item) => item.categoryName === "");
      if (checkCategoryEmpty?.length > 0) return setCategoryError(true)

      const obj = {
        UserId: Number(localStorage?.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: props?.editGoalDetails?.id ?? 0,
            Name: goalName,
            TotalHours: Number(totalHours),
            UntrackedHoursFlag: isUntrackedHours,
            UntrackedHours: Number(untrackedHours) === "" ? 0 : Number(untrackedHours),
            ModifiedBy: Number(localStorage?.userId),
          },
        ],
      };
      const res = await saveGoals(obj)
      const currentGoalId = res?.data[0]?.objData?.id;
      onGoalCategorySave(currentGoalId);
      setIsUntrackedHours(false);
    } catch (err) {
      console.log("Error while saving :-", err);
    }
  };

  const onGoalCategorySave = async (goalId) => {
    try {

      if (categories?.length !== 0) {
        const obj = {
          UserId: Number(localStorage?.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: categories.map((category) => ({

            Id: category?.id ?? 0,
            GoalId: Number(goalId),
            CategoryName: category.categoryName,
            UntrackedHours: Number(category?.untrackedHours ?? 0),
            ModifiedBy: Number(localStorage?.userId),
          })),
        };
        await saveGoalsCategories(obj)
      }
      await props?.onDataSave();
      handleClose();
    } catch (err) {
      handleClose();
      console.log("Error while saving :-", err);
    }
  };


  const onDeleteButtonPressed = async () => {
    try {
      const deleteObj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: false,
        ReturnRecordError: true,
        DeleteList: [{
          Id: props?.editGoalDetails?.id,
          ModifiedBy: Number(localStorage.userId)
        }]
      }
      await deleteGoals(deleteObj);
      await props?.onDataSave();
      handleClose();
    } catch (err) {
      console.log("Error while deleting goal :: ", err)
    }
  }

  return (
    <>
      <Dialog onClose={handleClose} open={props.isOpen} fullWidth maxWidth="sm">
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {props?.editGoalDetails ? "Edit Goal" : "Add Goal"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="input-container">
            <TextField
              // autoFocus
              error={categoryError && goalName?.trim() === ""}
              variant="outlined"
              label={"Name (eg. Electrical 1st Year)"}
              fullWidth
              onChange={(e) => {
                setGoalName(e.target.value);
              }}
              value={goalName}
            />
          </div>
          <div className="input-container">
            <TextField
              // autoFocus
              error={categoryError && Number(totalHours <= 0)}
              type={"number"}
              variant="outlined"
              label={"Total Hours"}
              fullWidth
              onChange={(e) => {
                setTotalHours(e.target.value);
              }}
              value={totalHours}
            />
          </div>
          {isUntrackedHours && (
            <div className="input-container">
              <TextField
                // autoFocus
                variant="outlined"
                label={"Untracked Hours"}
                fullWidth
                onChange={(e) => {
                  setUntrackedHours(e.target.value);
                }}
                value={untrackedHours}
              />
            </div>
          )}
          <div
            style={{
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #656060",
              paddingBottom: "10px",
              marginBottom: "10px",
            }}
          >
            <Button
              variant="standard"
              color="primary"
              onChange={(e) => handleIsUntrackedHours(e)}
              style={{ padding: "0px" }}
              size="small"
            >
              <FormControlLabel
                control={<Switch checked={isUntrackedHours ? true : false} />}
                label="Untracked Hours"
              />
            </Button>
            <HelpIcon
              style={{
                cursor: "pointer",
              }}
              onClick={handleDialogOpen}
            />
          </div>
          <Dialog open={openHelpDialog} onClose={handleDialogClose}>
            <div style={{ width: "450px", height: "190px" }}>
              <DialogContent>
                Do you have hour credits that are not tracked in BuildID? Enter
                the number of hours here to count them towards this Goal.{" "}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                  OK
                </Button>
              </DialogActions>
            </div>
          </Dialog>
          <div
            style={{ fontSize: "14px", color: "gray", marginBottom: "20px" }}
          >
            (Optional) You can use Categories to track specific items for your
            Goal
          </div>
          {categories.map((category, index) => (
            <div key={index} style={{ marginBottom: "10px" }}>
              <div style={{ display: "flex" }}>
                <div>
                  <TextField
                    id="outlined-error"
                    error={categoryError && category?.categoryName?.trim() === ""}
                    style={{ width: "32rem" }}
                    variant="outlined"
                    label={`Category name`}
                    placeholder={`Category name`}
                    fullWidth
                    value={category.categoryName}
                    onChange={(e) =>
                      handleCategoryChange(
                        index,
                        "categoryName",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div>
                  <IconButton onClick={() => handleRemoveCategory(index)}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              {isUntrackedHours && (
                <TextField
                  // autoFocus
                  style={{
                    width: "32rem",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  variant="outlined"
                  label={`Untracked hours for Category`}
                  fullWidth
                  value={category?.untrackedHours === 0 ? "" : category?.untrackedHours}
                  onChange={(e) =>
                    handleCategoryChange(
                      index,
                      "untrackedHours",
                      e.target.value
                    )
                  }
                />
              )}
            </div>
          ))}
          <div
            onClick={handleAddCategory}
            style={{
              justifyContent: "center",
              display: "flex",
              color: BIBlue,
              cursor: "pointer",
              fontWeight: "bold",
            }}
          >
            Add Category
          </div>
        </DialogContent>
        <DialogActions className="flex-container-horizontal" style={{ justifyContent: 'center' }}>
          {props?.editGoalDetails ?
            <>
              <Button color="primary" onClick={() => {
                setDeletePrompt(true)
              }} style={{ paddingRight: '30px' }}>
                Delete
              </Button>
              <Button color="primary" onClick={onGoalSave} style={{ paddingLeft: '30px' }}>
                Save
              </Button> </> : <Button color="primary" onClick={onGoalSave}>
              Save
            </Button>}
        </DialogActions>
      </Dialog>
      <PromptModal
        isOpen={deletePrompt}
        onClose={() => {
          setDeletePrompt(false)
        }}
        title="Delete Goals/Categories"
        desc="Any progress for this Goal (and Categories) will be deleted. This cannot be undone. Are you sure you want to delete this Goal?"
        redButtonText="Delete"
        onRedButtonClick={() => onDeleteButtonPressed()}
      />
    </>
  );
};

export default AddGoalModal;
