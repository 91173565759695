import React, { useCallback } from 'react'
import { useState } from 'react';
import SearchField from "../../components/SearchField";
import { searchCompanies } from '../../api/CompanyApi';
import SearchListSection from '../search/SearchListSection';

export default function Companylist() {

    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);
    const [getUpdatedData, setGetUpdatedData] = useState(false);

    const getCompanylist = useCallback(async (pageNumber, searchText) => {
        try {
            const obj = {
                UserId: Number(localStorage?.userId),
                IncludeRecordNr: true,
                SearchString: searchText ?? "",
                PageNumber: pageNumber === "" ? 1 : pageNumber,
                NrRowsPerPage: 20
            }
            const res = await searchCompanies(obj);
            return res?.data
        } catch (error) {
            console.log("error--", error);
        }
    }, [page])


    const increaseOffset = (callback) => {
        setPage((prevPageNr) => prevPageNr + 1);
        callback(page + 1, searchText);
    }

    const clearSearch = (value) => {
        setSearchText(value);
        if (value === "") {
            setGetUpdatedData(true)
        }
    }
    return (
        <div className="page">
            <div className="page-content">
                <div className="search-container" style={{ position: "fixed", zIndex: 999 }}>
                    <div style={{ backgroundColor: 'white', borderRadius: '5px' }}>
                        <SearchField
                            value={searchText}
                            onChange={(value) => clearSearch(value)}
                            onSubmit={() => { setPage(1); setGetUpdatedData(true) }}
                            placeholder={"Search Companies"}
                        />
                    </div>
                </div>
                <div className="list-container" id={"scrollList"}>
                    <SearchListSection
                        getData={getCompanylist}
                        increaseOffset={increaseOffset}
                        page={page}
                        universalsearch={false}
                        searchText={searchText}
                        getUpdatedData={getUpdatedData}
                        setGetUpdatedData={(val) => setGetUpdatedData(val)}
                        from="company"
                    />
                </div>
            </div>
        </div>
    )

}
