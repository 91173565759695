import React, { useState, useEffect } from "react";
import "../../assets/css/Dashboard.css";
import {
  FormControlLabel,
  Switch,
  Button,
  Card,
  List,
  ListSubheader,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { BIBlack } from "../../assets/buildidColors";
import ContactCard from "./ContactCard";
import ContactList from "./ContactList";
import Placeholder from "../../components/Placeholder";
import PersonIcon from "@mui/icons-material/Person";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Loader";
import {
  fetchUserAuth,
  getPeopleInterestedInRideShare,
  changeRideShareForUser,
} from "../../api/RideShareApi";
import HelpIcon from "@mui/icons-material/Help";
import UserUsages from "../../components/userUsages/UserUsages";
import { showTutorialForNewUser, updateTutorialForNewUser } from "../../assets/helpers";
import CarpoolTutorial from "../../components/Tutorial/CarpoolTutorial";
export default function CarPool(props) {
  const [isRideInterested, setIsRideInterested] = useState(false);
  const [yourContactData, setYourContactData] = useState([]);
  const [newConnectionData, setNewConnectionData] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [tutorialIndex, setTutorialIndex] = useState(1);


  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleIsRideShareInterested = async (e) => {
    setIsRideInterested(e.target.checked);
    const obj = {
      UserId: Number(localStorage.userId),
      RideShareInterested: e.target.checked,
    };
    await changeRideShareForUser(obj);
  };

  const history = useHistory();

  async function getData() {
    const data = await getPeopleInterestedInRideShare(
      localStorage.userId,
      localStorage.siteId || 0
    );
    setLoading(true);
    const obj = { SearchList: [{ UserId: localStorage.userId }] };
    const userProfile = await fetchUserAuth(obj);
    if (userProfile?.data?.length !== 0) {
      userProfile.data[0].address = userProfile?.data?.[0]?.address1;
      delete userProfile.data[0].address1;
      userProfile.data[0].address1 = userProfile?.data?.[0]?.address2;
      delete userProfile.data[0].address2;
    }
    const yourContacts = data.filter(
      (d) =>
        d.isMyContact && d.siteId === localStorage.siteId && d.distance <= 5
    );
    const newConnections = data.filter((d) => !d.isMyContact);
    userProfile.data.length > 0
      ? setIsRideInterested(userProfile.data[0]?.rideShareInterested)
      : setIsRideInterested(false);
    if (yourContacts.length > 0) {
      setSelectedAddress({
        address: yourContacts[0].address,
        address2: yourContacts[0].address2,
        city: yourContacts[0].city,
        state: yourContacts[0].state,
        postalCode: yourContacts[0].postalCode,
        country: yourContacts[0].country,
      });
    }
    setYourContactData(yourContacts);
    console.log("setYourContactData", yourContacts);
    setNewConnectionData(newConnections);
    console.log("setNewConnectionData", newConnections);

    setDataAvailable(yourContacts.length > 0 || newConnections.length > 0);
    setLoading(false);
  }

  useEffect(async () => {
    await UserUsages("Carpool");
    getData();
  }, []);

  return (
    <>
      <div className="page rewards-page">
        <div className="header">
          <div className="page-content">
            <h1>Carpool</h1>
          </div>
        </div>
      </div>
      <div className="page">
        <div className="page-content">
          <div
            style={{
              textAlign: "right",
              marginTop: "20px",
              paddingRight: "20px",
            }}>
            <Button
              variant="outlined"
              color="primary"
              onChange={(e) => handleIsRideShareInterested(e)}
              size="small">
              <FormControlLabel
                control={<Switch checked={isRideInterested ? true : false} />}
                label="Interested in carpool"
              />
            </Button>
            <HelpIcon
              style={{
                marginLeft: "10px",
                marginTop: "20px",
                cursor: "pointer",
              }}
              onClick={handleDialogOpen}
            />
          </div>
          <Dialog open={openDialog} onClose={handleDialogClose}>
            <div style={{ width: "450px", height: "190px" }}>
              <DialogContent>
                Your profile will be shared with nearby Builders that currently
                work on the same Site as you.
                <div style={{ marginTop: "20px" }}></div>
                Your address will not be shared.
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                  OK
                </Button>
              </DialogActions>
            </div>
          </Dialog>
          {isRideInterested ? (
            <div className="resp-container-horizontal">
              <div
                className="flex-container-vertical"
                style={{ flex: 1, marginRight: "8px" }}>
                <ContactCard trigger={getData} />
              </div>
              <div
                className="flex-container-vertical"
                style={{ flex: 2, paddingTop: "12px", marginLeft: "150px" }}>
                {loading ? (
                  <Loader />
                ) : dataAvailable ? (
                  <InfiniteScroll
                    dataLength={
                      yourContactData.length + newConnectionData.length
                    }
                    next={async () => {
                      if (1) {
                        // await props.increaseOffset();
                      }
                    }}
                    hasMore={true}>
                    <>
                      {yourContactData.length > 0 ? (
                        <List>
                          <Card>
                            <ListSubheader>
                              <h2 style={{ color: BIBlack }}>
                                Contacts within 5 km
                              </h2>
                            </ListSubheader>
                            {yourContactData.map((op, itemIndex) => (
                              <React.Fragment key={itemIndex}>
                                <ContactList
                                  userId={op.userId}
                                  itemIndex={itemIndex}
                                  firstName={op.firstName}
                                  lastName={op.lastName}
                                  userTrade={op.userTrade}
                                  distance={op.distance}
                                  address={op.address}
                                  address2={op.address2}
                                  city={op.city}
                                  state={op.state}
                                  postalCode={op.postalCode}
                                  country={op.country}
                                />
                              </React.Fragment>
                            ))}
                          </Card>
                        </List>
                      ) : (
                        <></>
                      )}

                      {newConnectionData.length > 0 ? (
                        <List>
                          <Card>
                            <ListSubheader>
                              <h3></h3>
                              <h3 style={{ color: BIBlack }}>
                                Others within 5 km
                              </h3>
                            </ListSubheader>
                            {newConnectionData.map((op, itemIndex) => (
                              <React.Fragment key={itemIndex}>
                                <ContactList
                                  userId={op.userId}
                                  itemIndex={itemIndex}
                                  firstName={op.firstName}
                                  lastName={op.lastName}
                                  userTrade={op.userTrade}
                                  pictureUrl={op.pictureUrl}
                                  distance={op.distance}
                                  address={op.address}
                                  address2={op.address2}
                                  city={op.city}
                                  state={op.state}
                                  postalCode={op.postalCode}
                                  country={op.country}
                                />
                              </React.Fragment>
                            ))}
                          </Card>
                        </List>
                      ) : (
                        <></>
                      )}
                    </>
                  </InfiniteScroll>
                ) : (
                  <Placeholder
                    icon={<PersonIcon />}
                    text="No people available for ride sharing."
                  />
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {showTutorialForNewUser('carpool') && (
        <CarpoolTutorial
          onPress={() => {
            setTutorialIndex((p) => {
              if (p >= 4) {
                if (showTutorialForNewUser('carpool')) {
                  updateTutorialForNewUser('carpool');
                }
                return 0;
              } else {
                return p + 1
              }
            });

          }}
          tutorialIndex={tutorialIndex}
        />
      )}
    </>
  );
}
