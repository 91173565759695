import React, { useState, useEffect, useCallback } from "react";
import { Button, Typography } from "@material-ui/core";
import { MdImage, MdWarning, MdStar } from "react-icons/md";
import {
  FaBriefcase,
  FaEllipsisV,
  FaQuestionCircle,
  FaBarcode,
  FaWrench,
} from "react-icons/fa";
import { IoPersonSharp } from "react-icons/io5";
import { fetchRewardActivities } from "../../api/RewardsApi";
import Store from "./Rewards";
import BuilderBucksBalance from "../../components/BuilderBucksBalance";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Minimize } from "@mui/icons-material";
import {
  GetNewQuestionForTheUser2,
  getNewQuestionForTheUser,
} from "../../api/UserApi";
export function HelpModal(props) {
  return (
    <>
      <Store activeTab={props?.activeTab ?? ""} />
      <div className="page">
        <div className="page-content">
          <BuilderBucksBalance />
          <HelpModalcard />
        </div>
      </div>
    </>
  );
}

export function HelpModalcard(props) {
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [rewardActivity, setRewardActivity] = useState([]);
  const [isEarnRewardsAvailable, setIsEarnRewardsAvailable] = useState(false);

  useEffect(() => {
    getNewQuestionListForTheUser();
  }, []);

  const getNewQuestionListForTheUser = async () => {
    try {
      const question = await GetNewQuestionForTheUser2(localStorage.userId);
      console.log("question ::::", question);
      setIsEarnRewardsAvailable(question ? true : false);
    } catch (err) {
      console.log("Error while fetching new question for the user :: ", err);
    }
  };

  let history = useHistory();
  const userId = localStorage.userId;

  const handleSendInviteClick = useCallback(async (company) => {
    window.location = "/contacts/";
  }, []);

  const handleSitesClick = useCallback(async (company) => {
    window.location = "/new-map/";
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeSecond = (panel) => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false);
  };
  const handleChangeThird = (panel) => (event, isExpanded) => {
    setExpanded3(isExpanded ? panel : false);
  };
  const handleChangeFourth = (panel) => (event, isExpanded) => {
    setExpanded4(isExpanded ? panel : false);
  };

  const updateRewardActivities = useCallback(() => {
    console.log("Getting Reward Activities for userId: " + userId);
    fetchRewardActivities({ SearchList: [{ createdBy: userId }] }).then(
      (res) => {
        let rewardActivityData = [];
        if (res) {
          res.forEach((rewardActivity) => {
            rewardActivityData.push({
              id: rewardActivity.id || 0,
              bucks: rewardActivity.bucks || 0,
            });
          });
        }
        setRewardActivity(rewardActivityData);
      }
    );
  }, [userId]);

  useEffect(() => {
    updateRewardActivities();
  }, [updateRewardActivities]);

  const goToSearchPage = (type) => {
    if (type == "manufacturers") {
      history.push("/manufactures");
    } else {
      history.push("/products");
    }
  };

  const getBucks = (activityId) => {
    const data = rewardActivity.filter((buck) => buck.id == activityId);
    return data[0]?.bucks;
  };

  return (
    <div className="earnBuck-cards-container">
      <div className="flex-container-vertical">
        {isEarnRewardsAvailable && (
          <div
            style={{
              backgroundColor: "white",
              marginTop: "10px",
              borderRadius: "5px",
            }}
          >
            <div
              className="flex-container-vertical"
              style={{ alignItems: "center", padding: "20px 0px" }}
            >
              <Typography variant="h4">
                Hello {localStorage.firstName} !
              </Typography>
              <span>
                You have questions you can answer to earn more BuilderBucks !{" "}
              </span>
              <div style={{ padding: "10px 0px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push("/rewards/earn-rewards");
                  }}
                >
                  Earn Rewards
                </Button>
              </div>
            </div>
          </div>
        )}
        <div
          className="flex-container-vertical rewards-help-modal-header"
          style={{ alignItems: "center", marginTop: "10px" }}
        >
          <h2 className="help-modal-title">
            How to Earn BuilderBucks {rewardActivity.id}
          </h2>
          <p>
            BuildID wants to reward you for what you know about the sites you
            work on!
          </p>
        </div>

        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          style={{ margin: "10px 0px" }}
        >
          <AccordionSummary
            expandIcon={expanded === "panel1" ? <Minimize /> : <AddIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className={"builder-bucks-bar-header-div"}>
              <Typography variant="h6">
                <b> Construction Community</b>
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="white-bg">
              <div className="community-container">
                {/* <h3>Construction Community</h3> */}
                <p>
                  {" "}
                  <span className="blue-text">
                    {" "}
                    {getBucks(21)} BuilderBucks*
                  </span>
                </p>
                <p> For every new user that you refer to BuildID.</p>
                <p>Ways you can invite people:</p>
              </div>

              <div className="flex-container-horizontal adding-contacts-container">
                <div className="flex-container knowledge-container">
                  <div className="help-modal-adding-contacts">
                    <IoPersonSharp />
                  </div>
                </div>

                <div
                  className="flex-container-right"
                  style={{ flex: 1, marginLeft: "20px" }}
                >
                  <div className="adding-contacts">
                    <h4>Adding Contacts </h4>
                    <p className="blue-text"> {getBucks(21)} BuilderBucks*</p>
                    <p>
                      Invite others to be a part of this great construction
                      community and earn BuilderBucks for each new contact you
                      add!
                    </p>

                    <Button
                      variant="contained"
                      onClick={() => handleSendInviteClick()}
                      color="primary"
                    >
                      Send Invite
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded2 === "panel1"}
          onChange={handleChangeSecond("panel1")}
          style={{ marginBottom: "10px" }}
        >
          <AccordionSummary
            expandIcon={expanded2 === "panel1" ? <Minimize /> : <AddIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className={"builder-bucks-bar-header-div"}>
              <Typography variant="h6">
                <b>Your Sites</b>
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="white-bg">
              <div className="community-container">
                <p>
                  {" "}
                  <span className="blue-text">
                    {" "}
                    {getBucks(8)} BuilderBucks*
                  </span>
                </p>
                <p> For every new site you add to your timeline.</p>
                <Button
                  variant="contained"
                  onClick={() => handleSitesClick()}
                  color="primary"
                >
                  Go to Sites
                </Button>
                <p>
                  Ways you can earn more BuilderBuckes from the <br />
                  sites added to your timeline :
                </p>
              </div>

              <div className="flex-container-horizontal adding-contacts-container">
                <div className="flex-container knowledge-container">
                  <div className="help-modal-adding-contacts">
                    <MdImage />
                  </div>
                </div>

                <div
                  className="flex-container-right"
                  style={{ flex: 1, marginLeft: "20px" }}
                >
                  <div className="adding-contacts">
                    <h4>Site Images </h4>
                    <p className="blue-text">{getBucks(9)} BuilderBucks*</p>
                    <p>
                      For adding picture you take of sites. We want to see them!
                    </p>
                    <p style={{ fontStyle: "italic" }}>
                      Tap the <b>Add Picture </b>button from the eacch site in
                      your timeline
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex-container-horizontal adding-contacts-container">
                <div
                  className="flex-container-right"
                  style={{ flex: 1, marginLeft: "20px" }}
                >
                  <div className="adding-contacts">
                    <h4>Company Roles </h4>
                    <p className="blue-text">{getBucks(11)} BuilderBucks*</p>
                    <p>
                      For adding the role your company played at each site you
                      worked at.
                    </p>
                    <p style={{ fontStyle: "italic" }}>
                      Under the Companies section of each site, tap Tab{" "}
                      <FaEllipsisV size={12} /> next to your company name.
                    </p>
                  </div>
                </div>
                <div className="flex-container knowledge-container">
                  <div className="help-modal-adding-contacts">
                    <FaBriefcase />
                  </div>
                </div>
              </div>

              <div className="flex-container-horizontal adding-contacts-container">
                <div className="flex-container knowledge-container">
                  <div className="help-modal-adding-contacts">
                    <FaBriefcase />
                  </div>
                </div>

                <div
                  className="flex-container-right"
                  style={{ flex: 1, marginLeft: "20px" }}
                >
                  <div className="adding-contacts">
                    <h4>Other Companies </h4>
                    <p className="blue-text">{getBucks(12)} BuilderBucks*</p>
                    <p>
                      For adding other companies that worked on the sites you
                      worked at.
                    </p>
                    <p style={{ fontStyle: "italic" }}>
                      Tap the <b>Add Company </b>button under the Companies
                      section of each site.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex-container-horizontal adding-contacts-container">
                <div
                  className="flex-container-right"
                  style={{ flex: 1, marginLeft: "20px" }}
                >
                  <div className="adding-contacts">
                    <h4>Site Products </h4>
                    <p className="blue-text">{getBucks(4)} BuilderBucks*</p>
                    <p>
                      For adding any products you worked with while on the site.
                    </p>
                    <p style={{ fontStyle: "italic" }}>
                      Tap the <b>Add Product </b>button under the Products
                      section of each site.
                    </p>
                  </div>
                </div>
                <div className="flex-container knowledge-container">
                  <div className="help-modal-adding-contacts">
                    <FaWrench />
                  </div>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded3 === "panel1"}
          onChange={handleChangeThird("panel1")}
          style={{ marginBottom: "10px" }}
        >
          <AccordionSummary
            expandIcon={expanded3 === "panel1" ? <Minimize /> : <AddIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className={"builder-bucks-bar-header-div"}>
              <Typography variant="h6">
                <b>Manufacture and Products</b>
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="white-bg">
              <div className="community-container">
                <p>
                  {" "}
                  <span className="blue-text">
                    {" "}
                    {getBucks(2)} BuilderBucks*
                  </span>
                </p>
                <p>
                  {" "}
                  For every new Manufacture and/or product you .<br /> and that
                  gets approved.
                </p>
                {/* <p></p> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "30px 0px",
                  }}
                >
                  <Button
                    style={{ width: "180px", marginRight: "20px" }}
                    variant="contained"
                    onClick={() => goToSearchPage("manufacturers")}
                    color="primary"
                  >
                    Go to Manufacture Profiles
                  </Button>
                  <Button
                    style={{ width: "180px", marginLeft: "20px" }}
                    variant="contained"
                    onClick={() => goToSearchPage("products")}
                    color="primary"
                  >
                    Go to Product Profiles
                  </Button>
                </div>
                <p>
                  You can earn more by adding by things to
                  <br />
                  products!
                </p>
              </div>

              <div className="flex-container-horizontal adding-contacts-container">
                <div
                  className="flex-container-right"
                  style={{ flex: 1, marginLeft: "20px" }}
                >
                  <div className="adding-contacts">
                    <h4>Rate this Product</h4>
                    <p className="blue-text">{getBucks(5)} BuilderBucks*</p>
                    <p>
                      For adding what you thought about each product you have
                      worked with.{" "}
                    </p>
                  </div>
                </div>
                <div className="flex-container knowledge-container">
                  <div className="help-modal-adding-contacts">
                    <MdStar />
                  </div>
                </div>
              </div>

              <div className="flex-container-horizontal adding-contacts-container">
                <div className="flex-container knowledge-container">
                  <div className="help-modal-adding-contacts">
                    <MdImage />
                  </div>
                </div>

                <div
                  className="flex-container-right"
                  style={{ flex: 1, marginLeft: "20px" }}
                >
                  <div className="adding-contacts">
                    <h4>Product Image Gallery </h4>
                    <p className="blue-text">{getBucks(6)} BuilderBucks*</p>
                    <p>For adding picture you have taken of the product. </p>
                  </div>
                </div>
              </div>
              <div className="flex-container-horizontal adding-contacts-container">
                <div
                  className="flex-container-right"
                  style={{ flex: 1, marginLeft: "20px" }}
                >
                  <div className="adding-contacts">
                    <h4>Barcode Image Gallery</h4>
                    <p className="blue-text">{getBucks(7)} BuilderBucks*</p>
                    <p>For scanning in the barcode for a product.</p>
                  </div>
                </div>
                <div className="flex-container knowledge-container">
                  <div className="help-modal-adding-contacts">
                    <FaBarcode />
                  </div>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded4 === "panel1"}
          onChange={handleChangeFourth("panel1")}
        >
          <AccordionSummary
            expandIcon={expanded4 === "panel1" ? <Minimize /> : <AddIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className={"builder-bucks-bar-header-div"}>
              <Typography variant="h6">
                <b>Sharing Your Knowledge</b>
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="white-bg">
              <div className="community-container">
                <p> Your feedback is important to us!.</p>
              </div>

              <div className="flex-container-horizontal adding-contacts-container">
                <div className="flex-container knowledge-container">
                  <div className="help-modal-adding-contacts">
                    <FaQuestionCircle />
                  </div>
                </div>

                <div
                  className="flex-container-right"
                  style={{ flex: 1, marginLeft: "20px" }}
                >
                  <div className="adding-contacts">
                    <h4>Answer Questions </h4>
                    <p className="blue-text">
                      BuilderBucks vary per question*.
                    </p>
                    <p>
                      Show off all your knowledge and earn free stuff by doing
                      so! There will be new questions to answer each day.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex-container-horizontal adding-contacts-container">
                <div
                  className="flex-container-right"
                  style={{ flex: 1, marginLeft: "20px" }}
                >
                  <div className="adding-contacts">
                    <h4>Report Incorrect Information </h4>
                    <p className="blue-text">{getBucks(22)} BuilderBucks*</p>
                    <p>
                      for any information inside BuildID that doesn't seem right
                      to you. We want to get these fixed!
                    </p>
                    <p style={{ fontStyle: "italic" }}>
                      <i>
                        Tab <FaEllipsisV size={12} /> next to the data you
                        disagree with.
                      </i>
                    </p>
                  </div>
                </div>
                <div className="flex-container knowledge-container">
                  <div className="help-modal-adding-contacts">
                    <MdWarning />
                  </div>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="help-modal-footer-info">
        <p>
          <i>
            * BuilderBucks awarded for information that is later deleted and/or
            found to be incorrect may be revoked.
          </i>
        </p>
      </div>
    </div>
  );
}
