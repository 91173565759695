import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import Post from "../../components/post/Post";
import "../../assets/css/Dashboard.css";
import {
  getUserInfo,
  getUserRewardAmount,
  fetchUserCompanies,
  fetchUserWorkHistory,
  updateProgressBarDismiss,
  getUserDashboard,
  getUserProfileDetailsForProgressBar,
  fetchPost,
} from "../../api/UserApi";
import {
  getPostComments,
  getPostsNewsFeed,
  savePostsUsers,
} from "../../api/PostsApi";
import {
  Card,
  IconButton,
  Stack,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  ListItemIcon,
  ListItemText,
  Fab,
  CircularProgress,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import ReportModal from "../../components/ReportModal";
import PostInput from "./AddPost";
import LinearProgress from "@mui/material/LinearProgress";
import { BIBlue, BILightGrey } from "../../assets/buildidColors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PromptModal from "../../components/PromptModal";
import Loader from "../../components/Loader";
import Placeholder from "../../components/Placeholder";
import ChatIcon from "@mui/icons-material/Chat";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import PostCommentModal from "../../components/post/PostCommentModal";
import EarnBuilderBucks from "../rewards/EarnBuilderBucksCard";
import { Logout, TryOutlined } from "@mui/icons-material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { Button, Link } from "@material-ui/core";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { fetchUserRewardactivities } from "../../api/RewardsApi";

export default function Dashboard() {
  const history = useHistory();
  const location = useLocation();

  const [builderBucksBalance, setBuilderBucksBalance] = useState(0);
  const [nrOfSites, setNrOfSites] = useState(0);
  const [workYears, setWorkYears] = useState(0);
  const [numUniqueCompanies, setNumUniqueCompanies] = useState(0);
  // posts
  const [pinned, setPinned] = useState([]);
  const [posts, setPosts] = useState([]);
  // query
  const postsPerPage = 10;
  const [pageNr, setPageNr] = useState(1);
  const [tab, setTab] = React.useState("All");
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [postId, setPostId] = useState(0);
  const [reportPostName, setRepostPostName] = useState("");
  const [isProgressBarExpanded, setIsProgressBarExpanded] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showProgressBarDismissModal, setShowProgressBarDismissModal] =
    useState(false);
  const [tasksCompleteStatus, setTasksCompleteStatus] = useState(false);
  const [progressStatus, setProgressStatus] = useState(0);
  const [progressBardDetails, setProgressBarDetails] = useState({
    userTrade1: false,
    userFollowingSite: false,
    userEducation: false,
    userExperience: false,
    userExperience1: false,
    userBuilderBucks: false,
  });
  const [progressLodaing, setProgressLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const [visitedPosts, setVisitedPosts] = useState([]);
  const visitedPostsRef = useRef(visitedPosts);
  const [loading, setLoading] = useState(false);
  const [newCommentModalOpen, setNewCommentModalOpen] = useState(false);
  const [notificationPost, setNotificationPost] = useState({});
  const [comments, setComments] = useState([]);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [visibleQuestions, setVisibleQuestions] = useState(false);
  const [answerCount, setAnswercount] = useState(null);
  // new code sanket 29-03-2024

  const [loadingData, setLoadingData] = useState(false);

  const [btnlist, Setbtnlist] = useState([
    {
      text: "All",
      active: true,
    },
    {
      text: "Projects",
      active: false,
    },
    {
      text: "Trades",
      active: false,
    },
    {
      text: "Products",
      active: false,
    },
  ]);

  const updateWorkHistory = useCallback(() => {
    Promise.all([
      fetchUserCompanies({ SearchList: [{ userId: localStorage.userId }] }),
      fetchUserWorkHistory({ SearchList: [{ userId: localStorage.userId }] }),
    ]).then(([companyRes, siteRes]) => {
      setNumUniqueCompanies(companyRes.length);
      if (siteRes) {
        let workHistoryData = [];
        siteRes.forEach((exp) => {
          workHistoryData.push({
            id: exp.id || 0,
            userId: exp.userId || 0,
            SiteId: exp.SiteId || 0,
            startDate: exp.startDate || "",
            endDate: exp.endDate || "",
            tradeId: exp.tradeId || 0,
            companyId: exp.companyId || 0,
            SiteName: exp.SiteName || "",
            tradeName: exp?.tradeName || "",
            companyName: exp.companyName || "",
            includeInResume: exp.includeInResume ? exp.includeInResume : false,
            present: exp.present ? exp.present : false,
            roleId: exp.roleId || 0,
            roleName: exp.roleName || "",
            latitude: exp.latitude || 0.0,
            longitude: exp.longitude || 0.0,
          });
        });

        let firstYear = new Date().getFullYear();
        let lastYear = new Date().getFullYear();

        companyRes.forEach((company) => {
          let companyStartYear = company.startDate
            ? new Date(company.startDate).getFullYear()
            : null;
          let companyEndYear = company.endDate
            ? new Date(company.endDate).getFullYear()
            : new Date().getFullYear();

          if (companyStartYear && companyStartYear < firstYear)
            firstYear = companyStartYear;
          if (companyEndYear && companyEndYear > lastYear)
            lastYear = companyEndYear;
        });

        setWorkYears(lastYear - firstYear);
      }
    });
  }, []);

  const updateBuilderBucksBalance = useCallback(() => {
    getUserRewardAmount(localStorage.userId).then((res) => {
      if (res) setBuilderBucksBalance(res.bucks);
      else console.log("Error: No BuilderBucks balance found");
    });
  }, []);

  useEffect(() => {
    setVisibleQuestions(true);
  }, []);

  useEffect(() => {
    if (
      location?.state?.from === "DashboardNotifications" ||
      location?.search != ""
    ) {
      // const queryString = "?notificationPostId=8557";
      const queryString = location?.search;
      const id = queryString.split("=")[1];

      setNewCommentModalOpen(true);
      fetchPostData(id);
    }
  }, [location?.state?.refresh, history]);

  useEffect(async () => {
    try {
      await getUserDashboard(localStorage?.userId)
        .then((res) => {
          setShowProgressBar(res?.isProgressBarDismiss);
          if (!res?.isProgressBarDismiss) {
            getUserProfileInfo();
          } else {
            setShowLoader(false);
          }
        })
        .catch((err) => {
          console.log("Error while getting user info. :: ", err);
        });
    } catch (err) {
      console.log("Error while getting user info. :: ", err);
    }
  }, [location?.state]);

  async function fetchPostData(params) {
    try {
      setLoading(true);
      const Post_obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage?.userId),
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        SearchList: [
          {
            Id:
              location?.state?.fromTo === "getNotify"
                ? location?.state?.comment
                : location?.search !== ""
                  ? params
                  : location?.state?.comment?.custom1,
          },
        ],
      };
      var singlePost = await fetchPost(Post_obj);

      setNotificationPost(singlePost.data[0]);
      updatePostComment(singlePost.data[0]);
      setLoading(false);
    } catch (e) {
      console.log("ERROR ::: ", e);
      setLoading(false);
    }
  }



  async function updatePostComment(params) {
    try {
      setLoading(true);
      let pageNr = 1;
      let nrOfRecPerPage = 25;
      const postComments = await getPostComments(
        params?.id,
        pageNr,
        nrOfRecPerPage,
        params?.appId
      );
      setComments(postComments);
      setPageNr(1);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("ERROR ::: ", e);
    }
  }

  async function updatePostCommentNew() {
    try {
      setLoading(true);
      let pageNr = 1;
      let nrOfRecPerPage = 25;
      const postComments = await getPostComments(
        notificationPost.id,
        pageNr,
        nrOfRecPerPage,
        notificationPost.appId
      );
      setComments(postComments);
      setPageNr(1);
      setLoading(false);
    } catch (e) {
      console.log("ERROR ::: ", e);
      setLoading(false);
    }
  }

  async function fetchMoreComments() {
    let nrOfRecPerPage = 25;
    try {
      const postComments = await getPostComments(
        notificationPost?.id,
        pageNr + 1,
        nrOfRecPerPage,
        notificationPost?.appId
      );
      setComments(comments.concat(postComments));
      setPageNr(pageNr + 1);
    } catch (e) {
      console.log("ERROR ::: ", e);
    }
  }

  const getUserProfileInfo = async () => {
    try {
      const progressBarInfo = await getUserProfileDetailsForProgressBar(
        localStorage?.userId
      );
      if (
        progressBarInfo?.[0]?.userTrade &&
        progressBarInfo?.[0]?.followingSite &&
        progressBarInfo?.[0]?.education &&
        (progressBarInfo?.[0]?.experience ||
          progressBarInfo?.[0]?.experience1) &&
        progressBarInfo?.[0]?.builderbuck
      ) {
        setTasksCompleteStatus(true);
      } else {
        setTasksCompleteStatus(false);
      }
      let completedTasks = 0;
      if (progressBarInfo?.[0]?.userTrade) {
        completedTasks++;
      }
      if (progressBarInfo?.[0]?.followingSite) completedTasks++;
      if (progressBarInfo?.[0]?.education) completedTasks++;
      if (progressBarInfo?.[0]?.experience || progressBarInfo?.[0]?.experience1)
        completedTasks++;
      if (progressBarInfo?.[0]?.builderbuck) completedTasks++;
      setProgressStatus((completedTasks / 5) * 100);
      setProgressBarDetails({
        userTrade1: progressBarInfo?.[0]?.userTrade,
        userFollowingSite: progressBarInfo?.[0]?.followingSite,
        userEducation: progressBarInfo?.[0]?.education,
        userExperience: progressBarInfo?.[0]?.experience,
        userExperience1: progressBarInfo?.[0]?.experience1,
        userBuilderBucks: progressBarInfo?.[0]?.builderbuck,
      });
      setProgressLoading(false);
      setShowLoader(false);
    } catch (error) {
      console.log(
        "Error while getting user profile details for progressbar :: ",
        error
      );
    }
  };

  async function fetchMorePosts() {
    try {
      setAllDataLoaded(true);
      const dashboardPost = await getPostsNewsFeed(
        tab,
        pageNr + 1,
        postsPerPage
      );
      if (dashboardPost.length > 0) {
        const withOutPinPost = dashboardPost.filter(
          (post) => post.appId !== 19
        );
        const withOutExternalAdPost = withOutPinPost.filter(
          (post) => post.appId !== 0
        );
        setPosts(posts.concat(withOutExternalAdPost));
      }
      setAllDataLoaded(false);
      setPageNr(pageNr + 1);
    } catch (e) {
      setAllDataLoaded(false);
      console.log("ERROR ::: ", e);
    }
  }

  async function updatePosts() {
    try {
      setLoadingData(true);
      const res = await getPostsNewsFeed(tab, 1, postsPerPage);
      if (res.length > 0) {
        const withOutPinPost = res.filter((post) => post.appId !== 19);
        const withOutExternalAdPost = withOutPinPost.filter(
          (post) => post.appId !== 0
        );
        setPinned(res.filter((post) => post.appId === 19));
        setPosts(withOutExternalAdPost);
      }
      setLoadingData(false);
    } catch (e) {
      setLoadingData(false);
      console.log("ERROR ::: ", e);
    }
  }

  // On tab change
  useEffect(() => {
    setPinned([]); // Reset pinned post
    setPosts([]);
    getPostsNewsFeedDashboard();
  }, [tab]);

  async function getPostsNewsFeedDashboard() {
    try {
      setLoadingData(true);
      const dashboardPost = await getPostsNewsFeed(tab, 1, postsPerPage);
      if (dashboardPost?.length > 0) {
        const withOutPinPost = dashboardPost?.filter(
          (post) => post?.appId !== 19
        );
        const withOutExternalAdPost = withOutPinPost.filter(
          (post) => post?.appId !== 0
        );
        setPinned(dashboardPost?.filter((post) => post?.appId === 19));
        setPosts(withOutExternalAdPost);
      }
      setLoadingData(false);
    } catch (e) {
      setLoadingData(false);
      console.log("ERROR ::: ", e);
    }
  }

  const findImpAndViewWithLatestDate = (arr) => {
    let resultMap = {};
    arr.forEach((obj) => {
      const {
        UserId,
        id,
        NumberOfImpression,
        ImpressionLastDate,
        NumberOfViews,
        ViewLastDate,
      } = obj;

      // console.log("obj : ", obj);

      if (id in resultMap) {
        // Update latest ImpressionLastDate
        if (NumberOfImpression && ImpressionLastDate) {
          if (
            !resultMap[id].ImpressionLastDate ||
            new Date(ImpressionLastDate) >
            new Date(resultMap[id].ImpressionLastDate)
          ) {
            resultMap[id].ImpressionLastDate = ImpressionLastDate;
          }
        }

        // Update latest ViewLastDate
        if (NumberOfViews && ViewLastDate) {
          if (
            !resultMap[id].ViewLastDate ||
            new Date(ViewLastDate) > new Date(resultMap[id].ViewLastDate)
          ) {
            resultMap[id].ViewLastDate = ViewLastDate;
          }
        }

        // Count NumberOfImpression
        if (NumberOfImpression) {
          resultMap[id].impCount = (resultMap[id].impCount || 0) + 1;
        }

        // Count view
        if (NumberOfViews) {
          resultMap[id].viewCount = (resultMap[id].viewCount || 0) + 1;
        }
      } else {
        resultMap[id] = { UserId, id };

        // Initialize impCount
        if (NumberOfImpression) {
          resultMap[id].impCount = 1;
        }

        // Initialize viewCount
        if (NumberOfViews) {
          resultMap[id].viewCount = 1;
        }

        // Initialize ImpressionLastDate
        if (NumberOfImpression && ImpressionLastDate) {
          resultMap[id].ImpressionLastDate = ImpressionLastDate;
        }

        // Initialize ViewLastDate
        if (NumberOfViews && ViewLastDate) {
          resultMap[id].ViewLastDate = ViewLastDate;
        }
      }
    });
    return Object.values(resultMap);
  };

  const impressionViewAnalyticHandler = async (visitedPostList) => {
    const arr = findImpAndViewWithLatestDate(visitedPostList);
    let newArr = [];
    for (let i = 0; i < arr?.length; i++) {
      const item = arr[i];
      if (
        item.hasOwnProperty("viewCount") ||
        item.hasOwnProperty("ViewLastDate")
      ) {
        newArr.push({
          Id: 0,
          PostId: item?.id,
          UserId: item?.UserId,
          NumberOfImpressions: item?.impCount,
          NumberOfViews: item?.viewCount,
          ImpressionLastDate: item?.ImpressionLastDate,
          ViewerDate: item?.ViewLastDate,
          ModifiedBy: item?.UserId,
        });
      } else {
        newArr.push({
          Id: 0,
          PostId: item?.id,
          UserId: item?.UserId,
          NumberOfImpressions: item?.impCount,
          NumberOfViews: 0,
          ImpressionLastDate: item?.ImpressionLastDate,
          ViewerDate: "",
          ModifiedBy: item?.UserId,
        });
      }
    }
    const addImpressionAndViewObj = {
      UserId: localStorage.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: newArr,
    };
    // console.log("final newArr : ", newArr);
    try {
      const res = await savePostsUsers(addImpressionAndViewObj);
    } catch (err) {
      console.log("ERROR WHILE SAVING IMPRESSIONS AND VIEW", err);
    }
  };

  // On load
  useEffect(() => {
    getUserInfo(localStorage.userId).then((res) => {
      if (res) setNrOfSites(res.nrOfSites || 0);
    });

    updateWorkHistory();
    updateBuilderBucksBalance();
  }, [updateBuilderBucksBalance, updateWorkHistory]);

  useEffect(() => {
    //   //called the function whenever user will refresh page or close the browser tab
    //   window.addEventListener("beforeunload", (e) => {
    //     e.preventDefault();
    //     impressionViewAnalyticHandler(visitedPostsRef.current);
    //   });

    return () => {
      //called the function on dashboard will unmount
      impressionViewAnalyticHandler(visitedPostsRef.current);

      //     //removed the event listener on unmount phase
      //     window.removeEventListener("beforeunload", (e) => {
      //       e.preventDefault();
      //       impressionViewAnalyticHandler(visitedPostsRef.current);
      //     });
    };
  }, []);

  useEffect(() => {
    visitedPostsRef.current = visitedPosts;
  }, [visitedPosts]);

  const onDismissProgress = async () => {
    const obj = {
      Id: localStorage.userId,
    };
    try {
      await updateProgressBarDismiss(obj)
        .then((res) => {
          setShowProgressBar(false);
          setTasksCompleteStatus(false);
          setProgressLoading(true);
          setShowProgressBarDismissModal(false);
          setShowLoader(false);
        })
        .catch((error) => {
          setShowProgressBarDismissModal(false);
          console.log("Error while dismissing the progress bar ::", error);
        });
    } catch (error) {
      setShowProgressBarDismissModal(false);
      console.log("Error while dismissing the progress bar ::", error);
    }
  };

  // new code sanket 29-03-2024
  const onPostactive = (index, tabName) => {
    let getList = btnlist.map((item, getIndex) => {
      if (index === getIndex) {
        return {
          ...item,
          active: true,
        };
      } else {
        return {
          ...item,
          active: false,
        };
      }
    });
    Setbtnlist(getList);
    if (tabName != null) setTab(tabName);
    else setTab(tab);
    setPageNr(1); // Also reset page number
  };

  const learnMore = () => {
     history.push("/rewards/store/");
  }

  return (
    <div className="dashboard-page">
      <ReportModal
        isOpen={reportModalOpen}
        onClose={() => setReportModalOpen(false)}
        name={reportPostName == "Ads" ? "Ad" : "Post"}
        recordId={postId}
      />

      <PostCommentModal
        isOpen={newCommentModalOpen}
        onClose={() => {
          console.log("MODAL CLOSED");
          setNewCommentModalOpen(false);
          history.replace({ ...location, state: null, search: "" });
        }}
        comments={comments}
        post={notificationPost}
        updateComments={updatePostCommentNew}
        loading={loading}
        fetchMoreComments={fetchMoreComments}
        from={"dashboardNotification"}
      />

      {showLoader ? (
        <div style={{ margin: "auto" }}>
          <Loader />
        </div>
      ) : (
        <div className="parent-inline-div">
          {!progressLodaing && !showProgressBar && !tasksCompleteStatus ? (
            <Card
              // className="dashboard-inlineMargin"
              sx={{ padding: "10px 20px", marginTop: "10px" }}
            >
              <LinearProgress
                variant="determinate"
                value={progressStatus}
                sx={{
                  backgroundColor: BILightGrey,
                  borderRadius: "10px",
                  height: "5px",
                  ".MuiLinearProgress-bar": {
                    backgroundColor: BIBlue,
                  },
                }}
              />
              <div style={{ marginTop: "10px" }}>
                <Typography variant="body2">
                  Add your contact information to your Profile so others can get
                  in touch with you.
                </Typography>
              </div>
              <div style={{ padding: "0 20px", margin: "5px 0" }}>
                <ul>
                  {!progressBardDetails?.userTrade1 && (
                    <li>
                      {" "}
                      <Typography variant="body2">
                        Add your Current trade{" "}
                      </Typography>
                    </li>
                  )}

                  {isProgressBarExpanded && (
                    <>
                      {!progressBardDetails?.userFollowingSite && (
                        <li>
                          <Typography variant="body2">
                            Follow a Single site that interests you{" "}
                          </Typography>
                        </li>
                      )}
                      {!progressBardDetails?.userEducation && (
                        <li>
                          <Typography variant="body2">
                            Add to your Education{" "}
                          </Typography>
                        </li>
                      )}
                      {progressBardDetails?.userExperience ||
                        progressBardDetails?.userExperience1 ? null : (
                        <li>
                          <Typography variant="body2">
                            Add to your Experiences
                          </Typography>
                        </li>
                      )}
                      {!progressBardDetails?.userBuilderBucks && (
                        <li>
                          <Typography variant="body2">
                            Spend a Builderbuck (under rewards)
                          </Typography>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              </div>
              <Stack direction="row" justifyContent="space-between">
                <Typography>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setIsProgressBarExpanded(!isProgressBarExpanded);
                    }}
                  >
                    {isProgressBarExpanded ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </Typography>
                <Stack direction={"row"} gap={2}>
                  <Typography>
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => {
                        setShowProgressBarDismissModal(true);
                      }}
                    >
                      Dismiss
                    </Button>
                  </Typography>
                  <Typography>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      sx={{
                        backgroundColor: BIBlue,
                        borderRadius: "30px",
                      }}
                      onClick={() => {
                        history.push("/profile");
                      }}
                    >
                      Go to Profile
                    </Button>
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          ) : (
            <></>
          )}
          <Stack className="btn-listing">
            {btnlist.map((item, index) => (
              <Button
                size="small"
                key={index}
                className="btn-post"
                onClick={() => onPostactive(index, item?.text)}
                style={{
                  background: item?.active === true ? "#083db8" : "#ffff",
                  color: item?.active === true ? "#ffff" : "#000",
                }}
              >
                {item?.text}
              </Button>
            ))}
          </Stack>
          <PostInput updatePosts={updatePosts} />
          <div
            id="scrollablediv_prodcut"
            className="scrollablediv_post experience-form"
          >
            {loadingData ? (
              <div>
                <Loader />
              </div>
            ) : (
              <>
                {posts.length != 0 ? (
                  <>
                    {pinned.length > 0 ? (
                      <Post
                        pinned
                        post={pinned[0]}
                        setVisitedPosts={(obj) =>
                          setVisitedPosts([...visitedPosts, obj])
                        }
                        from={"Dashboard"}
                      />
                    ) : (
                      ""
                    )}
                    {visibleQuestions ? (
                      <Card
                        style={{
                          marginTop: "10px",
                          marginBottom: "5px",
                          borderRadius: "10px",
                        }}
                      >
                        <EarnBuilderBucks
                          from={"dashboard-profile"}
                          setVisibleQuestions={() => setVisibleQuestions()}
                          setAnswercount={setAnswercount}
                        />
                      </Card>
                    ) : (
                      answerCount !== null ? (
                        <Card className="dashboard-inlineMargin builderbuks-count" style={{ marginTop: "10px" }}>
                          <p className="an-count">Your Answer was Submitted</p>
                          <div className="an-info">
                            <EmojiEventsIcon className="cup-icon" />
                            <h3 className="answer-count">{answerCount} BuilderBuks</h3>
                            <p className="more-info">Earned by answering this question {" "}<a style={{cursor:"pointer",color:"#083db8"}}  onClick={learnMore}>Learn more</a></p>
                          </div>
                        </Card>
                      ) : null
                    )}
                    <InfiniteScroll
                      dataLength={posts.length}
                      next={fetchMorePosts}
                      hasMore={true}
                      scrollableTarget="scrollablediv_prodcut"
                      loader={
                        !allDataLoaded ? null : (
                          <div
                            // className="flex-container-vertical dashboard-inlineMargin"
                            style={{ alignItems: "center" }}
                          >
                            <CircularProgress />
                          </div>
                        )
                      }
                    >
                      <Stack spacing={0.5}>
                        {posts.map((post, index) => (
                          <React.Fragment key={index}>
                            <Post
                              setReportModalOpen={(value) =>
                                setReportModalOpen(value)
                              }
                              updatePosts={updatePosts}
                              setPostId={(value) => setPostId(value)}
                              setRepostPostName={(value) =>
                                setRepostPostName(value)
                              }
                              post={post}
                              setVisitedPosts={(obj) =>
                                setVisitedPosts([...visitedPosts, obj])
                              }
                              from={location?.state?.from ?? "Dashboard"}
                            />
                          </React.Fragment>
                        ))}
                      </Stack>
                    </InfiniteScroll>{" "}
                  </>
                ) : (
                  <Stack
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "80px",
                      justifyContent: "center",
                    }}
                  >
                    <Placeholder icon={<ChatIcon />} text={"No Posts"} />
                  </Stack>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <PromptModal
        isOpen={showProgressBarDismissModal}
        onClose={() => {
          setShowProgressBarDismissModal(false);
        }}
        title={""}
        desc="Are you sure you want to hide the progress bar?"
        redButtonText="OK"
        blueButtonTextFirst="CANCEL"
        onRedButtonClick={onDismissProgress}
      />
    </div>
  );
}
