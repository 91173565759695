import React, { useState, useEffect, useCallback } from "react";
import defaultProjectProfileThumbnail from "../../../assets/images/project-profile-placeholder-thumbnail.png";
import mapMarkerYellow from "../../../assets/images/map-marker-yellow.png";
import { useHistory, useLocation, } from "react-router-dom/cjs/react-router-dom.min";
import { fetchUserCompanies, fetchUserWorkHistory, fetchUserWorkHistory2 } from "../../../api/UserApi";
import { getCommonProjects, getCommonSites } from "../../../api/SitesApi";
import { List, Card, ListItem, ListItemButton, ListItemText, Avatar, CardContent, } from "@mui/material";
import moment from "moment";
import DetailForm from "../../profile/sections/DetailForm";

export default function UserExperienceCard(props) {
  const [workHistory, setWorkHistory] = useState([]);
  const [unknownCompany, setUnknownCompany] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [workHistoryCommonSites, setWorkHistoryCommonSites] = useState([]);

  function removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray?.push(lookupObject[i]);
    }
    return newArray;
  }

  const getExperienceDetails = async () => {
    try {
      const workHistoryObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        NrOfRecPerPage: 20,
        UserId: Number(props?.contactId),
        SearchList: [{ UserId: Number(props?.contactId) }],
        SortList: [{ FieldName: 'id', Direction: 'DESC' }]
      }
      const workHistory = await fetchUserWorkHistory2(workHistoryObj);
      const filteredWorkHistory = workHistory.userExperienceList.filter(item => item?.projectId !== 0);
      const siteIdArray = filteredWorkHistory.map((work) => work?.projectId);
      const userCompanies = workHistory.userCompanyHistory;
      const noCompSites = filteredWorkHistory.filter((comp) => comp?.companyId == 0);

      let a = [];
      const expData = userCompanies.map((unique) => {
        a = unique;
        a['experience'] = [];
        filteredWorkHistory.map((tempData) => {
          if (tempData.companyId === unique.companyId) {
            let obj = {
              id: tempData.id,
              companyId: tempData.companyId,
              companyName: tempData.companyName, endDate: tempData.endDate,
              includeInResume: tempData.includeInResume, present: tempData.present,
              mainPictureUrl: tempData.mainPictureUrl, siteId: tempData.siteId,
              siteName: tempData.siteName,
              roleName: tempData.roleName, roleId: tempData.roleId,
              projectCompanyRoleName: tempData.projectCompanyRoleName, projectCompanyRoleId: tempData.projectCompanyRoleId,
              startDate: tempData.startDate, tradeId: tempData.tradeId,
              tradeName: tempData.tradeName, address: tempData.address,
              city: tempData.city, province: tempData.province,
              postalCode: tempData.postalCode, country: tempData.country,
              latitude: tempData.latitude, longitude: tempData.longitude,
              description: tempData.description, projectName: tempData.projectName,
              projectId: tempData.projectId,
            }
            a['experience'].push(obj);
          }
        });
        return a;
      })

      const uniqueSites = [...new Set(filteredWorkHistory.map(item => item.siteId))];
      const siteData = uniqueSites.map((siteId) => {
        const siteExperience = filteredWorkHistory.filter(item => item.siteId === siteId);
        if (siteExperience.length > 0) {
          return {
            siteId,
            siteName: siteExperience[0].siteName,
            experience: siteExperience.map(item => ({
              ...item,
            })),
          };
        }
      }).filter(item => item);
      let b = [];
      const noSiteExpData = noCompSites.map((unique) => {
        b = unique;
        b['experience'] = [];
        filteredWorkHistory.map((tempData) => {
          if (tempData.companyId === 0) {
            let obj = {
              id: tempData.id, companyId: tempData.companyId, companyName: tempData.companyName,
              endDate: tempData.endDate, includeInResume: tempData.includeInResume, present: tempData.present,
              mainPictureUrl: tempData.mainPictureUrl, siteId: tempData.siteId,
              siteName: tempData.siteName, roleName: tempData.roleName, roleId: tempData.roleId,
              projectCompanyRoleName: tempData.projectCompanyRoleName, projectCompanyRoleId: tempData.projectCompanyRoleId,
              startDate: tempData.startDate, tradeId: tempData.tradeId, tradeName: tempData.tradeName,
              address: tempData.address, city: tempData.city, province: tempData.province,
              postalCode: tempData.postalCode, country: tempData.country, latitude: tempData.latitude,
              longitude: tempData.longitude, description: tempData.description, projectName: tempData.projectName
            }
            b['experience'].push(obj);
          }
        });
        return b;
      })
      const totalExpData = [...expData, ...noSiteExpData];
      const sortedExpData = totalExpData.filter((op) => op.startDate && op.companyName != '').sort((a, b) => {
        let dateA = new Date(a.startDate), dateB = new Date(b.startDate);
        return dateB - dateA;
      });
      const noDateExp = totalExpData.filter((exp) => !exp.startDate && !exp.endDate && exp.companyId != 0 && exp.companyName !== '');
      const unknownExpData = totalExpData.filter((exp) => exp.companyName == '');
      const newUnknownExpData = removeDuplicates(unknownExpData, 'companyId');
      const sortedNewUnknownExpData = newUnknownExpData[0]?.experience.sort((a, b) => {
        let dateA = new Date(a.startDate), dateB = new Date(b.startDate);
        return dateB - dateA;
      });
      const finalExpData = [...sortedExpData, ...noDateExp,];
      setWorkHistory(finalExpData)
      setAllCompanies(userCompanies)
      setUnknownCompany(sortedNewUnknownExpData ?? [])
      if (props.setLoading) {
        props.setLoading(false);
      }
    } catch (err) {
      console.log("Error-----------", err);
    }
  };

  const fetchCommonProjectDetails = async () => {
    try {
      getCommonProjects(Number(props.contactId))
        .then((res) => {
          setWorkHistoryCommonSites(res);
        })
        .catch((error) => {
          console.log("Error while fetching common site details :: ", error);
        });
    } catch (error) {
      console.log("Error while fetching common site details :: ", error);
    }
  };

  useEffect(async () => {
    try {
      await getExperienceDetails();
      await fetchCommonProjectDetails();
    } catch (error) {
      console.log("Error while getting details :: ", error);
    }
  }, [props.contactId]);

  if (props?.showLoader) {
    return null;
  }

  return (
    <>
      {workHistory.length != 0 && workHistory != "" ? (
        <div className="exp-card">
          <div className="flex-container-horizontal">
            <div className="flex-container-left">
              <div className="input-container">
                <h3>Experience</h3>
              </div>
            </div>
          </div>

          <div>
            <List sx={{ padding: 0 }}>
              {workHistory.map((company, index) => (
                <div key={index}>
                  <Company
                    company={company}
                    commonSites={workHistoryCommonSites}
                  />
                </div>
              ))}
            </List>
            {
              // Display user's companies
              <>
                {unknownCompany.length != 0 ? (
                  <>
                    <p>
                      <b>No Company</b>
                    </p>
                    {unknownCompany?.map((company, index) => (
                      <div key={index}>
                        <Project
                          key={index}
                          project={company}
                          company={company}
                          allCompanies={allCompanies}
                          updateWorkHistory={getExperienceDetails}
                          commonSites={workHistoryCommonSites}
                          from="noCompany"
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            }
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

function Company(props) {
  const history = useHistory();
  const handleCompanyClick = (company) => {
    return history.push(`/company/${company?.companyId ?? 0}`);
  };

  return (
    <Card sx={{ marginBottom: "10px" }}>
      <ListItem disablePadding>
        <ListItemButton onClick={() => handleCompanyClick(props.company)}>
          <ListItemText
            primary={props?.company?.companyName}
            secondary={
              <>
                {props.sitesData ? null : (
                  <>
                    {props.company.roleName ? (
                      <span>
                        {" "}
                        {props.company.roleName} <br />{" "}
                      </span>
                    ) : null}

                    {
                      // If startDate is null, then it's "No date"
                      props.company.startDate !== null ? (
                        <span>
                          {moment(props.company.startDate).format("MMM YYYY")} -{" "}
                          {props.company.currentCompany === false
                            ? props.company.endDate == null ||
                              props.company.endDate == "Invalid date"
                              ? null
                              : moment(props.company.endDate).format("MMM YYYY")
                            : "Present"}{" "}
                          <br />
                        </span>
                      ) : null
                    }
                    {props.company.description ? (
                      <span> {props.company.description} </span>
                    ) : null}
                  </>
                )}
              </>
            }
          />
        </ListItemButton>
      </ListItem>

      <CardContent className={"expCardPadding1"}>
        {props.company.experience
          .sort((a, b) => {
            let dateA = new Date(a.startDate),
              dateB = new Date(b.startDate);
            return dateB - dateA;
          })
          .map((project, index) => {
            return (
              <Project
                key={index}
                project={project}
                company={props.company}
                commonSites={props.commonSites}
              />
            );
          })}
      </CardContent>
    </Card>
  );
}

function Project(props) {
  const [showDetailForm, setShowDetailForm] = useState(false);
  const handleProjectClick = () => {
    setShowDetailForm(true);
  };
  return (
    <>
      <Card className="expCardBgc">
        <ListItem
          secondaryAction={
            <div style={{ paddingRight: "10px" }}>
              {props?.commonSites.some((pro) => pro?.id == props?.project?.projectId) ?
                <img
                  src={mapMarkerYellow}
                  alt="Contact profile pic"
                  style={{ height: "28px" }}
                /> : null}
            </div>
          }
          disablePadding
        >
          <ListItemButton onClick={() => handleProjectClick(props.project)}>
            <Avatar
              variant="square"
              style={{
                marginRight: "15px",
                cursor: "pointer",
                width: "80px",
                height: "55px",
                borderRadius: "5px",
              }}
              src={
                props.project.mainPictureUrl
                  ? props.project.mainPictureUrl
                  : defaultProjectProfileThumbnail
              }
            ></Avatar>
            <ListItemText
              primary={props.project.projectName}
              secondary={
                <>
                  {
                    <span>
                      {" "}
                      {props.project.siteName} <br />{" "}
                    </span>
                  }

                  {
                    // If startDate is null or empty, then it's "No date"
                    props.project.startDate !== null &&
                      props.project.startDate !== "" ? (
                      <span>
                        {moment(props.project.startDate).format("MMM YYYY")} -{" "}
                        {
                          // If endDate is null, then it's to "Present"
                          props.project.present === false
                            ? props.project.endDate == null ||
                              props.project.endDate == "Invalid date"
                              ? null
                              : moment(props.project.endDate).format("MMM YYYY")
                            : "Present"
                        }{" "}
                        <br />
                      </span>
                    ) : null
                  }
                </>
              }
            />
          </ListItemButton>
        </ListItem>
      </Card>

      {showDetailForm && (
        <DetailForm
          show={showDetailForm}
          Close={() => setShowDetailForm(false)}
          new={"new"}
          project={props?.project}
          // siteData={true}
          from={"contactProfile"}
        />
      )}
    </>
  );
}
