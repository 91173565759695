import React, { useState, useEffect, useCallback } from "react";
import { Tabs, Tab, FormControlLabel, Switch, Menu, MenuItem } from "@material-ui/core";
import { Button, Container, Stack, Card, Typography, IconButton, } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import Placeholder from "../../components/Placeholder";
import TimeSheetListSection from "./TimeSheetListSection";
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { fetchGoals, fetchTimesheet, fetchTimeSheetsEntries, fetchTimeSheetsGoals, getTimeSheetsGoalsDetailsForProgressBar, sendUnsentTimesheets, updateIsGoalFlag } from "../../api/TimesheetApi";
import SendTimesheet from "./SendTimesheetModal";
import NewTimesheetModals from "./NewTimesheetModals";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import ReportModal from "../../components/ReportModal";
import LinearProgress from '@mui/material/LinearProgress';
import { BIBlue, BILightGrey } from "../../assets/buildidColors";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Loader from "../../components/Loader";
import { MoreVert as MoreIcon } from "@material-ui/icons";
import { BIRed } from "../../assets/buildidColors";
import AddGoalModal from "./AddGoalModal";
import { Close as CloseIcon } from "@material-ui/icons";
import { Edit as EditIcon } from '@mui/icons-material';
import AddTimeSheetModal from "./AddTimeSheetModal";
import ExportGoalsSummaryModal from "../buysell/ExportGoalsSummaryModal";
import { getUserDashboard, updateIsTimeSheetsBannerFlag } from "../../api/UserApi";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import UserUsages from "../../components/userUsages/UserUsages";
const tabsArray = ["unsent", "sent"];

function TimeSheets() {

	const [activeTab, setActiveTab] = useState("unsent");
	const [isUnsentTimeSheetEmpty, setIsUnsentTimeSheetEmpty] = useState(false);
	const [isSentTimeSheetEmpty, setIsSentTimeSheetEmpty] = useState(false);
	const [pageNr, setPageNr] = useState(1);
	const [sendButtonVisible, setSendButtonVisible] = useState(true);
	const [newTimesheetModalOpen, setnewTimesheetModalOpen] = useState(false);
	const [sendTimesheetModalOpen, setSendTimesheetModalOpen] = useState(false);
	const [selectedTimeSheets, setSelectedTimeSheets] = useState([])
	const [timeSheetId, setTimeSheetId] = useState(0);
	const [editTimeSheetData, setEditTimeSheetData] = useState([])
	const [timesheetEntriesData, setTimesheetEntriesData] = useState([])
	const [fetchTimesheetsLength, setFetchTimesheetsLength] = useState(0);
	const [loadingGoal, setLoadingGoal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [clickFrom, setClickFrom] = useState("");
	const [response, setResponse] = useState([]);
	const [infiniteScroll, setInfinitescroll] = useState(false);
	const [isProgressBarExpanded, setIsProgressBarExpanded] = useState(false);
	const [editGoalModal, setEditGoalModal] = useState(false);

	const [moreMenuAnchor, setMoreMenuAnchor] = useState(null);
	const [reportModalOpen, setReportModalOpen] = useState(false);
	const [addGoalModalOpen, setAddGoalModalOpen] = useState(false);

	const [editGoalDetails, setEditGoalDetails] = useState(null);
	const [TimesheetList, setTimesheetList] = useState([])
	const [userGoalData, setUserGoalData] = useState([])
	const [isHideCompletedGoals, setIsHideCompletedGoals] = useState(false);
	const [timesheetGoalData, setTimesheetGoalData] = useState([]);
	const [callUpdatedData, setCallUpdatedData] = useState(false);
	const [showExportModal, setShowExportModal] = useState(false);
	const [isTimeSheetsBannerFlag, setIsTimeSheetsBannerFlag] = useState(false);

	useEffect(async () => {
		const userData = await getUserDashboard(localStorage?.userId);
		setIsTimeSheetsBannerFlag(userData?.isTimeSheetsBannerFlag)
	}, [])

	useEffect(() => {
		setEditTimeSheetData(editTimeSheetData)
	}, [editTimeSheetData])

	useEffect(() => {
		setTimesheetEntriesData(timesheetEntriesData)
	}, [timesheetEntriesData])

	const handleTabChange = (event, newTab) => {
		setSendButtonVisible(true);
		setPageNr(1);
		setActiveTab(newTab);
	}


	const getDataForEditTimeSheet = async (id) => {
		try {
			const sendObj = {
				PageNr: 1,
				NrOfRecPerPage: 10,
				FullSearch: "",
				UserId: 1,
				IncludeRecordNr: true,
				SearchList: [{ TimeSheetId: id }]
			}
			const getTimesheetGoals = await fetchTimeSheetsGoals(sendObj)
			await setTimesheetGoalData(getTimesheetGoals?.data);
			const obj = {
				PageNr: 1,
				NrOfRecPerPage: 20,
				UserId: Number(localStorage.userId),
				SearchList: [{ TimesheetId: id }]
			};

			let timesheetEntries = await fetchTimeSheetsEntries(obj);
			const newTimeSheetData = timesheetEntries?.data?.map((unique) => {
				const entryWithGoals = { ...unique, timesheetGoalsArray: [] };
				getTimesheetGoals?.data.forEach((item) => {
					if (item.timeSheetEntryId === unique.id) {
						let obj = {
							id: item.id,
							key: item.goalId,
							value: item.goalName,
							goalName: item.goalName,
							categoryName: item.goalCategoryName,
							categoryId: item.goalCategoryId,
							time: item.hours,
							goalId: item.goalId,
							siteId: item.siteId,
							siteName: item.siteName,
							timeSheetEntryId: item.timeSheetEntryId,
							timeSheetId: item.timeSheetId,
						};
						entryWithGoals.timesheetGoalsArray.push(obj);
					}
				});
				return entryWithGoals;
			});
			await setTimesheetEntriesData(newTimeSheetData ?? []);
		} catch (err) {
			console.log("Error while getting data for editing the timesheet :: ", err)
		}

	};


	const getEditTimeSheetData = async (data, id) => {
		try {
			await getDataForEditTimeSheet(id);
			setTimeSheetId(id);
			await setEditTimeSheetData(data);
			setnewTimesheetModalOpen(true);
		} catch (error) {
			console.log('fetchTimesheetEntriesError:', error);
		}
	}

	const increaseOffset = () => {
		setPageNr(pageNr + 1);
	}

	const handleSendTimeSheet = () => {
		setSendTimesheetModalOpen(true)
	}

	function clearCheckBox() {
		response.map((item) => item.isTimesheetChecked = false);
		setResponse(response)
	}

	const sendUnsentTimesheet = async (emailList) => {
		setPageNr(1)
		const timeSheetArray = selectedTimeSheets.map((item) => item.id)

		const sendTimeSheetObj = {
			Emails: emailList,
			TimeSheets: timeSheetArray,
			UserId: Number(localStorage.getItem("userId"))
		}

		const res = await sendUnsentTimesheets(sendTimeSheetObj)
		return res;
	}


	const getUnsentTimesheet = useCallback(async () => {
		const obj = {
			PageNr: 1,
			NrOfRecPerPage: 100,
			FullSearch: "",
			UserId: Number(localStorage.getItem("userId")),
			IncludeRecordNr: true,
			TypeOfObjectReturned: "",
			SearchList: [{ UserId: Number(localStorage.getItem("userId")) }],
			SortList: [
				{
					FieldName: "Id",
					Direction: "DESC"
				}
			]
		}
		const res = await fetchTimesheet(obj);
		const filteredUnsent = res.data.filter((timesheet) => !timesheet.isSent);
		if (filteredUnsent.length === 0)
			setIsUnsentTimeSheetEmpty(true);
		else
			setIsUnsentTimeSheetEmpty(false);
		return filteredUnsent;

	}, [pageNr]);

	const getSentTimesheet = useCallback(async () => {
		const obj = {
			PageNr: 1,
			NrOfRecPerPage: 100,
			FullSearch: "",
			UserId: Number(localStorage.getItem("userId")),
			IncludeRecordNr: true,
			TypeOfObjectReturned: "",
			SearchList: [{ UserId: Number(localStorage.getItem("userId")) }],
			SortList: [
				{
					FieldName: "Id",
					Direction: "DESC"
				}
			]
		}
		const res = await fetchTimesheet(obj)
		const filteredSent = res.data.filter((timesheet) => timesheet.isSent)
		if (res.nrOfRecords === res.data.length) {
			setInfinitescroll(false);
		} else {
			setInfinitescroll(true);
		}
		setFetchTimesheetsLength(res.data.length);
		if (filteredSent.length === 0)
			setIsSentTimeSheetEmpty(true);
		else
			setIsSentTimeSheetEmpty(false);
		return filteredSent;
	}, [pageNr]);

	useEffect(() => {
		getTimeSheetGoal();
		getUsersGoal();
		setPageNr(1);
		UserUsages('Timesheets');
	}, []);

	const getTimeSheetGoal = async () => {
		try {
			const goals = await getTimeSheetsGoalsDetailsForProgressBar(localStorage.userId);
			setTimesheetList(goals)
		} catch (error) {
			console.log('getTimeSheetGoal:', error);
		}
	}

	const getUsersGoal = async () => {
		try {
			const obj = {
				PageNr: 1,
				// NrOfRecPerPage: 10,
				FullSearch: "",
				UserId: Number(localStorage.userId),
				IncludeRecordNr: true,
				SearchList: [{ createdBy: Number(localStorage.userId) }],
			}
			const goals = await fetchGoals(obj);
			let a = [];
			goals.data?.map((item) => {
				a = item;
				a['key'] = item.id;
				a['value'] = item.name;
				return a;
			})
			const sum = goals.data?.reduce((result, item) => { return result + item.hours; }, 0);
			setUserGoalData(goals?.data);
		} catch (error) {
			console.log("ERROR:[getData]:", error);
		}
	}

	const progressData = (item, index) => {

		const shouldHideItem = isHideCompletedGoals && item.isGoalFlag;
		if (shouldHideItem) {
			return null; // Return null to hide the item
		}
		var progressValue = (Number(item.goalProgressTotalHours / item.goalHours) * 100).toFixed(0);
		if (progressValue > 100) {
			var progressValue = 100
		}
		return (
			<>
				<Stack>
					<Stack direction="row" width="100%" justifyContent="space-between" sx={{ padding: '10px 0px' }}>
						<Stack>
							<Typography sx={{ fontWeight: 'bold' }}>
								{item.goalName}
							</Typography>
						</Stack>
						<Stack direction={'row'} gap={1} >
							<Typography>
								{item.goalProgressTotalHours}
							</Typography>
							<Typography>
								/ {item.goalHours} hrs
							</Typography>
						</Stack>
					</Stack>
					<LinearProgress variant="determinate"
						value={progressValue}
						sx={{
							backgroundColor: BILightGrey,
							borderRadius: '10px',
							height: '10px',
							'.MuiLinearProgress-bar': {
								backgroundColor: BIBlue,
							},
						}}
					/>

					{isProgressBarExpanded && item.categoryList.map((category) => (
						<Stack sx={{ paddingTop: '5px' }}>
							<Stack direction="row" width="100%" justifyContent="space-between" >
								<Stack>
									<Typography>
										{category.categoryName}
									</Typography>
								</Stack>
								<Stack direction={'row'}>
									<Typography>
										{category.categoryProgressTotalHours} hrs
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					))}
				</Stack></>
		)
	}


	const onCloseBannerPressed = async () => {
		try {
			const obj = {
				Id: Number(localStorage?.userId),
				IsTimeSheetsBannerFlag: false
			}
			await updateIsTimeSheetsBannerFlag(obj)
			setIsTimeSheetsBannerFlag(false);
		} catch (error) {
			console.log("Error while closing banner :: ", error)
		}
	}

	const handleAddGoal = () => {
		setMoreMenuAnchor(false);
		setAddGoalModalOpen(true);
		setEditGoalDetails(null);
	}

	const handleReport = () => {
		setMoreMenuAnchor(false);
		setReportModalOpen(true);
	}
	const onEditGoalClickModal = () => {
		setEditGoalModal(true);
	}

	const onEditGoalClick = (item) => {
		setEditGoalDetails(item);
		setAddGoalModalOpen(true)
	}

	const onToggleSwitch = async (value) => {
		setIsHideCompletedGoals(value)
		const updateFlagobj = {
			CreatedBy: Number(localStorage.userId),
		}
		try {
			setLoadingGoal(true);
			await updateIsGoalFlag(updateFlagobj);
			const obj = {
				PageNr: 1,
				NrOfRecPerPage: 10,
				FullSearch: "",
				UserId: Number(localStorage.userId),
				IncludeRecordNr: true,
				SearchList: [{
					createdBy: Number(localStorage.userId)
				}],
			}
			const goals = await fetchGoals(obj);
			setUserGoalData(goals?.data ?? [])
			setLoadingGoal(false)
		} catch (error) {
			console.log("ERROR:[updateIsGoalFlag/fetchGoals]:", error);
			setLoadingGoal(false)
		}
	};

	const onDataSave = () => {
		setIsTimeSheetsBannerFlag(false);
		getTimeSheetGoal();
		getUsersGoal();
	}


	const getUpdatedData = () => {
		// new code sanket 02-04-2024
		setIsUnsentTimeSheetEmpty(false);
		getTimeSheetGoal();
		getUsersGoal();
		setCallUpdatedData(true);
	}


	const renderPlaceholder = () => {
		return (
			<div style={{ marginTop: '90px' }}>
				<Placeholder icon={<CalendarMonthIcon />} text="You don't have any Timesheets." />
			</div>
		)
	}


	const getTimeSheetDivStyle = () => {
		if (isTimeSheetsBannerFlag) {
			return { flex: 1 }
		} else if (TimesheetList.length === 0) {
			return {};
		} else {
			return { flex: 1 }
		}
	}

	return (
		<>
			{
				newTimesheetModalOpen &&
				<AddTimeSheetModal
					isOpen={newTimesheetModalOpen}
					onClose={() => {
						setLoading(false);
						setnewTimesheetModalOpen(false)
					}}
					clickFrom={clickFrom}
					editTimeSheetData={editTimeSheetData}
					timesheetEntriesData={timesheetEntriesData}
					getUpdatedData={getUpdatedData}
				/>
			}

			<SendTimesheet
				isOpen={sendTimesheetModalOpen}
				onClose={() => setSendTimesheetModalOpen(false)}
				setSendButtonVisible={() => setSendButtonVisible(true)}
				selectedTimeSheet={selectedTimeSheets}
				getSentTimesheet={getSentTimesheet}
				closeTimeSheetModal={setSendTimesheetModalOpen}
				handleSendUnsentTimeSheet={sendUnsentTimesheet}
				setLoading={setLoading}
				activeTab={activeTab}
				clearCheckBox={clearCheckBox}
			/>

			<ReportModal
				isOpen={reportModalOpen}
				onClose={() => setReportModalOpen(false)}
				name={"Timesheet"}
				recordId={0}
			/>
			<AddGoalModal
				isOpen={addGoalModalOpen}
				onClose={() => {
					setEditGoalDetails(null);
					setAddGoalModalOpen(false)
				}}
				editGoalDetails={editGoalDetails}
				onDataSave={onDataSave}
			/>

			<div className="page">
				<div className="page rewards-page">
					<div className="header" style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'space-between' }}>
						<div className="page-content">
							<h1>Timesheets</h1>
						</div>
						<div className="page-content">
							<MoreIcon
								className="link"
								color="action"
								onClick={event => setMoreMenuAnchor(event.currentTarget)}
							/>
							<Menu
								open={Boolean(moreMenuAnchor)}
								onClose={() => setMoreMenuAnchor(null)}
								anchorEl={moreMenuAnchor}
								anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
								transformOrigin={{ horizontal: "right", vertical: "top" }}>
								<MenuItem onClick={() => handleAddGoal()}>Add Goal</MenuItem>
								<MenuItem onClick={() => {
									setMoreMenuAnchor(null)
									setShowExportModal(true)
								}}>Export Goals Summary</MenuItem>
								<MenuItem onClick={() => handleReport()} style={{ color: BIRed }}>Report</MenuItem>
							</Menu>
						</div>
					</div>
				</div>

				<div className="page-content" style={{ paddingTop: "20px" }}>
					<div className="resp-container-horizontal">
						<div className="flex-container-vertical" style={getTimeSheetDivStyle()}>
							<div style={{ margin: '15px' }}>
								{
									isTimeSheetsBannerFlag &&
									(
										<>
											<Stack style={{ backgroundColor: '#FFEEBF' }}>
												<Stack direction={'row'} gap={1} justifyContent={'start'} style={{ padding: '20px' }}>
													<Typography>
														Working towards an apprenticeship? Set up a Goal to track the hours from your Timesheets.
													</Typography>
													<IconButton
														style={{ padding: "0px", width: "21px", height: '24px' }}
														onClick={onCloseBannerPressed}
													>
														<CloseIcon />
													</IconButton>
												</Stack>
												<Button style={{ marginBottom: '20px' }} onClick={() => {
													handleAddGoal()
												}}>
													New Goal
												</Button>
											</Stack>
										</>
									)
								}
								{
									TimesheetList && TimesheetList.length > 0 &&
									(
										<>
											<Card style={{ padding: '15px', paddingBottom: '0px' }}>
												<div className="flex-container-horizontal" style={{ justifyContent: 'flex-end' }}>
													<Button variant="contained"
														style={{ background: BIBlue }} onClick={() => onEditGoalClickModal()}>Edit</Button>
												</div>
												{!editGoalModal ?
													<List style={{ padding: 0 }}>
														{TimesheetList.slice(0, 7).map((item, index) => {
															return (
																progressData(item, index)

															)
														})}
														{
															isProgressBarExpanded && TimesheetList.slice(7).map((item, index) => {
																return (
																	progressData(item, index)

																)
															})}
														<Stack direction="row" justifyContent="flex-end" >
															<IconButton onClick={() => {
																setIsProgressBarExpanded(!isProgressBarExpanded)
															}}>
																{isProgressBarExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}

															</IconButton>
														</Stack>
													</List> : <>
														<Typography variant="h6" sx={{ fontWeight: 'bold' }}>Edit Goals</Typography>
														<Button
															onClick={(e) => { onToggleSwitch(e.target.checked) }}
															size="small">
															<FormControlLabel
																control={
																	<Switch
																		color="primary"
																		checked={isHideCompletedGoals ? true : false}
																	/>}
															/>
															<Typography style={{ color: 'black', textTransform: 'capitalize' }}>
																Hide Completed Goals
															</Typography>
														</Button>
														{loadingGoal ? (<div><Loader /></div>) :
															<List style={{ padding: 0 }}>
																{userGoalData.map((item, index) => (
																	<Stack >
																		<Stack direction="row" width="100%" justifyContent="space-between" sx={{ padding: '10px 0px' }}>
																			<Stack>
																				<Typography >
																					{item.name}
																				</Typography>
																			</Stack>
																			<Stack>
																				<IconButton onClick={() => { onEditGoalClick(item) }}>
																					<EditIcon sx={{ fontSize: "20px", color: BIBlue, cursor: 'pointer' }} />
																				</IconButton>
																			</Stack>
																		</Stack>
																	</Stack>

																))}
															</List>}
														<div className="flex-container-horizontal" style={{ justifyContent: 'center' }}>
															<Button
																style={{ color: BIBlue }} onClick={() => handleAddGoal()} >Add Goal</Button></div>

														<div className="flex-container-horizontal" style={{ justifyContent: 'flex-end' }}>
															<Button
																style={{ color: BIBlue }} onClick={() => setEditGoalModal(false)}>Close</Button></div></>
												}
											</Card>
										</>
									)
								}

							</div>
						</div>

						<div className="flex-container-vertical" style={{ flex: 2 }}>
							<div style={{ margin: '15px' }} >
								<div className="flex-container-horizontal" style={{ justifyContent: 'space-between' }}>
									<Tabs
										textColor="primary"
										indicatorColor="primary"
										value={activeTab}
										onChange={handleTabChange}>
										{
											tabsArray.map((tab, index) => (
												<Tab key={index} value={tab} label={tab}
													style={{ backgroundColor: 'white', borderRadius: '5px', marginRight: '20px', }} />
											))
										}

									</Tabs>

									<div className="flex-container-horizontal resp-container-horizontal" >
										{activeTab === "unsent" && <div className="input-container">
											<Button
												variant="contained"
												style={{ background: BIBlue }}
												onClick={() => { setnewTimesheetModalOpen(true); setClickFrom("AddTimesheet") }}>
												Add Timesheet
											</Button>
										</div>}


										<div className="input-container">
											<Button
												variant="contained"
												style={{ background: !sendButtonVisible && BIBlue }}
												disabled={sendButtonVisible}
												onClick={handleSendTimeSheet}>
												Send
											</Button>
										</div>
									</div>
								</div>

								<div style={{ flex: 1 }} >
									{
										(activeTab === "unsent")
											? (
												isUnsentTimeSheetEmpty
													?
													renderPlaceholder()
													: loading ? (<div><Loader /></div>) :
														<TimeSheetListSection
															increaseOffset={increaseOffset}
															setClickFrom={setClickFrom}
															fetchTimesheetsLength={fetchTimesheetsLength}
															getData={getUnsentTimesheet}
															setLoading={setLoading}
															setSendButtonVisible={(resArr) => {
																setSelectedTimeSheets([...resArr]);
																const newCount = resArr?.length > 0 ? false : true;
																setSendButtonVisible(newCount);
															}}
															activeTab={activeTab}
															setResponse={setResponse}
															response={response}
															getEditTimeSheetData={getEditTimeSheetData}
															infiniteScroll={infiniteScroll}
															callUpdatedData={callUpdatedData}
															setCallUpdatedData={setCallUpdatedData}
														/>
											)
											: <></>
									}

									{
										(activeTab === "sent")
											? (
												isSentTimeSheetEmpty
													?
													renderPlaceholder()
													: loading ? (<div><Loader /></div>) :
														<TimeSheetListSection
															increaseOffset={increaseOffset}
															setClickFrom={setClickFrom}
															fetchTimesheetsLength={fetchTimesheetsLength}
															setLoading={setLoading}
															getData={getSentTimesheet}
															activeTab={activeTab}
															setSendButtonDisabled={() => setSendButtonVisible(true)}
															getEditTimeSheetData={getEditTimeSheetData}
															setSendButtonVisible={(resArr) => {
																setSelectedTimeSheets([...resArr]);
																const newCount = resArr?.length > 0 ? false : true;
																setSendButtonVisible(newCount);
															}}
															setResponse={setResponse}
															response={response}
															infiniteScroll={infiniteScroll}
															callUpdatedData={callUpdatedData}
															setCallUpdatedData={setCallUpdatedData}
														/>
											)
											: <></>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				showExportModal &&
				<ExportGoalsSummaryModal
					open={showExportModal}
					onClose={() => {
						setShowExportModal(false);
					}}
					userGoalData={userGoalData ?? []}

				/>
			}
		</>

	)
}

export default TimeSheets;
