import React, { useState } from "react";
import {
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Button,
  Tabs,
  Tab,
} from "@material-ui/core";
import { Stack, Alert, Typography } from "@mui/material/";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PictureViewer from "../PictureViewer";
import { Carousel } from "react-responsive-carousel";
import { BIRed, BILightRed, BIGrey } from "../../assets/buildidColors";
import defaultProjectImage from "../../assets/images/project-profile-placeholder.jpg";
import CheckIcon from "@mui/icons-material/Check";
import Loader from "../Loader";
import ReportModal from "../ReportModal";
import { useEffect } from "react";
import { getDbValue } from "../../api/RestClient";
import CircularProgress from "@mui/material/CircularProgress";
import MemberListModal from "./MemberListModal";
import { getChatGroupDashboard } from "../../api/ChatApi";
import SendInChatModal from "./SendInChatModal";
import defaultTradeImage from "../../assets/images/default-trade-profile.jpg";

/*
Reusable Header component for profile pages
Props (*required):
  images			- array of image URLs to display in Viewer
  *title			- string
  subtitle		- string; grey text that appears under title
  warningText		- string; if not empty string, displays it as a red banner
  members			- array of JSON objects from API
	
  Tabs are optional, but if they are used all 3 of the following are required
  tabs			- array of strings of tab titles
  activeTab		- string; state of the currently selected tab
  setActiveTab	- function; used to change activeTab state value
Usage:
  <ProfileHeader
    title=""
    subtitle=""/> 
*/
export default function ProfileHeader(props) {
  const [viewerOpen, setViewerOpen] = useState(false);
  const [viewerIndex, setViewerIndex] = useState(0);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
  const [reportModalOpenSearch, setreportModalOpenSearch] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [showSendInChatModal, setShowSendInChatModal] = useState(false);

  const handleReport = (id) => {
    setMoreMenuAnchorEl(null);
    setreportModalOpenSearch(true);
  };

  function getShareUrl(db) {
    if (db == 3) {
      return `${process.env.REACT_APP_WEB_USER}/proxysite/${props?.shareDetails?.type}/${props?.shareDetails?.id}`;
    } else if (db == 2) {
      return `${process.env.REACT_APP_WEB_USER}/proxysite/${props?.shareDetails?.type}/${props?.shareDetails?.id}`;
    } else {
      return `${process.env.REACT_APP_WEB_USER}/proxysite/${props?.shareDetails?.type}/${props?.shareDetails?.id}`;
    }
  }

  const onShareClick = async () => {
    await getDbValue();
    if (localStorage?.db) {
      let shareLink = await getShareUrl(localStorage?.db);
      navigator.clipboard
        .writeText(shareLink)
        .then((res) => {
          // alert("Link copied")
        })
        .catch((error) => {
          console.error("Failed to copy: ", error);
        });
    }
  };

  const onSendChatClick = async () => {
    setShowSendInChatModal(true);
  };

  return (
    <Card style={{ width: "100%", marginTop: props?.proxySite && "16px", }}>
      {
        props?.images?.length > 0 ? (
          <div>
            <PictureViewer
              isOpen={viewerOpen}
              onClose={() => setViewerOpen(false)}
              pictures={props?.images?.map((pic) => ({
                src: pic.imageUrl,
                alt: "",
              }))}
              viewerIndex={viewerIndex}
            />

            <Carousel
              showThumbs={false}
              showStatus={false}
              showIndicators={false}>
              {props?.images?.map((picture, index) => (
                <div
                  style={{ height: "300px" }}
                  key={picture.id}
                  onClick={() => {
                    setViewerIndex(index);
                    setViewerOpen(true);
                  }}>
                  <img src={picture.imageUrl} alt="" />
                </div>
              ))}
            </Carousel>
          </div>
        ) : (
          <div style={{ height: "350px", width: "100%" }}>
            {props?.imageLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}>
                <CircularProgress />
              </div>
            ) : (
              <img
                src={props?.imageUrl ?? defaultProjectImage}
                onError={(e) => {
                  e.target.src =
                    props?.subtitle === "Trade"
                      ? defaultTradeImage
                      : defaultProjectImage;
                }}
                onClick={() => {
                  setShowImagePreview(true);
                }}
                style={{ height: "350px", width: "100%", objectFit: "cover" }}
              />
            )}
          </div>
        )
      }

      <CardContent style={{ padding: "10px 10px 0px" }}>
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <div>
              <Typography variant="h5">{props?.title}</Typography>
              <Typography variant="body2" className="greytext">
                {props?.subtitle}
              </Typography>
            </div>

            {props?.proxySite ? (
              <></>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                {props?.warningText === true ||
                  (props?.from !== "Company" &&
                    props?.from !== "Manufacturer") ? (
                  <>
                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "20px" }}>
                        {props?.isFollow ? (
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={props?.handleFollowingClick}>
                            {props?.from === "Project"
                              ? "UnFollow"
                              : "Following"}
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={props?.handleFollowClick}>
                            {props?.btnTitle1}
                          </Button>
                        )}
                      </div>
                      {/* // 20-03-2024 chenges Yash */}
                      <div style={{ marginRight: "20px" }}>
                        {props?.isSiteOnTimeline ? (
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={props?.handleRightIconPressed}>
                            {props?.from === "Project" ? (
                              "ADD TO PROFILE"
                            ) : (
                              <CheckIcon />
                            )}
                          </Button>
                        ) : (
                          // <Button
                          //   color="primary"
                          //   onClick={props?.handleAddToProfileButton}>
                          //   {props?.btnTitle}
                          // </Button>
                          <></>
                        )}
                      </div>
                      <div style={{ marginRight: "20px" }}>
                        {props?.isAddToProfile ? (
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={props?.handleRightIconPressed}>
                            {props?.from === "Site" ? (
                              "NEW PROJECT"
                            ) : (
                              <CheckIcon />
                            )}
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            onClick={props?.handleAddToProfileButton}>
                            {props?.btnTitle}
                          </Button>
                        )}
                      </div>{" "}
                      {/* // 20-03-2024 chenges Yash */}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <IconButton
                  size="small"
                  onClick={(event) => setMoreMenuAnchorEl(event.currentTarget)}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={moreMenuAnchorEl}
                  open={Boolean(moreMenuAnchorEl)}
                  onClose={() => setMoreMenuAnchorEl(null)}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  style={{ top: '50px' }}
                >
                  {props?.menuOptions?.map((m, i) => (
                    <MenuItem
                      onClick={() => {
                        if (m === "Share") {
                          onShareClick();
                          setMoreMenuAnchorEl(null);
                        } else if (m === "Send in Chat") {
                          setMoreMenuAnchorEl(null);
                          onSendChatClick();
                        } else {
                          props?.onMenuOptionClick(m);
                          setMoreMenuAnchorEl(null);
                        }
                      }}
                      style={{ color: "#083DB8" }}
                      key={i}
                      dense>
                      {m}
                    </MenuItem>
                  ))}
                  <MenuItem
                    onClick={() => handleReport(props?.siteId)}
                    style={{ color: BIRed }}
                    dense>
                    Report
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            style={{ paddingTop: '5px' }}>
            <div style={{ width: "100%" }}>
              {/* Members Section and Join Group button
              This section is only shown if props?.members is specified
              However, if props?.warningText is specified (regardless of 
              props?.members), display the red warning banner instead	 */}
              {props.members?.length != 0 && (props?.from !== "Company" && props?.from !== "Manufacturer") ? (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                >
                  {
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Stack
                        sx={{ marginRight: "10px", cursor: "pointer" }}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        onClick={props?.handleMemberListPressed}>
                        {props?.members?.map((member, index) => (
                          <Avatar key={index} src={member.pictureUrl} />
                        ))}
                      </Stack>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <KeyboardArrowRightIcon
                          sx={{ color: BIGrey, cursor: "pointer" }}
                          onClick={props?.handleMemberListPressed}
                        />
                      </div>
                    </div>
                  }




                  {/* {props?.isLoading ? (
                      <>
                        <div
                          style={{
                            height: 50,
                            width: 250,
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <Loader style={{ height: 50, width: 50 }} />
                        </div>
                      </>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <div style={{ marginRight: "20px" }}>
                          {props?.isFollow ? (
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={props?.handleFollowingClick}>
                              {props?.from === "Site" ? "UnFollow" : "Following"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={props?.handleFollowClick}>
                              Follow
                            </Button>
                          )}
                        </div>
                        <div style={{ marginRight: "20px" }}>
                          {props?.isAddToProfile ? (
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={props?.handleRightIconPressed}>
                              {props?.from === "Site" ? "NEW PROJECT" : <CheckIcon />}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              onClick={props?.handleAddToProfileButton}>
                              {props?.btnTitle}
                            </Button>
                          )}
                        </div>
                      </div>
                    )} */}
                </Stack>
              ) : (
                <></>
              )
              }
            </div>
          </Stack>
        </Stack>
      </CardContent>
      {/* {
        // Red warning banner - message specified by props?.warningText
        props?.warningText ? (
          <Alert severity="error" style={{ backgroundColor: BILightRed }}>
            {props?.warningText}
          </Alert>
        ) : (
          <></>
        )
      } */}
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={props?.activeTab}
        onChange={(event, newTab) => props?.setActiveTab(newTab)}>
        {props?.tabs?.map((tab, index) => (
          tab == null ? null :
            <Tab key={index} value={tab} label={tab} />
        ))}
      </Tabs>
      <ReportModal
        name={props?.subtitle}
        isOpen={reportModalOpenSearch}
        onClose={() => setreportModalOpenSearch(false)}
        recordId={props?.shareDetails?.id}
      />

      <PictureViewer
        isOpen={showImagePreview && props?.imageUrl}
        onClose={() => {
          setShowImagePreview(false);
        }}
        src={props?.imageUrl}
      />

      {showSendInChatModal &&
        <SendInChatModal
          isOpen={showSendInChatModal}
          from={props?.from ?? ""}
          title={props?.title}
          imageUrl={props?.imageUrl}
          onClose={() => {
            setShowSendInChatModal(false)
          }}
        />}
    </Card>
  )
}
