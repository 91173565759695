import React from "react";
import StoreIcon from '@mui/icons-material/Store';
import Data from "../Data";
import StyleItem from "../StyleItem";
import "../Taketour.css";

const Rewards = () => {
  const { rewardsData } = Data();

  return (
    <div>
    <StoreIcon style={{ height: "100px", width: "100px" }} className="headicon"/>
      {rewardsData.map((item, index) => (
        <StyleItem key={index} icon={item.icon} data={item} />
        ))}
        <h5 className="headicon">BuildID has ads inside our app to help fund the Rewards Store </h5>
    </div>
  );
};

export default Rewards;
