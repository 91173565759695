import React from 'react'
import { Card, List, ListItem, ListItemButton, ListItemText, } from "@mui/material";
import { Chip } from "@material-ui/core";
const JobListSection = (props) => {
    return (
        <List style={{ padding: '0px' }}>
            {props?.activeJobArray.map((item, index) => {
                console.log("key-----", item)
                return (
                    <div key={index} style={{ marginBottom: "10px" }}>
                        <Card>
                            <ListItem
                                disablePadding>
                                <ListItemButton >
                                    <ListItemText
                                        primary={item?.companyName}
                                        secondary={
                                            <div style={{ marginTop: '5px' }}>
                                                <span style={{ marginRight: "3px" }}>
                                                    <Chip label={item?.tradeName} />{" "}
                                                </span>
                                                <span>
                                                    {item?.jobDuration &&
                                                        item?.jobDurationType ? (
                                                        <Chip
                                                            label={
                                                                item?.jobDuration +
                                                                " " +
                                                                item?.jobDurationType.replace("(s)", "")
                                                            }
                                                        />
                                                    ) : (
                                                        null
                                                    )}
                                                </span>
                                            </div>
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        </Card>
                    </div>
                );
            })
            }
        </List>
    )
}

export default JobListSection