import { restGet, restPost, restDelete } from "./RestClient";
import { form_headers, headers } from "./UserApi";

// let headers = {
// 	"Content-Type": "application/json",
// 	"Accept": "application/json",
// 	"Authorization": ('Bearer ' + localStorage.getItem('token')),
// }

export async function getUserSitePicture(userId) {
	const res = await restGet(`sites/getUserSitePictures/${userId}`, headers);
	return res;
}

export async function getMusicFiles(data) {
	const res = await restGet("misc/getmusicfilesv1", headers);
	return res;
}

export async function saveMontages(data) {
	const res = await restPost("misc/saveMontage", data, headers);
	return res;
}

export async function saveMontagesFile(data) {
	const res = await restPost("misc/saveMontageFile", data, headers);
	return res
}

export async function sendMontagesFile(data) {
	const res = await restPost("Misc/SendMontage", data, headers);
	return res
}

export async function getMontages(uid) {
	const res = await restGet(`misc/getMontagesv1/${uid}`, headers);
	return res;
}

export async function deleteMontageFile(picId, uid) {
	const res = await restDelete(`misc/deleteMontageFile/${picId}/${uid}`, headers);
	return res;
}

export async function deleteMontages(data) {
	const res = await restDelete(`misc/deleteMontage/${data.id}/${data.uid}`, headers);
	return res;
}

export async function saveGalleryPictures(data){	
	const res = await restPost("Misc/SaveSitesMontageFiles", data, form_headers);
	return res;
}

export async function getUserProjectPictures(userId) {
	try {
		const res = await restGet(`Projects/GetUserProjectPictures/${userId}`,headers);
		return res;
	}
	catch (error) {
		throw error;
	}
}
