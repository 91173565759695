import React, { useState, useEffect, useCallback, useRef } from "react";
import { Checkbox, Divider, IconButton, Input } from "@material-ui/core";
import {
  Save as SaveIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import {
  saveUserCompanies,
  saveUserWorkHistory,
  deleteUserWorkHistories,
  fetchUserSitesProjects,
  fetchUserWorkHistory,
  fetchUserCompanies,
} from "../../../api/UserApi";
import { fetchCompanies, saveCompanies2 } from "../../../api/CompanyApi";
import { fetchTrades } from "../../../api/TradesApi";
import { fetchRoles, saveRole } from "../../../api/RolesApi";
import Loader from "../../../components/Loader";
import PromptModal from "../../../components/PromptModal";
import DateSelector from "../../../components/DateSelector";
import AlertModal from "../../../components/AlertModal";
import moment from "moment";
import { ImageList, ImageListItem, Stack, TextField } from "@mui/material";
import { saveUserSitesProjects } from "../../../api/SitesApi";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useActions } from "../../../components/ActionsContext";
import { stringToLocale } from "../../../assets/helpers";
import defaultProjectImage from "../../../assets/images/project-profile-placeholder.jpg";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { BIBlue } from "../../../assets/buildidColors";

const filter = createFilterOptions();

const ProjectForm = (props) => {
  const today = moment();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [siteId, setSiteId] = useState(0);
  // const [userCompanyId,setUsercompanyId] = useState(0);
  const [siteName, setSiteName] = useState("");
  const [siteAddress, setSiteAddress] = useState("");
  const [projectName, setProjectName] = useState("");
  const [companyId, setCompanyId] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [tradeId, setTradeId] = useState(0);
  const [tradeName, setTradeName] = useState("");
  const [roleId, setRoleId] = useState(0);
  const [roleName, setRoleName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isPresent, setIsPresent] = useState(false);
  const [includeInResume, setIncludeInResume] = useState(false);
  const [description, setDescription] = useState("");
  const [companyStartDateNew, setCompanyStartDateNew] = useState("");
  const [companyEndDateNew, setCompanyEndDateNew] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [userSitesProjectsId, setUserSitesProjectsId] = useState(0);
  const [allCompanies, seAllCompanies] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [tradesData, setTradesData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [roleFlag, setRoleFlag] = useState(false);
  const [tradeFlag, setTradeFlag] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [filterRolesData, setfilterRolesData] = useState([]);
  const [filterTradesData, setfilterTradesData] = useState([]);
  const [companyDetail, setCompanyDetail] = useState(null);
  const { getBuilderBucksCount } = useActions();
  const [isSearching, setIsSearching] = useState(false);
  const [modalDateChecker, setModalDateChecker] = useState(null);
  const [modalDescription, setModalDescription] = useState(null);
  const [updateModal, setUpdateModal] = useState(null);
  const [updateModalTitle, setUpdateModalTitle] = useState(null);
  const [updateModalBody, setUpdateModalBody] = useState(null);
  const [prevCompId, setPrevCompId] = useState(0);
  const [prevRoleId, setPrevRoleId] = useState(0);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);

  const navigate = useHistory();

  function removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  const getCompanies = async () => {
    const res = await fetchCompanies({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ manufacturer: false }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = removeDuplicates(res.data, "name");
    setCompanyData(response);
  };

  const getRoles = async () => {
    const res = await fetchRoles({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ manufacturer: false }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res.data, "name");
    setRolesData(response);
  };

  const getTrades = async () => {
    const res = await fetchTrades({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ userId: Number(localStorage.userId) }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res.data, "name");
    setTradesData(response);
  };

  const getFilterCompanies = async (props) => {
    if (props !== "") {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    const res = await fetchCompanies({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ name: props, manufacturer: false }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res.data, "name");
    setFilterData(response);
  };

  const getFilterRoles = async (props) => {
    if (props !== "") {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    const res = await fetchRoles({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ name: props, manufacturer: false }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res.data, "name");
    setfilterRolesData(response);
  };

  const getFilterTrades = async (props) => {
    if (props !== "") {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    const res = await fetchTrades({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ name: props }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res.data, "name");
    setfilterTradesData(response);
  };

  useEffect(() => {
    if (props.isSiteExistForm) {
      setSiteId(props.siteId);
      setSiteName(props.siteName);
      setSiteAddress(props.siteAddress);
      setCompanyId(props.company.companyId);
      setCompanyName(props.company.companyName);
      setTradeId(props.tradeId);
      setTradeName(props.tradeName);
      setRoleId(props.company.roleId);
      setRoleName(props.company.roleName);
      setCompanyDetail({ userCompanyId: props?.company?.userCompanyId });
    } else if (props.isSiteNewForm) {
      setSiteId(props.siteId);
      setSiteName(props.siteName);
      setSiteAddress(props.siteAddress);
      setTradeId(props.tradeId);
      setTradeName(props.tradeName);
    } else if (props.isExperienceCard) {
      setSiteId(props.project?.siteId);
      setSiteName(props.project?.siteName);
      setCompanyId(props.project.companyId);
      setCompanyName(props.project.companyName);
      setTradeId(props.project.tradeId);
      setTradeName(props.project.tradeName);
      setRoleId(props.project.roleId);
      setRoleName(props.project.roleName);
      setProjectName(props.project?.projectName);
      setStartDate(moment(props.project.startDate).format("YYYY-MM-DD"));
      setEndDate(moment(props.project.endDate).format("YYYY-MM-DD"));
      setIsPresent(props.project.present);
      setIncludeInResume(props.project.includeInResume);
      setDescription(props.project.description);
      setCompanyDetail(props.project);
      setPrevCompId(props.project.companyId);
      setPrevRoleId(props.project.roleId);
    }
    getFetchUserCompanies();
  }, []);

  const getFetchUserCompanies = async () => {
    const userCompanyObj = {
      PageNr: 1,
      FullSearch: "",
      IncludeRecordNr: false,
      NrOfRecPerPage: 0,
      SearchList: [{ UserId: Number(localStorage.userId) }],
    };
    const userCompanies = await fetchUserCompanies(userCompanyObj);
    seAllCompanies(userCompanies);
  };

  const getSiteData = async () => {
    try {
      if (props.isExperienceCard) {
        const projectDataObj = {
          PageNr: 1,
          FullSearch: "",
          IncludeRecordNr: "true",
          TypeOfObjectReturned: "",
          SearchList: [
            {
              siteId: props.project?.siteId,
              userId: Number(localStorage.userId),
              userSiteId: props.project?.id,
            },
          ],
        };
        const projectData = await fetchUserSitesProjects(projectDataObj);
        const siteDataObj = {
          PageNr: 1,
          FullSearch: "",
          IncludeRecordNr: false,
          SearchList: [
            { SiteId: props.project?.siteId, Id: props.project?.id },
          ],
        };
        const siteData = await fetchUserWorkHistory(siteDataObj);
        setProjectName(siteData[0]?.projectName || props.project?.projectName);
        setDescription(siteData[0]?.description || props.project?.description);
        setUserSitesProjectsId(projectData[0]?.id || 0);
      }
    } catch (error) {
      console.log("fetchUsersWorkHistoryError:", error);
    }
  };

  useEffect(() => {
    getSiteData();
    getCompanies();
    getRoles();
    getTrades();
  }, []);

  const clearProjectSiteData = async () => {
    setSiteId(0);
    setSiteName("");
    setSiteAddress("");
    setCompanyId(0);
    setCompanyName("");
    setTradeId(0);
    setTradeName("");
    setRoleId(0);
    setRoleName("");
    setProjectName("");
    setStartDate(null);
    setEndDate(null);
    setIsPresent(false);
    setIncludeInResume(false);
    setDescription("");
    setCompanyDetail(null);
    setUserSitesProjectsId(0);
  };

  function handleCancelButtonClick() {
    // If any field has been changed, show popup to confirm discard
    if (props.isSiteExistForm || props.isExperienceCard) {
      if (!handleEditDisable()) {
        return setDiscardModalOpen(true);
      }
    }
    props.onReturnClick();
    clearProjectSiteData();
  }

  function handleDescription(e) {
    setDescription(e.target.value);
  }

  function handleDeleteButtonClick() {
    setIsLoading(true);
    setDeleteModalOpen(false);
    deleteUserWorkHistories({
      UserId: Number(localStorage.userId),
      ReturnRecordId: false,
      ReturnRecordError: true,
      DeleteList: [
        {
          id: props.project.id,
          modifiedBy: Number(localStorage.userId),
        },
      ],
    })
      .then((res) => {
        setIsLoading(false);
        clearProjectSiteData();
        if (props?.onSaveFunction) {
          return props.onProjectSave();
        }
        props.onReturnClick();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  const handleProjectSaveButton = async () => {
    let getRoleId = roleId;
    try {
      if (projectName?.trim() === "") {
        setModalDateChecker("company-name");
        setModalDescription("Please Enter Project Name");
        return;
      } else if (startDate == endDate && startDate != null) {
        setModalDateChecker("startdate-enddate");
        setModalDescription("Please Change End Date or unchecked present.");
        return;
      }
      // else if (startDate == null) {
      //     setModalDateChecker("start-date");
      //     setModalDescription('Please Select Start Date.');
      //     return;

      // }
      // else if (endDate == null && !isPresent) {
      //     setModalDateChecker("start-date");
      //     setModalDescription('Please select End date or checked present.');
      //     return;

      // }
      else if (
        !isPresent &&
        startDate &&
        endDate &&
        moment(startDate).format("YYYY-MM-DD") >
        moment(endDate).format("YYYY-MM-DD")
      ) {
        setModalDateChecker("start");
        setModalDescription(
          "Start Date should be before the End Date, or click I currently work here if you are still working on this site."
        );
        return;
      } else if (isPresent && !startDate) {
        setModalDateChecker("start-date");
        setModalDescription("Please select start date or unchecked present.");
        return;
      }

      if (roleId == 0 && roleName != "") {
        getRoleId = await saveSelectedItem(roleName, "role");
        setRoleId(getRoleId);
      }
      // if (tradeId == 0) await saveSelectedItem(trade, 'trade');
      // if (tradeId != -1 && tradeId != 0 && !props.allTrades.some(t => t.tradeId == tradeId)) (tradeId);
      if (companyId == 0 && companyName != "") {
        let getCompanyId = await saveSelectedItem(companyName, "company");
        await formatTimeframe(getRoleId, getCompanyId);
      } else {
        if (
          companyId != -1 &&
          companyId != 0 &&
          !allCompanies.some((comp) => comp?.companyId == companyId)
        ) {
          await saveCompanies(getRoleId, companyId);
        } else {
          await formatTimeframe(getRoleId, companyId);
        }
      }
    } catch (err) {
      console.log("Error-----------", err);
    }
  };

  const formatTimeframe = async (getRoleId, companyId) => {
    let isProjectAdd = true;
    let isProjectOutOfFrame = false;
    const comp = allCompanies
      .filter((comp) => comp.id == companyDetail?.userCompanyId)
      ?.pop();
    if (comp) {
      let companyStartDate = comp.startDate
        ? moment(comp?.startDate).format("YYYY-MM-DD")
        : null;
      let companyEndDate = comp.endDate
        ? moment(comp?.endDate).format("YYYY-MM-DD")
        : null;
      let newStartDate = startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : null;
      let newEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
      if (newStartDate && newEndDate && companyStartDate && companyEndDate) {
        if (newStartDate >= companyStartDate && newEndDate <= companyEndDate) {
          // Add project, no company update
          isProjectAdd = true;
        } else if (
          newStartDate < companyStartDate &&
          newEndDate > companyEndDate
        ) {
          // Add project, update company, show out of frame dialog, CSD = PSD, CED = PED
          companyStartDate = newStartDate;
          companyEndDate = newEndDate;
          isProjectOutOfFrame = true;
        } else if (newEndDate < companyStartDate) {
          // no project/company update
          isProjectAdd = false;
        } else if (newStartDate > companyEndDate) {
          // no project/company update
          isProjectAdd = false;
        }
      }
      if (!companyStartDate && !companyEndDate && newStartDate) {
        companyStartDate = newStartDate;
        companyEndDate = newEndDate;
        setCompanyData(comp);
        setCompanyStartDateNew(companyStartDate);
        setCompanyEndDateNew(companyEndDate);
        let obj = {
          data: comp,
          companyStartDateNew: companyStartDate,
          companyEndDateNew: companyEndDate,
          currentCompany: isPresent,
        };
        onUpdatePressed(obj);
        return;
      } else if (
        companyStartDate &&
        !companyEndDate &&
        newStartDate < companyStartDate &&
        !newEndDate
      ) {
        // update company, show out of frame dialog, CSD = PSD
        isProjectOutOfFrame = true;
        companyStartDate = newStartDate;
      } else if (
        newEndDate > companyEndDate &&
        newStartDate >= companyStartDate &&
        newStartDate <= companyEndDate
      ) {
        // update company, show out of frame dialog, CED = PED
        isProjectOutOfFrame = true;
        companyEndDate = newEndDate;
      } else if (
        companyStartDate &&
        !companyEndDate &&
        newStartDate < companyStartDate &&
        newEndDate
      ) {
        // update company, show out of frame dialog, CSD = PSD, CED = PED
        isProjectOutOfFrame = true;
        companyStartDate = newStartDate;
        companyEndDate = newEndDate;
      } else if (
        companyStartDate &&
        !companyEndDate &&
        newStartDate > companyStartDate &&
        !newEndDate
      ) {
        // update company, show out of frame dialog,  CED = PSD
        isProjectOutOfFrame = true;
        companyEndDate = newStartDate;
      } else if (
        companyStartDate &&
        !companyEndDate &&
        newStartDate > companyStartDate &&
        newEndDate
      ) {
        // update company, show out of frame dialog,  CED = PED
        isProjectOutOfFrame = true;
        companyEndDate = newEndDate;
      } else if (
        newStartDate < companyStartDate &&
        (!newEndDate ||
          (newEndDate >= companyStartDate && newEndDate <= companyEndDate))
      ) {
        // update company, show out of frame dialog, CSD = PSD
        isProjectOutOfFrame = true;
        companyStartDate = newStartDate;
      } else if (
        !newEndDate &&
        newStartDate >= companyStartDate &&
        newStartDate <= companyEndDate
      ) {
        // Add Project, no company update
        isProjectAdd = true;
      } else if (!newStartDate && newEndDate) {
        setModalDateChecker("start-date");
        setModalDescription("Please select start date ");
        return;
      }
      if (isProjectOutOfFrame) {
        setCompanyData(comp);
        setCompanyStartDateNew(companyStartDate);
        setCompanyEndDateNew(companyEndDate);
        setUpdateModal("isProjectOutOfFrame");
        setUpdateModalBody(
          `This Project’s timeframe is outside of your time at ${companyName}. Would you like to update the timeframe?`
        );
        return;
      }
      if (!isProjectAdd) {
        setModalDateChecker("start-date");
        setModalDescription(
          `This Project's timeframe is outside of ${companyName}'s timeframe. Please, select correct timeframe.`
        );
        return;
      }
      await saveWorkHistory(companyDetail?.userCompanyId, getRoleId, companyId);
    } else {
      try {
        if (companyId > 0) {
          const companyObj = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                Id: 0,
                UserId: Number(localStorage.userId),
                RoleId: getRoleId,
                CompanyId: companyId,
                StartDate: startDate
                  ? moment(startDate).format("YYYY-MM-DD")
                  : "",
                EndDate: isPresent
                  ? null
                  : endDate
                    ? moment(endDate).format("YYYY-MM-DD")
                    : "",
                ModifiedBy: Number(localStorage.userId),
                CurrentCompany: isPresent,
                IncludeInResume: false,
              },
            ],
          };
          const userCompaniesResponse = await saveUserCompanies(companyObj);
          if (userCompaniesResponse[0].objData.id != "") {
            await saveWorkHistory(
              userCompaniesResponse[0].objData.id,
              getRoleId,
              companyId
            );
          }
        } else {
          await saveWorkHistory(0, getRoleId, 0);
        }
      } catch (error) {
        console.log("SaveUserCompaniesError1:", error);
      }
    }
  };

  const onUpdatePressed = async (params) => {
    try {
      const companyObj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: params?.data?.id,
            UserId: Number(localStorage.userId),
            RoleId: params?.data?.roleId,
            CompanyId: params?.data?.companyId,
            StartDate: companyStartDateNew
              ? moment(companyStartDateNew).format("YYYY-MM-DD")
              : null,
            endDate: isPresent
              ? null
              : companyEndDateNew
                ? moment(companyEndDateNew).format("YYYY-MM-DD")
                : null,
            ModifiedBy: Number(localStorage.userId),
            CurrentCompany: isPresent
              ? params.currentCompany
              : params?.data?.currentCompany,
            IncludeInResume: params?.data?.includeResume
              ? params?.data?.includeResume
              : false,
            Description: params?.data?.description,
          },
        ],
      };
      const userCompaniesResponse = await saveUserCompanies(companyObj);
      if (userCompaniesResponse[0].objData.id != "") {
        await saveWorkHistory(
          userCompaniesResponse[0].objData.id,
          roleId,
          params?.data?.companyId
        );
      }
    } catch (error) {
      console.log("SaveCompaniesError2:" + error);
    }
  };

  const saveWorkHistory = async (userCompanyId, getRoleId, companyId) => {
    setIsLoading(true);
    try {
      const obj = {
        Id: companyDetail?.id ? companyDetail?.id : 0,
        UserId: Number(localStorage.userId),
        SiteId: siteId,
        StartDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        endDate: isPresent
          ? null
          : endDate
            ? moment(endDate).format("YYYY-MM-DD")
            : null,
        TradeId: tradeId != -1 ? tradeId : 0,
        CompanyId: companyId != -1 ? companyId : 0,
        RoleId: getRoleId,
        ModifiedBy: Number(localStorage.userId),
        IncludeInResume: includeInResume,
        Present: isPresent,
        UserCompanyId: userCompanyId ?? 0,
      };
      const workHistoryResponse = await saveUserWorkHistory(obj);
      const siteObj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: userSitesProjectsId,
            UserId: Number(localStorage.userId),
            SiteId: siteId,
            projectName: projectName,
            Description: description,
            RecordStatusId: 1,
            ModifiedBy: Number(localStorage.userId),
            UserSiteId: Number(workHistoryResponse) ?? 0,
            // UserSiteId: 0,
            ProductList: [],
          },
        ],
      };
      await saveUserSitesProjects(siteObj);
      // await getBuilderBucksCount()
      await clearProjectSiteData();
      setIsLoading(false);
      if (props?.goToSite) {
        return navigate.push(`/sites/site/${siteId ?? 0}`);
      }
      if (props?.goToProfile) {
        return navigate.push(`/profile`);
      }
      if (props?.onSaveFunction) {
        return props.onProjectSave();
      }
      props.onReturnClick();
    } catch (error) {
      console.log("SaveWorkHistoryError:", error);
    }
  };

  const saveSelectedItem = async (selectedItem, option) => {
    try {
      // if (option == 'trade') {
      //     const tradeObj = {
      //         UserId: 1,
      //         ReturnRecordId: true,
      //         ReturnRecordError: true,
      //         SaveList: [{ Id: 0, Name: selectedItem, Description: "", ModifiedBy: 1 }]
      //     }
      //     const tradeId = await SiteApi.(tradeObj);
      //     this.setState({ trade: selectedItem, tradeId });
      // }
      const roleObj = {
        Id: 0,
        Name: selectedItem,
        CreatedBy: localStorage.userId,
        DateCreated: moment().format(),
        ModifiedBy: localStorage.userId,
        DateModified: moment().format(),
      };
      if (option == "company") {
        const mainObj = {
          UserId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
        };
        const saveObject = {
          ...mainObj,
          SaveList: [
            {
              Id: 0,
              Name: selectedItem,
              ModifiedBy: Number(localStorage.userId),
              RecordStatusId: 1,
              Manufacturer: false,
            },
          ],
        };
        const res = await saveCompanies2(saveObject);
        setCompanyName(selectedItem);
        return res[0]?.objData?.id;
      } else {
        const roleId = await saveRole(roleObj);
        setRoleName(selectedItem);
        return roleId;
      }
    } catch (err) {
      console.log("ErrorSaveSelectedItem:", err);
    }
  };

  const saveCompanies = async (getRoleId, companyId) => {
    try {
      const companyObj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            UserId: Number(localStorage.userId),
            RoleId: getRoleId,
            CompanyId: companyId,
            StartDate: startDate
              ? moment(startDate).format("YYYY-MM-DD")
              : null,
            endDate: isPresent
              ? null
              : endDate != ""
                ? moment(endDate).format("YYYY-MM-DD")
                : null,
            ModifiedBy: Number(localStorage.userId),
            CurrentCompany: isPresent,
            IncludeInResume: includeInResume,
          },
        ],
      };
      const userCompaniesResponse = await saveUserCompanies(companyObj);
      const companies = await getCompanies();
      seAllCompanies(companies);
      if (userCompaniesResponse[0].objData.id != "") {
        saveWorkHistory(
          userCompaniesResponse[0].objData.id,
          getRoleId,
          companyId
        );
      }
    } catch (error) {
      console.log("SaveCompaniesError:", error);
    }
  };

  const onPresentChange = () => {
    setIsPresent(!isPresent);
    setEndDate(null);
  };

  const handleStartDateChange = (date) => {
    let getDate = moment(date).startOf("month");
    const newDate = moment(getDate).format("YYYY-MM-DD");
    setStartDate(newDate);
  };

  const handleEndDateChange = (date) => {
    let getDate = moment(date).startOf("month");
    const newDate = moment(getDate).format("YYYY-MM-DD");
    setEndDate(newDate);
  };

  const handleClearStartDate = (e) => {
    e.stopPropagation();
    setStartDate(null);
    setOpenDatePicker(false); // Close the date picker when clearing the date
  };

  const handleClearEndDate = (e) => {
    e.stopPropagation();
    setEndDate(null);
    setOpenEndDatePicker(false); // Close the date picker when clearing the date
  };

  const handleAddDisable = () => {
    return projectName?.trim() === "";
    // return projectName?.trim() === "" || !startDate || (!isPresent && endDate == null)
  };

  const handleEditDisable = () => {
    if (projectName?.trim() === "") return true;
    // if (projectName?.trim() === "" || !startDate) return true;
    // if (!isPresent && endDate == null) return true;
    // if (props?.isExperienceCard) {
    //     return (
    //         companyName?.trim() === props?.project?.companyName?.trim() &&
    //         tradeName?.trim() === props?.project?.tradeName?.trim() &&
    //         roleName?.trim() === props?.project?.roleName?.trim() &&
    //         projectName?.trim() === props?.project?.projectName?.trim() &&
    //         description?.trim() === props?.project?.description?.trim() &&
    //         moment(props?.project?.startDate).format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD') &&
    //         moment(props?.project?.endDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD')
    //     )
    // }
  };
  const openFilepicker = (id) => {
    document.getElementById(id).click();
  };
  return (
    <>
      <PromptModal
        isOpen={discardModalOpen}
        onClose={() => {
          setDiscardModalOpen(false);
        }}
        title="Are you sure you want to leave?"
        desc="Any unsaved changes will be discarded."
        redButtonText="Discard"
        blueButtonTextFirst="Go Back"
        onRedButtonClick={props.onReturnClick}
      />

      <PromptModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title="Are you sure you want to delete this project?"
        desc="This action cannot be undone."
        redButtonText="Delete"
        blueButtonTextFirst="Go Back"
        onRedButtonClick={handleDeleteButtonClick}
      />

      <PromptModal
        isOpen={updateModal !== null}
        onClose={() => {
          setUpdateModal(null);
          setUpdateModalTitle(null);
          setUpdateModalBody(null);
          clearProjectSiteData();
        }}
        title={updateModalTitle}
        desc={updateModalBody}
        redButtonText="Cancel"
        // onRedButtonClick={props.onReturnClick}
        onRedButtonClick={() => {
          setUpdateModal(null);
          setUpdateModalTitle(null);
          setUpdateModalBody(null);
        }}
        blueButtonText="Update"
        onBlueButtonClick={async () => {
          let comp = allCompanies
            .filter((comp) => comp.id == companyDetail?.userCompanyId)
            ?.pop();
          let obj = {
            data: comp,
            companyStartDateNew: companyStartDateNew,
            companyEndDateNew: companyEndDateNew,
            currentCompany: isPresent,
          };
          onUpdatePressed(obj);
          setUpdateModal(null);
          setUpdateModalTitle(null);
          setUpdateModalBody(null);
          clearProjectSiteData();
          props.onReturnClick();
        }}
      />

      <AlertModal
        isOpen={modalDateChecker !== null}
        onClose={() => {
          setModalDateChecker(null);
          setModalDescription(null);
        }}
        body={modalDescription}
        expCloseIcon={true}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex-container-vertical">
            <div
              className="flex-container-horizontal"
              style={{ justifyContent: "space-between" }}>
              <div>
                <IconButton color="primary" onClick={handleCancelButtonClick}>
                  <CloseIcon />
                </IconButton>
              </div>

              <div>
                <IconButton
                  color="primary"
                  onClick={handleProjectSaveButton}
                  disabled={
                    props.project ? handleEditDisable() : handleAddDisable()
                  }>
                  <SaveIcon />
                </IconButton>
                {props.project ? (
                  <IconButton
                    color="secondary"
                    onClick={() => setDeleteModalOpen(true)}>
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="flex-container-vertical" style={{ flex: 1 }}>
              <h2 style={{ paddingLeft: "10px" }}>
                {props.project ? "Edit Project" : "New Project"}
              </h2>
              {/* <div className="input-container">
                                        <TextField
                                            id="project-textfield"
                                            label="Site"
                                            variant="filled"
                                            fullWidth
                                            value={`${siteName} ${siteAddress}`}
                                            disabled />
                                    </div> */}
              {/* //! for 3.9 version update */}
              {/* <ImageList
                sx={{ width: "auto", height: "auto" }}
                cols={5}
                rowHeight={164}
                className="sitepic-list">
                <ImageListItem className="sitepic-item">
                  <div
                    className="add-picture addpic-inimagelist"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "5px",
                      height: "120px",
                      width: "720px",
                      alignItems: "center",
                      marginBottom: "5px",
                      gap: "5px",
                      cursor: "pointer",
                      background: "#e4e4e4",
                    }}
                    onClick={() => openFilepicker("multiplesite-pic")}>
                    <Input
                      type="file"
                      id="multiplesite-pic"
                      style={{ display: "none" }}
                      onChange={(e) => {}}
                    />
                    <InsertPhotoIcon style={{ height: "18px" }} />
                    <span className="addpicture-text">Add pictures</span>
                  </div>
                </ImageListItem>
              </ImageList>
              <TextField
                id="project-name-textfield"
                value={siteName}
                fullWidth
                onChange={(e) => setProjectName(e.target.value)}
                readOnly
              />
              <span style={{ fontSize: "0.800rem", color: "#8D8D8D" }}>
                {props?.project?.address ?? siteAddress}
              </span>
              <span
                style={{
                  textAlign: "center",
                  color: BIBlue,
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
                onClick={() => {}}>
                CHOOSE SITE
              </span> */}
              {/* //! for 3.9 version update */}
              <Stack direction={"column"} marginBottom={"10px"}>
                <h5
                  style={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}>
                  Site/Project
                </h5>
                <Stack direction={"row"} gap={1}>
                  {console.log(
                    "props?.project?.mainPictureUrl ===>",
                    props?.project?.mainPictureUrl
                  )}
                  <img
                    style={{
                      height: "70px",
                      width: "70px",
                      border: "1px solid #CCCCCC",
                    }}
                    src={
                      props?.project?.mainPictureUrl !== "" &&
                        props?.project?.mainPictureUrl
                        ? props?.project?.mainPictureUrl
                        : defaultProjectImage
                    }
                  />
                  <div>
                    <h4
                      style={{
                        fontSize: "1rem",
                        fontWeight: "500",
                        marginBottom: "0px",
                      }}>
                      {siteName}
                    </h4>
                    <span style={{ fontSize: "0.800rem", color: "#8D8D8D" }}>
                      {props?.project?.address ?? siteAddress}
                    </span>
                  </div>
                </Stack>
              </Stack>
              <Divider
                style={{
                  margin: "1rem 0px",
                  height: "0.9px",
                  backgroundColor: "#cccccc73",
                }}></Divider>
              <p style={{ paddingLeft: "5px" }}>My Information</p>
            </div>

            <div className="flex-container-horizontal" style={{ flex: 1 }}>
              <div className="flex-container-vertical" style={{ flex: 1 }}>
                <div className="input-container">
                  <TextField
                    id="project-name-textfield"
                    label="Project Name * (required)"
                    value={projectName}
                    fullWidth
                    onChange={(e) => setProjectName(e.target.value)}
                  />
                </div>

                <div className="input-container">
                  <Autocomplete
                    disabled={
                      props.isExperienceCard && prevCompId != 0 ? true : false
                    }
                    inputValue={companyName}
                    onChange={(event, newValue) => {
                      if (newValue && newValue.inputValue) {
                        setCompanyName(newValue?.inputValue);
                        setCompanyId(0);
                        setFlag(false);
                      } else {
                        setCompanyId(newValue?.id);
                        setFlag(false);
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      setCompanyName(newInputValue);
                      setCompanyId(0);
                      setFlag(true);
                      if (newInputValue !== "") {
                        getFilterCompanies(newInputValue);
                      } else {
                        getCompanies();
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;

                      const isExisting = options.some(
                        (option) => inputValue === option.title
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    options={isSearching ? filterData : companyData}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.title;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.title}</li>
                    )}
                    style={{ flex: 1 }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Company"
                        variant={
                          props.isExperienceCard && prevCompId != 0
                            ? "filled"
                            : "outlined"
                        }
                      />
                    )}
                    open={companyName?.length > 1 && flag}
                  />
                </div>

                <div className="input-container">
                  <Autocomplete
                    disabled={
                      props.isExperienceCard && prevRoleId != 0 ? true : false
                    }
                    inputValue={roleName}
                    onChange={(event, newValue) => {
                      if (newValue && newValue.inputValue) {
                        setRoleName(newValue?.inputValue);
                        setRoleId(0);
                        setRoleFlag(false);
                      } else {
                        setRoleId(newValue?.id);
                        setRoleFlag(false);
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      setRoleName(newInputValue);
                      setRoleFlag(true);
                      setRoleId(0);
                      if (newInputValue !== "") {
                        getFilterRoles(newInputValue);
                      } else {
                        getRoles();
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;

                      const isExisting = options.some(
                        (option) => inputValue === option.title
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    options={isSearching ? filterRolesData : rolesData}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.title;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.title}</li>
                    )}
                    style={{ flex: 1 }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Title/Role"
                        variant={
                          props.isExperienceCard && prevRoleId
                            ? "filled"
                            : "outlined"
                        }
                      />
                    )}
                    open={roleName?.length > 1 && roleFlag}
                  />
                </div>

                <div className="input-container">
                  <Autocomplete
                    inputValue={tradeName}
                    onChange={(event, newValue) => {
                      if (newValue && newValue.inputValue) {
                        setTradeName(newValue?.inputValue);
                        setTradeId(0);
                        setTradeFlag(false);
                      } else {
                        setTradeId(newValue?.id);
                        setTradeFlag(false);
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      setTradeName(newInputValue);
                      setTradeFlag(true);
                      setTradeId(0);
                      if (newInputValue !== "") {
                        getFilterTrades(newInputValue);
                      } else {
                        getTrades();
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;

                      const isExisting = options.some(
                        (option) => inputValue === option.title
                      );
                      if (inputValue !== "" && !isExisting && isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    options={isSearching ? filterTradesData : tradesData}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.title;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.title}</li>
                    )}
                    style={{ flex: 1 }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} label="Trade" />
                    )}
                    open={tradeName?.length > 1 && tradeFlag}
                  />
                </div>
              </div>
              <div className="flex-container-vertical" style={{ flex: 1 }}>
                <div className="input-container">
                  <div
                    className="flex-container-vertical"
                    style={{ alignItems: "stretch" }}>
                    <KeyboardDatePicker
                      autoOk
                      open={openDatePicker} // controlled open state
                      onClose={() => setOpenDatePicker(false)} // close date picker on date selection
                      variant="inline"
                      inputVariant="outlined"
                      label="Start Date"
                      placeholder="Month-Year"
                      value={
                        startDate === null || startDate === "Invalid date"
                          ? null
                          : startDate
                      }
                      // value={startDate === null || startDate === "Invalid date" ? null : stringToLocale(startDate)}
                      views={["year", "month"]}
                      InputAdornmentProps={{ position: "start" }}
                      onChange={(date) => handleStartDateChange(date)}
                      maxDate={moment(new Date()).format("YYYY-MM")}
                      InputProps={{
                        readOnly: true, // disable text field editing
                        onClick: () => setOpenDatePicker(true), // open date picker on text field click
                        endAdornment:
                          startDate !== "Invalid date" ? (
                            startDate != null ? (
                              <IconButton
                                style={{ order: 1 }}
                                onClick={(e) => handleClearStartDate(e)}>
                                <CloseIcon />
                              </IconButton>
                            ) : null
                          ) : null,
                      }}
                    />
                  </div>
                </div>
                <div className="input-container">
                  <div
                    className="flex-container-vertical"
                    style={{ alignItems: "stretch" }}>
                    <KeyboardDatePicker
                      autoOk
                      open={openEndDatePicker}
                      onClose={() => setOpenEndDatePicker(false)}
                      variant="inline"
                      inputVariant={isPresent ? "filled" : "outlined"}
                      label="End Date"
                      placeholder="Month-Year"
                      // format={!isPresent ? "YYYY-MM-DD" : ""}
                      value={
                        endDate === null || endDate === "Invalid date"
                          ? null
                          : !isPresent
                            ? endDate
                            : null
                      }
                      // value={endDate === null || endDate === "Invalid date" ? null : !isPresent ? stringToLocale(endDate) : null}
                      views={["year", "month"]}
                      disabled={isPresent}
                      InputAdornmentProps={{ position: "start" }}
                      onChange={(date) => handleEndDateChange(date)}
                      maxDate={today.format("YYYY-MM-DD")}
                      InputProps={{
                        // Only show "X" button if theres a date to clear
                        readOnly: true,
                        onClick: () => setOpenEndDatePicker(true),
                        endAdornment:
                          endDate !== "Invalid date" ? (
                            !isPresent && endDate != null ? (
                              <IconButton
                                style={{ order: 1 }}
                                onClick={(e) => handleClearEndDate(e)}>
                                <CloseIcon />
                              </IconButton>
                            ) : null
                          ) : null,
                      }}
                    />
                  </div>
                </div>

                <div>
                  <Checkbox
                    color="primary"
                    checked={isPresent}
                    onChange={() => onPresentChange()}
                  />
                  <span style={props.project ? { color: "grey" } : {}}>
                    Present
                  </span>
                </div>
                <div style={{ marginLeft: "5px" }}>
                  <TextField
                    fullWidth
                    rows={3}
                    multiline
                    label="Description"
                    type="text"
                    value={description}
                    onChange={(e) => handleDescription(e)}
                  />
                </div>
              </div>
            </div>
            <p style={{ color: "GrayText", padding: "10px 5px 0px 5px" }}>
              You can update this information from the My Sites tab or on your
              Profile
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default ProjectForm;
