import React from "react";
import {
  Modal,
  Stack,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  CardActions,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { BIBlue } from "../../assets/buildidColors";
import { Edit as EditIcon } from "@material-ui/icons";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import '../../assets/css/Carpool.css'


const CarpoolTutorial = (props) => {
  return (
    <>
      <Modal
        open={props?.tutorialIndex == 0 ? false : true}
        onClose={props.onClose}
        style={{
          backgroundColor: "rbga(0,0,0,0.1)",
        }}>
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", height: "100%" }}
          onClick={props.onPress}>
          {props.tutorialIndex === 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}>
              <Stack sx={{ textAlign: "center", color: "white" }}>
                <Typography variant="h6">Carpool</Typography>
                <Typography variant="subtitle1">
                  An easy way to find a ride with others going to the same place
                </Typography>
                <Typography variant="body1">
                  <span style={{ color: BIBlue }}>
                    {" "}
                    (Tap anywhere to continue)
                  </span>
                </Typography>
              </Stack>
            </div>
          )}

          {props.tutorialIndex === 2 && (
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                height: "100%",
              }}>
              <div className="carpool-tutorial-2-body" >
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className="carpool-tutorial-2-button">
                  <FormControlLabel
                    control={<Switch checked={true} />}
                    label="Interested in carpool"
                  />
                </Button>
                <div
                  style={{
                    marginLeft: "67.5%",
                    color: "white",
                    marginTop: "10px",
                  }}>
                  <ArrowUpwardIcon style={{ fontSize: "40px" }} />
                  <div>
                    To have full to Carpool, first toggle switch to interested
                  </div>
                </div>
              </div>
            </div>
          )}

          {props.tutorialIndex === 3 && (
            <div
            className="carpool-tutorial-3-body">
              <div>
                <Card sx={{ width: 475, marginTop:'-130px' }}>
                  <CardContent>
                    <h2>My Address</h2>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <div>
                        <HomeIcon style={{ color: "blue" }} />
                      </div>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        1600 Pennsylvania Avenue Northwest <br />
                        Washington, District of Columbia
                        <br />
                        20500, United States
                      </Typography>
                      <div>
                        <EditIcon style={{ color: "blue" }} />
                      </div>
                    </div>
                    <CardActions>
                      <TextField label="(no Site)" focused fullWidth />
                    </CardActions>
                  </CardContent>
                </Card>
                <Stack
                  direction="row"
                  justifyContent={"center"}
                  sx={{ marginTop: "10px", color: "white" }}>
                  <ArrowUpwardIcon style={{ fontSize: "40px" }} />
                </Stack>
                <div>
                  <Typography variant="subtitle1" style={{ color: "white" }}>
                    Next, set your home address as well as the site you working
                    at. <br />
                    Note: if you dont't have a project/site on your profile,
                    Carpool will not work
                  </Typography>
                </div>
              </div>
            </div>
          )}

          {props.tutorialIndex === 4 && (
            <div
            className="carpool-tutorial-4-body">
              <div>
                <h4 style={{ color: "#fff", padding: "10px" }}>
                  If there is anybody within 5km going to the same site, <br />{" "}
                  they will show on the list for you to message
                </h4>
              </div>
              <ArrowDownwardRoundedIcon
                style={{ color: "white", marginLeft: "10%" }}
              />
              <div>
                <h4 style={{ color: "#fff", padding: "15px" }}>Contacts</h4>
              </div>
              <div>
                <Card
                  style={{
                    background: "#d8d8d8",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    width: "60%",
                  }}>
                  <div style={{ display: "flex" }}>
                    <div>
                      <AccountCircleRoundedIcon
                        style={{
                          width: "50px",
                          height: "50px",
                          marginTop: "10px",
                        }}
                      />
                    </div>
                    <div>
                      John Smith
                      <br /> Boilermaker
                      <br />
                      1.2 Km away
                    </div>
                  </div>
                  <div>
                    <EmailIcon style={{ color: "blue" }} />
                  </div>
                </Card>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}>
                <Card
                  style={{
                    background: "#d8d8d8",
                    marginTop: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    width: "60%",
                  }}>
                  <div style={{ display: "flex" }}>
                    <div>
                      <AccountCircleRoundedIcon
                        style={{
                          width: "50px",
                          height: "50px",
                          marginTop: "10px",
                        }}
                      />
                    </div>
                    <div>
                      Jane Dae
                      <br /> Bricklayer
                      <br />
                      2.3 Km away
                    </div>
                  </div>
                  <div>
                    <EmailIcon style={{ color: "blue" }} />
                  </div>
                </Card>
              </div>
              <div>
                <h4 style={{ color: "#fff", padding: "15px" }}>Others</h4>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Card
                    style={{
                      background: "#d8d8d8",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      width: "60%",
                    }}>
                    <div style={{ display: "flex" }}>
                      <div>
                        <AccountCircleRoundedIcon
                          style={{
                            width: "50px",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        />
                      </div>
                      <div>
                        Alice A.
                        <br />
                        CabinetMaker
                        <br />
                        2.3 Km away
                      </div>
                    </div>
                    <div>
                      <EmailIcon style={{ color: "blue" }} />
                    </div>
                  </Card>
                </div>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Card
                    style={{
                      background: "#d8d8d8",
                      marginTop: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      width: "60%",
                    }}>
                    <div style={{ display: "flex" }}>
                      <div>
                        <AccountCircleRoundedIcon
                          style={{
                            width: "50px",
                            height: "50px",
                            marginTop: "10px",
                          }}
                        />
                      </div>
                      <div>
                        Bob B.
                        <br /> Carpenter
                        <br />
                        4.4 Km away
                      </div>
                    </div>
                    <div>
                      <EmailIcon style={{ color: "blue" }} />
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default CarpoolTutorial;
