import { restGet, restPost } from "./RestClient";
import { headers } from "./UserApi";

// let headers = {
//     "Content-Type": "application/json",
//     "Accept": "application/json",
//     "Authorization": ('Bearer ' + localStorage.getItem('token')),
// }



export async function fetchUserAuth(data) {
    const res = await restPost("users/FetchUserAuth3", data, headers);
    return res;
}

export async function getPeopleInterestedInRideShare(userId,siteId) {
    const res = await restGet("users/GetPeopleInterestedInRideShare/" + userId + "/" + siteId, headers);
    return res;
}

export async function changeRideShareForUser(data) {
    const res = await restPost("users/ChangeRideShareForUser", data, headers);
    return res;
}