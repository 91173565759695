import React from 'react'
import { Modal, Stack, Typography, Paper, IconButton, TextField, Button, Card, InputAdornment, CardContent, CardActions } from '@mui/material'
import { BIBlue, BIWhite } from '../../assets/buildidColors'
import SouthIcon from '@mui/icons-material/South';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import mapMarkerBlue from "../../assets/images/map-marker-blue.png";
import AddIcon from '@mui/icons-material/Add';
import {
    Search as SearchIcon,
} from "@material-ui/icons";
import { MoreVert as MoreIcon } from "@mui/icons-material";


const SitesTutorial = (props) => {

    const circleOuterStyle = {
        width: 40,
        height: 40,
        borderRadius: '50%',
        backgroundColor: '#ffde89',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const circleInnerStyle = {
        width: 30,
        height: 30,
        borderRadius: '50%',
        backgroundColor: '#FFBC0A',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <>
            <Modal
                open={props?.tutorialIndex == 0 ? false : true}
                onClose={props.onClose}
                style={{
                    backgroundColor: 'rbga(0,0,0,0.1)'
                }}
            >
                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', height: '100%' }} onClick={props.onPress}>
                    {
                        props.tutorialIndex === 1 && <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <Stack sx={{ textAlign: 'center', color: 'white' }}>
                                <Typography variant="h6">
                                    Map
                                </Typography>
                                <Typography variant="subtitle1">
                                    Shows you where sites are located
                                </Typography>
                                <Typography variant="body1" >
                                    <span style={{ color: BIBlue }}> (Tap anywhere to continue)</span>
                                </Typography>
                            </Stack>
                        </div>
                    }


                    {
                        props.tutorialIndex === 2 &&
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '100%',
                            }}
                        >
                            <Stack className='site-tutorial-2-body'>
                                <Typography variant="subtitle1">
                                    Numbered Clusters are groupings of multiple sites.
                                    <div>
                                        Individual sites are displayed with an ID Tag.
                                    </div>
                                    Tap on either to get more information, follow a site, or add a site to your profile.
                                </Typography>
                                <Typography variant="body1" >
                                    <span style={{ color: BIBlue }}> (Tap anywhere to continue)</span>
                                </Typography>

                                <Stack direction="row" spacing={10} justifyContent="center" sx={{ marginTop: "20px" }}>
                                    <Typography>
                                        <Typography>
                                            <SouthIcon style={{ fontSize: '40px' }} />
                                        </Typography>
                                        <Typography>
                                            <Paper style={circleOuterStyle}>
                                                <Paper style={circleInnerStyle}
                                                >
                                                    <Typography variant="h4" style={{ color: 'white', fontSize: '18px' }}>
                                                        4
                                                    </Typography>
                                                </Paper>
                                            </Paper>
                                        </Typography>
                                    </Typography>
                                    <Typography>
                                        <Typography>
                                            <SouthEastIcon style={{ fontSize: '40px' }} />
                                        </Typography>
                                        <Typography>
                                            <img src={mapMarkerBlue} alt='map' style={{ width: '25px', marginLeft: '50px' }} />
                                        </Typography>
                                    </Typography>
                                </Stack>
                            </Stack>
                        </div>
                    }


                    {
                        props.tutorialIndex === 3 &&
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                                height: '100%',
                            }}
                        >
                            <div className='site-tutorial-3-body'>
                                <Typography variant="subtitle1">
                                    Know of a site that isn't being shown?
                                    <div>
                                        You can add it here.
                                    </div>
                                </Typography>
                                <Typography variant="body1" >
                                    <span style={{ color: BIBlue }}> (Tap anywhere to continue)</span>
                                </Typography>

                                <Stack direction="row" spacing={10} justifyContent="flex-end" sx={{ marginTop: "20px" }}>
                                    <Typography>
                                        <Typography>
                                            <SouthEastIcon style={{ fontSize: '40px' }} />
                                        </Typography>
                                        <div className='add-iconbtn-tutorial-conatiner'>
                                            <IconButton
                                                className="add-iconbtn-tutorial"
                                            >
                                                <AddIcon style={{ color: 'white' }} />
                                            </IconButton>
                                        </div>
                                    </Typography>
                                </Stack>
                            </div>
                        </div>
                    }



                    {
                        props.tutorialIndex === 4 &&
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                height: '100%',
                            }}
                        >
                            <div className='site-tutorial-4-body'>
                                <Card style={{ width: '440px' }}>
                                    <div style={{ backgroundColor: BIWhite, borderRadius: "8px", margin: "8px", marginBottom: "0px" }}>
                                        <TextField
                                            variant="outlined"
                                            placeholder="Search address or site name"
                                            className="search-location"
                                            disable
                                            fullWidth
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton
                                                        color="primary"
                                                    >
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>,
                                            }} />
                                    </div>
                                    <div className="flex-container-horizontal" style={{ margin: "0px 8px" }}>
                                        <div className="input-container"
                                            style={{ backgroundColor: 'primary', borderRadius: "8px" }}>
                                            <Button
                                                style={{ borderRadius: "20px", fontSize: "12px" }}
                                                variant={"contained"}
                                            >
                                                Explore
                                            </Button>
                                        </div>
                                        <div className="input-container"
                                            style={{ borderRadius: "8px" }}>
                                            <Button
                                                style={{ borderRadius: "20px", fontSize: "12px", color: 'black', background: BIWhite }}
                                                variant={"contained"}
                                            >
                                                My Sites
                                            </Button>
                                        </div>
                                    </div>
                                </Card>
                                <Stack direction="row" spacing={10} sx={{ marginTop: "20px", marginLeft: '20px' }}>
                                    <Typography>
                                        <SouthIcon style={{ fontSize: '40px' }} />
                                    </Typography>
                                    <Typography>
                                        <SouthIcon style={{ fontSize: '40px' }} />
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant="subtitle1">
                                        You can choose to explore the map or search for a specific site.
                                        <div>
                                            Use the 'My Sites' button to filter the map to just the sites you follow or have worked at.
                                        </div>
                                    </Typography>
                                    <Typography variant="body1" >
                                        <span style={{ color: BIBlue }}> (Tap anywhere to continue)</span>
                                    </Typography>
                                </Stack>
                            </div>
                        </div>
                    }



                    {
                        props.tutorialIndex === 5 &&
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                height: '100%',
                            }}
                        >
                            <div style={{ textAlign: 'center', color: 'white', marginTop: '146px', marginLeft: '10px' }}>

                                <Stack>
                                    <Typography variant="subtitle1">
                                        <div>
                                            A popup will appear when you tap on a site.
                                        </div>
                                        <div>
                                            Here you can view the site details, and choose to follow the site or add it to your profile.
                                        </div>
                                        <div style={{ marginTop: '10px' }}>
                                            Follow = Receive Site Updates
                                        </div>
                                        <div>
                                            Add to Profile = You have worked here
                                        </div>
                                    </Typography>
                                    <div style={{ marginTop: '10px' }}>
                                        Tap on the Site Name to go to the Site Page
                                    </div>
                                    <Typography variant="body1" >
                                        <span style={{ color: BIBlue }}> (Tap anywhere to continue)</span>
                                    </Typography>
                                    <Typography>
                                        <SouthIcon style={{ fontSize: '40px' }} />
                                    </Typography>
                                </Stack>
                                <Card className="site-view-tutorial" style={{ marginLeft: '62px' }}>
                                    <CardContent className="site-info">
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography className="site-name" gutterBottom variant="h5" component="div">
                                                BMO Center
                                            </Typography>
                                            <IconButton
                                            >
                                                <MoreIcon />
                                            </IconButton>
                                        </div>
                                        <Typography className="site-address" variant="body2" color="text.secondary" style={{ textAlign: 'start' }}>
                                            20 RoundUp Way SE
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            color="primary"
                                            style={{ marginRight: '5px' }}
                                            fullWidth
                                            variant="contained"
                                        >
                                            Follow
                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            fullWidth>
                                            Add To Profile
                                        </Button>
                                    </CardActions>
                                </Card>
                            </div>
                        </div>
                    }

                </div>
            </Modal>
        </>
    )
}

export default SitesTutorial