import React, { useState, useEffect } from "react";
import { Modal, Tooltip, IconButton, Button, Avatar, Typography } from "@material-ui/core";
import {
	Close as CloseIcon,
	Mail as MailIcon,
	CreditCard as CreditCardIcon,
	DirectionsCar as CarIcon,
	Redeem as RedeemIcon,
	Person as PersonIcon,
} from "@material-ui/icons";

import { useHistory } from "react-router-dom";

import {
	getDashboardNotifications,
	dismissUserNotification
} from "../../api/DashboardApi";
import {
	getPeopleInterestedInRideShare,
	getUserInfo,
	saveContactEvent
} from "../../api/UserApi";
import { BIBlue } from "../../assets/buildidColors";
import PictureViewer from "../../components/PictureViewer";
import ProfilePicturePlaceholder from "../../assets/images/profile-picture-placeholder.png";
import AvtarImage from "../../assets/images/AvatarImage.jpeg";
import "../../assets/css/Dashboard.css";
import UserUsages from "../../components/userUsages/UserUsages";
import { Box, Card, List, ListItem, ListItemButton, ListItemText, Stack } from "@mui/material";
import moment from "moment";
import Loader from "../../components/Loader";

export default function Notification(props) {
	const history = useHistory();
	const [content, setContent] = useState([]);
	const [contactInvitations, setContactInvitations] = useState([]);
	const [loading, setLoading] = useState(false)

	const handleNotificationClick = (item) => {
		switch (item.title) {
			case 'Post Comment':
				history.push("/dashboard", { comment: item, from: 'DashboardNotifications', refresh: new Date() });
				props.handleNotificationClose();
				break;
			case 'Tickets':
				history.push("/tickets", { ticketType: 'valid', refresh: new Date() });
				props.handleNotificationClose();
				break;
			case 'Contact Invitation Accepted':
				history.push(`/user-dashboard-profile/${item?.custom1}`);
				props.handleNotificationClose();
				break;
			case 'Contact Invitation':
				history.push(`/user-dashboard-profile/${item?.custom1}`);
				props.handleNotificationClose();
				break;
			case 'BuildID Info':
				history.push("/dashboard", { refresh: new Date() });
				break;
			case 'Ride Share':
				history.push("/carpool");
				props.handleNotificationClose();
				break;
			case 'Expired Tickets':
				history.push("/tickets", { ticketType: 'expired', refresh: new Date() });
				props.handleNotificationClose();
				break;
			case 'Shared Tickets':
				history.push("/tickets", { ticketType: 'shared', refresh: new Date() });
				props.handleNotificationClose();
				break;
			case 'BuilderBucks':
				break;
			case 'New Job Posting':
				history.push(`/projects/project/${item?.recordId}`, { from: 'DashboardNotifications' });
				props.handleNotificationClose();
				break;
			case 'Job Posting Expired':
				history.push("/job-postings", { jobPostType: 'jobPostExpired', refresh: new Date() });
				props.handleNotificationClose();
				break;
			case 'Job Posting Expire 3 Days':
				history.push("/job-postings", { jobPostType: 'jobPostActive', refresh: new Date() });
				props.handleNotificationClose();
				break;
			default: break;
		}
	}


	async function handleChannelJoinRequest(userId, val) {
		try {
			const obj = {
				'Id': 0,
				'UserId': userId,
				'ContactEmail': localStorage?.primaryEmail ?? "",
				'EventStatusId': val,
				'ModifiedBy': Number(localStorage.userId)
			}
			await saveContactEvent(obj);
			const dashboardNotifications = await getDashboardNotifications(Number(localStorage.userId), true);
			const newcContactInvitations = dashboardNotifications.filter((dash) => dash.title == 'Contact Invitation');
			const newDashboardNotifications = dashboardNotifications.filter((dash) => dash.title != 'Contact Invitation' && dash.title != 'BadgeNotifications');

			setContactInvitations(newcContactInvitations)
			setContent(newDashboardNotifications);
		} catch (err) {
			console.log("Err while handling the joining request:-", err)
		}
	}

	const getData = async () => {
		try {
			setLoading(true)
			await UserUsages('DashboardNotifications');
			await getDashboardNotifications(localStorage.userId)
				.then(notifications => {
					const newcContactInvitations = notifications.filter((dash) => dash.title == 'Contact Invitation');
					setContactInvitations(newcContactInvitations);
					const newDashboardNotifications = notifications.filter((dash) => dash.title != 'Contact Invitation' && dash.title != 'BadgeNotifications');
					setContent(newDashboardNotifications);
				})
			setLoading(false)
		} catch (err) {
			setLoading(false)
			console.log("Error while recving the compaines data ::", err);
		}
	};

	// On load
	useEffect(() => {
		// Get notifications from API
		getData();
	}, [])

	return (
		<div className="Notification-menu">
			<div className="page-content">
				{loading ? <div style={{ display: 'flex', }}><Loader /> </div> : <>
					{
						contactInvitations.length === 0 ? (<></>) : (
							<div style={{ marginTop: '10px' }}>
								<List disablePadding>
									{contactInvitations.map((notification, index) => {
										return (
											< DashboardNotification
												key={index}
												notification={notification}
												contactInvitation={"contactInvitations"}
												handleChannelJoinRequest={handleChannelJoinRequest}
												handleNotificationClick={handleNotificationClick}
											/>
										)
									})}
								</List>
							</div>
						)
					}

					{content.length === 0 ? (<></>) :
						(
							<div style={{ marginTop: contactInvitations.length === 0 ? '10px' : '0px' }}>
								<List disablePadding>
									{content.map((notification, index) => {
										return (
											< DashboardNotification
												key={index}
												notification={notification}
												handleNotificationClick={handleNotificationClick}
											/>
										)
									})}
								</List>
							</div>
						)}
				</>}
			</div>
		</div>
	)
}


function DashboardNotification(props) {
	// Returns the appropriate icon for all 6 system-generated message types, otherwise returns
	// default icon - the BuildID pin
	function getNotificationIcon() {
		switch (props.notification.title) {
			case "Expired Tickets":
				return <CreditCardIcon fontSize="large" color={"primary"} />

			case "Shared Tickets":
				return <CreditCardIcon fontSize="large" color={"primary"} />

			case "Earn BuilderBucks":
				return <RedeemIcon fontSize="large" color={"primary"} />

			case "Ride Share":
				return <CarIcon fontSize="large" color={"primary"} />

			case "Contact Requests":
				return <PersonIcon fontSize="large" color={"primary"} />

			case "Channel Join Request":
				return <PersonIcon fontSize="large" color={"primary"} />

			default:
				return (<Avatar className="profile-icon" style={{ height: "35px", width: "35px" }} alt="Remy Sharp" src={AvtarImage} />)
		}
	}

	const getNotificationDate = (date) => {
		if (!moment.isMoment(date)) date = moment(date);
		if (date.isSame(moment(), 'day')) {
			return moment.parseZone(date).local().fromNow(true);
		} else if (date.isSame(moment().subtract(1, 'd'), 'day')) {
			return '1 d';
		} else if (date.isSame(moment().subtract(2, 'd'), 'day')) {
			return '2 d';
		} else if (date.isSame(moment().subtract(3, 'd'), 'day')) {
			return '3 d';
		} else if (date.isSame(moment().subtract(4, 'd'), 'day')) {
			return '4 d';
		} else if (date.isSame(moment().subtract(5, 'd'), 'day')) {
			return '5 d';
		} else if (date.isSame(moment().subtract(6, 'd'), 'day')) {
			return '6 d';
		} else if (date.isSame(moment(), 'week')) {
			return moment.parseZone(date).local().format('dddd');
		} else {
			return moment.parseZone(date).local().format('MMMM D, YYYY');
		}
	}
	const boldTextFromApi = (text) => {
		const parts = text?.split(/<([^>]+)>/);
		const styledText = parts?.map((part, index) => {
			if (index % 2 === 1) {
				return <span key={index} style={{ fontWeight: 'bold' }}>{part}</span>
			} else return <span key={index}>{part}</span>
		});
		return <span> {styledText}</span>
	}

	const notificationDate = getNotificationDate(moment(props?.notification?.notificationDate));

	const description = boldTextFromApi(props?.notification?.description);

	const postDate = notificationDate.includes('hours') || notificationDate.includes('hour') || notificationDate.includes('minutes') || notificationDate.includes('minute') ? notificationDate?.replace('hours', 'hr')?.replace('minutes', 'min')?.replace('hour', 'hr')?.replace('minute', 'min') : notificationDate

	return (
		<div style={{ marginBottom: "10px" }}>
			<Card >
				{props?.contactInvitation ?
					<>
						<Stack style={{ marginLeft: '20px', paddingTop: '10px' }}> <Typography variant="body2" color="textSecondary" >Contact Request</Typography></Stack>
						<ListItem style={{ paddingTop: '0px' }} disablePadding

							secondaryAction={
								<Box sx={{ right: '5px' }}>
									<Button style={{ marginRight: '5px' }} size="small"
										variant="contained" color="primary"
										onClick={() => props?.handleChannelJoinRequest(props?.notification.custom1, 2)}>
										Accept
									</Button>
									<Tooltip title="cancel">
										<IconButton size="medium" color="default"
											onClick={() => props?.handleChannelJoinRequest(props?.notification.custom1, 3)}>
											<CloseIcon fontSize="small" />
										</IconButton>
									</Tooltip>
								</Box>
							}
						>
							<ListItemButton style={{ padding: '0px' }} onClick={() => props.handleNotificationClick(props?.notification)}>
								<IconButton color="primary" disabled>
									{getNotificationIcon()}
								</IconButton>
								<ListItemText
									primary={
										<Box style={{ display: 'flex', flexDirection: 'row', width: "130px" }}>
											<Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '14px' }}> {props?.notification?.custom2}</Typography>
										</Box>}
									secondary={null}
								/>
							</ListItemButton>
						</ListItem>
					</> : null}
				{props?.contactInvitation ? null :
					<ListItem style={{ padding: '0px' }} disablePadding>
						<ListItemButton style={{ padding: '0px' }} onClick={() => props.handleNotificationClick(props?.notification)}>
							<IconButton disabled color="primary">
								{getNotificationIcon()}
							</IconButton>
							<ListItemText style={{ paddingRight: '8px' }}
								primary={<span style={{ fontSize: '14px', lineHeight: 1 }}>{description} </span>}
								secondary={postDate}
							/>
						</ListItemButton>
					</ListItem>}
			</Card>
		</div>
	)
}
