import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { Close } from "@material-ui/icons";
import { IconButton } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  padding: "25px",
  borderRadius: "10px",
};

export default function ClientSafetyIncidentViewModal({ data, close, open }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "flex", alignItems: "center", justifyContent:'right',marginBottom:'5px' }}>
            <IconButton
              color="primary"
              onClick={()=>close()} style={{ padding: "0px" }}>
              <Close />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>
              <Typography id="modal-modal-title">
                <b>Date :</b> {moment(data.incidentDate).format("MMM DD, YYYY")}
              </Typography>
            </span>
            <span>
              <Typography id="modal-modal-title">
                <b>Time :</b> {moment(data.incidentTime).format("h:mm a")}
              </Typography>
            </span>
          </div>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <b>Job Code :</b> {data.clientJobName}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <b>Description :</b>
          </Typography>
          <Typography
            id="modal-modal-description"
            style={{
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              wordWrap: "break-word",
              wordBreak: "break-word",
              hyphens: "auto",
            }}
          >
            {data.description}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
