import React, { useEffect, useRef, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, TextField, InputAdornment, Input, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { fetchUserTrades } from "../../api/UserApi";
import { Button } from "@material-ui/core";

export default function PostDialog(props) {

  const fileInputRef = useRef(null);
  const [tradeName, setTradeName] = useState("");

  useEffect(async () => {
    if (props?.isOpen) {
      try {
        await fetchUserTrades({ SearchList: [{ UserId: localStorage.userId }] })
          .then((res) => {
            const mainTradeDetails = res?.filter((trade) => trade?.tradeType?.toLowerCase() === "main");
            return mainTradeDetails?.[0]?.tradeName ? setTradeName(mainTradeDetails?.[0]?.tradeName) : setTradeName(null)
          }).catch((err) => {
            setTradeName(null)
            console.log("Error while getting user trade details:-", err)
          })
      } catch (err) {
        console.log("Error while getting user trade details:-", err)
      }
    }

  }, [props?.isOpen])

  const handleFileSelect = (event) => {
    const files = event.target.files;
    let images = [];
    for (let i = 0; i < files.length; i++) {
      images.push(files[i]);
    }
    props.setTempPic([...images, ...props?.tempPic]);
  }

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>Write a Post</DialogTitle>
      <DialogContent style={{ width: '600px', maxHeight: '500px' }}>
        <div className="flex-container-horizontal" style={{ marginBottom: '15px' }}>
          <div className="flex-container-vertical">
            <Avatar
              alt="Profile Picture"
              src={localStorage.pictureUrl} />
          </div>

          <div className="flex-container-vertical" style={{ flex: 1, marginLeft: "10px" }}>
            <div>{localStorage.firstName + " " + localStorage.lastName}</div>
            <div>{tradeName ?? "No Trade"}</div>
          </div>
        </div>

        <div className="flex-container-horizontal">
          <TextField
            variant="outlined"
            size="small"
            multiline
            sx={{ backgroundColor: "white" }}
            fullWidth
            value={props.userComment}
            onChange={event => props.setUserComment(event.target.value)}

            placeholder="What do you want to talk about?" />
        </div>



        <div style={{ maxHeight: '350px', overflowY: 'auto', marginTop: '10px' }}>
          {
            props?.tempPic?.length !== 0 &&
            (
              <>
                <div style={{ display: "flex", justifyContent: "center", flexWrap: 'wrap' }}>
                  {
                    props?.tempPic?.map((picture, index) => (
                      <div
                        className="picture-container"
                        style={{ margin: "0px 16px", height: "350px", display: "flex" }}
                        key={index}
                      >
                        <div className="picture-container-button">
                          <IconButton size="small" onClick={() => props.onDeletePic(picture, index)}>
                            <CloseIcon />
                          </IconButton>
                        </div>
                        <img
                          src={URL.createObjectURL(picture)} alt={picture.name}
                          width="100%"
                        />
                      </div>
                    ))

                  }

                </div>

              </>
            )
          }
        </div>

      </DialogContent>

      <div className="flex-container-horizontal" style={{ justifyContent: "space-between", alignItems: "center", margin: '0 10px' }}>
        <div className="input-container">
          <Button color={props.tempPic.length !== 0 ? "secondary" : "primary"} aria-label="upload picture" component="span"
            onClick={() => { fileInputRef.current.click() }}
          >
            Add Photos
          </Button>
        </div>

        <div className="flex-container-horizontal">
          <div className="input-container">
            <Button onClick={props.handleCancelButtonClick}>Cancel</Button>
          </div>

          <div className="input-container">
            <Button onClick={props.handlePostClick} disabled={props.tempPic.length === 0 && props.userComment === ""}>
              Post
            </Button>
          </div>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileSelect}
        accept="image/*"
        multiple
      />
    </Dialog>
  );
}
