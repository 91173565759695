import React, { useEffect, useState } from 'react'
import { Card, CircularProgress } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    IconButton,
    ListItem, ListItemButton, ListItemText, Stack,
} from "@mui/material";
import moment from "moment";
import { Edit as EditIcon } from "@material-ui/icons";
import { BIBlue } from '../../assets/buildidColors';
import ErrorIcon from '@mui/icons-material/Error';

export default function SafetyIncidentsListSection(props) {

    const [response, setResponse] = useState(props?.safetyIncidentsList);
    const [nrOfRecords, setNrOfRecords] = useState(props?.nrOfRecords);

    useEffect(() => {
        setResponse(props?.safetyIncidentsList ?? [])
        setNrOfRecords(props?.nrOfRecords?? 0)
    }, [props?.safetyIncidentsList,props?.nrOfRecords])


    return (

        <div className='other-tab'>
            {response.length > 0
                ?
                <InfiniteScroll
                    dataLength={response.length}
                    next={async () => {
                        props.getData()
                            .then(res => {
                                setNrOfRecords(res.nrOfRecords);
                                setResponse(res.data);
                            });
                    }}

                    hasMore={true}
                >

                    <Stack spacing={2}>
                        {response.map((item, itemIndex) => {
                            return (
                                <div key={itemIndex}>
                                    <ListSectionItem
                                        itemData={item}
                                        itemSiteName={item.siteName}
                                        itemIncidentDescription={item.incidentDescription}
                                        itemIndex={itemIndex}
                                        itemIncidentDate={item.incidentDate}
                                        itemGetEditSafetyIncidentsData={props.getEditSafetyIncidentsData}
                                    /></div>)
                        })}
                    </Stack>
                </InfiniteScroll>
                : <>{!nrOfRecords !== 0
                    ? <CircularProgress style={{ textAlign: "center", marginTop: "50%", marginLeft: "50%" }} /> : null}</>}
        </div>)

}


function ListSectionItem(props) {

    return (
        <>
            <Card>
                <ListItem key={props.itemIndex}
                    secondaryAction={
                        <div style={{ display: 'flex' }}>
                            <ListItemText
                                style={{ marginRight: '2rem' }}
                                primaryTypographyProps={{ fontSize: '5px', color: BIBlue }}
                                primary={<>

                                </>}
                            />

                            <IconButton
                                style={{ color: BIBlue }}
                                onClick={() => props.itemGetEditSafetyIncidentsData(props.itemData)}>
                                <EditIcon />
                            </IconButton>
                        </div>
                    }
                    disablePadding
                >
                    <ListItemButton>
                        <div key={props.itemIndex} style={{ display: 'flex', flexDirection: 'row' }}>
                            <ListItemText
                                primary={<div>{props.itemSiteName} {props.itemIsImminentDanger && <ErrorIcon style={{ color: 'red', fontSize: '15px' }} />} </div>}
                                secondary={<div key={props.itemIndex}>
                                    <div>{props.itemIncidentDescription}</div>
                                    <div>{moment(props.itemIncidentDate).format("MMMM DD, YYYY")}</div>
                                </div>}
                            />
                        </div>
                    </ListItemButton>
                </ListItem>
            </Card>
        </>
    )
}
