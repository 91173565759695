import React from "react";
import { Box, Container } from "@mui/material";
import BuildIDLogo from "./../../../../assets/images/logo.png";

const Welcome = ({ data, icon }) => {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <img
          style={{ height: "90px", width: "80px", display: "block", margin: "0 auto" }}
          src={BuildIDLogo}
          alt=""
        />
      </div>
      <Container>
        <div style={{ padding: "15px" }}>
          <h2 className="welcomebuild">Welcome to BuildID!</h2>
        </div>
        <div>
          <h4 className="welcometext">
            We want to create a construction web app that empowers and rewards all members
          </h4>
          <Box className="welcometext">We are so excited to have you on board!</Box>
        </div>
      </Container>
    </>
  );
};

export default Welcome;
