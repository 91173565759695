import { Tab, Tabs, Box, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import { searchTrade } from "../../api/TradesApi";
import SearchField from "../../components/SearchField";
import PropTypes from 'prop-types';
import SearchListSection from "../search/SearchListSection";

function TabPanel(props) {

    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component='span'>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


export default function Tradelist() {

    const [tradeTabValue, setTradeTabValue] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);
    const [getUpdatedData, setGetUpdatedData] = useState(false);

    const getTradelist = useCallback(async (pageNumber, searchText) => {
        console.log("tradeTabValue", tradeTabValue)
        const obj = {
            UserId: Number(localStorage?.userId),
            SearchString: searchText,
            PageNumber: pageNumber,
            NrRowsPerPage: 20,
            MyTradeCategory: ""
        }
        const res = await searchTrade(obj);
        console.log("res", res);
        return res?.data;
    }, [page])

    const getMytradelist = useCallback(async (pageNumber, searchText) => {
        const obj = {
            UserId: Number(localStorage?.userId),
            SearchString: searchText,
            PageNumber: pageNumber,
            NrRowsPerPage: 20,
            MyTradeCategory: "ON MY PROFILE"
        }
        const res = await searchTrade(obj);
        console.log("res", res);
        return res?.data;
    }, [page])


    const increaseOffset = (callback) => {
        setPage((prevPageNr) => prevPageNr + 1);
        callback(page + 1);
    }

    const handleChangeTradeTab = (event, newValue) => {
        setTradeTabValue(newValue);
        setPage(1);
    }

    const a11yProps = (index) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    const clearSearch = (value) => {
        setSearchText(value);
        if (value === "") {
            setGetUpdatedData(true)
        }
    }


    return (

        <div className="page">
            <div className="page-content">
                <div className="search-container" style={{ position: "fixed", zIndex: 999 }}>
                    <div style={{ backgroundColor: 'white', borderRadius: '5px' }}>
                        <SearchField
                            value={searchText}
                            onChange={(value) => clearSearch(value)}
                            onSubmit={() => { setPage(1); setGetUpdatedData(true) }}
                            placeholder={"Search Trades"}
                        />
                    </div>
                </div>
                <div className="product-list">
                    <Tabs
                        orientation="vertical"
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="primary tabs example"
                        style={{ marginTop: '17px', width: '250px' }}
                        value={tradeTabValue}
                        onChange={handleChangeTradeTab}
                    >
                        <Tab
                            textColor="primary"
                            className={'tab-btn-radius'}
                            label="All Trades" {...a11yProps(0)}
                        />
                        <Tab
                            textColor="primary"
                            className={'tab-btn-radius'}
                            style={{ marginTop: '17px' }}
                            label="My Trades" {...a11yProps(1)}
                        />
                    </Tabs>
                    <TabPanel value={tradeTabValue} index={0} style={{ width: '100%' }}>
                        <div className="list-container" style={{ marginTop: "0px" }} id={"scrollList"}>
                            <SearchListSection
                                getData={getTradelist}
                                increaseOffset={increaseOffset}
                                page={page}
                                universalsearch={false}
                                searchText={searchText}
                                getUpdatedData={getUpdatedData}
                                setGetUpdatedData={(val) => setGetUpdatedData(val)}
                                from="trade"
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={tradeTabValue} index={1} style={{ width: '100%' }}>
                        <div className="list-container" style={{ marginTop: "0px" }} id={"scrollList"}>
                            <SearchListSection
                                getData={getMytradelist}
                                increaseOffset={increaseOffset}
                                page={page}
                                universalsearch={false}
                                searchText={searchText}
                                getUpdatedData={getUpdatedData}
                                setGetUpdatedData={(val) => setGetUpdatedData(val)}
                                from="trade"
                            />
                        </div>
                    </TabPanel>
                </div>
            </div>
        </div>
    )

}