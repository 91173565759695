import { GoogleMap, Marker, MarkerClusterer } from "@react-google-maps/api";
import React, { useEffect, useState, useRef } from "react";
import { fetchUserWorkHistory } from "../../api/UserApi";
import { fetchSites, fetchSitesPictures, getAddress, getSitesByMapBounds } from "../../api/SitesApi";
import { BIBlack } from "../../assets/buildidColors";
import mapMarkerYellow from "../../assets/images/map-marker-yellow.png";
import mapMarkerBlue from "../../assets/images/map-marker-blue.png";
import Siteview from "./Siteview";
import placeholder from "../../assets/images/project-profile-placeholder.jpg";
import AddIcon from '@mui/icons-material/Add';
import { CircularProgress, IconButton } from "@mui/material";
import Chooseoption from "./Chooseoption";
import Addsite from "./Addsite";
import SitesOverlay from "./SitesOverlay";
import Chooseasite from "./Chooseasite";
import SitesTutorial from "../../components/Tutorial/SitesTutorial";
import { showTutorialForNewUser, updateTutorialForNewUser } from "../../assets/helpers";


function Sitemap() {

    const [selectedSite, setSelectedSite] = useState(false);
    const [chooseOption, setChooseoption] = useState(false);
    const [tutorialIndex, setTutorialIndex] = useState(1);
    const [showAddsite, setShowaddsite] = useState(false);
    const [isSkeleton, setIsSkeleton] = useState(false);
    const [siteInfo, setSiteinfo] = useState({});
    const [sitePic, setSitepic] = useState();
    const [selectSitepic, setSelectsitepic] = useState(null);
    const [userSites, setUserSites] = useState([]);
    const [map, setMap] = useState();
    const [mapSites, setMapSites] = useState([]);
    const [showChooseSiteModal, setShowChooseSiteModal] = useState(false);
    const [selectedSiteLists, setSelectedSiteList] = useState([]);
    const [currentLatlng, setCurrentLatlng] = useState({
        lat: 0,
        lng: 0
    });
    const [chooseLocation, setChooselocation] = useState({
        lat: 0,
        lng: 0
    });
    const [dropMarker, setDropmarker] = useState(false);
    const mapRef = useRef(null);

    const clusterOptions = {
        styles: [
            {
                textColor: BIBlack,
                textSize: "13",
                height: 56,
                width: 56,
                // Icon image file needs to be hosted somewhere
                // This is the default m2.png from Google Maps
                url: "https://unpkg.com/@googlemaps/markerclustererplus@1.0.3/images/m2.png"
            },
        ]
    }
    const containerStyle = {
        width: '100%',
        height: '85vh',
    };

    useEffect(() => {
        if (typeof map !== "undefined") {
            getCurrentLocation();
        }
    }, [map])


    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(currentLocation, showError);
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    const showError = (error) => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                alert("Please allow location from your browser");
                getCurrentLocation();
                break;
            case error.POSITION_UNAVAILABLE:
                alert("Location information is unavailable.");
                break;
            case error.TIMEOUT:
                alert("The request to get user location timed out.");
                break;
            case error.UNKNOWN_ERROR:
                alert("An unknown error occurred.");
                break;
            default:
                break;
        }
    };

    // Get current location
    const currentLocation = async (position) => {
        var lat = position?.coords?.latitude;
        var lng = position?.coords?.longitude;

        setCurrentLatlng({
            lat: lat,
            lng: lng
        });
        getSitesofCurrentlocation(lat, lng);
    };

    const handleMapDrag = () => {
        if (mapRef.current) {
            const center = mapRef.current.getCenter(); // Get the center of the map
            const lat = center.lat();
            const lng = center.lng();
            setCurrentLatlng({
                lat: lat,
                lng: lng
            });
            getSitesofCurrentlocation(lat, lng);
        }
    };


    const getSitesofCurrentlocation = async (lat, lng) => {
        let getUserworkhistory = await getFetchUserworkHistory();
        let addressData = await getAddress({ latitude: lat, longitude: lng });
        let addressObj = addressData.objAddress;
        let obj = {
            Active: 1,
            Id: 0,
            Latitude: addressObj.latitude,
            Longitude: addressObj.longitude,
            VP_Latitude_NE: map?.getBounds()?.getNorthEast()?.lat(),
            VP_Latitude_SW: map?.getBounds()?.getSouthWest()?.lat(),
            VP_Longitude_NE: map?.getBounds()?.getNorthEast()?.lng(),
            VP_Longitude_SW: map?.getBounds()?.getSouthWest()?.lng(),
            City: addressObj?.city ?? '',
            Province: addressObj?.province ?? '',
            Country: addressObj?.country ?? '',
            Address: addressObj?.address ?? '',
            PostalCode: addressObj?.postalCode ?? '',
            AllowedRecordsOnly: true,
            IsSelectedOnGoogleSearch: false
        }
        let getResponse = await getSitesByMapBounds(obj);
        if (getResponse?.length > 0) {
            getResponse = getResponse.map((site) => {
                let getItem = getUserworkhistory?.filter((item) => item?.siteId === site?.id);
                return {
                    ...site,
                    isFollow: getItem?.length > 0 ? "blue" : "yellow"
                }
            })

            setMapSites(getResponse);
        }
    }

    const getFetchUserworkHistory = async () => {
        // Call FetchUsersWorkHistory 
        let getUserworkhistory = await fetchUserWorkHistory({ SearchList: [{ UserId: Number(localStorage.userId) }] })
        // Call FetchSites 
        if (getUserworkhistory.length > 0) {
            await setUserSites(getUserworkhistory);
            return getUserworkhistory;
        }
    }

    const mapLoad = (map) => {
        mapRef.current = map
        setMap(map)
    }

    const handleMarkerClick = async (site) => {
        setIsSkeleton(true)
        setSelectedSite(true);
        let getSitepic = await fetchSitesPictures({ SearchList: [{ SiteId: site.id }] });
        let siteRes = await fetchSites({ SearchList: [{ Id: site.id }] });
        setSiteinfo(siteRes?.data[0]);
        setSitepic(getSitepic?.length > 0 ? getSitepic[0]?.imageUrl : placeholder)
        setIsSkeleton(false);
    }


    const handleClusterClick = (cluster) => {
        const clusterMarkers = cluster.getMarkers();
        const map = mapRef.current;
        if (map) {
            const zoomLevel = map.getZoom();
            if (zoomLevel >= 22) {
                // Filter out sites that have the same coordinates
                const clusterSites = mapSites.filter(site =>
                    clusterMarkers.some(clusterMarker =>
                        site.latitude === clusterMarker.getPosition().lat() && site.longitude === clusterMarker.getPosition().lng()
                    )
                );
                if (clusterSites?.length === 0) return;
                if (clusterSites?.length === 1) return handleMarkerClick(clusterSites?.[0]);
                setSelectedSiteList(clusterSites);
                setShowChooseSiteModal(true);
            }
        }
    };


    const showOption = () => {
        setChooselocation({
            lat: Number(currentLatlng.lat),
            lng: Number(currentLatlng.lng)
        })
        setChooseoption(true);
    }

    const handleMapClick = (e) => {
        setSelectedSite(false);
        setChooselocation({
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        });
        setDropmarker(true);
    }

    const addSiteonCurrentmarker = () => {
        setShowaddsite(true);
    }

    const addjustmapView = (viewport) => {
        map.fitBounds(viewport);
    }



    return (
        <>
            <GoogleMap
                mapContainerClassName="site-map-container"
                mapContainerStyle={containerStyle}
                center={currentLatlng}
                onDragEnd={handleMapDrag}
                zoom={12}
                onLoad={mapLoad}
                ref={mapRef}
                onClick={(e) => handleMapClick(e)}
              
            >

                {/* Markers and Clusters for sites */}
                <MarkerClusterer options={clusterOptions} onClick={handleClusterClick}>
                    {
                        clusterer => {
                            return mapSites.map(site => (
                                <Marker
                                    key={site?.id}
                                    position={{
                                        lat: site?.latitude,
                                        lng: site?.longitude
                                    }}
                                    clusterer={clusterer}
                                    onClick={() => handleMarkerClick(site)}
                                    icon={{
                                        url: site?.isFollow === "blue" ? mapMarkerBlue : mapMarkerYellow,
                                        scaledSize: (
                                            // Selected map marker is slightly larger
                                            new window.google.maps.Size(30, 40)
                                        )
                                    }}
                                />
                            ))
                        }
                    }
                </MarkerClusterer>


                {dropMarker && (
                    <Marker
                        position={{
                            lat: chooseLocation?.lat,
                            lng: chooseLocation?.lng
                        }}
                        onClick={() => addSiteonCurrentmarker()}
                        icon={{
                            url: mapMarkerBlue,
                            scaledSize: new window.google.maps.Size(35, 40)
                        }} />)}
                <IconButton className="add-iconbtn" color="primary" onClick={showOption}>
                    <AddIcon />
                </IconButton>
                <SitesOverlay
                    getSitesofCurrentlocation={getSitesofCurrentlocation}
                    addjustmapView={addjustmapView}
                    setDropmarker={setDropmarker}
                    setChooselocation={setChooselocation}
                    userSites={userSites}
                />
                {selectedSite && (<Siteview isSkeleton={isSkeleton} sitePic={sitePic} setShowaddsite={setShowaddsite} siteInfo={siteInfo} setSelectedSite={setSelectedSite} />)}
                {chooseOption && (
                    <Chooseoption
                        setSelectsitepic={(e) => {
                            const file = e;
                            file.alreadySelected = true;
                            setSelectsitepic(file);
                        }}
                        chooseOption={chooseOption}
                        chooseLocation={chooseLocation}
                        setChooselocation={setChooselocation}
                        currentLatlng={currentLatlng}
                        setShowaddsite={setShowaddsite}
                        setChooseoption={setChooseoption}

                    />)}
                {showAddsite && (<Addsite selectSitepic={selectSitepic} chooseLocation={chooseLocation} setChooselocation={setChooselocation} siteInfo={siteInfo} showAddsite={showAddsite}
                    setShowaddsite={() => {
                        setSelectsitepic(null);
                        setShowaddsite(false)
                    }}

                />)}
                {showChooseSiteModal && (<Chooseasite isOpen={showChooseSiteModal}
                    onClose={() => {
                        setShowChooseSiteModal(false)
                        setSelectedSiteList([])
                    }}
                    onSiteClick={(site) => {
                        if (site) {
                            setShowChooseSiteModal(false);
                            setSelectedSiteList([]);
                            return handleMarkerClick(site)
                        }
                    }}
                    siteList={selectedSiteLists}

                />
                )}
            </GoogleMap>
            {showTutorialForNewUser('sites') && (
                <SitesTutorial
                    onPress={() => {
                        setTutorialIndex((p) => {
                            if (p >= 5) {
                                if (showTutorialForNewUser('sites')) {
                                    updateTutorialForNewUser('sites');
                                }
                                return 0;
                            } else {
                                return p + 1
                            }
                        });
                    }}
                    tutorialIndex={tutorialIndex}
                />
            )}

        </>
    )
}
export default Sitemap;