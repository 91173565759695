import React, { useCallback, useState } from 'react'
import { Button, Container, Tab, Tabs } from '@material-ui/core';
import { Stack } from '@mui/material';
import { Search as SearchIcon } from "@mui/icons-material";
import { BIBlue } from '../../assets/buildidColors';
import Placeholder from '../../components/Placeholder';
import SafetyObservationListSection from './SafetyObservationListSection';
import Loader from '../../components/Loader';
import { useEffect } from 'react';
import { fetchSafetyObservations } from '../../api/SafetyObservationApi';
import SafetyObservationModal from './SafetyObservationModal';

const SafetyObservation = () => {
    const [activeTab, setActiveTab] = useState("BY ME");
    const [isByMeEmpty, setIsByMeEmpty] = useState(false);
    const [isAllEmpty, setIsAllEmpty] = useState(false);

    const [pageNr, setPageNr] = useState(1);

    const [myObservationList, setMyObservationList] = useState([])
    const [allObservationList, setAllObservationList] = useState([])
    const [nrOfMyRecords, setNrOfMyRecords] = useState([]);
    const [nrOfAllRecords, setNrOfAllRecords] = useState([]);


    const [safetyObservationsModalOpen, setSafetyObservationsModalOpen] = useState(false);


    const [safetyObservationsId, setSafetyObservationsId] = useState(0);
    const [safetyObservationsData, setSafetyObservationsData] = useState([]);
    const [editSafetyObservationsData, setEditSafetyObservationsData] = useState([]);

    const tabsArray = ["BY ME", "ALL"];




    const getMyObservationList = async () => {
        const obj = {
            PageNr: pageNr,
            NrOfRecPerPage: 100,
            FullSearch: "",
            UserId: localStorage.userId,
            IncludeRecordNr: "true",
            TypeOfObjectReturned: "",
            FetchAllowedRecordsOnly: false,
            SearchList: [
                {
                    CreatedBy: localStorage.userId
                }
            ],
            SortList: [
				{
					FieldName: "Id",
					Direction: "DESC"
				}
			]
        }
        await fetchSafetyObservations(obj).then(res => {
            setNrOfMyRecords(res.nrOfRecords);
            setMyObservationList(res.data);
        }).catch((err) => {
            console.log("getting error while getting my observation list", err)
        })
    }

    const getAllObservationList = async () => {
        const obj = {
            PageNr: pageNr,
            NrOfRecPerPage: 100,
            FullSearch: "",
            UserId: localStorage.userId,
            IncludeRecordNr: "true",
            TypeOfObjectReturned: "",
            FetchAllowedRecordsOnly: false,
            SearchList: [
                {}
            ],
            SortList: [
				{
					FieldName: "Id",
					Direction: "DESC"
				}
			]
        }
        const res = await fetchSafetyObservations(obj).then(res => {
            setNrOfAllRecords(res.nrOfRecords);
            setAllObservationList(res.data);
        }).catch((err) => {
            console.log("getting error while getting all observation list", err)
        })
    }


    useEffect( () => {
         getMyObservationList()
         getAllObservationList()
    }, [])



    const handleTabChange = (event, newTab) => {
        setPageNr(1);
        setActiveTab(newTab);
    }



    const getEditSafetyObservationsData = async (data) => {
        setSafetyObservationsId(data?.id);
        await setEditSafetyObservationsData(data);
        setSafetyObservationsModalOpen(true);
    }


    const getByMeRecords = useCallback(async () => {
        const obj = {
            PageNr: pageNr,
            NrOfRecPerPage: 100,
            FullSearch: "",
            UserId: localStorage.userId,
            IncludeRecordNr: "true",
            TypeOfObjectReturned: "",
            FetchAllowedRecordsOnly: false,
            SearchList: [
                {
                    CreatedBy: localStorage.userId
                }
            ],
            SortList: [
				{
					FieldName: "Id",
					Direction: "DESC"
				}
			]
        }
        const res = await fetchSafetyObservations(obj);

        if (res.nrOfRecords === 0)
            setIsByMeEmpty(true);
        else
            setIsByMeEmpty(false);
        return res;
    }, [pageNr]);


    const getAllRecords = useCallback(async () => {
        const obj = {
            PageNr: pageNr,
            NrOfRecPerPage: 100,
            FullSearch: "",
            UserId: localStorage.userId,
            IncludeRecordNr: "true",
            TypeOfObjectReturned: "",
            FetchAllowedRecordsOnly: false,
            SearchList: [
                {}
            ],
            SortList: [
				{
					FieldName: "Id",
					Direction: "DESC"
				}
			]
        }
        const res = await fetchSafetyObservations(obj);
        if (res.nrOfRecords === 0)
            setIsAllEmpty(true);
        else
            setIsAllEmpty(false);
        return res;
    }, [pageNr]);



    useEffect(() => {
        setPageNr(1);
    }, [])


    const dataAddedSuccessfully = () => {
        getMyObservationList()
        getAllObservationList()
    }

    return (
        <>
            <SafetyObservationModal
                isOpen={safetyObservationsModalOpen}
                onClose={() => {
                    setPageNr(1);
                    setSafetyObservationsModalOpen(false)
                }}

                safetyObservationsId={safetyObservationsId}
                editSafetyObservationsData={editSafetyObservationsData}
                setEditSafetyObservationsData={setEditSafetyObservationsData}

                setSafetyObservationsModalOpen={() => { setSafetyObservationsModalOpen(false) }}
                setSafetyObservationsData={() => setEditSafetyObservationsData([])}
                setPageNr={setPageNr}
                dataAddedSuccessfully={dataAddedSuccessfully}
            />



            <div className="page rewards-page">
                <div className="header">
                    <div className="page-content">
                        <h1>Safety Observations</h1>
                    </div>
                </div> </div>
            <div className="page-content" style={{ paddingLeft: '16%', paddingRight: '16%' }}>

                <Tabs
                    textColor="primary"
                    indicatorColor="primary"
                    value={activeTab}
                    onChange={handleTabChange}>
                    {
                        tabsArray.map((tab, index) => (
                            <Tab key={index} value={tab} label={tab} />
                        ))
                    }
                </Tabs>
            </div>
            <div className="page" style={{ alignItems: "center" }}>
                <Container>
                    <div className="resp-container-horizontal" style={{ margin: "15px 0px 0px 0px", display: 'flex', justifyContent: 'flex-end' }}>
                        <div className="flex-container-right" >
                            <div className="flex-container-horizontal" >
                                <div className="input-container">
                                    <Button
                                        variant="contained"
                                        style={{ background: BIBlue, color: 'white' }}
                                        onClick={() => {
                                            setSafetyObservationsModalOpen(true)
                                        }}>
                                        Add Safety Observation
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Stack spacing={2} style={{ marginBottom: "15px" }}>
                        <Stack direction="row" spacing={2}>
                            <div style={{ flex: 2 }} >
                                {
                                    (activeTab === "BY ME")
                                        ? (
                                            isByMeEmpty
                                                ?
                                                <Placeholder icon={<SearchIcon />} text="No results found." />
                                                :
                                                <SafetyObservationListSection
                                                    setPageNr={() => setPageNr(1)}
                                                    getData={getByMeRecords}
                                                    getEditSafetyObservationsData={getEditSafetyObservationsData}
                                                    observationDataList={myObservationList}
                                                    noObservationList={nrOfMyRecords}
                                                />
                                        )
                                        : <></>
                                }
                                {
                                    (activeTab === "ALL")
                                        ? (
                                            isAllEmpty
                                                ?
                                                <Placeholder icon={<SearchIcon />} text="No results found." />
                                                : <SafetyObservationListSection
                                                    setPageNr={() => setPageNr(1)}
                                                    getData={getAllRecords}
                                                    getEditSafetyObservationsData={getEditSafetyObservationsData}
                                                    observationDataList={allObservationList}
                                                    noObservationList={nrOfAllRecords}
                                                />
                                        )
                                        : <></>
                                }
                            </div>
                        </Stack>
                    </Stack>
                </Container>
            </div>
        </>
    )
}

export default SafetyObservation;